import update from 'immutability-helper';

import userActionTypes from 'actions/user/actions';
import actionTypes from 'actions/dashboard/actions';

// Helpers
const mapIndex = (maps, id) => maps.findIndex((element) => element.id === id);

const foldIndex = (folders, id) =>
  folders.findIndex((element) => element.id === id);

const ACTION_HANDLERS = {
  [actionTypes.LOAD_DASHBOARD_START]: (state, action) => ({
    ...state,
    mapsLoaded: false,
  }),
  [actionTypes.LOAD_DASHBOARD_SUCCESS]: (state, action) => ({
    ...state,
    maps: action.data,
    mapsLoaded: true,
  }),
  [actionTypes.LOAD_DASHBOARD_FAILURE]: (state, action) => ({
    ...state,
    maps: [],
    mapsLoaded: true,
  }),
  [actionTypes.LOAD_FOLDERS_START]: (state, action) => ({
    ...state,
    dashboardLoaded: false,
  }),
  [actionTypes.LOAD_FOLDERS_SUCCESS]: (state, action) => ({
    ...state,
    folders: action.data,
    dashboardLoaded: true,
  }),
  [actionTypes.LOAD_FOLDERS_FAILURE]: (state, action) => ({
    ...state,
    folders: [],
    dashboardLoaded: true,
  }),
  [actionTypes.DELETE_MAP_SUCCESS]: (state, action) => {
    const index = mapIndex(state.maps, action.data.id);
    // Search in sub folders if not found in root
    let { folders } = state;
    if (index < 0) {
      const otherFoldersMaps = folders.map((folder) => folder.maps).flat();
      const subfolderMap = otherFoldersMaps.find(
        (map) => map.id === action.data.id,
      );
      const folderIndex = foldIndex(folders, subfolderMap.folder_id);

      if (subfolderMap) {
        folders = update(folders, {
          [folderIndex]: {
            $apply: (folder) => {
              if (!folder) {
                return null;
              }
              const subfolderMapIndex = mapIndex(folder.maps, subfolderMap.id);
              folder.maps = folder.maps
                .slice(0, subfolderMapIndex)
                .concat(folder.maps.slice(subfolderMapIndex + 1));
              return folder;
            },
          },
        });
      }
    }

    return {
      ...state,
      maps:
        index >= 0 ?
          state.maps.slice(0, index).concat(state.maps.slice(index + 1))
        : state.maps,
      folders,
    };
  },
  [actionTypes.DELETE_MAP_FAILURE]: (state, action) => state,
  [actionTypes.CREATE_FOLDER_SUCCESS]: (state, action) => {
    const newFolder = { ...action.data, open: true };
    let { folders } = state;
    if (newFolder.folder_id) {
      const index = foldIndex(folders, newFolder.folder_id);

      folders = update(folders, {
        [index]: {
          $apply: (folder) => {
            if (!folder) {
              return null;
            }
            if (!folder.folders) {
              folder.folders = [];
            }
            folder.folders.push(newFolder);
            return folder;
          },
        },
      });
    }

    return { ...state, folders: folders.concat(newFolder) };
  },

  [actionTypes.DELETE_FOLDER_SUCCESS]: (state, action) => {
    // Check if the deleted folder is a subfolder.
    const { folders } = state;
    const index = foldIndex(folders, action.data.id);

    return {
      ...state,
      folders: folders.slice(0, index).concat(folders.slice(index + 1)),
    };
  },
  [actionTypes.EMPTY_FOLDER_SUCCESS]: (state, action) => {
    const folder = action.data;
    const mapsToMove = state.maps.filter((m) => m.folder_id === folder.id);
    let { maps } = state;

    mapsToMove.forEach((nextMap) => {
      const index = mapIndex(maps, nextMap.id);
      maps = update(maps, {
        [index]: { $set: { ...maps[index], folder_id: null } },
      });
    });

    return {
      ...state,
      maps,
    };
  },
  [actionTypes.MOVE_MAP_TO_FOLDER_SUCCESS]: (state, action) => {
    const index = mapIndex(state.maps, action.map.id);
    const folder = action.data;
    let { maps } = state;

    maps = update(maps, {
      [index]: { $set: { ...maps[index], folder_id: folder.id } },
    });

    return {
      ...state,
      maps,
    };
  },
  [actionTypes.MOVE_MAPS_TO_FOLDER_SUCCESS]: (state, action) => {
    const folder = action.data;
    const nextMaps = action.maps;
    let { maps } = state;

    nextMaps.forEach((nextMap) => {
      const index = mapIndex(maps, nextMap.id);
      maps = update(maps, {
        [index]: { $set: { ...maps[index], folder_id: folder.id } },
      });
    });

    return {
      ...state,
      maps,
    };
  },
  [actionTypes.REMOVE_MAP_FROM_FOLDER_SUCCESS]: (state, action) => {
    const { map } = action;
    let { maps } = state;

    const index = mapIndex(maps, map.id);
    maps = update(maps, {
      [index]: { $set: { ...maps[index], folder_id: null } },
    });

    return {
      ...state,
      maps,
    };
  },
  [userActionTypes.LOGOUT]: () => initialState,
  [actionTypes.MERGE_MAP_START]: (state, action) => ({
    ...state,
    mergeStatus: 'RUNNING',
  }),
  [actionTypes.MERGE_MAP_SUCCESS]: (state, action) => ({
    ...state,
    mergeStatus: 'SUCCESS',
  }),
  [actionTypes.MERGE_MAP_FAILURE]: (state, action) => ({
    ...state,
    mergeStatus: 'FAILURE',
  }),
  [actionTypes.TOGGLE_OPEN_FOLDER]: (state, action) => {
    const targetFolder = action.folder;
    let { folders } = state;
    const found = folders.find((f) => f.id === targetFolder.id);

    if (found) {
      const folderIndex = foldIndex(folders, targetFolder.id);
      folders = update(folders, {
        [folderIndex]: {
          $apply: (f) => {
            if (!f) {
              return null;
            }
            return {
              ...f,
              open: action.value,
            };
          },
        },
      });
    }

    return {
      ...state,
      folders,
    };
  },
};

const initialState = {
  dashboardLoaded: false,
  mapsLoaded: false,
  maps: [],
  folders: [],
  mergeStatus: 'NOT_RUNNING',
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
