import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const MagnifyingGlassIcon = ({
  fill = colors.black,
  variant = 'filled',
  width = '28',
  height = '29',
}: IconPropsWithVariant) => {
  const filled = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8437 1.57442C13.0528 0.448252 9.92884 0.476877 7.15908 1.654C4.38931 2.83112 2.20059 5.06032 1.07442 7.8512C-0.0517482 10.6421 -0.0231222 13.766 1.154 16.5358C2.33113 19.3055 4.56032 21.4943 7.3512 22.6204C10.1421 23.7466 13.266 23.718 16.0358 22.5409C16.9523 22.1513 17.8053 21.6466 18.5772 21.0444L25.3753 27.8435C25.9185 28.3868 26.7993 28.3868 27.3425 27.8436C27.8858 27.3004 27.8858 26.4197 27.3427 25.8765L20.5444 19.0772C21.1889 18.2511 21.7214 17.3326 22.1204 16.3437C23.2466 13.5528 23.218 10.4288 22.0409 7.65908C20.8637 4.88931 18.6345 2.70059 15.8437 1.57442ZM8.24721 4.21438C10.3379 3.32585 12.696 3.30424 14.8026 4.15431C16.9093 5.00438 18.5919 6.6565 19.4805 8.74721C20.369 10.8379 20.3906 13.196 19.5405 15.3026C19.1116 16.3657 18.4783 17.3208 17.6876 18.1204C17.6761 18.1312 17.6647 18.1421 17.6536 18.1533C17.6423 18.1646 17.6313 18.176 17.6205 18.1875C16.8581 18.9416 15.9536 19.5529 14.9476 19.9805C12.8569 20.869 10.4989 20.8906 8.39223 20.0405C6.28558 19.1905 4.60291 17.5384 3.71438 15.4476C2.82585 13.3569 2.80424 10.9989 3.65431 8.89223C4.50438 6.78558 6.1565 5.10291 8.24721 4.21438Z"
        fill={fill}
      />
    </svg>
  );

  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8437 1.57442C13.0528 0.448252 9.92884 0.476877 7.15908 1.654C4.38931 2.83112 2.20059 5.06032 1.07442 7.8512C-0.0517482 10.6421 -0.0231222 13.766 1.154 16.5358C2.33113 19.3055 4.56032 21.4943 7.3512 22.6204C10.1421 23.7466 13.266 23.718 16.0358 22.5409C16.9523 22.1513 17.8053 21.6466 18.5772 21.0444L25.3753 27.8435C25.9185 28.3868 26.7993 28.3868 27.3425 27.8436C27.8858 27.3004 27.8858 26.4197 27.3427 25.8765L20.5444 19.0772C21.1889 18.2511 21.7214 17.3326 22.1204 16.3437C23.2466 13.5528 23.218 10.4288 22.0409 7.65908C20.8637 4.88931 18.6345 2.70059 15.8437 1.57442ZM8.24721 4.21438C10.3379 3.32585 12.696 3.30424 14.8026 4.15431C16.9093 5.00438 18.5919 6.6565 19.4805 8.74721C20.369 10.8379 20.3906 13.196 19.5405 15.3026C19.1116 16.3657 18.4783 17.3208 17.6876 18.1204C17.6761 18.1312 17.6647 18.1421 17.6536 18.1533C17.6423 18.1646 17.6313 18.176 17.6205 18.1875C16.8581 18.9416 15.9536 19.5529 14.9476 19.9805C12.8569 20.869 10.4989 20.8906 8.39223 20.0405C6.28558 19.1905 4.60291 17.5384 3.71438 15.4476C2.82585 13.3569 2.80424 10.9989 3.65431 8.89223C4.50438 6.78558 6.1565 5.10291 8.24721 4.21438Z"
        stroke={fill}
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

export default MagnifyingGlassIcon;
