import { StyleableOnlyIconProps } from '../types';

const MapSaved = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0343 12.838C14.8222 12.7695 14.5968 12.7524 14.3768 12.7881C14.1568 12.8238 13.9484 12.9112 13.7689 13.0433C13.5893 13.1753 13.4436 13.3481 13.344 13.5475C13.2119 13.8116 13.1662 14.1107 13.2135 14.4022C13.2607 14.6937 13.3985 14.963 13.6074 15.1718L13.6184 15.1831L16.7227 18.4211L19.8265 15.1831L19.8375 15.1719C20.0463 14.963 20.1842 14.6937 20.2315 14.4021C20.2789 14.1105 20.2334 13.8114 20.1014 13.5471C20.0018 13.3479 19.8562 13.1752 19.6767 13.0433C19.4971 12.9112 19.2888 12.8238 19.0687 12.7881C18.8487 12.7524 18.6234 12.7695 18.4113 12.838C18.1992 12.9066 18.0064 13.0245 17.8488 13.1821L17.2531 13.7779C16.9602 14.0707 16.4853 14.0707 16.1925 13.7779L15.5968 13.1822C15.4393 13.0246 15.2464 12.9066 15.0343 12.838ZM20.7725 13.2121L21.4434 12.877C21.7159 13.4228 21.8099 14.0404 21.7121 14.6426C21.6148 15.2419 21.3323 15.7957 20.9044 16.2264L17.2642 20.0239C17.1228 20.1715 16.9272 20.2549 16.7228 20.2549C16.5184 20.2549 16.3229 20.1715 16.1814 20.0239L12.5406 16.2264C12.1126 15.7958 11.83 15.2419 11.7328 14.6423C11.6351 14.0401 11.7294 13.4224 12.0023 12.8767M20.7725 13.2121L21.4434 12.877C21.2374 12.4651 20.9363 12.1076 20.5652 11.8347C20.1942 11.5619 19.7635 11.3812 19.3089 11.3074C18.8542 11.2337 18.3885 11.2691 17.9502 11.4107C17.512 11.5522 17.1137 11.7959 16.7881 12.1216C16.788 12.1216 16.7881 12.1215 16.7881 12.1216L16.7228 12.1869L16.6576 12.1217C16.6576 12.1217 16.6577 12.1217 16.6576 12.1217C16.332 11.796 15.9336 11.5522 15.4953 11.4107C15.057 11.2691 14.5914 11.2337 14.1367 11.3074C13.682 11.3812 13.2514 11.5619 12.8803 11.8347C12.5093 12.1076 12.2083 12.4647 12.0023 12.8767"
          fill="#1D1D1D"
        />
        <path
          d="M19.0396 9.5V6.78038C19.0397 6.56662 18.9756 6.35777 18.8558 6.18076C18.736 6.00376 18.5658 5.86672 18.3674 5.78735L14.0901 4.07646C13.8352 3.97451 13.5509 3.97451 13.296 4.07646L8.74361 5.89784C8.48871 5.99978 8.20437 5.99978 7.94947 5.89784L3.73355 4.21119C3.65221 4.17858 3.56409 4.16648 3.47697 4.17594C3.38985 4.18539 3.30638 4.21612 3.23393 4.2654C3.16147 4.31469 3.10224 4.38104 3.06145 4.45861C3.02066 4.53617 2.99956 4.62257 3.00001 4.7102V14.9563C2.99997 15.1701 3.064 15.3789 3.18382 15.5559C3.30365 15.7329 3.47377 15.87 3.67224 15.9493L7.94947 17.6602C8.20437 17.7622 8.48871 17.7622 8.74361 17.6602L10.7974 16.8383"
          stroke="#1D1D1D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.34668 5.22412C8.76089 5.22412 9.09668 5.55991 9.09668 5.97412V17.7365C9.09668 18.1507 8.76089 18.4865 8.34668 18.4865C7.93247 18.4865 7.59668 18.1507 7.59668 17.7365V5.97412C7.59668 5.55991 7.93247 5.22412 8.34668 5.22412Z"
          fill="#1D1D1D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6929 3.25012C14.1071 3.25012 14.4429 3.58591 14.4429 4.00012V9.34666C14.4429 9.76087 14.1071 10.0967 13.6929 10.0967C13.2787 10.0967 12.9429 9.76087 12.9429 9.34666V4.00012C12.9429 3.58591 13.2787 3.25012 13.6929 3.25012Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7121 14.6426C21.8099 14.0404 21.7159 13.4228 21.4434 12.877C21.2374 12.4651 20.9363 12.1076 20.5652 11.8347C20.1942 11.5619 19.7635 11.3812 19.3089 11.3074C18.8542 11.2337 18.3885 11.2691 17.9502 11.4107C17.512 11.5522 17.1137 11.7959 16.7881 12.1216L16.7228 12.1869L16.6576 12.1217C16.332 11.796 15.9336 11.5522 15.4953 11.4107C15.057 11.2691 14.5914 11.2337 14.1367 11.3074C13.682 11.3812 13.2514 11.5619 12.8803 11.8347C12.5093 12.1076 12.2083 12.4647 12.0023 12.8767C11.7294 13.4224 11.6351 14.0401 11.7328 14.6423C11.83 15.2419 12.1126 15.7958 12.5406 16.2264L16.1814 20.0239C16.3229 20.1715 16.5184 20.2549 16.7228 20.2549C16.9272 20.2549 17.1228 20.1715 17.2642 20.0239L20.9044 16.2264C21.3323 15.7957 21.6148 15.2419 21.7121 14.6426Z"
          fill="#1D1D1D"
        />
        <path
          d="M19.0396 9.5V6.78038C19.0397 6.56662 18.9756 6.35777 18.8558 6.18076C18.736 6.00376 18.5658 5.86672 18.3674 5.78735L14.0901 4.07646C13.8352 3.97451 13.5509 3.97451 13.296 4.07646L8.74361 5.89784C8.48871 5.99978 8.20437 5.99978 7.94947 5.89784L3.73355 4.21119C3.65221 4.17858 3.56409 4.16648 3.47697 4.17594C3.38985 4.18539 3.30638 4.21612 3.23393 4.2654C3.16147 4.31469 3.10224 4.38104 3.06145 4.45861C3.02066 4.53617 2.99956 4.62257 3.00001 4.7102V14.9563C2.99997 15.1701 3.064 15.3789 3.18382 15.5559C3.30365 15.7329 3.47377 15.87 3.67224 15.9493L7.94947 17.6602C8.20437 17.7622 8.48871 17.7622 8.74361 17.6602L10.7974 16.8383"
          stroke="#1D1D1D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.34668 5.97412V17.7365"
          stroke="#1D1D1D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6929 4.00024V9.34678"
          stroke="#1D1D1D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>;
};

export default MapSaved;
