import { NonConfigurableIconProps } from '../types';

const Circle = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Circle;
