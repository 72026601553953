import { NonConfigurableIconProps } from '../types';
import * as colorPalette from '../../styles/colors.module.scss';

const Waypoint = ({
  width = '24',
  height = '24',
  color = colorPalette.black,
}: NonConfigurableIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8.82483C4 5.05562 7.10918 2 10.9444 2H13.7222C17.5575 2 20.6667 5.05562 20.6667 8.82483V11.5548C20.6667 13.5253 19.8167 15.3009 18.4576 16.5468L13.3155 21.6003C12.7732 22.1332 11.8937 22.1332 11.3513 21.6003L6.21241 16.5499C4.85133 15.304 4 13.5269 4 11.5548V8.82483ZM12.3333 13.6667C14.1743 13.6667 15.6667 12.1743 15.6667 10.3333C15.6667 8.49238 14.1743 7 12.3333 7C10.4924 7 9 8.49238 9 10.3333C9 12.1743 10.4924 13.6667 12.3333 13.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default Waypoint;
