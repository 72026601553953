/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Compass = ({ fill }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7877 11.0968L15.7361 11.1484C15.6444 11.2173 15.5343 11.2574 15.4197 11.2637C13.2746 11.3813 10.9166 11.3727 8.74433 11.2632C8.62922 11.2574 8.51845 11.2174 8.42616 11.1484C8.33387 11.0787 8.2671 10.9806 8.23617 10.8692C8.20525 10.7577 8.2119 10.6392 8.25508 10.532L11.5679 2.34217C11.6112 2.23993 11.6838 2.15283 11.7767 2.09192C11.8695 2.03101 11.9783 1.99903 12.0893 2.00002C12.1978 2.00123 12.3035 2.03431 12.3933 2.09515C12.4831 2.15598 12.553 2.24189 12.5944 2.34217L15.8909 10.5265C15.9319 10.6212 15.944 10.7258 15.9257 10.8273C15.9073 10.9288 15.8593 11.0225 15.7877 11.0968Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0431 13.4735L11.3397 21.6578C11.381 21.7581 11.4509 21.844 11.5407 21.9049C11.6305 21.9657 11.7363 21.9988 11.8447 22C11.9557 22.001 12.0645 21.969 12.1574 21.9081C12.2502 21.8472 12.3228 21.7601 12.3661 21.6578L15.6789 13.468C15.7221 13.3608 15.7288 13.2423 15.6979 13.1308C15.6669 13.0194 15.6002 12.9213 15.5079 12.8516C15.4156 12.7826 15.3048 12.7426 15.1897 12.7368C13.0174 12.6273 10.6594 12.6187 8.5143 12.7363C8.39974 12.7426 8.28962 12.7827 8.19788 12.8516L8.14629 12.9032C8.07471 12.9775 8.02671 13.0712 8.00835 13.1727C7.98998 13.2742 8.00207 13.3788 8.0431 13.4735ZM9.94388 14.1777L11.8547 18.9219L13.7724 14.1812C12.5027 14.1434 11.2005 14.1407 9.94388 14.1777Z"
      fill={fill}
    />
  </svg>
);

Compass.propTypes = {
  fill: PropTypes.string,
};

Compass.defaultProps = {
  fill: '#1D1D1D',
};

export default Compass;
