/* eslint object-property-newline: "off" */
import { serialize } from 'object-to-formdata';
import { pick, get } from 'lodash';

import {
  makeRequest,
  makeAuthenticatedRequest,
  waitForRequest,
} from './utils/request';
import { getAPIURL, getParcelsAPIURL } from './utils/domains';
import layersApi from './layers.js';
import subscriptions from './subscriptions.js';
import transactions from './transactions.js';
import plans from './plans';

export const loadMap = (id, options = {}) => {
  let withExport = '';

  if (get(options, 'export', false)) {
    withExport = '?export=true';
  }

  return makeAuthenticatedRequest(
    `${getAPIURL()}/maps/${id}.json${withExport}`,
  );
};

export const loadShareMap = (slug) =>
  makeAuthenticatedRequest(`${getAPIURL()}/maps/${slug}/share.json`);

export const loadHomeMap = (defaultState) =>
  makeAuthenticatedRequest(`${getAPIURL()}/get_or_create_home_map.json`, {
    method: 'POST',
    body: JSON.stringify({
      maps: {
        geoJSON: '[]',
        name: 'Home Map',
        // TODO: define if this is the default state or
        // we will add a new screen to select the state.
        state: defaultState ?? 'Texas',
      },
    }),
  });

export const loadCounties = (state) =>
  makeAuthenticatedRequest(`${getAPIURL()}/states/${state}/counties.json`);

export const loadParcelSuggestions = (state, fips_codes, type) => {
  const path = `${getParcelsAPIURL()}/parcels/parcels/suggestions.json`;
  const params = `?state=${state}&fips_codes=${fips_codes}&type=${type}&raw=true`;

  return makeAuthenticatedRequest(`${path}${params}`, { timeout: 15000 });
};

export const mergeMap = (
  map,
  mapToMerge,
  layer,
  folderId,
  SEOEnabled,
  name,
  description,
  state,
) =>
  makeAuthenticatedRequest(`${getAPIURL()}/maps/merge.json`, {
    method: 'POST',
    body: JSON.stringify({
      map_a_id: map.id,
      map_b_id: mapToMerge.id,
      seo_enabled: SEOEnabled,
      folder_id: folderId,
      layer,
      name,
      description,
      state,
    }),
  });

export const checkMergeStatus = (id1, id2) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/maps/merge_status.json?map_a_id=${id1}&map_b_id=${id2}`,
  );

export const saveMap = (map, toolsChanges) => {
  const {
    geoJSON,
    lat,
    lng,
    layer,
    custom_layers,
    shared_layers,
    name,
    state,
    county,
    acres,
    description,
    export_on_share_mode,
    shared_enabled_layers,
    video,
    auto_play_video,
    display_creator_logo,
    display_creator_info,
    share_options_saved,
    active_filter_id,
    active_filter_type,
    photos,
    outside_usa,
    documents,
    custom_message,
    seo_enabled,
    streets_on,
  } = map;

  return makeAuthenticatedRequest(`${getAPIURL()}/maps/${map.id}.json`, {
    method: 'PATCH',
    body: JSON.stringify({
      maps: {
        geoJSON: JSON.stringify(geoJSON),
        lat,
        lng,
        layer,
        custom_layers,
        shared_layers,
        name,
        state,
        county,
        acres,
        description,
        export_on_share_mode,
        shared_enabled_layers,
        video,
        auto_play_video,
        display_creator_logo,
        display_creator_info,
        photos,
        share_options_saved,
        active_filter_id,
        active_filter_type,
        outside_usa,
        documents,
        custom_message,
        seo_enabled,
        streets_on,
      },
      tools_changes: toolsChanges,
    }),
  });
};

export const saveMapProperties = (map, properties) =>
  makeAuthenticatedRequest(`${getAPIURL()}/maps/${map.id}.json`, {
    method: 'PATCH',
    body: JSON.stringify({
      maps: pick(map, properties),
      options: {
        update_timestamps: false,
      },
    }),
  });

export const saveReport = (report) =>
  makeAuthenticatedRequest(`${getAPIURL()}/reports/${report.id}.json`, {
    method: 'PATCH',
    body: JSON.stringify(report),
  });

export const setCustomToolData = (
  map,
  maprightId,
  toolId,
  toolType,
  customData,
) => {
  const url = `${getAPIURL()}/custom_tool_data/${map.id}.json`;
  return makeAuthenticatedRequest(url, {
    method: 'PATCH',
    body: JSON.stringify({
      mapright_id: maprightId,
      toolId,
      toolType,
      custom_data: customData,
    }),
  });
};

export const setPanoPointCustomData = (map, maprightId, toolId, toolType) => {
  const url = `${getAPIURL()}/custom_tool_data/${map.id}/set_panopoint_data.json`;
  return makeAuthenticatedRequest(url, {
    method: 'PATCH',
    body: JSON.stringify({
      mapright_id: maprightId,
      toolId,
      toolType,
      custom_data: {},
    }),
  });
};

export const sendPanopointRequest = (map, maprightId) => {
  const url = `${getAPIURL()}/panopoint_request.json`;
  const mapId = map ? map.id : null;

  return makeAuthenticatedRequest(url, {
    method: 'POST',
    body: JSON.stringify({
      map_id: mapId,
      mapright_id: maprightId,
    }),
  });
};

export const createMap = (map, toolsChanges) => {
  const {
    geoJSON,
    lat,
    lng,
    layer,
    custom_layers,
    shared_layers,
    name,
    state,
    county,
    acres,
    description,
    export_on_share_mode,
    shared_enabled_layers,
    video,
    auto_play_video,
    display_creator_logo,
    display_creator_info,
    report,
    photos,
    active_filter_id,
    active_filter_type,
    outside_usa,
    documents,
    custom_message,
    seo_enabled,
    streets_on,
  } = map;

  return makeAuthenticatedRequest(`${getAPIURL()}/maps.json`, {
    method: 'POST',
    body: JSON.stringify({
      soil_report_id: report ? report.id : null,
      maps: {
        geoJSON: JSON.stringify(geoJSON),
        lat,
        lng,
        layer,
        custom_layers,
        shared_layers,
        name,
        state,
        county,
        acres,
        description,
        export_on_share_mode,
        shared_enabled_layers,
        video,
        auto_play_video,
        display_creator_logo,
        display_creator_info,
        photos,
        active_filter_id,
        active_filter_type,
        outside_usa,
        documents,
        custom_message,
        seo_enabled,
        streets_on,
      },
      tools_changes: toolsChanges,
    }),
  });
};

export const duplicateMap = (map, toolsChanges) => {
  const {
    geoJSON,
    lat,
    lng,
    layer,
    custom_layers,
    shared_layers,
    name,
    state,
    county,
    acres,
    description,
    export_on_share_mode,
    shared_enabled_layers,
    video,
    auto_play_video,
    display_creator_logo,
    display_creator_info,
    photos,
    active_filter_id,
    active_filter_type,
    outside_usa,
    documents,
    custom_message,
    seo_enabled,
    streets_on,
  } = map;

  return waitForRequest(
    () =>
      makeAuthenticatedRequest(
        `${getAPIURL()}/${map.toolbox_slug}/maps/${map.id}/save_as_new.json`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: map.id,
            toolbox_slug: map.toolbox_slug,
            maps: {
              geoJSON: JSON.stringify(geoJSON),
              lat,
              lng,
              layer,
              custom_layers,
              shared_layers,
              name,
              state,
              county,
              acres,
              description,
              export_on_share_mode,
              shared_enabled_layers,
              video,
              auto_play_video,
              display_creator_logo,
              display_creator_info,
              photos,
              active_filter_id,
              active_filter_type,
              outside_usa,
              documents,
              custom_message,
              seo_enabled,
              streets_on,
            },
            tools_changes: toolsChanges,
          }),
        },
      ),
    (jobId) =>
      makeAuthenticatedRequest(
        `${getAPIURL()}/${map.toolbox_slug}/maps/${map.id}/save_as_new.json?job_id=${jobId}`,
        { method: 'POST' },
      ),
  );
};

export const importMap = (map, modifications) => {
  const { geoJSON, name } = map;

  return waitForRequest(
    () =>
      makeAuthenticatedRequest(
        `${getAPIURL()}/create_map_from_gis_import.json`,
        {
          method: 'POST',
          body: JSON.stringify({
            id: map.id,
            toolbox_slug: map.toolbox_slug,
            maps: {
              geoJSON: JSON.stringify(geoJSON),
              name,
            },
            modifications,
          }),
        },
      ),
    (jobId) =>
      makeAuthenticatedRequest(
        `${getAPIURL()}/create_map_from_gis_import.json?job_id=${jobId}`,
        { method: 'POST' },
      ),
  );
};

export const updateMapFromImport = (map, modifications) => {
  const { geoJSON, name } = map;

  return waitForRequest(
    () =>
      makeAuthenticatedRequest(
        `${getAPIURL()}/update_map_from_gis_import.json?map_id=${map.id}`,
        {
          method: 'POST',
          body: JSON.stringify({
            maps: {
              geoJSON: JSON.stringify(geoJSON),
              name,
            },
            modifications,
          }),
        },
      ),
    (jobId) =>
      makeAuthenticatedRequest(
        `${getAPIURL()}/update_map_from_gis_import.json?job_id=${jobId}`,
        { method: 'POST' },
      ),
  );
};

export const deleteMap = (map) =>
  makeAuthenticatedRequest(`${getAPIURL()}/maps/${map.id}.json`, {
    method: 'DELETE',
  });

export const loadDashboard = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/dashboard/load_maps.json`);

export const loadFolders = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/dashboard/load_folders.json`);

export const createFolder = (name, parentFolder) =>
  makeAuthenticatedRequest(`${getAPIURL()}/folders.json`, {
    method: 'POST',
    body: JSON.stringify({
      name,
      folder_id: parentFolder ? parentFolder.id : null,
      subfolder: !!parentFolder,
    }),
  });

export const deleteFolder = (folder) =>
  makeAuthenticatedRequest(`${getAPIURL()}/folders.json`, {
    method: 'DELETE',
    body: JSON.stringify({ id: folder.id }),
  });

export const moveMapToFolder = (folderId, mapIds) => {
  const ids = Array.isArray(mapIds) ? mapIds : [mapIds];

  return makeAuthenticatedRequest(`${getAPIURL()}/folders/add_maps.json`, {
    method: 'POST',
    body: JSON.stringify({ id: folderId, maps_ids: ids }),
  });
};

export const emptyFolder = (folderId, mapIds) => {
  const ids = Array.isArray(mapIds) ? mapIds : [mapIds];

  return makeAuthenticatedRequest(`${getAPIURL()}/folders/remove_maps.json`, {
    method: 'DELETE',
    body: JSON.stringify({ id: folderId, maps_ids: ids }),
  });
};

export const createGeoreference = (map, image) =>
  makeAuthenticatedRequest(`${getAPIURL()}/maps/${map.id}/georeference.json`, {
    method: 'POST',
    body: serialize({ photo: { image } }),
  });

export const sendShareEmails = (mapId, emails) =>
  makeAuthenticatedRequest(`${getAPIURL()}/maps/${mapId}/share.json`, {
    method: 'POST',
    body: JSON.stringify({ emails }),
  });

export const loadMapPrints = (
  currentPage,
  pageLimit,
  searchQuery,
  userTimezone,
) => {
  const query =
    `?currentPage=${currentPage}&userTimezone=${encodeURIComponent(userTimezone)}` +
    `&pageLimit=${pageLimit}&searchQuery=${encodeURIComponent(searchQuery)}`;
  return makeAuthenticatedRequest(
    `${getAPIURL()}/map_prints/load_prints.json${query}`,
    {
      method: 'GET',
    },
  );
};

export const loadParcelReports = (
  currentPage,
  pageLimit,
  searchQuery,
  userTimezone,
) => {
  const query =
    `?currentPage=${currentPage}&userTimezone=${encodeURIComponent(userTimezone)}` +
    `&pageLimit=${pageLimit}&searchQuery=${encodeURIComponent(searchQuery)}`;
  return makeAuthenticatedRequest(
    `${getAPIURL()}/parcels/reports_info${query}`,
    {
      method: 'GET',
    },
  );
};

export const authenticate = (token, ga_client_id, kochava_device_id) =>
  makeRequest(`${getAPIURL()}/authenticate.json`, {
    method: 'POST',
    body: JSON.stringify({
      token,
    }),
    headers: {
      'DEVICE-ID': navigator.productSub,
      'KOCHAVA-DEVICE-ID': kochava_device_id,
    },
  });

export const signInWithGoogle = (code, ga_client_id, kochava_device_id) =>
  makeRequest(`${getAPIURL()}/auth/oauth`, {
    method: 'POST',
    body: JSON.stringify({
      oauth_provider: 'google',
      code,
    }),
    headers: {
      'Content-Type': 'application/json',
      'DEVICE-ID': navigator.productSub,
      'KOCHAVA-DEVICE-ID': kochava_device_id,
    },
  });

export const signInWithApple = (code, ga_client_id, kochava_device_id, name) =>
  makeRequest(`${getAPIURL()}/auth/oauth`, {
    method: 'POST',
    body: JSON.stringify({
      oauth_provider: 'apple',
      code,
      name,
    }),
    headers: {
      'Content-Type': 'application/json',
      'DEVICE-ID': navigator.productSub,
      'KOCHAVA-DEVICE-ID': kochava_device_id,
    },
  });

export const signIn = (email, password, ga_client_id, kochava_device_id) =>
  makeRequest(`${getAPIURL()}/users/sign_in.json`, {
    method: 'POST',
    body: JSON.stringify({
      user: { email, password, ga_client_id },
    }),
    headers: {
      'DEVICE-ID': navigator.productSub,
      'KOCHAVA-DEVICE-ID': kochava_device_id,
    },
  });

export const signOut = () =>
  makeRequest(`${getAPIURL()}/users/sign_out.json`, {
    method: 'DELETE',
  });

export const embedSignIn = (email, auth_token) =>
  makeRequest(`${getAPIURL()}/users/sign_in.json`, {
    method: 'POST',
    body: JSON.stringify({
      user: { email, auth_token },
    }),
  });

export const impersonate = (userId, adminToken) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/impersonate_user/${userId}/${adminToken}.json`,
    {
      method: 'POST',
      body: JSON.stringify({ user_id: userId, token: adminToken }),
    },
  );

export const signUp = (
  first_name,
  last_name,
  email,
  password,
  reCaptchaToken,
  usage_tracking,
  ga_client_id,
  plan,
) =>
  makeRequest(`${getAPIURL()}/users.json`, {
    method: 'POST',
    headers: {
      'Re-Captcha-Token': reCaptchaToken,
    },
    body: JSON.stringify({
      user: {
        first_name,
        last_name,
        email,
        password,
        usage_tracking,
        ga_client_id,
      },
      subscription: { plan },
    }),
  });

export const sendResetPasswordInstructions = (email) =>
  makeRequest(`${getAPIURL()}/password/forgot.json`, {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  });

export const resetPassword = (password, token) =>
  makeRequest(`${getAPIURL()}/password/reset.json`, {
    method: 'POST',
    body: JSON.stringify({
      token,
      password,
    }),
  });

export const getUser = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/profile.json`, {
    method: 'GET',
    headers: {
      'DEVICE-ID': navigator.productSub,
    },
  });

export const sendEmailVerification = () => {
  const url = `${getAPIURL()}/users/send_email_verification`;
  return makeAuthenticatedRequest(url, { method: 'POST' });
};

export const updateUser = (user) =>
  makeAuthenticatedRequest(`${getAPIURL()}/profile.json`, {
    method: 'PATCH',
    body: JSON.stringify({ user }),
  });

export const updateUserLogo = (user) =>
  makeAuthenticatedRequest(`${getAPIURL()}/profile.json`, {
    method: 'PATCH',
    body: serialize({ user: { avatar: user.avatar } }),
  });

export const sendSoilsReportQuery = (polygons, state) =>
  makeAuthenticatedRequest(`${getAPIURL()}/soils/find_by_intersects.json`, {
    method: 'POST',
    body: JSON.stringify({ wkt_polygons: polygons, state }),
  });

export const saveSoilsReport = (soilsReport, mapId, selectedPolygonsArea) =>
  waitForRequest(
    () =>
      makeAuthenticatedRequest(`${getAPIURL()}/reports.json`, {
        method: 'POST',
        body: JSON.stringify({
          report: {
            geoJSON: soilsReport,
            map_id: mapId,
            total_area: selectedPolygonsArea,
          },
        }),
      }),
    (jobId) =>
      makeAuthenticatedRequest(`${getAPIURL()}/reports.json?job_id=${jobId}`, {
        method: 'POST',
      }),
  );

export const deleteSoilsReport = (reportId) =>
  makeAuthenticatedRequest(`${getAPIURL()}/reports/${reportId}.json`, {
    method: 'DELETE',
    body: JSON.stringify({ id: reportId }),
  });

export const searchAbstract = (number, county) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/abstracts/find.json?abstract=${number}&county=${county}`,
    { method: 'GET' },
  );

export const searchTRS = (state, county, twp, twpDir, rng, rngDir, sec) => {
  const query = `?state=${state}&county=${county}&twp=${twp}&twp_dir=${twpDir}&rng=${rng}&rng_dir=${rngDir}&sec=${sec}`;
  return makeAuthenticatedRequest(`${getAPIURL()}/trs/find.json${query}`, {
    method: 'GET',
  });
};

export const searchAPN = (apn, state, county = null, fips_codes = null) => {
  const params = new URLSearchParams();

  params.append('state', state);
  params.append('apn', apn);

  if (county) {
    const formattedCounty = county
      ?.replace(' County', '')
      .replace(' Borough', '')
      .replace(' Census Area', '')
      .replace(' Municipality', '')
      .replace(' Parish', '');

    params.append('county', formattedCounty);
  }

  if (fips_codes) {
    params.append('fips_codes', fips_codes);
  }

  return makeAuthenticatedRequest(
    `${getParcelsAPIURL()}/parcels/v2/by_apn.json?${params}`,
  );
};

export const searchByAttribute = (ids, state, fipsCodes, attribute, page) => {
  const parameters = `state=${state}&ids=${ids}&fips_codes=${fipsCodes}&attribute=${attribute}&page=${page}`;

  return makeAuthenticatedRequest(
    `${getParcelsAPIURL()}/parcels/v2/search.json?${parameters}`,
  );
};

export const uploadPhoto = (file, mapId) => {
  const url = mapId ? `maps/${mapId}/photos.json` : 'photos.json';

  return makeAuthenticatedRequest(`${getAPIURL()}/${url}`, {
    method: 'POST',
    body: serialize({ file }),
  });
};

export const uploadDocument = (document, mapId, belongsToTool) => {
  const url = `maps/${mapId}/documents.json`;
  return makeAuthenticatedRequest(`${getAPIURL()}/${url}`, {
    method: 'POST',
    body: serialize({
      document: { ...document, belongs_to_tool: belongsToTool },
    }),
  });
};

export const deleteDocument = (id, map_id) => {
  const url = `maps/${map_id}/documents/${id}.json`;
  return makeAuthenticatedRequest(`${getAPIURL()}/${url}`, {
    method: 'DELETE',
  });
};

export const updateDocument = (document, id, map_id) => {
  const url = `maps/${map_id}/documents/${id}.json`;
  return makeAuthenticatedRequest(`${getAPIURL()}/${url}`, {
    method: 'PATCH',
    body: serialize({ document }),
  });
};

export const sendShareMapEmails = (
  customers_emails,
  custom_message,
  map_id,
) => {
  const url = 'customer_shared_maps';
  return makeAuthenticatedRequest(`${getAPIURL()}/${url}.json`, {
    method: 'POST',
    body: JSON.stringify({ customers_emails, custom_message, map_id }),
  });
};

export const fetchShareMapEmails = (map_id) => {
  const url = 'customer_shared_maps/customers_index';
  return makeAuthenticatedRequest(
    `${getAPIURL()}/${url}.json?map_id=${map_id}`,
    {
      method: 'GET',
    },
  );
};

export const deleteShareMapEmail = (customers_emails, map_id) => {
  const url = 'customer_shared_maps';
  return makeAuthenticatedRequest(`${getAPIURL()}/${url}.json`, {
    method: 'DELETE',
    body: JSON.stringify({ customers_emails, map_id }),
  });
};

export const saveMapImage = (printId, zoom, center, mapType, size) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/google_images/save_google_image.json`,
    {
      method: 'POST',
      body: JSON.stringify({
        print_id: printId,
        zoom,
        center,
        mapType,
        size,
      }),
    },
  );

export const saveLabelsImage = (
  printId,
  mapId,
  zoom,
  center,
  size,
  labelsSources,
  labelsLayers,
) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/labels_image/create_labels_image.json`,
    {
      method: 'POST',
      body: JSON.stringify({
        zoom,
        center,
        size,
        map_id: mapId,
        print_id: printId,
        labels_layers: labelsLayers,
        labels_sources: labelsSources,
      }),
    },
  );

export const checkLabelsImageStatus = (requestId) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/labels_image/check_labels_image_status.json?request_id=${requestId}`,
  );

export const saveCanvasImage = (printId, image) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/google_images/save_canvas_image.json`,
    {
      method: 'POST',
      body: serialize({ image, print_id: printId }),
    },
  );

export const checkPrintStatus = (map, printId) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/maps/${map.slug}/print_link.json?map_id=${map.id}&print_id=${printId}`,
  );

export const getMapFilters = (id, share = false) => {
  const mapUri = id ? `maps/${id}/` : '';
  const uri =
    share ?
      `${getAPIURL()}/${mapUri}filters/share.json`
    : `${getAPIURL()}/${mapUri}filters.json`;

  if (share) {
    return makeRequest(uri, {
      method: 'GET',
    });
  }

  return makeAuthenticatedRequest(uri, {
    method: 'GET',
  });
};

export const mapLastModified = (map) => {
  const url = `${getAPIURL()}/maps/${map.id}/last_modified.json`;
  return makeAuthenticatedRequest(url, { method: 'GET' });
};

export const getReportMeta = async (wktPolygon, filters, intervalTime) => {
  const reportMetaPromise = new Promise((resolve, reject) => {
    const INTERVAL_TIME = intervalTime || 3000;

    makeAuthenticatedRequest(`${getAPIURL()}/parcels/report_meta.json`, {
      method: 'POST',
      body: JSON.stringify({
        wkt_polygon: wktPolygon,
        filters,
      }),
    })
      .then((data) => {
        const interval = setInterval(() => {
          makeAuthenticatedRequest(
            `${getAPIURL()}/parcels/report_meta.json?job_id=${data.job_id}`,
            { method: 'POST' },
          )
            .then((meta) => {
              if (meta.ready) {
                resolve(meta);
                clearInterval(interval);
              }
            })
            .catch((error) => {
              reject(error);
              clearInterval(interval);
            });
        }, INTERVAL_TIME);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return reportMetaPromise;
};

export const sendParcelsReportRequest = (
  mapId,
  wktPolygon,
  email,
  parcelIds,
  filters,
  paymentMethodUuid,
) =>
  makeAuthenticatedRequest(`${getAPIURL()}/parcels/build_csv_report.json`, {
    method: 'POST',
    body: JSON.stringify({
      map_id: mapId,
      wkt_polygon: wktPolygon,
      email,
      parcel_ids: parcelIds,
      filters,
      payment_method_uuid: paymentMethodUuid,
    }),
  });

export const reexecuteParcelsReportRequest = (
  parcelReportId,
  paymentMethodUuid,
) =>
  makeAuthenticatedRequest(`${getAPIURL()}/parcels/reexecute_csv_report.json`, {
    method: 'POST',
    body: JSON.stringify({
      parcel_report_id: parcelReportId,
      payment_method_uuid: paymentMethodUuid,
    }),
  });

export const parseShapefile = (shps) =>
  makeAuthenticatedRequest(
    `${process.env.MAPRIGHT_MICROSERVICES_URL}/api/v1/shape_files/parse.json`,
    {
      method: 'POST',
      body: serialize({ shps }),
      headers: { 'Accept-Encoding': 'gzip' },
    },
  );

export const downloadMapAs = (data, fileType, geomType) => {
  const endpoint = fileType === 'kml' ? 'kml_files' : 'shape_files';
  const { geoJSON, name } = data;
  return makeAuthenticatedRequest(
    `${process.env.MAPRIGHT_MICROSERVICES_URL}/api/v1/${endpoint}/encode.json`,
    {
      method: 'POST',
      body: JSON.stringify({
        geojson: {
          type: 'FeatureCollection',
          features: geoJSON,
          name,
        },
        geom_type: geomType,
      }),
      blob: true,
    },
  );
};

export const fetchDefaultFilters = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/ranching/filters/default.json`);

export const getUsageTrackingOptions = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/usage_tracking_options.json`, {
    method: 'GET',
  });

export const getCoupon = (couponCode, planCode) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/coupons/find_by_code/${couponCode}?${new URLSearchParams({ planCode })}`,
    {
      method: 'GET',
    },
  );

export default {
  loadMap,
  loadCounties,
  loadParcelSuggestions,
  loadShareMap,
  loadHomeMap,
  mergeMap,
  checkMergeStatus,
  saveMap,
  saveMapProperties,
  saveReport,
  duplicateMap,
  createMap,
  deleteMap,
  loadDashboard,
  loadMapPrints,
  loadParcelReports,
  loadFolders,
  createFolder,
  deleteFolder,
  moveMapToFolder,
  emptyFolder,
  createGeoreference,
  sendShareEmails,
  authenticate,
  signInWithGoogle,
  signInWithApple,
  signIn,
  signOut,
  embedSignIn,
  sendResetPasswordInstructions,
  resetPassword,
  impersonate,
  signUp,
  getUser,
  sendEmailVerification,
  updateUser,
  updateUserLogo,
  sendSoilsReportQuery,
  saveSoilsReport,
  deleteSoilsReport,
  searchAbstract,
  searchTRS,
  searchAPN,
  searchByAttribute,
  uploadPhoto,
  uploadDocument,
  deleteDocument,
  updateDocument,
  saveMapImage,
  saveCanvasImage,
  saveLabelsImage,
  checkLabelsImageStatus,
  checkPrintStatus,
  layersApi,
  subscriptions,
  getMapFilters,
  mapLastModified,
  setCustomToolData,
  setPanoPointCustomData,
  sendPanopointRequest,
  sendParcelsReportRequest,
  reexecuteParcelsReportRequest,
  getReportMeta,
  parseShapefile,
  fetchDefaultFilters,
  sendShareMapEmails,
  fetchShareMapEmails,
  deleteShareMapEmail,
  downloadMapAs,
  importMap,
  updateMapFromImport,
  getUsageTrackingOptions,
  getCoupon,
  transactions,
  plans,
};
