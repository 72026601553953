/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Convert = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <path
      d="M5.18514 15.5H12.7769C13.0286 15.5 13.27 15.3987 13.448 15.2184C13.6259 15.038 13.7259 14.7935 13.7259 14.5385C13.7259 14.2834 13.6259 14.0389 13.448 13.8586C13.27 13.6782 13.0286 13.5769 12.7769 13.5769H5.18514C4.83339 13.5749 4.49662 13.4324 4.2479 13.1804C3.99918 12.9284 3.85856 12.5872 3.85657 12.2308V8.96154C3.85657 8.91054 3.87657 8.86162 3.91216 8.82556C3.94775 8.78949 3.99603 8.76923 4.04636 8.76923H4.80555C4.88399 8.77763 4.96303 8.76054 5.03126 8.72044C5.09949 8.68035 5.15338 8.61931 5.18514 8.54615C5.21821 8.48078 5.23084 8.40674 5.22134 8.33391C5.21185 8.26107 5.18068 8.1929 5.13199 8.13846L3.23404 5.83077C3.19916 5.78735 3.15517 5.75236 3.10527 5.72832C3.05536 5.70428 3.00081 5.69181 2.94555 5.69181C2.8903 5.69181 2.83574 5.70428 2.78584 5.72832C2.73594 5.75236 2.69195 5.78735 2.65706 5.83077L0.75911 8.13846C0.71042 8.1929 0.679253 8.26107 0.669759 8.33391C0.660265 8.40674 0.672896 8.48078 0.705968 8.54615C0.732546 8.60726 0.774668 8.66012 0.828025 8.69931C0.881382 8.73851 0.944058 8.76264 1.00964 8.76923H1.76882C1.81916 8.76923 1.86743 8.78949 1.90303 8.82556C1.93862 8.86162 1.95862 8.91054 1.95862 8.96154V12.2308C1.96062 13.0972 2.3012 13.9276 2.90586 14.5402C3.51051 15.1529 4.33003 15.498 5.18514 15.5Z"
      fill={fill}
    />
    <path
      d="M15.0545 10.3077C15.1097 10.3078 15.1642 10.2954 15.2141 10.2714C15.264 10.2475 15.308 10.2126 15.343 10.1692L17.2409 7.86154C17.2896 7.8071 17.3208 7.73893 17.3303 7.66609C17.3398 7.59326 17.3271 7.51922 17.2941 7.45385C17.2646 7.38709 17.2166 7.33046 17.156 7.29086C17.0953 7.25125 17.0246 7.23037 16.9524 7.23077H16.1933C16.1429 7.23077 16.0946 7.21051 16.0591 7.17444C16.0235 7.13838 16.0035 7.08946 16.0035 7.03846V3.76923C16.0035 2.90218 15.6635 2.07064 15.0584 1.45754C14.4534 0.844436 13.6327 0.5 12.7769 0.5H5.18514C4.93345 0.5 4.69208 0.601305 4.51411 0.781628C4.33614 0.961952 4.23616 1.20652 4.23616 1.46154C4.23616 1.71655 4.33614 1.96113 4.51411 2.14145C4.69208 2.32177 4.93345 2.42308 5.18514 2.42308H12.7769C13.1293 2.42308 13.4672 2.5649 13.7164 2.81736C13.9655 3.06981 14.1055 3.41221 14.1055 3.76923V7.03846C14.1055 7.08946 14.0855 7.13838 14.0499 7.17444C14.0143 7.21051 13.9661 7.23077 13.9157 7.23077H13.1565C13.0844 7.23037 13.0137 7.25125 12.953 7.29086C12.8923 7.33046 12.8444 7.38709 12.8149 7.45385C12.7818 7.51922 12.7692 7.59326 12.7787 7.66609C12.7882 7.73893 12.8194 7.8071 12.868 7.86154L14.766 10.1692C14.8009 10.2126 14.8449 10.2475 14.8948 10.2714C14.9447 10.2954 14.9993 10.3078 15.0545 10.3077Z"
      fill={fill}
    />
  </svg>
);

Convert.propTypes = {
  fill: PropTypes.string,
};

Convert.defaultProps = {
  fill: '#FFFFFF',
};

export default Convert;
