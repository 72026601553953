/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const Alert = ({ fill, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
  >
    <path
      d="M6.00057 1.50094C4.66217 1.52182 3.38564 2.06805 2.4464 3.02177C1.9789 3.4892 1.61004 4.04576 1.36167 4.65843C1.11331 5.27111 0.990518 5.92741 1.00057 6.58844C1.00002 7.23406 1.12685 7.87345 1.37379 8.46998C1.62074 9.06651 1.98294 9.60847 2.43966 10.0648C2.89638 10.5211 3.43864 10.8829 4.03538 11.1293C4.63212 11.3757 5.27162 11.502 5.91724 11.5009H6.00057C7.3381 11.4888 8.61609 10.9462 9.55367 9.99217C10.4912 9.03819 11.0117 7.75097 11.0006 6.41344C11.0018 5.76057 10.8727 5.11402 10.6207 4.51172C10.3688 3.90942 9.99914 3.36348 9.53344 2.90592C9.06773 2.44837 8.51535 2.0884 7.9087 1.84714C7.30204 1.60588 6.65332 1.48818 6.00057 1.50094ZM5.37557 8.3926C5.37216 8.30884 5.38587 8.22525 5.41586 8.14696C5.44585 8.06867 5.49148 7.99732 5.54998 7.93727C5.60848 7.87721 5.6786 7.82971 5.75608 7.79767C5.83355 7.76564 5.91674 7.74973 6.00057 7.75094C6.16468 7.75188 6.32211 7.81608 6.44007 7.93017C6.55804 8.04426 6.62747 8.19945 6.6339 8.36344C6.63728 8.44595 6.62393 8.52829 6.59466 8.60552C6.56539 8.68274 6.52081 8.75324 6.46359 8.81279C6.40638 8.87234 6.33771 8.91971 6.26172 8.95204C6.18573 8.98437 6.10399 9.00101 6.0214 9.00094C5.85583 9.00327 5.69584 8.94109 5.5753 8.82755C5.45476 8.71401 5.38313 8.55802 5.37557 8.3926ZM5.5839 6.72594V4.22594C5.5839 4.11543 5.6278 4.00945 5.70594 3.93131C5.78408 3.85317 5.89006 3.80927 6.00057 3.80927C6.11108 3.80927 6.21706 3.85317 6.2952 3.93131C6.37334 4.00945 6.41724 4.11543 6.41724 4.22594V6.72594C6.41724 6.83644 6.37334 6.94242 6.2952 7.02056C6.21706 7.0987 6.11108 7.1426 6.00057 7.1426C5.89006 7.1426 5.78408 7.0987 5.70594 7.02056C5.6278 6.94242 5.5839 6.83644 5.5839 6.72594Z"
      fill={fill}
    />
  </svg>
);

Alert.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Alert.defaultProps = {
  fill: colorPalette.redDark,
  width: '12',
  height: '13',
};

export default Alert;
