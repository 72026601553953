import Account from './Account';
import AccountUser from './AccountDetails/AccountUser';
import Acres from './Acres';
import Add from './Add';
import AddBold from './GeoreferenceIcons/AddBold';
import AddBoundary from './AddBoundary';
import Alert from './Alert';
import AmexCC from './Amex';
import AppleLogo from './AppleLogo';
import Area from './ToolIcons/Area';
import Arrow from './Arrow';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import Arrows360 from './Arrows360';
import BaseMap from './BaseMap';
import BoldStyle from './LabelsToolsIcons/BoldStyle';
import Boundary from './Boundary';
import Buffer from './ToolIcons/Buffer';
import Camera from './Camera';
import Check from './Check';
import CheckCircle from './CheckCircle';
import Chevron from './Chevron';
import ChevronBold from './ChevronBold';
import CloseCircle from './CloseCircle';
import CollapseExpand from './CollapseExpand';
import Company from './AccountDetails/Company';
import Compass from './Compass';
import Convert from './Convert';
import Copy from './Copy';
import CreditCard from './CreditCard';
import Csv from './Csv';
import Cut from './ToolIcons/Cut';
import DeedArch from './DeedArch';
import deedDrawer from './DeedDrawer';
import Discover from './NavBarDiscover';
import DiscoverCC from './Discover';
import Document from './Document';
import DocumentPlus from './DocumentPlus';
import Dots from './Dots';
import Download from './Download';
import DownloadAction from './DownloadAction';
import Drag from './Drag';
import Drawer from './Drawer';
import Duplicate from './Duplicate';
import Ellipsis from './Ellipsis';
import Email from './Email';
import ErrorImage from './ErrorImage';
import Expand from './Expand';
import ExpandFull from './ExpandFull';
import Eye from './Eye';
import EyeSlash from './EyeSlash';
import EyeSlashUnfilled from './EyeSlashUnfilled';
import EyeUnfilled from './EyeUnfilled';
import FarmRanch from './FarmRanch';
import Filter from './Filter';
import Folder from './Folder';
import FolderEmpty from './FolderEmpty';
import FolderOpen from './FolderOpen';
import Frame from './Frame';
import Georeference from './ToolIcons/Georeference';
import GeoXMark from './GeoreferenceIcons/xMark';
import GrayWaypointMarker from './GrayWaypointMarker';
import HelpQuestionMark from './HelpQuestionMark';
import Helper from './Helper';
import Icon360 from './Icon360';
import Images from './Images';
import Indent from './Indent';
import Information from './Information';
import ItalicStyle from './LabelsToolsIcons/ItalicStyle';
import Landscape from './Landscape';
import LandId from './LandId';
import LandIdDark from './LandIdDark';
import LandIdFull from './LandIdFull';
import Layers from './Layers';
import Legend from './Legend';
import Lightbulb from './Lightbulb';
import Line from './Line';
import LocationTarget from './LocationTarget';
import LocationTargetMap from './LocationTargetMap';
import Locked from './GeoreferenceIcons/Locked';
import Logo from './AccountDetails/Logo';
import Lowercase from './LabelsToolsIcons/Lowercase';
import MagnifyingGlass from './MagnifyingGlass';
import MastercardCC from './Mastercard';
import MailBox from './MailBox';
import Map from './Map';
import Measurement from './Measurement';
import Merge from './Merge';
import Message from './Message';
import Move from './Move';
import NaturalResources from './NaturalResources';
import NoFillBox from './NoFillBox';
import OpenMap from './OpenMap';
import OptionVertical from './OptionVertical';
import Overlay from './Overlay';
import Overview from './Overview';
import Panorama from './Panorama';
import Parcel from './Parcel';
import ParcelIcon from './ParcelIcon';
import Pencil from './Pencil';
import Phone from './Phone';
import Photo from './Photo';
import Pin from './Pin';
import PinAdd from './PinAdd';
import PinPlus from './PinPlus';
import PlainWaypoint from './PlainWaypoint';
import PlaystoreLogo from './PlaystoreLogo';
import Plus from './Plus';
import Portrait from './Portrait';
import Print from './Print';
import PrintsAndReports from './NavBarPrintsAndReports';
import Profile from './Profile';
import QuestionMark from './QuestionMark';
import Ranching from './Ranching';
import RealEstate from './RealEstate';
import RecreationFun from './RecreationFun';
import Refresh from './Refresh';
import Remove from './Remove';
import Reports from './Reports';
import Road from './Road';
import Rotate from './LabelsToolsIcons/Rotate';
import Ruler from './ToolIcons/Ruler';
import Save from './Save';
import Saved from './NavBarSaved';
import Search from './Search';
import SelectedOverview from './SelectedOverview';
import Settings from './Settings';
import Share from './Share';
import Shield from './Shield';
import Soil from './Soil';
import SoilReport from './SoilReport';
import Substract from './Substract';
import Tag from './Tag';
import TextLabel from './ToolIcons/TextLabel';
import TextLabelBackround from './ToolIcons/TextLabelBackround';
import Tick from './Tick';
import Tip from './Tip';
import Tools from './ToolIcons/Tools';
import Trash from './Trash';
import TransparentBox from './TransparentBox';
import TrimBoundaries from './TrimBoundaries';
import Undo from './Undo';
import Unlocked from './GeoreferenceIcons/Unlocked';
import Upload from './Upload';
import Uppercase from './LabelsToolsIcons/UpperCase';
import User from './User';
import VisaCC from './Visa';
import WaypointMarker from './WaypointMarker';
import Webinar from './Webinar';
import XMark from './XMark';
import XMarkRounded from './XMarkRounded';
import XMarkSlim from './XMarkSlim';

import {
  IconProps,
  IconPropsWithBackground,
  IconPropsWithVariant,
  IconWithExtraProps,
} from '../types';

type IconsProps =
  | IconProps
  | IconPropsWithBackground
  | IconPropsWithVariant
  | IconWithExtraProps;

const icons: { [key: string]: (props: IconsProps) => JSX.Element } = {
  account: Account,
  accountUser: AccountUser,
  acres: Acres,
  add: Add,
  addBold: AddBold,
  addBoundary: AddBoundary,
  alert: Alert,
  amexCc: AmexCC,
  appleLogo: AppleLogo,
  area: Area,
  arrow: Arrow,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrows360: Arrows360,
  baseMap: BaseMap,
  boldStyle: BoldStyle,
  boundary: Boundary,
  buffer: Buffer,
  camera: Camera,
  check: Check,
  checkCircle: CheckCircle,
  chevron: Chevron,
  chevronBold: ChevronBold,
  closeCircle: CloseCircle,
  collapseExpand: CollapseExpand,
  company: Company,
  compass: Compass,
  convert: Convert,
  copy: Copy,
  creditCard: CreditCard,
  csv: Csv,
  cut: Cut,
  deedArch: DeedArch,
  deedDrawer,
  discover: Discover,
  discoverCc: DiscoverCC,
  document: Document,
  documentPlus: DocumentPlus,
  dots: Dots,
  download: Download,
  downloadAction: DownloadAction,
  drag: Drag,
  drawer: Drawer,
  duplicate: Duplicate,
  ellipsis: Ellipsis,
  email: Email,
  errorImage: ErrorImage,
  expand: Expand,
  expandFull: ExpandFull,
  eye: Eye,
  eyeSlash: EyeSlash,
  eyeSlashUnfilled: EyeSlashUnfilled,
  eyeUnfilled: EyeUnfilled,
  farmRanch: FarmRanch,
  filter: Filter,
  folder: Folder,
  folderEmpty: FolderEmpty,
  folderOpen: FolderOpen,
  frame: Frame,
  georeference: Georeference,
  geoXMark: GeoXMark,
  grayWaypointMarker: GrayWaypointMarker,
  helpQuestionMark: HelpQuestionMark,
  helper: Helper,
  icon360: Icon360,
  images: Images,
  indent: Indent,
  information: Information,
  italicStyle: ItalicStyle,
  landscape: Landscape,
  landId: LandId,
  landIdDark: LandIdDark,
  landIdFull: LandIdFull,
  layers: Layers,
  legend: Legend,
  lightbulb: Lightbulb,
  line: Line,
  locationTarget: LocationTarget,
  locationTargetMap: LocationTargetMap,
  locked: Locked,
  logo: Logo,
  lowercase: Lowercase,
  magnifyingGlass: MagnifyingGlass,
  mastercardCc: MastercardCC,
  mailBox: MailBox,
  map: Map,
  measurement: Measurement,
  merge: Merge,
  message: Message,
  move: Move,
  naturalResources: NaturalResources,
  noFillBox: NoFillBox,
  openMap: OpenMap,
  optionVertical: OptionVertical,
  overlay: Overlay,
  overview: Overview,
  panorama: Panorama,
  parcel: Parcel,
  parcelIcon: ParcelIcon,
  pencil: Pencil,
  phone: Phone,
  photo: Photo,
  pin: Pin,
  pinAdd: PinAdd,
  pinPlus: PinPlus,
  plainWaypoint: PlainWaypoint,
  playstoreLogo: PlaystoreLogo,
  plus: Plus,
  portrait: Portrait,
  print: Print,
  printsAndReports: PrintsAndReports,
  profile: Profile,
  questionMark: QuestionMark,
  ranching: Ranching,
  realEstate: RealEstate,
  recreationFun: RecreationFun,
  refresh: Refresh,
  remove: Remove,
  reports: Reports,
  road: Road,
  rotate: Rotate,
  ruler: Ruler,
  save: Save,
  saved: Saved,
  search: Search,
  selectedOverview: SelectedOverview,
  settings: Settings,
  share: Share,
  shield: Shield,
  soil: Soil,
  soilReport: SoilReport,
  substract: Substract,
  tag: Tag,
  textLabel: TextLabel,
  textLabelBackground: TextLabelBackround,
  tick: Tick,
  tip: Tip,
  tools: Tools,
  trash: Trash,
  transparentBox: TransparentBox,
  trimBoundaries: TrimBoundaries,
  undo: Undo,
  unlocked: Unlocked,
  upload: Upload,
  uppercase: Uppercase,
  user: User,
  visaCc: VisaCC,
  waypointMarker: WaypointMarker,
  webinar: Webinar,
  xMark: XMark,
  xMarkRounded: XMarkRounded,
  xMarkSlim: XMarkSlim,
};

export default icons;
