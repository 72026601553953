import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import './fonts/gotham.scss';
import './fonts/opensans.scss';
import './fonts/fabriga.scss';
import './fonts/montserrat.scss';
import './fonts/inter.scss';
import './icons/fontawesome_icons/index.scss';
import './icons/material_icons.scss';
import { TopNav, TopNavContext } from 'packages/auth';

import './Layout.scss';

const Layout = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const topNavRef = useRef(null);

  useEffect(() => {
    if (topNavRef.current) {
      setIsReady(true);
    }
  }, []);

  return (
    <TopNavContext.Provider value={{ isReady, topNavRef }}>
      <main className="main-container">
        <TopNav ref={topNavRef} />
        <Outlet />
        {children}
      </main>
    </TopNavContext.Provider>
  );
};
Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
