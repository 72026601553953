import { NonConfigurableIconProps } from '../types';

const PropertyInfo = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34501 19.3618H10.0328C10.2634 20.014 10.592 20.6275 11.0071 21.1809H5.34501C4.45294 21.1809 3.69249 20.5341 3.54942 19.6535L1.02445 4.11293C0.806131 2.76921 2.08696 1.67059 3.38177 2.09096L20.5911 7.67801C21.5678 7.99509 22.0886 9.05717 21.7413 10.0235L21.0242 12.0189C20.5535 11.5954 20.0297 11.2424 19.4692 10.9669L20.0294 9.40825L2.82004 3.82119L5.34501 19.3618Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3766 17.0878C22.3766 20.353 19.7296 23 16.4644 23C13.1992 23 10.5522 20.353 10.5522 17.0878C10.5522 13.8226 13.1992 11.1756 16.4644 11.1756C19.7296 11.1756 22.3766 13.8226 22.3766 17.0878ZM15.687 20.2665V16.3506H17.4758V20.2665H15.687ZM16.5856 15.5192C16.3197 15.5192 16.0915 15.431 15.9012 15.2546C15.7136 15.0755 15.6198 14.8613 15.6198 14.6122C15.6198 14.3659 15.7136 14.1545 15.9012 13.9782C16.0915 13.799 16.3197 13.7094 16.5856 13.7094C16.8515 13.7094 17.0783 13.799 17.2658 13.9782C17.4562 14.1545 17.5514 14.3659 17.5514 14.6122C17.5514 14.8613 17.4562 15.0755 17.2658 15.2546C17.0783 15.431 16.8515 15.5192 16.5856 15.5192Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default PropertyInfo;
