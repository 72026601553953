import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const ArrowLeft = ({
  fill = colors.black,
  width = '15',
  height = '16',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.96967 17.8633C9.26256 18.1562 9.73744 18.1562 10.0303 17.8633C10.3232 17.5704 10.3232 17.0956 10.0303 16.8027L4.31054 11.0829L17.5 11.0829C17.9142 11.0829 18.25 10.7471 18.25 10.3329C18.25 9.91867 17.9142 9.58288 17.5 9.58288L4.31078 9.58289L10.0303 3.86334C10.3232 3.57044 10.3232 3.09557 10.0303 2.80268C9.73744 2.50978 9.26256 2.50978 8.96967 2.80268L1.9776 9.79474C1.84042 9.92794 1.754 10.1131 1.75014 10.3185C1.75005 10.3233 1.75 10.3282 1.75 10.333C1.75 10.4844 1.79575 10.631 1.8796 10.7544C1.90594 10.7932 1.93604 10.8297 1.96967 10.8633L8.96967 17.8633Z"
      fill={fill}
    />
  </svg>
);

export default ArrowLeft;
