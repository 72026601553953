import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Road = ({ fill = colors.black }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8332 9.45783L10.1943 1.81895C10.0967 1.72141 9.96428 1.66663 9.82626 1.66663C9.68824 1.66663 9.55586 1.72141 9.4582 1.81895L1.81931 9.45783C1.72178 9.55549 1.66699 9.68787 1.66699 9.82589C1.66699 9.96391 1.72178 10.0963 1.81931 10.1939L9.4582 17.8328C9.55586 17.9304 9.68824 17.9852 9.82626 17.9852C9.96428 17.9852 10.0967 17.9304 10.1943 17.8328L17.8332 10.1939C17.9307 10.0963 17.9855 9.96391 17.9855 9.82589C17.9855 9.68787 17.9307 9.55549 17.8332 9.45783ZM11.5832 10.3467H9.82626C9.78021 10.3467 9.73605 10.365 9.7035 10.3976C9.67094 10.4301 9.65265 10.4743 9.65265 10.5203V13.2981C9.65265 13.4362 9.59777 13.5687 9.5001 13.6664C9.40242 13.7641 9.26995 13.8189 9.13181 13.8189C8.99368 13.8189 8.8612 13.7641 8.76353 13.6664C8.66585 13.5687 8.61098 13.4362 8.61098 13.2981V6.35367C8.61098 6.21553 8.66585 6.08306 8.76353 5.98538C8.8612 5.88771 8.99368 5.83283 9.13181 5.83283C9.26995 5.83283 9.40242 5.88771 9.5001 5.98538C9.59777 6.08306 9.65265 6.21553 9.65265 6.35367V9.13145C9.65265 9.17749 9.67094 9.22165 9.7035 9.25421C9.73605 9.28677 9.78021 9.30506 9.82626 9.30506H11.5624C11.7005 9.30506 11.833 9.35993 11.9307 9.4576C12.0283 9.55528 12.0832 9.68776 12.0832 9.82589C12.0832 9.96402 12.0283 10.0965 11.9307 10.1942C11.833 10.2918 11.7005 10.3467 11.5624 10.3467H11.5832Z"
      fill={fill}
    />
  </svg>
);

export default Road;
