import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Tip = ({
  fill = colors.black,
  variant,
  width = '24',
  height = '24',
}: IconPropsWithVariant) => {
  const filled = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5892 5.50589C12.433 5.66217 12.221 5.74997 12 5.74997C11.779 5.74997 11.567 5.66217 11.4107 5.50589C11.2545 5.34961 11.1667 5.13765 11.1667 4.91664V2.8333C11.1667 2.61229 11.2545 2.40033 11.4107 2.24405C11.567 2.08777 11.779 1.99997 12 1.99997C12.221 1.99997 12.433 2.08777 12.5892 2.24405C12.7455 2.40033 12.8333 2.61229 12.8333 2.8333V4.91664C12.8333 5.13765 12.7455 5.34961 12.5892 5.50589Z"
        fill={fill}
      />
      <path
        d="M11.9958 6.99997C10.8252 7.0018 9.68565 7.37081 8.74271 8.05341C7.79977 8.736 7.10252 9.69662 6.75223 10.7957C6.40194 11.8949 6.41685 13.0753 6.7948 14.1655C7.17275 15.2557 7.89406 16.199 8.85395 16.8583C8.90978 16.8965 8.9552 16.9477 8.98622 17.0073C9.01723 17.0669 9.03288 17.133 9.03179 17.2V17.8333C9.03179 18.0543 9.12101 18.2663 9.27983 18.4226C9.43864 18.5788 9.65404 18.6666 9.87864 18.6666H14.1129C14.3375 18.6666 14.5529 18.5788 14.7117 18.4226C14.8705 18.2663 14.9598 18.0543 14.9598 17.8333V17.2C14.9588 17.1321 14.9754 17.0652 15.008 17.0054C15.0406 16.9457 15.0881 16.895 15.1461 16.8583C16.1065 16.1986 16.8281 15.2546 17.2059 14.1635C17.5836 13.0725 17.5979 11.8913 17.2465 10.7918C16.8951 9.69224 16.1965 8.73165 15.2522 8.04971C14.3079 7.36777 13.1671 7.00003 11.9958 6.99997Z"
        fill={fill}
      />
      <path
        d="M13.5878 19.9167H10.4121C10.3391 19.9162 10.2671 19.9344 10.2033 19.9695C10.1395 20.0045 10.086 20.0552 10.048 20.1167C10.0123 20.1775 9.99354 20.2464 9.99354 20.3167C9.99354 20.3869 10.0123 20.4558 10.048 20.5167L10.3275 21.075C10.4664 21.3501 10.6799 21.5822 10.9444 21.7454C11.2089 21.9086 11.5142 21.9967 11.8264 22H12.1651C12.4817 22.0014 12.7923 21.9155 13.0617 21.752C13.3311 21.5885 13.5486 21.3539 13.6895 21.075L13.9689 20.5167C14.0046 20.4558 14.0234 20.3869 14.0234 20.3167C14.0234 20.2464 14.0046 20.1775 13.9689 20.1167C13.9302 20.0521 13.8741 19.9993 13.8069 19.964C13.7397 19.9287 13.6639 19.9124 13.5878 19.9167Z"
        fill={fill}
      />
      <path
        d="M19.6583 5.17497C19.5022 5.01977 19.291 4.93265 19.0708 4.93265C18.8507 4.93265 18.6395 5.01977 18.4833 5.17497L17 6.64997C16.9219 6.72744 16.8599 6.81961 16.8176 6.92116C16.7753 7.02271 16.7535 7.13163 16.7535 7.24164C16.7535 7.35165 16.7753 7.46057 16.8176 7.56212C16.8599 7.66367 16.9219 7.75584 17 7.83331C17.0779 7.91054 17.1702 7.97165 17.2718 8.01312C17.3733 8.05459 17.482 8.07561 17.5917 8.07498C17.8103 8.07405 18.0198 7.98726 18.175 7.83331L19.65 6.35831C19.7287 6.28139 19.7913 6.18966 19.8343 6.08841C19.8774 5.98716 19.8999 5.87839 19.9007 5.76838C19.9015 5.65837 19.8804 5.54929 19.8388 5.44744C19.7973 5.3456 19.7359 5.25299 19.6583 5.17497Z"
        fill={fill}
      />
      <path
        d="M19.5 12H21.1667C21.3877 12 21.5996 12.0878 21.7559 12.244C21.9122 12.4003 22 12.6123 22 12.8333C22 13.0543 21.9122 13.2663 21.7559 13.4226C21.5996 13.5788 21.3877 13.6666 21.1667 13.6666H19.5C19.279 13.6666 19.067 13.5788 18.9107 13.4226C18.7545 13.2663 18.6667 13.0543 18.6667 12.8333C18.6667 12.6123 18.7545 12.4003 18.9107 12.244C19.067 12.0878 19.279 12 19.5 12Z"
        fill={fill}
      />
      <path
        d="M7.00001 6.64996L5.51668 5.17496C5.35726 5.03844 5.1522 4.9671 4.94247 4.9752C4.73274 4.9833 4.53379 5.07025 4.38537 5.21866C4.23696 5.36707 4.15002 5.56602 4.14192 5.77575C4.13382 5.98548 4.20516 6.19054 4.34168 6.34996L5.81668 7.83329C5.97189 7.98725 6.18139 8.07404 6.40001 8.07496C6.51108 8.0767 6.62138 8.05622 6.72442 8.01472C6.82745 7.97322 6.92116 7.91153 7.00001 7.83329C7.07812 7.75582 7.14011 7.66366 7.18242 7.56211C7.22473 7.46056 7.24651 7.35164 7.24651 7.24163C7.24651 7.13162 7.22473 7.0227 7.18242 6.92115C7.14011 6.8196 7.07812 6.72743 7.00001 6.64996Z"
        fill={fill}
      />
      <path
        d="M2.83333 12H4.5C4.72101 12 4.93298 12.0878 5.08926 12.244C5.24554 12.4003 5.33333 12.6123 5.33333 12.8333C5.33333 13.0543 5.24554 13.2663 5.08926 13.4226C4.93298 13.5788 4.72101 13.6666 4.5 13.6666H2.83333C2.61232 13.6666 2.40036 13.5788 2.24408 13.4226C2.0878 13.2663 2 13.0543 2 12.8333C2 12.6123 2.0878 12.4003 2.24408 12.244C2.40036 12.0878 2.61232 12 2.83333 12Z"
        fill={fill}
      />
    </svg>
  );

  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.74498C12.46 5.74498 12.83 5.37498 12.83 4.91498V2.82498C12.83 2.36498 12.46 1.99498 12 1.99498C11.54 1.99498 11.17 2.36498 11.17 2.82498V4.91498C11.17 5.37498 11.54 5.74498 12 5.74498Z"
        fill={fill}
      />
      <path
        d="M13.57 19.915H10.45C10.3 19.915 10.17 19.995 10.09 20.115C10.02 20.235 10.02 20.395 10.09 20.515L10.36 21.075C10.64 21.635 11.21 21.995 11.84 22.005H12.17C12.81 22.005 13.39 21.645 13.67 21.075L13.94 20.515C14.01 20.395 14.01 20.235 13.94 20.115C13.86 19.985 13.72 19.905 13.57 19.915Z"
        fill={fill}
      />
      <path
        d="M18.49 5.16497C18.81 4.84497 19.33 4.84497 19.66 5.16497H19.67C19.99 5.49497 19.99 6.02497 19.66 6.34497L18.18 7.81497C18.03 7.96497 17.82 8.05497 17.6 8.05497C17.38 8.05497 17.17 7.97497 17.01 7.81497C16.68 7.48497 16.68 6.95497 17.01 6.63497L18.49 5.16497Z"
        fill={fill}
      />
      <path
        d="M21.17 11.995H19.5C19.04 11.995 18.67 12.365 18.67 12.825C18.67 13.285 19.04 13.655 19.5 13.655H21.17C21.63 13.655 22 13.285 22 12.825C22 12.365 21.63 11.995 21.17 11.995Z"
        fill={fill}
      />
      <path
        d="M5.52002 5.17498L7.00002 6.64498C7.33002 6.97498 7.33002 7.50498 7.00002 7.82498C6.84002 7.98498 6.62002 8.06498 6.40002 8.06498C6.18002 8.06498 5.98002 7.97498 5.82002 7.82498L4.35002 6.34498C4.08002 6.03498 4.08002 5.57498 4.35002 5.26498C4.64002 4.91498 5.17002 4.87498 5.52002 5.17498Z"
        fill={fill}
      />
      <path
        d="M4.5 11.995H2.83C2.37 11.995 2 12.365 2 12.825C2 13.285 2.37 13.655 2.83 13.655H4.5C4.96 13.655 5.33 13.285 5.33 12.825C5.33 12.365 4.96 11.995 4.5 11.995Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59003 12.425C6.59003 9.42498 9.01003 6.99498 12 6.99498C13.77 6.99498 15.43 7.86498 16.44 9.31498C18.15 11.765 17.55 15.145 15.1 16.855C14.99 16.925 14.92 17.055 14.92 17.195V17.825C14.92 18.285 14.55 18.655 14.09 18.655H9.92003C9.46003 18.655 9.09003 18.285 9.09003 17.825V17.195C9.09003 17.065 9.02003 16.935 8.91003 16.855C7.46003 15.845 6.59003 14.195 6.59003 12.425ZM15.21 10.165C14.48 9.12498 13.28 8.49498 12 8.49498C10.95 8.49498 9.97003 8.90498 9.23003 9.64498C8.49003 10.385 8.09003 11.365 8.09003 12.415C8.09003 13.695 8.71003 14.895 9.76003 15.625C10.27 15.975 10.58 16.555 10.58 17.165H13.41C13.41 16.545 13.73 15.965 14.25 15.615C15.1 15.015 15.67 14.115 15.85 13.095C16.04 12.065 15.81 11.025 15.21 10.165Z"
        fill={fill}
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

export default Tip;
