import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'store/slices/modalsSlice';

import { getAPIURL } from 'api/utils/domains';
import { INDIVIDUAL_TEAM, ORGANIZATIONS } from 'utils/constants';
import { USERS_SIGN_IN_ROUTE } from 'utils/routes';

import {
  getIndividualSubscription,
  getTeamSubscription,
  isLegacyUser,
} from 'selectors/userSelectors';

import AccountItem from './AccountItem';
import Icon from 'components/ui/DeprecatedIcon';
import CustomModal from 'components/CustomModal';

import { useTeams } from '../../store/teamsHooks';

import packageInfo from '../../../../../package.json';
import generatedGitInfo from 'generatedGitInfo.json';
import { fetchVersion } from 'api/version';

import * as colors from 'components/ui/styles/colors.module.scss';
import classNames from 'classnames';
import './styles.scss';
import FeatureFlagModal from 'components/modals/FeatureFlagModal';

export const ChooseAccountModal = ({
  admin,
  allUserSubscription,
  onClose,
  topNavVersion,
  signOut,
}) => {
  const [apiVersionData, setApiVersionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchVersion();
        setApiVersionData(response);
      } catch (error) {
        console.error('Error fetching API version:', error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const selectedAccount = useSelector((state) => state.user.selectedAccount);

  const teams = useTeams();
  const subscriptions = useSelector((state) => state.subscriptions);

  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.user?.user?.role);
  const isEmployee = userRole === 'employee';

  const setSelectedAccount = (selectedAccount) => {
    dispatch({
      type: 'SELECT_ACCOUNT',
      data: selectedAccount,
    });

    let selectedSubscription;
    if (selectedAccount.type === 'individual') {
      selectedSubscription = getIndividualSubscription(subscriptions);
    } else if (selectedAccount.type === 'organizations') {
      selectedSubscription = getTeamSubscription({
        subscriptions,
        teamId: selectedAccount.id,
      });
    }

    if (selectedSubscription) {
      dispatch({
        type: 'FETCH_USER_SUBSCRIPTIONS_SUCCESS',
        response: { data: selectedSubscription },
      });
    }

    onClose();
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  };

  const isSelected = (team) => {
    if (team?.team_id) {
      return (
        selectedAccount?.type === ORGANIZATIONS &&
        selectedAccount?.organizationId === team.organization_id &&
        selectedAccount?.id === team.team_id
      );
    }

    return (
      selectedAccount?.type === INDIVIDUAL_TEAM &&
      selectedAccount?.id === allUserSubscription.individual.id
    );
  };

  const handleSignOut = async () => {
    onClose();
    await signOut();
    navigate(`${USERS_SIGN_IN_ROUTE}`);
  };

  return (
    <div>
      {isLegacyUser ?
        <CustomModal
          notCloseOnOverlayClick={!topNavVersion}
          className={classNames('choose-account-modal', {
            'top-nav-version': topNavVersion,
          })}
          positionModifier="top"
          onClose={onClose}
          customStyle={customStyles}
        >
          {allUserSubscription && (
            <>
              <span className="title">Choose Account</span>
              <div className="account-list">
                {teams.map((team, index) => (
                  <div key={`${team.organization_id} / ${team.team_id}`}>
                    <AccountItem
                      name={
                        team.team_id ?
                          `${team.organization_name} / ${team.team_name}`
                        : team.team_name
                      }
                      accountIconType={
                        team.team_id ? 'organization' : 'individual'
                      }
                      onClick={() =>
                        setSelectedAccount({
                          type: team.team_id ? ORGANIZATIONS : INDIVIDUAL_TEAM,
                          organizationId: team.organization_id,
                          organizationName: team.organization_name,
                          id:
                            team.team_id ?
                              team.team_id
                            : allUserSubscription.individual.id,
                          name: team.team_name,
                        })
                      }
                      selected={isSelected(team)}
                    />
                    {!index && topNavVersion && (
                      <NavLink
                        className="account-item"
                        to="/profile"
                        onClick={onClose}
                      >
                        <Icon id="settings" />
                        <div className="account-item__name">
                          Account Details
                        </div>
                        <Icon
                          id="chevron"
                          direction="right"
                          className="chevron"
                        />
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {topNavVersion && (
            <button
              className="sign-out"
              onClick={() => {
                signOut();
                onClose();
              }}
            >
              Sign Out
            </button>
          )}
        </CustomModal>
      : <CustomModal
          notCloseOnOverlayClick={!topNavVersion}
          className={classNames('choose-account-modal', {
            'top-nav-version': topNavVersion,
          })}
          positionModifier="top"
          onClose={onClose}
          customStyle={customStyles}
        >
          {allUserSubscription && (
            <>
              <span className="title">Choose Account</span>
              <div className="account-list">
                {teams?.map((team, index) => (
                  <div
                    key={`${team.organization_id} / ${team.team_id}`}
                    className={classNames({ selected: isSelected(team) })}
                  >
                    <AccountItem
                      id="account-profile"
                      name={
                        team.team_id ?
                          `${team.organization_name} / ${team.team_name}`
                        : team.team_name
                      }
                      accountIconType={
                        team.team_id ? 'organization' : (
                          'filled-square-individual'
                        )
                      }
                      onClick={() =>
                        setSelectedAccount({
                          type: team.team_id ? ORGANIZATIONS : INDIVIDUAL_TEAM,
                          organizationId: team.organization_id,
                          organizationName: team.organization_name,
                          id:
                            team.team_id ?
                              team.team_id
                            : allUserSubscription.individual.id,
                          name: team.team_name,
                        })
                      }
                      selected={isSelected(team)}
                    />
                    {!index && topNavVersion && (
                      <NavLink
                        className="account-details"
                        to="/profile"
                        onClick={onClose}
                      >
                        <Icon id="settings" fill={colors.grayScale400} />
                        <div className="account-item__name">
                          Account Details
                        </div>
                        <Icon
                          id="chevron"
                          direction="right"
                          className="chevron"
                        />
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="sign-out-list">
            {topNavVersion && admin && (
              <button
                href={`${getAPIURL()}/admin`}
                target="_blank"
                rel="noopener noreferrer"
                className="sign-out"
                onClick={() => {
                  window.location.href = `${getAPIURL()}/admin`;
                }}
              >
                Admin
              </button>
            )}
            {isEmployee && (
              <button
                className="sign-out"
                onClick={() => {
                  dispatch(showModal(FeatureFlagModal));
                }}
              >
                Feature Flags
              </button>
            )}
            {topNavVersion && (
              <button
                href={`${process.env.HELP_LINK}`}
                target="_blank"
                rel="noopener noreferrer"
                className="sign-out"
                onClick={() => {
                  window.location.href = `${process.env.HELP_LINK}`;
                }}
              >
                Help
              </button>
            )}
            {topNavVersion && (
              <button
                target="_blank"
                rel="noopener noreferrer"
                className="sign-out"
                to="/hc/en-us"
                onClick={() => {
                  window.location.href = '/contact';
                }}
              >
                Contact
              </button>
            )}
            {topNavVersion && (
              <button className="sign-out" onClick={handleSignOut}>
                Sign Out
              </button>
            )}
          </div>
          {admin && apiVersionData && (
            <div className="version">
              API Version {apiVersionData.commit} ({apiVersionData.branch})
            </div>
          )}
          <div className="version">
            Version{' '}
            {process.env.ENVIRONMENT === 'staging' ?
              'trunk'
            : packageInfo.version}{' '}
            {generatedGitInfo.gitCommitHash}{' '}
            {admin && generatedGitInfo.gitBranch}
            {admin && apiVersionData && (
              <div
                className="release-notes"
                onClick={() => {
                  navigate('/release-notes');
                }}
              >
                Release Notes
              </div>
            )}
          </div>
        </CustomModal>
      }
    </div>
  );
};

ChooseAccountModal.propTypes = {
  allUserSubscription: PropTypes.object,
  onClose: PropTypes.func,
  topNavVersion: PropTypes.bool,
  signOut: PropTypes.func,
  admin: PropTypes.bool,
};
