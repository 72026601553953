import { HttpLink, ApolloLink } from '@apollo/client';

export const endpoints = {
  Search: '/search/query',
  EventRouter: '/event-router/query',
  Authz: '/authz/query',
} as const;

const httpLinks = Object.entries(endpoints).reduce(
  (acc, [key, path]) => {
    acc[key as keyof typeof endpoints] = new HttpLink({
      uri: process.env.API_GATEWAY_URL + path,
    });
    return acc;
  },
  {} as Record<keyof typeof endpoints, HttpLink>,
);

export const routingLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName;

  const endpoint = Object.keys(endpoints).find((prefix) =>
    operationName.startsWith(prefix),
  );

  if (!endpoint) {
    throw new Error(
      `Unknown operation ${operationName}. Please revise your operation name or add a new endpoint for your service.`,
    );
  }

  return httpLinks[endpoint as keyof typeof endpoints].request(
    operation,
    forward,
  );
});
