import { isString, isObject, cloneDeep, toNumber, round } from 'lodash';
import { DECIMAL_PRECISION, MEASUREMENT_UNITS } from './constants';
import * as turf from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import pointToLineDistance from '@turf/point-to-line-distance';
import polygonToLine from '@turf/polygon-to-line';

export const generateNewMaprightId = () => Math.random() * Math.random() * 1000;

export const orderMediaByType = (videos = [], photos = []) => {
  const photosWithoutIndex = photos.filter(
    (photo) => photo.mediaIndex === undefined,
  );
  const videosWithoutIndex = videos.filter(
    (video) => video.mediaIndex === undefined,
  );
  const mediaWithIndex = photos
    .filter((photo) => photo.mediaIndex !== undefined)
    .concat(videos.filter((video) => video.mediaIndex !== undefined))
    .sort((a, b) => a.mediaIndex - b.mediaIndex);

  return photosWithoutIndex.concat(videosWithoutIndex).concat(mediaWithIndex);
};

export const orderMediaByObjects = (media = []) => {
  const photosWithoutIndex = media.filter(
    (elem) => elem.mediaIndex === undefined && elem.type === 'photo',
  );
  const videosWithoutIndex = media.filter(
    (elem) => elem.mediaIndex === undefined && elem.type === 'video',
  );
  const mediaWithIndex = media
    .filter((photo) => photo.mediaIndex !== undefined)
    .sort((a, b) => a.mediaIndex - b.mediaIndex);

  return photosWithoutIndex.concat(videosWithoutIndex).concat(mediaWithIndex);
};

export const getAllMedia = (map) => {
  const allMedia =
    map.video ? [{ videoLink: map.video, element: { properties: {} } }] : [];

  map?.geoJSON.forEach((element) => {
    orderMediaByType(element.videos, element.photos).forEach((media) => {
      let newMedia = cloneDeep(media);
      if (isObject(newMedia)) {
        newMedia.element = element;
      } else {
        newMedia = { id: newMedia, element };
      }

      allMedia.push(newMedia);
    });
  });

  map?.documents?.forEach((document) => {
    const newDocument = {
      ...document,
      document: true,
      element: { properties: {} },
    };
    allMedia.push(newDocument);
  });

  return allMedia;
};

const YOUTUBE_ID_REG_EXP =
  /(?:youtube.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu.be\/)([^"&?/ ]{11})/i;
const YOUTUBE_SHORT_ID_REG_EXP =
  /(?:youtube.com\/shorts\/|youtu.be\/)([^"&?/ ]{11})/i;
const VIMEO_ID_REG_EXP =
  /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
const GOOGLE_DRIVE_ID_REG_EXP =
  /https?:\/\/(?:www\.)?drive\.google\.com\/(?:file\/d\/|open\?id=)([a-zA-Z0-9_-]+)(?:&.*|\/.*)?$/;

const videoPlatforms = [
  {
    test: (link) => YOUTUBE_ID_REG_EXP.exec(link),
    buildUrl: (videoID) =>
      `https://www.youtube.com/embed/${videoID}?controls=0&showinfo=0&enablejsapi=1`,
  },
  {
    test: (link) => YOUTUBE_SHORT_ID_REG_EXP.exec(link),
    buildUrl: (videoID) =>
      `https://www.youtube.com/embed/${videoID}?controls=0&showinfo=0&enablejsapi=1`,
  },
  {
    test: (link) => VIMEO_ID_REG_EXP.exec(link),
    buildUrl: (videoID) => `https://player.vimeo.com/video/${videoID}`,
  },
  {
    test: (link) => GOOGLE_DRIVE_ID_REG_EXP.exec(link),
    buildUrl: (videoID) => `https://drive.google.com/file/d/${videoID}/preview`,
  },
];

export const isValidVideoURL = (url) =>
  videoPlatforms.some((platform) => platform.test(url));

export const encodeVideoURL = (link) => {
  for (const platform of videoPlatforms) {
    const match = platform.test(link);
    if (match && match.length > 1) {
      const videoID = match[match.length - 1];
      return platform.buildUrl(videoID);
    }
  }

  return link;
};

export const isGoogleDriveVideo = (URL) => {
  const regex = /https:\/\/drive\.google\.com\/file\/d\/[a-zA-Z0-9_-]+/;

  return regex.test(URL);
};

export const extractVideoURL = (media) => {
  if (isString(media)) {
    return media;
  }

  if (isString(media.id)) {
    return media.id;
  }

  if (media.videoLink) {
    return media.videoLink;
  }

  if (typeof media.element !== 'undefined') {
    return extractVideoURL(media.element);
  }

  return null;
};

export const extractPhotoId = (media) => {
  if (Number.isInteger(media)) {
    return media;
  }

  if (media.id) {
    return media.id;
  }

  if (typeof media.element !== 'undefined') {
    return extractPhotoId(media.element);
  }

  return null;
};

export const formatDecimalNumber = (value, precision = DECIMAL_PRECISION) => {
  const numberValue = toNumber(value);
  if (!numberValue) {
    return value;
  }

  return round(value, precision);
};

export const abbreviateMesurement = (measurementUnit) => {
  switch (measurementUnit) {
    case MEASUREMENT_UNITS.FT:
      return ' FT';
    case MEASUREMENT_UNITS.MILES:
      return ' M';
    default:
      return '';
  }
};

export const formatPercentageNumber = (value, precision = 1) => {
  const numberValue = toNumber(value);
  if (!numberValue) {
    return value;
  }

  let percentage = round(value, precision);
  if (percentage === 0) {
    percentage = round(value, 2);
  }
  if (percentage === 0) {
    percentage = round(value, 3);
  }

  return percentage;
};

export const removeExtraQuotes = (code) => {
  try {
    const parsedCode = JSON.parse(code);
    return parsedCode;
  } catch {
    return code;
  }
};

// Returns distance in meters (negative values for points inside) from a point to the edges of a polygon
export const distanceToPolygon = (point, polygon) => {
  let poly = polygon;
  if (polygon.type === 'Feature') {
    poly = polygon.geometry;
  }
  let distance;
  if (polygon.type === 'MultiPolygon') {
    distance = poly.coordinates
      .map((coords) => distanceToPolygon(point, turf.polygon(coords).geometry))
      .reduce((smallest, current) => (current < smallest ? current : smallest));
  } else {
    if (poly.coordinates.length > 1) {
      // Has holes
      const [exteriorDistance, ...interiorDistances] = poly.coordinates.map(
        (coords) => distanceToPolygon(point, turf.polygon([coords])),
      );
      if (exteriorDistance < 0) {
        // point is inside the exterior polygon shape
        const smallestInteriorDistance = interiorDistances.reduce(
          (smallest, current) => (current < smallest ? current : smallest),
        );
        if (smallestInteriorDistance < 0) {
          // point is inside one of the holes (therefore not actually inside this shape)
          distance = smallestInteriorDistance * -1;
        } else {
          // find which is closer, the distance to the hole or the distance to the edge of the exterior,
          // and set that as the inner distance.
          distance =
            smallestInteriorDistance < exteriorDistance * -1 ?
              smallestInteriorDistance * -1
            : exteriorDistance;
        }
      } else {
        distance = exteriorDistance;
      }
    } else {
      // The actual distance operation - on a normal, hole-less polygon (converted to meters)
      const latestPolygon = turf.polygon(poly.coordinates);
      const { lng, lat } = point.lngLat;
      const latestPoint = turf.point([lng, lat]);
      distance =
        pointToLineDistance(latestPoint, polygonToLine(latestPolygon)) * 1000;
      if (booleanPointInPolygon(latestPoint, latestPolygon)) {
        distance = distance * -1;
      }
    }
  }
  // In this particular case we get the absolute of the distance because the features processed
  // are only those which geometry are outside the point. In case is required to know if the point
  // is inside the feature return only the distance.
  return Math.abs(distance);
};

export const nearestFeature = (point, features) => {
  let minDistance = distanceToPolygon(point, features[0]);
  let index = 0;
  for (let i = 1; i <= features.length - 1; i++) {
    const dist = distanceToPolygon(point, features[i]);
    if (dist < minDistance) {
      index = i;
      minDistance = dist;
    }
  }
  return features[index].properties.maprightId;
};

export const stringToId = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }

  return Math.abs(hash);
};
