import Cookies from 'cookies-js';

import { loadDashboard, loadFolders } from 'actions/dashboard';
import actionTypes from './actions';
import api from 'api/index';
import EventRouter from 'services/EventRouter';
import { fetchUserSubscription } from 'api/subscriptions';

export const getUser = () => (dispatch, getState) => {
  api
    .getUser()
    .then((data) => {
      dispatch({ type: actionTypes.RELOAD_USER_SUCCESS, user: data });
    })
    .catch((error) =>
      dispatch({ type: actionTypes.RELOAD_USER_FAILURE, error }),
    );
};

export const authenticate = (token) => (dispatch, _) =>
  new Promise((resolve) => {
    api
      .authenticate(token)
      .then((data) => {
        dispatch({ type: actionTypes.AUTHENTICATE, user: data.user });
        resolve(data);
      })
      .catch((error) => {
        dispatch({ type: actionTypes.AUTHENTICATE_FAILURE, error });
        resolve(error);
      });
  });

function trackSignInEvent(selectedPlan, isSignUp) {
  const eventPayload = {
    login_type: 'credential_entry',
    plan_selected: selectedPlan,
    subscription_status: 'free',
  };

  if (!isSignUp) {
    fetchUserSubscription()
      .then((response) => {
        eventPayload.subscription_status = response.data.status;
        EventRouter.send('account_user_loggedIn', eventPayload);
      })
      .catch((error) => {
        console.error('Failed to fetch user subscription:', error);
      });
  } else {
    EventRouter.send('account_user_loggedIn', eventPayload);
  }
}

export const signIn =
  (email, password, { isSignUp = false } = {}) =>
  (dispatch, getState) =>
    api
      .signIn(email, password, getGaClientId(), getDeviceId())
      .then((data) => {
        dispatch({ type: actionTypes.SIGN_IN_SUCCESS, user: data.resource });
        trackSignInEvent(data.resource.subscription_plan, isSignUp);
        const {
          resource: { redirect_to: redirectTo },
        } = data;
        return redirectTo;
      })
      .catch((error) => {
        dispatch({ type: actionTypes.SIGN_IN_FAILURE, error });
        throw error;
      });

export const embedSignIn = (email, authToken) => async (dispatch) => {
  try {
    const data = await api.embedSignIn(email, authToken);
    dispatch({ type: actionTypes.SIGN_IN_SUCCESS, user: data.resource });
    return '/dashboard';
  } catch (error) {
    dispatch({ type: actionTypes.SIGN_IN_FAILURE, error });
    return '/users/sign_in';
  }
};

export const cleanResetPasswordForm = () => (dispatch) => {
  dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
};

export const sendResetPasswordInstructions = (email) => async (dispatch) => {
  try {
    const data = await api.sendResetPasswordInstructions(email);
    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, user: data.resource });
  } catch (error) {
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error });
  }
};

export const resetPassword = (password, token) => async (dispatch) => {
  try {
    const data = await api.resetPassword(password, token);
    dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS, user: data.resource });
  } catch (error) {
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILURE, error });
  }
};

export const impersonate =
  (userId, adminToken, redirectTo) => async (dispatch, getState) => {
    try {
      const data = await api.impersonate(userId, adminToken);
      dispatch({ type: actionTypes.SIGN_IN_SUCCESS, user: data });

      document.title = 'Land id™ - Impersonate User';

      if (redirectTo) {
        return `/${redirectTo}`;
      }
      dispatch(loadFolders());
      dispatch(loadDashboard());
      return '/dashboard';
    } catch (error) {
      dispatch({ type: actionTypes.SIGN_IN_FAILURE, error });
      await signOut();
      throw error;
    }
  };

export const signOut = () => async (dispatch, getState) => {
  await api.signOut();
  dispatch({ type: actionTypes.LOGOUT });
};

export const signUp =
  (
    firstName,
    lastName,
    email,
    password,
    reCaptchaToken,
    usageTracking,
    freeTrialGaEvent,
    plan,
  ) =>
  (dispatch, getState) => {
    dispatch({ type: actionTypes.CLEAR_USER_ERROR });
    if (!localStorage.getItem('first_sign_in'))
      localStorage.setItem('first_sign_in', true);

    return api
      .signUp(
        firstName,
        lastName,
        email,
        password,
        reCaptchaToken,
        usageTracking,
        getGaClientId(),
        plan,
      )
      .then((data) => {
        dispatch({ type: actionTypes.SIGN_UP_SUCCESS, user: data.resource });
        freeTrialGaEvent();
        return data;
      })
      .then((data) => dispatch(signIn(email, password, { isSignUp: true })))
      .catch((error) => {
        dispatch({ type: actionTypes.SIGN_UP_FAILURE, error });
        throw error;
      });
  };

export const signInWithGoogle = (token) => (dispatch) =>
  api
    .signInWithGoogle(token, getGaClientId(), getDeviceId())
    .then((data) => {
      const user = {
        ...data.user,
        redirect_to: data.redirect_to,
      };

      dispatch({ type: actionTypes.SIGN_IN_SUCCESS, user });
      return user.redirect_to;
    })
    .catch((error) => {
      dispatch({ type: actionTypes.SIGN_IN_FAILURE, error });
      throw error;
    });

export const signInWithApple = (code, name) => (dispatch) =>
  api
    .signInWithApple(code, getGaClientId(), getDeviceId(), name)
    .then((data) => {
      const user = {
        ...data.user,
        redirect_to: data.redirect_to,
      };

      dispatch({ type: actionTypes.SIGN_IN_SUCCESS, user });

      return user.redirect_to;
    })
    .catch((error) => {
      dispatch({ type: actionTypes.SIGN_IN_FAILURE, error });
      throw error;
    });

export const updateUser =
  (user, opts = {}) =>
  async (dispatch, getState) => {
    const nonLogoUpdate = async () => {
      try {
        const data = await api.updateUser(user);
        dispatch({ type: actionTypes.UPDATE_USER_SUCCESS, user: data });
        // After some seconds, we set the `updated` flag to false
        setTimeout(
          () => dispatch({ type: actionTypes.DISABLE_UPDATE_FLAG }),
          4000,
        );

        if (opts.redirect) {
          return opts.redirect;
        }
      } catch (error) {
        dispatch({ type: actionTypes.UPDATE_USER_FAILURE, error });
        throw error;
      }
    };

    if (user.avatar && user.avatar.small) {
      return nonLogoUpdate();
    }

    try {
      await api.updateUserLogo(user);
      return nonLogoUpdate();
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_USER_FAILURE, error });
      throw error;
    }
  };

export const finishTutorial = () => (dispatch, getState) => {
  const { user } = structuredClone(getState().user);

  user.tutorial = {
    ...user.tutorial,
    finished: true,
    step: 1,
  };

  dispatch({
    type: actionTypes.SET_TUTORIAL_ATTRIBUTE,
    attribute: 'finished',
    value: true,
  });
  dispatch(updateUser(user));
};

export const restartTutorial = () => (dispatch, getState) => {
  const { user } = structuredClone(getState().user);

  user.tutorial = {
    ...user.tutorial,
    finished: false,
    step: 1,
  };

  dispatch({
    type: actionTypes.SET_TUTORIAL_ATTRIBUTE,
    attribute: 'finished',
    value: false,
  });
  dispatch({
    type: actionTypes.SET_TUTORIAL_ATTRIBUTE,
    attribute: 'step',
    value: 1,
  });
  dispatch(updateUser(user));
};

export const toggleParcelButton = () => (dispatch, getState) => {
  const { user } = structuredClone(getState().user);
  dispatch({ type: actionTypes.TOGGLE_PARCEL_BUTTON });

  user.tutorial.parcel_button_clicked = true;
  dispatch(updateUser(user));
};

export const goToStep = (stepNumber) => (dispatch, getState) => {
  const { user } = structuredClone(getState().user);

  user.tutorial.step = stepNumber;

  dispatch({
    type: actionTypes.SET_TUTORIAL_ATTRIBUTE,
    attribute: 'step',
    value: stepNumber,
  });
  dispatch(updateUser(user));
};

export const setUserDefaultState = (state) => (dispatch, getState) => {
  const { user } = getState().user;
  const newUser = { ...user, default_state: state };

  dispatch({
    type: actionTypes.SET_TUTORIAL_ATTRIBUTE,
    attribute: 'default_state',
    value: state,
  });
  dispatch(updateUser(newUser));
};

export const setTutorialCustomData = (attribute) => (dispatch, getState) => {
  const { user } = structuredClone(getState().user);

  const tutorialMapping = {
    autoSave: 'autosave_watched',
    galleryPromo: 'gallery_promo_watched',
  };

  if (tutorialMapping[attribute]) {
    user.tutorial = {
      ...user.tutorial,
      [tutorialMapping[attribute]]: true,
    };

    dispatch({
      type: actionTypes.SET_TUTORIAL_ATTRIBUTE,
      attribute: tutorialMapping[attribute],
      value: true,
    });

    dispatch(updateUser(user));
  }
};

export const getUsageTrackingOptions = (dispatch, getState) => {
  api
    .getUsageTrackingOptions()
    .then((data) =>
      dispatch({ type: actionTypes.GET_USAGE_TRACKING_OPTIONS, data }),
    );
};

export const actions = {
  getUser,
  signIn,
  embedSignIn,
  signUp,
  signInWithGoogle,
  signInWithApple,
  updateUser,
  impersonate,
  finishTutorial,
  restartTutorial,
  toggleParcelButton,
  goToStep,
  sendResetPasswordInstructions,
  signOut,
  getUsageTrackingOptions,
};

export const getDeviceId = () => {
  try {
    const deviceId = window.kochava.getDeviceId();
    return deviceId;
  } catch (error) {
    return null;
  }
};

export const getGaClientId = () =>
  Cookies.get('_ga') ? Cookies.get('_ga').substring(6) : null;
