import { NonConfigurableIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const TextLabelBackground = ({
  width = '24',
  height = '24',
  color = colorPalette.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="3" fill={color} />
      <path
        d="M16.4098 5H7.31701C6.96772 5 6.63273 5.13876 6.38574 5.38574C6.13876 5.63273 6 5.96772 6 6.31701V7.45361C6 7.65298 6.0792 7.84418 6.22017 7.98515C6.36115 8.12613 6.55235 8.20533 6.75172 8.20533C6.95109 8.20533 7.14229 8.12613 7.28326 7.98515C7.42424 7.84418 7.50344 7.65298 7.50344 7.45361V6.65378C7.50344 6.61391 7.51928 6.57567 7.54747 6.54747C7.57567 6.51928 7.61391 6.50344 7.65378 6.50344H10.9613C11.0012 6.50344 11.0395 6.51928 11.0676 6.54747C11.0958 6.57567 11.1117 6.61391 11.1117 6.65378V17.3462C11.1117 17.3861 11.0958 17.4243 11.0676 17.4525C11.0395 17.4807 11.0012 17.4966 10.9613 17.4966H9.87285C9.67348 17.4966 9.48228 17.5758 9.34131 17.7167C9.20033 17.8577 9.12113 18.0489 9.12113 18.2483C9.12113 18.4476 9.20033 18.6389 9.34131 18.7798C9.48228 18.9208 9.67348 19 9.87285 19H13.854C14.0533 19 14.2445 18.9208 14.3855 18.7798C14.5265 18.6389 14.6057 18.4476 14.6057 18.2483C14.6057 18.0489 14.5265 17.8577 14.3855 17.7167C14.2445 17.5758 14.0533 17.4966 13.854 17.4966H12.7655C12.7256 17.4966 12.6874 17.4807 12.6592 17.4525C12.631 17.4243 12.6151 17.3861 12.6151 17.3462V6.65378C12.6151 6.61391 12.631 6.57567 12.6592 6.54747C12.6874 6.51928 12.7256 6.50344 12.7655 6.50344H16.073C16.1129 6.50344 16.1511 6.51928 16.1793 6.54747C16.2075 6.57567 16.2234 6.61391 16.2234 6.65378V7.45361C16.2234 7.65298 16.3026 7.84418 16.4435 7.98515C16.5845 8.12613 16.7757 8.20533 16.9751 8.20533C17.1745 8.20533 17.3657 8.12613 17.5066 7.98515C17.6476 7.84418 17.7268 7.65298 17.7268 7.45361V6.31701C17.7268 5.96772 17.588 5.63273 17.3411 5.38574C17.0941 5.13876 16.7591 5 16.4098 5Z"
        fill="black"
      />
    </svg>
  );
};

export default TextLabelBackground;
