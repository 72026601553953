import actionTypes from '../actions/redirect/actions';

const ACTION_HANDLERS = {
  [actionTypes.SET_REDIRECT]: (state, action) => {
    const { url } = action;
    return { ...state, needsRedirect: true, redirectPath: url };
  },
  [actionTypes.CLEAR_REDIRECT]: (state, action) => initialState,
};

const initialState = {
  needsRedirect: false,
  redirectPath: '/',
};
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
