import { StyleableOnlyIconProps } from '../types';
import * as colors from '../../styles/colors.module.scss';

const ChevronDown = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colors.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.67161 6.34551C4.34205 5.94286 3.74842 5.88353 3.34566 6.21295C2.94285 6.54241 2.88346 7.13597 3.21306 7.53868L10.8063 16.8161C11.5527 17.728 12.9474 17.728 13.6937 16.8161L21.287 7.53868C21.6166 7.13597 21.5572 6.54241 21.1544 6.21295C20.7516 5.88353 20.158 5.94286 19.8284 6.34551L12.25 15.6048L4.67161 6.34551Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9692 17.201C11.4453 17.201 10.9964 16.9765 10.697 16.6023L3.28864 8.29594C2.83965 7.69728 2.91448 6.79929 3.51313 6.3503C4.11179 5.90131 4.93495 5.90131 5.38394 6.49996L11.8195 13.8335C11.8943 13.9083 11.9692 13.9083 12.1188 13.8335L18.5544 6.49996C19.0782 5.90131 19.9014 5.82647 20.5 6.3503C21.0987 6.87412 21.1735 7.69728 20.6497 8.29594C20.6497 8.29594 20.6497 8.29593 20.5749 8.37077L13.2413 16.6771C12.942 16.9765 12.4182 17.201 11.9692 17.201Z"
          fill={color}
        />
      </svg>;
};

export default ChevronDown;
