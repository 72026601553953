import { NonConfigurableIconProps } from '../types';

const Company = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2963 6.62963C7.71674 6.62963 7.24691 7.09945 7.24691 7.67901C7.24691 8.25857 7.71674 8.72839 8.2963 8.72839C8.87586 8.72839 9.34568 8.25857 9.34568 7.67901C9.34568 7.09945 8.87586 6.62963 8.2963 6.62963Z"
        fill="#1D1D1D"
      />
      <path
        d="M13.0494 7.67901C13.0494 7.09944 12.5795 6.62963 12 6.62963C11.4205 6.62963 10.9506 7.09944 10.9506 7.67901C10.9506 8.25859 11.4205 8.72839 12 8.72839C12.5795 8.72839 13.0494 8.25859 13.0494 7.67901Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.7531 7.67901C16.7531 7.09944 16.2832 6.62963 15.7037 6.62963C15.1242 6.62963 14.6543 7.09944 14.6543 7.67901C14.6543 8.25859 15.1242 8.72839 15.7037 8.72839C16.2832 8.72839 16.7531 8.25859 16.7531 7.67901Z"
        fill="#1D1D1D"
      />
      <path
        d="M8.2963 12.4321C7.71672 12.4321 7.24691 11.9623 7.24691 11.3827C7.24691 10.8032 7.71672 10.3333 8.2963 10.3333C8.87587 10.3333 9.34568 10.8032 9.34568 11.3827C9.34568 11.9623 8.87587 12.4321 8.2963 12.4321Z"
        fill="#1D1D1D"
      />
      <path
        d="M13.0494 11.3827C13.0494 10.8032 12.5796 10.3333 12 10.3333C11.4204 10.3333 10.9506 10.8032 10.9506 11.3827C10.9506 11.9623 11.4204 12.4321 12 12.4321C12.5796 12.4321 13.0494 11.9623 13.0494 11.3827Z"
        fill="#1D1D1D"
      />
      <path
        d="M15.7037 12.4321C15.1242 12.4321 14.6543 11.9623 14.6543 11.3827C14.6543 10.8032 15.1242 10.3333 15.7037 10.3333C16.2833 10.3333 16.7531 10.8032 16.7531 11.3827C16.7531 11.9623 16.2833 12.4321 15.7037 12.4321Z"
        fill="#1D1D1D"
      />
      <path
        d="M8.2963 16.1358C7.71672 16.1358 7.24691 15.666 7.24691 15.0864C7.24691 14.5069 7.71672 14.037 8.2963 14.037C8.87587 14.037 9.34568 14.5069 9.34568 15.0864C9.34568 15.666 8.87587 16.1358 8.2963 16.1358Z"
        fill="#1D1D1D"
      />
      <path
        d="M13.0494 15.0864C13.0494 14.5069 12.5796 14.037 12 14.037C11.4204 14.037 10.9506 14.5069 10.9506 15.0864C10.9506 15.666 11.4204 16.1358 12 16.1358C12.5796 16.1358 13.0494 15.666 13.0494 15.0864Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.7531 15.0864C16.7531 14.5069 16.2833 14.037 15.7037 14.037C15.1242 14.037 14.6543 14.5069 14.6543 15.0864C14.6543 15.666 15.1242 16.1358 15.7037 16.1358C16.2833 16.1358 16.7531 15.666 16.7531 15.0864Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78191 2.40947C5.90738 2.15852 6.16387 2 6.44445 2H17.5556C17.8361 2 18.0926 2.15852 18.2181 2.40947L19.446 4.86535C19.4944 4.95729 19.524 5.06064 19.5298 5.17032C19.5303 5.17906 19.5306 5.18781 19.5307 5.19656L19.5309 5.21141V20.5185H21.2593C21.6684 20.5185 22 20.8502 22 21.2593C22 21.6684 21.6684 22 21.2593 22H2.74074C2.33164 22 2 21.6684 2 21.2593C2 20.8502 2.33164 20.5185 2.74074 20.5185H4.46914V5.20988L4.46914 5.20665C4.46967 5.08343 4.50028 4.9673 4.55402 4.86525L5.78191 2.40947ZM18.0494 20.5185V5.95062H5.95062V20.5185H8.91358V19.9012C8.91358 18.2648 10.2401 16.9383 11.8765 16.9383C13.5129 16.9383 14.8395 18.2648 14.8395 19.9012V20.5185H18.0494ZM13.358 20.5185V19.9012C13.358 19.083 12.6947 18.4198 11.8765 18.4198C11.0583 18.4198 10.3951 19.083 10.3951 19.9012V20.5185H13.358ZM6.90225 3.48148L6.40842 4.46914H17.5916L17.0978 3.48148H6.90225Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Company;
