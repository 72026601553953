import { actionTypes } from '../actions/measurementUnit';
import { MEASUREMENT_UNITS } from '../utils/constants';

const ACTION_HANDLERS = {
  [actionTypes.CHANGE_MEASUREMENT_UNIT]: (state, action) => ({
    unit: action.measurementUnit,
  }),
};

const initialState = {
  unit: MEASUREMENT_UNITS.FT,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
