import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/ui/DeprecatedIcon';
import classNames from 'classnames';

const AccountItem = ({ name, accountIconType, selected, ...props }) => (
  <div
    className={classNames('account-item', {
      'account-item--selected': selected,
    })}
    {...props}
  >
    <Icon id="account" type={accountIconType} />
    <div className="account-item__name">{name}</div>
    <button
      className={classNames('circle', {
        'circle--selected': selected,
      })}
    />
  </div>
);

AccountItem.propTypes = {
  name: PropTypes.string,
  accountIconType: PropTypes.string,
  selected: PropTypes.bool,
};

export default AccountItem;
