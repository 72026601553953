import { ThemeableOnlyIconProps } from '../types';

const CloseCircleOutlined = ({
  width = '28',
  height = '28',
  theme = 'light',
}: ThemeableOnlyIconProps) => {
  return theme === 'light' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="28" height="28" rx="14" fill="#F5F5F5" />
        <rect x="1" y="1" width="28" height="28" rx="14" stroke="#979797" />
        <path
          d="M9.65355 19.055C9.31764 19.3909 9.31078 19.9873 9.66041 20.3369C10.0169 20.6866 10.6133 20.6797 10.9424 20.3507L15.0008 16.2923L19.0523 20.3438C19.3951 20.6866 19.9846 20.6866 20.3343 20.3369C20.6839 19.9805 20.6839 19.3978 20.3411 19.055L16.2896 15.0035L20.3411 10.9451C20.6839 10.6023 20.6907 10.0127 20.3343 9.66311C19.9846 9.31348 19.3951 9.31348 19.0523 9.65625L15.0008 13.7078L10.9424 9.65625C10.6133 9.32034 10.01 9.30663 9.66041 9.66311C9.31078 10.0127 9.31764 10.616 9.65355 10.9451L13.7051 15.0035L9.65355 19.055Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="1" y="1" width="28" height="28" rx="14" fill="#282828" />
        <rect x="1" y="1" width="28" height="28" rx="14" stroke="#4A4A4A" />
        <path
          d="M9.65355 19.055C9.31764 19.3909 9.31078 19.9873 9.66041 20.3369C10.0169 20.6866 10.6133 20.6797 10.9424 20.3507L15.0008 16.2923L19.0523 20.3438C19.3951 20.6866 19.9846 20.6866 20.3343 20.3369C20.6839 19.9805 20.6839 19.3978 20.3411 19.055L16.2896 15.0035L20.3411 10.9451C20.6839 10.6023 20.6907 10.0127 20.3343 9.66311C19.9846 9.31348 19.3951 9.31348 19.0523 9.65625L15.0008 13.7078L10.9424 9.65625C10.6133 9.32034 10.01 9.30663 9.66041 9.66311C9.31078 10.0127 9.31764 10.616 9.65355 10.9451L13.7051 15.0035L9.65355 19.055Z"
          fill="#B9B9B9"
        />
      </svg>;
};

export default CloseCircleOutlined;
