import actionTypes from '../actions/settings/actions';

const ACTION_HANDLERS = {
  [actionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({ ...state }),
};

const initialState = {
  settings: {},
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
