import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Undo = ({ fill = colors.white }: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3319_25177)">
      <path
        d="M5.44693 3.11285C5.42382 3.11291 5.40121 3.1061 5.38198 3.09328C5.36274 3.08047 5.34775 3.06223 5.33889 3.04088C5.33004 3.01953 5.32773 2.99603 5.33225 2.97337C5.33678 2.9507 5.34794 2.92989 5.36431 2.91358L6.27804 1.99984C6.36956 1.90832 6.42098 1.7842 6.42098 1.65476C6.42098 1.52533 6.36956 1.40121 6.27804 1.30968C6.18652 1.21816 6.06239 1.16675 5.93296 1.16675C5.80353 1.16675 5.6794 1.21816 5.58788 1.30968L3.64377 3.2538C3.59821 3.29898 3.56205 3.35274 3.53738 3.41196C3.5127 3.47119 3.5 3.53472 3.5 3.59888C3.5 3.66304 3.5127 3.72657 3.53738 3.78579C3.56205 3.84502 3.59821 3.89878 3.64377 3.94396L5.58788 5.88807C5.6332 5.93339 5.68699 5.96933 5.7462 5.99386C5.80541 6.01838 5.86887 6.03101 5.93296 6.03101C5.99705 6.03101 6.06051 6.01838 6.11972 5.99386C6.17892 5.96933 6.23272 5.93339 6.27804 5.88807C6.32336 5.84275 6.3593 5.78896 6.38383 5.72975C6.40835 5.67054 6.42098 5.60708 6.42098 5.54299C6.42098 5.4789 6.40835 5.41544 6.38383 5.35623C6.3593 5.29703 6.32336 5.24323 6.27804 5.19791L5.36431 4.28418C5.34794 4.26786 5.33678 4.24705 5.33225 4.22439C5.32773 4.20172 5.33004 4.17822 5.33889 4.15688C5.34775 4.13553 5.36274 4.11729 5.38198 4.10447C5.40121 4.09166 5.42382 4.08485 5.44693 4.08491C6.47815 4.08491 7.46714 4.49456 8.19632 5.22374C8.92551 5.95292 9.33516 6.94191 9.33516 7.97313C9.33516 9.00435 8.92551 9.99334 8.19632 10.7225C7.46714 11.4517 6.47815 11.8614 5.44693 11.8614C5.31803 11.8614 5.19441 11.9126 5.10326 12.0037C5.01211 12.0949 4.9609 12.2185 4.9609 12.3474C4.9609 12.4763 5.01211 12.5999 5.10326 12.6911C5.19441 12.7822 5.31803 12.8334 5.44693 12.8334C6.73596 12.8334 7.97219 12.3213 8.88367 11.4099C9.79515 10.4984 10.3072 9.26216 10.3072 7.97313C10.3072 6.6841 9.79515 5.44787 8.88367 4.53639C7.97219 3.62491 6.73596 3.11285 5.44693 3.11285V3.11285Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_3319_25177">
        <rect width="14" height="14" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

export default Undo;
