import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Overview = ({
  fill = colors.white,
  width = '24',
  height = '24',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M21.874 4H3.12604C2.78381 4 2.5 4.30306 2.5 4.66852V19.3315C2.5 19.6969 2.78381 20 3.12604 20H21.874C22.2162 20 22.5 19.6969 22.5 19.3315V4.66852C22.5 4.30306 22.2162 4 21.874 4ZM21.2479 8.53705L12.6336 15.5521L9.24457 10.8279L15.9975 5.33705H21.2479V8.53705ZM13.9357 5.33705L3.75209 13.6357V5.33705H13.9357ZM3.75209 15.3114L8.2596 11.639L13.293 18.663H3.75209V15.3114ZM14.8623 18.663L13.3932 16.6217L21.2479 10.2217V18.663H14.8623Z"
      fill={fill}
    />
  </svg>
);

export default Overview;
