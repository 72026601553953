import { createSelector } from 'reselect';
import { chain } from 'lodash';
import {
  PRO_LIMITED_PLANS,
  ACTIVE_STATUS,
  EXPIRED_STATUS,
  TRIAL_STATUS,
  FREE_PLAN,
  PRO_UNLIMITED_PLANS,
  FREE_STATUS,
  CANCELLED_STATUS,
  MOBILE_SUBSCRIPTION_SOURCES,
} from 'utils/constants';

const getUserSubscription = (subscriptions = {}) =>
  subscriptions.userSubscription;
const getAllUserSubscription = (subscriptions = {}) =>
  subscriptions.allUserSubscription;
const getSubscriptionsData = (subscriptions, teamId) => ({
  subscriptions,
  teamId,
});
const getUserBasePlanAttributes = (user) => user;

export const isLegacyUser = false;

export const subscriptionActive = createSelector(
  [getUserSubscription],
  (userSubscription = {}) => userSubscription.status === ACTIVE_STATUS,
);

export const subscriptionCancelled = createSelector(
  [getUserSubscription],
  (userSubscription = {}) => userSubscription.status === CANCELLED_STATUS,
);

export const subscriptionActiveOrCancelled = createSelector(
  [getUserSubscription],
  (userSubscription = {}) =>
    userSubscription.status === CANCELLED_STATUS ||
    userSubscription.status === ACTIVE_STATUS,
);

export const subscriptionExpired = createSelector(
  [getUserSubscription],
  (userSubscription = {}) => userSubscription.status === EXPIRED_STATUS,
);

export const subscriptionFree = createSelector(
  [getUserSubscription],
  (userSubscription = {}) => userSubscription.status === FREE_STATUS,
);

export const isDiscoverPlan = createSelector(
  [getUserSubscription],
  (userSubscription = {}) =>
    userSubscription.plan_info?.code.includes('discover'),
);

export const getIndividualSubscription = createSelector(
  [getAllUserSubscription],
  (allUserSubscription = {}) => allUserSubscription.individual,
);

export const hasIndividualDiscoverPlan = createSelector(
  [getIndividualSubscription],
  (individual = {}) => individual.plan_info?.code.includes('discover'),
);

export const isAdmin = createSelector(
  [getUserBasePlanAttributes],
  (user = {}) => user?.user?.admin,
);

export const onTrialUser = createSelector(
  [getUserSubscription],
  (userSubscription = {}) => userSubscription.status === TRIAL_STATUS,
);

export const isFreePlan = createSelector(
  [getUserSubscription],
  (userSubscription = {}) =>
    userSubscription.status === FREE_PLAN ||
    userSubscription.plan_info?.code === FREE_PLAN,
);

export const isAssociatedWithOrganization = createSelector(
  [getAllUserSubscription],
  (allUserSubscription = {}) =>
    allUserSubscription.organizations &&
    allUserSubscription.organizations.length !== 0,
);

export const isProPlan = createSelector(
  [getUserSubscription],
  (userSubscription = {}) =>
    PRO_LIMITED_PLANS.includes(userSubscription.plan_info?.code),
);

export const isProUnlimitedPlan = createSelector(
  [getUserSubscription],
  (userSubscription = {}) =>
    PRO_UNLIMITED_PLANS.includes(userSubscription.plan_info?.code),
);

export const isIosUser = createSelector(
  [getUserBasePlanAttributes],
  (user = {}) => user.sign_up_tracking?.includes('ios'),
);

export const isLiteUser = createSelector(
  [getUserBasePlanAttributes],
  (user = {}) => user.sign_up_tracking?.includes('mrlite_ios'),
);

export const isEmailVerified = createSelector(
  [getUserBasePlanAttributes],
  (user = {}) => !!user?.email_verified,
);

export const getSubscriptionPlan = createSelector(
  [getUserBasePlanAttributes],
  (user = {}) => user?.subscription_plan ?? null,
);

export const mobileSubscription = createSelector(
  [getUserSubscription],
  (userSubscription = {}) =>
    MOBILE_SUBSCRIPTION_SOURCES.some((value) =>
      userSubscription.source?.includes(value),
    ),
);

const findTeamSubscription = (subscriptionsData) => {
  const { subscriptions, teamId } = subscriptionsData;
  return chain(subscriptions?.allUserSubscription?.organizations)
    .flatMap('teams')
    .flatMap('team_subscriptions')
    .find({ team_subscription: { owner_id: teamId } })
    .value();
};

export const getTeamSubscription = createSelector(
  [getSubscriptionsData],
  (subscriptionsData) => {
    const teamSubscription = findTeamSubscription(subscriptionsData);
    return teamSubscription?.subscription;
  },
);

export const isTeamSubscriptionDiscover = createSelector(
  [getSubscriptionsData],
  (subscriptionsData) => {
    const teamSubscription = findTeamSubscription(subscriptionsData);
    return teamSubscription?.subscription.plan_info?.code.includes('discover');
  },
);

export const isOrganizationSelected = createSelector(
  [(state) => state.user?.selectedAccount],
  (selectedAccount = {}) => selectedAccount?.type === 'organizations',
);

export const selectedAccountSelector = createSelector(
  [(state) => state.user.selectedAccount],
  (selectedAccount) => selectedAccount,
);

export const hasDiscoverInSelectedSubscription = createSelector(
  [(state) => state.subscriptions, selectedAccountSelector],
  (subscriptions, selectedAccount) => {
    return (
        isOrganizationSelected({
          user: { selectedAccount },
        })
      ) ?
        isTeamSubscriptionDiscover(subscriptions, selectedAccount?.id)
      : isDiscoverPlan(subscriptions);
  },
);

export const isIndividualOnTrial = createSelector(
  [getIndividualSubscription],
  (individualSubscription = {}) =>
    individualSubscription?.status === TRIAL_STATUS,
);

export const individualSubscriptionExpired = createSelector(
  [getIndividualSubscription],
  (individualSubscription = {}) =>
    individualSubscription?.status === EXPIRED_STATUS,
);

const isPaidPlan = (subscriptions) =>
  isDiscoverPlan(subscriptions) ||
  isProPlan(subscriptions) ||
  isProUnlimitedPlan(subscriptions);

export const isSubscriptionFreeOrExpired = createSelector(
  [(state) => state.subscriptions],
  (subscriptions) =>
    subscriptionFree(subscriptions) ||
    (isPaidPlan(subscriptions) && subscriptionExpired(subscriptions)),
);
