import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Save = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 13C17 15.2091 15.2091 17 13 17C10.7909 17 9 15.2091 9 13C9 10.7909 10.7909 9 13 9C15.2091 9 17 10.7909 17 13ZM15.5 13C15.5 14.3807 14.3807 15.5 13 15.5C11.6193 15.5 10.5 14.3807 10.5 13C10.5 11.6193 11.6193 10.5 13 10.5C14.3807 10.5 15.5 11.6193 15.5 13Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.3775 2.6225C6.77496 2.22504 7.32674 2 7.90381 2H19.8947C21.0574 2 22 2.89543 22 4V20C22 21.1046 21.0574 22 19.8947 22H4.10526C2.94256 22 2 21.1046 2 20V7.79855C2 7.28586 2.20725 6.79275 2.57895 6.42105L6.3775 2.6225ZM20.4211 4V20C20.4211 20.2761 20.1854 20.5 19.8947 20.5H4.10526C3.81459 20.5 3.57895 20.2761 3.57895 20V7.79855C3.57895 7.67038 3.63076 7.5471 3.72368 7.45418L7.33306 3.8448V5C7.33306 6.10457 8.27562 7 9.43832 7H15.8945C17.0572 7 17.9997 6.10457 17.9997 5V3.5H19.8947C20.1854 3.5 20.4211 3.72386 20.4211 4ZM16.6664 3.5H8.66639V4.1875C8.66639 5.05044 9.40277 5.75 10.3111 5.75H15.0217C15.93 5.75 16.6664 5.05044 16.6664 4.1875V3.5Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.3775 2.6225C6.77496 2.22504 7.32674 2 7.90381 2H19.8947C21.0574 2 22 2.89543 22 4V20C22 21.1046 21.0574 22 19.8947 22H4.10526C2.94256 22 2 21.1046 2 20V7.79855C2 7.28586 2.20725 6.79275 2.57895 6.42105L6.3775 2.6225ZM17 13C17 15.2091 15.2091 17 13 17C10.7909 17 9 15.2091 9 13C9 10.7909 10.7909 9 13 9C15.2091 9 17 10.7909 17 13ZM19 3.5H8V4.5C8 5.60457 8.89543 6.5 10 6.5H17C18.1046 6.5 19 5.60457 19 4.5V3.5Z"
          fill={color}
        />
      </svg>;
};

export default Save;
