/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Duplicate = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66602 2.49996C1.66602 2.03972 2.03911 1.66663 2.49935 1.66663H13.3327C13.7929 1.66663 14.166 2.03972 14.166 2.49996V13.3333C14.166 13.7935 13.7929 14.1666 13.3327 14.1666H2.49935C2.03911 14.1666 1.66602 13.7935 1.66602 13.3333V2.49996Z"
      fill={fill}
    />
    <path
      d="M6.45768 15C6.1125 15 5.83268 15.2798 5.83268 15.625V17.5C5.83268 17.9602 6.20578 18.3333 6.66602 18.3333H17.4993C17.9596 18.3333 18.3327 17.9602 18.3327 17.5V6.66663C18.3327 6.20639 17.9596 5.83329 17.4993 5.83329H15.6243C15.2792 5.83329 14.9993 6.11311 14.9993 6.45829C14.9993 6.80347 15.2792 7.08329 15.6243 7.08329H17.0827V17.0833H7.08268V15.625C7.08268 15.2798 6.80286 15 6.45768 15Z"
      fill={fill}
    />
  </svg>
);

Duplicate.propTypes = {
  fill: PropTypes.string,
};

Duplicate.defaultProps = {
  fill: '#1D1D1D',
};

export default Duplicate;
