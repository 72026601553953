import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const QuestionMark = ({ fill = colors.grayScale100 }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.77734 7.77783C7.77743 7.37044 7.88948 6.97092 8.10128 6.62292C8.31308 6.27492 8.61645 5.99184 8.97831 5.80461C9.34008 5.61738 9.74645 5.53322 10.1528 5.56132C10.5593 5.58942 10.9502 5.7287 11.2828 5.96394C11.6153 6.19918 11.8769 6.52132 12.0388 6.89515C12.2007 7.26899 12.2567 7.68014 12.2007 8.08366C12.1447 8.48718 11.9789 8.86758 11.7213 9.18321C11.4638 9.49884 11.1244 9.73758 10.7403 9.87336C10.5236 9.95002 10.3359 10.0919 10.2033 10.2797C10.0706 10.4674 9.99942 10.6917 9.99957 10.9215V11.6667"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8522 9.99996C16.8522 13.7841 13.7845 16.8518 10.0003 16.8518C6.21616 16.8518 3.14847 13.7841 3.14847 9.99996C3.14847 6.21579 6.21616 3.14811 10.0003 3.14811C13.7845 3.14811 16.8522 6.21579 16.8522 9.99996ZM10.0003 18.3333C14.6027 18.3333 18.3337 14.6023 18.3337 9.99996C18.3337 5.39759 14.6027 1.66663 10.0003 1.66663C5.39796 1.66663 1.66699 5.39759 1.66699 9.99996C1.66699 14.6023 5.39796 18.3333 10.0003 18.3333ZM10.8337 14.1666C10.8337 14.6269 10.4606 15 10.0003 15C9.54009 15 9.16699 14.6269 9.16699 14.1666C9.16699 13.7064 9.54009 13.3333 10.0003 13.3333C10.4606 13.3333 10.8337 13.7064 10.8337 14.1666Z"
      fill={fill}
    />
  </svg>
);

export default QuestionMark;
