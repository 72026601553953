import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Information = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.6771 3.78299C8.52293 3.67998 8.34167 3.62499 8.15625 3.62499C7.90761 3.62499 7.66916 3.72376 7.49334 3.89958C7.31753 4.07539 7.21875 4.31385 7.21875 4.56249C7.21875 4.74791 7.27374 4.92917 7.37675 5.08334C7.47976 5.23751 7.62618 5.35767 7.79749 5.42863C7.96879 5.49959 8.15729 5.51815 8.33915 5.48198C8.52101 5.4458 8.68806 5.35652 8.81917 5.2254C8.95028 5.09429 9.03957 4.92725 9.07574 4.74539C9.11191 4.56353 9.09335 4.37503 9.02239 4.20373C8.95143 4.03242 8.83127 3.886 8.6771 3.78299Z"
          fill={color}
        />
        <path
          d="M7.0625 12.0625H9.5625C9.72826 12.0625 9.88723 11.9966 10.0044 11.8794C10.1217 11.7622 10.1875 11.6033 10.1875 11.4375C10.1875 11.2717 10.1217 11.1128 10.0044 10.9955C9.88723 10.8783 9.72826 10.8125 9.5625 10.8125H9.09375C9.05231 10.8125 9.01257 10.796 8.98327 10.7667C8.95397 10.7374 8.9375 10.6977 8.9375 10.6562V7.68749C8.9375 7.35597 8.80581 7.03803 8.57139 6.80361C8.33697 6.56919 8.01902 6.43749 7.6875 6.43749H7.0625C6.89674 6.43749 6.73777 6.50334 6.62056 6.62055C6.50335 6.73776 6.4375 6.89673 6.4375 7.06249C6.4375 7.22825 6.50335 7.38722 6.62056 7.50443C6.73777 7.62164 6.89674 7.68749 7.0625 7.68749H7.53125C7.57269 7.68749 7.61244 7.70395 7.64174 7.73326C7.67104 7.76256 7.6875 7.8023 7.6875 7.84374V10.6562C7.6875 10.6977 7.67104 10.7374 7.64174 10.7667C7.61244 10.796 7.57269 10.8125 7.53125 10.8125H7.0625C6.89674 10.8125 6.73777 10.8783 6.62056 10.9955C6.50335 11.1128 6.4375 11.2717 6.4375 11.4375C6.4375 11.6033 6.50335 11.7622 6.62056 11.8794C6.73777 11.9966 6.89674 12.0625 7.0625 12.0625Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM14.375 8C14.375 11.5208 11.5208 14.375 8 14.375C4.47918 14.375 1.625 11.5208 1.625 8C1.625 4.47918 4.47918 1.625 8 1.625C11.5208 1.625 14.375 4.47918 14.375 8Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10.2083 4.16667C10.4556 4.16667 10.6972 4.23998 10.9028 4.37733C11.1084 4.51468 11.2686 4.7099 11.3632 4.93831C11.4578 5.16672 11.4825 5.41805 11.4343 5.66053C11.3861 5.903 11.267 6.12573 11.0922 6.30055C10.9174 6.47536 10.6947 6.59442 10.4522 6.64265C10.2097 6.69088 9.95839 6.66612 9.72998 6.57152C9.50157 6.47691 9.30635 6.31669 9.169 6.11113C9.03165 5.90557 8.95834 5.66389 8.95834 5.41667C8.95834 5.08514 9.09003 4.7672 9.32445 4.53278C9.55887 4.29836 9.87682 4.16667 10.2083 4.16667ZM12.0833 15.4167H8.75C8.52899 15.4167 8.31703 15.3289 8.16075 15.1726C8.00447 15.0163 7.91667 14.8043 7.91667 14.5833C7.91667 14.3623 8.00447 14.1504 8.16075 13.9941C8.31703 13.8378 8.52899 13.75 8.75 13.75H9.375C9.43026 13.75 9.48325 13.728 9.52232 13.689C9.56139 13.6499 9.58334 13.5969 9.58334 13.5417V9.79167C9.58334 9.73641 9.56139 9.68342 9.52232 9.64435C9.48325 9.60528 9.43026 9.58333 9.375 9.58333H8.75C8.52899 9.58333 8.31703 9.49553 8.16075 9.33925C8.00447 9.18297 7.91667 8.97101 7.91667 8.75C7.91667 8.52899 8.00447 8.31702 8.16075 8.16074C8.31703 8.00446 8.52899 7.91667 8.75 7.91667H9.58334C10.0254 7.91667 10.4493 8.09226 10.7618 8.40482C11.0744 8.71738 11.25 9.1413 11.25 9.58333V13.5417C11.25 13.5969 11.272 13.6499 11.311 13.689C11.3501 13.728 11.4031 13.75 11.4583 13.75H12.0833C12.3043 13.75 12.5163 13.8378 12.6726 13.9941C12.8289 14.1504 12.9167 14.3623 12.9167 14.5833C12.9167 14.8043 12.8289 15.0163 12.6726 15.1726C12.5163 15.3289 12.3043 15.4167 12.0833 15.4167Z"
          fill={color}
        />
      </svg>;
};

export default Information;
