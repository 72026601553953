import { FEATURES_PANEL_INDEX } from 'utils/constants';
import { actionTypes as helpCenterActionTypes } from '../actions/helpCenter';
import { actionTypes as highlightActionTypes } from '../actions/highlight';
import settingsActionTypes from '../actions/settings/actions';
import searchActionTypes from '../actions/search/actions';
import actionTypes from '../actions/editSidebar/actions';
import filterActionTypes from '../actions/filters/actions';
import layerPopupsActionTypes from '../actions/parcels/actions';
import mapActionsTypes from '../actions/map/actions';

const ACTION_HANDLERS = {
  [actionTypes.CHANGE_ACTIVE_PANEL]: (state, action) => ({
    ...state,
    activePanel: action.panelName,
    activePanelProps: action.props,
    scrollSidebar: null,
    collapsed: state.collapsed,
  }),
  [actionTypes.CHANGE_ACTIVE_PANEL_ITEM]: (state, action) => ({
    ...state,
    scrollSidebar: null,
    activePanelItems: [action.panelItem],
  }),
  [actionTypes.ADD_ACTIVE_PANEL_ITEM]: (state, action) => {
    if (state.activePanelItems.indexOf(action.panelItem) === -1) {
      return {
        ...state,
        scrollSidebar: null,
        activePanelItems: state.activePanelItems.concat(action.panelItem),
      };
    }
    return state;
  },
  [actionTypes.REMOVE_ACTIVE_PANEL_ITEM]: (state, action) => {
    const index = state.activePanelItems.indexOf(action.panelItem);
    let newActivePanels = state.activePanelItems;

    if (index >= 0) {
      newActivePanels = state.activePanelItems
        .slice(0, index)
        .concat(state.activePanelItems.slice(index + 1));
      return {
        ...state,
        scrollSidebar: null,
        activePanelItems: newActivePanels,
      };
    }

    return state;
  },
  [actionTypes.SET_ACTIVE_PANEL_ITEMS]: (state, action) => ({
    ...state,
    scrollSidebar: null,
    activePanelItems: action.panelItems,
  }),
  [actionTypes.TOGGLE_COLLAPSED]: (state, action) => ({
    ...state,
    collapsed: !state.collapsed,
  }),
  [actionTypes.CHANGE_EDIT_PANEL]: (state, action) => ({
    ...state,
    editPanel: action.panel,
    editPanelProps: action.props,
  }),
  [actionTypes.HIGHLIGHT_LAYER_ITEM]: (state, action) => ({
    ...state,
    highlightedLayerFeature: action.feature,
  }),
  [highlightActionTypes.HIGHLIGHT]: (state, action) => {
    if (action.element.indexOf('search') >= 0) {
      return {
        ...state,
        activePanel: 'SEARCH',
        scrollSidebar: null,
      };
    } else if (action.element.indexOf('mapping_tools') >= 0) {
      const panelOpen = state.activePanelItems.includes(1);
      let newActivePanels = state.activePanelItems;

      if (!panelOpen) {
        newActivePanels = state.activePanelItems.concat(2);
      }
      let scrollSidebar = 'top';
      if (
        action.element === 'lines_mapping_tools' ||
        action.element === 'polygons_mapping_tools'
      ) {
        scrollSidebar = 'bottom';
      }
      return {
        ...state,
        activePanel: 'MAP_TOOLS',
        scrollSidebar,
        activePanelItems: newActivePanels,
      };
    } else if (action.element === 'mapright_layers') {
      const panelOpen = state.activePanelItems.includes(2);
      let newActivePanels = state.activePanelItems;

      if (!panelOpen) {
        newActivePanels = state.activePanelItems.concat(1);
      }
      return {
        ...state,
        activePanel: 'MAP_TOOLS',
        activePanelProps: {},
        scrollSidebar: 'top',
        activePanelItems: newActivePanels,
      };
    }

    return {
      ...state,
      scrollSidebar: null,
    };
  },
  [helpCenterActionTypes.OPEN_HELP_CENTER]: (state, action) => ({
    ...state,
    activePanel: 'HELP_CENTER',
    activePanelProps: {},
    scrollSidebar: null,
  }),
  [mapActionsTypes.LOAD_MAP_SUCCESS]: (state, action) => ({
    ...state,
    activePanel: 'MAP_TOOLS',
    activePanelProps: {},
  }),
  [mapActionsTypes.LOAD_NEW_MAP]: (state, action) => ({
    ...state,
    activePanel: 'SEARCH',
    activePanelProps: {},
  }),
  [mapActionsTypes.CREATE_MAP_SUCCESS]: (state, action) => ({
    ...state,
    activePanel: 'MAP_TOOLS',
    activePanelProps: {},
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
  [layerPopupsActionTypes.START_PARCEL_SELECTION]: (state, action) => ({
    ...initialState,
    activePanel: 'PARCELS_MERGE',
  }),
  [searchActionTypes.SEARCHING_PARCELS]: (state, action) => ({
    ...initialState,
    activePanel: 'PARCELS',
    activePanelProps: action.activePanelProps || {},
  }),
  [layerPopupsActionTypes.STOP_PARCEL_SELECTION]: (state, action) => ({
    ...initialState,
    activePanel: 'MAP_TOOLS',
  }),
  [filterActionTypes.CHANGE_FEATURE_ID]: (state, action) => {
    if (
      state.editPanel !== 'EDIT_TOOL_INFO' ||
      !state.editPanelProps ||
      !state.editPanelProps.toolId
    ) {
      return state;
    }

    return {
      ...state,
      editPanelProps: {
        ...state.editPanelProps,
        toolId: action.newId,
      },
    };
  },
  [mapActionsTypes.REMOVE_TOOL_FROM_MAP]: (state, action) => {
    if (
      state.editPanel !== 'EDIT_TOOL_INFO' ||
      !state.editPanelProps ||
      !state.editPanelProps.feature
    ) {
      return state;
    }

    if (state.editPanelProps.feature !== action.maprightId) {
      return state;
    }

    return {
      ...state,
      editPanel: null,
      editPanelProps: null,
    };
  },
};

const initialState = {
  scrollSidebar: null,
  activePanel: 'MAP_TOOLS',
  activePanelProps: {},
  activePanelItems: [FEATURES_PANEL_INDEX],
  collapsed: false,
  editPanel: null,
  editPanelProps: null,
  highlightedLayerFeature: null,
  editModuleExpanded: false,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
