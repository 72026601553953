/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Dots = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66602 2.88893C6.66602 3.30149 6.8299 3.69715 7.12163 3.98887C7.41335 4.2806 7.80901 4.44448 8.22157 4.44448C8.63413 4.44448 9.02979 4.2806 9.32151 3.98887C9.61324 3.69715 9.77713 3.30149 9.77713 2.88893C9.77713 2.47637 9.61324 2.08071 9.32151 1.78899C9.02979 1.49726 8.63413 1.33337 8.22157 1.33337C7.80901 1.33337 7.41335 1.49726 7.12163 1.78899C6.8299 2.08071 6.66602 2.47637 6.66602 2.88893ZM6.66602 13.1111C6.66602 13.5237 6.8299 13.9193 7.12163 14.211C7.41335 14.5028 7.80901 14.6667 8.22157 14.6667C8.63413 14.6667 9.02979 14.5028 9.32151 14.211C9.61324 13.9193 9.77713 13.5237 9.77713 13.1111C9.77713 12.6985 9.61324 12.3029 9.32151 12.0112C9.02979 11.7194 8.63413 11.5555 8.22157 11.5555C7.80901 11.5555 7.41335 11.7194 7.12163 12.0112C6.8299 12.3029 6.66602 12.6985 6.66602 13.1111ZM7.12163 9.10004C6.8299 8.80832 6.66602 8.41265 6.66602 8.00009C6.66602 7.58754 6.8299 7.19187 7.12163 6.90015C7.41335 6.60843 7.80901 6.44454 8.22157 6.44454C8.63413 6.44454 9.02979 6.60843 9.32151 6.90015C9.61324 7.19187 9.77713 7.58754 9.77713 8.00009C9.77713 8.41265 9.61324 8.80832 9.32151 9.10004C9.02979 9.39176 8.63413 9.55565 8.22157 9.55565C7.80901 9.55565 7.41335 9.39176 7.12163 9.10004Z"
      fill={fill}
    />
  </svg>
);

Dots.propTypes = {
  fill: PropTypes.string,
};

Dots.defaultProps = {
  fill: '#1D1D1D',
};

export default Dots;
