import { StyleableOnlyIconProps } from '../types';
import * as colors from '../../styles/colors.module.scss';

const ChevronLeft = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colors.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1545 4.67161C17.5572 4.34205 17.6165 3.74842 17.2871 3.34566C16.9576 2.94285 16.364 2.88346 15.9613 3.21306L6.68391 10.8063C5.77203 11.5527 5.77203 12.9474 6.68391 13.6937L15.9613 21.287C16.364 21.6166 16.9576 21.5572 17.2871 21.1544C17.6165 20.7516 17.5572 20.158 17.1545 19.8284L7.89523 12.25L17.1545 4.67161Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.79904 11.9692C5.79904 11.4453 6.02353 10.9964 6.39769 10.697L14.7041 3.28864C15.3027 2.83965 16.2007 2.91448 16.6497 3.51313C17.0987 4.11179 17.0987 4.93495 16.5 5.38394L9.16648 11.8195C9.09165 11.8943 9.09165 11.9692 9.16648 12.1188L16.5 18.5544C17.0987 19.0782 17.1735 19.9014 16.6497 20.5C16.1259 21.0987 15.3027 21.1735 14.7041 20.6497C14.7041 20.6497 14.7041 20.6497 14.6292 20.5749L6.32286 13.2413C6.02353 12.942 5.79904 12.4182 5.79904 11.9692Z"
          fill={color}
        />
      </svg>;
};

export default ChevronLeft;
