import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Trash = ({
  fill = colors.fail500,
  width = '18',
  height = '18',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M13.8125 6.1875H4.4375C4.35462 6.1875 4.27513 6.22042 4.21653 6.27903C4.15792 6.33763 4.125 6.41712 4.125 6.5V15.25C4.125 15.5815 4.2567 15.8995 4.49112 16.1339C4.72554 16.3683 5.04348 16.5 5.375 16.5H12.875C13.2065 16.5 13.5245 16.3683 13.7589 16.1339C13.9933 15.8995 14.125 15.5815 14.125 15.25V6.5C14.125 6.41712 14.0921 6.33763 14.0335 6.27903C13.9749 6.22042 13.8954 6.1875 13.8125 6.1875Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.375 4H12.4062C12.3648 4 12.3251 3.98354 12.2958 3.95424C12.2665 3.92493 12.25 3.88519 12.25 3.84375V3.0625C12.25 2.6481 12.0854 2.25067 11.7924 1.95765C11.4993 1.66462 11.1019 1.5 10.6875 1.5H7.5625C7.1481 1.5 6.75067 1.66462 6.45765 1.95765C6.16462 2.25067 6 2.6481 6 3.0625V3.84375C6 3.88519 5.98354 3.92493 5.95424 3.95424C5.92493 3.98354 5.88519 4 5.84375 4H2.875C2.70924 4 2.55027 4.06585 2.43306 4.18306C2.31585 4.30027 2.25 4.45924 2.25 4.625C2.25 4.79076 2.31585 4.94973 2.43306 5.06694C2.55027 5.18415 2.70924 5.25 2.875 5.25H15.375C15.5408 5.25 15.6997 5.18415 15.8169 5.06694C15.9342 4.94973 16 4.79076 16 4.625C16 4.45924 15.9342 4.30027 15.8169 4.18306C15.6997 4.06585 15.5408 4 15.375 4ZM7.25 3.84375V3.0625C7.25 2.97962 7.28292 2.90013 7.34153 2.84153C7.40013 2.78292 7.47962 2.75 7.5625 2.75H10.6875C10.7704 2.75 10.8499 2.78292 10.9085 2.84153C10.9671 2.90013 11 2.97962 11 3.0625V3.84375C11 3.88519 10.9835 3.92493 10.9542 3.95424C10.9249 3.98354 10.8852 4 10.8438 4H7.40625C7.36481 4 7.32507 3.98354 7.29576 3.95424C7.26646 3.92493 7.25 3.88519 7.25 3.84375Z"
      fill={fill}
    />
  </svg>
);

export default Trash;
