const LandId = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4143_8292)">
      <path
        d="M-0.0447998 19.3948C-0.0447998 12.6145 -0.0448014 9.22348 1.27478 6.63376C2.43581 4.35604 4.28731 2.50283 6.56504 1.34351C9.15475 0.0239259 12.5458 0.0239258 19.3261 0.0239258H20.5366C27.3169 0.0239258 30.7079 0.0239259 33.2976 1.34351C35.5753 2.50453 37.4286 4.35604 38.5879 6.63376C39.9075 9.22348 39.9075 12.6145 39.9075 19.3948V20.6053C39.9075 27.3856 39.9075 30.7766 38.5879 33.3664C37.4269 35.6441 35.5753 37.4973 33.2976 38.6566C30.7079 39.9762 27.3169 39.9762 20.5366 39.9762H19.3261C12.5458 39.9762 9.15475 39.9762 6.56504 38.6566C4.28731 37.4956 2.4341 35.6441 1.27478 33.3664C-0.0448014 30.7766 -0.0447998 27.3856 -0.0447998 20.6053V19.3948Z"
        fill="white"
      />
      <path
        d="M32.3838 23.1965H31.4018C30.4075 23.1965 29.6014 24.0026 29.6014 24.9969V25.9789C29.6014 26.9732 30.4075 27.7793 31.4018 27.7793H32.3838C33.3781 27.7793 34.1842 26.9732 34.1842 25.9789V24.9969C34.1842 24.0026 33.3781 23.1965 32.3838 23.1965Z"
        fill="#F9213B"
      />
      <path
        d="M13.7068 13.0269C13.7068 14.4164 12.5287 15.17 11.1171 15.17C9.70542 15.17 8.55121 14.4164 8.55121 13.0269V12.8155C8.55121 11.4261 9.70542 10.6265 11.1171 10.6265C12.5287 10.6265 13.7068 11.4278 13.7068 12.8155V13.0269ZM8.97402 27.6958V15.9235H13.2823V27.6958H8.97402Z"
        fill="#1D1D1D"
      />
      <path
        d="M23.6002 11.218V16.822H23.5525C22.9166 15.9985 21.9277 15.574 20.6099 15.574C17.2427 15.574 14.7468 18.4229 14.7468 21.6246V22.0952C14.7468 25.7914 17.4303 28.052 20.2092 28.052C21.6822 28.052 22.8688 27.6071 23.6957 26.6421L23.636 25.8323L23.9463 25.6567L24.6726 27.6991H27.9085V11.218H23.6002ZM21.4811 24.5212C19.9978 24.5212 19.1744 23.4386 19.1744 21.7423C19.1744 20.0459 19.9978 18.9633 21.4811 18.9633C22.9643 18.9633 23.7179 19.9521 23.7179 21.7423C23.7179 23.5324 22.8944 24.5212 21.4811 24.5212Z"
        fill="#1D1D1D"
      />
    </g>
    <defs>
      <clipPath id="clip0_4143_8292">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LandId;
