import { connect } from 'react-redux';
import { ChooseAccountModal } from './presentation';
import { signOut } from 'actions/user';

const mapStateToProps = (state) => ({
  allUserSubscription: state.subscriptions.allUserSubscription,
  admin: state.user.user && state.user.user.admin,
});

const mapActionsToProps = {
  signOut,
};

export default connect(mapStateToProps, mapActionsToProps)(ChooseAccountModal);
