import settingsActionTypes from '../actions/settings/actions';
import sidebarActionTypes from '../actions/editSidebar/actions';
import actionTypes from '../actions/export/actions';

const ACTION_HANDLERS = {
  [actionTypes.NOTIFY_EXPORT]: (state, action) => ({
    ...state,
    status: 'READY_TO_START',
  }),
  [actionTypes.START_MAP_EXPORT]: (state, action) => ({
    ...state,
    status: 'RUNNING',
    link: action.data.aws_public_url,
  }),
  [actionTypes.EXPORT_MAP_SUCCESS]: (state, action) => ({
    ...state,
    status: 'SUCCESS',
    canvas: null,
  }),
  [actionTypes.EXPORT_MAP_FAILUER]: (state, action) => ({
    ...state,
    status: 'FAILURE',
    canvas: null,
  }),
  [actionTypes.CHANGE_EXPORT_OPTION]: (state, action) => ({
    ...state,
    options: { ...state.options, [action.key]: action.value },
  }),
  [actionTypes.CHANGE_EXPORT_OPTIONS]: (state, action) => ({
    ...state,
    options: { ...state.options, ...action.values },
  }),
  [sidebarActionTypes.CHANGE_ACTIVE_PANEL]: (state, action) => {
    const { panelName } = action;

    if (panelName === 'REPORT') {
      return {
        ...state,
        options: { ...state.options, printReport: true },
      };
    }
    if (panelName !== 'PRINT') {
      return {
        ...state,
        options: { ...state.options, printReport: false },
      };
    }
    return state;
  },
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

// Reducer
const initialState = {
  link: null,
  status: null,
  options: {
    emailNotification: true,
    logo: true,
    contact: true,
    orientation: 'Landscape',
    size: '8.5x11',
    full_page: false,
    unit: 'ft',
    format: 'pdf',
    quality: 300,
    compress: true,
    printReport: false,
    capabilityOn: false,
    isICap: false,
    soilsColoring: {},
    isWapi: false,
    simplifyLegends: false,
  },
  canvas: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
