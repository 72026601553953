import { isEqual, uniqBy } from 'lodash';
import distance from '@turf/distance';
import LatLon from 'geodesy/latlon-spherical';
import bearing from '@turf/bearing';
import { point, polygon } from '@turf/helpers';
import buffer from '@turf/buffer';
import unkinkPolygon from '@turf/unkink-polygon';
import { hexaToColorName } from './colors';
import {
  TOOL_EDGE_CODE,
  MEASUREMENT_UNITS,
  FEET_PER_KILOMETER,
  MILE_PER_KILOMETER,
} from './constants';
import { formatDecimalNumber } from 'utils/general';
import { getAPIURL } from 'api/utils/domains';

const METERS_PER_ACRE = 4046.86;
const SQUARE_MILES_PER_ACRE = 0.0015625;
const GEOMETRY_TYPES = {
  POINT: 'POINT',
  LINESTRING: 'LINESTRING',
  POLYGON: 'POLYGON',
};
const SPECIAL_POLYGONS_TYPES = ['buffer'];

export const SPECIAL_POLYGON_KEY = 'special-polygon-type';

export const getPointForLine = (geoJSON, tool, pointPosition = 'end') => {
  let coordinates = [];
  let rotation = 0;
  const coordinatesLast = geoJSON.geometry.coordinates.length - 1;
  switch (pointPosition) {
    case 'start': {
      [coordinates] = geoJSON.geometry.coordinates;
      rotation =
        bearing(
          point(geoJSON.geometry.coordinates[0]),
          point(geoJSON.geometry.coordinates[1]),
        ) - 180;
      break;
    }
    case 'end': {
      [coordinates] = geoJSON.geometry.coordinates.slice(-1);
      rotation = bearing(
        point(geoJSON.geometry.coordinates[coordinatesLast - 1]),
        point(geoJSON.geometry.coordinates[coordinatesLast]),
      );
      break;
    }
    default:
      [coordinates] = geoJSON.geometry.coordinates;
  }

  const maprightId = geoJSON.maprightId * 10;
  const id = parseInt(maprightId.toString().replace('.', ''));

  return {
    geometry: {
      type: 'Point',
      coordinates: [coordinates[0], coordinates[1]],
    },
    id,
    maprightId,
    type: 'Feature',
    properties: {
      id,
      toolId: geoJSON.properties.toolId,
      toolType: TOOL_EDGE_CODE,
      color: geoJSON.properties.color,
      parentToolId: geoJSON.properties.toolId,
      parentId: geoJSON.maprightId,
      iconName: tool.code,
      maprightId,
      highlight: geoJSON.properties.highlight,
      visibility: geoJSON.properties.visibility,
      rotation: rotation - 90,
    },
  };
};

export const findTool = (element, tools) =>
  tools.find(
    (tool) =>
      tool.id === element.properties.toolId &&
      tool.tool_type === element.properties.toolType,
  );

export const getMarkerStyle = (element, tool) => {
  if (!tool.data.details.length > 0) {
    return tool;
  }

  const stylingDetailIndex = tool.data.details.findIndex(
    (detail) => !!detail.isStyling,
  );
  if (stylingDetailIndex < 0) {
    return tool;
  }

  const detail = tool.data.details[stylingDetailIndex];
  if (!element.properties || !element.properties.details) {
    return tool;
  }

  const {
    properties: { details },
  } = element;

  if (!Array.isArray(details)) {
    return tool;
  }

  const elementDetailIndex = details.findIndex(({ id }) => id === detail.id);
  if (elementDetailIndex < 0 || !details[elementDetailIndex].value) {
    return tool;
  }

  if (!detail.styling) {
    return tool;
  }

  return (
    detail.styling.find(
      (styling) => styling.name === details[elementDetailIndex].value,
    ) || tool
  );
};

export const getPathStyle = (element, tool) => {
  if (!tool.data.details.length > 0) {
    return tool.style;
  }

  const stylingDetailIndex = tool.data.details.findIndex(
    (detail) => !!detail.isStyling,
  );
  if (stylingDetailIndex < 0) {
    return tool.style;
  }

  const detail = tool.data.details[stylingDetailIndex];
  if (!element.properties || !element.properties.details) {
    return tool.style;
  }

  const {
    properties: { details },
  } = element;

  if (!Array.isArray(details)) {
    return tool;
  }

  const elementDetailIndex = details.findIndex(({ id }) => id === detail.id);
  if (elementDetailIndex < 0 || !details[elementDetailIndex].value) {
    return tool.style;
  }

  if (!detail.styling) {
    return tool.style;
  }

  return (
    detail.styling.find(
      (styling) => styling.name === details[elementDetailIndex].value,
    ) || tool.style
  );
};

export const getToolIconName = (tool, color, shape) => {
  const { code, color_less: colorLess, shape_less: shapeLess } = tool;
  const shapeName = shape === 'square' ? 'rectangle' : 'circle';
  const colorName = hexaToColorName(color);

  if (shapeLess) {
    if (colorLess) {
      return `${code}`;
    }
    return `${code}-${colorName}`;
  }

  return `${code}-${shapeName}-${colorName}`;
};

export const getTrackToolIconPath = (colorName) =>
  `${process.env.TOOLS_ICONS_BASE_URL}/polyline/${colorName}/line-tracks-edge/${colorName}.png`;

export const getToolEdgeIcon = (tool) => {
  const { color } = tool;
  return `${process.env.TOOLS_ICONS_BASE_URL}/polyline/${color}/arrow-edge/${color}.png`;
};

export const getToolEdgeIconForPrint = (color) =>
  `${process.env.TOOLS_ICONS_BASE_URL}/polyline/${color}/arrow-edge/${color}.png`;

export const getToolIconPath = (tool, shape) => {
  const { code } = tool;
  const shapeName = shape === 'square' ? 'rectangle' : 'circle';

  return tool.shape_less ?
      `${process.env.TOOLS_ICONS_BASE_URL}/icon/${code}/`
    : `${process.env.TOOLS_ICONS_BASE_URL}/icon/${code}/${code}-${shapeName}/`;
};

export const getToolIcon = (tool) => {
  if (['georeferencing', 'labels'].includes(tool.tool_type)) {
    return tool.icon?.url;
  }

  if (tool.tool_type === 'icons') {
    if (tool.shape_less) {
      if (tool.color_less) {
        return `${process.env.TOOLS_ICONS_BASE_URL}/icon/${tool.code}/${tool.code}.png`;
      }
      return `${process.env.TOOLS_ICONS_BASE_URL}/icon/${tool.code}/${tool.code}-${tool.color}.png`;
    }

    const shapeName = tool.shape === 'square' ? 'rectangle' : 'circle';
    return (
      `${process.env.TOOLS_ICONS_BASE_URL}/icon/${tool.code}/${tool.code}-` +
      `${shapeName}/${tool.code}-${shapeName}-${tool.color}.png`
    );
  }

  if (!tool.icon.url) {
    return null;
  }

  const [, , , toolTypeName] = tool.icon.url.split('/');
  if (toolTypeName) {
    const color = tool.color.replace('#', '');
    return `${process.env.TOOLS_ICONS_BASE_URL}/${toolTypeName}/${color}/${tool.code}/${color}.png`;
  }

  return tool.icon.url;
};

export const getIconPathForStyleInfo = (tool, styleInfo) => {
  const [, , , toolType] = tool.icon?.url?.split('/') || [];
  const { shape } = styleInfo;
  const { color, fillColor } = styleInfo;

  if (['georeferencing', 'labels'].includes(tool.tool_type)) {
    return getAPIURL() + tool.icon?.url;
  } else if (tool.tool_type === 'icons') {
    const path = getToolIconPath(tool, shape);
    return `${path}${getToolIconName(tool, color, shape)}.png`;
  }

  const iconColor = hexaToColorName(color || fillColor).replace('#', '');
  return `${process.env.TOOLS_ICONS_BASE_URL}/${toolType}/${iconColor}/${tool.code}/${iconColor}.png`;
};

export const findModifiedElement = (oldArray, newArray) =>
  newArray.find((element) => {
    const oldElementState = oldArray.find(
      (oldElement) => oldElement.maprightId === element.maprightId,
    );

    if (!oldElementState) {
      return false;
    }

    const geometryChanged = !isEqual(
      oldElementState.geometry,
      element.geometry,
    );
    const propertiesChanged = !isEqual(
      oldElementState.properties,
      element.properties,
    );

    return geometryChanged || propertiesChanged;
  });

export const findModifiedTool = (oldArray, newArray) => {
  const modifiedTools = (left, right) =>
    left.filter(
      (leftValue) =>
        !right.some((rightValue) => isEqual(leftValue, rightValue)),
    );

  return modifiedTools(newArray, oldArray);
};

export const calculatePolylineDistance = (element, units = 'feet') => {
  const {
    geometry: { coordinates },
  } = element;

  const buildGeoJSON = (coords) => ({
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Point',
      coordinates: coords,
    },
  });

  let totalDistance = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    const point1 = coordinates[i];
    const point2 = coordinates[i + 1];
    if (Array.isArray(point1) && Array.isArray(point2)) {
      totalDistance += distance(buildGeoJSON(point1), buildGeoJSON(point2));
    }
  }

  return units === MEASUREMENT_UNITS.MILES ?
      totalDistance * MILE_PER_KILOMETER
    : totalDistance * FEET_PER_KILOMETER;
};

export const calculateLastPolylineSegmentDistance = (
  element,
  units = 'feet',
) => {
  let distance = 0;
  if (element.geometry.coordinates.length > 1) {
    distance = calculatePolylineDistance(
      {
        ...element,
        geometry: {
          ...element.geometry,
          coordinates: element.geometry.coordinates.slice(-2),
        },
      },
      units,
    );
  }

  return distance;
};

export const addPointToPolyline = (feature, point) => {
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [...feature.geometry.coordinates, point],
    },
  };
};

/* Given the circle polygon area in acres return the radius in miles */
export const calculatePolygonRadius = (polygonAcreage) => {
  const polygonAreaInSquareMiles = polygonAcreage * SQUARE_MILES_PER_ACRE;
  const radiusInMiles = Math.sqrt(polygonAreaInSquareMiles / Math.PI); // Circle Radius in miles
  return formatDecimalNumber(radiusInMiles);
};

export const calculatePolygonPerimeter = (polygonElement, units = 'feet') => {
  const coordinates = polygonElement.geometry.coordinates[0];
  const element = {
    ...polygonElement,
    geometry: { type: 'LineString', coordinates },
  };

  let perimeter = calculatePolylineDistance(element, units);
  if (coordinates.length <= 3) {
    perimeter = perimeter / 2;
  }

  return perimeter;
};

export const addPointToPolygon = (feature, point) => {
  const prevCoordinates = uniqBy(
    feature.geometry.coordinates[0],
    (element) => element[0] + element[1],
  );

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [
        [...prevCoordinates, ...(point ? [point] : []), prevCoordinates[0]],
      ],
    },
  };
};

/* Return the polygon area in acres */
export const calculatePolygonArea = (element) => {
  const {
    geometry: {
      type,
      coordinates: [coordinates],
    },
  } = element;

  if (type === 'Multipolygon') {
    return multiPolygonArea(element);
  }

  if (type !== 'Polygon' || coordinates.length < 4) {
    return 0;
  }

  // Takes a kinked polygon and returns a feature collection of polygons that have no kink
  // this is used since area of latlon-spherical can not handle kinked polygons
  let unkikedFeatures;

  try {
    const { features } = unkinkPolygon(element);
    unkikedFeatures = features;
  } catch (error) {
    unkikedFeatures = false;
  }

  let totalArea = 0;

  if (unkikedFeatures) {
    unkikedFeatures.forEach((poly) => {
      const polygon = [];
      poly.geometry.coordinates[0].forEach((coords) => {
        polygon.push(new LatLon(coords[1], coords[0]));
      });
      totalArea +=
        Math.floor((LatLon.areaOf(polygon) * 1000) / METERS_PER_ACRE) / 1000;
    });
  } else {
    const polygon = [];
    coordinates.forEach((coords) => {
      polygon.push(new LatLon(coords[1], coords[0]));
    });
    totalArea =
      Math.floor((LatLon.areaOf(polygon) * 1000) / METERS_PER_ACRE) / 1000;
  }

  return formatDecimalNumber(totalArea, 3);
};

const multiPolygonArea = (element) => {
  const {
    geometry: { coordinates: mPolygonCoords },
  } = element;
  let area = 0;

  mPolygonCoords.forEach((singlePolygonCoordinates) => {
    const polygon = [];
    const [coordinates] = singlePolygonCoordinates;

    coordinates.forEach((coords) => {
      polygon.push(new LatLon(coords[1], coords[0]));
    });

    area +=
      Math.floor((LatLon.areaOf(polygon) * 1000) / METERS_PER_ACRE) / 1000;
  });

  return area;
};

export const calculateStops = (toolCode, layerType, devicePixelRatio) => {
  switch (toolCode) {
    case 'line-tour':
    case 'line-transmission-line':
      if (layerType === 'path') {
        return [
          [0, 0.25],
          [10, 0.4],
          [12, 0.7],
          [14, 1],
          [16, 1.4],
          [18, 1.8],
        ];
      }

      return [
        [0, 0.5],
        [10, 0.8],
        [12, 1.4],
        [14, 2],
        [16, 2.8],
        [18, 3.6],
      ];
    case 'line-pipeline':
    case 'polygon-boundary':
      if (layerType === 'path') {
        return [
          [0, 1.45],
          [10, 2.61],
          [12, 3.17],
          [14, 3.77],
          [16, 4.37],
          [18, 5.22],
        ];
      }

      return [
        [0, 0.79],
        [10, 1.43],
        [12, 1.58],
        [14, 2.06],
        [16, 2.38],
        [18, 2.85],
      ];
    case 'line-tracks':
      if (devicePixelRatio > 1) {
        return [
          [0, 8],
          [10, 12],
          [12, 16],
          [14, 18],
          [16, 20],
          [18, 22],
        ];
      }

      return [
        [0, 4],
        [10, 6],
        [12, 8],
        [14, 9],
        [16, 10],
        [18, 11],
      ];
    case 'custom-line':
    case 'polygon-pivot':
    case 'custom-polygon-boundary':
      if (layerType === 'path') {
        return [
          [0, 1.8],
          [10, 3.24],
          [12, 3.6],
          [14, 4.68],
          [16, 5.4],
          [18, 5.48],
        ];
      }

      return [
        [0, 0.25],
        [10, 0.4],
        [12, 0.7],
        [14, 1],
        [16, 1.4],
        [18, 1.8],
      ];
    case 'line-distance':
    case 'line-direction':
      if (layerType === 'path') {
        return [
          [0, 0.8],
          [10, 1.25],
          [12, 2.25],
          [14, 3.25],
          [16, 4.5],
          [18, 5.87],
        ];
      }

      return [
        [0, 0.4],
        [10, 0.625],
        [12, 1.63],
        [14, 2],
        [16, 2.4],
        [18, 3],
      ];
    default:
      if (layerType === 'path') {
        return [
          [0, 0.5],
          [10, 0.8],
          [12, 1.4],
          [14, 2],
          [16, 2.8],
          [18, 3.6],
        ];
      }

      return [
        [0, 0.25],
        [10, 0.4],
        [12, 0.7],
        [14, 1],
        [16, 1.4],
        [18, 1.8],
      ];
  }
};

export const getToolDashArray = (tool) => {
  if (!tool.style.dashArray) {
    return [1000, 0.1];
  }

  const {
    style: { dashArray },
  } = tool;

  return dashArray.split(' ').map((dash) => parseFloat(dash));
};

export const getInsideToolDashArray = (tool) => {
  if (!(tool.style.fillWidth && tool.style.fillWidth.dashArray)) {
    return [1000, 0.1];
  }

  const {
    style: {
      fillWidth: { dashArray },
    },
  } = tool;

  return dashArray.split(' ').map((dash) => parseFloat(dash));
};

export const geomTypesOnGeoJSON = (geoJSON) => {
  const geomTypes = [];

  const points = geoJSON.find((e) => e.geometry.type === 'Point');
  const lines = geoJSON.find((e) => e.geometry.type === 'LineString');
  const shapes = geoJSON.find((e) => e.geometry.type === 'Polygon');

  if (points) geomTypes.push(GEOMETRY_TYPES.POINT);
  if (lines) geomTypes.push(GEOMETRY_TYPES.LINESTRING);
  if (shapes) geomTypes.push(GEOMETRY_TYPES.POLYGON);

  return geomTypes;
};

export const loadIcons = (map, icons) => {
  const promises = icons.map(
    ({ name: iconName, url: iconURL }) =>
      new Promise((resolve, reject) => {
        try {
          if (!map.hasImage(iconName)) {
            // the cacheblock is used to force the browser into reloading the image
            // this is necessary because of a known problem with S3 buckets (CORS)
            map.loadImage(`${iconURL}?cacheblock=true`, (error, image) => {
              if (error) {
                throw error;
              }

              if (!map.hasImage(iconName)) {
                map.addImage(iconName, image, {
                  sdf: true,
                  pixelRatio: window.devicePixelRatio > 1 ? 0.29 : 0.35, // used just for edge icons (arrowheads)
                });
              }
              resolve();
            });
          } else {
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      }),
  );

  return Promise.all(promises);
};

export const isSpecialPolygon = (element) => {
  if (!Array.isArray(element?.properties?.details)) {
    return false;
  }

  const detail = element.properties?.details?.find(
    (detail) => detail.id === SPECIAL_POLYGON_KEY,
  );
  return detail && SPECIAL_POLYGONS_TYPES.includes(detail.value);
};

export const normalizePolylineName = (name) => name.replace('Tour', 'Track');

export const adjustPolygonBorder = (originalGeoJSON, distance) => {
  // Creating a deep copy of the original GeoJSON
  const geoJSONCopy = JSON.parse(JSON.stringify(originalGeoJSON));

  const originalPolygon = polygon(geoJSONCopy.geometry.coordinates);
  return buffer(originalPolygon, distance, { units: 'meters' });
};
