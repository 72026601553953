import { StyleableOnlyIconProps } from '../types';

const Calendar = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.75 2C8.16421 2 8.5 2.33579 8.5 2.75V4.5H15.75V2.75C15.75 2.33579 16.0858 2 16.5 2C16.9142 2 17.25 2.33579 17.25 2.75V4.5H20.25C21.3546 4.5 22.25 5.39543 22.25 6.5V10.25V20.25C22.25 21.3546 21.3546 22.25 20.25 22.25H4C2.89543 22.25 2 21.3546 2 20.25V10.25V6.5C2 5.39543 2.89543 4.5 4 4.5H7V2.75C7 2.33579 7.33579 2 7.75 2ZM15.75 6V7.125C15.75 7.53921 16.0858 7.875 16.5 7.875C16.9142 7.875 17.25 7.53921 17.25 7.125V6H20.25C20.5261 6 20.75 6.22386 20.75 6.5V9.5H3.5V6.5C3.5 6.22386 3.72386 6 4 6H7V7.125C7 7.53921 7.33579 7.875 7.75 7.875C8.16421 7.875 8.5 7.53921 8.5 7.125V6H15.75ZM20.75 11V20.25C20.75 20.5261 20.5261 20.75 20.25 20.75H4C3.72386 20.75 3.5 20.5261 3.5 20.25V11H20.75Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.5 4.5H17.2083C17.1531 4.5 17.1001 4.47805 17.061 4.43898C17.0219 4.39991 17 4.34692 17 4.29167V2.83333C17 2.61232 16.9122 2.40036 16.7559 2.24408C16.5996 2.0878 16.3877 2 16.1667 2C15.9457 2 15.7337 2.0878 15.5774 2.24408C15.4211 2.40036 15.3333 2.61232 15.3333 2.83333V6.79167C15.3333 6.95743 15.2675 7.1164 15.1503 7.23361C15.0331 7.35082 14.8741 7.41667 14.7083 7.41667C14.5426 7.41667 14.3836 7.35082 14.2664 7.23361C14.1492 7.1164 14.0833 6.95743 14.0833 6.79167V4.91667C14.0833 4.80616 14.0394 4.70018 13.9613 4.62204C13.8832 4.5439 13.7772 4.5 13.6667 4.5H8.45833C8.40308 4.5 8.35009 4.47805 8.31102 4.43898C8.27195 4.39991 8.25 4.34692 8.25 4.29167V2.83333C8.25 2.61232 8.1622 2.40036 8.00592 2.24408C7.84964 2.0878 7.63768 2 7.41667 2C7.19565 2 6.98369 2.0878 6.82741 2.24408C6.67113 2.40036 6.58333 2.61232 6.58333 2.83333V6.79167C6.58333 6.95743 6.51749 7.1164 6.40028 7.23361C6.28306 7.35082 6.12409 7.41667 5.95833 7.41667C5.79257 7.41667 5.6336 7.35082 5.51639 7.23361C5.39918 7.1164 5.33333 6.95743 5.33333 6.79167V4.91667C5.33333 4.80616 5.28943 4.70018 5.21129 4.62204C5.13315 4.5439 5.02717 4.5 4.91667 4.5H3.66667C3.22464 4.5 2.80072 4.67559 2.48816 4.98816C2.17559 5.30072 2 5.72464 2 6.16667V20.3333C2 20.7754 2.17559 21.1993 2.48816 21.5118C2.80072 21.8244 3.22464 22 3.66667 22H19.5C19.942 22 20.3659 21.8244 20.6785 21.5118C20.9911 21.1993 21.1667 20.7754 21.1667 20.3333V6.16667C21.1667 5.72464 20.9911 5.30072 20.6785 4.98816C20.3659 4.67559 19.942 4.5 19.5 4.5ZM19.0833 20.3333H4.08333C3.97283 20.3333 3.86685 20.2894 3.78871 20.2113C3.71057 20.1332 3.66667 20.0272 3.66667 19.9167V9.91667C3.66667 9.80616 3.71057 9.70018 3.78871 9.62204C3.86685 9.5439 3.97283 9.5 4.08333 9.5H19.0833C19.1938 9.5 19.2998 9.5439 19.378 9.62204C19.4561 9.70018 19.5 9.80616 19.5 9.91667V19.9167C19.5 20.0272 19.4561 20.1332 19.378 20.2113C19.2998 20.2894 19.1938 20.3333 19.0833 20.3333Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Calendar;
