export default {
  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE_FAILURE: 'AUTHENTICATE_FAILURE',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  SET_TUTORIAL_ATTRIBUTE: 'SET_TUTORIAL_ATTRIBUTE',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  RELOAD_USER_SUCCESS: 'RELOAD_USER_SUCCESS',
  RELOAD_USER_FAILURE: 'RELOAD_USER_FAILURE',
  CLEAR_USER_ERROR: 'CLEAR_USER_ERROR',
  DISABLE_UPDATE_FLAG: 'DISABLE_UPDATE_FLAG',
  LOGOUT: 'LOGOUT',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  SET_PARCELS_AVAILABILITY: 'SET_PARCELS_AVAILABILITY',
  TOGGLE_PARCEL_BUTTON: 'TOGGLE_PARCEL_BUTTON',
  GET_USAGE_TRACKING_OPTIONS: 'GET_USAGE_TRACKING_OPTIONS',
  SELECT_ACCOUNT: 'SELECT_ACCOUNT',
};
