import React, { useState, useEffect } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { fetchReleaseNotes } from 'api/release-notes';

const ReleaseNotes = ({ admin }) => {
  const [selectedPlatform, setSelectedPlatform] = useState('iOS Release Notes');
  const [releaseData, setReleaseData] = useState(null);
  const releaseNotes = [
    'iOS Release Notes',
    'Android Release Notes',
    'Web Release Notes',
    'API Release Notes',
  ];

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted or not for testing purposes

    const fetchData = async () => {
      try {
        const filePaths = [
          'ios-release.json',
          'web-release.json',
          'android-release.json',
          'api-release.json',
        ];
        const releaseNotes = await fetchReleaseNotes(filePaths);

        if (isMounted) {
          setReleaseData(releaseNotes);
        }
      } catch (error) {
        console.error('Error fetching releases:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleReleaseClick = (platform) => {
    setSelectedPlatform(platform);
  };

  const formatGitHubDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    admin && (
      <div className="release-notes">
        <header className="release-notes__header">{selectedPlatform}</header>
        <div className="release-notes__content-container">
          <div className="release-notes__link-and-notes">
            <div className="release-notes__platform-links">
              <h4>Release Notes</h4>
              {releaseNotes.map((platform, index) => (
                <div
                  key={index}
                  onClick={() => handleReleaseClick(platform)}
                  className={`platform ${selectedPlatform === platform ? 'selected' : ''}`}
                >
                  {platform}
                </div>
              ))}
            </div>
            <div className="release-notes__header__git-info">
              {releaseData &&
                releaseData.versions
                  .filter((version) => version.platform === selectedPlatform)
                  .map((version) => (
                    <div key={version.platform}>
                      {version.releases.map((release) => (
                        <div key={release.name}>
                          <h2>Land id {release.tag_name}</h2>
                          <h3>
                            Date: {formatGitHubDate(release.published_at)}
                          </h3>
                          <h4>Bug Fixes</h4>
                          <ul>
                            {release.body.split('\n').map(
                              (note, index) =>
                                // Check if the line is not empty before adding to the <ul>
                                note.trim() !== '' && (
                                  <li key={index}>{note}</li>
                                ),
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

ReleaseNotes.propTypes = {
  admin: PropTypes.bool,
};

export default ReleaseNotes;
