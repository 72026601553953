import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Discover = ({ fill = colors.grayScale200 }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.41 5.8125C21.19 5.4825 20.88 5.2325 20.51 5.0925L15.65 3.1425C15.18 2.9525 14.66 2.9525 14.19 3.1425L9.01 5.2125C8.95 5.2325 8.9 5.2425 8.84 5.2425C8.78 5.2425 8.72 5.2325 8.67 5.2125L3.86 3.2925C3.65 3.2125 3.43 3.1825 3.21 3.2025C2.99 3.2225 2.77 3.3025 2.59 3.4325C2.4 3.5525 2.25 3.7325 2.15 3.9225C2.05 4.1225 2 4.3425 2 4.5625V16.2225C2 16.6125 2.12 16.9925 2.34 17.3225C2.56 17.6525 2.87 17.9025 3.24 18.0425L8.11 19.9925C8.35 20.0825 8.59 20.1325 8.84 20.1325C8.92572 20.1325 9.01027 20.1266 9.09405 20.1153C9.38856 20.0753 9.57322 19.7972 9.57812 19.5V12.5L9.58 12.4992V6.6025L14.16 4.7725V9.245C14.16 9.66197 14.498 10 14.915 10C15.332 10 15.67 9.66197 15.67 9.245V4.7625L19.96 6.4825C20.05 6.5125 20.12 6.5725 20.17 6.6525C20.22 6.7325 20.25 6.8225 20.25 6.9125V10.745C20.25 11.162 20.588 11.5 21.005 11.5C21.422 11.5 21.76 11.162 21.76 10.745V6.9125C21.76 6.5125 21.64 6.1325 21.42 5.8125H21.41ZM3.58 16.4825C3.53 16.4025 3.5 16.3125 3.5 16.2225V4.7625L8.08 6.5925V18.3725L3.79 16.6625C3.7 16.6225 3.63 16.5625 3.58 16.4925V16.4825Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1637 11.3901C14.7855 11.1326 15.452 11 16.125 11C16.798 11 17.4645 11.1326 18.0863 11.3901C18.708 11.6477 19.273 12.0252 19.7489 12.5011C20.2248 12.977 20.6023 13.542 20.8599 14.1637C21.1174 14.7855 21.25 15.452 21.25 16.125C21.25 16.798 21.1174 17.4645 20.8599 18.0863C20.6986 18.4756 20.4903 18.8427 20.2402 19.1796L22.2803 21.2196C22.5732 21.5125 22.5732 21.9874 22.2803 22.2803C21.9874 22.5732 21.5125 22.5732 21.2196 22.2803L19.1796 20.2402C18.8427 20.4903 18.4756 20.6986 18.0863 20.8599C17.4645 21.1174 16.798 21.25 16.125 21.25C15.452 21.25 14.7855 21.1174 14.1637 20.8599C13.542 20.6023 12.977 20.2248 12.5011 19.7489C12.0252 19.273 11.6477 18.708 11.3901 18.0863C11.1326 17.4645 11 16.798 11 16.125C11 15.452 11.1326 14.7855 11.3901 14.1637C11.6477 13.542 12.0252 12.977 12.5011 12.5011C12.977 12.0252 13.542 11.6477 14.1637 11.3901ZM16.125 12.5C15.649 12.5 15.1776 12.5938 14.7378 12.7759C14.298 12.9581 13.8983 13.2251 13.5617 13.5617C13.2251 13.8983 12.9581 14.298 12.7759 14.7378C12.5938 15.1776 12.5 15.649 12.5 16.125C12.5 16.601 12.5938 17.0724 12.7759 17.5122C12.9581 17.952 13.2251 18.3517 13.5617 18.6883C13.8984 19.0249 14.298 19.2919 14.7378 19.4741C15.1776 19.6562 15.649 19.75 16.125 19.75C16.601 19.75 17.0724 19.6562 17.5122 19.4741C17.952 19.2919 18.3517 19.0249 18.6883 18.6883C19.0249 18.3516 19.2919 17.952 19.4741 17.5122C19.6562 17.0724 19.75 16.601 19.75 16.125C19.75 15.649 19.6562 15.1776 19.4741 14.7378C19.2919 14.298 19.0249 13.8984 18.6883 13.5617C18.3517 13.2251 17.952 12.9581 17.5122 12.7759C17.0724 12.5938 16.601 12.5 16.125 12.5Z"
      fill={fill}
    />
  </svg>
);

export default Discover;
