import useEntitlement from './useEntitlement';
import { checkQuota } from '../utils';

const useCanAddMapBranding = () => {
  const createMapEntitlement = useEntitlement('ADD_CONTACT_INFO');

  return checkQuota(createMapEntitlement);
};

export default useCanAddMapBranding;
