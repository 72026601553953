import { NonConfigurableIconProps } from '../types';

const LayersStacked = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.69801 10.487L11.044 13.6799C11.3325 13.8058 11.6438 13.8708 11.9585 13.8708C12.2732 13.8708 12.5845 13.8058 12.8729 13.6799L20.2266 10.487C20.4323 10.3951 20.6067 10.2453 20.7285 10.0558C20.8503 9.86631 20.9142 9.64541 20.9124 9.42016C20.913 9.19707 20.8475 8.9788 20.7241 8.79297C20.6006 8.60714 20.4248 8.46209 20.219 8.37617L12.8729 5.19086C12.5845 5.06497 12.2732 5 11.9585 5C11.6438 5 11.3325 5.06497 11.044 5.19086L3.69801 8.39141C3.49451 8.47632 3.32037 8.61902 3.19712 8.80186C3.07387 8.98471 3.00692 9.19967 3.00456 9.42016C3.00242 9.64647 3.06706 9.86839 3.19041 10.0582C3.31375 10.2479 3.49032 10.3971 3.69801 10.487Z"
        fill="#1D1D1D"
      />
      <path
        d="M20.219 12.9636L19.2741 12.5521C19.1779 12.5102 19.0741 12.4885 18.9692 12.4885C18.8643 12.4885 18.7606 12.5102 18.6644 12.5521L11.9585 15.4631L5.25258 12.5521C5.15644 12.5102 5.05267 12.4885 4.94777 12.4885C4.84287 12.4885 4.73909 12.5102 4.64295 12.5521L3.69803 12.9636C3.49128 13.0499 3.31469 13.1955 3.19048 13.3819C3.06628 13.5684 3 13.7874 3 14.0114C3 14.2355 3.06628 14.4545 3.19048 14.6409C3.31469 14.8274 3.49128 14.9729 3.69803 15.0592L11.0441 18.2522C11.3325 18.378 11.6438 18.443 11.9585 18.443C12.2732 18.443 12.5845 18.378 12.8729 18.2522L20.219 15.0592C20.4267 14.9693 20.6032 14.8201 20.7266 14.6304C20.8499 14.4406 20.9146 14.2187 20.9124 13.9924C20.9101 13.7719 20.8431 13.5569 20.7199 13.3741C20.5966 13.1912 20.4225 13.0485 20.219 12.9636Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default LayersStacked;
