import { NonConfigurableIconProps } from '../types';

const SEO = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2883 17.7167C16.995 17.3902 16.5847 17.1925 16.1466 17.1667H5.92993C5.87468 17.1667 5.82169 17.1447 5.78262 17.1056C5.74355 17.0666 5.7216 17.0136 5.7216 16.9583V10.2917C5.7216 10.2364 5.74355 10.1834 5.78262 10.1444C5.82169 10.1053 5.87468 10.0833 5.92993 10.0833H11.1466C11.3676 10.0833 11.5796 9.99554 11.7359 9.83926C11.8921 9.68297 11.9799 9.47101 11.9799 9.25C11.9799 9.02899 11.8921 8.81702 11.7359 8.66074C11.5796 8.50446 11.3676 8.41667 11.1466 8.41667H5.7216C5.27957 8.41667 4.85565 8.59226 4.54309 8.90482C4.23053 9.21738 4.05493 9.64131 4.05493 10.0833V17.5833C4.05493 17.5833 4.05493 17.625 4.05493 17.65C4.0649 17.687 4.06302 17.7262 4.04957 17.762C4.03612 17.7979 4.01176 17.8287 3.97993 17.85L2.31327 19.2083C2.20355 19.296 2.11775 19.4099 2.06385 19.5396C2.00994 19.6692 1.98968 19.8104 2.00493 19.95C2.01728 20.1503 2.07014 20.3461 2.16033 20.5253C2.25052 20.7046 2.37616 20.8638 2.52964 20.9931C2.68311 21.1224 2.86124 21.2193 3.05323 21.2777C3.24522 21.3362 3.44709 21.3551 3.6466 21.3333H17.8133C18.0147 21.3588 18.2192 21.3426 18.4142 21.2858C18.6091 21.229 18.7903 21.1328 18.9466 21.0031C19.1028 20.8734 19.2308 20.713 19.3225 20.5319C19.4143 20.3507 19.4678 20.1527 19.4799 19.95C19.4952 19.8104 19.4749 19.6692 19.421 19.5396C19.3671 19.4099 19.2813 19.296 19.1716 19.2083C18.513 18.7513 17.884 18.2531 17.2883 17.7167ZM12.3883 19.875H9.05493C8.88917 19.875 8.7302 19.8092 8.61299 19.6919C8.49578 19.5747 8.42993 19.4158 8.42993 19.25C8.42993 19.0842 8.49578 18.9253 8.61299 18.8081C8.7302 18.6908 8.88917 18.625 9.05493 18.625H12.3883C12.554 18.625 12.713 18.6908 12.8302 18.8081C12.9474 18.9253 13.0133 19.0842 13.0133 19.25C13.0133 19.4158 12.9474 19.5747 12.8302 19.6919C12.713 19.8092 12.554 19.875 12.3883 19.875Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.9716 13.575C19.9412 13.5356 19.9247 13.4873 19.9247 13.4375C19.9247 13.3877 19.9412 13.3394 19.9716 13.3C20.4654 12.6029 20.7331 11.7709 20.7383 10.9167C20.7383 10.0926 20.4939 9.287 20.0361 8.60179C19.5782 7.91659 18.9275 7.38253 18.1661 7.06717C17.4048 6.7518 16.567 6.66929 15.7587 6.83006C14.9505 6.99083 14.208 7.38767 13.6253 7.97039C13.0426 8.55311 12.6458 9.29554 12.485 10.1038C12.3242 10.912 12.4067 11.7498 12.7221 12.5112C13.0375 13.2725 13.5715 13.9233 14.2567 14.3811C14.9419 14.839 15.7475 15.0833 16.5716 15.0833C17.2311 15.0791 17.88 14.9162 18.4633 14.6083C18.499 14.5843 18.5411 14.5715 18.5841 14.5715C18.6271 14.5715 18.6692 14.5843 18.7049 14.6083L20.5716 16.475C20.7285 16.6308 20.9409 16.7179 21.162 16.7171C21.3832 16.7163 21.595 16.6278 21.7508 16.4708C21.9066 16.3139 21.9937 16.1015 21.9929 15.8804C21.9921 15.6593 21.9035 15.4475 21.7466 15.2917L19.9716 13.575ZM16.5549 13.4167C16.0605 13.4167 15.5771 13.27 15.166 12.9953C14.7549 12.7206 14.4345 12.3302 14.2452 11.8734C14.056 11.4166 14.0065 10.9139 14.103 10.4289C14.1994 9.94399 14.4375 9.49853 14.7872 9.1489C15.1368 8.79927 15.5823 8.56117 16.0672 8.4647C16.5522 8.36824 17.0548 8.41775 17.5116 8.60697C17.9685 8.79619 18.3589 9.11662 18.6336 9.52774C18.9083 9.93886 19.0549 10.4222 19.0549 10.9167C19.0549 11.245 18.9903 11.5701 18.8646 11.8734C18.739 12.1767 18.5548 12.4523 18.3227 12.6844C18.0906 12.9166 17.815 13.1007 17.5116 13.2264C17.2083 13.352 16.8832 13.4167 16.5549 13.4167Z"
        fill="#1D1D1D"
      />
      <path
        d="M15.7216 3.83333V5.5C15.7216 5.72101 15.8094 5.93298 15.9657 6.08926C16.122 6.24554 16.3339 6.33333 16.5549 6.33333C16.7759 6.33333 16.9879 6.24554 17.1442 6.08926C17.3005 5.93298 17.3883 5.72101 17.3883 5.5V3.83333C17.3883 3.61232 17.3005 3.40036 17.1442 3.24408C16.9879 3.0878 16.7759 3 16.5549 3C16.3339 3 16.122 3.0878 15.9657 3.24408C15.8094 3.40036 15.7216 3.61232 15.7216 3.83333Z"
        fill="#1D1D1D"
      />
      <path
        d="M12.2216 4.49167C12.1435 4.56914 12.0815 4.6613 12.0392 4.76285C11.9969 4.8644 11.9751 4.97332 11.9751 5.08333C11.9751 5.19334 11.9969 5.30226 12.0392 5.40381C12.0815 5.50536 12.1435 5.59753 12.2216 5.675L13.0549 6.50833C13.2119 6.66415 13.4242 6.75124 13.6454 6.75046C13.8665 6.74968 14.0783 6.66109 14.2341 6.50417C14.3899 6.34725 14.477 6.13486 14.4762 5.91372C14.4754 5.69258 14.3869 5.48081 14.2299 5.325L13.3966 4.49167C13.2405 4.33646 13.0293 4.24934 12.8091 4.24934C12.5889 4.24934 12.3777 4.33646 12.2216 4.49167Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.7216 4.49167L18.8883 5.325C18.7313 5.48081 18.6428 5.69258 18.642 5.91372C18.6412 6.13486 18.7283 6.34725 18.8841 6.50417C19.0399 6.66109 19.2517 6.74968 19.4728 6.75046C19.694 6.75124 19.9063 6.66415 20.0633 6.50833L20.8966 5.675C20.9747 5.59753 21.0367 5.50536 21.079 5.40381C21.1213 5.30226 21.1431 5.19334 21.1431 5.08333C21.1431 4.97332 21.1213 4.8644 21.079 4.76285C21.0367 4.6613 20.9747 4.56914 20.8966 4.49167C20.7405 4.33646 20.5293 4.24934 20.3091 4.24934C20.0889 4.24934 19.8777 4.33646 19.7216 4.49167Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default SEO;
