export const SUBDOMAIN_NAMES = {
  kw: 'kw',
  uc: 'uc',
  wtp: 'wtp',
  fn: 'fn',
  prod: 'prod',
  app: 'app',
  staging_2: 'staging-2',
};

const SUBDOMAINS = Object.values(SUBDOMAIN_NAMES);

export const getSubdomain = () => {
  const hotsURL = window.location.host;
  const [subdomain] = hotsURL.split('.');

  if (SUBDOMAINS.includes(subdomain)) {
    return subdomain;
  }

  return null;
};
