import React, { useState } from 'react';
import { useFeatureFlagOverrides } from 'services/FeatureFlags';
import Dropdown from 'components/ui/Inputs/Dropdown';
import Input from 'components/ui/Inputs/Input';
import Typography from 'components/ui/Typography';
import { useFeatureFlagsContext } from 'services/FeatureFlags/context';
import CustomModal from 'components/CustomModal';
import Icon from 'components/ui/Icon';
import EditPayloadModal from './editPayloadModal';

import * as styles from './styles.module.scss';

interface FeatureFlagModalProps {
  onClose: () => void;
}

const FeatureFlagModal: React.FC<FeatureFlagModalProps> = ({ onClose }) => {
  const [featureFlagsOverrides, setOverrideFeatureFlag] =
    useFeatureFlagOverrides();
  const { remoteFeatureFlags } = useFeatureFlagsContext();
  const [currentFeatureFlag, setCurrentFeatureFlag] = useState<string | null>(
    null,
  );

  const OVERRIDE_VALUES = {
    TRUE: 'true',
    FALSE: 'false',
    NO_OVERRIDE: 'no override',
  };

  const handleNewOverride = (featureFlag: string, override: string) => {
    const overrideMapping: { [key: string]: boolean } = {
      true: true,
      false: false,
    };

    const featureFlagValue =
      override === '' || override === OVERRIDE_VALUES.NO_OVERRIDE ?
        null
      : (overrideMapping[override] ?? override);

    setOverrideFeatureFlag(featureFlag, featureFlagValue);
  };

  const handleEditPayload = (featureFlag: string) => {
    setCurrentFeatureFlag(featureFlag);
  };

  return (
    <>
      <CustomModal
        onClose={onClose}
        shouldCloseOnEsc
        positionModifier="center-full"
        className={styles.modal}
      >
        <CustomModal.Title title="Feature Flags" onClose={onClose} />
        <div>
          <div className={styles.container}>
            <div className={styles.tile}>
              <Typography type="Header5b">Feature Flag</Typography>
              <Typography type="Header5b">Remote Value</Typography>
              <Typography type="Header5b">Override</Typography>
              <Typography type="Header5b">Payload</Typography>
            </div>
            {Object.keys(featureFlagsOverrides).map((featureFlag) => (
              <div className={styles.tile} key={featureFlag}>
                <Typography type="Header5r">{featureFlag}</Typography>
                <Typography type="ParagraphRegular">
                  {remoteFeatureFlags[featureFlag].value.toString()}
                </Typography>
                {typeof remoteFeatureFlags[featureFlag].value === 'string' ?
                  <Input
                    type="text"
                    value={String(
                      featureFlagsOverrides[featureFlag]?.value ?? '',
                    )}
                    onChange={(event) => {
                      const override = event.target.value;
                      handleNewOverride(featureFlag, override);
                    }}
                    name={`override-${featureFlag}`}
                    placeholder={String(
                      featureFlagsOverrides[featureFlag]?.value ??
                        'Enter override value',
                    )}
                    theme="light"
                  />
                : <Dropdown
                    options={[
                      { value: OVERRIDE_VALUES.TRUE, label: 'true' },
                      { value: OVERRIDE_VALUES.FALSE, label: 'false' },
                      {
                        value: OVERRIDE_VALUES.NO_OVERRIDE,
                        label: 'no override',
                      },
                    ]}
                    value={
                      featureFlagsOverrides[featureFlag]?.value?.toString() ??
                      OVERRIDE_VALUES.NO_OVERRIDE
                    }
                    onSelect={(value) => {
                      handleNewOverride(featureFlag, value.toString());
                    }}
                    name={`override-${featureFlag}`}
                  />
                }
                {remoteFeatureFlags[featureFlag].payload && (
                  <button
                    className={styles.editButton}
                    onClick={() => handleEditPayload(featureFlag)}
                  >
                    <Icon width={'20'} height={'20'} id={'pencilEdit'} />
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </CustomModal>

      {currentFeatureFlag && (
        <EditPayloadModal
          currentFeatureFlag={currentFeatureFlag}
          featureFlagsOverrides={featureFlagsOverrides}
          remoteFeatureFlags={remoteFeatureFlags}
          setOverrideFeatureFlag={setOverrideFeatureFlag}
          onClose={() => setCurrentFeatureFlag(null)}
        />
      )}
    </>
  );
};

export default FeatureFlagModal;
