import actionTypes from '../actions/errors/actions';

const ACTION_HANDLERS = {
  [actionTypes.ADD_ERROR]: (state, action) => {
    if (action.error instanceof TypeError) {
      return {
        ...state,
        errors: [...state.errors, { detail: ['Error'] }],
      };
    }

    return { ...state, errors: [...state.errors, ...action.error.json.errors] };
  },
  [actionTypes.REMOVE_ERROR]: (state, action) => {
    const filteredErrors = state.errors.filter(
      (error) => error.id !== action.id,
    );
    return {
      ...state,
      errors: filteredErrors,
    };
  },
};

const initialState = {
  errors: [],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
