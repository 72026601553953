/* eslint-disable max-len */
import React from 'react';
import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Webinar = ({
  fill = colors.black,
  variant = 'filled',
  width = '24',
  height = '24',
}: IconPropsWithVariant) => {
  const filled = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2714 24.3604C23.8681 23.9115 23.304 23.6397 22.7016 23.6042H8.65366C8.57768 23.6042 8.50482 23.574 8.4511 23.5203C8.39738 23.4665 8.3672 23.3937 8.3672 23.3177V14.151C8.3672 14.0751 8.39738 14.0022 8.4511 13.9485C8.50482 13.8948 8.57768 13.8646 8.65366 13.8646H15.8266C16.1305 13.8646 16.4219 13.7439 16.6368 13.529C16.8517 13.3141 16.9724 13.0226 16.9724 12.7188C16.9724 12.4149 16.8517 12.1234 16.6368 11.9085C16.4219 11.6936 16.1305 11.5729 15.8266 11.5729H8.3672C7.75941 11.5729 7.17652 11.8144 6.74675 12.2441C6.31698 12.6739 6.07553 13.2568 6.07553 13.8646V24.1771C6.07553 24.1771 6.07553 24.2344 6.07553 24.2687C6.08923 24.3196 6.08665 24.3735 6.06816 24.4228C6.04966 24.4721 6.01618 24.5144 5.97241 24.5437L3.68074 26.4115C3.52988 26.532 3.41191 26.6886 3.33779 26.8669C3.26367 27.0452 3.23581 27.2393 3.25678 27.4312C3.27375 27.7067 3.34644 27.9758 3.47046 28.2224C3.59447 28.4689 3.76722 28.6877 3.97825 28.8655C4.18928 29.0433 4.43421 29.1765 4.69819 29.2569C4.96218 29.3373 5.23974 29.3633 5.51407 29.3333H24.9932C25.2702 29.3683 25.5515 29.3461 25.8195 29.268C26.0876 29.1899 26.3367 29.0576 26.5516 28.8793C26.7664 28.7009 26.9423 28.4804 27.0685 28.2313C27.1946 27.9823 27.2683 27.7099 27.2849 27.4312C27.3059 27.2393 27.278 27.0452 27.2039 26.8669C27.1298 26.6886 27.0118 26.532 26.8609 26.4115C25.9554 25.7831 25.0904 25.098 24.2714 24.3604ZM17.5339 27.3281H12.9505C12.7226 27.3281 12.504 27.2376 12.3429 27.0764C12.1817 26.9153 12.0912 26.6967 12.0912 26.4688C12.0912 26.2408 12.1817 26.0222 12.3429 25.8611C12.504 25.6999 12.7226 25.6094 12.9505 25.6094H17.5339C17.7618 25.6094 17.9804 25.6999 18.1415 25.8611C18.3027 26.0222 18.3932 26.2408 18.3932 26.4688C18.3932 26.6967 18.3027 26.9153 18.1415 27.0764C17.9804 27.2376 17.7618 27.3281 17.5339 27.3281Z"
        fill={fill}
      />
      <path
        d="M27.9609 18.6656C27.9191 18.6115 27.8965 18.545 27.8965 18.4766C27.8965 18.4081 27.9191 18.3417 27.9609 18.2875C28.64 17.329 29.008 16.185 29.0151 15.0104C29.0151 13.8773 28.6791 12.7696 28.0496 11.8275C27.42 10.8853 26.5253 10.151 25.4784 9.71736C24.4315 9.28373 23.2796 9.17027 22.1682 9.39133C21.0569 9.6124 20.0361 10.158 19.2348 10.9593C18.4336 11.7605 17.8879 12.7814 17.6669 13.8927C17.4458 15.0041 17.5593 16.156 17.9929 17.2029C18.4265 18.2497 19.1608 19.1445 20.103 19.774C21.0452 20.4036 22.1528 20.7396 23.2859 20.7396C24.1928 20.7338 25.085 20.5098 25.887 20.0865C25.9361 20.0535 25.994 20.0358 26.0531 20.0358C26.1123 20.0358 26.1702 20.0535 26.2193 20.0865L28.7859 22.6531C29.0017 22.8674 29.2938 22.9871 29.5978 22.9861C29.9019 22.985 30.1931 22.8632 30.4073 22.6474C30.6215 22.4316 30.7413 22.1396 30.7402 21.8355C30.7392 21.5315 30.6173 21.2403 30.4016 21.026L27.9609 18.6656ZM23.263 18.4479C22.5832 18.4479 21.9186 18.2463 21.3533 17.8686C20.788 17.4909 20.3474 16.954 20.0872 16.3259C19.827 15.6978 19.7589 15.0066 19.8916 14.3398C20.0242 13.673 20.3516 13.0605 20.8324 12.5797C21.3131 12.099 21.9256 11.7716 22.5924 11.639C23.2592 11.5063 23.9504 11.5744 24.5785 11.8346C25.2066 12.0948 25.7435 12.5353 26.1212 13.1006C26.4989 13.6659 26.7005 14.3305 26.7005 15.0104C26.7005 15.4618 26.6116 15.9088 26.4389 16.3259C26.2661 16.7429 26.0129 17.1219 25.6937 17.4411C25.3745 17.7603 24.9956 18.0135 24.5785 18.1863C24.1614 18.359 23.7145 18.4479 23.263 18.4479Z"
        fill={fill}
      />
      <path
        d="M22.1172 5.27083V7.5625C22.1172 7.86639 22.2379 8.15784 22.4528 8.37273C22.6677 8.58761 22.9591 8.70833 23.263 8.70833C23.5669 8.70833 23.8584 8.58761 24.0733 8.37273C24.2881 8.15784 24.4089 7.86639 24.4089 7.5625V5.27083C24.4089 4.96694 24.2881 4.67549 24.0733 4.46061C23.8584 4.24572 23.5669 4.125 23.263 4.125C22.9591 4.125 22.6677 4.24572 22.4528 4.46061C22.2379 4.67549 22.1172 4.96694 22.1172 5.27083Z"
        fill={fill}
      />
      <path
        d="M17.3047 6.17604C17.1973 6.28256 17.1121 6.40929 17.0539 6.54892C16.9957 6.68855 16.9658 6.83832 16.9658 6.98958C16.9658 7.14085 16.9957 7.29061 17.0539 7.43024C17.1121 7.56987 17.1973 7.6966 17.3047 7.80312L18.4505 8.94896C18.6663 9.1632 18.9583 9.28296 19.2624 9.28189C19.5665 9.28081 19.8576 9.15899 20.0719 8.94323C20.2861 8.72746 20.4059 8.43543 20.4048 8.13137C20.4037 7.8273 20.2819 7.53612 20.0662 7.32188L18.9203 6.17604C18.7056 5.96263 18.4152 5.84284 18.1125 5.84284C17.8098 5.84284 17.5194 5.96263 17.3047 6.17604Z"
        fill={fill}
      />
      <path
        d="M27.6172 6.17604L26.4714 7.32188C26.2556 7.53612 26.1338 7.8273 26.1327 8.13137C26.1316 8.43543 26.2514 8.72746 26.4656 8.94323C26.6799 9.15899 26.9711 9.28081 27.2751 9.28189C27.5792 9.28296 27.8712 9.1632 28.087 8.94896L29.2328 7.80312C29.3402 7.6966 29.4255 7.56987 29.4836 7.43024C29.5418 7.29061 29.5718 7.14085 29.5718 6.98958C29.5718 6.83832 29.5418 6.68855 29.4836 6.54892C29.4255 6.40929 29.3402 6.28256 29.2328 6.17604C29.0181 5.96263 28.7277 5.84284 28.425 5.84284C28.1223 5.84284 27.8319 5.96263 27.6172 6.17604Z"
        fill={fill}
      />
    </svg>
  );

  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2714 24.3604C23.8681 23.9115 23.304 23.6397 22.7016 23.6042H8.65366C8.57768 23.6042 8.50482 23.574 8.4511 23.5203C8.39738 23.4665 8.3672 23.3937 8.3672 23.3177V14.151C8.3672 14.0751 8.39738 14.0022 8.4511 13.9485C8.50482 13.8948 8.57768 13.8646 8.65366 13.8646H15.8266C16.1305 13.8646 16.4219 13.7439 16.6368 13.529C16.8517 13.3141 16.9724 13.0226 16.9724 12.7188C16.9724 12.4149 16.8517 12.1234 16.6368 11.9085C16.4219 11.6936 16.1305 11.5729 15.8266 11.5729H8.3672C7.75941 11.5729 7.17652 11.8144 6.74675 12.2441C6.31698 12.6739 6.07553 13.2568 6.07553 13.8646V24.1771C6.07553 24.1771 6.07553 24.2344 6.07553 24.2687C6.08923 24.3196 6.08665 24.3735 6.06816 24.4228C6.04966 24.4721 6.01618 24.5144 5.97241 24.5437L3.68074 26.4115C3.52988 26.532 3.41191 26.6886 3.33779 26.8669C3.26367 27.0452 3.23581 27.2393 3.25678 27.4312C3.27375 27.7067 3.34644 27.9758 3.47046 28.2224C3.59447 28.4689 3.76722 28.6877 3.97825 28.8655C4.18928 29.0433 4.43421 29.1765 4.69819 29.2569C4.96218 29.3373 5.23974 29.3633 5.51407 29.3333H24.9932C25.2702 29.3683 25.5515 29.3461 25.8195 29.268C26.0876 29.1899 26.3367 29.0576 26.5516 28.8793C26.7664 28.7009 26.9423 28.4804 27.0685 28.2313C27.1946 27.9823 27.2683 27.7099 27.2849 27.4312C27.3059 27.2393 27.278 27.0452 27.2039 26.8669C27.1298 26.6886 27.0118 26.532 26.8609 26.4115C25.9554 25.7831 25.0904 25.098 24.2714 24.3604ZM17.5339 27.3281H12.9505C12.7226 27.3281 12.504 27.2376 12.3429 27.0764C12.1817 26.9153 12.0912 26.6967 12.0912 26.4688C12.0912 26.2408 12.1817 26.0222 12.3429 25.8611C12.504 25.6999 12.7226 25.6094 12.9505 25.6094H17.5339C17.7618 25.6094 17.9804 25.6999 18.1415 25.8611C18.3027 26.0222 18.3932 26.2408 18.3932 26.4688C18.3932 26.6967 18.3027 26.9153 18.1415 27.0764C17.9804 27.2376 17.7618 27.3281 17.5339 27.3281Z"
        stroke={fill}
      />
      <path
        d="M27.9609 18.6656C27.9191 18.6115 27.8965 18.545 27.8965 18.4766C27.8965 18.4081 27.9191 18.3417 27.9609 18.2875C28.64 17.329 29.008 16.185 29.0151 15.0104C29.0151 13.8773 28.6791 12.7696 28.0496 11.8275C27.42 10.8853 26.5253 10.151 25.4784 9.71736C24.4315 9.28373 23.2796 9.17027 22.1682 9.39133C21.0569 9.6124 20.0361 10.158 19.2348 10.9593C18.4336 11.7605 17.8879 12.7814 17.6669 13.8927C17.4458 15.0041 17.5593 16.156 17.9929 17.2029C18.4265 18.2497 19.1608 19.1445 20.103 19.774C21.0452 20.4036 22.1528 20.7396 23.2859 20.7396C24.1928 20.7338 25.085 20.5098 25.887 20.0865C25.9361 20.0535 25.994 20.0358 26.0531 20.0358C26.1123 20.0358 26.1702 20.0535 26.2193 20.0865L28.7859 22.6531C29.0017 22.8674 29.2938 22.9871 29.5978 22.9861C29.9019 22.985 30.1931 22.8632 30.4073 22.6474C30.6215 22.4316 30.7413 22.1396 30.7402 21.8355C30.7392 21.5315 30.6173 21.2403 30.4016 21.026L27.9609 18.6656ZM23.263 18.4479C22.5832 18.4479 21.9186 18.2463 21.3533 17.8686C20.788 17.4909 20.3474 16.954 20.0872 16.3259C19.827 15.6978 19.7589 15.0066 19.8916 14.3398C20.0242 13.673 20.3516 13.0605 20.8324 12.5797C21.3131 12.099 21.9256 11.7716 22.5924 11.639C23.2592 11.5063 23.9504 11.5744 24.5785 11.8346C25.2066 12.0948 25.7435 12.5353 26.1212 13.1006C26.4989 13.6659 26.7005 14.3305 26.7005 15.0104C26.7005 15.4618 26.6116 15.9088 26.4389 16.3259C26.2661 16.7429 26.0129 17.1219 25.6937 17.4411C25.3745 17.7603 24.9956 18.0135 24.5785 18.1863C24.1614 18.359 23.7145 18.4479 23.263 18.4479Z"
        stroke={fill}
      />
      <path
        d="M22.1172 5.27083V7.5625C22.1172 7.86639 22.2379 8.15784 22.4528 8.37273C22.6677 8.58761 22.9591 8.70833 23.263 8.70833C23.5669 8.70833 23.8584 8.58761 24.0733 8.37273C24.2881 8.15784 24.4089 7.86639 24.4089 7.5625V5.27083C24.4089 4.96694 24.2881 4.67549 24.0733 4.46061C23.8584 4.24572 23.5669 4.125 23.263 4.125C22.9591 4.125 22.6677 4.24572 22.4528 4.46061C22.2379 4.67549 22.1172 4.96694 22.1172 5.27083Z"
        stroke={fill}
      />
      <path
        d="M17.3047 6.17604C17.1973 6.28256 17.1121 6.40929 17.0539 6.54892C16.9957 6.68855 16.9658 6.83832 16.9658 6.98958C16.9658 7.14085 16.9957 7.29061 17.0539 7.43024C17.1121 7.56987 17.1973 7.6966 17.3047 7.80312L18.4505 8.94896C18.6663 9.1632 18.9583 9.28296 19.2624 9.28189C19.5665 9.28081 19.8576 9.15899 20.0719 8.94323C20.2861 8.72746 20.4059 8.43543 20.4048 8.13137C20.4037 7.8273 20.2819 7.53612 20.0662 7.32188L18.9203 6.17604C18.7056 5.96263 18.4152 5.84284 18.1125 5.84284C17.8098 5.84284 17.5194 5.96263 17.3047 6.17604Z"
        stroke={fill}
      />
      <path
        d="M27.6172 6.17604L26.4714 7.32188C26.2556 7.53612 26.1338 7.8273 26.1327 8.13137C26.1316 8.43543 26.2514 8.72746 26.4656 8.94323C26.6799 9.15899 26.9711 9.28081 27.2751 9.28189C27.5792 9.28296 27.8712 9.1632 28.087 8.94896L29.2328 7.80312C29.3402 7.6966 29.4255 7.56987 29.4836 7.43024C29.5418 7.29061 29.5718 7.14085 29.5718 6.98958C29.5718 6.83832 29.5418 6.68855 29.4836 6.54892C29.4255 6.40929 29.3402 6.28256 29.2328 6.17604C29.0181 5.96263 28.7277 5.84284 28.425 5.84284C28.1223 5.84284 27.8319 5.96263 27.6172 6.17604Z"
        stroke={fill}
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

export default Webinar;
