import { makeAuthenticatedRequest } from './utils/request';
import { getAPIURL } from './utils/domains';

export const fetchPlansAttributtes = ({ active = false }) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/base_plan_attributes.json?active=${active}`,
  );

export default {
  fetchPlansAttributtes,
};
