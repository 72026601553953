import { useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import { useIdentifyUser } from 'services/FeatureFlags';
import AppsFlyer from 'services/AppsFlyer';
import EventRouter from 'services/EventRouter';
import * as amplitude from '@amplitude/analytics-browser';
import api from 'api';
import usePrevious from 'hooks/usePrevious';

const UserContext = () => {
  const user = useSelector((state) => state.user?.user);

  const identifyUser = useIdentifyUser();

  useEffect(() => {
    identifyUser(user?.id);

    if (user?.id) {
      AppsFlyer.identifyUser(user?.id);
    }
  }, [user?.id]);

  useEffect(() => {
    const trackSessionStarted = async () => {
      let {
        subscription_plan: subscriptionPlan,
        subscription_status: subscriptionStatus,
      } = user;

      if (!subscriptionPlan || !subscriptionStatus) {
        amplitude.track('debug', {
          key: 'subscription_properties_missing',
          willBeQueued: EventRouter.enabled === null,
          currentPage: window.location.href,
          subscription_status: subscriptionStatus,
          subscription_plan: subscriptionPlan,
          timestampMillis: Date.now(),
        });

        const data = await api.getUser();
        subscriptionPlan = data.subscription_plan;
        subscriptionStatus = data.subscription_status;
      }

      EventRouter.send('account_session_started', {
        login_type: 'token',
        plan_selected: subscriptionPlan,
        subscription_status: subscriptionStatus,
      });
    };

    if (user?.id && !!user?.email_verified) {
      trackSessionStarted();
    }
  }, [user?.id, user?.email_verified]);

  const subscriptionStatus = user?.subscription_status;
  const prevSubscriptionStatus = usePrevious(subscriptionStatus);

  useEffect(() => {
    if (
      user?.id &&
      (!prevSubscriptionStatus || !subscriptionStatus) &&
      subscriptionStatus !== prevSubscriptionStatus
    ) {
      amplitude.track('debug', {
        key: 'subscription_status_set_or_unset',
        userId: user.id,
        subscription_status: subscriptionStatus,
        previous_subscription_status: prevSubscriptionStatus,
        timestampMillis: Date.now(),
      });
    }
  }, [user?.id, subscriptionStatus, prevSubscriptionStatus]);

  return null;
};
export default memo(UserContext);
