import { convert } from 'terraformer-wkt-parser';
import { parse } from 'wellknown';
import distance from '@turf/distance';
import inside from '@turf/inside';
import proj4 from 'proj4';

// eslint-disable-next-line
const PLANAR_PROJECTION =
  'PROJCS["WGS_1984_Web_Mercator_Auxiliary_Sphere",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Mercator_Auxiliary_Sphere"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],PARAMETER["Auxiliary_Sphere_Type",0.0],UNIT["Meter",1.0]]';
const SPHEROID_PROJECTION = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
const LATITUDE_MAX_VALUE = 90;
const LONGITUDE_MAX_VALUE = 180;

export const latLngToPlaneCoordinates = (geoJSON) => {
  const { geometry, properties } = geoJSON;
  let coordinates;
  if (properties?.toolType === 'polylines') {
    ({ coordinates } = geometry);
  } else {
    ({
      coordinates: [coordinates],
    } = geometry);
  }

  const planarCoordinates = coordinates.map((coordinate) =>
    proj4(SPHEROID_PROJECTION, PLANAR_PROJECTION, coordinate),
  );

  const polygon = convert({
    type: 'Polygon',
    coordinates: [planarCoordinates],
  });

  return polygon;
};

export const multiPolygonLatLngToPlaneCoordinates = (geoJSON) => {
  const {
    geometry: { coordinates: multiPolygonCoordinates },
  } = geoJSON;

  const planarCoordinates = multiPolygonCoordinates.map((polygonCoordinates) =>
    polygonCoordinates.map((polygonCoordinate) =>
      polygonCoordinate.map((polygonCoordinatePair) =>
        proj4(SPHEROID_PROJECTION, PLANAR_PROJECTION, polygonCoordinatePair),
      ),
    ),
  );

  const polygon = convert({
    type: 'MultiPolygon',
    coordinates: planarCoordinates,
  });

  return polygon;
};

export const planeCoordinatesToLatLng = (wkt) => {
  let coordinates;
  const projectedCoordinates = [];
  const geoJSON = parse(wkt);

  geoJSON.geometry = {
    coordinates: {},
    type: 'Polygon',
  };

  geoJSON.coordinates.forEach((planarCoordinates) => {
    coordinates = planarCoordinates.map((coordinate) => {
      const projectedCoords = proj4(
        PLANAR_PROJECTION,
        SPHEROID_PROJECTION,
        coordinate,
      );
      return [projectedCoords[0], projectedCoords[1]];
    });

    projectedCoordinates.push(coordinates);
  });

  geoJSON.geometry.coordinates = projectedCoordinates;

  return geoJSON.geometry;
};

export const coordinatesToPixels = (geoJSON, type, map) => {
  let coordinates;

  switch (type) {
    case 'icons':
      coordinates = [geoJSON.geometry.coordinates];
      break;
    case 'polylines':
      ({
        geometry: { coordinates },
      } = geoJSON);
      break;
    default:
      ({
        geometry: {
          coordinates: [coordinates],
        },
      } = geoJSON);
  }

  return mapCoordinatesToPoints(coordinates, map);
};

export const mapCoordinatesToPoints = (coordinates, map) =>
  coordinates.map((coord) => {
    const point = map.project(coord);
    return [point.x, point.y];
  });

// Returns SVG path of the given coordinates.
export const getPathFromCoords = (coords) => {
  let pathString = 'M';
  coords.forEach((coord) => {
    pathString = `${pathString} ${coord[0]} ${coord[1]},`;
  });

  return pathString;
};

export const pointsDistance = (p1, p2, map) => {
  const coord1 = map.unproject(p1);
  const coord2 = map.unproject(p2);

  return distance(coord1.toArray(), coord2.toArray());
};

export const getFeaturesInMapBounds = (features, map) => {
  const bounds = map.getBounds();
  const polygonBounds = [
    [
      bounds.getSouthEast().toArray(),
      bounds.getNorthEast().toArray(),
      bounds.getNorthWest().toArray(),
      bounds.getSouthWest().toArray(),
      bounds.getSouthEast().toArray(),
    ],
  ];

  const boundsPolygon = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: polygonBounds,
    },
  };

  return features.filter((feature) =>
    inside(feature.geometry.coordinates, boundsPolygon),
  );
};

export const isLatValid = (lat) =>
  !isNaN(lat) && lat >= -LATITUDE_MAX_VALUE && lat <= LATITUDE_MAX_VALUE;

export const isLngValid = (lng) =>
  !isNaN(lng) && lng >= -LONGITUDE_MAX_VALUE && lng <= LONGITUDE_MAX_VALUE;

export const chainsToFeet = (chains) => chains * 66;

export const polesRodsPerchesToFeet = (poles) => poles * 16.5;

export const varasToFeet = (varas) => varas * 2.77778;

export const convertDistance = (distance, toUnit, fromUnit) => {
  if (!distance) {
    return 0;
  }

  const conversions = {
    meters: {
      factor: 1,
      system: 'metric',
    },
    kilometers: {
      factor: 1000,
      system: 'metric',
    },
    miles: {
      factor: 5280,
      system: 'imperial',
    },
    feet: {
      factor: 1,
      system: 'imperial',
    },
    chains: {
      factor: 66,
      system: 'imperial',
    },
    poles: {
      factor: 16.5,
      system: 'imperial',
    },
    rods: {
      factor: 16.5,
      system: 'imperial',
    },
    perches: {
      factor: 16.5,
      system: 'imperial',
    },
    varas: {
      factor: 2.77778,
      system: 'imperial',
    },
  };

  const anchors = {
    metric: {
      unit: 'meters',
      ratio: 3.28084,
    },
    imperial: {
      unit: 'feet',
      ratio: 0.3048,
    },
  };

  if (fromUnit === toUnit) {
    return distance;
  }

  const fromUnitData = conversions[fromUnit];
  const toUnitData = conversions[toUnit];

  let anchor = distance * fromUnitData.factor;
  if (fromUnitData.system !== toUnitData.system) {
    anchor *= anchors[fromUnitData.system].ratio;
  }

  let result = anchor / toUnitData.factor;

  if (
    result.toString().split('.')[1] &&
    result.toString().split('.')[1].length > 2
  ) {
    result = result.toFixed(2);
  }

  return Number(result);
};

const getValidLongitude = (lng) => {
  if (lng < -LONGITUDE_MAX_VALUE) {
    return (lng % LONGITUDE_MAX_VALUE) + LONGITUDE_MAX_VALUE;
  } else if (lng > LONGITUDE_MAX_VALUE) {
    return (lng % LONGITUDE_MAX_VALUE) - LONGITUDE_MAX_VALUE;
  }

  return lng;
};

export const getValidCoordinate = ([lng, lat]) => [getValidLongitude(lng), lat];
