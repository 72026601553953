import { actionTypes } from '../actions/georeference';
import settingsActionTypes from '../actions/settings/actions';

const ACTION_HANDLERS = {
  [actionTypes.START_DRAWING_CONTROL_POINT]: (state, action) => ({
    ...state,
    status: 'DRAWING',
    maprightId: action.maprightId,
  }),
  [actionTypes.STOP_DRAWING_CONTROL_POINT]: (state, action) => ({
    ...state,
    status: 'NOT_DRAWING',
    maprightId: null,
  }),
  [actionTypes.CHANGE_STATUS]: (state, action) => ({
    ...state,
    status: action.status,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

// Reducer
const initialState = {
  status: 'NOT_DRAWING',
  maprightId: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
