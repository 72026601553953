import { useEffect } from 'react';

interface RefObject {
  current: HTMLElement | null;
}

type Handler = (event: MouseEvent | TouchEvent) => void;

export default function useOnClickOutside(
  ref: RefObject,
  handler: Handler,
  disabled = false,
) {
  useEffect(() => {
    if (disabled) {
      return;
    }

    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);

    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, handler, disabled]);
}
