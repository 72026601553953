import { IconProps } from '../types';
import * as colors from '../../styles/colors.module.scss';

const Information = ({
  fill = colors.links300,
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12.2083 6.16667C12.4556 6.16667 12.6972 6.23998 12.9028 6.37733C13.1084 6.51468 13.2686 6.7099 13.3632 6.93831C13.4578 7.16672 13.4825 7.41805 13.4343 7.66053C13.3861 7.903 13.267 8.12573 13.0922 8.30055C12.9174 8.47536 12.6947 8.59442 12.4522 8.64265C12.2097 8.69088 11.9584 8.66612 11.73 8.57152C11.5016 8.47691 11.3064 8.31669 11.169 8.11113C11.0316 7.90557 10.9583 7.66389 10.9583 7.41667C10.9583 7.08515 11.09 6.7672 11.3245 6.53278C11.5589 6.29836 11.8768 6.16667 12.2083 6.16667ZM14.0833 17.4167H10.75C10.529 17.4167 10.317 17.3289 10.1607 17.1726C10.0045 17.0163 9.91667 16.8043 9.91667 16.5833C9.91667 16.3623 10.0045 16.1504 10.1607 15.9941C10.317 15.8378 10.529 15.75 10.75 15.75H11.375C11.4303 15.75 11.4832 15.728 11.5223 15.689C11.5614 15.6499 11.5833 15.5969 11.5833 15.5417V11.7917C11.5833 11.7364 11.5614 11.6834 11.5223 11.6444C11.4832 11.6053 11.4303 11.5833 11.375 11.5833H10.75C10.529 11.5833 10.317 11.4955 10.1607 11.3393C10.0045 11.183 9.91667 10.971 9.91667 10.75C9.91667 10.529 10.0045 10.317 10.1607 10.1607C10.317 10.0045 10.529 9.91667 10.75 9.91667H11.5833C12.0254 9.91667 12.4493 10.0923 12.7618 10.4048C13.0744 10.7174 13.25 11.1413 13.25 11.5833V15.5417C13.25 15.5969 13.272 15.6499 13.311 15.689C13.3501 15.728 13.4031 15.75 13.4583 15.75H14.0833C14.3043 15.75 14.5163 15.8378 14.6726 15.9941C14.8289 16.1504 14.9167 16.3623 14.9167 16.5833C14.9167 16.8043 14.8289 17.0163 14.6726 17.1726C14.5163 17.3289 14.3043 17.4167 14.0833 17.4167Z"
      fill={fill}
    />
  </svg>
);

export default Information;
