import React from 'react';
import PropTypes from 'prop-types';

const Ellipsis = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.8889 11.9445C4.4046 11.9445 4.89918 11.7396 5.26383 11.3749C5.62849 11.0103 5.83335 10.5157 5.83335 10C5.83335 9.48431 5.62849 8.98974 5.26383 8.62508C4.89918 8.26043 4.4046 8.05557 3.8889 8.05557C3.3732 8.05557 2.87863 8.26043 2.51397 8.62508C2.14932 8.98974 1.94446 9.48431 1.94446 10C1.94446 10.5157 2.14932 11.0103 2.51397 11.3749C2.87863 11.7396 3.3732 11.9445 3.8889 11.9445ZM16.6666 11.9445C17.1823 11.9445 17.6769 11.7396 18.0415 11.3749C18.4062 11.0103 18.6111 10.5157 18.6111 10C18.6111 9.48431 18.4062 8.98974 18.0415 8.62508C17.6769 8.26043 17.1823 8.05557 16.6666 8.05557C16.1509 8.05557 15.6563 8.26043 15.2917 8.62508C14.927 8.98974 14.7222 9.48431 14.7222 10C14.7222 10.5157 14.927 11.0103 15.2917 11.3749C15.6563 11.7396 16.1509 11.9445 16.6666 11.9445ZM11.6528 11.3749C11.2881 11.7396 10.7936 11.9445 10.2779 11.9445C9.76216 11.9445 9.26758 11.7396 8.90293 11.3749C8.53828 11.0103 8.33341 10.5157 8.33341 10C8.33341 9.48431 8.53828 8.98974 8.90293 8.62508C9.26758 8.26043 9.76216 8.05557 10.2779 8.05557C10.7936 8.05557 11.2881 8.26043 11.6528 8.62508C12.0174 8.98974 12.2223 9.48431 12.2223 10C12.2223 10.5157 12.0174 11.0103 11.6528 11.3749Z"
      fill={fill}
    />
  </svg>
);

Ellipsis.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Ellipsis.defaultProps = {
  fill: '#1D1D1D',
  width: '20',
  height: '20',
};

export default Ellipsis;
