import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { PLAN_ENTITLEMENTS_QUERY } from '../services/graphql';
import { PlanEntitlement, EntitlementCode } from '../types';

const useEntitlements = (): Record<EntitlementCode, PlanEntitlement> => {
  const { data } = useQuery(PLAN_ENTITLEMENTS_QUERY);

  const flattenedEntitlements = useMemo(() => {
    if (!data) return {} as Record<EntitlementCode, PlanEntitlement>;

    return data.planEntitlements.current.reduce(
      (acc, entitlement) => {
        acc[entitlement.code] = entitlement;
        return acc;
      },
      {} as Record<EntitlementCode, PlanEntitlement>,
    );
  }, [data]);

  return flattenedEntitlements;
};

export default useEntitlements;
