/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Add = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 2.5C11.25 1.80964 10.6904 1.25 10 1.25C9.30964 1.25 8.75 1.80964 8.75 2.5V8.75H2.5C1.80964 8.75 1.25 9.30964 1.25 10C1.25 10.6904 1.80964 11.25 2.5 11.25H8.75V17.5C8.75 18.1904 9.30964 18.75 10 18.75C10.6904 18.75 11.25 18.1904 11.25 17.5V11.25H17.5C18.1904 11.25 18.75 10.6904 18.75 10C18.75 9.30964 18.1904 8.75 17.5 8.75H11.25V2.5Z"
      fill={fill}
    />
  </svg>
);

Add.propTypes = {
  fill: PropTypes.string,
};

Add.defaultProps = {
  fill: '#1D1D1D',
};

export default Add;
