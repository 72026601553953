import { makeAuthenticatedRequest } from './utils/request';
import { getAPIURL } from './utils/domains';

export const addUserPaymentMethod = (userId, paymentMethod) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/users/${userId}/transactions/add_payment_method.json`,
    {
      method: 'POST',
      body: JSON.stringify(paymentMethod),
    },
  );

export default {
  addUserPaymentMethod,
};
