import React from 'react';
import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Pencil = ({
  fill = colors.black,
  width = '20',
  height = '20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2948 2.5C15.8761 2.5 16.4348 2.72561 16.8531 3.12932C17.0581 3.33373 17.2208 3.5766 17.3319 3.844C17.4429 4.11141 17.5 4.39809 17.5 4.68762C17.5 4.97715 17.4429 5.26383 17.3319 5.53124C17.2208 5.79864 17.0581 6.04151 16.8531 6.24592L16.3981 6.68224C16.3391 6.73791 16.261 6.76891 16.1799 6.76891C16.0988 6.76891 16.0208 6.73791 15.9618 6.68224L13.294 4.01444C13.2647 3.98546 13.2416 3.95099 13.2257 3.91301C13.2099 3.87504 13.2018 3.8343 13.2018 3.79316C13.2018 3.75201 13.2099 3.71128 13.2257 3.6733C13.2416 3.63532 13.2647 3.60085 13.294 3.57188L13.7365 3.12932C14.1548 2.72561 14.7135 2.5 15.2948 2.5ZM12.6083 4.69386C12.549 4.63636 12.4696 4.6042 12.387 4.6042C12.3044 4.6042 12.225 4.63636 12.1657 4.69386L5.05368 11.8059C5.02446 11.8349 5.00128 11.8694 4.98546 11.9073C4.96963 11.9453 4.96149 11.9861 4.96149 12.0272C4.96149 12.0683 4.96963 12.1091 4.98546 12.1471C5.00128 12.185 5.02446 12.2195 5.05368 12.2485L7.72148 14.9163C7.74946 14.9461 7.78325 14.9699 7.82078 14.9861C7.8583 15.0023 7.89875 15.0107 7.93964 15.0107C7.98053 15.0107 8.02098 15.0023 8.0585 14.9861C8.09603 14.9699 8.12982 14.9461 8.1578 14.9163L15.2761 7.80422C15.3336 7.74491 15.3658 7.66555 15.3658 7.58294C15.3658 7.50033 15.3336 7.42097 15.2761 7.36166L12.6083 4.69386ZM4.38673 12.9217C4.34855 12.8848 4.30174 12.858 4.25056 12.8439C4.19939 12.8297 4.14549 12.8286 4.09377 12.8406C4.04138 12.8517 3.99293 12.8766 3.95346 12.9128C3.91399 12.949 3.88495 12.9951 3.86938 13.0463L2.51678 17.048C2.49788 17.1027 2.49488 17.1616 2.50812 17.2178C2.52136 17.2741 2.5503 17.3255 2.59157 17.3659C2.63117 17.4144 2.68445 17.4499 2.74449 17.4677C2.80453 17.4855 2.86854 17.4848 2.92817 17.4657L6.92987 16.1318C6.97991 16.1141 7.02483 16.0843 7.0607 16.0452C7.09657 16.0061 7.12227 15.9588 7.13557 15.9074C7.15168 15.8525 7.15204 15.7941 7.13661 15.739C7.12118 15.6839 7.09058 15.6343 7.0483 15.5957L4.38673 12.9217Z"
      fill={fill}
    />
  </svg>
);

export default Pencil;
