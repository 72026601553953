import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M10.0002 14.1666C9.82415 14.1666 9.6646 14.1012 9.5482 13.9951L10.2471 14.1211C10.1711 14.1504 10.0876 14.1666 10.0002 14.1666Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M10.2471 14.1211L9.54354 13.9908L6.85579 11.4962C6.60384 11.2623 6.60384 10.8832 6.85579 10.6494C7.10774 10.4155 7.51624 10.4155 7.76819 10.6494L9.355 12.1221L9.355 6.43205C9.355 6.10135 9.64385 5.83325 10.0002 5.83325C10.3565 5.83325 10.6453 6.10135 10.6453 6.43205L10.6453 12.1221L12.2321 10.6494C12.4841 10.4155 12.8926 10.4155 13.1445 10.6494C13.3965 10.8832 13.3965 11.2623 13.1445 11.4962L10.4564 13.9912C10.3945 14.0486 10.3232 14.0919 10.2471 14.1211Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M10.0002 18.3333C12.2103 18.3333 14.3299 17.4553 15.8927 15.8925C17.4555 14.3297 18.3335 12.2101 18.3335 9.99992C18.3335 7.78978 17.4555 5.67016 15.8927 4.10736C14.3299 2.54456 12.2103 1.66659 10.0002 1.66659C7.79003 1.66659 5.67041 2.54456 4.10761 4.10736C2.5448 5.67016 1.66683 7.78978 1.66683 9.99992C1.66683 12.2101 2.5448 14.3297 4.10761 15.8925C5.67041 17.4553 7.79002 18.3333 10.0002 18.3333ZM15.0197 15.0195C13.6885 16.3508 11.8829 17.0987 10.0002 17.0987C8.11745 17.0987 6.31185 16.3508 4.98058 15.0195C3.6493 13.6882 2.9014 11.8826 2.9014 9.99992C2.9014 8.11721 3.6493 6.31161 4.98058 4.98033C6.31185 3.64906 8.11745 2.90115 10.0002 2.90115C11.8829 2.90115 13.6885 3.64906 15.0197 4.98033C16.351 6.31161 17.0989 8.11721 17.0989 9.99992C17.0989 11.8826 16.351 13.6882 15.0197 15.0195Z"
      fill={fill}
    />
  </svg>
);

Download.propTypes = {
  fill: PropTypes.string,
};

Download.defaultProps = {
  fill: '#FFFFFF',
};

export default Download;
