import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import type { RootState } from 'store';

const getFilters = (state: RootState) => state.filters.filters;
export const getTools = (state: RootState) => state.filters.tools;
const getMapActiveFilter = (state: RootState) => ({
  id: state.map.active_filter_id,
  type: state.map.active_filter_type,
});

export const getToolsChanges = (state: RootState) => {
  const {
    filters: { modified, duplicated, deleted, tools },
  } = state;

  return {
    modifiedTools: modified.map((feature) => {
      const tool = tools.find((tool) => tool.id === feature.newId);
      return { ...feature, attributes: tool };
    }),
    duplicatedTools: duplicated.map((feature) => {
      const tool = tools.find((tool) => tool.id === feature.newId);
      return { ...feature, attributes: tool };
    }),
    deletedTools: deleted,
  };
};

export const getActiveFilter = createSelector(
  [getFilters, getMapActiveFilter],
  (filters, { id, type }) =>
    filters.find((filter) => filter.id === id && filter.type === type) ||
    filters[0],
);

export const getToolsByType = createCachedSelector(
  getTools,
  (_: any, toolType: string) => toolType,
  (tools, toolType) => tools.filter((tool) => tool.tool_type === toolType),
)((_, toolType) => toolType);

export const getToolByCode = createCachedSelector(
  getTools,
  (_: any, toolCode: string) => toolCode,
  (tools, toolCode) => tools.find((tool) => tool.code === toolCode),
)((_, toolCode) => toolCode);

export const getLabelingTools = createSelector([getTools], (tools) =>
  tools.filter(
    (tool) =>
      tool.data &&
      (tool.data.details.some((detail: any) => detail.labeling) ||
        tool.data.labeling.enabled),
  ),
);
