import { NonConfigurableIconProps } from '../types';

const Database = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83264 3.53704C7.83955 3.66856 7.87753 3.79657 7.94348 3.91057C8.00943 4.02457 8.10147 4.12132 8.21205 4.19286C8.32262 4.26441 8.44858 4.30873 8.5796 4.32217C8.71061 4.33562 8.84295 4.31782 8.96575 4.27022C10.9309 3.48184 13.1244 3.48184 15.0895 4.27022C15.2109 4.30715 15.3391 4.31588 15.4644 4.29573C15.5896 4.27558 15.7086 4.22709 15.8123 4.15395C15.916 4.08082 16.0016 3.98497 16.0626 3.87373C16.1236 3.76248 16.1584 3.63878 16.1643 3.51204V3.45372C16.1593 3.29774 16.1105 3.1463 16.0236 3.01668C15.9367 2.88705 15.8151 2.78444 15.6727 2.72053C13.3001 1.75982 10.6468 1.75982 8.27422 2.72053C8.13993 2.79204 8.02778 2.89894 7.94994 3.02966C7.87209 3.16038 7.83153 3.30992 7.83264 3.46205V3.53704Z"
        fill="#1D1D1D"
      />
      <path
        d="M20.3301 12.002C20.3313 12.7367 20.2332 13.4682 20.0385 14.1766C20.0103 14.2867 20.005 14.4014 20.0228 14.5137C20.0406 14.6259 20.0812 14.7334 20.142 14.8294C20.2029 14.9254 20.2828 15.0079 20.3767 15.0719C20.4707 15.1358 20.5768 15.1799 20.6884 15.2014C20.8917 15.2356 21.1005 15.1932 21.2744 15.0824C21.4484 14.9716 21.575 14.8003 21.6299 14.6015C21.8665 13.755 21.9898 12.8809 21.9965 12.002C21.9972 11.1177 21.8766 10.2374 21.6382 9.38586C21.585 9.18555 21.4591 9.01224 21.285 8.89978C21.1109 8.78732 20.9012 8.74377 20.6967 8.77765C20.5851 8.79911 20.479 8.84318 20.3851 8.90715C20.2911 8.97113 20.2112 9.05365 20.1504 9.14964C20.0895 9.24564 20.0489 9.35307 20.0311 9.46534C20.0133 9.57761 20.0187 9.69233 20.0468 9.80245C20.2436 10.5188 20.3389 11.2592 20.3301 12.002Z"
        fill="#1D1D1D"
      />
      <path
        d="M3.66679 12.002C3.66507 11.2646 3.76316 10.5303 3.9584 9.81911C3.98796 9.70902 3.99462 9.59403 3.97796 9.48126C3.9613 9.36849 3.92168 9.26034 3.86154 9.1635C3.80141 9.06666 3.72204 8.98319 3.62835 8.91826C3.53466 8.85332 3.42865 8.8083 3.31686 8.78598C3.11352 8.75174 2.9047 8.79412 2.7308 8.90493C2.55689 9.01574 2.43026 9.18709 2.37538 9.38586C2.1313 10.2367 2.00515 11.1169 2.00046 12.002C2.00166 12.8802 2.11933 13.7543 2.35039 14.6015C2.40526 14.8003 2.5319 14.9716 2.7058 15.0824C2.87971 15.1932 3.08852 15.2356 3.29187 15.2014C3.40349 15.1799 3.50955 15.1358 3.6035 15.0719C3.69746 15.0079 3.77733 14.9254 3.83821 14.8294C3.89908 14.7334 3.93967 14.6259 3.95747 14.5137C3.97528 14.4014 3.96992 14.2867 3.94173 14.1766C3.75267 13.4674 3.66019 12.7359 3.66679 12.002Z"
        fill="#1D1D1D"
      />
      <path
        d="M11.9985 20.3337C10.9515 20.3343 9.91398 20.1364 8.94077 19.7505C8.8161 19.7069 8.68286 19.6935 8.55203 19.7115C8.4212 19.7296 8.29653 19.7784 8.1883 19.8541C8.08007 19.9298 7.99137 20.0301 7.92954 20.1468C7.86771 20.2635 7.8345 20.3932 7.83266 20.5253C7.83114 20.6903 7.87864 20.852 7.96911 20.9899C8.05959 21.1278 8.18898 21.2358 8.34089 21.3001C10.6872 22.2329 13.3014 22.2329 15.6478 21.3001C15.7983 21.2364 15.9268 21.1297 16.0172 20.9934C16.1076 20.8571 16.1559 20.6972 16.156 20.5336C16.1555 20.4009 16.1232 20.2702 16.0619 20.1524C16.0007 20.0346 15.9121 19.9332 15.8037 19.8566C15.6953 19.7799 15.5701 19.7303 15.4387 19.7119C15.3072 19.6934 15.1732 19.7066 15.0479 19.7505C14.0775 20.1363 13.0427 20.3342 11.9985 20.3337Z"
        fill="#1D1D1D"
      />
      <path
        d="M6.99947 7.2113V4.33688C6.99916 4.30254 6.99069 4.26876 6.97475 4.23834C6.95882 4.20792 6.93588 4.18172 6.90782 4.16191C6.87785 4.1478 6.84514 4.14049 6.81201 4.14049C6.77889 4.14049 6.74617 4.1478 6.7162 4.16191C6.00428 4.4092 5.25332 4.52495 4.49997 4.50351C3.74561 4.5196 2.99462 4.3982 2.28375 4.14525C2.25378 4.13114 2.22106 4.12382 2.18794 4.12382C2.15481 4.12382 2.1221 4.13114 2.09212 4.14525C2.06168 4.16663 2.03725 4.19549 2.0212 4.22906C2.00515 4.26262 1.99801 4.29975 2.00048 4.33688V7.2113C2.00048 7.66954 3.11692 8.04447 4.49997 8.04447C5.88303 8.04447 6.99947 7.66954 6.99947 7.2113Z"
        fill="#1D1D1D"
      />
      <path
        d="M11.9985 15.7513C14.0731 15.7513 15.7477 15.193 15.7477 14.5015V13.2351C15.7487 13.1984 15.7399 13.1622 15.7224 13.13C15.7048 13.0978 15.6791 13.0708 15.6477 13.0518C15.6171 13.035 15.5827 13.0262 15.5478 13.0262C15.5128 13.0262 15.4784 13.035 15.4478 13.0518C14.3536 13.4956 13.1787 13.7056 11.9985 13.6683C10.8177 13.703 9.64274 13.4901 8.54916 13.0435C8.51851 13.0267 8.48413 13.0179 8.44918 13.0179C8.41424 13.0179 8.37986 13.0267 8.3492 13.0435C8.31788 13.0625 8.29214 13.0895 8.27459 13.1217C8.25703 13.1539 8.24828 13.1901 8.24922 13.2268V14.5015C8.24922 15.193 9.92389 15.7513 11.9985 15.7513Z"
        fill="#1D1D1D"
      />
      <path
        d="M11.9985 12.8352C14.0731 12.8352 15.7477 12.277 15.7477 11.5854V10.3357C15.7487 10.299 15.7399 10.2628 15.7224 10.2306C15.7048 10.1984 15.6791 10.1714 15.6477 10.1524C15.6171 10.1356 15.5827 10.1268 15.5478 10.1268C15.5128 10.1268 15.4784 10.1356 15.4478 10.1524C14.3524 10.5905 13.1775 10.7949 11.9985 10.7523C10.8177 10.7869 9.64273 10.5741 8.54916 10.1274C8.5185 10.1106 8.48412 10.1018 8.44918 10.1018C8.41424 10.1018 8.37985 10.1106 8.3492 10.1274C8.31384 10.1484 8.28547 10.1793 8.26768 10.2164C8.24989 10.2535 8.24347 10.295 8.24922 10.3357V11.5854C8.24922 12.277 9.92388 12.8352 11.9985 12.8352Z"
        fill="#1D1D1D"
      />
      <path
        d="M11.9985 9.91909C14.0691 9.91909 15.7477 9.35956 15.7477 8.66934C15.7477 7.97912 14.0691 7.41959 11.9985 7.41959C9.9278 7.41959 8.2492 7.97912 8.2492 8.66934C8.2492 9.35956 9.9278 9.91909 11.9985 9.91909Z"
        fill="#1D1D1D"
      />
      <path
        d="M4.49996 3.67034C5.88039 3.67034 6.99945 3.29732 6.99945 2.83718C6.99945 2.37703 5.88039 2.00401 4.49996 2.00401C3.11952 2.00401 2.00046 2.37703 2.00046 2.83718C2.00046 3.29732 3.11952 3.67034 4.49996 3.67034Z"
        fill="#1D1D1D"
      />
      <path
        d="M4.49996 22C5.88301 22 6.99945 21.6251 6.99945 21.1668V18.2757C6.99914 18.2414 6.99067 18.2076 6.97473 18.1772C6.9588 18.1468 6.93586 18.1206 6.90781 18.1008C6.87784 18.0867 6.84512 18.0794 6.81199 18.0794C6.77887 18.0794 6.74615 18.0867 6.71618 18.1008C6.00426 18.3481 5.2533 18.4638 4.49996 18.4424C3.74559 18.4585 2.9946 18.3371 2.28373 18.0841C2.25376 18.07 2.22105 18.0627 2.18792 18.0627C2.15479 18.0627 2.12208 18.07 2.09211 18.0841C2.06405 18.1039 2.04112 18.1301 2.02518 18.1605C2.00925 18.191 2.00077 18.2247 2.00046 18.2591V21.1668C2.00046 21.6084 3.1169 22 4.49996 22Z"
        fill="#1D1D1D"
      />
      <path
        d="M4.49996 17.6092C5.88039 17.6092 6.99945 17.2362 6.99945 16.776C6.99945 16.3159 5.88039 15.9429 4.49996 15.9429C3.11952 15.9429 2.00046 16.3159 2.00046 16.776C2.00046 17.2362 3.11952 17.6092 4.49996 17.6092Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.4969 18.4424C18.7453 18.4584 17.9971 18.337 17.2891 18.0841C17.2577 18.0698 17.2236 18.0625 17.1891 18.0625C17.1546 18.0625 17.1205 18.0698 17.0891 18.0841C17.061 18.1039 17.0381 18.1301 17.0222 18.1605C17.0062 18.191 16.9978 18.2247 16.9974 18.2591V21.1668C16.9974 21.6251 18.1139 22 19.4969 22C20.88 22 21.9964 21.6251 21.9964 21.1668V18.2757C21.9961 18.2414 21.9877 18.2076 21.9717 18.1772C21.9558 18.1468 21.9328 18.1206 21.9048 18.1008C21.875 18.0861 21.8422 18.0784 21.809 18.0784C21.7758 18.0784 21.743 18.0861 21.7132 18.1008C21.0014 18.3486 20.2503 18.4644 19.4969 18.4424Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.4887 17.6092C20.8691 17.6092 21.9882 17.2362 21.9882 16.776C21.9882 16.3159 20.8691 15.9429 19.4887 15.9429C18.1082 15.9429 16.9892 16.3159 16.9892 16.776C16.9892 17.2362 18.1082 17.6092 19.4887 17.6092Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.497 4.50351C18.7426 4.52016 17.9915 4.39875 17.2807 4.14525C17.2509 4.13055 17.2182 4.12291 17.1849 4.12291C17.1517 4.12291 17.1189 4.13055 17.0891 4.14525C17.0587 4.16663 17.0342 4.1955 17.0182 4.22906C17.0021 4.26262 16.995 4.29975 16.9975 4.33688V7.2113C16.9975 7.66954 18.1139 8.04447 19.497 8.04447C20.88 8.04447 21.9965 7.66954 21.9965 7.2113V4.33688C21.9962 4.30254 21.9877 4.26876 21.9717 4.23834C21.9558 4.20792 21.9329 4.18172 21.9048 4.16191C21.8734 4.14764 21.8393 4.14026 21.8048 4.14026C21.7703 4.14026 21.7363 4.14764 21.7049 4.16191C20.996 4.40984 20.2476 4.52562 19.497 4.50351Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.472 3.67034C20.8524 3.67034 21.9715 3.29732 21.9715 2.83718C21.9715 2.37703 20.8524 2.00401 19.472 2.00401C18.0915 2.00401 16.9725 2.37703 16.9725 2.83718C16.9725 3.29732 18.0915 3.67034 19.472 3.67034Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Database;
