import React from 'react';
import { IconProps } from '../types';

const VisaCC = ({ width = 100, height = 67 }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 16.6 100.1 66.8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="#EBEDED"
          d="M100,76.7c0,3.7-3,6.7-6.7,6.7H6.7c-3.7,0-6.7-3-6.7-6.7V23.3c0-3.7,3-6.7,6.7-6.7h86.7 c3.7,0,6.7,3,6.7,6.7L100,76.7L100,76.7z"
        />
        <path
          fill="#0556A3"
          d="M93.3,16.7H6.6c-3.7,0-6.6,3-6.6,6.7v4.7h100v-4.7C100,19.7,97,16.7,93.3,16.7z"
        />
        <path
          fill="#F29C1F"
          d="M93.3,83.3H6.6c-3.7,0-6.6-3-6.6-6.7v-4.7h100v4.7C100,80.4,97,83.3,93.3,83.3z"
        />
        <g>
          <polyline
            fill="#2756A6"
            points="39.8,60.6 43.3,39 48.9,39 45.4,60.6 39.8,60.6"
          />
          <path
            fill="#2756A6"
            d="M65.6,39.6c-1.1-0.4-2.8-0.9-5-0.9c-5.5,0-9.4,2.9-9.4,7.1c0,3.1,2.8,4.8,4.9,5.9c2.2,1.1,2.9,1.7,2.9,2.7c0,1.4-1.7,2.1-3.3,2.1c-2.2,0-3.4-0.3-5.2-1.1l-0.7-0.3L49,59.9c1.3,0.6,3.7,1.1,6.2,1.1c5.8,0,9.6-2.9,9.7-7.4c0-2.5-1.5-4.3-4.7-5.9c-1.9-1-3.1-1.7-3.1-2.7c0-0.9,1-1.9,3.2-1.9c1.8,0,3.1,0.4,4.2,0.8l0.5,0.2L65.6,39.6"
          />
          <path
            fill="#2756A6"
            d="M79.8,39.1h-4.3c-1.3,0-2.3,0.4-2.9,1.8l-8.3,19.7h5.8c0,0,1-2.7,1.2-3.2c0.6,0,6.3,0,7.1,0c0.2,0.8,0.7,3.2,0.7,3.2h5.2L79.8,39.1 M73,52.9c0.5-1.2,2.2-6,2.2-6c0,0.1,0.5-1.2,0.7-2.1l0.4,1.9c0,0,1.1,5.1,1.3,6.2C76.7,52.9,74.1,52.9,73,52.9z"
          />
          <path
            fill="#2756A6"
            d="M35.2,39.1l-5.4,14.7l-0.6-3c-1-3.4-4.2-7.2-7.7-9l5,18.8h5.9l8.8-21.5H35.2"
          />
          <path
            fill="#F29C1F"
            d="M24.7,39h-9l-0.1,0.4c7,1.8,11.6,6.1,13.5,11.3l-1.9-9.9C26.8,39.5,25.9,39.1,24.7,39"
          />
        </g>
      </g>
    </svg>
  );
};

export default VisaCC;
