import { NonConfigurableIconProps } from '../types';

const Move = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7083 12.875C22.2083 12.375 22.2083 11.625 21.7083 11.125L19.2083 8.625C18.875 8.29167 18.2917 8.125 17.875 8.375C17.375 8.45833 17.0417 8.95833 17.0417 9.45833V10.4583C17.0417 10.5417 16.9583 10.625 16.875 10.625H13.5417C13.4583 10.625 13.375 10.5417 13.375 10.4583V7.125C13.375 7.04167 13.4583 6.95833 13.5417 6.95833H14.5417C15.0417 6.95833 15.5417 6.625 15.7083 6.20833C15.875 5.70833 15.7917 5.20833 15.4583 4.875L12.9583 2.375C12.4583 1.875 11.7083 1.875 11.2083 2.375L8.70833 4.875C8.29167 5.20833 8.20833 5.70833 8.375 6.20833C8.54167 6.625 9.04167 6.95833 9.54167 6.95833H10.5417C10.625 6.95833 10.7083 7.04167 10.7083 7.125V10.4583C10.7083 10.5417 10.625 10.625 10.5417 10.625H7.20833C7.125 10.625 7.04167 10.5417 7.04167 10.4583V9.45833C7.04167 8.95833 6.70833 8.45833 6.29167 8.29167C5.79167 8.125 5.29167 8.20833 4.875 8.54167L2.375 11.0417C1.875 11.5417 1.875 12.2917 2.375 12.7917L4.875 15.2917C5.125 15.5417 5.45833 15.625 5.79167 15.625C5.95833 15.625 6.125 15.625 6.29167 15.5417C6.70833 15.4583 7.04167 14.9583 7.04167 14.4583V13.4583C7.04167 13.375 7.125 13.2917 7.20833 13.2917H10.5417C10.625 13.2917 10.7083 13.375 10.7083 13.4583V16.7917C10.7083 16.875 10.625 16.9583 10.5417 16.9583H9.54167C9.04167 16.9583 8.54167 17.2917 8.375 17.7083C8.20833 18.2083 8.29167 18.7083 8.625 19.0417L11.125 21.5417C11.625 22.0417 12.375 22.0417 12.875 21.5417L15.375 19.0417C15.7083 18.7083 15.875 18.125 15.625 17.7083C15.5417 17.2917 15.0417 16.9583 14.5417 16.9583H13.5417C13.4583 16.9583 13.375 16.875 13.375 16.7917V13.4583C13.375 13.375 13.4583 13.2917 13.5417 13.2917H16.875C16.9583 13.2917 17.0417 13.375 17.0417 13.4583V14.4583C17.0417 14.9583 17.375 15.4583 17.7917 15.625C17.9583 15.7083 18.125 15.7083 18.2917 15.7083C18.625 15.7083 18.9583 15.5417 19.2083 15.375L21.7083 12.875Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Move;
