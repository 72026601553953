import actionTypes from 'actions/printHistory/actions';

const ACTION_HANDLERS = {
  [actionTypes.LOAD_PRINTS_START]: (state, action) => ({
    ...state,
    printHistoryLoaded: false,
  }),
  [actionTypes.LOAD_PRINTS_SUCCESS]: (state, action) => ({
    ...state,
    prints: action.data.prints,
    printHistoryLoaded: true,
    totalPrints: action.data.totalPrints,
  }),
  [actionTypes.LOAD_PRINTS_FAILURE]: (state, action) => ({
    ...state,
    prints: [],
    printHistoryLoaded: true,
  }),
};

const initialState = {
  printHistoryLoaded: false,
  prints: [],
  totalPrints: 0,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
