export default {
  ADD_SHARE_EMAIL: 'ADD_SHARE_EMAIL',
  REMOVE_SHARE_EMAIL: 'REMOVE_SHARE_EMAIL',
  REMOVE_SINGLE_SHARE_EMAIL: 'REMOVE_SINGLE_SHARE_EMAIL',
  TOGGLE_SELECTED_EMAIL: 'TOGGLE_SELECTED_EMAIL',
  SELECT_ALL_EMAILS: 'SELECT_ALL_EMAILS',
  DESELECT_ALL_EMAILS: 'DESELECT_ALL_EMAILS',
  FETCH_SHARE_EMAIL_SUCCESS: 'FETCH_SHARE_EMAIL_SUCCESS',
  MARK_EMAIL_SENT: 'MARK_EMAIL_SENT',
};
