import React, { useEffect, useRef, useState } from 'react';

import { UIIconProps } from 'components/ui/Icon/types';
import Icon from 'components/ui/Icon';
import Typography from 'components/ui/Typography';
import useOnClickOutside from 'hooks/useOnClickOutside';

import classNames from 'classnames';

import * as colors from 'components/ui/styles/colors.module.scss';
import * as styles from './styles.module.scss';

interface DropdownOption {
  label: string;
  value: string | number;
}

interface DropdownProps {
  label?: string;
  placeholder?: string;
  name?: string;
  leftIcon?: UIIconProps['id'];
  theme?: 'light' | 'dark';
  options: DropdownOption[];
  onSelect: (value: string | number) => void;
  value: string | undefined;
  dropdownMode?: 'floating' | 'inline';
}

const Dropdown = ({
  label = '',
  placeholder = '',
  name = '',
  leftIcon,
  theme = 'light',
  options = [],
  onSelect,
  value = undefined,
  dropdownMode = 'inline',
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref to detect outside clicks
  const selectedOptionRef = useRef<HTMLDivElement | null>(null); // Ref for scrolling to selected option
  const optionsContainerRef = useRef<HTMLDivElement | null>(null);

  const [selectValue, setSelectValue] = useState<string | undefined>(
    options.find((opt) => opt.value === value)?.label || undefined,
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const foundOption = options.find((opt) => opt.value === value);
    setSelectValue(foundOption ? foundOption.label : undefined);
  }, [value, options]);

  useEffect(() => {
    if (isOpen && selectedOptionRef.current && optionsContainerRef.current) {
      optionsContainerRef.current.scrollTo({
        top: selectedOptionRef.current.offsetTop - 50,
        behavior: 'smooth',
      });
    }
  }, [isOpen]);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useOnClickOutside(dropdownRef, closeDropdown);

  const handleOptionClick = (value: DropdownOption) => {
    setSelectValue(value.label);
    onSelect(value.value);
    setIsOpen(false);
  };

  const iconFillColors = {
    light: colors.black,
    dark: colors.white,
  };

  const fill = iconFillColors[theme] || colors.black;

  const leftIconProps =
    leftIcon ?
      ({ id: leftIcon, color: fill, height: 20, width: 20 } as UIIconProps)
    : null;

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.container, styles[theme], {
        [styles.containerFloating]: dropdownMode === 'floating',
      })}
      id={name}
    >
      {label && (
        <label className={classNames(styles[theme], styles.label)}>
          {label}
        </label>
      )}

      <div
        className={classNames(styles.wrapper, styles[theme])}
        onClick={() => setIsOpen(!isOpen)}
      >
        {leftIconProps && (
          <div className={styles.leftIcon}>
            <Icon {...leftIconProps} />
          </div>
        )}

        <div
          className={classNames(styles.input, styles[theme], {
            [styles.hasLeftIcon]: leftIcon,
          })}
        >
          <Typography
            type="ParagraphRegular"
            color={!selectValue ? 'grayScale100' : 'black'}
          >
            {selectValue || placeholder}
          </Typography>
        </div>

        <div className={styles.rightIcon}>
          <Icon
            id={isOpen ? 'chevronDown' : 'chevronRight'}
            color={fill}
            width={16}
            height={16}
          />
        </div>
      </div>

      {isOpen && (
        <div
          ref={optionsContainerRef}
          className={classNames(styles.options, styles[theme], {
            [styles.optionsFloating]: dropdownMode === 'floating',
          })}
        >
          {options.map((opt) => {
            const isSelected = opt.value === value;
            return (
              <div
                key={opt.value}
                ref={isSelected ? selectedOptionRef : null}
                className={classNames(styles.option, {
                  [styles.selectedOption]: isSelected,
                })}
                onClick={() => handleOptionClick(opt)}
              >
                <span
                  className={classNames(styles[theme], {
                    [styles.hasLeftIcon]: leftIcon,
                  })}
                >
                  <Typography
                    type={isSelected ? 'ParagraphSmallBold' : 'ParagraphSmall'}
                  >
                    {opt.label}
                  </Typography>
                </span>

                {isSelected && (
                  <Icon
                    width={'18'}
                    height={'18'}
                    id={'checkCircle'}
                    color={colors.links300}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export { DropdownProps };
export default Dropdown;
