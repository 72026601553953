import { ChevronProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';
import * as directionClasses from './ChevronBold.module.scss';

const ChevronBold = ({
  fill = colors.white,
  direction = 'bottom',
  dataTestid,
  width = '20',
  height = '20',
}: ChevronProps) => {
  const directionClass = directionClasses[direction];
  return (
    <svg
      className={direction && `${directionClass}`}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestid}
    >
      <path
        d="M10.0257 4.83261C10.4622 4.83261 10.8364 5.01969 11.0858 5.33149L17.2595 12.2535C17.6336 12.7523 17.5713 13.5007 17.0724 13.8748C16.5735 14.249 15.8875 14.249 15.5134 13.7501L10.1504 7.63882C10.0881 7.57646 10.0257 7.57646 9.90097 7.63882L4.538 13.7501C4.10148 14.249 3.41552 14.3114 2.91664 13.8748C2.41776 13.4383 2.3554 12.7524 2.79192 12.2535C2.79192 12.2535 2.79192 12.2535 2.85428 12.1911L8.96557 5.26913C9.21501 5.01969 9.65153 4.83261 10.0257 4.83261Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChevronBold;
