import React from 'react';
import PropTypes from 'prop-types';

import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const Expand = ({ fill }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M14.6657 1.88887C14.6657 1.74153 14.6072 1.60022 14.503 1.49603C14.3988 1.39184 14.2575 1.33331 14.1101 1.33331H10.499C10.3893 1.33386 10.2823 1.36686 10.1913 1.42816C10.1004 1.48945 10.0296 1.5763 9.98791 1.67776C9.94537 1.77893 9.93374 1.89044 9.95451 1.99821C9.97527 2.10598 10.0275 2.20519 10.1046 2.28331L11.3324 3.49998C11.3456 3.51245 11.3562 3.52751 11.3635 3.54423C11.3707 3.56095 11.3744 3.57898 11.3744 3.5972C11.3744 3.61542 11.3707 3.63345 11.3635 3.65017C11.3562 3.66689 11.3456 3.68195 11.3324 3.69442L8.89902 6.1222C8.83452 6.18623 8.78333 6.2624 8.74839 6.34631C8.71346 6.43021 8.69548 6.5202 8.69548 6.61109C8.69548 6.70198 8.71346 6.79197 8.74839 6.87588C8.78333 6.95978 8.83452 7.03595 8.89902 7.09998C8.96267 7.16516 9.03871 7.21695 9.12267 7.25231C9.20663 7.28767 9.29681 7.30589 9.38791 7.30589C9.47901 7.30589 9.5692 7.28767 9.65315 7.25231C9.73711 7.21695 9.81315 7.16516 9.8768 7.09998L12.3046 4.66665C12.317 4.65336 12.3321 4.64277 12.3488 4.63553C12.3656 4.62829 12.3836 4.62456 12.4018 4.62456C12.42 4.62456 12.4381 4.62829 12.4548 4.63553C12.4715 4.64277 12.4866 4.65336 12.499 4.66665L13.7157 5.88331C13.7667 5.93681 13.8278 5.9796 13.8956 6.00917C13.9633 6.03875 14.0362 6.05451 14.1101 6.05554C14.2575 6.05554 14.3988 5.997 14.503 5.89282C14.6072 5.78863 14.6657 5.64732 14.6657 5.49998V1.88887Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M1.88905 14.6664H5.50016C5.60984 14.6659 5.71691 14.6329 5.80786 14.5716C5.89882 14.5103 5.9696 14.4234 6.01127 14.322C6.05382 14.2208 6.06544 14.1093 6.04468 14.0015C6.02392 13.8937 5.97169 13.7945 5.89461 13.7164L4.66683 12.4998C4.65354 12.4873 4.64295 12.4722 4.63571 12.4555C4.62848 12.4388 4.62474 12.4207 4.62474 12.4025C4.62474 12.3843 4.62848 12.3663 4.63571 12.3496C4.64295 12.3328 4.65354 12.3178 4.66683 12.3053L7.08905 9.87753C7.21871 9.74787 7.29156 9.57201 7.29156 9.38864C7.29156 9.20527 7.21871 9.02941 7.08905 8.89975C6.95939 8.77009 6.78353 8.69725 6.60016 8.69725C6.41679 8.69725 6.24094 8.77009 6.11127 8.89975L3.69461 11.3331C3.68214 11.3464 3.66708 11.357 3.65036 11.3642C3.63363 11.3714 3.61561 11.3752 3.59738 11.3752C3.57916 11.3752 3.56114 11.3714 3.54441 11.3642C3.52769 11.357 3.51263 11.3464 3.50016 11.3331L2.2835 10.1053C2.20537 10.0282 2.10616 9.976 1.99839 9.95523C1.89062 9.93447 1.77911 9.94609 1.67794 9.98864C1.57649 10.0303 1.48964 10.1011 1.42834 10.192C1.36704 10.283 1.33404 10.3901 1.3335 10.4998V14.1109C1.3335 14.2582 1.39203 14.3995 1.49621 14.5037C1.6004 14.6079 1.74171 14.6664 1.88905 14.6664Z"
      fill={fill}
    />
  </svg>
);

Expand.propTypes = {
  fill: PropTypes.string,
};

Expand.defaultProps = {
  fill: colorPalette.blue,
};

export default Expand;
