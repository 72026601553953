/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Document = ({ fill, variant, width, height }) => {
  const filled = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.917 7H20.167C20.2083 6.99943 20.2485 6.98661 20.2824 6.96315C20.3164 6.9397 20.3427 6.90668 20.3578 6.86829C20.373 6.82989 20.3765 6.78785 20.3677 6.7475C20.3589 6.70716 20.3384 6.67032 20.3087 6.64167L16.0253 2.35C15.9948 2.32351 15.9575 2.30607 15.9176 2.29965C15.8777 2.29324 15.8368 2.29811 15.7996 2.31371C15.7623 2.32931 15.7301 2.35502 15.7067 2.38794C15.6833 2.42086 15.6695 2.45968 15.667 2.5V5.75C15.667 6.08153 15.7987 6.39947 16.0331 6.63389C16.2675 6.86831 16.5855 7 16.917 7Z"
        fill={fill}
      />
      <path
        d="M20.4583 7.83333H16.9167C16.3641 7.83333 15.8342 7.61384 15.4435 7.22314C15.0528 6.83244 14.8333 6.30253 14.8333 5.75V2.20833C14.8333 2.15308 14.8114 2.10009 14.7723 2.06102C14.7332 2.02195 14.6803 2 14.625 2H5.66667C5.22464 2 4.80072 2.17559 4.48816 2.48816C4.17559 2.80072 4 3.22464 4 3.66667V20.3333C4 20.7754 4.17559 21.1993 4.48816 21.5118C4.80072 21.8244 5.22464 22 5.66667 22H19C19.442 22 19.8659 21.8244 20.1785 21.5118C20.4911 21.1993 20.6667 20.7754 20.6667 20.3333V8.04167C20.6667 7.98641 20.6447 7.93342 20.6056 7.89435C20.5666 7.85528 20.5136 7.83333 20.4583 7.83333Z"
        fill={fill}
      />
    </svg>
  );

  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7723 2.06102C14.7332 2.02195 14.6803 2 14.625 2H5.66667C5.22464 2 4.80072 2.17559 4.48816 2.48816C4.17559 2.80072 4 3.22464 4 3.66667V20.3333C4 20.7754 4.17559 21.1993 4.48816 21.5118C4.80072 21.8244 5.22464 22 5.66667 22H19C19.442 22 19.8659 21.8244 20.1785 21.5118C20.4911 21.1993 20.6667 20.7754 20.6667 20.3333V8.04167C20.6667 7.98641 20.6447 7.93342 20.6056 7.89435L14.7723 2.06102ZM14 3.5H5.66667C5.62246 3.5 5.58007 3.51756 5.54882 3.54882C5.51756 3.58007 5.5 3.62246 5.5 3.66667V20.3333C5.5 20.3775 5.51756 20.4199 5.54882 20.4512C5.58007 20.4824 5.62246 20.5 5.66667 20.5H19C19.0442 20.5 19.0866 20.4824 19.1179 20.4512C19.1491 20.4199 19.1667 20.3775 19.1667 20.3333V9H15.5939C15.1712 9 14.7658 8.83199 14.4668 8.53293C14.1679 8.23386 14 7.82825 14 7.40531V3.5Z"
        fill={fill}
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

Document.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['outlined', 'filled']),
};

Document.defaultProps = {
  fill: '#4A4A4A',
  width: '24',
  height: '24',
};

export default Document;
