import actionTypes from 'actions/cutTool/actions';
import settingsActionTypes from 'actions/settings/actions';

const ACTION_HANDLERS = {
  [actionTypes.START_CUT]: (state, action) => ({
    status: 'ENABLED',
    element: true,
    cutHistory: [],
  }),
  [actionTypes.FINISH_CUT]: (state, action) => ({
    status: 'DISABLED',
    element: false,
    cutHistory: [],
  }),
  [actionTypes.ADD_CUT_HISTORY]: (state, action) => ({
    ...state,
    cutHistory: [...state.cutHistory, action.cutHistory],
  }),
  [actionTypes.UNDO_CUT_HISTORY]: (state, action) => {
    state.cutHistory.pop();

    return {
      ...state,
    };
  },
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

const initialState = {
  status: 'DISABLED',
  element: false,
  cutHistory: [],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
