import { api } from 'api/endpoints';
import { ApiOverlayLayers } from './types';

export const overlaysEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchOverlays: builder.query<
      ApiOverlayLayers,
      { mapId?: number; creatorId?: number; stateAbbr?: string }
    >({
      query: ({ mapId, creatorId, stateAbbr }) => {
        if (!mapId) {
          return {
            url: '/mapright_layers.json',
            withAuth: true,
          };
        }

        const params = new URLSearchParams();

        if (creatorId) {
          params.append('user_id', creatorId.toString());
        }
        if (mapId) {
          params.append('mapId', mapId.toString());
        }
        if (stateAbbr) {
          params.append('state', stateAbbr);
        }

        return {
          url: `mapright_layers/share.json?${params.toString()}`,
          method: 'GET',
        };
      },
      serializeQueryArgs: () => 'fetchOverlays',
    }),
  }),
});

export const { useFetchOverlaysQuery } = overlaysEndpoints;
