import { IconPropsWithDirection } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const getRotation = (
  direction: 'left' | 'top' | 'right' | 'bottom' = 'left',
): number => {
  const rotation = {
    left: 0,
    top: 90,
    right: 180,
    bottom: 270,
  };
  return rotation[direction] || 0;
};

const Arrow = ({
  fill = colors.black,
  direction = 'left',
}: IconPropsWithDirection) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    transform={`rotate(${getRotation(direction)})`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.96967 17.8633C9.26256 18.1562 9.73744 18.1562 10.0303 17.8633C10.3232 17.5704 10.3232 17.0956 10.0303 16.8027L4.31054 11.0829L17.5 11.0829C17.9142 11.0829 18.25 10.7471 18.25 10.3329C18.25 9.91867 17.9142 9.58288 17.5 9.58288L4.31078 9.58289L10.0303 3.86334C10.3232 3.57044 10.3232 3.09557 10.0303 2.80268C9.73744 2.50978 9.26256 2.50978 8.96967 2.80268L1.97759 9.79476C1.84089 9.92749 1.7546 10.1118 1.75018 10.3164C1.75006 10.3219 1.75 10.3275 1.75 10.333C1.75 10.4837 1.79532 10.6296 1.87841 10.7527C1.90504 10.7921 1.93554 10.8292 1.96967 10.8633L8.96967 17.8633Z"
      fill={fill}
    />
  </svg>
);

export default Arrow;
