import React, { useState, useEffect, useMemo } from 'react';
import CustomModal from 'components/CustomModal';
import Typography from 'components/ui/Typography';
import Input from 'components/ui/Inputs/Input';
import {
  FeatureFlagOverrides,
  FeatureFlags,
  FlagValue,
} from 'services/FeatureFlags/types';

import * as styles from './styles.module.scss';

interface EditPayloadModalProps {
  currentFeatureFlag: string;
  featureFlagsOverrides: FeatureFlagOverrides;
  remoteFeatureFlags: FeatureFlags;
  setOverrideFeatureFlag: (
    featureFlag: string,
    value: FlagValue | null,
    payload?: any,
  ) => void;
  onClose: () => void;
}

const validatePayload = (value: string): boolean => {
  try {
    JSON.parse(value);
    return true;
  } catch {
    return false;
  }
};

const EditPayloadModal: React.FC<EditPayloadModalProps> = ({
  currentFeatureFlag,
  featureFlagsOverrides,
  remoteFeatureFlags,
  setOverrideFeatureFlag,
  onClose,
}) => {
  const [payloadValue, setPayloadValue] = useState<string>('');

  useEffect(() => {
    const payload = featureFlagsOverrides[currentFeatureFlag]?.payload;
    if (payload) setPayloadValue(JSON.stringify(payload));
  }, [currentFeatureFlag, featureFlagsOverrides]);

  const isPayloadValid = useMemo(
    () => validatePayload(payloadValue),
    [payloadValue],
  );

  const handlePayloadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPayloadValue(value);

    if (validatePayload(value)) {
      const parsedValue = value === '' ? null : JSON.parse(value);
      setOverrideFeatureFlag(
        currentFeatureFlag,
        featureFlagsOverrides[currentFeatureFlag]?.value,
        parsedValue,
      );
    }
  };

  return (
    <CustomModal
      onClose={onClose}
      shouldCloseOnEsc
      positionModifier="center-full"
      className={styles.modal}
    >
      <CustomModal.Title title="Edit Payload" onClose={onClose} />
      <div className={styles.container}>
        <div className={styles.payloadItem}>
          <Typography type="Header5b">Current Payload Value</Typography>
          <Typography type="ParagraphRegular">
            {JSON.stringify(remoteFeatureFlags[currentFeatureFlag].payload)}
          </Typography>
        </div>
        <div className={styles.payloadItem}>
          <Typography type="Header5b">New Payload Value</Typography>
          <Input
            type="text"
            value={payloadValue}
            onChange={handlePayloadChange}
            name="text"
            placeholder="Enter new payload value"
            theme="light"
            errors={
              !isPayloadValid ? { text: 'Please input valid JSON' } : undefined
            }
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default EditPayloadModal;
