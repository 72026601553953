/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const AccountUser = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33632 2.97804C8.04259 2.27178 9.00049 1.875 9.9993 1.875C10.9981 1.875 11.956 2.27178 12.6623 2.97804C13.3686 3.68431 13.7653 4.64221 13.7653 5.64103C13.7653 6.63984 13.3686 7.59774 12.6623 8.30401C11.956 9.01028 10.9981 9.40705 9.9993 9.40705C9.00049 9.40705 8.04259 9.01028 7.33632 8.30401C6.63005 7.59774 6.23328 6.63984 6.23328 5.64103C6.23328 4.64221 6.63005 3.68431 7.33632 2.97804ZM9.9993 3.125C9.33201 3.125 8.69205 3.39008 8.2202 3.86193C7.74836 4.33377 7.48328 4.97373 7.48328 5.64103C7.48328 6.30832 7.74836 6.94828 8.2202 7.42013C8.69205 7.89197 9.33201 8.15705 9.9993 8.15705C10.6666 8.15705 11.3066 7.89197 11.7784 7.42013C12.2502 6.94828 12.5153 6.30832 12.5153 5.64103C12.5153 4.97373 12.2502 4.33377 11.7784 3.86193C11.3066 3.39008 10.6666 3.125 9.9993 3.125Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99935 11.7147C8.61801 11.7147 7.29325 12.2634 6.3165 13.2402C5.33975 14.2169 4.79102 15.5417 4.79102 16.923C4.79102 17.2682 4.51119 17.548 4.16602 17.548C3.82084 17.548 3.54102 17.2682 3.54102 16.923C3.54102 15.2102 4.22145 13.5675 5.43262 12.3563C6.64379 11.1451 8.28649 10.4647 9.99935 10.4647C11.7122 10.4647 13.3549 11.1451 14.5661 12.3563C15.7773 13.5675 16.4577 15.2102 16.4577 16.923C16.4577 17.2682 16.1779 17.548 15.8327 17.548C15.4875 17.548 15.2077 17.2682 15.2077 16.923C15.2077 15.5417 14.6589 14.2169 13.6822 13.2402C12.7054 12.2634 11.3807 11.7147 9.99935 11.7147Z"
      fill={fill}
    />
  </svg>
);

AccountUser.propTypes = {
  fill: PropTypes.string,
};

AccountUser.defaultProps = {
  fill: colorPalette.grayMedium,
};

export default AccountUser;
