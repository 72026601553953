import React from 'react';

import MapRightLogo from 'static/images/logo.png';

import './styles.scss';
import { Helmet } from 'react-helmet';

const PageNotFound = () => (
  <div className="page-not-found">
    <Helmet title="Land id™ - 404 Page" />
    <img className="main-header__logo" src={MapRightLogo} />
    <h1>404 Page Not Found</h1>
  </div>
);

export default PageNotFound;
