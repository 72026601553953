import { NonConfigurableIconProps } from '../types';

const Rotate = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3333 20.3367C10.5652 20.3367 8.86953 19.6344 7.61929 18.3841C6.36905 17.1339 5.66667 15.4382 5.66667 13.6701C5.66667 11.902 6.36905 10.2063 7.61929 8.95603C8.86953 7.70579 10.5652 7.00341 12.3333 7.00341C12.373 7.00331 12.4117 7.01499 12.4447 7.03696C12.4777 7.05893 12.5034 7.0902 12.5186 7.12681C12.5338 7.16341 12.5377 7.2037 12.53 7.24256C12.5222 7.28142 12.5031 7.3171 12.475 7.34508L10.9083 8.91174C10.8302 8.98921 10.7682 9.08138 10.7259 9.18293C10.6836 9.28448 10.6618 9.3934 10.6618 9.50341C10.6618 9.61342 10.6836 9.72234 10.7259 9.82389C10.7682 9.92544 10.8302 10.0176 10.9083 10.0951C10.9862 10.1723 11.0785 10.2334 11.1801 10.2749C11.2816 10.3164 11.3903 10.3374 11.5 10.3367C11.6097 10.3374 11.7184 10.3164 11.8199 10.2749C11.9215 10.2334 12.0138 10.1723 12.0917 10.0951L15.425 6.76174C15.5031 6.68427 15.5651 6.59211 15.6074 6.49056C15.6497 6.38901 15.6715 6.28009 15.6715 6.17008C15.6715 6.06007 15.6497 5.95115 15.6074 5.8496C15.5651 5.74805 15.5031 5.65588 15.425 5.57841L12.0917 2.24508C12.014 2.16738 11.9217 2.10574 11.8202 2.06369C11.7187 2.02164 11.6099 2 11.5 2C11.3901 2 11.2813 2.02164 11.1798 2.06369C11.0783 2.10574 10.986 2.16738 10.9083 2.24508C10.8306 2.32278 10.769 2.41502 10.7269 2.51654C10.6849 2.61805 10.6633 2.72686 10.6633 2.83674C10.6633 2.94663 10.6849 3.05543 10.7269 3.15695C10.769 3.25847 10.8306 3.35071 10.9083 3.42841L12.475 4.99508C12.5031 5.02305 12.5222 5.05873 12.53 5.09759C12.5377 5.13645 12.5338 5.17674 12.5186 5.21335C12.5034 5.24995 12.4777 5.28122 12.4447 5.30319C12.4117 5.32516 12.373 5.33684 12.3333 5.33674C10.1232 5.33674 8.00358 6.21472 6.44078 7.77752C4.87797 9.34032 4 11.4599 4 13.6701C4 15.8802 4.87797 17.9998 6.44078 19.5626C8.00358 21.1254 10.1232 22.0034 12.3333 22.0034C12.5543 22.0034 12.7663 21.9156 12.9226 21.7593C13.0789 21.6031 13.1667 21.3911 13.1667 21.1701C13.1667 20.9491 13.0789 20.7371 12.9226 20.5808C12.7663 20.4245 12.5543 20.3367 12.3333 20.3367Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.3515 18.9952C15.9054 19.3287 15.4215 19.6085 14.9099 19.8286C14.7066 19.9149 14.546 20.0784 14.4632 20.283C14.3804 20.4877 14.3822 20.7169 14.4682 20.9202C14.5102 21.0216 14.5717 21.1137 14.6493 21.1912C14.727 21.2687 14.8191 21.3301 14.9206 21.372C15.022 21.4138 15.1307 21.4351 15.2404 21.4348C15.3501 21.4345 15.4587 21.4126 15.5599 21.3702C16.1977 21.1015 16.7995 20.7544 17.3515 20.3369C17.4391 20.2713 17.5128 20.189 17.5686 20.0948C17.6243 20.0007 17.661 19.8964 17.6765 19.7881C17.692 19.6798 17.6859 19.5694 17.6588 19.4634C17.6316 19.3574 17.5839 19.2578 17.5182 19.1702C17.4525 19.0827 17.3703 19.0089 17.2761 18.9532C17.1819 18.8974 17.0777 18.8608 16.9694 18.8453C16.861 18.8298 16.7507 18.8358 16.6447 18.863C16.5387 18.8901 16.4391 18.9379 16.3515 19.0036V18.9952Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.857 14.2786C19.6404 14.237 19.4162 14.283 19.2335 14.4064C19.0507 14.5298 18.9243 14.7206 18.882 14.937C18.7676 15.5151 18.577 16.0756 18.3153 16.6036C18.2181 16.8025 18.2038 17.0319 18.2757 17.2414C18.3476 17.4508 18.4997 17.6231 18.6986 17.7203C18.8975 17.8175 19.1269 17.8318 19.3364 17.7599C19.5458 17.688 19.7181 17.5359 19.8153 17.337C20.1388 16.6761 20.3741 15.9757 20.5153 15.2536C20.5569 15.0371 20.511 14.8129 20.3876 14.6301C20.2642 14.4474 20.0734 14.321 19.857 14.2786Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.8412 12.8954C20.0565 12.8476 20.2441 12.7164 20.3628 12.5305C20.4815 12.3446 20.5217 12.1192 20.4746 11.9038C20.3151 11.1541 20.0515 10.4304 19.6912 9.75378C19.6445 9.64955 19.5767 9.55606 19.4923 9.4791C19.4078 9.40215 19.3085 9.34339 19.2004 9.30646C19.0922 9.26953 18.9777 9.25522 18.8638 9.26443C18.7499 9.27364 18.6392 9.30617 18.5384 9.35999C18.4376 9.41381 18.349 9.48778 18.278 9.5773C18.207 9.66683 18.1552 9.76999 18.1258 9.88038C18.0964 9.99077 18.0899 10.106 18.1069 10.219C18.1239 10.332 18.164 10.4403 18.2246 10.5371C18.5076 11.0793 18.7178 11.6565 18.8496 12.2538C18.8723 12.3611 18.9161 12.463 18.9783 12.5534C19.0405 12.6438 19.1199 12.721 19.2121 12.7806C19.3042 12.8403 19.4072 12.8811 19.5152 12.9008C19.6232 12.9205 19.734 12.9187 19.8412 12.8954Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Rotate;
