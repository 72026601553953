import { StyleableOnlyIconProps } from '../types';

const NavigationMenuEllipsesVertical = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4.33333C10 4.95217 10.2458 5.54566 10.6834 5.98325C11.121 6.42083 11.7145 6.66667 12.3333 6.66667C12.9522 6.66667 13.5457 6.42083 13.9832 5.98325C14.4208 5.54566 14.6667 4.95217 14.6667 4.33333C14.6667 3.71449 14.4208 3.121 13.9832 2.68342C13.5457 2.24583 12.9522 2 12.3333 2C11.7145 2 11.121 2.24583 10.6834 2.68342C10.2458 3.121 10 3.71449 10 4.33333ZM10 19.6666C10 20.2854 10.2458 20.8789 10.6834 21.3165C11.121 21.7541 11.7145 21.9999 12.3333 21.9999C12.9522 21.9999 13.5457 21.7541 13.9832 21.3165C14.4208 20.8789 14.6667 20.2854 14.6667 19.6666C14.6667 19.0477 14.4208 18.4543 13.9832 18.0167C13.5457 17.5791 12.9522 17.3333 12.3333 17.3333C11.7145 17.3333 11.121 17.5791 10.6834 18.0167C10.2458 18.4543 10 19.0477 10 19.6666ZM10.6834 13.65C10.2458 13.2124 10 12.6189 10 12.0001C10 11.3812 10.2458 10.7878 10.6834 10.3502C11.121 9.91258 11.7145 9.66675 12.3333 9.66675C12.9522 9.66675 13.5457 9.91258 13.9832 10.3502C14.4208 10.7878 14.6667 11.3812 14.6667 12.0001C14.6667 12.6189 14.4208 13.2124 13.9832 13.65C13.5457 14.0876 12.9522 14.3334 12.3333 14.3334C11.7145 14.3334 11.121 14.0876 10.6834 13.65Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7083 7.41667C13.2041 7.41667 14.4167 6.2041 14.4167 4.70833C14.4167 3.21256 13.2041 2 11.7083 2C10.2126 2 9 3.21256 9 4.70833C9 6.2041 10.2126 7.41667 11.7083 7.41667ZM11.7083 14.7083C13.2041 14.7083 14.4167 13.4957 14.4167 12C14.4167 10.5042 13.2041 9.29163 11.7083 9.29163C10.2126 9.29163 9 10.5042 9 12C9 13.4957 10.2126 14.7083 11.7083 14.7083ZM14.4167 19.2917C14.4167 20.7875 13.2041 22 11.7083 22C10.2126 22 9 20.7875 9 19.2917C9 17.7959 10.2126 16.5834 11.7083 16.5834C13.2041 16.5834 14.4167 17.7959 14.4167 19.2917Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default NavigationMenuEllipsesVertical;
