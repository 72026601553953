import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import persistState from 'redux-localstorage';
import createDebounce from 'redux-debounced';
import reducers from 'store/reducers';
import api from 'api/endpoints';
import resetApiOnLogoutMiddleware from './middlewares/logoutMiddleware';

const debounce = createDebounce();
const customMiddleware = [debounce, api.middleware, resetApiOnLogoutMiddleware];

const getParameterByName = (name: string) => {
  const safeName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${safeName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(window.location.href);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

if (
  process.env.ENVIRONMENT !== 'production' ||
  getParameterByName('debug') === 'true'
) {
  const logger = createLogger({ collapsed: true, diff: true });
  customMiddleware.push(logger);
}

const rootReducer = combineReducers(reducers);

export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: process.env.ENVIRONMENT === 'development',
        serializableCheck: {
          // we know that we have non-serializable data regarding modals, but the benefits
          // of having it are much more than the cost of not using redux for this
          ignoredActions: ['modals/showModal', 'modals/pushModal'],
          ignoredPaths: ['modals'],
        },
      }).concat(...customMiddleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({}).concat([persistState('user')]),
    devTools: process.env.ENVIRONMENT !== 'production',
    preloadedState,
  });
};

const store = setupStore();

export default store;
