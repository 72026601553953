import { NonConfigurableIconProps } from '../types';

const Regional = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9788 6.40054C15.0136 6.40176 15.048 6.39366 15.0788 6.37705C15.1096 6.36044 15.1357 6.33588 15.1546 6.30578C15.1707 6.27539 15.1791 6.24133 15.1791 6.20671C15.1791 6.17209 15.1707 6.13803 15.1546 6.10764C14.5743 4.45469 13.5064 3.02996 12.1004 2.03296C12.068 2.01144 12.0302 2 11.9916 2C11.9531 2 11.9153 2.01144 11.8829 2.03296C10.4676 3.02598 9.39057 4.45114 8.80361 6.10764C8.7875 6.13803 8.77906 6.17209 8.77906 6.20671C8.77906 6.24133 8.7875 6.27539 8.80361 6.30578C8.82243 6.33588 8.84851 6.36044 8.87931 6.37705C8.91011 6.39366 8.94458 6.40176 8.97933 6.40054H14.9788Z"
        fill="#1D1D1D"
      />
      <path
        d="M17.397 11.1385C17.4011 11.1935 17.4247 11.2449 17.4633 11.283C17.5019 11.3212 17.5528 11.3433 17.6062 11.3453H21.79C21.8497 11.3449 21.9069 11.3201 21.949 11.2764C21.9674 11.2538 21.9813 11.2278 21.9899 11.1997C21.9985 11.1716 22.0017 11.142 21.9992 11.1127C21.9035 9.97211 21.6206 8.85649 21.1624 7.81333C21.1449 7.77641 21.1174 7.74544 21.0833 7.72407C21.0492 7.7027 21.0099 7.69182 20.97 7.69272H17.2381C17.2077 7.69299 17.1777 7.70006 17.1503 7.71345C17.1228 7.72684 17.0985 7.74622 17.0791 7.77025C17.0651 7.79824 17.0579 7.82925 17.0579 7.86071C17.0579 7.89216 17.0651 7.92317 17.0791 7.95116C17.2717 9.00256 17.3781 10.0688 17.397 11.1385Z"
        fill="#1D1D1D"
      />
      <path
        d="M15.9411 11.3539C15.9979 11.3513 16.0517 11.3267 16.0917 11.285C16.1153 11.2685 16.1351 11.2468 16.1496 11.2215C16.164 11.1961 16.1729 11.1678 16.1754 11.1385C16.1373 10.0362 15.9972 8.94003 15.757 7.86501C15.7449 7.81018 15.7133 7.76203 15.6683 7.73011C15.6234 7.69819 15.5684 7.68485 15.5144 7.69272H8.46891C8.42148 7.6928 8.37537 7.70883 8.33761 7.73838C8.29984 7.76792 8.27249 7.80937 8.25972 7.8564C8.00775 8.9331 7.85645 10.0322 7.80788 11.1385C7.80505 11.1666 7.80807 11.1949 7.81673 11.2217C7.82538 11.2484 7.83948 11.2729 7.85808 11.2936C7.89807 11.3353 7.95186 11.3599 8.0087 11.3625L15.9411 11.3539Z"
        fill="#1D1D1D"
      />
      <path
        d="M9.00443 17.5995C8.96968 17.5982 8.93522 17.6063 8.90442 17.6229C8.87361 17.6396 8.84753 17.6641 8.82872 17.6942C8.8126 17.7246 8.80416 17.7587 8.80416 17.7933C8.80416 17.8279 8.8126 17.862 8.82872 17.8924C9.41567 19.5489 10.4927 20.974 11.908 21.967C11.9404 21.9886 11.9782 22 12.0167 22C12.0553 22 12.0931 21.9886 12.1255 21.967C13.5408 20.974 14.6178 19.5489 15.2048 17.8924C15.2209 17.862 15.2293 17.8279 15.2293 17.7933C15.2293 17.7587 15.2209 17.7246 15.2048 17.6942C15.1873 17.6648 15.1627 17.6406 15.1333 17.624C15.104 17.6073 15.0709 17.5989 15.0374 17.5995H9.00443Z"
        fill="#1D1D1D"
      />
      <path
        d="M8.05054 12.6461C7.99077 12.6465 7.93361 12.6713 7.89155 12.715C7.86793 12.7315 7.84817 12.7532 7.83369 12.7785C7.81922 12.8039 7.8104 12.8322 7.80788 12.8615C7.84599 13.9638 7.98608 15.06 8.22625 16.135C8.23902 16.182 8.26637 16.2235 8.30414 16.253C8.3419 16.2826 8.38801 16.2986 8.43544 16.2987H15.4809C15.5283 16.2986 15.5744 16.2826 15.6122 16.253C15.65 16.2235 15.6773 16.182 15.6901 16.135C15.9528 15.0622 16.1153 13.9661 16.1754 12.8615C16.1782 12.8334 16.1752 12.8051 16.1665 12.7783C16.1579 12.7516 16.1438 12.7271 16.1252 12.7064C16.0852 12.6647 16.0314 12.6401 15.9746 12.6375L8.05054 12.6461Z"
        fill="#1D1D1D"
      />
      <path
        d="M2.00083 11.1385C1.99833 11.1679 2.00148 11.1974 2.0101 11.2255C2.01871 11.2536 2.03262 11.2797 2.05103 11.3022C2.09309 11.3459 2.15025 11.3707 2.21002 11.3711H6.39377C6.44849 11.3647 6.49878 11.337 6.5345 11.2939C6.57022 11.2507 6.5887 11.1952 6.58622 11.1385C6.62725 10.0668 6.756 9.00059 6.97113 7.95116C6.98508 7.92317 6.99235 7.89216 6.99235 7.86071C6.99235 7.82925 6.98508 7.79824 6.97113 7.77025C6.9456 7.73521 6.9103 7.70901 6.86993 7.69516C6.82956 7.68131 6.78605 7.68046 6.74521 7.69272H3.0133C2.96702 7.68509 2.9196 7.69368 2.87864 7.71712C2.83767 7.74055 2.80553 7.77746 2.78738 7.82194C2.34468 8.87365 2.07872 9.99514 2.00083 11.1385Z"
        fill="#1D1D1D"
      />
      <path
        d="M6.58622 12.8615C6.58214 12.8065 6.55855 12.7551 6.51997 12.717C6.48138 12.6788 6.43049 12.6567 6.37704 12.6547H2.19328C2.13352 12.6551 2.07635 12.6799 2.0343 12.7236C2.00891 12.7646 1.99716 12.813 2.00083 12.8615C2.09651 14.0021 2.37944 15.1177 2.83758 16.1608C2.85391 16.1994 2.88083 16.2321 2.91503 16.2551C2.94923 16.278 2.98921 16.2902 3.03003 16.2901H6.74521C6.77558 16.2898 6.80554 16.2827 6.833 16.2693C6.86045 16.2559 6.88475 16.2366 6.90419 16.2125C6.91814 16.1845 6.92541 16.1535 6.92541 16.1221C6.92541 16.0906 6.91814 16.0596 6.90419 16.0316C6.71248 14.9859 6.60612 13.9255 6.58622 12.8615Z"
        fill="#1D1D1D"
      />
      <path
        d="M7.40624 17.7459C7.39209 17.7028 7.36504 17.6654 7.32896 17.6391C7.29288 17.6128 7.24963 17.5989 7.20542 17.5995H3.95883C3.92101 17.5984 3.88367 17.6083 3.8511 17.6281C3.81853 17.648 3.79206 17.6768 3.77474 17.7115C3.75384 17.7448 3.74273 17.7837 3.74273 17.8234C3.74273 17.8632 3.75384 17.902 3.77474 17.9354C5.12307 19.9084 7.09629 21.3369 9.35587 21.9757C9.39964 21.9868 9.4457 21.9837 9.48768 21.9667C9.52965 21.9497 9.56546 21.9197 9.59016 21.8809C9.61868 21.8442 9.63421 21.7987 9.63421 21.7517C9.63421 21.7047 9.61868 21.6591 9.59016 21.6225C8.61593 20.495 7.87308 19.1764 7.40624 17.7459Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.7778 17.5995C16.7336 17.5989 16.6904 17.6128 16.6543 17.6391C16.6182 17.6654 16.5912 17.7028 16.577 17.7459C16.1152 19.175 15.3781 20.4935 14.4098 21.6225C14.3813 21.6591 14.3658 21.7047 14.3658 21.7517C14.3658 21.7987 14.3813 21.8442 14.4098 21.8809C14.4345 21.9197 14.4704 21.9497 14.5123 21.9667C14.5543 21.9837 14.6004 21.9868 14.6441 21.9757C16.9037 21.3369 18.8769 19.9084 20.2253 17.9354C20.2454 17.9034 20.2562 17.866 20.2562 17.8277C20.2562 17.7895 20.2454 17.7521 20.2253 17.7201C20.21 17.6831 20.1843 17.6518 20.1514 17.6303C20.1186 17.6088 20.0801 17.598 20.0412 17.5995H16.7778Z"
        fill="#1D1D1D"
      />
      <path
        d="M7.20542 6.40054C7.24963 6.40111 7.29288 6.38724 7.32896 6.36093C7.36504 6.33462 7.39209 6.29722 7.40624 6.25409C7.86809 4.82502 8.60518 3.50655 9.57342 2.37754C9.60194 2.34087 9.61748 2.2953 9.61748 2.24832C9.61748 2.20135 9.60194 2.15577 9.57342 2.1191C9.55052 2.08489 9.51883 2.0579 9.48189 2.04117C9.44495 2.02443 9.40421 2.01861 9.36423 2.02434C7.10466 2.66313 5.13143 4.09156 3.78311 6.06457C3.76294 6.09665 3.7522 6.13404 3.7522 6.17225C3.7522 6.21046 3.76294 6.24786 3.78311 6.27993C3.79833 6.31686 3.82408 6.34816 3.85694 6.36969C3.88981 6.39122 3.92826 6.40198 3.96719 6.40054H7.20542Z"
        fill="#1D1D1D"
      />
      <path
        d="M21.9824 12.8615C21.9849 12.8321 21.9818 12.8026 21.9732 12.7745C21.9646 12.7464 21.9506 12.7203 21.9322 12.6978C21.8902 12.6541 21.833 12.6293 21.7732 12.6289H17.5895C17.536 12.6308 17.4852 12.653 17.4466 12.6911C17.408 12.7293 17.3844 12.7807 17.3803 12.8356C17.3462 13.9155 17.223 14.9904 17.0121 16.0488C16.9982 16.0768 16.9909 16.1078 16.9909 16.1393C16.9909 16.1708 16.9982 16.2018 17.0121 16.2297C17.0316 16.2538 17.0559 16.2732 17.0833 16.2866C17.1108 16.2999 17.1407 16.307 17.1711 16.3073H20.903C20.9439 16.3074 20.9838 16.2953 21.018 16.2723C21.0522 16.2493 21.0791 16.2166 21.0955 16.1781C21.5728 15.1327 21.8728 14.011 21.9824 12.8615Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.577 6.25409C16.5912 6.29722 16.6182 6.33462 16.6543 6.36093C16.6904 6.38724 16.7336 6.40111 16.7778 6.40054H20.0244C20.0623 6.40162 20.0996 6.39168 20.1322 6.37186C20.1647 6.35205 20.1912 6.32317 20.2085 6.28855C20.2294 6.25516 20.2405 6.21628 20.2405 6.17656C20.2405 6.13684 20.2294 6.09796 20.2085 6.06457C18.8581 4.08994 16.8817 2.66133 14.619 2.02434C14.5753 2.01319 14.5292 2.01632 14.4872 2.03329C14.4452 2.05027 14.4094 2.08026 14.3847 2.1191C14.3562 2.15577 14.3407 2.20135 14.3407 2.24832C14.3407 2.2953 14.3562 2.34087 14.3847 2.37754C15.362 3.50428 16.1077 4.82291 16.577 6.25409Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Regional;
