const isFirefox = () =>
  navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const isEdge = () => navigator.userAgent.toLowerCase().indexOf('edge') > -1;

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isAndroidPlatform = () => {
  const { userAgent } = navigator;
  return userAgent.indexOf('Android ') > -1;
};

const isIpadOS = () =>
  navigator?.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);

const isIpad = () => {
  const { userAgent } = navigator;
  const isIpad = /iPad/.test(userAgent);
  const isMacLike = /Macintosh/.test(userAgent);
  const hasTouch = !!navigator.maxTouchPoints && navigator.maxTouchPoints > 0;

  // If the user agent specifically says iPad or it's a Mac with touch support, it's likely an iPad
  return isIpad || (isMacLike && hasTouch);
};

const isIOSPlatform = () => {
  const { userAgent } = navigator;
  return (
    userAgent.match(/iPad/i) !== null || userAgent.match(/Phone/i) !== null
  );
};

const isMobile = () => isAndroidPlatform() || isIOSPlatform();

const isInIframe = () => window.location !== window.parent.location;

const uaParser = {
  isEdge,
  isSafari,
  isFirefox,
  isAndroidPlatform,
  isIOSPlatform,
  isIpadOS,
  isIpad,
  isMobile,
  isInIframe,
};

export default uaParser;
