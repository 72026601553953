import { overlaysEndpoints } from './endpoints';
import type { RootState } from 'store';

export const selectOverlays = (state: RootState) => {
  return (
    overlaysEndpoints.endpoints.fetchOverlays.select({})(state)?.data || {
      layers: {},
    }
  );
};
