/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const Drag = ({ fill }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="12" height="1" fill={fill} />
    <rect y="6.5" width="12" height="1" fill={fill} />
  </svg>
);

Drag.propTypes = {
  fill: PropTypes.string,
};

Drag.defaultProps = {
  fill: colorPalette.grayMedium,
};

export default Drag;
