import { actionTypes } from '../actions/transformHandler';

// Action Handlers
const ACTION_HANDLERS = {
  [actionTypes.START_TRANSFORM]: (state, action) => ({
    status: 'ENABLED',
    element: action.element,
  }),
  [actionTypes.FINISH_TRANSFORM]: (state, action) => ({
    status: 'DISABLED',
    element: null,
  }),
};

// Reducer
const initialState = {
  status: 'DISABLED',
  element: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
