import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const MenuCollapse = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="menuCollapse"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4946 12.4683H13.69L15.5257 14.0838C15.6285 14.1821 15.6893 14.3163 15.6954 14.4583C15.7016 14.6004 15.6526 14.7393 15.5587 14.8461C15.4648 14.9529 15.3333 15.0192 15.1916 15.0313C15.05 15.0434 14.9091 15.0003 14.7985 14.9109L14.792 14.9055L12.0658 12.5066C11.9823 12.4329 11.9154 12.3422 11.8695 12.2407C11.8237 12.1392 11.8 12.029 11.8 11.9176C11.8 11.8062 11.8237 11.6961 11.8695 11.5945C11.9154 11.493 11.9825 11.4022 12.066 11.3285L14.798 8.92466C14.9085 8.83419 15.0498 8.79015 15.1922 8.80184C15.3345 8.81352 15.4668 8.88002 15.5611 8.98731C15.6554 9.0946 15.7043 9.23431 15.6976 9.37698C15.6909 9.51966 15.6291 9.65416 15.5252 9.75215L15.5201 9.75678L13.6905 11.3667H17.2946V11.3609L17.4999 11.3663C17.6435 11.3701 17.78 11.4298 17.8802 11.5327C17.9804 11.6356 18.0365 11.7736 18.0365 11.9173C18.0365 12.0609 17.9804 12.1989 17.8802 12.3018C17.78 12.4047 17.6435 12.4644 17.4999 12.4682L17.4946 12.4683Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM8.25 3.5H7C5.067 3.5 3.5 5.067 3.5 7V17C3.5 18.933 5.067 20.5 7 20.5H8.25V3.5ZM9.75 3.5V20.5H17C18.933 20.5 20.5 18.933 20.5 17V7C20.5 5.067 18.933 3.5 17 3.5H9.75Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4946 12.4683H13.69L15.5257 14.0838C15.6285 14.1821 15.6893 14.3163 15.6954 14.4583C15.7016 14.6004 15.6526 14.7393 15.5587 14.8461C15.4648 14.9529 15.3333 15.0192 15.1916 15.0313C15.05 15.0434 14.9091 15.0003 14.7985 14.9109L14.792 14.9055L12.0658 12.5066C11.9823 12.4329 11.9154 12.3422 11.8695 12.2407C11.8237 12.1392 11.8 12.029 11.8 11.9176C11.8 11.8062 11.8237 11.6961 11.8695 11.5945C11.9154 11.493 11.9825 11.4022 12.066 11.3285L14.798 8.92466C14.9085 8.83419 15.0498 8.79015 15.1922 8.80184C15.3345 8.81352 15.4668 8.88002 15.5611 8.98731C15.6554 9.0946 15.7043 9.23431 15.6976 9.37698C15.6909 9.51966 15.6291 9.65416 15.5252 9.75215L15.5201 9.75678L13.6905 11.3667H17.2946V11.3609L17.4999 11.3663C17.6435 11.3701 17.78 11.4298 17.8802 11.5327C17.9804 11.6356 18.0365 11.7736 18.0365 11.9173C18.0365 12.0609 17.9804 12.1989 17.8802 12.3018C17.78 12.4047 17.6435 12.4644 17.4999 12.4682L17.4946 12.4683Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2H7ZM9.75 3.5V20.5H17C18.933 20.5 20.5 18.933 20.5 17V7C20.5 5.067 18.933 3.5 17 3.5H9.75Z"
          fill={color}
        />
      </svg>;
};

export default MenuCollapse;
