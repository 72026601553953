import React from 'react';
import { IconProps } from '../types';

const MastercardCC = ({ width = 100, height = 67 }: IconProps) => (
  <svg
    id="mc-dark"
    width={width}
    height={height}
    viewBox="0 16.6 100.1 66.8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      {/* eslint-disable-next-line max-len */}
      <path
        fill="#35495E"
        d="M100,76.7c0,3.7-3,6.7-6.7,6.7H6.7c-3.7,0-6.7-3-6.7-6.7V23.3c0-3.7,3-6.7,6.7-6.7h86.7 c3.7,0,6.7,3,6.7,6.7L100,76.7L100,76.7z"
      />
      <g>
        <circle fill="#E64C3C" cx="37.2" cy="49.9" r="19.5" />
        <circle fill="#F0C419" cx="63.5" cy="49.9" r="19.5" />
        <path
          fill="#F29C1F"
          d="M44,49.9c0,5.7,2.5,10.9,6.4,14.5c3.9-3.6,6.4-8.7,6.4-14.5S54.3,39,50.4,35.4C46.4,39,44,44.2,44,49.9z"
        />
      </g>
      <g>
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M42.1,56.5l0.3-2.4c-0.2,0-0.5,0.1-0.7,0.1c-1,0-1.1-0.5-1-0.9l0.8-4.8H43l0.4-2.6H42l0.3-1.6h-2.8c-0.1,0.1-1.6,9.2-1.6,10.3c0,1.6,0.9,2.4,2.2,2.4C41.1,56.9,41.8,56.6,42.1,56.5z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M43,52c0,3.9,2.6,4.9,4.8,4.9c2,0,2.9-0.5,2.9-0.5l0.5-2.7c0,0-1.6,0.7-3,0.7c-3,0-2.5-2.2-2.5-2.2h5.7c0,0,0.4-1.8,0.4-2.5c0-1.8-0.9-4.1-4-4.1C45.1,45.6,43,48.6,43,52z M47.9,48.1c1.6,0,1.3,1.8,1.3,1.9h-3.1C46.1,49.8,46.4,48.1,47.9,48.1z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M65.5,56.5l0.5-3c0,0-1.4,0.7-2.3,0.7c-2,0-2.8-1.5-2.8-3.1c0-3.3,1.7-5.1,3.6-5.1c1.4,0,2.6,0.8,2.6,0.8l0.5-2.9c0,0-1.7-0.7-3.1-0.7c-3.2,0-6.3,2.8-6.3,8c0,3.5,1.7,5.8,5,5.8C64,56.9,65.5,56.5,65.5,56.5z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M26.8,45.6c-1.9,0-3.4,0.6-3.4,0.6L23,48.6c0,0,1.2-0.5,3-0.5c1,0,1.8,0.1,1.8,1c0,0.5-0.1,0.7-0.1,0.7s-0.8-0.1-1.2-0.1c-2.4,0-4.9,1-4.9,4.1c0,2.4,1.7,3,2.7,3c2,0,2.8-1.3,2.9-1.3l-0.1,1.1h2.5l1.1-7.7C30.6,45.7,27.8,45.6,26.8,45.6z M27.4,51.9c0.1,0.5-0.3,2.7-2,2.7c-0.9,0-1.1-0.7-1.1-1.1c0-0.8,0.4-1.7,2.5-1.7C27.3,51.8,27.3,51.9,27.4,51.9z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M33.2,56.8c0.6,0,4.2,0.2,4.2-3.5c0-3.5-3.3-2.8-3.3-4.2c0-0.7,0.5-0.9,1.5-0.9c0.4,0,1.9,0.1,1.9,0.1l0.4-2.5c0,0-1-0.2-2.6-0.2c-2.1,0-4.1,0.8-4.1,3.6c0,3.2,3.5,2.9,3.5,4.2c0,0.9-1,1-1.7,1c-1.3,0-2.5-0.4-2.5-0.4l-0.4,2.4C30.2,56.5,30.9,56.8,33.2,56.8z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M88.6,43.4L88,47.1c0,0-1-1.4-2.7-1.4c-2.5,0-4.7,3.1-4.7,6.6c0,2.3,1.1,4.5,3.5,4.5c1.7,0,2.6-1.2,2.6-1.2l-0.1,1h2.7l2.1-13.2L88.6,43.4z M87.3,50.7c0,1.5-0.7,3.4-2.2,3.4c-1,0-1.5-0.8-1.5-2.2c0-2.2,1-3.6,2.2-3.6C86.8,48.4,87.3,49.1,87.3,50.7z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M11.8,56.7l1.7-10l0.2,10h1.9l3.6-10l-1.6,10h2.8l2.2-13.3h-4.4l-2.7,8.1l-0.1-8.1h-4.1L9.1,56.7H11.8z"
        />
        {/* eslint-disable-next-line max-len */}
        <path
          fill="#FFFFFF"
          d="M54.6,56.7c0.8-4.6,1-8.3,2.9-7.7c0.3-1.8,0.7-2.5,1-3.2c0,0-0.2,0-0.5,0c-1.2,0-2.2,1.7-2.2,1.7l0.2-1.6h-2.6l-1.7,10.8H54.6z"
        />
        <g transform="translate(847.0062,0)">
          {/* eslint-disable-next-line max-len */}
          <path
            fill="#FFFFFF"
            d="M-775.1,45.6c-1.9,0-3.4,0.6-3.4,0.6l-0.4,2.4c0,0,1.2-0.5,3-0.5c1,0,1.8,0.1,1.8,1c0,0.5-0.1,0.7-0.1,0.7s-0.8-0.1-1.2-0.1c-2.4,0-4.9,1-4.9,4.1c0,2.4,1.7,3,2.7,3c2,0,2.8-1.3,2.9-1.3l-0.1,1.1c0,0,2.4,0,2.5,0l1.1-7.7C-771.3,45.7-774.1,45.6-775.1,45.6z M-774.5,51.9c0.1,0.5-0.3,2.7-2,2.7c-0.9,0-1.1-0.7-1.1-1.1c0-0.8,0.4-1.7,2.5-1.7  C-774.6,51.8-774.6,51.9-774.5,51.9z"
          />
        </g>
        <g transform="translate(442.2857,0)">
          {/* eslint-disable-next-line max-len */}
          <path
            fill="#FFFFFF"
            d="M-364.3,56.7c0.8-4.6,1-8.3,2.9-7.7c0.3-1.8,0.7-2.5,1-3.2c0,0-0.2,0-0.5,0  c-1.2,0-2.2,1.7-2.2,1.7l0.2-1.6h-2.6l-1.7,10.8H-364.3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default MastercardCC;
