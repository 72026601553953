import { lazy, Suspense } from 'react';
import PageLoader from '../../components/PageLoader';

const EmbedMapPage = lazy(() => import('./index'));

const EmbedMapPageLoader = () => {
  return (
    <Suspense fallback={<PageLoader staticContent isLoaded={false} />}>
      <EmbedMapPage />
    </Suspense>
  );
};

export default EmbedMapPageLoader;
