import { NonConfigurableIconProps } from '../types';

const CheckCircle = ({
  width = '24',
  height = '24',
  color = '#1D1D1D',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM15.775 6.83333L10.0667 14.575C10.0002 14.6655 9.91622 14.7417 9.81962 14.7989C9.72303 14.8562 9.61587 14.8933 9.50457 14.9081C9.39327 14.9229 9.28014 14.915 9.17195 14.885C9.06376 14.8549 8.96276 14.8034 8.875 14.7333L4.8 11.475C4.7144 11.4062 4.64323 11.3212 4.59058 11.2249C4.53794 11.1285 4.50486 11.0227 4.49324 10.9135C4.48162 10.8043 4.4917 10.6939 4.5229 10.5886C4.55409 10.4833 4.60578 10.3852 4.675 10.3C4.81348 10.132 5.01222 10.0248 5.2287 10.0014C5.44518 9.97808 5.6622 10.0404 5.83334 10.175L9.23334 12.8917L14.4333 5.83333C14.5645 5.65762 14.7597 5.54071 14.9766 5.50795C15.1934 5.47519 15.4144 5.52922 15.5917 5.65833C15.6817 5.7228 15.7579 5.80455 15.816 5.89882C15.8741 5.99309 15.9128 6.09798 15.9298 6.20737C15.9469 6.31676 15.942 6.42846 15.9154 6.53593C15.8888 6.64341 15.8411 6.74451 15.775 6.83333Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckCircle;
