import { useMemo } from 'react';
import { useFeatureFlagsContext } from './context';
import { FeatureFlagOverrides, FeatureFlags } from './types';
import type { FeatureFlagContextType } from './context';

export { FeatureFlagsProvider } from './context';

export function useFeatureFlags() {
  const { remoteFeatureFlags, overrides } = useFeatureFlagsContext();

  const flags = useMemo(() => {
    const nonNullOverrides = Object.fromEntries(
      Object.entries(overrides).filter(([_key, value]) => value !== null),
    );

    const mergedFlags = { ...remoteFeatureFlags };

    for (const [key, value] of Object.entries(nonNullOverrides)) {
      if (value !== null) {
        mergedFlags[key] = {
          value: value?.value ?? remoteFeatureFlags[key]?.value ?? false,
          payload: value.payload ?? remoteFeatureFlags[key]?.payload ?? null,
        };
      }
    }

    return mergedFlags;
  }, [remoteFeatureFlags, overrides]);

  return flags;
}

export function useFeatureFlagsLoaded() {
  const { featureFlagsLoaded } = useFeatureFlagsContext();

  return featureFlagsLoaded;
}

export function useFeatureFlagOverrides(): [
  FeatureFlagOverrides,
  FeatureFlagContextType['setOverride'],
] {
  const { remoteFeatureFlags, overrides, setOverride } =
    useFeatureFlagsContext();

  const featureFlagOverrides: FeatureFlagOverrides = {};
  for (const key of Object.keys(remoteFeatureFlags)) {
    featureFlagOverrides[key] = overrides[key] ?? {
      value: null,
      payload: null,
    };
  }

  return [featureFlagOverrides, setOverride];
}

export function useIdentifyUser() {
  const { identifyUser } = useFeatureFlagsContext();
  return identifyUser;
}

interface withFeatureFlagsProps {
  featureFlags: FeatureFlags;
}

// This Higher-Order Component (HOC) function exists to inject feature flags into class components.
export function withFeatureFlags<P extends withFeatureFlagsProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, 'featureFlags'>> {
  const ComponentWithFeatureFlags: React.FC<Omit<P, 'featureFlags'>> = (
    props,
  ) => {
    const featureFlags = useFeatureFlags();
    return <WrappedComponent {...(props as P)} featureFlags={featureFlags} />;
  };

  return ComponentWithFeatureFlags;
}
