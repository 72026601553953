import React from 'react';
import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const ErrorImage = ({
  fill = colors.grayScale100,
  width = '32',
  height = '32',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49334 22.7467H15.2667C15.2533 22.8934 15.24 23.0534 15.24 23.2C15.24 23.8 15.32 24.4 15.4533 24.9734H4.92001C3.68001 24.9734 2.66667 23.9734 2.66667 22.7467V4.89335C2.66667 3.66669 3.68001 2.66669 4.92001 2.66669H23C24.24 2.66669 25.2533 3.66669 25.2533 4.89335V15.7067C24.5733 15.52 23.8533 15.4134 23.1333 15.4134H22.9867V5.45335C22.9867 5.14669 22.7333 4.89335 22.4267 4.89335H5.49334C5.18667 4.89335 4.93334 5.14669 4.93334 5.45335V22.1867C4.93334 22.4934 5.18667 22.7467 5.49334 22.7467ZM17.7467 12.1333L19.4267 16.32L19.4134 16.3067C18.7334 16.6667 18.1067 17.1333 17.5467 17.68C16.7867 18.4267 16.2 19.3067 15.8134 20.2667H7.76002C7.62669 20.28 7.49335 20.24 7.38669 20.16C7.14669 19.9733 7.09335 19.6133 7.28002 19.3733L10 14.76C10.1067 14.56 10.2534 14.3867 10.44 14.2533C11.04 13.8533 11.8534 14 12.2667 14.5867L13.5067 16.0533C13.5734 16.1333 13.6667 16.16 13.76 16.16C13.8534 16.1467 13.9467 16.08 13.9867 16L15.8267 12.1333C15.92 11.8667 16.2267 11.36 16.7734 11.36C17.28 11.36 17.6 11.7733 17.7467 12.1333ZM27.52 18.88C26.3067 17.68 24.72 17.08 23.1334 17.08C21.5467 17.08 19.96 17.68 18.7467 18.88C16.3334 21.2667 16.3334 25.1467 18.7467 27.5467C19.96 28.7467 21.5467 29.3467 23.1334 29.3467C24.72 29.3467 26.3067 28.7467 27.52 27.5467C29.9334 25.16 29.9334 21.28 27.52 18.88ZM22.5734 19.1867H23.7867C24.08 19.1867 24.32 19.4267 24.3067 19.72L24.08 23.5867C24.0667 23.8533 23.84 24.0667 23.56 24.0667H22.7867C22.5067 24.0667 22.28 23.8533 22.2667 23.5867L22.0534 19.72C22.04 19.4267 22.28 19.1867 22.5734 19.1867ZM24.3467 26.1733C24.3467 26.8533 23.8134 27.24 23.1334 27.24C22.4534 27.24 21.9334 26.8667 21.9334 26.1733V26.08C21.9334 25.4 22.4534 25 23.1334 25C23.8134 25 24.3467 25.3867 24.3467 26.08V26.1733ZM12.12 8.97335C12.12 10.2031 11.1111 11.2 9.86666 11.2C8.62218 11.2 7.61333 10.2031 7.61333 8.97335C7.61333 7.7436 8.62218 6.74668 9.86666 6.74668C11.1111 6.74668 12.12 7.7436 12.12 8.97335Z"
      fill={fill}
    />
  </svg>
);

export default ErrorImage;
