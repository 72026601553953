export default {
  LOAD_MAP_SUCCESS: 'LOAD_MAP_SUCCESS',
  LOAD_MAP_FAILURE: 'LOAD_MAP_FAILURE',
  LOAD_NEW_MAP: 'LOAD_NEW_MAP',
  LOAD_COUNTIES_SUCCESS: 'LOAD_COUNTIES_SUCCESS',
  LOAD_COUNTIES_FAILURE: 'LOAD_COUNTIES_FAILURE',
  LOAD_SHARE_MAP_SUCCESS: 'LOAD_SHARE_MAP_SUCCESS',
  LOAD_SHARE_MAP_FAILURE: 'LOAD_SHARE_MAP_FAILURE',
  CREATE_MAP_SUCCESS: 'CREATE_MAP_SUCCESS',
  CREATE_MAP_FAILURE: 'CREATE_MAP_FAILURE',
  DUPLICATE_MAP_SUCCESS: 'DUPLICATE_MAP_SUCCESS',
  DUPLICATE_MAP_FAILURE: 'DUPLICATE_MAP_FAILURE',
  SAVE_MAP_REQUEST: 'SAVE_MAP_REQUEST',
  SET_SAVING_MAP: 'SET_SAVING_MAP',
  SAVE_MAP_SUCCESS: 'SAVE_MAP_SUCCESS',
  SAVE_MAP_FAILURE: 'SAVE_MAP_FAILURE',
  SAVE_NEW_MAP_FAILURE: 'SAVE_NEW_MAP_FAILURE',
  SAVE_REPORT_SUCCESS: 'SAVE_REPORT_SUCCESS',
  SAVE_REPORT_FAILURE: 'SAVE_REPORT_FAILURE',
  CHANGE_BASE_LAYER: 'CHANGE_BASE_LAYER',
  REMOVE_TOOL_FROM_MAP: 'REMOVE_TOOL_FROM_MAP',
  CHANGE_GEO_JSON: 'CHANGE_GEO_JSON',
  CHANGE_MAP_PROPERTY: 'CHANGE_MAP_PROPERTY',
  CHANGE_TOOL_PROPERTY: 'CHANGE_TOOL_PROPERTY',
  UPDATE_TOOL_PROPERTIES: 'UPDATE_TOOL_PROPERTIES',
  CHANGE_TOOL_POSITION: 'CHANGE_TOOL_POSITION',
  CHANGE_TOOLS_POSITIONS: 'CHANGE_TOOLS_POSITIONS',
  CHANGE_TOOL_INDEX: 'CHANGE_TOOL_INDEX',
  TOGGLE_ELEMENT_VISIBILITY: 'TOGGLE_ELEMENT_VISIBILITY',
  TOGGLE_ELEMENT_PROPERTY: 'TOGGLE_ELEMENT_PROPERTY',
  TOGGLE_LAYER_VISIBILITY: 'TOGGLE_LAYER_VISIBILITY',
  TURN_LAYER_VISIBILITY_ON: 'TURN_LAYER_VISIBILITY_ON',
  TURN_LAYER_VISIBILITY_OFF: 'TURN_LAYER_VISIBILITY_OFF',
  TOGGLE_SHARED_LAYER_VISIBILITY: 'TOGGLE_SHARED_LAYER_VISIBILITY',
  TOGGLE_ALL_LAYERS_VISIBILITY: 'TOGGLE_ALL_LAYERS_VISIBILITY',
  CREATE_TOOL: 'CREATE_TOOL',
  CREATE_GEOREFERENCE_SUCCESS: 'CREATE_GEOREFERENCE_SUCCESS',
  CREATE_GEOREFERENCE_FAILURE: 'CREATE_GEOREFERENCE_FAILURE',
  ADD_CONTROL_POINT_TO_GEOREFERENCE: 'ADD_CONTROL_POINT_TO_GEOREFERENCE',
  REMOVE_CONTROL_POINT_FROM_GEOREFERENCE:
    'REMOVE_CONTROL_POINT_FROM_GEOREFERENCE',
  UPDATE_GEOREFERENCE_CALCULATED_COORDINATES:
    'UPDATE_GEOREFERENCE_CALCULATED_COORDINATES',
  CHANGE_ELEMENT_GEOMETRY: 'CHANGE_ELEMENT_GEOMETRY',
  CHANGE_GEOJSON_ELEMENT: 'CHANGE_GEOJSON_ELEMENT',
  CHANGE_TOOL_FROM_DEED_PLOTTER: 'CHANGE_TOOL_FROM_DEED_PLOTTER',
  CHANGE_MAP_ZOOM: 'CHANGE_MAP_ZOOM',
  CHANGE_MAP_CENTER: 'CHANGE_MAP_CENTER',
  TOGGLE_LAYERS_VISIBILITY_OFF_BY_CATEGORIZATION:
    'TOGGLE_LAYERS_VISIBILITY_OFF_BY_CATEGORIZATION',
  TOGGLE_LAYERS_VISIBILITY_ON_BY_CATEGORIZATION:
    'TOGGLE_LAYERS_VISIBILITY_ON_BY_CATEGORIZATION',
  CHANGE_TOOL_INSTANCES_PROPERTIES: 'CHANGE_TOOL_INSTANCES_PROPERTIES',
  CHANGE_MAP_ACTIVE_FILTER: 'CHANGE_MAP_ACTIVE_FILTER',
  SET_TOOL_CUSTOM_DATA_STATUS: 'SET_TOOL_CUSTOM_DATA_STATUS',
  SET_CUSTOM_TOOL_DATA_SUCCESS: 'SET_CUSTOM_TOOL_DATA_SUCCESS',
  SET_CUSTOM_TOOL_DATA_FAILURE: 'SET_CUSTOM_TOOL_DATA_FAILURE',
  SET_HAS_UNSAVED_CHANGES: 'SET_HAS_UNSAVED_CHANGES',
  ADD_DOCUMENT_SUCCESS: 'ADD_DOCUMENT_SUCCESS',
  ADD_DOCUMENT_FAILURE: 'ADD_DOCUMENT_FAILURE',
  REMOVE_DOCUMENT_SUCCESS: 'REMOVE_DOCUMENT_SUCCESS',
  REMOVE_DOCUMENT_FAILURE: 'REMOVE_DOCUMENT_FAILURE',
  EDIT_DOCUMENT_SUCCESS: 'EDIT_DOCUMENT_SUCCESS',
  EDIT_DOCUMENT_FAILURE: 'EDIT_DOCUMENT_FAILURE',
  ADD_CUSTOM_MESSAGE: 'ADD_CUSTOM_MESSAGE',
  TURN_STREETS_ON: 'TURN_STREETS_ON',
  TURN_STREETS_OFF: 'TURN_STREETS_OFF',
  UPDATE_WAYPOINT: 'UPDATE_WAYPOINT',
  TOGGLE_LAYERS_VISIBILITY: 'TOGGLE_LAYERS_VISIBILITY',
};
