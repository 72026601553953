import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const MenuExpand = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="menuExpand"
      >
        <path
          d="M16.1465 11.365L12.3419 11.365L12.3366 11.3651C12.193 11.3689 12.0566 11.4286 11.9563 11.5315C11.8561 11.6344 11.8 11.7724 11.8 11.9161C11.8 12.0597 11.8561 12.1977 11.9563 12.3006C12.0566 12.4035 12.193 12.4632 12.3366 12.467L12.5419 12.4724V12.4666L16.146 12.4666L14.3164 14.0765L14.3113 14.0812C14.2074 14.1791 14.1456 14.3137 14.1389 14.4563C14.1322 14.599 14.1811 14.7387 14.2754 14.846C14.3697 14.9533 14.502 15.0198 14.6443 15.0315C14.7867 15.0432 14.928 14.9991 15.0385 14.9087L17.7705 12.5048C17.8541 12.4311 17.9211 12.3403 17.967 12.2388C18.0128 12.1372 18.0365 12.0271 18.0365 11.9157C18.0365 11.8043 18.0128 11.6941 17.967 11.5926C17.9211 11.4911 17.8542 11.4004 17.7707 11.3268L15.0445 8.92782L15.038 8.92238C14.9274 8.83304 14.7865 8.7899 14.6449 8.80199C14.5032 8.81407 14.3717 8.88044 14.2778 8.98722C14.1839 9.094 14.1349 9.23294 14.1411 9.37499C14.1472 9.51704 14.208 9.65122 14.3108 9.74949L16.1465 11.365Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 3.5H8.25V20.5H7C5.067 20.5 3.5 18.933 3.5 17V7C3.5 5.067 5.067 3.5 7 3.5ZM9.75 20.5V3.5H17C18.933 3.5 20.5 5.067 20.5 7V17C20.5 18.933 18.933 20.5 17 20.5H9.75Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1465 11.365L12.3419 11.365L12.3366 11.3651C12.193 11.3689 12.0566 11.4286 11.9563 11.5315C11.8561 11.6344 11.8 11.7724 11.8 11.9161C11.8 12.0597 11.8561 12.1977 11.9563 12.3006C12.0566 12.4035 12.193 12.4632 12.3366 12.467L12.5419 12.4724V12.4666L16.146 12.4666L14.3164 14.0765L14.3113 14.0812C14.2074 14.1791 14.1456 14.3137 14.1389 14.4563C14.1322 14.599 14.1811 14.7387 14.2754 14.846C14.3697 14.9533 14.502 15.0198 14.6443 15.0315C14.7867 15.0432 14.928 14.9991 15.0385 14.9087L17.7705 12.5048C17.8541 12.4311 17.9211 12.3403 17.967 12.2388C18.0128 12.1372 18.0365 12.0271 18.0365 11.9157C18.0365 11.8043 18.0128 11.6941 17.967 11.5926C17.9211 11.4911 17.8542 11.4004 17.7707 11.3268L15.0445 8.92782L15.038 8.92238C14.9274 8.83304 14.7865 8.7899 14.6449 8.80199C14.5032 8.81407 14.3717 8.88044 14.2778 8.98722C14.1839 9.094 14.1349 9.23294 14.1411 9.37499C14.1472 9.51704 14.208 9.65122 14.3108 9.74949L16.1465 11.365Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM9.75 20.5V3.5H17C18.933 3.5 20.5 5.067 20.5 7V17C20.5 18.933 18.933 20.5 17 20.5H9.75Z"
          fill={color}
        />
      </svg>;
};

export default MenuExpand;
