import actionTypes from 'actions/gisFileImport/actions';

const ACTION_HANDLERS = {
  [actionTypes.SHAPEFILE_PARSE_SUCCESS]: (state, action) => ({
    ...state,
    parsedGeoJson: action.response.data,
  }),
  [actionTypes.KML_PARSE_SUCCESS]: (state, action) => ({
    ...state,
    parsedGeoJson: action.data,
  }),
  [actionTypes.SHAPEFILE_PARSE_FAILURE]: (state, action) => ({
    ...state,
    error: action.error,
    parsedGeoJson: null,
  }),
  [actionTypes.FETCH_DEFAULT_FILTERS_SUCCESS]: (state, action) => ({
    ...state,
    defaultFilters: action.data,
  }),
  [actionTypes.FETCH_DEFAULT_FILTERS_FAILURE]: (state, action) => ({
    ...state,
    error: action.error,
  }),
};

const initialState = {
  defaultFilters: {},
  parsedGeoJson: {},
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
