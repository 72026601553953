export const actionTypes = {
  HIGHLIGHT: 'HIGHLIGHT',
};

export const highlight = (element) => ({
  type: actionTypes.HIGHLIGHT,
  element,
});

export const actions = {
  highlight,
};
