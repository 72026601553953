import { NonConfigurableIconProps } from '../types';

const PinAdd = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9165 2C9.8176 2.00221 7.80528 2.83697 6.32113 4.32113C4.83697 5.80528 4.00221 7.8176 4 9.91651C4 15.3747 9.41656 20.333 11.0832 21.7079C11.3071 21.8966 11.5904 22 11.8832 22C12.1759 22 12.4593 21.8966 12.6832 21.7079C14.4165 20.333 19.833 15.3747 19.833 9.91651C19.8308 7.8176 18.996 5.80528 17.5119 4.32113C16.0277 2.83697 14.0154 2.00221 11.9165 2V2ZM15.8748 11.2331H13.1665C13.056 11.2331 12.95 11.277 12.8719 11.3552C12.7937 11.4333 12.7498 11.5393 12.7498 11.6498V14.3581C12.7498 14.5791 12.662 14.7911 12.5058 14.9473C12.3495 15.1036 12.1375 15.1914 11.9165 15.1914C11.6955 15.1914 11.4835 15.1036 11.3273 14.9473C11.171 14.7911 11.0832 14.5791 11.0832 14.3581V11.6498C11.0832 11.5393 11.0393 11.4333 10.9612 11.3552C10.883 11.277 10.777 11.2331 10.6665 11.2331H7.95825C7.73724 11.2331 7.52529 11.1454 7.36901 10.9891C7.21273 10.8328 7.12494 10.6208 7.12494 10.3998C7.12494 10.1788 7.21273 9.96686 7.36901 9.81059C7.52529 9.65431 7.73724 9.56651 7.95825 9.56651H10.6665C10.777 9.56651 10.883 9.52262 10.9612 9.44448C11.0393 9.36634 11.0832 9.26036 11.0832 9.14986V6.44158C11.0832 6.22057 11.171 6.00861 11.3273 5.85233C11.4835 5.69606 11.6955 5.60826 11.9165 5.60826C12.1375 5.60826 12.3495 5.69606 12.5058 5.85233C12.662 6.00861 12.7498 6.22057 12.7498 6.44158V9.14986C12.7498 9.26036 12.7937 9.36634 12.8719 9.44448C12.95 9.52262 13.056 9.56651 13.1665 9.56651H15.8748C16.0958 9.56651 16.3077 9.65431 16.464 9.81059C16.6203 9.96686 16.7081 10.1788 16.7081 10.3998C16.7081 10.6208 16.6203 10.8328 16.464 10.9891C16.3077 11.1454 16.0958 11.2331 15.8748 11.2331Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default PinAdd;
