import { StyleableOnlyIconProps } from '../types';

const Import = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 17C11.7888 17 11.5973 16.9215 11.4576 16.7942L12.2963 16.9455C12.2051 16.9806 12.105 17 12 17Z"
          fill="black"
        />
        <path
          d="M12.2963 16.9455L11.452 16.7891L8.22676 13.7955C7.92442 13.5149 7.92442 13.0599 8.22676 12.7793C8.5291 12.4987 9.01929 12.4987 9.32163 12.7793L11.2258 14.5467L11.2258 7.71856C11.2258 7.32171 11.5724 7 12 7C12.4276 7 12.7742 7.32171 12.7742 7.71856L12.7742 14.5467L14.6784 12.7793C14.9807 12.4987 15.4709 12.4987 15.7732 12.7793C16.0756 13.0599 16.0756 13.5149 15.7732 13.7955L12.5474 16.7895C12.4732 16.8584 12.3877 16.9104 12.2963 16.9455Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.80429 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34783 22 12 22ZM18.0235 18.0235C16.426 19.621 14.2593 20.5185 12 20.5185C9.74075 20.5185 7.57403 19.621 5.9765 18.0235C4.37897 16.426 3.48148 14.2593 3.48148 12C3.48148 9.74075 4.37897 7.57403 5.9765 5.9765C7.57403 4.37896 9.74075 3.48148 12 3.48148C14.2593 3.48148 16.426 4.37896 18.0235 5.9765C19.621 7.57403 20.5185 9.74075 20.5185 12C20.5185 14.2593 19.621 16.426 18.0235 18.0235Z"
          fill="black"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34783 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34783 3.05357 6.80429 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34783 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711ZM12 17.35C12.1482 17.35 12.2908 17.3226 12.4218 17.2722C12.5529 17.2219 12.6772 17.1466 12.7855 17.0461L16.0113 14.0521C16.4629 13.633 16.4629 12.9419 16.0113 12.5228C15.5747 12.1176 14.8769 12.1176 14.4403 12.5228L13.1242 13.7443V7.71857C13.1242 7.1043 12.5958 6.65 12 6.65C11.4042 6.65 10.8758 7.1043 10.8758 7.71857L10.8758 13.7443L9.55974 12.5228C9.12312 12.1176 8.42528 12.1176 7.98867 12.5228C7.53712 12.9419 7.53712 13.633 7.98867 14.0521L11.214 17.0456L11.2206 17.0517L11.2219 17.0529C11.426 17.2389 11.7015 17.35 12 17.35Z"
          fill="black"
        />
      </svg>;
};

export default Import;
