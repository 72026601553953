/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const DocumentPlus = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5833 20C15.6546 20 16.7019 19.6823 17.5927 19.0871C18.4834 18.4919 19.1777 17.646 19.5877 16.6562C19.9977 15.6664 20.1049 14.5773 19.8959 13.5266C19.6869 12.4759 19.171 11.5107 18.4135 10.7532C17.656 9.99564 16.6908 9.47975 15.6401 9.27075C14.5893 9.06175 13.5002 9.16901 12.5105 9.57899C11.5207 9.98896 10.6747 10.6832 10.0795 11.574C9.48435 12.4648 9.16667 13.512 9.16667 14.5833C9.16667 16.0199 9.73735 17.3977 10.7532 18.4135C11.769 19.4293 13.1467 20 14.5833 20ZM11.875 14.1319H14.0191C14.049 14.1319 14.0777 14.1201 14.0989 14.0989C14.1201 14.0777 14.1319 14.049 14.1319 14.0191V11.875C14.1319 11.7553 14.1795 11.6405 14.2642 11.5558C14.3488 11.4712 14.4636 11.4236 14.5833 11.4236C14.703 11.4236 14.8179 11.4712 14.9025 11.5558C14.9872 11.6405 15.0347 11.7553 15.0347 11.875V14.0191C15.0347 14.049 15.0466 14.0777 15.0678 14.0989C15.0889 14.1201 15.1176 14.1319 15.1476 14.1319H17.2917C17.4114 14.1319 17.5262 14.1795 17.6108 14.2642C17.6955 14.3488 17.7431 14.4636 17.7431 14.5833C17.7431 14.7031 17.6955 14.8179 17.6108 14.9025C17.5262 14.9872 17.4114 15.0347 17.2917 15.0347H15.1476C15.1176 15.0347 15.0889 15.0466 15.0678 15.0678C15.0466 15.0889 15.0347 15.1176 15.0347 15.1476V17.2917C15.0347 17.4114 14.9872 17.5262 14.9025 17.6108C14.8179 17.6955 14.703 17.7431 14.5833 17.7431C14.4636 17.7431 14.3488 17.6955 14.2642 17.6108C14.1795 17.5262 14.1319 17.4114 14.1319 17.2917V15.1476C14.1319 15.1176 14.1201 15.0889 14.0989 15.0678C14.0777 15.0466 14.049 15.0347 14.0191 15.0347H11.875C11.7553 15.0347 11.6405 14.9872 11.5558 14.9025C11.4712 14.8179 11.4236 14.7031 11.4236 14.5833C11.4236 14.4636 11.4712 14.3488 11.5558 14.2642C11.6405 14.1795 11.7553 14.1319 11.875 14.1319Z"
      fill={fill}
    />
    <path
      d="M18.3333 9.58333V1.59724C18.3333 1.17376 18.1722 0.767439 17.8857 0.46796C17.5993 0.168481 17.2114 2.43716e-05 16.8056 2.43716e-05H6.11111C6.06337 -0.000365575 6.01563 0.00392384 5.96937 0.0128926C5.91716 0.0226413 5.86643 0.0382391 5.81719 0.0592962C5.72469 0.0990707 5.63965 0.157563 5.56803 0.231652L0.220812 5.82192C0.150689 5.8964 0.0939941 5.98492 0.0566949 6.08241C0.0193956 6.1795 0 6.28401 0 6.38891V17.5694C0 17.9929 0.161133 18.3993 0.447591 18.6987C0.734049 18.9982 1.12196 19.1667 1.52778 19.1667H10.3341C10.2765 19.1132 10.2197 19.0586 10.1639 19.0028C9.73611 18.5749 9.37673 18.0915 9.09282 17.5694H1.90972C1.80827 17.5694 1.71129 17.5273 1.63968 17.4525C1.56806 17.3776 1.52778 17.2762 1.52778 17.1701V7.18751C1.52778 7.15086 1.53225 7.1146 1.54121 7.07989C1.55911 7.01438 1.59342 6.95394 1.63968 6.90519C1.71129 6.83032 1.80827 6.78821 1.90972 6.78821H4.96528C5.14431 6.78821 5.31887 6.75545 5.48448 6.69345C5.69336 6.61468 5.88582 6.48795 6.04546 6.32027C6.15736 6.20368 6.24986 6.07071 6.32148 5.92721C6.43338 5.70182 6.49306 5.44952 6.49306 5.19099V1.99655C6.49306 1.96028 6.49753 1.9248 6.50648 1.89087C6.52439 1.82458 6.55721 1.76375 6.60495 1.71423C6.67657 1.63936 6.77355 1.59724 6.875 1.59724H16.4236C16.5251 1.59724 16.622 1.63936 16.6937 1.71423C16.7653 1.7891 16.8056 1.89048 16.8056 1.99655V8.74174C17.3514 8.94938 17.8657 9.23263 18.3333 9.58333Z"
      fill={fill}
    />
  </svg>
);

DocumentPlus.propTypes = {
  fill: PropTypes.string,
};

DocumentPlus.defaultProps = {
  fill: '#0065FF',
};

export default DocumentPlus;
