import us from 'us';

import { clearFlash, flash } from '../flashMessages';
import { getToolsChanges } from 'selectors/filterSelectors';
import { showModal, hideModal } from 'store/slices/modalsSlice';
import searchActionTypes from 'actions/search/actions';
import actionTypes from './actions';
import errorActionTypes from 'actions/errors/actions';
import api from '../../api';
import { changeActivePanel } from 'actions/editSidebar';
import { selectToolInstance } from 'actions/layerPopups';
import UpgradePlanModal from 'components/modals/UpgradePlanModal';
import SaveAsNewMapProgessModal from 'components/modals/SaveAsNewMapProgess';
import SaveOutdatedMapModal from 'components/modals/SaveOutdatedMap';
import SyncOutdatedMapModal from 'components/modals/SyncOutdatedMap';
import { WAYPOINT_EDIT_MODE, WAYPOINT_PANEL } from 'utils/constants';
import { getStateOverlays } from 'utils/maps/layers';
import { getDefaultBaseLayerForMapCreation } from 'selectors/layersSelectors';
import { selectSettings } from 'api/endpoints/settings/settingsSelectors';
import { api as rtkQueryApi } from 'api/endpoints';
import { selectOverlays } from 'api/endpoints/overlays/overlaysSelectors';
import EventRouter from 'services/EventRouter';
import {
  INTERACTIVE_LABELS_LAYER,
  THIRD_PARTY_PARCELS_LAYER,
} from 'utils/layers';
import { US_GEOGRAPHIC_CENTER } from 'utils/bounds';

const fontSizeLimits = {
  MAX: 40,
  MIN: 8,
};

// Actions creators
export const loadMap = (mapId) => (dispatch, getState) =>
  api
    .loadMap(mapId)
    .then((data) => {
      const stateOverlays = getStateOverlays(
        selectOverlays(getState()),
        data.state,
      );

      // TODO: this is a temporary workaround, we should rely as much as possible on the hooks
      // provided by RTK Query
      const cachedSettings = selectSettings()(getState());
      const settingsPromise =
        cachedSettings ?
          Promise.resolve({ data: cachedSettings })
        : dispatch(rtkQueryApi.endpoints.fetchSettings.initiate({}));

      return settingsPromise.then((settings) => {
        const enabledLayers = settings.data.configuration.enabled_layers;
        dispatch({
          type: actionTypes.LOAD_MAP_SUCCESS,
          data,
          stateOverlays,
          enabledLayers,
        });
        return data;
      });
    })
    .then((data) => {
      if (data.state) {
        loadCounties(data.state)(dispatch, getState);
      }

      return data;
    })
    .catch((error) => dispatch({ type: actionTypes.LOAD_MAP_FAILURE, error }));

export const loadNewMap = (toolboxSlug) => (dispatch, getState) => {
  const { user, map } = getState();
  const settings = selectSettings()(getState());
  const { lat, lng } = settings.configuration.usa_geographic_center;
  const enabledLayers = settings.configuration.enabled_layers;
  const defaultLayer = getDefaultBaseLayerForMapCreation(getState());
  const defaultLayerConfig = enabledLayers[defaultLayer];
  const state = map.newMapState || user.default_state;

  dispatch({
    type: actionTypes.LOAD_NEW_MAP,
    toolboxSlug,
    lng,
    lat,
    defaultLayer,
    state,
    streetsOn: !defaultLayerConfig?.disable_roads_layer_toggle,
  });

  if (state) {
    loadCounties(state)(dispatch, getState);
  }
};

export const loadCounties = (state) => (dispatch, getState) => {
  const mapState = us.lookup(state);

  if (mapState && mapState.abbr) {
    api
      .loadCounties(mapState.abbr.toLowerCase())
      .then((data) =>
        dispatch({ type: actionTypes.LOAD_COUNTIES_SUCCESS, data }),
      )
      .catch((error) =>
        dispatch({ type: actionTypes.LOAD_COUNTIES_FAILURE, error }),
      );
  } else {
    dispatch({
      type: actionTypes.LOAD_COUNTIES_FAILURE,
      error: `Invalid State: ${state}`,
    });
  }
};

/**
 * TODO: Maybe move the duplicated logic to another method.
 */
export const loadHomeMap = () => (dispatch, getState) => {
  return api
    .loadHomeMap(getState().user.user.default_state)
    .then((data) => {
      const stateOverlays = getStateOverlays(
        selectOverlays(getState()),
        data.state,
      );

      const enabledLayers =
        selectSettings('discover')(getState())?.configuration?.enabled_layers;
      dispatch({
        type: actionTypes.LOAD_MAP_SUCCESS,
        data,
        stateOverlays,
        enabledLayers,
      });
      return data;
    })
    .catch((error) => {
      dispatch({ type: actionTypes.LOAD_MAP_FAILURE, error });
    });
};

export const loadCountiesSuggestions =
  (state, counties, type) => (dispatch) => {
    const mapState = us.lookup(state);
    if (mapState && mapState.abbr) {
      api
        .loadParcelSuggestions(
          mapState.abbr.toLowerCase(),
          counties.join(','),
          type,
        )
        .then((data) =>
          dispatch({
            type: searchActionTypes.LOAD_PARCEL_SUGGESTIONS_SUCCESS,
            data,
          }),
        )
        .catch((error) =>
          dispatch({
            type: searchActionTypes.LOAD_PARCEL_SUGGESTIONS_FAILURE,
            error,
          }),
        );
    } else {
      dispatch({
        type: actionTypes.LOAD_PARCEL_SUGGESTIONS_FAILURE,
        error: `Invalid State: ${state}`,
      });
    }
  };

export const createMap = (map) => (dispatch, getState) => {
  const mapToSave = { ...map };
  let { tools_changes: toolsChanges } = mapToSave;

  if (!toolsChanges) {
    toolsChanges = getToolsChanges(getState());
  }

  return api
    .createMap(mapToSave, toolsChanges)
    .then((data) => dispatch(loadMap(data.id)).then(() => data))
    .then((data) => {
      dispatch(flash('MAP_SAVED'));
      setTimeout(() => dispatch(clearFlash()), 3000);
      return data;
    })
    .catch((error) => {
      if (error.cause?.status === 402) {
        dispatch(showModal(UpgradePlanModal, { onMap: true }));
      } else {
        dispatch({ type: actionTypes.CREATE_MAP_FAILURE, error });
      }
    });
};

export const createMapFromScratch =
  (state, name, geoJSON) => async (dispatch, getState) => {
    let settings = selectSettings()(getState());

    if (!settings) {
      settings = await dispatch(
        rtkQueryApi.endpoints.fetchSettings.initiate({}),
      ).unwrap();
    }

    const defaultLayer =
      settings.configuration.base_layers.default_base_layer_for_map_creation;

    const mapToSave = {
      name,
      outside_usa: false,
      geoJSON: geoJSON || [],
      lat: US_GEOGRAPHIC_CENTER.lat,
      lng: US_GEOGRAPHIC_CENTER.lng,
      zoom: null,
      layer: defaultLayer,
      roadsVectorLayerEnabled: true,
      legend: {},
      state,
      county: '',
      acres: '',
      description: '',
      custom_layers: [THIRD_PARTY_PARCELS_LAYER, INTERACTIVE_LABELS_LAYER],
      shared_layers: [],
      photos: [],
      shared_enabled_layers: [],
      toolbox_slug: 'ranching',
      video: null,
      auto_play_video: false,
      display_creator_logo: true,
      display_creator_info: true,
      roadsVectorLayerIds: [],
      streets_on: false,
      active_filter_id: 1,
      active_filter_type: 'DefaultFilter',
      documents: [],
      custom_message: '',
      hasUnsavedChanges: false,
    };

    return api
      .createMap(mapToSave, {
        modifiedTools: [],
        duplicatedTools: [],
        deletedTools: [],
      })
      .then((data) => {
        const stateOverlays = getStateOverlays(
          selectOverlays(getState()),
          state,
        );
        const cachedSettings = selectSettings()(getState());
        const settingsPromise =
          cachedSettings ?
            Promise.resolve({ data: cachedSettings })
          : dispatch(rtkQueryApi.endpoints.fetchSettings.initiate({}));

        return settingsPromise.then((settings) => {
          const enabledLayers = settings.data.configuration.enabled_layers;

          dispatch({
            type: actionTypes.CREATE_MAP_SUCCESS,
            data,
            stateOverlays,
            enabledLayers,
          });

          return data;
        });
      })
      .then((action) => {
        return action;
      })
      .catch((error) => {
        if (error.cause?.status === 402) {
          dispatch(showModal(UpgradePlanModal, { onMap: true }));
        } else {
          dispatch({ type: actionTypes.CREATE_MAP_FAILURE, error });
        }
      });
  };

export const duplicateMap = (map) => (dispatch, getState) => {
  dispatch(showModal(SaveAsNewMapProgessModal));
  const mapToSave = { ...map };
  const toolsChanges = getToolsChanges(getState());

  return api
    .duplicateMap(mapToSave, toolsChanges)
    .then(async (data) => {
      const { data: map } = data;
      if (map) {
        dispatch(showModal(SaveAsNewMapProgessModal, { isCompleted: true }));
        const action = await dispatch({
          type: actionTypes.DUPLICATE_MAP_SUCCESS,
          data: map,
        });
        return action.data;
      }
    })
    .catch((error) => {
      dispatch(showModal(SaveAsNewMapProgessModal, { hasErrors: true }));
      dispatch({ type: actionTypes.DUPLICATE_MAP_FAILURE, error });
      throw error;
    });
};

export const importMap = (map) => (dispatch, getState) => {
  const mapToSave = { ...map };
  let { modifications } = mapToSave;

  if (!modifications) {
    modifications = getToolsChanges(getState());
  }

  return api
    .importMap(mapToSave, modifications)
    .then((data) => dispatch(loadMap(data?.data?.id)).then(() => data))
    .then((data) => {
      dispatch(flash('MAP_SAVED'));
      setTimeout(() => dispatch(clearFlash()), 3000);
      return data;
    })
    .catch((error) => {
      if (error.cause?.status === 402) {
        dispatch(showModal(UpgradePlanModal, { onMap: true }));
      } else {
        dispatch({ type: actionTypes.CREATE_MAP_FAILURE, error });
      }
      throw error;
    });
};

export const updateMapFromImport = (map) => (dispatch, getState) => {
  const { modifications } = map;

  return api.updateMapFromImport(map, modifications);
};

export const loadShareMap =
  (mapSlug, from = 'share') =>
  (dispatch, getState) =>
    api
      .loadShareMap(mapSlug)
      .then((data) => {
        const enabledLayers =
          selectSettings(from)(getState())?.configuration?.enabled_layers;

        dispatch({
          type: actionTypes.LOAD_SHARE_MAP_SUCCESS,
          data,
          enabledLayers,
        });
      })
      .catch((error) =>
        dispatch({ type: actionTypes.LOAD_SHARE_MAP_FAILURE, error }),
      );

const saveMapAction =
  (modalAction, beforeSuccess = () => {}) =>
  (map, showSavedModal = true) =>
  (dispatch, getState) => {
    const mapToSave = { ...map };

    let { tools_changes: toolsChanges } = mapToSave;

    if (!toolsChanges) {
      toolsChanges = getToolsChanges(getState());
    }

    const originalLayer = mapToSave.layer;

    dispatch({ type: actionTypes.SAVE_MAP_REQUEST });
    const saveRequest = (showFlash) =>
      api
        .saveMap(mapToSave, toolsChanges)
        .then((data) =>
          api
            .getMapFilters(mapToSave.id)
            .then((filters) => ({ data, filters })),
        )
        .then(({ data, filters }) => {
          beforeSuccess(data, mapToSave.geoJSON);

          dispatch({
            type: actionTypes.SAVE_MAP_SUCCESS,
            data,
            roadsVectorLayerEnabled: map.roadsVectorLayerEnabled,
            roadsVectorLayerIds: map.roadsVectorLayerIds,
            originalLayer,
            filters,
            documents: mapToSave.documents,
          });
          return data;
        })
        .then((data) => {
          if (showFlash) {
            dispatch(flash('MAP_SAVED'));
            setTimeout(() => dispatch(clearFlash()), 3000);
          }
          return data;
        })
        .catch((error) => {
          dispatch({ type: errorActionTypes.ADD_ERROR, error });
          dispatch({ type: actionTypes.SAVE_MAP_FAILURE, error });
        });

    return new Promise((resolve) =>
      api.mapLastModified(mapToSave).then((data) => {
        const dateDifference =
          new Date(data.last_modified) - new Date(mapToSave.updated_at);

        if (dateDifference > 0) {
          dispatch(hideModal());
          resolve();
          dispatch(modalAction(data, saveRequest, resolve));
        } else {
          return saveRequest(showSavedModal).then((data) => {
            resolve(data);
            return data;
          });
        }
      }),
    );
  };

export const saveMap = saveMapAction((data, saveRequest, resolve) =>
  showModal(SaveOutdatedMapModal, {
    lastModified: data.last_modified,
    saveRequest: () => saveRequest(true).then(() => resolve()),
    onCancel: () => resolve(),
  }),
);

export const autoSaveMap = saveMapAction(
  (data, _saveRequest, _resolve) =>
    showModal(SyncOutdatedMapModal, { lastModified: data.last_modified }),
  (data, geoJSON) => {
    data.geoJSON = geoJSON;
  },
);

export const setToolCustomDataStatus = (data) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SET_TOOL_CUSTOM_DATA_STATUS, data });
};

export const setCustomToolData =
  (map, toolProps, customData) => (dispatch, getState) => {
    setCustomToolDataRequest(map, toolProps, customData, dispatch, getState)
      .then((data) =>
        dispatch({ type: actionTypes.SET_CUSTOM_TOOL_DATA_SUCCESS, data }),
      )
      .catch((error) =>
        dispatch({ type: actionTypes.SET_CUSTOM_TOOL_DATA_FAILURE, error }),
      );
  };

const setCustomToolDataRequest = (
  map,
  toolProps,
  customData,
  dispatch,
  getState,
) => {
  const mapToSave = { ...map };
  const { toolId, toolType, maprightId } = toolProps;
  const originalLayer = mapToSave.layer;

  return api
    .saveMap(mapToSave)
    .then((data) =>
      api.getMapFilters(mapToSave.id).then((filters) => ({ data, filters })),
    )
    .then(({ data, filters }) => {
      dispatch({
        type: actionTypes.SAVE_MAP_SUCCESS,
        data,
        roadsVectorLayerEnabled: mapToSave.roadsVectorLayerEnabled,
        roadsVectorLayerIds: mapToSave.roadsVectorLayerIds,
        originalLayer,
        filters,
      });
    })
    .then(() =>
      api.setCustomToolData(
        mapToSave,
        maprightId,
        toolId,
        toolType,
        customData,
      ),
    );
};

export const sendPanopointRequest =
  (map, toolProps) => (dispatch, getState) => {
    const { toolId, toolType, maprightId } = toolProps;
    const mapToSave = { ...map };
    const { layer: originalLayer } = mapToSave;

    if (map.id) {
      return api
        .setPanoPointCustomData(map, maprightId, toolId, toolType)
        .then((data) =>
          dispatch({ type: actionTypes.SET_CUSTOM_TOOL_DATA_SUCCESS, data }),
        )
        .then(() => {
          api
            .saveMap(mapToSave)
            .then((data) =>
              api
                .getMapFilters(mapToSave.id)
                .then((filters) => ({ data, filters })),
            )
            .then(({ data, filters }) => {
              dispatch({
                type: actionTypes.SAVE_MAP_SUCCESS,
                data,
                roadsVectorLayerEnabled: mapToSave.roadsVectorLayerEnabled,
                roadsVectorLayerIds: mapToSave.roadsVectorLayerIds,
                originalLayer,
                filters,
              });
            });
        })
        .then((data) => api.sendPanopointRequest(map, toolProps.maprightId))
        .catch((error) => {
          dispatch({ type: actionTypes.SET_CUSTOM_TOOL_DATA_FAILURE, error });
        });
    }
  };
export const saveMapError = (error) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_MAP_FAILURE, error });
};

export const saveMapSuccess = (data) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SAVE_MAP_SUCCESS, data });
};

export const saveReport = (report) => (dispatch, getState) =>
  api
    .saveReport(report)
    .then((report) =>
      dispatch({ type: actionTypes.SAVE_REPORT_SUCCESS, report }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.SAVE_REPORT_FAILURE, error }),
    );

export const saveWapiFieldSelection =
  (wapiClassLegend, sharing = false) =>
  (dispatch, getState) => {
    const {
      map: { report: reportTmp },
    } = getState();
    const { id, map_id: mapId } = reportTmp;

    const newReport = {
      id,
      map_id: mapId,
      selected_wapi_field: wapiClassLegend,
    };

    dispatch({
      type: actionTypes.SAVE_REPORT_SUCCESS,
      report: { ...reportTmp, selected_wapi_field: wapiClassLegend },
    });

    if (!sharing) {
      api
        .saveReport(newReport)
        .catch((error) =>
          dispatch({ type: actionTypes.SAVE_REPORT_FAILURE, error }),
        );
    }
  };

/**
 * Changes base layer on map.
 *
 * @param {any} layer
 * @param {'edit' | 'discover' | 'share' | 'embed'}
 */
export const changeBaseLayer = (layer, mapType) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CHANGE_BASE_LAYER,
    layer,
  });

  const {
    map: { id: map_id },
  } = getState();

  EventRouter.sendWithPlanProperties('map_basemap_toggled', {
    map_id,
    map_type: mapType,
    basemap: layer,
  });
};
/**
 * Send overlay toggled events to event router.
 *
 * @param {any} layer
 * @param {'parcel_card' | 'overlay_menu'}
 * @param {'edit' | 'discover' | 'share' | 'embed'}
 */
const sendOverlayToggledEvent = (layer, path, mapType, state) => {
  if (!path || !mapType) return;

  const {
    map: {
      id: map_id,
      custom_layers: updatedCustomLayers,
      shared_enabled_layers: updatedShareCustomLayers,
    },
  } = state;

  const layerTurnedOn =
    mapType === 'share' ?
      updatedShareCustomLayers.includes(layer)
    : updatedCustomLayers.includes(layer);
  const allOverlaysOn =
    layer === 'all_overlays' && updatedCustomLayers.length > 0;

  if (layerTurnedOn || allOverlaysOn) {
    EventRouter.sendWithPlanProperties('map_overlay_toggledOn', {
      map_id,
      map_type: mapType,
      overlay: layer,
      path,
    });
  } else {
    EventRouter.sendWithPlanProperties('map_overlay_toggledOff', {
      map_id,
      map_type: mapType,
      overlay: layer,
      path,
    });
  }
};

/**
 * Toggles the visibility of a layer.
 *
 * @param {any} layer
 * @param {any} category
 * @param {'parcel_card' | 'overlay_menu'}
 * @param {'edit' | 'discover' | 'share' | 'embed'}
 */
export const toggleLayerVisibility =
  (layer, category, path, mapType) => (dispatch, getState) => {
    const {
      map: { custom_layers: customLayers },
      editSidebar: { activePanel },
    } = getState();

    dispatch({
      type: actionTypes.TOGGLE_LAYER_VISIBILITY,
      layer,
      category,
      customLayers,
      activePanel,
    });

    sendOverlayToggledEvent(layer, path, mapType, getState());
  };

export const toggleLayersVisibility =
  (layers, category = null, toggleOn = false, debounce = 500) =>
  (dispatch, getState) => {
    const {
      editSidebar: { activePanel },
    } = getState();

    dispatch({
      type: actionTypes.TOGGLE_LAYERS_VISIBILITY,
      layers,
      category,
      activePanel,
      toggleOn,
      meta: { debounce: { time: debounce } },
    });
  };

export const turnLayerVisibilityOn = (layer) => ({
  type: actionTypes.TURN_LAYER_VISIBILITY_ON,
  layer,
});

export const turnLayerVisibilityOff = (layer) => ({
  type: actionTypes.TURN_LAYER_VISIBILITY_OFF,
  layer,
});

export const toggleSharedLayerVisibility = (layer) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.TOGGLE_SHARED_LAYER_VISIBILITY,
    layer,
  });

  sendOverlayToggledEvent(layer, 'overlay_menu', 'share', getState());
};

/**
 * Toggles the visibility of all layers.
 *
 * @param {any} layer
 * @param {bool} active
 * @param {'parcel_card' | 'overlay_menu'}
 * @param {'edit' | 'discover' | 'share' | 'embed'}
 */
export const toggleAllLayersVisibility =
  (layers, active, path, mapType) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.TOGGLE_ALL_LAYERS_VISIBILITY,
      layers,
      active,
    });

    sendOverlayToggledEvent('all_overlays', path, mapType, getState());
  };

export const removeToolFromMap = (maprightId) => ({
  type: actionTypes.REMOVE_TOOL_FROM_MAP,
  maprightId,
});

export const changeGeoJSON = (geoJSON) => ({
  type: actionTypes.CHANGE_GEO_JSON,
  geoJSON,
});

export const changeMapState =
  (state, keepLayers = false) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.CHANGE_MAP_PROPERTY,
      property: 'state',
      value: state,
      keepLayers,
    });

    if (!state) {
      return;
    }

    const mapState = us.lookup(state);
    if (mapState && mapState.abbr) {
      api
        .loadCounties(mapState.abbr.toLowerCase())
        .then((data) =>
          dispatch({ type: actionTypes.LOAD_COUNTIES_SUCCESS, data }),
        )
        .catch((error) =>
          dispatch({ type: actionTypes.LOAD_COUNTIES_FAILURE, error }),
        );
    } else {
      dispatch({
        type: actionTypes.LOAD_COUNTIES_FAILURE,
        error: `Invalid State: ${state}`,
      });
    }
  };

export const setNewMapState = (state) => ({
  type: actionTypes.CHANGE_MAP_PROPERTY,
  property: 'newMapState',
  value: state,
});

export const updateToolProperties = (maprightId, properties) => ({
  type: actionTypes.UPDATE_TOOL_PROPERTIES,
  maprightId,
  properties,
});

export const changeToolProperty = (maprightId, property, value) => ({
  type: actionTypes.CHANGE_TOOL_PROPERTY,
  maprightId,
  property,
  value,
});

export const incrementLabelFontSize = (maprightId, increment = 1) => ({
  type: actionTypes.CHANGE_TOOL_PROPERTY,
  maprightId,
  property: 'font_size',
  transform: (oldValue) => {
    let newValue = oldValue + increment;
    if (newValue < fontSizeLimits.MIN || fontSizeLimits.MAX < newValue) {
      newValue = oldValue;
    }
    return newValue;
  },
});

export const decrementLabelFontSize = (maprightId, increment = -1) =>
  incrementLabelFontSize(maprightId, increment);

export const changeToolPosition = (maprightId, coordinates) => ({
  type: actionTypes.CHANGE_TOOL_POSITION,
  maprightId,
  coordinates,
});

export const changeToolsPositions = (coordinatesIdsMap, debounce = 200) => ({
  type: actionTypes.CHANGE_TOOLS_POSITIONS,
  coordinatesIdsMap,
  meta: { debounce: { time: debounce } },
});

export const changeToolIndex = (targetTool, draggedTool, forward) => ({
  type: actionTypes.CHANGE_TOOL_INDEX,
  targetTool,
  draggedTool,
  forward,
});

export const toggleElementVisibility = (toolId, toolType, visibility) => ({
  type: actionTypes.TOGGLE_ELEMENT_VISIBILITY,
  toolId,
  toolType,
  visibility,
});

export const toggleElementProperty = (maprightId, attribute) => ({
  type: actionTypes.TOGGLE_ELEMENT_PROPERTY,
  maprightId,
  attribute,
});

export const createTool =
  (geoJSON, customData = {}, path) =>
  (dispatch, getState) => {
    const {
      draw: { properties },

      map: { id: map_id },
      filters: { tools },
    } = getState();

    const toolProperties = geoJSON?.properties;
    const toolId = toolProperties?.toolId;
    const featureType = toolProperties?.toolType;
    const tool = tools.find(
      (tool) => tool.id === toolId && tool.tool_type === featureType,
    );
    const isCustom = !tool.predefined;

    geoJSON.properties.name = tool?.name;

    const element = dispatch({
      type: actionTypes.CREATE_TOOL,
      geoJSON,
      properties,
      customData,
    });

    if (featureType) {
      EventRouter.sendWithPlanProperties('mapFeature_feature_added', {
        map_id,
        map_type: 'edit',
        feature_type: featureType,
        feature_name: tool?.name,
        is_custom: isCustom,
        path,
      });
    }

    dispatch(selectToolInstance(element.geoJSON));

    if (
      element?.geoJSON?.properties?.name?.toLowerCase().includes('waypoint')
    ) {
      dispatch(
        changeActivePanel(WAYPOINT_PANEL, {
          mode: WAYPOINT_EDIT_MODE,
          id: element.geoJSON.id,
        }),
      );
    }

    return element;
  };

export const createToolFromDeedPlotter = (geoJSON) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CREATE_TOOL,
    geoJSON,
  });
};

export const createGeoreference = (image) => (dispatch, getState) => {
  const { map } = getState();

  api
    .createGeoreference(map, image)
    .then((data) =>
      dispatch({ type: actionTypes.CREATE_GEOREFERENCE_SUCCESS, data }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.CREATE_GEOREFERENCE_FAILURE, error }),
    );
};

export const changeGeoreferenceOpacity = (maprightId, value) => ({
  type: actionTypes.CHANGE_TOOL_PROPERTY,
  property: 'opacity',
  maprightId,
  value,
});

export const changeGeoreferenceName = (maprightId, value) => ({
  type: actionTypes.CHANGE_TOOL_PROPERTY,
  property: 'instanceName',
  maprightId,
  value,
});

export const addControlPoinToGeoreference =
  (controlPoint) => (dispatch, getState) =>
    dispatch({
      type: actionTypes.ADD_CONTROL_POINT_TO_GEOREFERENCE,
      controlPoint,
      maprightId: getState().georeference.maprightId,
    });

export const removeControlPointFromGeoreference = (maprightId, index) => ({
  type: actionTypes.REMOVE_CONTROL_POINT_FROM_GEOREFERENCE,
  index,
  maprightId,
});

export const updateGeoreferenceCalculatedCoordinates = (
  maprightId,
  coords,
) => ({
  type: actionTypes.UPDATE_GEOREFERENCE_CALCULATED_COORDINATES,
  coords,
  maprightId,
  meta: {
    debounce: {
      time: 200,
      key: `update-georeference-${maprightId}`,
    },
  },
});

export const turnStreetsOn = () => ({
  type: actionTypes.TURN_STREETS_ON,
});

export const turnStreetsOff = () => ({
  type: actionTypes.TURN_STREETS_OFF,
});

export const changeGeoJSONElement = (element, debounceParam = 0) => ({
  type: actionTypes.CHANGE_GEOJSON_ELEMENT,
  element,
  meta: { debounce: { time: debounceParam } },
});

export const changeToolFromDeedPlotter = (element, index) => ({
  type: actionTypes.CHANGE_TOOL_FROM_DEED_PLOTTER,
  element,
  index,
});

export const changeElementGeometry = (element, index, debounce = 20) => ({
  type: actionTypes.CHANGE_ELEMENT_GEOMETRY,
  element,
  index,
  meta: { debounce: { time: debounce } },
});

export const changeMapZoom = (newZoom, debounceParam) => ({
  type: actionTypes.CHANGE_MAP_ZOOM,
  newZoom,
  meta: { debounce: { time: debounceParam } },
});

export const changeMapCenter = ({ lat, lng }, debounceParam) => ({
  type: actionTypes.CHANGE_MAP_CENTER,
  lat,
  lng,
  meta: { debounce: { time: debounceParam } },
});

export const toggleLayersByCategorization =
  (categorization, turnOn, sharing = false) =>
  (dispatch, getState) => {
    const {
      map,
      editSidebar: { activePanel },
    } = getState();
    const overlays = selectOverlays(getState());
    const type =
      turnOn ?
        actionTypes.TOGGLE_LAYERS_VISIBILITY_ON_BY_CATEGORIZATION
      : actionTypes.TOGGLE_LAYERS_VISIBILITY_OFF_BY_CATEGORIZATION;
    const stateOverlays = getStateOverlays(overlays, map.state);
    dispatch({
      type,
      categorization,
      stateOverlays,
      sharing,
      activePanel,
    });
  };

export const changeToolInstancesProperties = (
  toolInstances,
  properties,
  options,
) => ({
  type: actionTypes.CHANGE_TOOL_INSTANCES_PROPERTIES,
  toolInstances,
  properties,
  options,
});

export const changeMapActiveFilter = (filterId, filterType) => ({
  type: actionTypes.CHANGE_MAP_ACTIVE_FILTER,
  filterId,
  filterType,
});

export const addDocument =
  (document, mapId, belongsToTool, geoJSON) => (dispatch) =>
    api
      .uploadDocument(document, mapId, belongsToTool)
      .then((response) =>
        dispatch({
          type: actionTypes.ADD_DOCUMENT_SUCCESS,
          document: response,
          geoJSON,
        }),
      )
      .catch((error) => {
        dispatch({ type: actionTypes.ADD_DOCUMENT_FAILURE });
        dispatch({ type: errorActionTypes.ADD_ERROR, error });
      });

export const removeDocument = (document, mapId, geoJSON) => (dispatch) =>
  api
    .deleteDocument(document.id, mapId)
    .then((response) =>
      dispatch({
        type: actionTypes.REMOVE_DOCUMENT_SUCCESS,
        document: response.id,
        geoJSON,
      }),
    )
    .catch((error) => {
      dispatch({ type: actionTypes.REMOVE_DOCUMENT_FAILURE });
      dispatch({ type: actionTypes.ADD_ERROR, error });
    });

export const editDocument = (document, changes, mapId) => (dispatch) =>
  api
    .updateDocument(changes, document.id, mapId)
    .then((document) =>
      dispatch({ type: actionTypes.EDIT_DOCUMENT_SUCCESS, document }),
    )
    .catch((error) => {
      dispatch({ type: actionTypes.EDIT_DOCUMENT_FAILURE });
      dispatch({ type: errorActionTypes.ADD_ERROR, error });
    });

export const addCustomMessage = (message) => ({
  type: actionTypes.ADD_CUSTOM_MESSAGE,
  message,
});

export const updateWaypoint = (feature, notes, newPhotos) => ({
  type: actionTypes.UPDATE_WAYPOINT,
  notes,
  newPhotos,
  feature,
});

export const setSavingMap = () => ({
  type: actionTypes.SET_SAVING_MAP,
});

export const setHasUnsavedChanges = () => ({
  type: actionTypes.SET_HAS_UNSAVED_CHANGES,
});

export const actions = {
  loadMap,
  loadNewMap,
  loadHomeMap,
  createMap,
  createMapFromScratch,
  duplicateMap,
  saveMap,
  saveReport,
  changeBaseLayer,
  toggleLayerVisibility,
  turnLayerVisibilityOn,
  turnLayerVisibilityOff,
  toggleAllLayersVisibility,
  removeToolFromMap,
  changeMapState,
  incrementLabelFontSize,
  decrementLabelFontSize,
  changeToolPosition,
  changeToolsPositions,
  changeToolIndex,
  changeToolProperty,
  updateToolProperties,
  toggleElementVisibility,
  toggleElementProperty,
  createTool,
  createToolFromDeedPlotter,
  createGeoreference,
  changeGeoreferenceOpacity,
  changeGeoreferenceName,
  addControlPoinToGeoreference,
  removeControlPointFromGeoreference,
  updateGeoreferenceCalculatedCoordinates,
  changeElementGeometry,
  changeGeoJSONElement,
  changeToolFromDeedPlotter,
  changeMapZoom,
  changeMapCenter,
  toggleLayersByCategorization,
  changeToolInstancesProperties,
  changeMapActiveFilter,
  setCustomToolData,
  sendPanopointRequest,
  addDocument,
  removeDocument,
  editDocument,
  addCustomMessage,
  saveWapiFieldSelection,
  loadCounties,
  loadCountiesSuggestions,
  turnStreetsOn,
  turnStreetsOff,
  updateWaypoint,
  setSavingMap,
  setHasUnsavedChanges,
};
