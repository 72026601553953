import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Message = ({ fill = colors.grayScale300 }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.91091 0.333353C8.87776 0.331646 9.83183 0.554645 10.6979 0.984785C11.5642 1.41501 12.3188 2.04071 12.9022 2.81253C13.4856 3.58435 13.882 4.48123 14.06 5.43245C14.2381 6.38367 14.193 7.36328 13.9283 8.29413C13.6636 9.22492 13.1866 10.0815 12.5347 10.7964C11.8828 11.5113 11.074 12.0649 10.1718 12.4136C9.26969 12.7623 8.2989 12.8966 7.33611 12.8058C6.44994 12.7222 5.59279 12.4501 4.82169 12.0088L1.4295 13.6261C1.27026 13.702 1.08047 13.6694 0.955674 13.5448C0.83088 13.4201 0.798131 13.2303 0.873914 13.071L2.48954 9.67485C1.97617 8.77691 1.69324 7.76421 1.66791 6.72763C1.6407 5.61411 1.91171 4.51358 2.45285 3.54023C2.994 2.56687 3.78556 1.75615 4.74544 1.19223C5.70512 0.628425 6.79805 0.331871 7.91091 0.333353Z"
      fill={fill}
    />
  </svg>
);

export default Message;
