import { INDIVIDUAL_TEAM, ORGANIZATIONS } from '../../../utils/constants';

export const chooseAccount = async (
  allUserSubscription,
  selectedAccount,
  store,
  teams,
) => {
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      const updatedSelectedAccount = store.getState().selectedAccount;

      if (
        updatedSelectedAccount &&
        ((updatedSelectedAccount.type === INDIVIDUAL_TEAM &&
          updatedSelectedAccount.id === allUserSubscription?.individual?.id) ||
          (updatedSelectedAccount.type === ORGANIZATIONS &&
            updatedSelectedAccount.id === selectedAccount?.id))
      ) {
        unsubscribe();
        resolve();
      }
    });

    if (selectedAccount) {
      if (
        selectedAccount.type === INDIVIDUAL_TEAM &&
        allUserSubscription.individual?.id === selectedAccount.id
      ) {
        unsubscribe();
        resolve();
        return;
      }

      if (selectedAccount.type === ORGANIZATIONS) {
        const selected = teams.find(
          ({ organization_id, team_id }) =>
            organization_id === selectedAccount.organizationId &&
            team_id === selectedAccount.id,
        );

        if (selected) {
          unsubscribe();
          resolve();
          return;
        }
      }
    }

    if (teams.length === 1) {
      return store.dispatch({
        type: 'SELECT_ACCOUNT',
        data: {
          type: INDIVIDUAL_TEAM,
          id: allUserSubscription?.individual?.id,
        },
      });
    }

    const team = teams.find(({ team_id }) => !!team_id);
    if (team) {
      return store.dispatch({
        type: 'SELECT_ACCOUNT',
        data: {
          type: ORGANIZATIONS,
          organizationId: team.organization_id,
          organizationName: team.organization_name,
          id: team.team_id,
          name: team.team_name,
        },
      });
    }
  });
};
