import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import ErrorScreen from 'components/ErrorScreen';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' },
  };

  static propTypes = {
    children: PropTypes.node,
  };

  static getDerivedStateFromError(error) {
    if (error) {
      return { hasError: true };
    }
  }

  componentDidCatch = (error, info) => {
    const { user, map } = this.props;

    this.setState({ error, info });

    if (['production'].includes(process.env.ENVIRONMENT)) {
      newrelic.noticeError(error, {
        user: user?.email,
        selectedAccount: user?.selectedAccount,
        map: map?.id,
      });
    }
  };

  renderErrorData = () => {
    const { error, info } = this.state;

    return (
      <main className="error-boundary">
        <h2>Error</h2>
        <h3>Message</h3>
        <div>
          <p>{error.message}</p>
        </div>

        <h3>Stack</h3>
        <div>
          {error.stack.split('\n').map((line, index) => (
            <p key={`call-stack-line-${index}`}>{line}</p>
          ))}
        </div>

        <h2>Info</h2>
        <h3>Component Stack</h3>

        <div>
          {info.componentStack.split('\n').map((line, index) => (
            <p key={`component-stack-line-${index}`}>{line}</p>
          ))}
        </div>
      </main>
    );
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return (
      hasError ?
        process.env.ENVIRONMENT === 'production' ?
          <ErrorScreen />
        : this.renderErrorData()
      : children
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user?.user,
  map: state.map,
});

ErrorBoundary.propTypes = {
  user: PropTypes.object,
  map: PropTypes.object,
};

export default connect(mapStateToProps)(ErrorBoundary);
