import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Refresh = ({ fill = colors.white }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M9.7559 3.64089C9.0284 3.41035 8.25187 3.38211 7.50955 3.55922C6.79914 3.74315 6.14507 4.09948 5.60531 4.59663C5.06554 5.09378 4.65674 5.71641 4.41515 6.40933C4.40497 6.42807 4.39963 6.44907 4.39963 6.4704C4.39963 6.49173 4.40497 6.51273 4.41515 6.53147C4.42867 6.56279 4.45363 6.58775 4.48495 6.60127L5.57846 6.74087C5.64116 6.73717 5.70338 6.75386 5.7558 6.78846C5.80822 6.82306 5.84804 6.8737 5.86928 6.93281C5.88764 6.98371 5.89082 7.03884 5.87843 7.0915C5.86603 7.14416 5.83861 7.19209 5.79948 7.22946L3.78114 9.3176C3.74995 9.35005 3.71166 9.37483 3.66926 9.38997C3.62687 9.40511 3.58154 9.4102 3.53685 9.40484C3.49191 9.39959 3.44873 9.38432 3.41047 9.36016C3.37222 9.336 3.33988 9.30357 3.31582 9.26525L1.87914 6.73505C1.84937 6.69023 1.8335 6.63763 1.8335 6.58382C1.8335 6.53002 1.84937 6.47741 1.87914 6.43259C1.90711 6.38678 1.94795 6.35022 1.99656 6.32746C2.04518 6.3047 2.09942 6.29676 2.15251 6.30463L2.73417 6.38024C2.76834 6.38319 2.80249 6.37443 2.83103 6.3554C2.85956 6.33638 2.88079 6.30822 2.89121 6.27555C3.18255 5.27481 3.73115 4.36794 4.4823 3.64537C5.23346 2.92279 6.16091 2.40976 7.17219 2.15743C8.16928 1.91708 9.21298 1.95249 10.1915 2.25987C11.17 2.56726 12.0465 3.13504 12.727 3.9024C12.7904 3.97382 12.8391 4.05702 12.8703 4.14727C12.9016 4.23751 12.9147 4.33302 12.9091 4.42835C12.9034 4.52369 12.879 4.61697 12.8373 4.70287C12.7956 4.78877 12.7373 4.86562 12.6659 4.92902C12.5945 4.99241 12.5113 5.04113 12.421 5.07237C12.3308 5.10361 12.2353 5.11677 12.14 5.1111C12.0446 5.10543 11.9513 5.08104 11.8654 5.03931C11.7795 4.99759 11.7027 4.93936 11.6393 4.86794C11.1345 4.29561 10.4834 3.87144 9.7559 3.64089Z"
      fill={fill}
    />
    <path
      d="M15.1668 8.73595C15.1668 8.78943 15.1518 8.84184 15.1234 8.88718C15.0989 8.93445 15.0623 8.97441 15.0173 9.00301C14.9724 9.03161 14.9207 9.04783 14.8675 9.05004L14.216 8.97442C14.1823 8.9688 14.1476 8.97576 14.1187 8.99399C14.0897 9.01222 14.0685 9.04044 14.059 9.07331C13.7712 10.0793 13.2234 10.9918 12.4709 11.7188C11.7183 12.4458 10.7875 12.9618 9.77218 13.2147C9.34366 13.3185 8.90437 13.3712 8.46346 13.3717C7.65995 13.3719 6.86564 13.2008 6.13347 12.8698C5.4013 12.5388 4.7481 12.0556 4.21738 11.4523C4.1463 11.3823 4.09039 11.2984 4.05313 11.2058C4.01586 11.1133 3.99804 11.0141 4.00078 10.9143C4.00351 10.8146 4.02674 10.7165 4.06902 10.6261C4.1113 10.5358 4.17172 10.4551 4.24653 10.3891C4.32134 10.3231 4.40893 10.2732 4.50385 10.2425C4.59878 10.2118 4.69901 10.2009 4.7983 10.2106C4.89759 10.2203 4.99383 10.2504 5.08101 10.2989C5.1682 10.3474 5.24447 10.4133 5.30508 10.4925C5.81159 11.0643 6.46371 11.488 7.19198 11.7185C7.92025 11.949 8.69739 11.9776 9.44064 11.8013C10.1553 11.6207 10.8141 11.2659 11.3582 10.7686C11.9022 10.2712 12.3145 9.64681 12.5583 8.95116C12.5685 8.93241 12.5738 8.91142 12.5738 8.89008C12.5738 8.86875 12.5685 8.84776 12.5583 8.82901C12.5509 8.80486 12.5374 8.78304 12.5191 8.7657C12.5007 8.74836 12.4781 8.7361 12.4536 8.73013L11.3775 8.60216C11.3237 8.59617 11.2726 8.57493 11.2304 8.54093C11.1881 8.50693 11.1564 8.46159 11.1391 8.41022C11.1214 8.35968 11.118 8.30523 11.1293 8.25289C11.1406 8.20055 11.1661 8.15234 11.2031 8.11358L13.2098 6.00799C13.2421 5.97542 13.2814 5.95064 13.3248 5.93553C13.3681 5.92041 13.4143 5.91535 13.4599 5.92074C13.5034 5.92789 13.5448 5.94465 13.581 5.96981C13.6172 5.99496 13.6474 6.02787 13.6693 6.06615L15.1234 8.58472C15.1518 8.63005 15.1668 8.68246 15.1668 8.73595Z"
      fill={fill}
    />
  </svg>
);

export default Refresh;
