/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const HelpQuestionMark = ({ fill }) => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.906 8.107L4.991 6.917C6.266 6.509 7.507 5.574 7.507 3.891V3.721C7.507 1.885 5.807 0.711999 3.886 0.711999C1.829 0.711999 0.078 1.732 0.078 3.891V4.129H2.679C2.628 3.364 3.036 2.803 3.784 2.803C4.447 2.803 4.838 3.211 4.838 3.908C4.838 5.166 3.835 5.404 2.543 5.404L2.356 5.608L2.475 8.107H4.906ZM5.331 10.521C5.331 9.569 4.583 9.025 3.648 9.025C2.747 9.025 1.999 9.569 1.999 10.521V10.657C1.999 11.609 2.747 12.136 3.648 12.136C4.583 12.136 5.331 11.609 5.331 10.657V10.521Z"
      fill={fill}
    />
  </svg>
);

HelpQuestionMark.propTypes = {
  fill: PropTypes.string,
};

HelpQuestionMark.defaultProps = {
  fill: '#1D1D1D',
};

export default HelpQuestionMark;
