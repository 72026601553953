import { StyleableOnlyIconProps } from '../types';

const Discover = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8649 9.08244C13.8649 9.49167 14.1966 9.82295 14.6064 9.82295C15.0162 9.82295 15.348 9.49167 15.348 9.08244V4.71731L19.5338 6.39321C19.6217 6.42244 19.69 6.4809 19.7387 6.55885C19.7875 6.6368 19.8168 6.72449 19.8168 6.81218V10.544C19.8168 10.9532 20.1486 11.2845 20.5584 11.2845C20.9682 11.2845 21.2999 10.9532 21.2999 10.544V6.81218C21.2999 6.42244 21.1828 6.05218 20.9682 5.74038H20.9584C20.7437 5.41885 20.4413 5.17526 20.0803 5.03885L15.3187 3.13885C14.8601 2.95372 14.3527 2.95372 13.8941 3.13885L8.83986 5.15577C8.78131 5.17526 8.73253 5.185 8.67398 5.185C8.61544 5.185 8.5569 5.185 8.50811 5.15577L3.81486 3.285C3.60995 3.20705 3.39529 3.17782 3.18063 3.19731C2.96597 3.21679 2.75131 3.29474 2.57568 3.42141C2.39029 3.53833 2.24393 3.71372 2.14636 3.89885C2.04879 4.09372 2 4.30808 2 4.52244V15.8835C2 16.2635 2.11709 16.6337 2.33175 16.9553C2.54641 17.2768 2.84888 17.5204 3.2099 17.6568L7.9617 19.5568C8.19588 19.6445 8.43005 19.6932 8.67398 19.6932C8.7618 19.6932 8.83986 19.6932 8.91792 19.6737C9.20088 19.6347 9.38627 19.3619 9.38627 19.0696V6.51013L13.8551 4.72705V9.08244H13.8649ZM7.93243 17.9783L3.74655 16.3122C3.65874 16.2732 3.59044 16.2147 3.54165 16.1465C3.49286 16.0588 3.46359 15.9712 3.46359 15.8835V4.71731L7.93243 6.50038V17.9783Z"
          fill="#1D1D1D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7878 20.7553L19.7973 18.7676C20.0412 18.4363 20.2461 18.0855 20.4022 17.7055C20.6559 17.1014 20.7828 16.4486 20.7828 15.7958C20.7828 15.1429 20.6559 14.4901 20.4022 13.886C20.1486 13.2819 19.7778 12.7265 19.3192 12.2686C18.8606 11.8106 18.3044 11.4404 17.6995 11.1871C17.0945 10.9337 16.4408 10.8071 15.787 10.8071C15.1333 10.8071 14.4796 10.9337 13.8746 11.1871C13.2697 11.4404 12.7135 11.8106 12.2549 12.2686C11.7963 12.7265 11.4255 13.2819 11.1718 13.886C10.9182 14.4901 10.7913 15.1429 10.7913 15.7958C10.7913 16.4486 10.9182 17.1014 11.1718 17.7055C11.4255 18.3096 11.7963 18.865 12.2549 19.3229C12.7135 19.7809 13.2697 20.1512 13.8746 20.4045C14.4796 20.6578 15.1333 20.7845 15.787 20.7845C16.4408 20.7845 17.0945 20.6578 17.6995 20.4045C18.08 20.2486 18.441 20.044 18.763 19.8004L20.7535 21.7881C21.0365 22.0706 21.5048 22.0706 21.7878 21.7881C22.0707 21.5055 22.0707 21.0378 21.7878 20.7553ZM19.046 17.1404C18.8703 17.5691 18.6069 17.9588 18.2752 18.2901C17.9434 18.6214 17.5531 18.8747 17.1238 19.0599C16.6945 19.2353 16.2359 19.3327 15.7675 19.3327C15.2992 19.3327 14.8406 19.245 14.4113 19.0599C13.9819 18.8845 13.5917 18.6214 13.2599 18.2901C12.9282 17.9588 12.6745 17.5691 12.4891 17.1404C12.3135 16.7117 12.2159 16.2537 12.2159 15.786C12.2159 15.3183 12.3037 14.8604 12.4891 14.4317C12.6647 14.0029 12.9282 13.6132 13.2599 13.2819C13.5917 12.9506 13.9819 12.6973 14.4113 12.5122C14.8406 12.3368 15.2992 12.2394 15.7675 12.2394C16.2359 12.2394 16.6945 12.3271 17.1238 12.5122C17.5531 12.6876 17.9434 12.9506 18.2752 13.2819C18.6069 13.6132 18.8606 14.0029 19.046 14.4317C19.2216 14.8604 19.3192 15.3183 19.3192 15.786C19.3192 16.2537 19.2314 16.7117 19.046 17.1404Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.7957 20.7575L19.7975 18.7716C20.4275 17.9302 20.8114 16.903 20.8114 15.7779C20.8114 13.0093 18.5473 10.7592 15.7615 10.7592C12.9758 10.7592 10.7117 13.0093 10.7117 15.7779C10.7117 18.5466 12.9758 20.7967 15.7615 20.7967C16.8837 20.7967 17.9075 20.4151 18.7442 19.7988L20.7425 21.7848C20.8901 21.9315 21.0771 22 21.2642 22C21.4512 22 21.6382 21.9315 21.7859 21.7848C22.0714 21.5011 22.0714 21.0315 21.7859 20.7478L21.7957 20.7575ZM12.1981 15.7877C12.1981 13.8311 13.8026 12.2364 15.7714 12.2364C17.7401 12.2364 19.3447 13.8311 19.3447 15.7877C19.3447 17.7443 17.7401 19.339 15.7714 19.339C13.8026 19.339 12.1981 17.7443 12.1981 15.7877Z"
          fill="#1D1D1D"
        />
        <path
          d="M20.772 5.42736L15.9879 3.50986C15.6631 3.38268 15.3087 3.61748 15.3087 3.95989V8.72428C15.3087 8.99821 15.5351 9.20365 15.8108 9.21343C18.38 9.233 19.8664 10.8277 19.9353 10.8961C20.0731 11.0527 20.2798 11.1407 20.4865 11.1407C20.5751 11.1407 20.6637 11.1211 20.7523 11.0918C21.0378 10.9842 21.2248 10.7103 21.2248 10.407V6.1024C21.2248 5.79912 21.0378 5.53497 20.7621 5.41758L20.772 5.42736Z"
          fill="#1D1D1D"
        />
        <path
          d="M7.91608 5.34909C7.91608 5.15343 7.79796 4.96755 7.61093 4.89907L3.0139 3.05006C2.7875 2.96201 2.53156 2.99136 2.32484 3.12832C2.11812 3.26528 2 3.4903 2 3.73488V16.541C2 16.8345 2.17719 17.1084 2.46266 17.216L7.24671 19.1531C7.57155 19.2803 7.92593 19.0455 7.92593 18.7031V5.34909H7.91608Z"
          fill="#1D1D1D"
        />
        <path
          d="M13.1628 3.50986L9.70764 4.89907C9.52061 4.97733 9.40249 5.15343 9.40249 5.34909V11.4342C9.40249 11.8842 9.97342 12.0995 10.2687 11.7473C11.4204 10.3678 12.6706 9.77107 13.5073 9.50693C13.714 9.44823 13.842 9.25257 13.842 9.04712V3.95989C13.842 3.61748 13.4876 3.3729 13.1628 3.50986Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Discover;
