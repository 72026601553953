import api from 'api/index';
import actionTypes from './actions';
import { handleSelectToolInstance } from 'actions/toolSelectionHandler';

const setSelectedToolInstanceAction = (selectedToolInstance) => ({
  type: actionTypes.CHANGE_SELECTED_TOOL_INSTANCE,
  selectedToolInstance,
});

const updateToolInstancesPropsAction = (
  toolInstances,
  properties,
  options,
) => ({
  type: 'CHANGE_TOOL_INSTANCES_PROPERTIES',
  toolInstances,
  properties,
  options,
});

export const selectToolInstance =
  (selectedToolInstance) => (dispatch, getState) => {
    handleSelectToolInstance(
      selectedToolInstance,
      dispatch,
      getState,
      updateToolInstancesPropsAction,
      setSelectedToolInstanceAction,
    );
  };

export const fetchPopupInfo = () => (dispatch) =>
  api.layersApi
    .fetchPopupInfo()
    .then((data) =>
      dispatch({ type: actionTypes.FETCH_POPUP_INFO_SUCCESS, data }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.FETCH_POPUP_INFO_ERROR, error }),
    );

export const setPopupLayersData = (data) => ({
  type: actionTypes.SET_POPUP_LAYERS_DATA,
  data,
});
