import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Search = ({
  fill = colors.grayScale200,
  height = '20',
  width = '20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.1174 2.1664C9.42601 1.48387 7.53271 1.50122 5.85407 2.21463C4.17542 2.92804 2.84893 4.27906 2.1664 5.9705C1.48387 7.66194 1.50122 9.55524 2.21463 11.2339C2.92804 12.9125 4.27907 14.239 5.9705 14.9216C7.66194 15.6041 9.55524 15.5867 11.2339 14.8733C11.7894 14.6372 12.3063 14.3313 12.7741 13.9664L16.8942 18.087C17.2234 18.4163 17.7572 18.4163 18.0865 18.0871C18.4157 17.7579 18.4157 17.2242 18.0865 16.8949L13.9664 12.7741C14.357 12.2735 14.6797 11.7168 14.9216 11.1175C15.6041 9.42601 15.5867 7.53272 14.8733 5.85407C14.1599 4.17542 12.8089 2.84893 11.1174 2.1664ZM6.51354 3.76637C7.78064 3.22787 9.20976 3.21477 10.4865 3.72997C11.7633 4.24516 12.7831 5.24644 13.3216 6.51354C13.8601 7.78064 13.8732 9.20976 13.358 10.4865C13.098 11.1308 12.7142 11.7097 12.235 12.1943C12.228 12.2008 12.2211 12.2074 12.2144 12.2142C12.2075 12.221 12.2009 12.2279 12.1943 12.2349C11.7322 12.6919 11.1841 13.0625 10.5744 13.3216C9.30731 13.8601 7.87819 13.8732 6.60143 13.358C5.32468 12.8428 4.30488 11.8415 3.76637 10.5744C3.22787 9.30731 3.21477 7.87819 3.72997 6.60143C4.24516 5.32468 5.24644 4.30488 6.51354 3.76637Z"
      fill={fill}
    />
  </svg>
);

export default Search;
