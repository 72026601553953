import { actionTypes } from '../actions/helpCenter';
import settingsActionTypes from '../actions/settings/actions';

const ACTION_HANDLERS = {
  [actionTypes.OPEN_HELP_CENTER]: (state, action) => ({
    isOpen: true,
    topic: action.topic,
  }),
  [actionTypes.CLOSE_HELP_CENTER]: (state, action) => ({
    ...state,
    isOpen: false,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

// Reducer
const initialState = {
  isOpen: false,
  topic: 'home',
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
