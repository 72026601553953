import React from 'react';
import Icon from 'components/ui/DeprecatedIcon';
import uaParser from 'utils/userAgentParser';

import { GOOGLE_PLAY_URL, APPLE_STORE_URL } from 'utils/constants';
import Link from 'components/ui/DeprecatedLink';

import './styles.scss';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const MobileRedirector = () => {
  const { isAndroidPlatform, isIOSPlatform } = uaParser;

  return (
    <div className="mobile-redirector-overlay">
      <div className="mobile-redirector-overlay__container">
        <div className="mobile-redirector-overlay__container__text">
          <Icon id="landIdDark" />
          <div className="mobile-redirector-overlay__container__text__paragraph">
            Download the Mobile App to Use Land id™ on Your Phone
          </div>
        </div>
        <div className="mobile-redirector-overlay__container__paragraph">
          Land id™ allows you to discover land with the most accurate
          nationwide property data and create custom maps on your desktop &
          phone
        </div>
        <div className="mobile-redirector-overlay__container__buttons">
          {isIOSPlatform() && (
            <a className="button-bar-web" href={APPLE_STORE_URL}>
              <div style={{ marginRight: 5 }}>Download for Apple</div>
              <Icon className="button-bar-web__icon" id="applelogo" />
            </a>
          )}
          {isAndroidPlatform() && (
            <a className="button-bar-web" href={GOOGLE_PLAY_URL}>
              <div style={{ marginRight: 5 }}>Download for Android</div>
              <Icon className="button-bar-web__icon" id="playstorelogo" />
            </a>
          )}
        </div>
      </div>

      <div className="mobile-redirector-overlay__footer">
        <div className="mobile-redirector-overlay__footer__link">
          <Link to="/profile">View Account Details</Link>
          <Icon
            id="chevronBold"
            fill={colorPalette.blueLighter}
            direction="right"
            height={18}
            width={18}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileRedirector;
