export default {
  HIDE_SOILS_REPORT: 'HIDE_SOILS_REPORT',
  CREATE_SOILS_REPORT: 'CREATE_SOILS_REPORT',
  SHOW_SOILS_REPORT: 'SHOW_SOILS_REPORT',
  SELECT_POLYGON_FOR_REPORT: 'SELECT_POLYGON_FOR_REPORT',
  DESELECT_POLYGON_FOR_REPORT: 'DESELECT_POLYGON_FOR_REPORT',
  SCROLL_TO_SOIL_ROW: 'SCROLL_TO_SOIL_ROW',
  DESELECT_ALL_POLYGONS_FOR_REPORT: 'DESELECT_ALL_POLYGONS_FOR_REPORT',
  SEND_REPORT_QUERY_SUCCESS: 'SEND_REPORT_QUERY_SUCCESS',
  SEND_REPORT_QUERY_FAILURE: 'SEND_REPORT_QUERY_FAILURE',
  CHANGE_PANEL_STATE: 'CHANGE_PANEL_STATE',
  SAVE_SOILS_REPORT_SUCCESS: 'SAVE_SOILS_REPORT_SUCCESS',
  SAVE_SOILS_REPORT_FAILURE: 'SAVE_SOILS_REPORT_FAILURE',
  DELETE_SOILS_REPORT_SUCCESS: 'DELETE_SOILS_REPORT_SUCCESS',
  DELETE_SOILS_REPORT_FAILURE: 'DELETE_SOILS_REPORT_FAILURE',
  SET_MUSYM_COLOR_MAPPER: 'SET_MUSYM_COLOR_MAPPER',
  SELECT_SOIL_POLYGON: 'SELECT_SOIL_POLYGON',
  DESELECT_SOIL_POLYGON: 'DESELECT_SOIL_POLYGON',
  CHANGE_LAYER: 'CHANGE_LAYER',
  SOILS_REPORT_QUERY_IN_PROGRESS: 'SOILS_REPORT_QUERY_IN_PROGRESS',
  SOILS_REPORT_QUERY_SUCCESS: 'SOILS_REPORT_QUERY_SUCCESS',
  CLEAN_SELECTED_POLYGON_FOR_REPORT: 'CLEAN_SELECTED_POLYGON_FOR_REPORT',
  SOILS_REPORT_QUERY_READY: 'SOILS_REPORT_QUERY_READY',
  SOILS_REPORT_QUERY_REMOVE: 'SOILS_REPORT_QUERY_REMOVE',
  SOILS_REPORT_QUERY_ERROR: 'SOILS_REPORT_QUERY_ERROR',
};
