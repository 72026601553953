export const MAX_MAPBOX_ZOOM = 22;
export const LAYER_RASTER_TYPE = 'raster';
export const LAYER_TILE_SIZE = 256;
export const MAX_GOOGLE_ZOOM = 19;
export const CONTROL_ZOOM_STEP = 0.5;
export const GOOGLE_CONTROL_ZOOM_STEP = 1;
export const HEX_BACKGROUND_ID_TERRAIN = 'country-boundaries-background';
export const HEX_BACKGROUND_ID_WATER = 'water-background';
export const ROADS_VECTOR_LAYER_NAME = 'roadsVectorLayer';
export const ICON_SIZE = 152; // icons are 152x152 pixels
export const MIDDLE_SLOT = 'middle';
export const TOP_SLOT = 'top';
export const BOTTOM_SLOT = 'bottom';
