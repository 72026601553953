/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const BoldStyle = ({ fill }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3629 0H1.63706C1.20289 0 0.786494 0.170269 0.479485 0.473349C0.172476 0.77643 0 1.18749 0 1.61612V2.69289C0 2.97948 0.115322 3.25433 0.320596 3.45697C0.525869 3.65962 0.80428 3.77347 1.09458 3.77347C1.38488 3.77347 1.66329 3.65962 1.86857 3.45697C2.07384 3.25433 2.18916 2.97948 2.18916 2.69289V2.16115H4.90542V11.8388H4.08977C3.79947 11.8388 3.52106 11.9527 3.31579 12.1553C3.11051 12.358 2.99519 12.6328 2.99519 12.9194C2.99519 13.206 3.11051 13.4809 3.31579 13.6835C3.52106 13.8862 3.79947 14 4.08977 14H7.91023C8.20053 14 8.47894 13.8862 8.68421 13.6835C8.88949 13.4809 9.00481 13.206 9.00481 12.9194C9.00481 12.6328 8.88949 12.358 8.68421 12.1553C8.47894 11.9527 8.20053 11.8388 7.91023 11.8388H7.09458V2.16115H9.81084V2.69289C9.81084 2.97948 9.92616 3.25433 10.1314 3.45697C10.3367 3.65962 10.6151 3.77347 10.9054 3.77347C11.1957 3.77347 11.4741 3.65962 11.6794 3.45697C11.8847 3.25433 12 2.97948 12 2.69289V1.61612C12 1.1875 11.8275 0.77643 11.5205 0.473349C11.2135 0.170269 10.7971 0 10.3629 0Z"
      fill={fill}
    />
  </svg>
);

BoldStyle.propTypes = {
  fill: PropTypes.string,
};

BoldStyle.defaultProps = {
  fill: '#FFFFFF',
};

export default BoldStyle;
