import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const SelectedOverview = ({
  fill = colors.white,
  width = '24',
  height = '24',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.12499 5H15.5331L2.5 14.6415V5.62499C2.5 5.28333 2.78333 5 3.12499 5ZM12.6335 15.8248L9.15026 11.2749L17.6334 5H21.875C22.2167 5 22.5 5.27499 22.5 5.62499V8.29994L12.6335 15.8248ZM8.14989 12.0165L2.5 16.1997V19.3747C2.5 19.7246 2.78333 19.9996 3.12499 19.9996H14.2581L8.14989 12.0165ZM13.4039 16.8218L13.3999 16.8165V16.8249L13.4039 16.8218ZM13.4039 16.8218L15.8331 19.9998H21.8747C22.2163 19.9998 22.4997 19.7165 22.4997 19.3748V9.87499L13.4039 16.8218Z"
      fill={fill}
    />
  </svg>
);

export default SelectedOverview;
