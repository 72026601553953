export const actionTypes = {
  START_TRANSFORM: 'START_TRANSFORM',
  FINISH_TRANSFORM: 'FINISH_TRANSFORM',
};

export const startTransform = (element) => ({
  type: actionTypes.START_TRANSFORM,
  element,
});

export const finishTransform = () => ({ type: actionTypes.FINISH_TRANSFORM });

export const actions = {
  startTransform,
  finishTransform,
};
