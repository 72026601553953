import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import Icon from 'components/ui/Icon';
import type { UIIconProps } from 'components/ui/Icon/types';

import classNames from 'classnames';

import * as colors from 'components/ui/styles/colors.module.scss';
import * as styles from './styles.module.scss';

const COPY_MESSAGE_TIME = 1500;

type InputCombinations =
  | {
      type?: 'password';
      theme: 'light' | 'dark';
      readOnly?: false;
      copyable?: false;
      cleanable?: false;
      rightIcon?: null;
      rightIconProps?: null;
    }
  | {
      type?: 'text' | 'email';
      theme: 'light' | 'dark';
    };

type AllowedIconProps = PickDefined<UIIconProps, 'id' | 'theme' | 'style'>;
type LeftIconProps =
  | { leftIcon?: UIIconProps['id']; leftIconProps?: null }
  | { leftIcon?: null; leftIconProps?: AllowedIconProps };

type RightIconProps =
  | { rightIcon?: UIIconProps['id']; rightIconProps?: null }
  | { rightIcon?: null; rightIconProps?: AllowedIconProps };

type InputProps = InputCombinations &
  LeftIconProps &
  RightIconProps & {
    label?: string;
    value: string;
    placeholder?: string;
    type?: 'text' | 'number' | 'password' | 'email';
    name?: string;
    theme?: 'light' | 'dark';
    readOnly?: boolean;
    copyable?: boolean;
    cleanable?: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    onClean?: () => void;
    onFocus?: () => void;
    errors?: Record<string, string | undefined>;
    focusOnMount?: boolean;
  };

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label = '',
      value = '',
      placeholder = '',
      type = 'text',
      name = '',
      leftIcon = null,
      leftIconProps = null,
      rightIcon = null,
      rightIconProps = null,
      theme = 'light',
      readOnly = false,
      copyable = false,
      cleanable = false,
      errors = {},
      onChange,
      onKeyDown,
      onClean,
      onFocus,
      focusOnMount = false,
    },
    ref,
  ) => {
    const [message, setMessage] = useState('Copy');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    useEffect(() => {
      if (focusOnMount) {
        inputRef.current?.focus();
      }
    }, [focusOnMount]);

    const copyHandler = () => {
      setMessage('Copied!');
      setTimeout(() => setMessage('Copy'), COPY_MESSAGE_TIME);
    };

    const error = errors[name];

    const handleClean = () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.value = '';
      }

      onClean && onClean();
    };

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };

    const iconFillColors: Record<string, (readOnly: boolean) => string> = {
      light: (readOnly) =>
        error ? colors.fail700
        : readOnly ? colors.grayScale100
        : colors.black,

      dark: (readOnly) =>
        error ? colors.fail100
        : readOnly ? colors.grayScale100
        : colors.white,
    };

    const fill = iconFillColors[theme]?.(readOnly) ?? colors.black;

    const leftIconBaseProps: UIIconProps | null =
      leftIcon ? ({ id: leftIcon } as UIIconProps) : leftIconProps;
    const rightIconBaseProps: UIIconProps | null =
      rightIcon ? ({ id: rightIcon } as UIIconProps) : rightIconProps;

    return (
      <div className={styles.container}>
        {label && (
          <label className={classNames(styles[theme], styles.label)}>
            {label}
          </label>
        )}

        <div
          className={classNames(styles.wrapper, styles[theme], {
            [styles.error]: error,
            [styles.copyable]: copyable,
            [styles.cleanable]: cleanable,
            [styles.readOnly]: readOnly,
          })}
        >
          {leftIconBaseProps && (
            <div className={styles.leftIcon}>
              <Icon
                {...leftIconBaseProps}
                color={fill}
                height={20}
                width={20}
              />
            </div>
          )}

          <input
            ref={inputRef}
            type={type === 'password' && passwordVisible ? 'text' : type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
            onFocus={onFocus}
            className={classNames({
              [styles.error]: error,
              [styles.hasLeftIcon]: leftIconBaseProps,
              [styles.hasRightIcon]:
                cleanable || copyable || rightIconBaseProps,
            })}
          />

          {copyable && (
            <CopyToClipboard text={value} onCopy={copyHandler}>
              <button className={styles.copyButton}>{message}</button>
            </CopyToClipboard>
          )}

          {value && cleanable && !readOnly && (
            <div
              className={styles.cleanIcon}
              onClick={handleClean}
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon
                height={18}
                width={18}
                id="close"
                color={
                  theme === 'dark' && type === 'text' ? colors.white
                  : error ?
                    colors.black
                  : fill
                }
              />
            </div>
          )}

          {type === 'password' && !copyable && !cleanable && (
            <div
              className={styles.rightIcon}
              onClick={togglePasswordVisibility}
            >
              <Icon
                height="20"
                width="20"
                id={passwordVisible ? 'eye' : 'eyeSlash'}
                color={error ? colors.black : fill}
              />
            </div>
          )}

          {rightIconBaseProps && !copyable && !cleanable && (
            <div className={styles.rightIcon}>
              <Icon
                {...rightIconBaseProps}
                height={20}
                width={20}
                color={
                  theme === 'dark' && type === 'text' ? colors.white
                  : error ?
                    colors.black
                  : fill
                }
              />
            </div>
          )}
        </div>

        {error && (
          <span
            className={classNames(styles.errors, styles[theme], {
              [styles.error]: error,
            })}
          >
            {error}
          </span>
        )}
      </div>
    );
  },
);

export { InputProps };
export default Input;
