import React from 'react';
import classNames from 'classnames';
import type { Color } from '../types';

import * as colors from '../styles/colors.module.scss';
import * as styles from './styles.module.scss';

type DisplayTypes = 'DisplayLarge' | 'DisplayRegular';
type HeaderTypes =
  | 'Header1'
  | 'Header2'
  | 'Header3b'
  | 'Header3r'
  | 'Header3bi'
  | 'Header4b'
  | 'Header4r'
  | 'Header5b'
  | 'Header5r';
type ParagraphTypes =
  | 'ParagraphLarge'
  | 'ParagraphRegular'
  | 'ParagraphSemiBold'
  | 'ParagraphBold'
  | 'ParagraphSmall'
  | 'ParagraphSmallBold'
  | 'ParagraphSmallBoldItalic';
type LinkTypes =
  | 'LinkNormal'
  | 'LinkPrimary'
  | 'LinkSmallBold'
  | 'LinkSmallRegular';
type LabelTypes =
  | 'LabelRegular'
  | 'LabelBold'
  | 'LabelOptional'
  | 'LabelOptionalBold'
  | 'LabelPlaceholder'
  | 'LabelDescription';
type ButtonTypes = 'Button1' | 'Button2' | 'Button3';
type MetadataTypes = 'MetadataRegular' | 'MetadataBold';

export type Type =
  | DisplayTypes
  | HeaderTypes
  | ParagraphTypes
  | LinkTypes
  | LabelTypes
  | ButtonTypes
  | MetadataTypes;

type TypographyProps = {
  children: React.ReactNode;
  type?: Type;
  desktopType?: Type;
  laptopType?: Type;
  tabletType?: Type;
  phoneType?: Type;
  ellipsis?: boolean;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  color?: Color;
};

const TYPE_TO_COMPONENT: Record<
  Type,
  'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
> = {
  DisplayLarge: 'h1',
  DisplayRegular: 'h1',
  Header1: 'h1',
  Header2: 'h2',
  Header3b: 'h3',
  Header3r: 'h3',
  Header3bi: 'h3',
  Header4b: 'h4',
  Header4r: 'h4',
  Header5b: 'h5',
  Header5r: 'h5',
  ParagraphLarge: 'p',
  ParagraphRegular: 'p',
  ParagraphSemiBold: 'p',
  ParagraphBold: 'p',
  ParagraphSmall: 'p',
  ParagraphSmallBold: 'p',
  ParagraphSmallBoldItalic: 'p',
  LinkNormal: 'span',
  LinkPrimary: 'span',
  LinkSmallBold: 'span',
  LinkSmallRegular: 'span',
  LabelRegular: 'span',
  LabelBold: 'span',
  LabelOptional: 'span',
  LabelOptionalBold: 'span',
  LabelPlaceholder: 'span',
  LabelDescription: 'span',
  Button1: 'span',
  Button2: 'span',
  Button3: 'span',
  MetadataRegular: 'span',
  MetadataBold: 'span',
};

const Typography: React.FC<TypographyProps> = ({
  children,
  type = 'ParagraphRegular' as Type,
  desktopType = null,
  laptopType = null,
  tabletType = null,
  phoneType = null,
  ellipsis = false,
  component,
  color = null,
}) => {
  const Component = component || TYPE_TO_COMPONENT[type];

  return (
    <Component
      className={classNames(
        styles.base,
        styles[type],
        desktopType && styles[`desktop${desktopType}`],
        laptopType && styles[`laptop${laptopType}`],
        tabletType && styles[`tablet${tabletType}`],
        phoneType && styles[`phone${phoneType}`],
        ellipsis && styles.ellipsis,
      )}
      style={color ? { color: colors[color] } : undefined}
    >
      {children}
    </Component>
  );
};

export { Typography, TypographyProps };
export default Typography;
