import {
  selectSettings,
  selectSettingsEnabledLayers,
} from 'api/endpoints/settings/settingsSelectors';
import { selectOverlays } from 'api/endpoints/overlays/overlaysSelectors';
import { chain, pickBy } from 'lodash';
import { createSelector } from 'reselect';
import us from 'us';

export const getBaseLayers = (state) =>
  selectSettingsEnabledLayers()(state) || {};

export const getBaseLayerKeys = createSelector([getBaseLayers], (baseLayers) =>
  Object.values(baseLayers)
    ?.filter((lyr) => lyr.enabled)
    ?.map((lyr) => ({ name: lyr.name, key: lyr.key })),
);

export const getBaseLayerSelector = createSelector(
  [getBaseLayers],
  (baseMapLayers) => baseMapLayers,
);

export const getDefaultBaseLayerForMapCreation = (state, from) =>
  selectSettings(from)(state)?.configuration?.base_layers
    ?.default_base_layer_for_map_creation;

export const selectDefaultBaseLayerForMapCreation = createSelector(
  [getDefaultBaseLayerForMapCreation],
  (defaultBaseLayer) => defaultBaseLayer,
);

export const getDefaultBaseLayerForDiscover = (state) =>
  selectSettings('discover')(state)?.configuration?.base_layers
    ?.default_base_layer_for_home_map;

export const getEnabledLayers = (state) => {
  const { custom_layers: customLayers } = state.map || {};

  return customLayers;
};

const selectMap = (state) => state.map;
const selectMapState = (state) => state.map?.state;

const selectStateAbbr = createSelector(
  [selectMapState],
  (state) => state && us.lookup(state)?.abbr,
);

export const getMapSelector = createSelector([selectMap], (map) => map);

export const selectStateLayers = createSelector(
  [selectStateAbbr, selectOverlays],
  (stateAbbr, overlays) => {
    return stateAbbr && overlays.layers[stateAbbr.toLowerCase()];
  },
);

export const selectStateLayersInCategory = createSelector(
  [selectStateLayers, (state, element) => element],
  (stateLayers, element) =>
    pickBy(stateLayers, (el) => element.overlays.some((o) => o.id === el.id)),
);

const selectCustomLayers = (state) => state.map.custom_layers;
const selectSharedEnabledLayers = (state) => state.map.shared_enabled_layers;

export const getSelectedCategorizations = (useSharedEnabledLayers = false) =>
  createSelector(
    [
      selectMapState,
      selectCustomLayers,
      selectSharedEnabledLayers,
      selectStateLayers,
    ],
    (state, customLayers, sharedEnabledLayers, stateOverlays) => {
      const layersToUse =
        useSharedEnabledLayers ? sharedEnabledLayers : customLayers;

      return (
        state &&
        chain(layersToUse || [])
          .map((layer) => stateOverlays[layer]?.categorization)
          .compact()
          .uniq()
          .value()
      );
    },
  );
