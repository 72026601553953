// extracted by mini-css-extract-plugin
var _1 = "#1d1d1d";
var _2 = "styles-module__cleanIcon___AjrwV";
var _3 = "styles-module__cleanable___vPz62";
var _4 = "styles-module__container___dAxSs";
var _5 = "styles-module__copyButton___XyfcK";
var _6 = "styles-module__copyable___IURlE";
var _7 = "styles-module__dark___Mlohu";
var _8 = "styles-module__error___OziFl";
var _9 = "styles-module__errors___HgCNP";
var _a = "#fdbaba";
var _b = "#ff7384";
var _c = "#fb6a6a";
var _d = "#f92121";
var _e = "#b11717";
var _f = "#a50d1f";
var _10 = "#3c1212";
var _11 = "#fafafa";
var _12 = "#b9b9b9";
var _13 = "#979797";
var _14 = "#686868";
var _15 = "#4a4a4a";
var _16 = "#e8e8e8";
var _17 = "#282828";
var _18 = "#1d1d1d";
var _19 = "styles-module__hasLeftIcon___NqRz0";
var _1a = "styles-module__hasRightIcon___zDjYm";
var _1b = "styles-module__label___p8J6Z";
var _1c = "styles-module__leftIcon___gICFr";
var _1d = "styles-module__light___vodRQ";
var _1e = "#6ba6ff";
var _1f = "#0065ff";
var _20 = "#003e9c";
var _21 = "#f4f3f1";
var _22 = "#ebe5e1";
var _23 = "#dab2a2";
var _24 = "styles-module__readOnly___cM9CH";
var _25 = "#f9213b";
var _26 = "styles-module__rightIcon___auOzL";
var _27 = "#c9f2c8";
var _28 = "#8be489";
var _29 = "#52d64f";
var _2a = "#3a9838";
var _2b = "#fde7ba";
var _2c = "#fbc64a";
var _2d = "#f9b021";
var _2e = "#fff";
var _2f = "styles-module__wrapper___PM08a";
export { _1 as "black", _2 as "cleanIcon", _3 as "cleanable", _4 as "container", _5 as "copyButton", _6 as "copyable", _7 as "dark", _8 as "error", _9 as "errors", _a as "fail100", _b as "fail200", _c as "fail300", _d as "fail500", _e as "fail700", _f as "fail800", _10 as "fail900", _11 as "grayScale00", _12 as "grayScale100", _13 as "grayScale200", _14 as "grayScale300", _15 as "grayScale400", _16 as "grayScale50", _17 as "grayScale500", _18 as "grayScale700", _19 as "hasLeftIcon", _1a as "hasRightIcon", _1b as "label", _1c as "leftIcon", _1d as "light", _1e as "links100", _1f as "links300", _20 as "links500", _21 as "marketingSecondary100", _22 as "marketingSecondary300", _23 as "marketingSecondary500", _24 as "readOnly", _25 as "red", _26 as "rightIcon", _27 as "success100", _28 as "success300", _29 as "success500", _2a as "success700", _2b as "warning100", _2c as "warning300", _2d as "warning500", _2e as "white", _2f as "wrapper" }
