import actionTypes from './actions';
import api from 'api/index';
import { geomTypesOnGeoJSON } from 'utils/geoJSONElements';

export const loadDashboard = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.LOAD_DASHBOARD_START });
  api
    .loadDashboard()
    .then((data) =>
      dispatch({ type: actionTypes.LOAD_DASHBOARD_SUCCESS, data }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.LOAD_DASHBOARD_FAILURE, error }),
    );
};

export const loadFolders = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.LOAD_FOLDERS_START });
  api
    .loadFolders()
    .then((data) => dispatch({ type: actionTypes.LOAD_FOLDERS_SUCCESS, data }))
    .catch((error) =>
      dispatch({ type: actionTypes.LOAD_FOLDERS_FAILURE, error }),
    );
};

export const createFolder = (name, parentFolder) => (dispatch, getState) => {
  api
    .createFolder(name, parentFolder)
    .then((data) => {
      dispatch({ type: actionTypes.CREATE_FOLDER_SUCCESS, data });
      dispatch({
        type: actionTypes.TOGGLE_OPEN_FOLDER,
        folder: data,
        value: true,
      });
    })
    .catch((error) =>
      dispatch({ type: actionTypes.CREATE_FOLDER_FAILURE, error }),
    );
};

export const deleteFolder = (folder) => (dispatch, getState) => {
  api
    .deleteFolder(folder)
    .then((data) => dispatch({ type: actionTypes.DELETE_FOLDER_SUCCESS, data }))
    .catch((error) =>
      dispatch({ type: actionTypes.DELETE_FOLDER_FAILURE, error }),
    );
};

export const emptyFolder = (folder) => (dispatch, getState) => {
  api
    .emptyFolder(
      folder.id,
      folder.maps.map((folder) => folder.id),
    )
    .then((data) =>
      dispatch({
        type: actionTypes.EMPTY_FOLDER_SUCCESS,
        data,
        map: folder.maps,
      }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.EMPTY_FOLDER_FAILURE, error }),
    );
};

export const moveMapToFolder = (folderId, map) => (dispatch, getState) => {
  api
    .moveMapToFolder(folderId, map.id)
    .then((data) =>
      dispatch({ type: actionTypes.MOVE_MAP_TO_FOLDER_SUCCESS, data, map }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.MOVE_MAP_TO_MAP_FAILURE, error }),
    );
};

export const moveMapsToFolder = (folderId, maps) => {
  const mapIds = maps.map((map) => map.id);

  return (dispatch, getState) => {
    api
      .moveMapToFolder(folderId, mapIds)
      .then((data) =>
        dispatch({ type: actionTypes.MOVE_MAPS_TO_FOLDER_SUCCESS, data, maps }),
      )
      .catch((error) =>
        dispatch({ type: actionTypes.MOVE_MAPS_TO_FOLDER_FAILURE, error }),
      );
  };
};

export const removeMapFromFolder = (map) => (dispatch, getState) => {
  api
    .emptyFolder(map.folder_id, map.id)
    .then((data) =>
      dispatch({ type: actionTypes.REMOVE_MAP_FROM_FOLDER_SUCCESS, data, map }),
    )
    .catch((error) =>
      dispatch({ type: actionTypes.REMOVE_MAP_FROM_FOLDER_FAILURE, error }),
    );
};

export const deleteMap = (map) => (dispatch, getState) => {
  return api
    .deleteMap(map)
    .then((data) => dispatch({ type: actionTypes.DELETE_MAP_SUCCESS, data }))
    .catch((error) =>
      dispatch({ type: actionTypes.DELETE_MAP_FAILURE, error }),
    );
};

const createErrorObject = (detail) => ({
  json: {
    errors: [
      {
        detail: [detail],
        title: detail,
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
      },
    ],
  },
});

export const downloadMapAs = (mapId, fileType) => () =>
  new Promise((resolve, reject) => {
    api
      .loadMap(mapId, { export: true })
      .then((data) => {
        if (fileType === 'kml') {
          api
            .downloadMapAs(data, fileType)
            .then((data) => resolve([[data, '']]))
            .catch((error) => reject(error));
        } else {
          const geomTypes = geomTypesOnGeoJSON(data.geoJSON);

          if (geomTypes.length === 0) {
            reject(createErrorObject('Map is empty'));
          }

          Promise.all(
            geomTypes.map((geomType) =>
              api.downloadMapAs(data, fileType, geomType),
            ),
          )
            .then((values) =>
              resolve(values.map((value, index) => [value, geomTypes[index]])),
            )
            .catch((error) => reject(error));
        }
      })
      .catch((error) => reject(error));
  });

export const mergeMap =
  (map, mapToMerge, layer, folderId, SEOEnabled, name, description, state) =>
  (dispatch) => {
    api
      .mergeMap(
        map,
        mapToMerge,
        layer,
        folderId,
        SEOEnabled,
        name,
        description,
        state,
      )
      .then((data) => {
        handleMergeMapRequest(dispatch, map.id, mapToMerge.id);
      });
  };

const handleMergeMapRequest = (dispatch, id1, id2) => {
  dispatch({ type: actionTypes.MERGE_MAP_START });

  const interval = setInterval(() => {
    api
      .checkMergeStatus(id1, id2)
      .then((data) => {
        if (data.status === 'finished') {
          clearInterval(interval);
          dispatch({ type: actionTypes.MERGE_MAP_SUCCESS, data });
        } else if (data.status === 'error' || data.status === 'not_found') {
          clearInterval(interval);
          dispatch({ type: actionTypes.MERGE_MAP_FAILURE });
        }
      })
      .catch(() => {
        clearInterval(interval);
        dispatch({ type: actionTypes.MERGE_MAP_FAILURE });
      });
  }, 5000);
};

export const toggleOpenFolder = (value, folder) => (dispatch, getState) => {
  dispatch({ type: actionTypes.TOGGLE_OPEN_FOLDER, folder, value });
};

export const actions = {
  loadDashboard,
  moveMapsToFolder,
  moveMapToFolder,
  loadFolders,
  deleteMap,
  createFolder,
  removeMapFromFolder,
  deleteFolder,
  downloadMapAs,
  toggleOpenFolder,
};
