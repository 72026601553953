import { PlanEntitlement } from './types';

export const checkQuota = (
  entitlement: PlanEntitlement | undefined,
  currentValue: number = 1, // default to 1 for non-numeric quotas (like map branding)
) => {
  if (!entitlement) {
    return false;
  }

  // unlimited
  if (entitlement.quota === -1) {
    return true;
  }

  return entitlement.quota > currentValue;
};
