import { NonConfigurableIconProps } from '../types';

const Line = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6 3.75C17.6887 3.75 16.95 4.48873 16.95 5.4C16.95 6.31127 17.6887 7.05 18.6 7.05C19.5113 7.05 20.25 6.31127 20.25 5.4C20.25 4.48873 19.5113 3.75 18.6 3.75ZM15.45 5.4C15.45 3.6603 16.8603 2.25 18.6 2.25C20.3397 2.25 21.75 3.6603 21.75 5.4C21.75 7.1397 20.3397 8.55 18.6 8.55C18.0044 8.55 17.4473 8.38468 16.9723 8.09743L8.12227 17.0142C8.39419 17.4799 8.55 18.0218 8.55 18.6C8.55 20.3397 7.1397 21.75 5.4 21.75C3.6603 21.75 2.25 20.3397 2.25 18.6C2.25 16.8603 3.6603 15.45 5.4 15.45C6.01765 15.45 6.59378 15.6278 7.08001 15.9349L15.9094 7.039C15.6179 6.56151 15.45 6.00037 15.45 5.4ZM6.58058 17.4473C6.28096 17.1405 5.86273 16.95 5.4 16.95C4.48873 16.95 3.75 17.6887 3.75 18.6C3.75 19.5113 4.48873 20.25 5.4 20.25C6.31127 20.25 7.05 19.5113 7.05 18.6C7.05 18.1649 6.88154 17.769 6.60629 17.4742C6.59745 17.4654 6.58888 17.4564 6.58058 17.4473Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Line;
