export const actionTypes = {
  START_DRAWING_CONTROL_POINT: 'START_DRAWING_CONTROL_POINT',
  STOP_DRAWING_CONTROL_POINT: 'STOP_DRAWING_CONTROL_POINT',
  CHANGE_STATUS: 'CHANGE_GEOREF_STATUS',
};

export const startDrawingControlPoint = (maprightId) => ({
  type: actionTypes.START_DRAWING_CONTROL_POINT,
  maprightId,
});

export const stopDrawingControlPoint = () => ({
  type: actionTypes.STOP_DRAWING_CONTROL_POINT,
});

export const changeStatus = (status) => ({
  type: actionTypes.CHANGE_STATUS,
  status,
});

export const actions = {
  startDrawingControlPoint,
  stopDrawingControlPoint,
  changeStatus,
};
