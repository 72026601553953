import { StyleableOnlyIconProps } from '../types';
import * as colors from '../../styles/colors.module.scss';

const ChevronRight = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colors.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.34551 19.3284C5.94286 19.6579 5.88353 20.2516 6.21295 20.6543C6.54241 21.0571 7.13597 21.1165 7.53868 20.7869L16.8161 13.1937C17.728 12.4473 17.728 11.0526 16.8161 10.3063L7.53868 2.71304C7.13597 2.38344 6.54241 2.44283 6.21295 2.84565C5.88353 3.24841 5.94286 3.84204 6.34551 4.1716L15.6048 11.75L6.34551 19.3284Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.201 12.0308C17.201 12.5547 16.9765 13.0036 16.6023 13.303L8.29594 20.7114C7.69728 21.1604 6.79929 21.0855 6.3503 20.4869C5.90131 19.8882 5.90131 19.0651 6.49996 18.6161L13.8335 12.1805C13.9083 12.1057 13.9083 12.0308 13.8335 11.8812L6.49996 5.4456C5.9013 4.92178 5.82647 4.09862 6.3503 3.49996C6.87412 2.90131 7.69728 2.82647 8.29593 3.3503C8.29593 3.3503 8.29593 3.3503 8.37077 3.42513L16.6771 10.7587C16.9765 11.058 17.201 11.5818 17.201 12.0308Z"
          fill={color}
        />
      </svg>;
};

export default ChevronRight;
