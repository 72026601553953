export default {
  SHOW_LOCATION_MARKER: 'SHOW_LOCATION_MARKER',
  HIDE_LOCATION_MARKER: 'HIDE_LOCATION_MARKER',
  SEARCH_ABSTRACT_SUCCESS: 'SEARCH_ABSTRACT_SUCCESS',
  SEARCH_ABSTRACT_FAILURE: 'SEARCH_ABSTRACT_FAILURE',
  SEARCHING_ABSTRACT: 'SEARCHING_ABSTRACT',
  SEARCH_TRS_SUCCESS: 'SEARCH_TRS_SUCCESS',
  SEARCH_TRS_FAILURE: 'SEARCH_TRS_FAILURE',
  SEARCHING_TRS: 'SEARCHING_TRS',
  SEARCH_APN_SUCCESS: 'SEARCH_APN_SUCCESS',
  SEARCH_APN_FAILURE: 'SEARCH_APN_FAILURE',
  SEARCHING_APN: 'SEARCHING_APN',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  CLEAR_ALERTS: 'CLEAR_ALERTS',
  SEARCH_VIEWS_LAYERS_FEATURES_CHANGE: 'SEARCH_VIEWS_LAYERS_FEATURES_CHANGE',
  LOAD_PARCEL_SUGGESTIONS_SUCCESS: 'LOAD_PARCEL_SUGGESTIONS_SUCCESS',
  LOAD_PARCEL_SUGGESTIONS_FAILURE: 'LOAD_PARCEL_SUGGESTIONS_FAILURE',
  SEARCH_PARCELS_FAILURE: 'SEARCH_PARCELS_FAILURE',
  SEARCHING_PARCELS: 'SEARCHING_PARCELS',
  SEARCH_PARCELS_SUCCESS: 'SEARCH_PARCELS_SUCCESS',
};
