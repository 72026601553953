import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { routingLink } from './routing';
import { authLink } from './auth';

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const conditionalRetryLink = new ApolloLink((operation, forward) => {
  const { retry } = operation.getContext();

  return split(() => retry, retryLink.concat(routingLink), routingLink).request(
    operation,
    forward,
  );
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, conditionalRetryLink]),
});

export default client;
