import actionTypes from 'actions/exportParcelCsv/actions';

const ACTION_HANDLERS = {
  [actionTypes.STEP]: (state, action) => ({
    ...state,
    step: action.step,
  }),
  [actionTypes.TEXT_COLLAPSED]: (state, action) => ({
    ...state,
    textCollapsed: action.isCollapsed,
  }),
};

const initialState = {
  step: null,
  textCollapsed: false,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
