import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  fetchUserSubscription,
  fetchAllUserSubscriptions,
} from 'actions/subscriptions';
import store from 'store';
import { getUser } from 'actions/user';
import {
  subscriptionExpired,
  isFreePlan,
  isEmailVerified,
  getSubscriptionPlan,
} from 'selectors/userSelectors';
import {
  EMAIL_VERIFICATION_ROUTE,
  PROFILE_ROUTE,
  DASHBOARD_ROUTE,
  DISCOVER_ROUTE,
  PLANS_ROUTE,
  isAuthenticated,
  REPORTS_ROUTE,
} from 'utils/routes';
import { INDIVIDUAL_TEAM } from 'utils/constants';
import { chooseAccount } from '../utils/needsToChooseAccount';
import { useFetchUserTeamsQuery } from '../endpoints/teams';

import EllipseSpinner from 'components/EllipseSpinner';

export const getRedirectPath = async (
  user,
  subscriptions,
  selectedAccount,
  pathname,
) => {
  const emailVerified = isEmailVerified(user.user);
  const subscriptionPlan = getSubscriptionPlan(user.user);

  if (!pathname.includes('/impersonate_user')) {
    await isAuthenticated();
  }

  if (!user.authenticated) {
    return null;
  }

  if (!emailVerified) {
    return EMAIL_VERIFICATION_ROUTE;
  }

  if (pathname === EMAIL_VERIFICATION_ROUTE) {
    return subscriptionPlan?.includes('discover') ? DISCOVER_ROUTE : (
        DASHBOARD_ROUTE
      );
  }

  const expiredSubscription = subscriptionExpired(subscriptions);
  const isFree = isFreePlan(subscriptions);

  const allowedPaths = [
    DASHBOARD_ROUTE,
    PROFILE_ROUTE,
    DISCOVER_ROUTE,
    REPORTS_ROUTE,
  ];

  const shouldRedirect =
    (isFree || expiredSubscription) &&
    !allowedPaths.some((path) => pathname.includes(path));

  if (selectedAccount.type === INDIVIDUAL_TEAM) {
    if (shouldRedirect) {
      return PLANS_ROUTE;
    }
  }

  if (shouldRedirect) {
    return PROFILE_ROUTE;
  }

  return null; // No redirect needed
};

const withRedirectRules = (WrappedComponent) => (props) => {
  const location = useLocation();
  const { pathname } = location;
  const [isReady, setIsReady] = useState(false);
  const [accountProcessed, setAccountProcessed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const subscriptions = useSelector((state) => state.subscriptions);
  const selectedAccount = useSelector((state) => state.user.selectedAccount);
  const allUserSubscription = useSelector(
    (state) => state.subscriptions.allUserSubscription,
  );

  const { data } = useFetchUserTeamsQuery({}, { skip: !user.user?.id });
  const teams = data?.teams || [];

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (user.user) {
      dispatch(fetchAllUserSubscriptions());
    }
  }, [user.user]);

  useEffect(() => {
    if (user?.user && !isEmailVerified(user.user)) {
      setAccountProcessed(true);
    } else if (allUserSubscription && teams) {
      chooseAccount(allUserSubscription, selectedAccount, store, teams).then(
        () => {
          if (selectedAccount) {
            dispatch(fetchUserSubscription()).then(() =>
              setAccountProcessed(true),
            );
          }
        },
      );
    }
  }, [allUserSubscription, selectedAccount, teams, user.user]);

  useEffect(() => {
    const checkRedirect = async () => {
      const redirectPath = await getRedirectPath(
        user,
        subscriptions,
        selectedAccount,
        pathname,
      );

      if (redirectPath) {
        navigate(redirectPath);
      }

      setIsReady(true);
    };

    if (accountProcessed) checkRedirect();
  }, [accountProcessed, user.user, subscriptions]);

  return isReady ? <WrappedComponent {...props} /> : <EllipseSpinner />;
};

export default withRedirectRules;
