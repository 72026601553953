import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Brand = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7089 3.52384C16.3335 1.90719 19.6628 4.03081 19.1735 7.20421C20.6335 7.61346 21.6168 8.51132 21.9191 10.0248C22.222 11.5396 21.6537 12.744 20.4669 13.6873C21.2102 15.007 21.262 16.3446 20.4102 17.6216C19.5597 18.8966 18.3059 19.3567 16.7925 19.1756C16.3878 20.6372 15.4793 21.6161 13.9698 21.9197C12.4561 22.224 11.2508 21.6495 10.3132 20.4632C8.98416 21.2052 7.64897 21.2594 6.36983 20.4004C5.09717 19.5459 4.64454 18.2929 4.8195 16.7895C3.35601 16.3805 2.37495 15.4717 2.07816 13.9624C1.78268 12.4595 2.33704 11.2477 3.53647 10.3113C3.51042 10.2591 3.48359 10.2069 3.45668 10.1545C3.39625 10.037 3.33539 9.91855 3.28181 9.79705C3.02391 9.21219 2.91375 8.59654 3.00123 7.96534C3.19336 6.5782 3.93369 5.58669 5.22871 5.04006C5.8294 4.78668 6.46379 4.7128 7.11437 4.82427C7.15196 4.83075 7.23133 4.77501 7.24138 4.73581C7.61721 3.25664 8.79851 2.19709 10.3336 2.02535C11.3286 1.91389 12.24 2.1676 13.0092 2.83314C13.1742 2.97597 13.327 3.13333 13.4837 3.29474C13.5573 3.37058 13.6318 3.44731 13.7089 3.52384ZM15.2089 8.50871C15.3882 8.47923 15.5713 8.52604 15.7182 8.63894C15.7917 8.69597 15.8537 8.76788 15.9007 8.85052C15.9478 8.93315 15.9788 9.02485 15.9921 9.12031C16.0054 9.21576 16.0007 9.31307 15.9783 9.40658C15.9558 9.50009 15.916 9.58794 15.8613 9.66504L12.0097 15.2036C11.9453 15.2938 11.8622 15.367 11.7668 15.4176C11.6713 15.4681 11.5662 15.4947 11.4595 15.4951C11.305 15.5148 11.1489 15.4755 11.0193 15.3844L8.26815 13.0523C8.12222 12.9317 8.02748 12.7546 8.00478 12.56C7.98208 12.3654 8.03328 12.1692 8.1471 12.0146C8.26092 11.8599 8.42806 11.7596 8.61173 11.7355C8.7954 11.7115 8.98057 11.7657 9.1265 11.8863L11.3274 13.7461L14.7608 8.79635C14.8685 8.64159 15.0296 8.5382 15.2089 8.50871Z"
        fill={color}
      />
    </svg>
  );
};

export default Brand;
