/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Rotate = ({ fill }) => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 18.3367C6.56522 18.3367 4.86953 17.6344 3.61929 16.3841C2.36905 15.1339 1.66667 13.4382 1.66667 11.6701C1.66667 9.90197 2.36905 8.20627 3.61929 6.95603C4.86953 5.70579 6.56522 5.00341 8.33333 5.00341C8.37296 5.00331 8.41173 5.01499 8.44471 5.03696C8.47768 5.05893 8.5034 5.0902 8.51857 5.12681C8.53375 5.16341 8.53771 5.2037 8.52995 5.24256C8.5222 5.28142 8.50307 5.3171 8.475 5.34508L6.90833 6.91174C6.83023 6.98921 6.76823 7.08138 6.72592 7.18293C6.68362 7.28448 6.66183 7.3934 6.66183 7.50341C6.66183 7.61342 6.68362 7.72234 6.72592 7.82389C6.76823 7.92544 6.83023 8.01761 6.90833 8.09508C6.9862 8.17231 7.07855 8.23341 7.18008 8.27489C7.28161 8.31636 7.39033 8.33738 7.5 8.33674C7.60967 8.33738 7.71839 8.31636 7.81992 8.27489C7.92145 8.23341 8.0138 8.17231 8.09167 8.09508L11.425 4.76174C11.5031 4.68427 11.5651 4.59211 11.6074 4.49056C11.6497 4.38901 11.6715 4.28009 11.6715 4.17008C11.6715 4.06007 11.6497 3.95115 11.6074 3.8496C11.5651 3.74805 11.5031 3.65588 11.425 3.57841L8.09167 0.245076C8.01397 0.167378 7.92173 0.105744 7.82021 0.0636933C7.71869 0.021643 7.60988 1.63738e-09 7.5 0C7.39012 -1.63738e-09 7.28131 0.021643 7.17979 0.0636933C7.07827 0.105744 6.98603 0.167378 6.90833 0.245076C6.83063 0.322775 6.769 0.415017 6.72695 0.516535C6.6849 0.618054 6.66326 0.72686 6.66326 0.836743C6.66326 0.946626 6.6849 1.05543 6.72695 1.15695C6.769 1.25847 6.83063 1.35071 6.90833 1.42841L8.475 2.99508C8.50307 3.02305 8.5222 3.05873 8.52995 3.09759C8.53771 3.13645 8.53375 3.17674 8.51857 3.21335C8.5034 3.24995 8.47768 3.28122 8.44471 3.30319C8.41173 3.32516 8.37296 3.33684 8.33333 3.33674C6.1232 3.33674 4.00358 4.21472 2.44078 5.77752C0.877974 7.34032 0 9.45994 0 11.6701C0 13.8802 0.877974 15.9998 2.44078 17.5626C4.00358 19.1254 6.1232 20.0034 8.33333 20.0034C8.55435 20.0034 8.76631 19.9156 8.92259 19.7593C9.07887 19.6031 9.16667 19.3911 9.16667 19.1701C9.16667 18.9491 9.07887 18.7371 8.92259 18.5808C8.76631 18.4245 8.55435 18.3367 8.33333 18.3367Z"
      fill={fill}
    />
    <path
      d="M12.3515 16.9952C11.9054 17.3287 11.4215 17.6085 10.9099 17.8286C10.7066 17.9149 10.546 18.0784 10.4632 18.283C10.3804 18.4877 10.3822 18.7169 10.4682 18.9202C10.5102 19.0216 10.5717 19.1137 10.6493 19.1912C10.727 19.2687 10.8191 19.3301 10.9206 19.372C11.022 19.4138 11.1307 19.4351 11.2404 19.4348C11.3501 19.4345 11.4587 19.4126 11.5599 19.3702C12.1977 19.1015 12.7995 18.7544 13.3515 18.3369C13.4391 18.2713 13.5128 18.189 13.5686 18.0948C13.6243 18.0007 13.661 17.8964 13.6765 17.7881C13.692 17.6798 13.6859 17.5694 13.6588 17.4634C13.6316 17.3574 13.5839 17.2578 13.5182 17.1702C13.4525 17.0827 13.3703 17.0089 13.2761 16.9532C13.1819 16.8974 13.0777 16.8608 12.9694 16.8453C12.861 16.8298 12.7507 16.8358 12.6447 16.863C12.5387 16.8901 12.4391 16.9379 12.3515 17.0036V16.9952Z"
      fill={fill}
    />
    <path
      d="M15.857 12.2786C15.6404 12.237 15.4162 12.283 15.2335 12.4064C15.0507 12.5298 14.9243 12.7206 14.882 12.937C14.7676 13.5151 14.577 14.0756 14.3153 14.6036C14.2181 14.8025 14.2038 15.0319 14.2757 15.2414C14.3476 15.4508 14.4997 15.6231 14.6986 15.7203C14.8975 15.8175 15.1269 15.8318 15.3364 15.7599C15.5458 15.688 15.7181 15.5359 15.8153 15.337C16.1388 14.6761 16.3741 13.9757 16.5153 13.2536C16.5569 13.0371 16.511 12.8129 16.3876 12.6301C16.2642 12.4474 16.0734 12.321 15.857 12.2786Z"
      fill={fill}
    />
    <path
      d="M15.8412 10.8954C16.0565 10.8476 16.2441 10.7164 16.3628 10.5305C16.4815 10.3446 16.5217 10.1192 16.4746 9.90378C16.3151 9.15405 16.0515 8.43036 15.6912 7.75378C15.6445 7.64955 15.5767 7.55606 15.4923 7.4791C15.4078 7.40215 15.3085 7.34339 15.2004 7.30646C15.0922 7.26953 14.9777 7.25522 14.8638 7.26443C14.7499 7.27364 14.6392 7.30617 14.5384 7.35999C14.4376 7.41381 14.349 7.48778 14.278 7.5773C14.207 7.66683 14.1552 7.76999 14.1258 7.88038C14.0964 7.99077 14.0899 8.10603 14.1069 8.21901C14.1239 8.33199 14.164 8.44026 14.2246 8.53711C14.5076 9.0793 14.7178 9.65652 14.8496 10.2538C14.8723 10.3611 14.9161 10.463 14.9783 10.5534C15.0405 10.6438 15.1199 10.721 15.2121 10.7806C15.3042 10.8403 15.4072 10.8811 15.5152 10.9008C15.6232 10.9205 15.734 10.9187 15.8412 10.8954Z"
      fill={fill}
    />
  </svg>
);

Rotate.propTypes = {
  fill: PropTypes.string,
};

Rotate.defaultProps = {
  fill: '#FFFFFF',
};

export default Rotate;
