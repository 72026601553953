import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Substract = ({ fill = colors.black }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 8.75H17.5C18.1904 8.75 18.75 9.30964 18.75 10C18.75 10.6904 18.1904 11.25 17.5 11.25H2.5C1.80964 11.25 1.25 10.6904 1.25 10C1.25 9.30964 1.80964 8.75 2.5 8.75Z"
      fill={fill}
    />
  </svg>
);

export default Substract;
