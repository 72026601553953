import actionTypes from '../actions/map/actions';

const ACTION_HANDLERS = {
  [actionTypes.LOAD_COUNTIES_SUCCESS]: (state, action) => action.data,
  [actionTypes.LOAD_COUNTIES_FAILURE]: (state, action) => initialState,
};

// Reducer
const initialState = [];

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
