import { StyleableOnlyIconProps } from '../types';

const Profile = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.80437 3.57365C9.65189 2.72613 10.8014 2.25 11.9999 2.25C13.1985 2.25 14.348 2.72613 15.1955 3.57365C16.043 4.42117 16.5192 5.57066 16.5192 6.76923C16.5192 7.96781 16.043 9.11729 15.1955 9.96481C14.348 10.8123 13.1985 11.2885 11.9999 11.2885C10.8014 11.2885 9.65189 10.8123 8.80437 9.96481C7.95684 9.11729 7.48071 7.96781 7.48071 6.76923C7.48071 5.57066 7.95684 4.42117 8.80437 3.57365ZM11.9999 3.75C11.1992 3.75 10.4312 4.0681 9.86503 4.63431C9.29881 5.20053 8.98071 5.96848 8.98071 6.76923C8.98071 7.56998 9.29881 8.33793 9.86503 8.90415C10.4312 9.47037 11.1992 9.78846 11.9999 9.78846C12.8007 9.78846 13.5686 9.47037 14.1349 8.90415C14.7011 8.33793 15.0192 7.56998 15.0192 6.76923C15.0192 5.96848 14.7011 5.20053 14.1349 4.63431C13.5686 4.0681 12.8007 3.75 11.9999 3.75Z"
          fill="#1D1D1D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 14.0576C10.3424 14.0576 8.75269 14.7161 7.58058 15.8882C6.40848 17.0603 5.75 18.65 5.75 20.3076C5.75 20.7218 5.41421 21.0576 5 21.0576C4.58579 21.0576 4.25 20.7218 4.25 20.3076C4.25 18.2522 5.06652 16.2809 6.51992 14.8275C7.97333 13.3741 9.94457 12.5576 12 12.5576C14.0554 12.5576 16.0267 13.3741 17.4801 14.8275C18.9335 16.2809 19.75 18.2522 19.75 20.3076C19.75 20.7218 19.4142 21.0576 19 21.0576C18.5858 21.0576 18.25 20.7218 18.25 20.3076C18.25 18.65 17.5915 17.0603 16.4194 15.8882C15.2473 14.7161 13.6576 14.0576 12 14.0576Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8044 3.57365C9.65192 2.72613 10.8014 2.25 12 2.25C13.1985 2.25 14.348 2.72613 15.1956 3.57365C16.0431 4.42117 16.5192 5.57066 16.5192 6.76923C16.5192 7.96781 16.0431 9.11729 15.1956 9.96481C14.348 10.8123 13.1985 11.2885 12 11.2885C10.8014 11.2885 9.65192 10.8123 8.8044 9.96481C7.95688 9.11729 7.48074 7.96781 7.48074 6.76923C7.48074 5.57066 7.95688 4.42117 8.8044 3.57365ZM8.60156 21.0576H12H15.5H17.4801H19C19.4142 21.0576 19.75 20.7218 19.75 20.3076C19.75 18.2522 18.9335 16.2809 17.4801 14.8275C16.0267 13.3741 14.0554 12.5576 12 12.5576C9.94457 12.5576 7.97333 13.3741 6.51992 14.8275C5.06652 16.2809 4.25 18.2522 4.25 20.3076C4.25 20.7218 4.58579 21.0576 5 21.0576H6.51992C6.73618 21.0576 6.91597 21.0514 7.09875 21.0451C7.47361 21.0322 7.86107 21.0189 8.60156 21.0576Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Profile;
