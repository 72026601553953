// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "#1d1d1d";
var _3 = "#282828";
var _4 = "#0065ff";
var _5 = "#4a90e2";
var _6 = "#4a4a4a";
var _7 = "#666";
var _8 = "#999";
var _9 = "#ccc";
var _a = "#b9b9b9";
var _b = "#e8e8e8";
var _c = "#979797";
var _d = "#3a9838";
var _e = "#f9213b";
var _f = "#b11717";
var _10 = "#f92121";
var _11 = "#fdbaba";
var _12 = "#f1f1f1";
var _13 = "#fff";
export { _1 as "black", _2 as "blackLight", _3 as "blackLighter", _4 as "blue", _5 as "blueLighter", _6 as "charcoal", _7 as "doveGray", _8 as "dustyGray", _9 as "gray", _a as "grayLight", _b as "grayLightest", _c as "grayMedium", _d as "green", _e as "red", _f as "redDark", _10 as "redDelete", _11 as "redLight", _12 as "seashell", _13 as "white" }
