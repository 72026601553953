import { NonConfigurableIconProps } from '../types';

const Radius = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9592 15.251C21.3374 15.42 21.5071 15.8635 21.3382 16.2417C21.0496 16.8878 20.6932 17.5015 20.2751 18.0723C20.0303 18.4065 19.561 18.479 19.2269 18.2342C18.8927 17.9895 18.8202 17.5202 19.065 17.186C19.4204 16.7008 19.7233 16.1792 19.9686 15.63C20.1375 15.2518 20.581 15.0821 20.9592 15.251Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8631 20.1961C17.0549 20.5632 16.9127 21.0163 16.5456 21.2081C16.226 21.3751 15.8937 21.5165 15.5518 21.6311C15.2166 21.7622 14.8718 21.867 14.5204 21.9448C14.116 22.0343 13.7156 21.779 13.6261 21.3745C13.5366 20.9701 13.7919 20.5697 14.1963 20.4802C14.4773 20.418 14.7529 20.3338 15.0206 20.2282C15.0335 20.2231 15.0465 20.2184 15.0597 20.214C15.3321 20.1236 15.5967 20.0114 15.8511 19.8786C16.2183 19.6868 16.6714 19.8289 16.8631 20.1961Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32192 20.1961C7.5137 19.8289 7.9668 19.6868 8.33394 19.8786C8.58832 20.0114 8.85299 20.1236 9.12539 20.214C9.13843 20.2183 9.15136 20.223 9.16415 20.2281C9.43206 20.3336 9.70786 20.418 9.989 20.4803C10.3934 20.5699 10.6486 20.9704 10.5589 21.3748C10.4693 21.7792 10.0688 22.0344 9.66439 21.9447C9.31317 21.8669 8.96849 21.762 8.63342 21.6311C8.29148 21.5165 7.95912 21.3751 7.63945 21.2081C7.27231 21.0163 7.13015 20.5632 7.32192 20.1961Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.22686 15.2509C3.60511 15.0821 4.0486 15.2519 4.21741 15.6302C4.46265 16.1796 4.76536 16.7016 5.12048 17.1874C5.36494 17.5217 5.29205 17.991 4.95767 18.2355C4.62328 18.4799 4.15404 18.407 3.90958 18.0726C3.49198 17.5014 3.13602 16.8876 2.84764 16.2415C2.67883 15.8632 2.84861 15.4198 3.22686 15.2509Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28855 8.82028C3.68696 8.9336 3.91807 9.34845 3.80475 9.74686C3.64022 10.3253 3.53755 10.9195 3.49844 11.5196C3.4715 11.9329 3.11459 12.2462 2.70126 12.2192C2.28792 12.1923 1.97468 11.8354 2.00161 11.4221C2.04762 10.7161 2.16842 10.017 2.36198 9.33648C2.4753 8.93807 2.89014 8.70696 3.28855 8.82028Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46999 3.92474C7.70578 4.2653 7.62084 4.73252 7.28028 4.9683C6.78041 5.31439 6.31458 5.70723 5.8891 6.14153C5.59923 6.43741 5.12438 6.44228 4.8285 6.15241C4.53262 5.86253 4.52775 5.38768 4.81762 5.0918C5.30966 4.58956 5.84836 4.13526 6.42644 3.73503C6.767 3.49925 7.23421 3.58419 7.46999 3.92474Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.053 2.00104C12.0793 1.99965 12.1057 1.99965 12.132 2.00104L13.112 2.05271C13.5257 2.07451 13.8433 2.42751 13.8215 2.84115C13.7997 3.25479 13.4467 3.57243 13.033 3.55063L12.0925 3.50104L11.152 3.55063C10.7384 3.57243 10.3854 3.25479 10.3636 2.84115C10.3418 2.42751 10.6594 2.07451 11.073 2.05271L12.053 2.00104Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7169 3.92453C16.9528 3.58405 17.42 3.49928 17.7605 3.73518C18.3383 4.13551 18.8768 4.58968 19.3689 5.09161C19.6589 5.38739 19.6542 5.86224 19.3584 6.15222C19.0626 6.4422 18.5878 6.4375 18.2978 6.14172C17.8721 5.70745 17.4061 5.31451 16.9062 4.96815C16.5657 4.73225 16.481 4.265 16.7169 3.92453Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8936 8.8204C21.2919 8.70684 21.7069 8.93771 21.8205 9.33605C22.0145 10.0167 22.1354 10.7162 22.181 11.4225C22.2077 11.8359 21.8942 12.1926 21.4809 12.2193C21.0675 12.246 20.7108 11.9325 20.6841 11.5192C20.6454 10.9193 20.5427 10.3253 20.3779 9.74728C20.2644 9.34894 20.4952 8.93396 20.8936 8.8204Z"
        fill="#1D1D1D"
      />
      <path
        d="M8.96753 12.125C8.96753 12.9538 9.29677 13.7487 9.88282 14.3347C10.4689 14.9208 11.2637 15.25 12.0925 15.25C12.9213 15.25 13.7162 14.9208 14.3022 14.3347C14.8883 13.7487 15.2175 12.9538 15.2175 12.125C15.2175 11.2962 14.8883 10.5013 14.3022 9.91529C13.7162 9.32924 12.9213 9 12.0925 9C11.2637 9 10.4689 9.32924 9.88282 9.91529C9.29677 10.5013 8.96753 11.2962 8.96753 12.125Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Radius;
