import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Profile = ({ fill = colors.links300 }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33681 2.97804C8.04308 2.27178 9.00098 1.875 9.99979 1.875C10.9986 1.875 11.9565 2.27178 12.6628 2.97804C13.369 3.68431 13.7658 4.64221 13.7658 5.64103C13.7658 6.63984 13.369 7.59774 12.6628 8.30401C11.9565 9.01028 10.9986 9.40705 9.99979 9.40705C9.00098 9.40705 8.04308 9.01028 7.33681 8.30401C6.63054 7.59774 6.23376 6.63984 6.23376 5.64103C6.23376 4.64221 6.63054 3.68431 7.33681 2.97804ZM9.99979 3.125C9.3325 3.125 8.69254 3.39008 8.22069 3.86193C7.74885 4.33377 7.48376 4.97373 7.48376 5.64103C7.48376 6.30832 7.74885 6.94828 8.22069 7.42013C8.69254 7.89197 9.3325 8.15705 9.99979 8.15705C10.6671 8.15705 11.307 7.89197 11.7789 7.42013C12.2507 6.94828 12.5158 6.30832 12.5158 5.64103C12.5158 4.97373 12.2507 4.33377 11.7789 3.86193C11.307 3.39008 10.6671 3.125 9.99979 3.125Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99984 11.7147C8.6185 11.7147 7.29374 12.2634 6.31699 13.2402C5.34024 14.2169 4.7915 15.5417 4.7915 16.923C4.7915 17.2682 4.51168 17.548 4.1665 17.548C3.82133 17.548 3.5415 17.2682 3.5415 16.923C3.5415 15.2102 4.22193 13.5675 5.43311 12.3563C6.64428 11.1451 8.28698 10.4647 9.99984 10.4647C11.7127 10.4647 13.3554 11.1451 14.5666 12.3563C15.7777 13.5675 16.4582 15.2102 16.4582 16.923C16.4582 17.2682 16.1783 17.548 15.8332 17.548C15.488 17.548 15.2082 17.2682 15.2082 16.923C15.2082 15.5417 14.6594 14.2169 13.6827 13.2402C12.7059 12.2634 11.3812 11.7147 9.99984 11.7147Z"
      fill={fill}
    />
  </svg>
);

export default Profile;
