import { makeAuthenticatedRequest } from './utils/request';

export const fetchReleaseNotes = (filepathArray) => {
  const url = `${process.env.API_URL}/release-notes`;

  const fullUrl =
    filepathArray.length ?
      `${url}?filepathArray=${encodeURIComponent(JSON.stringify(filepathArray))}`
    : url;
  return makeAuthenticatedRequest(fullUrl);
};

export default {
  fetchReleaseNotes,
};
