const LandIdDark = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_44_25166)">
      <path
        d="M-0.0559082 24.2434C-0.0559082 15.768 -0.0559102 11.5293 1.59357 8.29211C3.04485 5.44495 5.35923 3.12844 8.20639 1.67929C11.4435 0.0298158 15.6823 0.0298157 24.1577 0.0298157H25.6708C34.1462 0.0298157 38.385 0.0298158 41.6221 1.67929C44.4693 3.13057 46.7858 5.44495 48.2349 8.29211C49.8844 11.5293 49.8844 15.768 49.8844 24.2434V25.7565C49.8844 34.2319 49.8844 38.4707 48.2349 41.7078C46.7837 44.555 44.4693 46.8715 41.6221 48.3207C38.385 49.9701 34.1462 49.9701 25.6708 49.9701H24.1577C15.6823 49.9701 11.4435 49.9701 8.20639 48.3207C5.35923 46.8694 3.04272 44.555 1.59357 41.7078C-0.0559102 38.4707 -0.0559082 34.2319 -0.0559082 25.7565V24.2434Z"
        fill="#1D1D1D"
      />
      <path
        d="M40.4797 28.9958H39.2522C38.0093 28.9958 37.0017 30.0034 37.0017 31.2463V32.4738C37.0017 33.7167 38.0093 34.7243 39.2522 34.7243H40.4797C41.7226 34.7243 42.7301 33.7167 42.7301 32.4738V31.2463C42.7301 30.0034 41.7226 28.9958 40.4797 28.9958Z"
        fill="#F9213B"
      />
      <path
        d="M17.1334 16.2838C17.1334 18.0207 15.6608 18.9626 13.8963 18.9626C12.1317 18.9626 10.689 18.0207 10.689 16.2838V16.0195C10.689 14.2827 12.1317 13.2832 13.8963 13.2832C15.6608 13.2832 17.1334 14.2848 17.1334 16.0195V16.2838ZM11.2175 34.6198V19.9045H16.6028V34.6198H11.2175Z"
        fill="white"
      />
      <path
        d="M29.5002 14.0227V21.0276H29.4405C28.6456 19.9983 27.4096 19.4677 25.7622 19.4677C21.5533 19.4677 18.4333 23.0288 18.4333 27.031V27.6192C18.4333 32.2394 21.7877 35.0652 25.2614 35.0652C27.1027 35.0652 28.5859 34.509 29.6195 33.3028L29.5449 32.2905L29.9328 32.071L30.8406 34.6241H34.8855V14.0227H29.5002ZM26.8512 30.6517C24.9972 30.6517 23.9678 29.2985 23.9678 27.178C23.9678 25.0576 24.9972 23.7043 26.8512 23.7043C28.7053 23.7043 29.6472 24.9404 29.6472 27.178C29.6472 29.4157 28.6179 30.6517 26.8512 30.6517Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_44_25166">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LandIdDark;
