import { actionTypes } from '../actions/share/actions';

const ACTION_HANDLERS = {
  [actionTypes.OPEN_SHARE_TUTORIAL]: (state, action) => ({
    isOpen: true,
  }),
  [actionTypes.CLOSE_SHARE_TUTORIAL]: (state, action) => {
    const { showMapVideo } = action;
    const activeButton = showMapVideo ? 'Photo/Video' : state.activeButton;

    return {
      ...state,
      activeButton,
      isOpen: false,
    };
  },
  [actionTypes.CHANGE_ACTIVE_BUTTON]: (state, action) => ({
    ...state,
    activeButton: action.button,
  }),
  [actionTypes.CHANGE_NAV_HISTORY]: (state, action) => ({
    ...state,
    navHistory: action.path,
  }),
  [actionTypes.SELECT_HIGHLIGHT]: (state, action) => ({
    ...state,
    navHistory: 'highlights',
    selectedHighlight: action.highlight,
  }),
  [actionTypes.UNSELECT_HIGHLIGHT]: (state, action) => ({
    ...state,
    selectedHighlight: null,
  }),
  [actionTypes.SELECT_MEDIA_ELEMENT]: (state, action) => ({
    ...state,
    selectedMediaElement: action.element,
    scrollToElement: action.scrollToElement,
  }),
  [actionTypes.UNSELECT_MEDIA_ELEMENT]: (state, action) => ({
    ...state,
    selectedMediaElement: null,
  }),
  [actionTypes.TOGGLE_COLLAPSED]: (state, action) => ({
    ...state,
    collapsed: action.collapsed,
  }),
  [actionTypes.OPEN_PANO_VIEW]: (state, action) => ({
    ...state,
    panopoint: action.tool,
  }),
  [actionTypes.CLOSE_PANO_VIEW]: (state, action) => ({
    ...state,
    panopoint: null,
  }),
};

// Reducer
const initialState = {
  isOpen: false,
  activeButton: null,
  navHistory: null,
  selectedHighlight: null,
  selectedMediaElement: null,
  collapsed: false,
  scrollToElement: false,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
