import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PopupTool {
  isOpen: boolean;
  offsetTop: number;
  popupType: string | null;
}

const initialState: PopupTool = {
  isOpen: false,
  offsetTop: 0,
  popupType: null,
};

const LAYERS = {
  'Land Use': 'SIDEBAR_LEGEND_LAND_USE',
  Airports: 'SIDEBAR_LEGEND_AIRPORTS',
} as const;

type LayerKeys = keyof typeof LAYERS;

const popupsSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    openSidebarLegendPopup: (
      state,
      action: PayloadAction<{ offsetTop: number; popupType: LayerKeys }>,
    ) => {
      state.isOpen = !state.isOpen;
      state.offsetTop = action.payload.offsetTop;
      state.popupType = LAYERS[action.payload.popupType];
    },
  },
});

export const { openSidebarLegendPopup } = popupsSlice.actions;
export default popupsSlice.reducer;
