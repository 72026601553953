import { StyleableOnlyIconProps } from '../types';

const Favorites = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1652 2.82495C15.3773 2.82495 13.6613 3.63495 12.5413 4.91495C11.4213 3.63495 9.70538 2.82495 7.91749 2.82495C4.75272 2.82495 2.26611 5.24495 2.26611 8.32495C2.26611 12.105 5.75969 15.185 11.0514 19.865L12.5413 21.175L14.0313 19.855C19.323 15.185 22.8166 12.105 22.8166 8.32495C22.8166 5.24495 20.33 2.82495 17.1652 2.82495ZM12.6441 18.375L12.5413 18.475L12.4386 18.375C7.54758 14.065 4.32116 11.215 4.32116 8.32495C4.32116 6.32495 5.86244 4.82495 7.91749 4.82495C9.49987 4.82495 11.0412 5.81495 11.5857 7.18495H13.5072C14.0415 5.81495 15.5828 4.82495 17.1652 4.82495C19.2202 4.82495 20.7615 6.32495 20.7615 8.32495C20.7615 11.215 17.5351 14.065 12.6441 18.375Z"
          fill="black"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8807 21.175L11.3908 19.855C6.09905 15.185 2.60547 12.105 2.60547 8.32495C2.60547 5.24495 5.09207 2.82495 8.25684 2.82495C10.0447 2.82495 11.7607 3.63495 12.8807 4.91495C14.0007 3.63495 15.7167 2.82495 17.5046 2.82495C20.6693 2.82495 23.1559 5.24495 23.1559 8.32495C23.1559 12.105 19.6623 15.185 14.3706 19.865L12.8807 21.175Z"
          fill="black"
        />
      </svg>;
};

export default Favorites;
