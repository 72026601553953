import actionTypes from '../actions/drawingTooltips/actions';

const ACTION_HANDLERS = {
  [actionTypes.SHOW_TOOLTIP]: (_, action) => ({
    message: action.message,
  }),
  [actionTypes.CLEAR_TOOLTIP]: () => initialState,
};

const initialState = {
  message: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
