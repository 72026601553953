import { NonConfigurableIconProps } from '../types';

const FeaturePoint = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.35411 16.8224H6.75921C6.51702 16.8224 6.32673 16.6321 6.32673 16.3899V9.90267C6.32673 9.42693 5.93749 9.0377 5.46176 9.0377C4.98603 9.0377 4.5968 9.42693 4.5968 9.90267V16.3899C4.5968 17.5836 5.56556 18.5523 6.75921 18.5523H9.35411C9.82984 18.5523 10.2191 18.1631 10.2191 17.6874C10.2191 17.2116 9.82984 16.8224 9.35411 16.8224Z"
        fill="black"
      />
      <path
        d="M19.0937 8.06028C19.4051 7.69699 19.3532 7.15207 18.9899 6.84068L12.6929 1.51249C11.8799 0.829169 10.7122 0.829169 9.89909 1.51249L2.3047 7.93918C1.94141 8.25057 1.89816 8.7955 2.2009 9.15878C2.37389 9.35773 2.61608 9.46152 2.85827 9.46152C3.05722 9.46152 3.25616 9.39233 3.41185 9.25393L11.0235 2.83589C11.1879 2.69749 11.4214 2.69749 11.5858 2.83589L17.8827 8.16407C18.246 8.47546 18.7909 8.42356 19.1023 8.06028H19.0937Z"
        fill="black"
      />
      <path
        d="M16.8102 9.90267C13.9472 9.90267 11.6204 12.2294 11.6204 15.0925C11.6204 17.9555 13.9472 20.2822 16.8102 20.2822C19.6732 20.2822 22 17.9555 22 15.0925C22 12.2294 19.6732 9.90267 16.8102 9.90267ZM19.4051 15.7412H17.4589V17.6874C17.4589 18.042 17.1648 18.3361 16.8102 18.3361C16.4556 18.3361 16.1615 18.042 16.1615 17.6874V15.7412H14.2153C13.8607 15.7412 13.5666 15.4471 13.5666 15.0925C13.5666 14.7378 13.8607 14.4437 14.2153 14.4437H16.1615V12.4976C16.1615 12.1429 16.4556 11.8488 16.8102 11.8488C17.1648 11.8488 17.4589 12.1429 17.4589 12.4976V14.4437H19.4051C19.7597 14.4437 20.0538 14.7378 20.0538 15.0925C20.0538 15.4471 19.7597 15.7412 19.4051 15.7412Z"
        fill="black"
      />
    </svg>
  );
};

export default FeaturePoint;
