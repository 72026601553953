import { IconPropsWithBackgroundAndVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Tick = ({
  fill = colors.grayScale400,
  variant,
  background = colors.links300,
}: IconPropsWithBackgroundAndVariant) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {variant === 'filled' && (
      <rect width="20" height="20" rx="4" fill={background} />
    )}
    <path
      d="M16.1965 4.3981C15.9517 4.20993 15.6466 4.13191 15.3477 4.18106C15.0488 4.2302 14.7804 4.40251 14.6009 4.66046L8.87852 12.91L5.21035 9.81038C4.96713 9.60936 4.65852 9.51896 4.3524 9.55905C4.04627 9.59914 3.76772 9.76644 3.57801 10.0241C3.3883 10.2819 3.30298 10.6089 3.34082 10.9332C3.37865 11.2576 3.53655 11.5527 3.77976 11.7537L8.36498 15.6405C8.58105 15.7924 8.84113 15.8579 9.09861 15.8251C9.27645 15.8243 9.45172 15.7801 9.61077 15.6958C9.76983 15.6116 9.90839 15.4895 10.0157 15.3393L16.435 6.10826C16.5262 5.97977 16.5925 5.83335 16.6299 5.6775C16.6674 5.52165 16.6752 5.35947 16.6531 5.20038C16.6309 5.04129 16.5791 4.88845 16.5007 4.75073C16.4224 4.61301 16.319 4.49314 16.1965 4.3981Z"
      fill={fill}
    />
  </svg>
);

export default Tick;
