import { NonConfigurableIconProps } from '../types';

const LaptopComputer = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7392 19.22C22.675 19.1466 21.0705 16.9461 20.5662 16.2584C20.5214 16.2025 20.4913 16.1364 20.4784 16.0659C20.4656 15.9955 20.4706 15.923 20.4928 15.8549C20.5482 15.6768 20.576 15.4913 20.5753 15.3048V4.8338C20.5753 4.34745 20.3821 3.88101 20.0382 3.53711C19.6943 3.1932 19.2279 3 18.7415 3H5.0889C4.60255 3 4.13612 3.1932 3.79221 3.53711C3.44831 3.88101 3.2551 4.34745 3.2551 4.8338V15.3048C3.25218 15.5275 3.29267 15.7486 3.3743 15.9558C3.40019 16.0273 3.40788 16.1042 3.39667 16.1794C3.38547 16.2546 3.35572 16.3259 3.31012 16.3867L1.21959 19.275C1.07587 19.5162 1 19.7919 1 20.0727C1 20.3535 1.07587 20.6291 1.21959 20.8704C1.36021 21.1178 1.56445 21.323 1.81111 21.4649C2.05778 21.6068 2.3379 21.6801 2.62244 21.6773H21.3822C21.6711 21.683 21.956 21.6097 22.2063 21.4654C22.4566 21.321 22.6627 21.1111 22.8023 20.8582C22.942 20.6052 23.0099 20.319 22.9988 20.0303C22.9877 19.7416 22.8979 19.4614 22.7392 19.22ZM14.2029 19.8618H9.61839C9.43675 19.8594 9.26321 19.7862 9.13476 19.6578C9.0063 19.5293 8.93309 19.3558 8.93072 19.1741C8.93313 18.9933 9.00664 18.8208 9.13534 18.6938C9.26405 18.5668 9.43759 18.4956 9.61839 18.4956H14.2029C14.3828 18.4956 14.5554 18.5671 14.6827 18.6943C14.8099 18.8216 14.8814 18.9942 14.8814 19.1741C14.8814 19.3549 14.8102 19.5285 14.6832 19.6572C14.5562 19.7859 14.3837 19.8594 14.2029 19.8618ZM18.6224 15.1673C18.5814 15.2113 18.5317 15.2463 18.4764 15.27C18.4212 15.2937 18.3616 15.3055 18.3014 15.3048H5.57486C5.45327 15.3048 5.33666 15.2565 5.25069 15.1705C5.16471 15.0845 5.11641 14.9679 5.11641 14.8463V5.28308C5.1164 5.2233 5.12832 5.16412 5.15147 5.109C5.17461 5.05389 5.20853 5.00395 5.25123 4.96211C5.29392 4.92026 5.34454 4.88736 5.40011 4.86533C5.45568 4.8433 5.51509 4.83258 5.57486 4.8338H18.3014C18.4206 4.8338 18.5349 4.88114 18.6191 4.96539C18.7034 5.04965 18.7507 5.16392 18.7507 5.28308V14.8463C18.7516 14.9057 18.7407 14.9646 18.7186 15.0196C18.6966 15.0747 18.6639 15.1249 18.6224 15.1673Z"
        fill="black"
      />
    </svg>
  );
};

export default LaptopComputer;
