import { NonConfigurableIconProps } from '../types';

const Duplicate = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3C2 2.44772 2.44772 2 3 2H16C16.5523 2 17 2.44772 17 3V16C17 16.5523 16.5523 17 16 17H3C2.44772 17 2 16.5523 2 16V3Z"
        fill="#1D1D1D"
      />
      <path
        d="M7.75 18C7.33579 18 7 18.3358 7 18.75V21C7 21.5523 7.44772 22 8 22H21C21.5523 22 22 21.5523 22 21V8C22 7.44772 21.5523 7 21 7H18.75C18.3358 7 18 7.33579 18 7.75C18 8.16421 18.3358 8.5 18.75 8.5H20.5V20.5H8.5V18.75C8.5 18.3358 8.16421 18 7.75 18Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Duplicate;
