import { NonConfigurableIconProps } from '../types';

const Water = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9583 21.7616C17.4502 21.7616 18.8809 21.169 19.9358 20.1141C20.9907 19.0592 21.5833 17.6285 21.5833 16.1366C21.5833 13.7332 18.2595 8.11844 16.8277 5.81731C16.7359 5.66931 16.6079 5.54717 16.4558 5.46248C16.3037 5.37779 16.1324 5.33333 15.9583 5.33333C15.7842 5.33333 15.613 5.37779 15.4609 5.46248C15.3087 5.54717 15.1807 5.66931 15.089 5.81731C13.6572 8.11844 10.3333 13.7332 10.3333 16.1366C10.3333 17.6285 10.926 19.0592 11.9809 20.1141C13.0357 21.169 14.4665 21.7616 15.9583 21.7616ZM12.6345 15.6253C12.7701 15.6253 12.9002 15.6791 12.9961 15.775C13.092 15.8709 13.1458 16.001 13.1458 16.1366C13.1458 16.8825 13.4421 17.5979 13.9696 18.1254C14.497 18.6528 15.2124 18.9491 15.9583 18.9491C16.094 18.9491 16.224 19.003 16.3199 19.0989C16.4158 19.1948 16.4697 19.3249 16.4697 19.4605C16.4697 19.5961 16.4158 19.7262 16.3199 19.8221C16.224 19.918 16.094 19.9719 15.9583 19.9719C14.9412 19.9719 13.9657 19.5678 13.2464 18.8485C12.5272 18.1293 12.1231 17.1538 12.1231 16.1366C12.1231 16.001 12.177 15.8709 12.2729 15.775C12.3688 15.6791 12.4988 15.6253 12.6345 15.6253Z"
        fill="#1D1D1D"
      />
      <path
        d="M5.75 12.9522C6.74456 12.9522 7.69839 12.5571 8.40165 11.8538C9.10491 11.1506 9.5 10.1968 9.5 9.20219C9.5 7.59992 7.28409 3.85674 6.32955 2.32265C6.2684 2.22398 6.18307 2.14256 6.08165 2.0861C5.98023 2.02964 5.86608 2 5.75 2C5.63392 2 5.51977 2.02964 5.41835 2.0861C5.31693 2.14256 5.2316 2.22398 5.17045 2.32265C4.21591 3.85674 2 7.59992 2 9.20219C2 10.1968 2.39509 11.1506 3.09835 11.8538C3.80161 12.5571 4.75544 12.9522 5.75 12.9522Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Water;
