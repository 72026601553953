import Amplitude from 'services/FeatureFlags/amplitude';
import React, {
  createContext,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import EventRouter from 'services/EventRouter';
import AppsFlyer from 'services/AppsFlyer';
import type { FeatureFlagOverrides, FeatureFlags, FlagValue } from './types';

export interface FeatureFlagContextType {
  remoteFeatureFlags: FeatureFlags;
  featureFlagsLoaded: boolean;
  overrides: FeatureFlagOverrides;
  setOverride: (
    key: string,
    value: FlagValue | null,
    payload?: string | null,
  ) => void;
  identifyUser: (userId: number | undefined) => void;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(
  undefined,
);

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
}

export function FeatureFlagsProvider({ children }: FeatureFlagsProviderProps) {
  const [remoteFeatureFlags, setRemoteFeatureFlags] = useState<{
    result: FeatureFlags;
    isLoading: boolean;
  }>({
    result: {},
    isLoading: true,
  });
  const [localOverrides, setLocalOverrides] = useState<FeatureFlagOverrides>(
    () => getLocalFeatureFlagOverrides(),
  );

  const amplitudeFeatureFlagProvider = useRef(new Amplitude()).current;
  const initializationStarted = useRef(false);

  // handle local storage changes to feature flags overrides
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (
        event.storageArea === localStorage &&
        event.key === 'featureFlagOverrides' &&
        event.newValue
      ) {
        setLocalOverrides(JSON.parse(event.newValue));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const fetchFlags = async () => {
    try {
      const remoteFlags =
        await amplitudeFeatureFlagProvider.getAllFeatureFlags();
      setRemoteFeatureFlags({
        result: remoteFlags as FeatureFlags,
        isLoading: false,
      });
    } catch {
      setRemoteFeatureFlags({ result: {}, isLoading: false });
    }
  };

  // initialize feature flags
  useEffect(() => {
    if (initializationStarted.current) {
      return;
    }

    initializationStarted.current = true;
    fetchFlags();
  }, []);

  useEffect(() => {
    // Save the new feature flag overrides to local storage
    storeLocalFeatureFlagOverrides(localOverrides);
  }, [localOverrides]);

  // Should be taken out after initial roll out of event router
  useEffect(() => {
    EventRouter.setLogging(
      Boolean(
        localOverrides.eventLogTool?.value ??
          remoteFeatureFlags.result.eventLogTool?.value,
      ),
    );
    EventRouter.setEnabled(
      !!remoteFeatureFlags.result.eventRouterEnabled?.value,
    );
  }, [
    remoteFeatureFlags.result.eventRouterEnabled,
    remoteFeatureFlags.result.eventLogTool,
    localOverrides.eventLogTool,
  ]);

  // Should be removed after we commit to a feature flag provider
  // once we do that, we need to implement a way to read the flags from
  // outside a react component. Maybe through `store.getState().featureFlags`?
  useEffect(() => {
    if (
      typeof remoteFeatureFlags.result.appsFlyerEnabled?.value === 'boolean'
    ) {
      AppsFlyer.setEnabled(remoteFeatureFlags.result.appsFlyerEnabled.value);
    }
  }, [remoteFeatureFlags.result.appsFlyerEnabled]);

  const identifyUser = async (userId: number | undefined) => {
    await amplitudeFeatureFlagProvider.identifyUser(userId);
    fetchFlags();
  };

  const setOverride = (
    key: string,
    value: FlagValue | null,
    payload: string | null = null,
  ) => setLocalOverrides((prev) => ({ ...prev, [key]: { value, payload } }));

  return (
    <FeatureFlagContext.Provider
      value={{
        remoteFeatureFlags: remoteFeatureFlags.result,
        featureFlagsLoaded: !remoteFeatureFlags.isLoading,
        overrides: localOverrides,
        setOverride,
        identifyUser,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
}

export function useFeatureFlagsContext() {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error(
      'useFeatureFlagsContext must be used within a FeatureFlagsProvider',
    );
  }
  return context;
}

function getLocalFeatureFlagOverrides() {
  const localFeatureFlagsValue = localStorage.getItem('featureFlagOverrides');
  const localFeatureFlags: FeatureFlagOverrides =
    localFeatureFlagsValue ? JSON.parse(localFeatureFlagsValue) : {};
  return localFeatureFlags;
}

function storeLocalFeatureFlagOverrides(featureFlags: FeatureFlagOverrides) {
  localStorage.setItem('featureFlagOverrides', JSON.stringify(featureFlags));
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node,
};
