import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import icons from './icons';
import { GenericIconProps } from './types';

/**
 * @deprecated please use `components/ui/Icons` instead
 */
const Icon = (props: GenericIconProps) => {
  const { id, className, onClick, dataTestId } = props;
  if (icons[id]) {
    const Current = icons[id];
    return (
      <span className={className} onClick={onClick} data-testid={dataTestId}>
        <Current {...props} />
      </span>
    );
  }

  return <Glyphicon glyph={id} className={className} />;
};

export default Icon;
