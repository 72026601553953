import icons from './UI';
import toolIcons from './Tools';
import { UIIconProps } from './types';

const Icon = (props: UIIconProps) => {
  const { id } = props;
  const Current = icons[id] || toolIcons[id];
  return <Current {...props} />;
};

export default Icon;
