// eslint-disable-next-line restricted-imports/react-modal -- we allow to use react-modal here since this is our wrapper
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import React from 'react';

import LegacyIcon from 'components/ui/DeprecatedIcon';
import Icon from 'components/ui/Icon';
import Typography from 'components/ui/Typography';

import './styles.scss';
import classNames from 'classnames';
import * as colors from 'components/ui/styles/colors.module.scss';

const Header = ({
  title,
  icon,
  iconVariant,
  onClose,
  danger,
  step,
  onBack,
}) => (
  <div className="reskin-modal__header">
    <span className="reskin-modal__title">
      {step ?
        <button className="reskin-modal__back-button" onClick={onBack}>
          <LegacyIcon id="arrow" />
        </button>
      : icon ?
        <LegacyIcon
          fill={danger ? colors.red : colors.grayScale700}
          variant={iconVariant}
          id={icon}
        />
      : null}

      <Typography type="Header4b">{title}</Typography>
    </span>

    <button
      type="button"
      className="reskin-modal__close-button"
      onClick={onClose}
    >
      <Icon width={'20'} height={'20'} id={'close'} style="bold" theme="dark" />
    </button>
  </div>
);

const Footer = ({ children }) => (
  <footer className="reskin-modal__footer">{children}</footer>
);

const Alert = ({ children }) => (
  <div className="reskin-modal__alert">{children}</div>
);

const Modal = (props) => {
  const { header, onClose, danger, className, overlayClassName, wide } = props;
  const { children, notCloseOnOverlayClick, step, onBack } = props;

  return (
    <ReactModal
      className={classNames('reskin-modal', className, {
        'reskin-modal--danger': danger,
        'reskin-modal--wide': wide,
      })}
      overlayClassName={classNames('reskin-modal-overlay', overlayClassName)}
      contentLabel="Modal"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={!overlayClassName && !notCloseOnOverlayClick}
      isOpen
    >
      {header && (
        <Header
          {...header}
          onClose={onClose}
          danger={danger}
          step={step}
          onBack={onBack}
        />
      )}
      {children}
    </ReactModal>
  );
};

Modal.Header = Header;
Modal.Alert = Alert;
Modal.Footer = Footer;

Modal.defaultProps = {
  shouldCloseOnOverlayClick: false,
  danger: false,
  wide: false,
  notCloseOnOverlayClick: true,
};

Modal.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }),
  notCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  positionModifier: PropTypes.string,
  children: PropTypes.node,
  danger: PropTypes.bool,
  wide: PropTypes.bool,
  step: PropTypes.number,
  onBack: PropTypes.func,
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconVariant: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  danger: PropTypes.bool.isRequired,
  step: PropTypes.number,
  onBack: PropTypes.func,
};

Footer.propTypes = {
  children: PropTypes.node,
};

Alert.propTypes = {
  children: PropTypes.node,
};

export default Modal;
