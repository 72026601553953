import { StyleableOnlyIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const MailEnvelope = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colors.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5C2.89543 5 2 5.89543 2 7V17C2 18.1046 2.89543 19 4 19H20.25C21.3546 19 22.25 18.1046 22.25 17V7C22.25 5.89543 21.3546 5 20.25 5H4ZM3.5 7.74737V17C3.5 17.2761 3.72386 17.5 4 17.5H20.25C20.5261 17.5 20.75 17.2761 20.75 17V7.74738L16.8592 11.9028L19.8841 14.6993C20.1883 14.9805 20.2069 15.455 19.9257 15.7591C19.6445 16.0633 19.17 16.0819 18.8659 15.8007L15.8339 12.9978L13.8116 15.1576C13.775 15.1967 13.7344 15.2318 13.6903 15.2622C13.2301 15.5799 12.6842 15.7501 12.125 15.7501C11.5658 15.7501 11.0199 15.5799 10.5597 15.2622C10.5156 15.2318 10.475 15.1967 10.4384 15.1576L8.41607 12.9978L5.38413 15.8007C5.07997 16.0819 4.60546 16.0633 4.32428 15.7591C4.0431 15.455 4.06173 14.9805 4.36588 14.6993L7.39082 11.9028L3.5 7.74737ZM19.8631 6.5H4.38695L11.4717 14.0666C11.6681 14.1864 11.8941 14.2501 12.125 14.2501C12.3559 14.2501 12.5819 14.1864 12.7783 14.0666L19.8631 6.5Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2583 13.8C11.4559 13.9919 11.7204 14.0993 11.9958 14.0993C12.2712 14.0993 12.5358 13.9919 12.7333 13.8L20.8083 5.725C20.8627 5.67034 20.9008 5.60168 20.9185 5.52665C20.9361 5.45162 20.9326 5.37316 20.9083 5.3C20.8837 5.22699 20.8396 5.16206 20.7809 5.11215C20.7222 5.06224 20.651 5.02924 20.575 5.01667L20.3333 5H3.425C3.34863 5.01168 3.27702 5.04437 3.21816 5.0944C3.15929 5.14443 3.1155 5.20985 3.09167 5.28333C3.06536 5.35625 3.06081 5.43523 3.07857 5.51068C3.09632 5.58613 3.13561 5.6548 3.19167 5.70833L11.2583 13.8Z"
          fill={color}
        />
        <path
          d="M21.9083 6.525C21.8837 6.50852 21.8547 6.49972 21.825 6.49972C21.7953 6.49972 21.7663 6.50852 21.7417 6.525L16.575 11.6917C16.5537 11.7104 16.5367 11.7335 16.525 11.7594C16.5133 11.7852 16.5073 11.8133 16.5073 11.8417C16.5073 11.87 16.5133 11.8981 16.525 11.924C16.5367 11.9498 16.5537 11.9729 16.575 11.9917L20.7417 16.1583C20.8521 16.2768 20.9122 16.4335 20.9093 16.5954C20.9065 16.7574 20.8409 16.9118 20.7264 17.0264C20.6118 17.1409 20.4574 17.2065 20.2954 17.2093C20.1335 17.2122 19.9768 17.1521 19.8583 17.0417L15.6917 12.875C15.6722 12.8548 15.6489 12.8388 15.6232 12.8278C15.5974 12.8169 15.5697 12.8112 15.5417 12.8112C15.5137 12.8112 15.4859 12.8169 15.4602 12.8278C15.4344 12.8388 15.4111 12.8548 15.3917 12.875L13.6167 14.65C13.1858 15.078 12.6031 15.3182 11.9958 15.3182C11.3885 15.3182 10.8059 15.078 10.375 14.65L8.60833 12.9083C8.58957 12.8871 8.56648 12.87 8.54062 12.8583C8.51476 12.8467 8.48671 12.8406 8.45833 12.8406C8.42996 12.8406 8.40191 12.8467 8.37605 12.8583C8.35018 12.87 8.3271 12.8871 8.30833 12.9083L4.14167 17.075C4.02319 17.1854 3.86648 17.2455 3.70456 17.2426C3.54265 17.2398 3.38816 17.1742 3.27365 17.0597C3.15914 16.9452 3.09354 16.7907 3.09069 16.6288C3.08783 16.4669 3.14793 16.3101 3.25833 16.1917L7.425 12.025C7.44628 12.0062 7.46333 11.9832 7.475 11.9573C7.48667 11.9314 7.49271 11.9034 7.49271 11.875C7.49271 11.8466 7.48667 11.8186 7.475 11.7927C7.46333 11.7669 7.44628 11.7438 7.425 11.725L2.25833 6.55833C2.23367 6.54185 2.20467 6.53306 2.175 6.53306C2.14533 6.53306 2.11633 6.54185 2.09167 6.55833C2.06863 6.56742 2.04821 6.58207 2.03221 6.60097C2.01622 6.61988 2.00515 6.64245 2 6.66667V16.6667C2 17.1087 2.17559 17.5326 2.48816 17.8452C2.80072 18.1577 3.22464 18.3333 3.66667 18.3333H20.3333C20.7754 18.3333 21.1993 18.1577 21.5118 17.8452C21.8244 17.5326 22 17.1087 22 16.6667V6.66667C22.0007 6.63658 21.9923 6.60699 21.976 6.58172C21.9596 6.55646 21.9361 6.53669 21.9083 6.525Z"
          fill={color}
        />
      </svg>;
};

export default MailEnvelope;
