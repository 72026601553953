import { clearFlash, flash } from '../flashMessages';
import actionTypes from './actions';
import errorActionTypes from 'actions/errors/actions';
import api from 'api/index';

export const addLocationMarker = (
  lngLat,
  color = 'red',
  flyToLocation = true,
) => ({ type: actionTypes.SHOW_LOCATION_MARKER, lngLat, color, flyToLocation });

export const hideLocationMarker = () => ({
  type: actionTypes.HIDE_LOCATION_MARKER,
});

export const searchAbstract = (number, county) => (dispatch, getState) => {
  dispatch({ type: actionTypes.SEARCHING_ABSTRACT });
  api
    .searchAbstract(number, county)
    .then((data) => {
      dispatch({ type: actionTypes.SEARCH_ABSTRACT_SUCCESS, data });
      if (!data || data.length < 1) {
        flashNoResults(data, dispatch);
      }
    })
    .catch((error) =>
      dispatch({ type: actionTypes.SEARCH_ABSTRACT_FAILURE, error }),
    );
};

export const searchTRS =
  (state, county, twp, twpDir, rng, rngDir, sec) => (dispatch, getState) => {
    dispatch({ type: actionTypes.SEARCHING_TRS });
    api
      .searchTRS(state, county, twp, twpDir, rng, rngDir, sec)
      .then((data) => {
        dispatch({ type: actionTypes.SEARCH_TRS_SUCCESS, data });
        if (!data || data.length < 1) {
          flashNoResults(data, dispatch);
        } else {
          dispatch(addLocationMarker(getState().search.center, 'gray', true));
        }
      })
      .catch((error) =>
        dispatch({ type: actionTypes.SEARCH_TRS_FAILURE, error }),
      );
  };

export const searchAPN =
  (apn, state, counties, fips_codes = null) =>
  (dispatch, getState) => {
    dispatch({ type: actionTypes.SEARCHING_APN });
    api
      .searchAPN(apn, state, counties, fips_codes)
      .then((data) => {
        if (data?.parcels?.length > 1) {
          dispatch({
            type: actionTypes.SEARCHING_PARCELS,
            activePanelProps: {
              parcelItemsClickable: true,
            },
          });
          dispatch(searchParcelsSuccess(data));
        } else {
          dispatch(searchAPNSuccess(data || {}));
        }
      })
      .catch((error) =>
        dispatch({ type: actionTypes.SEARCH_APN_FAILURE, error }),
      );
  };

export const searchByAttribute =
  (ids, state, fipsCodes, attribute, page, options = {}) =>
  (dispatch, getState) => {
    api
      .searchByAttribute(ids, state, fipsCodes, attribute, page)
      .then((data) => {
        if (options?.parcelItemsClickable && data?.parcels?.length === 1) {
          dispatch({ type: actionTypes.SEARCHING_APN });
          dispatch(searchAPNSuccess(data || {}));
        } else {
          dispatch({
            type: actionTypes.SEARCHING_PARCELS,
            activePanelProps: {
              ...options,
            },
          });

          dispatch({
            type: actionTypes.SEARCH_PARCELS_SUCCESS,
            data,
            params: { ids, state, fipsCodes, attribute },
          });
        }

        if (data.errors) {
          dispatch({
            type: errorActionTypes.ADD_ERROR,
            error: { json: { errors: data.errors } },
          });
        }
      })
      .catch((error) =>
        dispatch({ type: actionTypes.SEARCH_PARCELS_FAILURE, error }),
      );
  };

export const clearSearch = () => ({
  type: actionTypes.CLEAR_SEARCH,
});

export const clearAlerts = () => ({
  type: actionTypes.CLEAR_ALERTS,
});

export const searchViewsLayersFeatures = (search) => ({
  type: actionTypes.SEARCH_VIEWS_LAYERS_FEATURES_CHANGE,
  search,
});

export const searchAPNSuccess = (data, clearParcelResults = true) => ({
  type: actionTypes.SEARCH_APN_SUCCESS,
  data,
  clearParcelResults,
});

export const searchParcelsSuccess = (data, params = {}) => ({
  type: actionTypes.SEARCH_PARCELS_SUCCESS,
  data: {
    parcels: data.parcels,
    next_page: false,
    current_page: 1,
  },
  params,
});

const flashNoResults = (_, dispatch) => {
  dispatch(flash('NO_RESULTS'));
  setTimeout(() => dispatch(clearFlash()), 3000);
};

export const actions = {
  addLocationMarker,
  hideLocationMarker,
  searchAbstract,
  searchTRS,
  clearSearch,
  clearAlerts,
  searchViewsLayersFeatures,
  searchAPN,
  searchByAttribute,
};
