import { NonConfigurableIconProps } from '../types';

const ArrowButtonRight = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9041 12.7707C13.0042 12.5262 13.0549 12.2642 13.0533 12C13.0549 11.7358 13.0042 11.4738 12.9041 11.2293C12.8039 10.9848 12.6563 10.7626 12.4698 10.5754L5.2408 3.34641C4.98561 3.11565 4.65149 2.99178 4.30755 3.00042C3.9636 3.00906 3.63612 3.14955 3.39284 3.39284C3.14955 3.63612 3.00906 3.9636 3.00042 4.30755C2.99178 4.65149 3.11565 4.98561 3.34641 5.2408L9.96921 11.8636C10.0028 11.9011 10.0213 11.9497 10.0213 12C10.0213 12.0503 10.0028 12.0989 9.96921 12.1364L3.34641 18.7592C3.11565 19.0144 2.99178 19.3485 3.00042 19.6925C3.00906 20.0364 3.14955 20.3639 3.39284 20.6072C3.63612 20.8504 3.9636 20.9909 4.30755 20.9996C4.65149 21.0082 4.98561 20.8843 5.2408 20.6536L12.4698 13.4246C12.6563 13.2374 12.8039 13.0152 12.9041 12.7707ZM20.1913 13.4246C20.5618 13.0436 20.7662 12.5314 20.7597 12C20.7662 11.4686 20.5618 10.9564 20.1913 10.5754L12.9396 3.34641C12.6844 3.11565 12.3503 2.99178 12.0063 3.00042C11.6624 3.00906 11.3349 3.14955 11.0916 3.39284C10.8483 3.63612 10.7079 3.9636 10.6992 4.30755C10.6906 4.65149 10.8144 4.98561 11.0452 5.2408L17.6907 11.8636C17.7091 11.8813 17.7237 11.9025 17.7336 11.9259C17.7436 11.9493 17.7487 11.9745 17.7487 12C17.7487 12.0255 17.7436 12.0507 17.7336 12.0741C17.7237 12.0975 17.7091 12.1187 17.6907 12.1364L11.0679 18.7592C10.8372 19.0144 10.7133 19.3485 10.7219 19.6925C10.7306 20.0364 10.8711 20.3639 11.1144 20.6072C11.3576 20.8504 11.6851 20.9909 12.0291 20.9996C12.373 21.0082 12.7071 20.8843 12.9623 20.6536L20.1913 13.4246Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default ArrowButtonRight;
