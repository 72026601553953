import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const ImageMissing = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12 17.06H11.45C11.44 17.17 11.43 17.29 11.43 17.4C11.43 17.85 11.49 18.3 11.59 18.73H3.69C2.76 18.73 2 17.98 2 17.06V3.67C2 2.75 2.76 2 3.69 2H17.25C18.18 2 18.94 2.75 18.94 3.67V11.78C18.43 11.64 17.89 11.56 17.35 11.56H17.24V4.09C17.24 3.86 17.05 3.67 16.82 3.67H4.12C3.89 3.67 3.7 3.86 3.7 4.09V16.64C3.7 16.87 3.89 17.06 4.12 17.06ZM13.31 9.09999L14.57 12.24L14.56 12.23C14.05 12.5 13.58 12.85 13.16 13.26C12.59 13.82 12.15 14.48 11.86 15.2H5.82001C5.72001 15.21 5.62001 15.18 5.54001 15.12C5.36001 14.98 5.32001 14.71 5.46001 14.53L7.50001 11.07C7.58001 10.92 7.69001 10.79 7.83001 10.69C8.28001 10.39 8.89001 10.5 9.20001 10.94L10.13 12.04C10.18 12.1 10.25 12.12 10.32 12.12C10.39 12.11 10.46 12.06 10.49 12L11.87 9.09999C11.94 8.89999 12.17 8.51999 12.58 8.51999C12.96 8.51999 13.2 8.82999 13.31 9.09999ZM20.64 14.16C19.73 13.26 18.54 12.81 17.35 12.81C16.16 12.81 14.97 13.26 14.06 14.16C12.25 15.95 12.25 18.86 14.06 20.66C14.97 21.56 16.16 22.01 17.35 22.01C18.54 22.01 19.73 21.56 20.64 20.66C22.45 18.87 22.45 15.96 20.64 14.16ZM16.93 14.39H17.84C18.06 14.39 18.24 14.57 18.23 14.79L18.06 17.69C18.05 17.89 17.88 18.05 17.67 18.05H17.09C16.88 18.05 16.71 17.89 16.7 17.69L16.54 14.79C16.53 14.57 16.71 14.39 16.93 14.39ZM18.26 19.63C18.26 20.14 17.86 20.43 17.35 20.43C16.84 20.43 16.45 20.15 16.45 19.63V19.56C16.45 19.05 16.84 18.75 17.35 18.75C17.86 18.75 18.26 19.04 18.26 19.56V19.63ZM9.08999 6.73C9.08999 7.65231 8.33335 8.4 7.39999 8.4C6.46663 8.4 5.70999 7.65231 5.70999 6.73C5.70999 5.80768 6.46663 5.06 7.39999 5.06C8.33335 5.06 9.08999 5.80768 9.08999 6.73Z"
        fill={color}
      />
    </svg>
  );
};

export default ImageMissing;
