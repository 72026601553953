import { ChevronProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';
import * as directionClasses from './Chevron.module.scss';

const Chevron = ({
  fill = colors.white,
  direction = 'bottom',
  dataTestid,
  strokeWidth,
  width = '16',
  height = '16',
}: ChevronProps) => {
  const directionClass = directionClasses[direction];
  return (
    <svg
      className={direction && `${directionClass}`}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestid}
    >
      <path
        // eslint-disable-next-line max-len
        d="M3.11441 4.23034C2.8947 3.9619 2.49895 3.92235 2.23044 4.14197C1.9619 4.3616 1.9223 4.75732 2.14204 5.02579L7.20421 11.2107C7.70177 11.8187 8.63157 11.8187 9.12914 11.2107L14.1913 5.02579C14.411 4.75732 14.3714 4.3616 14.1029 4.14197C13.8344 3.92235 13.4386 3.9619 13.2189 4.23034L8.16667 10.4032L3.11441 4.23034Z"
        fill={fill}
        stroke={strokeWidth ? fill : 'none'}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default Chevron;
