import { StyleableOnlyIconProps } from '../types';

const User = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.375 3C7.26441 3 6.1993 3.44118 5.41399 4.22649C4.62868 5.0118 4.1875 6.07691 4.1875 7.1875C4.1875 8.29809 4.62868 9.3632 5.41399 10.1485C6.1993 10.9338 7.26441 11.375 8.375 11.375C9.48559 11.375 10.5507 10.9338 11.336 10.1485C12.1213 9.3632 12.5625 8.29809 12.5625 7.1875C12.5625 6.07691 12.1213 5.0118 11.336 4.22649C10.5507 3.44118 9.48559 3 8.375 3ZM6.47465 5.28715C6.97865 4.78315 7.66223 4.5 8.375 4.5C9.08777 4.5 9.77135 4.78315 10.2753 5.28715C10.7794 5.79115 11.0625 6.47473 11.0625 7.1875C11.0625 7.90027 10.7794 8.58385 10.2753 9.08785C9.77135 9.59185 9.08777 9.875 8.375 9.875C7.66223 9.875 6.97865 9.59185 6.47465 9.08785C5.97065 8.58385 5.6875 7.90027 5.6875 7.1875C5.6875 6.47473 5.97065 5.79115 6.47465 5.28715Z"
          fill="black"
        />
        <path
          d="M4.92785 15.0529C5.84209 14.1386 7.08207 13.625 8.375 13.625C9.66793 13.625 10.9079 14.1386 11.8221 15.0529C12.7364 15.9671 13.25 17.2071 13.25 18.5C13.25 18.9142 13.5858 19.25 14 19.25C14.4142 19.25 14.75 18.9142 14.75 18.5C14.75 16.8092 14.0784 15.1877 12.8828 13.9922C11.6873 12.7966 10.0658 12.125 8.375 12.125C6.68425 12.125 5.06274 12.7966 3.86719 13.9922C2.67165 15.1877 2 16.8092 2 18.5C2 18.9142 2.33579 19.25 2.75 19.25C3.16421 19.25 3.5 18.9142 3.5 18.5C3.5 17.2071 4.01361 15.9671 4.92785 15.0529Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3784 6.54343C15.0465 5.87533 15.9527 5.5 16.8975 5.5C17.8423 5.5 18.7485 5.87533 19.4166 6.54343C20.0847 7.21153 20.46 8.11767 20.46 9.0625C20.46 10.0073 20.0847 10.9135 19.4166 11.5816C18.7485 12.2497 17.8423 12.625 16.8975 12.625C15.9527 12.625 15.0465 12.2497 14.3784 11.5816C13.7103 10.9135 13.335 10.0073 13.335 9.0625C13.335 8.11767 13.7103 7.21153 14.3784 6.54343ZM16.8975 7C16.3505 7 15.8259 7.2173 15.4391 7.60409C15.0523 7.99089 14.835 8.51549 14.835 9.0625C14.835 9.60951 15.0523 10.1341 15.4391 10.5209C15.8259 10.9077 16.3505 11.125 16.8975 11.125C17.4445 11.125 17.9691 10.9077 18.3559 10.5209C18.7427 10.1341 18.96 9.60951 18.96 9.0625C18.96 8.51549 18.7427 7.99089 18.3559 7.60409C17.9691 7.2173 17.4445 7 16.8975 7Z"
          fill="black"
        />
        <path
          d="M15.5618 14.8854C16.1444 14.6707 16.7702 14.5998 17.386 14.6789C18.0018 14.758 18.5894 14.9847 19.0989 15.3396C19.6083 15.6946 20.0244 16.1673 20.3119 16.7176C20.5993 17.2679 20.7496 17.8795 20.75 18.5004C20.7502 18.9146 21.0862 19.2502 21.5004 19.25C21.9146 19.2498 22.2502 18.9138 22.25 18.4996C22.2495 17.6371 22.0407 16.7875 21.6414 16.0231C21.2421 15.2587 20.664 14.602 19.9564 14.1089C19.2488 13.6159 18.4325 13.301 17.5771 13.1911C16.7217 13.0813 15.8524 13.1797 15.0431 13.4779C14.6545 13.6212 14.4555 14.0524 14.5988 14.441C14.742 14.8297 15.1732 15.0286 15.5618 14.8854Z"
          fill="black"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 12.9167C10.4361 12.9167 12.2083 11.1445 12.2083 8.95833C12.2083 6.77221 10.4361 5 8.25 5C6.06387 5 4.29167 6.77221 4.29167 8.95833C4.29167 11.1445 6.06387 12.9167 8.25 12.9167ZM20.075 11.0417C20.075 12.7676 18.6759 14.1667 16.95 14.1667C15.2241 14.1667 13.825 12.7676 13.825 11.0417C13.825 9.31578 15.2241 7.91667 16.95 7.91667C18.6759 7.91667 20.075 9.31578 20.075 11.0417ZM14.825 15.0917C15.501 14.7565 16.2455 14.5825 17 14.5833C18.3261 14.5833 19.5979 15.1101 20.5355 16.0478C21.4732 16.9855 22 18.2573 22 19.5833C22 19.6938 21.9561 19.7998 21.878 19.878C21.7998 19.9561 21.6939 20 21.5833 20H16.1667C16.0568 19.9979 15.9521 19.9533 15.8744 19.8756C15.7967 19.7979 15.7521 19.6932 15.75 19.5833C15.7529 18.2064 15.372 16.8558 14.65 15.6833C14.5924 15.5823 14.5746 15.4635 14.6 15.35C14.6166 15.2932 14.6453 15.2407 14.6841 15.1961C14.723 15.1515 14.771 15.1159 14.825 15.0917ZM12.6694 15.1639C13.8415 16.336 14.5 17.9257 14.5 19.5833C14.5 19.6938 14.4561 19.7998 14.378 19.878C14.2998 19.9561 14.1938 20 14.0833 20H2.41667C2.30616 20 2.20018 19.9561 2.12204 19.878C2.0439 19.7998 2 19.6938 2 19.5833C2 17.9257 2.65848 16.336 3.83058 15.1639C5.00268 13.9918 6.5924 13.3333 8.25 13.3333C9.9076 13.3333 11.4973 13.9918 12.6694 15.1639Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default User;
