import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Folder = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 8.815C20.6601 8.61076 20.2715 8.50194 19.875 8.5H4.125C3.7285 8.50194 3.33986 8.61076 3 8.815V5.125C3 4.82663 3.11853 4.54048 3.3295 4.3295C3.54048 4.11853 3.82663 4 4.125 4H9.75C9.95878 4.00029 10.1634 4.05866 10.3409 4.1686C10.5183 4.27854 10.6617 4.4357 10.755 4.6225L11.4675 6.04C11.4977 6.10368 11.5456 6.15734 11.6054 6.19457C11.6652 6.2318 11.7345 6.25104 11.805 6.25H19.875C20.1734 6.25 20.4595 6.36853 20.6705 6.5795C20.8815 6.79048 21 7.07663 21 7.375V8.815ZM4.125 9.625H19.875C20.4963 9.625 21 10.1287 21 10.75V19.375C21 19.9963 20.4963 20.5 19.875 20.5H4.125C3.50368 20.5 3 19.9963 3 19.375V10.75C3 10.1287 3.50368 9.625 4.125 9.625Z"
        fill={color}
      />
    </svg>
  );
};

export default Folder;
