import React, { FC } from 'react';
import classNames from 'classnames';
import Icon from '../DeprecatedIcon';

import * as colors from '../styles/colors-deprecated.module.scss';
import './styles.scss';

interface ButtonProps {
  children: React.ReactNode;
  color?:
    | 'blue'
    | 'black'
    | 'gray'
    | 'grayLightest'
    | 'seashell'
    | 'red'
    | 'redLight'
    | 'white';
  fontColor?: 'fWhite' | 'fBlack' | 'fRedDark';
  size?:
    | 'w100'
    | 'w150'
    | 'w200'
    | 'w270'
    | 'w280'
    | 'w500'
    | 'w50p'
    | 'w100p'
    | 'wAuto'
    | 'small';
  icon?: 'folder' | 'plus' | 'download' | 'save' | 'share' | 'print' | null;
  iconWidth?: string;
  iconHeight?: string;
  iconStrokeWidth?: string;
  scale?: 'small' | 'default';
  fill?: string;
  otherClassNames?: string[];
  [key: string]: any;
}

/**
 * @deprecated please use `components/ui/Buttons/Button` instead
 */
const Button: FC<ButtonProps> = ({
  children,
  color = 'black',
  fontColor = 'fWhite',
  size = 'w280',
  icon = null,
  scale = 'default',
  fill = colors.white,
  iconWidth,
  iconHeight,
  iconStrokeWidth,
  otherClassNames = [],
  ...props
}) => {
  const buttonClass = classNames(
    'reskin-button',
    `reskin-button--${color}`,
    `reskin-button--${fontColor}`,
    `reskin-button--${size}`,
    `reskin-button--scale__${scale}`,
    { 'reskin-button__button-icon': icon },
    ...otherClassNames,
  );

  return (
    <button className={buttonClass} {...props}>
      {children}
      {icon && (
        <Icon
          id={icon}
          className="reskin-button__icon"
          fill={fill}
          height={iconHeight}
          width={iconWidth}
          strokeWidth={iconStrokeWidth}
        />
      )}
    </button>
  );
};

export { Button, ButtonProps };
export default Button;
