import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Checkmark = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4374 5.2777C19.1436 5.05189 18.7775 4.95827 18.4188 5.01724C18.0601 5.07621 17.738 5.28299 17.5226 5.59252L10.6558 15.492L6.25398 11.7724C5.96212 11.5312 5.59179 11.4227 5.22444 11.4708C4.85709 11.5189 4.52283 11.7197 4.29518 12.029C4.06753 12.3382 3.96514 12.7306 4.01054 13.1198C4.05595 13.5091 4.24542 13.8633 4.53728 14.1045L10.0395 18.7685C10.2988 18.9509 10.6109 19.0294 10.9199 18.9901C11.1333 18.9892 11.3436 18.9361 11.5345 18.835C11.7254 18.7339 11.8916 18.5874 12.0203 18.4071L19.7235 7.32989C19.833 7.1757 19.9126 6.99999 19.9575 6.81297C20.0024 6.62596 20.0118 6.43135 19.9852 6.24043C19.9586 6.04952 19.8965 5.86611 19.8024 5.70085C19.7084 5.53558 19.5843 5.39175 19.4374 5.2777Z"
        fill={color}
      />
    </svg>
  );
};

export default Checkmark;
