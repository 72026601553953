import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const PinPlus = ({
  fill = colors.black,
  variant = 'outlined',
}: IconPropsWithVariant) => {
  const outlined = () => (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.30944 1.48904C8.87817 1.40914 10.418 1.92737 11.6108 2.93662C12.8035 3.94587 13.5579 5.36899 13.7181 6.91184C13.7603 7.31868 14.1286 7.61468 14.5406 7.57298C14.9527 7.53128 15.2525 7.16767 15.2103 6.76083C15.0109 4.84014 14.0717 3.0685 12.5869 1.81209C11.1021 0.555676 9.18507 -0.0894667 7.23216 0.0100066C5.27924 0.10948 3.43967 0.945964 2.09368 2.34657C0.74775 3.7471 -0.00177319 5.60464 3.1502e-06 7.53528C9.63674e-05 8.39845 0.296122 9.37561 0.714685 10.345C1.13921 11.3283 1.71896 12.3688 2.3484 13.3772C3.60769 15.3946 5.10207 17.3403 6.05684 18.5317C6.31386 18.8524 6.78554 18.9067 7.11037 18.6529C7.4352 18.3992 7.49018 17.9335 7.23316 17.6127C6.29294 16.4395 4.83982 14.5457 3.62536 12.6002C3.01792 11.6271 2.47923 10.6553 2.09454 9.76428C1.70395 8.85963 1.5 8.10078 1.5 7.53488C1.49852 5.98395 2.1006 4.49103 3.18181 3.36595C4.26302 2.24088 5.7407 1.56895 7.30944 1.48904Z"
        fill={fill}
      />
      <path
        d="M13.25 11.114C13.6642 11.114 14 11.4455 14 11.8545V13.5823H15.75C16.1642 13.5823 16.5 13.9139 16.5 14.3228C16.5 14.7318 16.1642 15.0633 15.75 15.0633H14V16.7912C14 17.2001 13.6642 17.5317 13.25 17.5317C12.8358 17.5317 12.5 17.2001 12.5 16.7912V15.0633H10.75C10.3358 15.0633 10 14.7318 10 14.3228C10 13.9139 10.3358 13.5823 10.75 13.5823H12.5V11.8545C12.5 11.4455 12.8358 11.114 13.25 11.114Z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 8.64564C11.725 8.64564 10.2625 9.24377 9.18414 10.3084C8.1058 11.3731 7.5 12.8171 7.5 14.3228C7.5 15.8285 8.1058 17.2725 9.18414 18.3372C10.2625 19.4019 11.725 20 13.25 20C14.775 20 16.2375 19.4019 17.3159 18.3372C18.3942 17.2725 19 15.8285 19 14.3228C19 12.8171 18.3942 11.3731 17.3159 10.3084C16.2375 9.24377 14.775 8.64564 13.25 8.64564ZM10.2448 11.3557C11.0418 10.5687 12.1228 10.1266 13.25 10.1266C14.3772 10.1266 15.4582 10.5687 16.2552 11.3557C17.0522 12.1426 17.5 13.2099 17.5 14.3228C17.5 15.4357 17.0522 16.503 16.2552 17.29C15.4582 18.0769 14.3772 18.519 13.25 18.519C12.1228 18.519 11.0418 18.0769 10.2448 17.29C9.44777 16.503 9 15.4357 9 14.3228C9 13.2099 9.44777 12.1426 10.2448 11.3557Z"
        fill={fill}
      />
    </svg>
  );

  const filled = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3012 18.4923C9.33589 17.527 8.79357 16.2177 8.79357 14.8525C8.79357 13.4874 9.33589 12.1781 10.3012 11.2127C11.2666 10.2474 12.5758 9.70509 13.941 9.70509C15.3062 9.70509 16.6155 10.2474 17.5808 11.2127C18.5462 12.1781 19.0885 13.4874 19.0885 14.8525C19.0885 16.2177 18.5462 17.527 17.5808 18.4923C16.6155 19.4577 15.3062 20 13.941 20C12.5758 20 11.2666 19.4577 10.3012 18.4923ZM13.941 11.6354C14.2964 11.6354 14.5845 11.9235 14.5845 12.2788V14.2091H16.5147C16.8701 14.2091 17.1582 14.4972 17.1582 14.8525C17.1582 15.2079 16.8701 15.496 16.5147 15.496H14.5845V17.4263C14.5845 17.7816 14.2964 18.0697 13.941 18.0697C13.5857 18.0697 13.2976 17.7816 13.2976 17.4263V15.496H11.3673C11.0119 15.496 10.7239 15.2079 10.7239 14.8525C10.7239 14.4972 11.0119 14.2091 11.3673 14.2091H13.2976V12.2788C13.2976 11.9235 13.5857 11.6354 13.941 11.6354Z"
        fill="#0065FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.72118 0C5.67436 0.00281988 3.71227 0.733875 2.26495 2.03294C0.81763 3.332 0.00314171 5.0931 0 6.93025C0 11.195 5.19101 17.4856 6.78276 19.3194C6.8938 19.4478 7.03608 19.5517 7.19872 19.6232C7.36137 19.6947 7.54008 19.7319 7.72118 19.7319C7.90228 19.7319 8.08099 19.6947 8.24364 19.6232C8.40628 19.5517 8.54856 19.4478 8.6596 19.3194C8.75385 19.2108 8.86071 19.0867 8.97836 18.9482C8.05905 17.8356 7.5067 16.4086 7.5067 14.8525C7.5067 11.299 10.3874 8.41823 13.941 8.41823C14.3834 8.41823 14.8153 8.46287 15.2326 8.5479C15.3673 7.98718 15.4424 7.44423 15.4424 6.93025C15.4392 5.0931 14.6247 3.332 13.1774 2.03294C11.7301 0.733875 9.768 0.00281988 7.72118 0ZM10.2949 6.86327C10.2949 8.2847 9.14261 9.437 7.72118 9.437C6.29975 9.437 5.14745 8.2847 5.14745 6.86327C5.14745 5.44184 6.29975 4.28954 7.72118 4.28954C9.14261 4.28954 10.2949 5.44184 10.2949 6.86327Z"
        fill="#0065FF"
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

export default PinPlus;
