import { actionTypes } from 'actions/contextualCard';

const ACTION_HANDLERS = {
  [actionTypes.SHOW_CARD]: (state, action) => ({
    ...state,
    cardName: action.cardName,
    force: action.force,
    shownCards: state.shownCards.concat(action.cardName),
  }),
  [actionTypes.HIDE_CARD]: (state, action) => ({
    ...state,
    cardName: null,
    force: false,
  }),
};

// Reducer
const initialState = {
  cardName: null,
  force: false,
  shownCards: [],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
