/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Arrows360 = ({ fill }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5763 6.66793C14.612 6.65045 14.6419 6.62311 14.6626 6.58915C14.6833 6.55518 14.6938 6.51602 14.6929 6.47627C14.6932 6.43771 14.6824 6.3999 14.6617 6.36737C14.641 6.33485 14.6113 6.309 14.5763 6.29293L10.2429 4.16793C10.1689 4.12708 10.0858 4.10565 10.0013 4.10565C9.91674 4.10565 9.8336 4.12708 9.7596 4.16793L5.42627 6.3346C5.39122 6.35066 5.36156 6.37651 5.34086 6.40904C5.32016 6.44156 5.30931 6.47938 5.3096 6.51793C5.31576 6.5504 5.32955 6.58093 5.34983 6.60701C5.37012 6.63309 5.39632 6.65397 5.42627 6.66793L9.9096 8.9096C9.93857 8.92198 9.96976 8.92836 10.0013 8.92836C10.0328 8.92836 10.064 8.92198 10.0929 8.9096L14.5763 6.66793Z"
      fill={fill}
    />
    <path
      d="M10.4179 15.4096C10.4184 15.4448 10.4279 15.4794 10.4454 15.51C10.4628 15.5406 10.4878 15.5663 10.5179 15.5846C10.5486 15.6014 10.583 15.6102 10.6179 15.6102C10.6529 15.6102 10.6873 15.6014 10.7179 15.5846L15.1179 13.3846C15.2085 13.3406 15.2847 13.2718 15.3378 13.1863C15.3908 13.1007 15.4186 13.0019 15.4179 12.9013V7.54293C15.4174 7.50768 15.408 7.47314 15.3905 7.44253C15.373 7.41193 15.348 7.38626 15.3179 7.36793C15.2873 7.35116 15.2529 7.34236 15.2179 7.34236C15.183 7.34236 15.1486 7.35116 15.1179 7.36793L10.5346 9.6596C10.4996 9.67566 10.4699 9.70151 10.4492 9.73404C10.4285 9.76656 10.4176 9.80438 10.4179 9.84293V15.4096Z"
      fill={fill}
    />
    <path
      d="M4.8846 7.3596C4.85394 7.34282 4.81955 7.33403 4.7846 7.33403C4.74965 7.33403 4.71526 7.34282 4.6846 7.3596C4.65449 7.37793 4.62952 7.40359 4.61203 7.4342C4.59454 7.46481 4.5851 7.49935 4.5846 7.5346V12.9013C4.58389 13.0019 4.61169 13.1007 4.66478 13.1863C4.71787 13.2718 4.79408 13.3406 4.8846 13.3846L9.2846 15.5846C9.31526 15.6014 9.34965 15.6102 9.3846 15.6102C9.41955 15.6102 9.45394 15.6014 9.4846 15.5846C9.51471 15.5663 9.53968 15.5406 9.55717 15.51C9.57466 15.4794 9.5841 15.4448 9.5846 15.4096V9.8346C9.58489 9.79605 9.57404 9.75823 9.55334 9.72571C9.53264 9.69318 9.50298 9.66733 9.46793 9.65127L4.8846 7.3596Z"
      fill={fill}
    />
    <path
      d="M12.5013 16.6679H7.50127C7.419 16.6683 7.33871 16.6931 7.27049 16.7391C7.20227 16.785 7.14919 16.8502 7.11793 16.9263C7.0839 17.0014 7.07443 17.0853 7.09089 17.1661C7.10735 17.2469 7.14889 17.3205 7.2096 17.3763L9.7096 19.8763C9.74701 19.9161 9.79219 19.9479 9.84235 19.9696C9.89252 19.9913 9.9466 20.0025 10.0013 20.0025C10.0559 20.0025 10.11 19.9913 10.1602 19.9696C10.2103 19.9479 10.2555 19.9161 10.2929 19.8763L12.7929 17.3763C12.8536 17.3205 12.8952 17.2469 12.9116 17.1661C12.9281 17.0853 12.9186 17.0014 12.8846 16.9263C12.8533 16.8502 12.8003 16.785 12.732 16.7391C12.6638 16.6931 12.5835 16.6683 12.5013 16.6679Z"
      fill={fill}
    />
    <path
      d="M7.50127 3.3346H12.5013C12.5835 3.33419 12.6638 3.30944 12.732 3.26347C12.8003 3.21749 12.8533 3.15236 12.8846 3.07627C12.9186 3.00116 12.9281 2.91722 12.9116 2.83642C12.8952 2.75562 12.8536 2.68207 12.7929 2.62627L10.2929 0.126266C10.2555 0.0864065 10.2103 0.054638 10.1602 0.0329211C10.11 0.0112043 10.0559 0 10.0013 0C9.9466 0 9.89252 0.0112043 9.84235 0.0329211C9.79219 0.054638 9.74701 0.0864065 9.7096 0.126266L7.2096 2.62627C7.14889 2.68207 7.10735 2.75562 7.09089 2.83642C7.07443 2.91722 7.0839 3.00116 7.11793 3.07627C7.14919 3.15236 7.20227 3.21749 7.27049 3.26347C7.33871 3.30944 7.419 3.33419 7.50127 3.3346Z"
      fill={fill}
    />
    <path
      d="M19.8763 9.7346L17.3763 7.2346C17.3205 7.17389 17.2469 7.13235 17.1661 7.11589C17.0853 7.09943 17.0014 7.1089 16.9263 7.14293C16.8534 7.17159 16.7903 7.22061 16.7445 7.28415C16.6987 7.34768 16.6721 7.42305 16.6679 7.50127V12.5013C16.6683 12.5835 16.6931 12.6638 16.7391 12.732C16.785 12.8003 16.8502 12.8533 16.9263 12.8846C16.9784 12.8964 17.0325 12.8964 17.0846 12.8846C17.1392 12.8853 17.1934 12.8745 17.2437 12.853C17.2939 12.8314 17.3391 12.7996 17.3763 12.7596L19.8763 10.2596C19.9396 10.1868 19.9744 10.0936 19.9744 9.9971C19.9744 9.90062 19.9396 9.80739 19.8763 9.7346Z"
      fill={fill}
    />
    <path
      d="M3.07627 7.14293C3.00092 7.11014 2.91731 7.10135 2.8368 7.11775C2.75629 7.13415 2.68278 7.17495 2.62627 7.2346L0.126266 9.7346C0.0864065 9.77201 0.054638 9.81719 0.0329211 9.86735C0.0112043 9.91752 0 9.9716 0 10.0263C0 10.0809 0.0112043 10.135 0.0329211 10.1852C0.054638 10.2353 0.0864065 10.2805 0.126266 10.3179L2.62627 12.8179C2.66347 12.858 2.70864 12.8898 2.75887 12.9113C2.8091 12.9328 2.86329 12.9436 2.91793 12.9429C2.97005 12.9547 3.02415 12.9547 3.07627 12.9429C3.16231 12.9085 3.23443 12.8464 3.28121 12.7664C3.328 12.6864 3.34678 12.5931 3.3346 12.5013V7.50127C3.33044 7.42305 3.30388 7.34768 3.25807 7.28415C3.21227 7.22061 3.14916 7.17159 3.07627 7.14293Z"
      fill={fill}
    />
  </svg>
);

Arrows360.propTypes = {
  fill: PropTypes.string,
};

Arrows360.defaultProps = {
  fill: '#0065FF',
};

export default Arrows360;
