export default {
  LOAD_DASHBOARD_START: 'LOAD_DASHBOARD_START',
  LOAD_DASHBOARD_SUCCESS: 'LOAD_DASHBOARD_SUCCESS',
  LOAD_DASHBOARD_FAILURE: 'LOAD_DASHBOARD_FAILURE',
  LOAD_FOLDERS_START: 'LOAD_FOLDERS_START',
  LOAD_FOLDERS_SUCCESS: 'LOAD_FOLDERS_SUCCESS',
  LOAD_FOLDERS_FAILURE: 'LOAD_FOLDERS_FAILURE',
  DELETE_MAP_SUCCESS: 'DELETE_MAP_SUCCESS',
  DELETE_MAP_FAILURE: 'DELETE_MAP_FAILURE',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',
  DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_FAILURE: 'DELETE_FOLDER_FAILURE',
  EMPTY_FOLDER_SUCCESS: 'EMPTY_FOLDER_SUCCESS',
  EMPTY_FOLDER_FAILURE: 'EMPTY_FOLDER_FAILURE',
  MOVE_MAP_TO_FOLDER_SUCCESS: 'MOVE_MAP_TO_FOLDER_SUCCESS',
  MOVE_MAPS_TO_FOLDER_SUCCESS: 'MOVE_MAPS_TO_FOLDER_SUCCESS',
  MOVE_MAPS_TO_FOLDER_FAILURE: 'MOVE_MAPS_TO_FOLDER_FAILURE',
  MOVE_FOLDER_TO_MAP_FAILURE: 'MOVE_FOLDER_TO_MAP_FAILURE',
  MERGE_MAP_START: 'MERGE_MAP_START',
  MERGE_MAP_SUCCESS: 'MERGE_MAP_SUCCESS',
  MERGE_MAP_FAILURE: 'MERGE_MAP_FAILURE',
  REMOVE_MAP_FROM_FOLDER_SUCCESS: 'REMOVE_MAP_FROM_FOLDER_SUCCESS',
  REMOVE_MAP_FROM_FOLDER_FAILURE: 'REMOVE_MAP_FROM_FOLDER_FAILURE',
  TOGGLE_OPEN_FOLDER: 'TOGGLE_OPEN_FOLDER',
};
