import { actionTypes } from '../actions/draw';
import filterActionTypes from '../actions/filters/actions';
import mapActionTypes from '../actions/map/actions';
import settingsActionTypes from '../actions/settings/actions';

const ACTION_HANDLERS = {
  [actionTypes.START_DRAWING]: (state, action) => ({
    ...state,
    toolId: action.toolId,
    toolType: action.toolType,
    properties: action.properties,
    persist: action.persist,
  }),
  [actionTypes.STOP_DRAWING]: (state, action) => initialState,
  [actionTypes.UPDATE_DRAWING_FEATURE]: (state, action) => {
    const { feature } = action;
    return {
      ...state,
      drawingFeature: feature,
    };
  },
  [actionTypes.UPDATE_DRAWING_FEATURE_CENTER]: (state, action) => {
    const { center } = action;
    return {
      ...state,
      drawingFeature: {
        ...state.drawingFeature,
        geometry: {
          ...state.drawingFeature.geometry,
          center,
        },
      },
    };
  },
  [mapActionTypes.CREATE_TOOL]: (state, action) => {
    if (!action.customData) {
      return state;
    }

    const {
      customData: { cut: isCutting },
    } = action;
    if (!isCutting) {
      return initialState;
    }

    return state;
  },
  [filterActionTypes.CHANGE_FEATURE_ID]: (state, action) => {
    if (!state.toolId || !state.toolType || state.toolId !== action.toolId) {
      return state;
    }

    return {
      ...state,
      toolId: action.newId,
    };
  },
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

// Reducer
const initialState = {
  toolId: null,
  toolType: null,
  properties: null,
  persist: true,
  drawingFeature: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
