import { NonConfigurableIconProps } from '../types';

const OpenStreet = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5489 21.8133C11.6686 21.9329 11.8308 22 12 22C12.1692 22 12.3314 21.9329 12.4511 21.8133L21.8133 12.4511C21.9329 12.3314 22 12.1692 22 12C22 11.8308 21.9329 11.6686 21.8133 11.5489L12.4511 2.18668C12.3314 2.06714 12.1692 2 12 2C11.8308 2 11.6686 2.06714 11.5489 2.18668L2.18668 11.5489C2.06714 11.6686 2 11.8308 2 12C2 12.1692 2.06714 12.3314 2.18668 12.4511L11.5489 21.8133ZM8.80833 8.59555C8.81465 8.42827 8.88393 8.26956 9.0023 8.15119C9.12067 8.03282 9.27939 7.96354 9.44667 7.95722H11.1489C11.2747 7.95637 11.3978 7.9932 11.5025 8.06298C11.6072 8.13276 11.6886 8.23229 11.7362 8.34873C11.7852 8.46545 11.7985 8.59413 11.7742 8.7184C11.75 8.84268 11.6893 8.95693 11.6 9.04664L11.4468 9.19984C11.4268 9.21986 11.4112 9.24381 11.4009 9.27019C11.3906 9.29656 11.386 9.32477 11.3872 9.35304C11.3865 9.38203 11.3922 9.41081 11.404 9.4373C11.4158 9.4638 11.4333 9.48734 11.4553 9.50624L12.6894 10.5872C12.9411 10.8064 13.1427 11.0772 13.2808 11.3812C13.4188 11.6851 13.49 12.0151 13.4894 12.349V15.83C13.4894 15.9993 13.4222 16.1617 13.3025 16.2814C13.1828 16.4011 13.0204 16.4683 12.8511 16.4683C12.6818 16.4683 12.5195 16.4011 12.3997 16.2814C12.28 16.1617 12.2128 15.9993 12.2128 15.83V12.349C12.2129 12.1971 12.1803 12.0471 12.1171 11.909C12.054 11.7709 11.9618 11.6481 11.8468 11.5489L10.5361 10.3914C10.4967 10.3549 10.4451 10.3346 10.3914 10.3346C10.3377 10.3346 10.2861 10.3549 10.2467 10.3914L9.89775 10.7318C9.77713 10.85 9.61554 10.9171 9.44667 10.9191C9.36181 10.9186 9.27791 10.9013 9.19984 10.868C9.08606 10.8215 8.98837 10.7428 8.9188 10.6415C8.84923 10.5401 8.81083 10.4207 8.80833 10.2978V8.59555Z"
        fill="black"
      />
    </svg>
  );
};

export default OpenStreet;
