import { useState, useEffect } from 'react';
import ProgressBar from 'components/ProgressBar';
import logoImage from 'static/images/LandId.svg';

import * as colors from 'components/ui/styles/colors.module.scss';
import * as styles from './styles.module.scss';

interface PageLoaderProps {
  staticContent?: boolean;
  isLoaded: boolean;
}

const PageLoader = ({ staticContent = false, isLoaded }: PageLoaderProps) => {
  const [progress, setProgress] = useState(0);
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress <= 80 ? prevProgress + 10 : prevProgress,
      );
    }, 100);

    setIntervalRef(interval);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setProgress(100);
    }
  }, [isLoaded]);

  if (progress >= 100 && isLoaded) {
    if (intervalRef) {
      clearInterval(intervalRef);
    }
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.background} />

      <div className={styles.content}>
        <img className={styles.logo} src={logoImage} />

        {staticContent ?
          <span className={styles.title}>Loading map experience</span>
        : <div className={styles.progressBarContainer}>
            <ProgressBar
              progress={progress}
              height={9}
              width={181}
              fillColor={colors.white}
              backgroundColor="rgba(74, 74, 74, 0.2)"
            />
          </div>
        }
      </div>
    </div>
  );
};

export default PageLoader;
