import { NonConfigurableIconProps } from '../types';

const CompassDirection = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM17.7083 7.83333L13.0167 18.925C12.9516 19.0897 12.8357 19.2294 12.6859 19.3238C12.536 19.4182 12.36 19.4624 12.1833 19.45H12.0583C11.873 19.4243 11.6995 19.3441 11.56 19.2194C11.4205 19.0947 11.3213 18.9313 11.275 18.75L10.1667 14.2L5.625 13.0583C5.4437 13.0121 5.28029 12.9129 5.15562 12.7733C5.03094 12.6338 4.95066 12.4603 4.925 12.275C4.89813 12.0855 4.9341 11.8925 5.02742 11.7254C5.12074 11.5583 5.26625 11.4265 5.44167 11.35L16.5083 6.65C16.6771 6.5827 16.8619 6.56657 17.0397 6.60361C17.2175 6.64066 17.3805 6.72925 17.5083 6.85833C17.6339 6.98316 17.7198 7.14224 17.7554 7.31567C17.791 7.4891 17.7746 7.66916 17.7083 7.83333Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default CompassDirection;
