import * as colors from 'components/ui/styles/colors.module.scss';
const PrintsAndReports = ({ fill = colors.grayScale200 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10.0312C5 9.61704 5.33579 9.28125 5.75 9.28125H18.25C18.6642 9.28125 19 9.61704 19 10.0312C19 10.4455 18.6642 10.7812 18.25 10.7812H5.75C5.33579 10.7812 5 10.4455 5 10.0312Z"
      fill={fill}
    />
    <path
      d="M5.75 12.8125C5.33579 12.8125 5 13.1483 5 13.5625C5 13.9767 5.33579 14.3125 5.75 14.3125H18.25C18.6642 14.3125 19 13.9767 19 13.5625C19 13.1483 18.6642 12.8125 18.25 12.8125H5.75Z"
      fill={fill}
    />
    <path
      d="M5 17.125C5 16.7108 5.33579 16.375 5.75 16.375H18.25C18.6642 16.375 19 16.7108 19 17.125C19 17.5392 18.6642 17.875 18.25 17.875H5.75C5.33579 17.875 5 17.5392 5 17.125Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H20ZM9.5 6.5C9.5 7.05228 9.94772 7.5 10.5 7.5H20C20.2761 7.5 20.5 7.72386 20.5 8V20C20.5 20.2761 20.2761 20.5 20 20.5H4C3.72386 20.5 3.5 20.2761 3.5 20V4C3.5 3.72386 3.72386 3.5 4 3.5H9C9.27614 3.5 9.5 3.72386 9.5 4V6.5ZM20.5 6V4C20.5 3.72386 20.2761 3.5 20 3.5H16.7812V6H20.5ZM15.2812 6H11.5C11.2239 6 11 5.77614 11 5.5V3.5H15.2812V6Z"
      fill={fill}
    />
  </svg>
);

export default PrintsAndReports;
