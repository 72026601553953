import actionTypes from '../actions/subscriptions/actions';

const ACTION_HANDLERS = {
  [actionTypes.FETCH_USER_SUBSCRIPTIONS_SUCCESS]: (
    state,
    { response: { data } = {} },
  ) => ({ ...state, userSubscription: data }),
  [actionTypes.FETCH_ALL_USER_SUBSCRIPTIONS_SUCCESS]: (
    state,
    { allSubscriptions: { data } = {} },
  ) => ({ ...state, allUserSubscription: data }),
  [actionTypes.FETCH_SUBSCRIPTIONS_PLANS_SUCCESS]: (state, action) => {
    const purchasablePlans = action.response.data.plans.filter(
      (plan) => plan.purchasable,
    );

    return {
      ...state,
      purchasablePlans,
      plans: action.response.data.plans,
      pricingSchema: action.response.data.pricing_schema,
    };
  },
  [actionTypes.PATCH_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    userSubscription: action.response.data,
  }),
};

const initialState = {
  plans: [],
  userSubscription: undefined,
  allUserSubscription: undefined,
  purchasablePlans: [],
  pricingSchema: {},
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
