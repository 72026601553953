import React from 'react';

import Button from 'components/ui/Button';
import Modal from 'components/Modal';

import './styles.scss';

const SyncOutdatedMap = (props) => (
  <Modal
    header={{ title: 'You Have Unsaved Changes', icon: 'information' }}
    className="sync-outdated-map-modal"
    {...props}
  >
    <main>
      Your Discover Map has been edited on another device. Click the button
      below to sync your map.
    </main>
    <Modal.Footer>
      <Button icon="refresh" color="blue" onClick={() => location.reload()}>
        Sync Changes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default SyncOutdatedMap;
