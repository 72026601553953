import { NonConfigurableIconProps } from '../types';

const Convert = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42214 21H16.5323C16.8343 21 17.124 20.8784 17.3375 20.662C17.5511 20.4457 17.6711 20.1522 17.6711 19.8462C17.6711 19.5401 17.5511 19.2466 17.3375 19.0303C17.124 18.8139 16.8343 18.6923 16.5323 18.6923H7.42214C7.00005 18.6899 6.59592 18.5189 6.29746 18.2165C5.99899 17.9141 5.83025 17.5046 5.82786 17.0769V13.1538C5.82786 13.0926 5.85185 13.0339 5.89457 12.9907C5.93728 12.9474 5.99521 12.9231 6.05561 12.9231H6.96663C7.06076 12.9332 7.15561 12.9126 7.23749 12.8645C7.31937 12.8164 7.38403 12.7432 7.42214 12.6554C7.46183 12.5769 7.47698 12.4881 7.46559 12.4007C7.4542 12.3133 7.4168 12.2315 7.35837 12.1662L5.08082 9.39692C5.03896 9.34482 4.98618 9.30283 4.92629 9.27398C4.86641 9.24514 4.80094 9.23017 4.73464 9.23017C4.66833 9.23017 4.60286 9.24514 4.54298 9.27398C4.4831 9.30283 4.43031 9.34482 4.38845 9.39692L2.11091 12.1662C2.05248 12.2315 2.01508 12.3133 2.00369 12.4007C1.99229 12.4881 2.00745 12.5769 2.04714 12.6554C2.07903 12.7287 2.12958 12.7921 2.19361 12.8392C2.25763 12.8862 2.33285 12.9152 2.41154 12.9231H3.32256C3.38297 12.9231 3.4409 12.9474 3.48361 12.9907C3.52632 13.0339 3.55032 13.0926 3.55032 13.1538V17.0769C3.55272 18.1166 3.96142 19.1131 4.687 19.8483C5.41259 20.5835 6.39601 20.9976 7.42214 21Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.2654 14.7692C19.3316 14.7693 19.3971 14.7544 19.4569 14.7257C19.5168 14.697 19.5696 14.6551 19.6115 14.6031L21.8891 11.8338C21.9475 11.7685 21.9849 11.6867 21.9963 11.5993C22.0077 11.5119 21.9925 11.4231 21.9529 11.3446C21.9175 11.2645 21.8599 11.1966 21.7872 11.149C21.7144 11.1015 21.6295 11.0764 21.5429 11.0769H20.6319C20.5715 11.0769 20.5136 11.0526 20.4708 11.0093C20.4281 10.9661 20.4041 10.9074 20.4041 10.8462V6.92308C20.4041 5.88261 19.9962 4.88476 19.2701 4.14904C18.544 3.41332 17.5592 3 16.5323 3H7.42214C7.12012 3 6.83047 3.12157 6.61691 3.33795C6.40334 3.55434 6.28337 3.84783 6.28337 4.15385C6.28337 4.45987 6.40334 4.75335 6.61691 4.96974C6.83047 5.18613 7.12012 5.30769 7.42214 5.30769H16.5323C16.9551 5.30769 17.3607 5.47788 17.6596 5.78083C17.9586 6.08377 18.1266 6.49465 18.1266 6.92308V10.8462C18.1266 10.9074 18.1026 10.9661 18.0599 11.0093C18.0172 11.0526 17.9592 11.0769 17.8988 11.0769H16.9878C16.9012 11.0764 16.8164 11.1015 16.7436 11.149C16.6708 11.1966 16.6132 11.2645 16.5779 11.3446C16.5382 11.4231 16.523 11.5119 16.5344 11.5993C16.5458 11.6867 16.5832 11.7685 16.6416 11.8338L18.9192 14.6031C18.9611 14.6551 19.0139 14.697 19.0738 14.7257C19.1337 14.7544 19.1991 14.7693 19.2654 14.7692Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Convert;
