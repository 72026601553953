import { StyleableOnlyIconProps } from '../types';

const DocumentHorizontal = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.06102 10.2277C2.02195 10.2668 2 10.3197 2 10.375L2 19.3333C2 19.7754 2.17559 20.1993 2.48816 20.5118C2.80072 20.8244 3.22464 21 3.66667 21L20.3333 21C20.7754 21 21.1993 20.8244 21.5118 20.5118C21.8244 20.1993 22 19.7754 22 19.3333L22 6C22 5.55797 21.8244 5.13405 21.5118 4.82149C21.1993 4.50893 20.7754 4.33333 20.3333 4.33333L8.04167 4.33333C7.98641 4.33333 7.93342 4.35528 7.89435 4.39435L2.06102 10.2277ZM3.5 11L3.5 19.3333C3.5 19.3775 3.51756 19.4199 3.54882 19.4512C3.58007 19.4824 3.62246 19.5 3.66667 19.5L20.3333 19.5C20.3775 19.5 20.4199 19.4824 20.4512 19.4512C20.4824 19.4199 20.5 19.3775 20.5 19.3333L20.5 6C20.5 5.9558 20.4824 5.91341 20.4512 5.88215C20.4199 5.85089 20.3775 5.83333 20.3333 5.83333L9 5.83333L9 9.4061C9 9.82883 8.83199 10.2342 8.53292 10.5332C8.23386 10.8321 7.82825 11 7.40531 11L3.5 11Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 8.08333L7 4.83333C6.99943 4.79205 6.9866 4.75187 6.96315 4.71789C6.9397 4.68391 6.90668 4.65766 6.86828 4.64248C6.82989 4.6273 6.78785 4.62387 6.7475 4.63263C6.70715 4.64139 6.67031 4.66193 6.64167 4.69167L2.35 8.975C2.3235 9.0055 2.30606 9.04281 2.29965 9.0827C2.29323 9.12259 2.2981 9.16348 2.3137 9.20076C2.32931 9.23803 2.35502 9.27019 2.38794 9.29362C2.42086 9.31705 2.45967 9.3308 2.5 9.33333L5.75 9.33333C6.08152 9.33333 6.39946 9.20164 6.63388 8.96722C6.8683 8.73279 7 8.41485 7 8.08333Z"
          fill="black"
        />
        <path
          d="M7.83333 4.54167L7.83333 8.08333C7.83333 8.63587 7.61384 9.16577 7.22314 9.55647C6.83244 9.94717 6.30253 10.1667 5.75 10.1667L2.20833 10.1667C2.15308 10.1667 2.10009 10.1886 2.06102 10.2277C2.02195 10.2668 2 10.3197 2 10.375L2 19.3333C2 19.7754 2.17559 20.1993 2.48816 20.5118C2.80072 20.8244 3.22464 21 3.66667 21L20.3333 21C20.7754 21 21.1993 20.8244 21.5118 20.5118C21.8244 20.1993 22 19.7754 22 19.3333L22 6C22 5.55797 21.8244 5.13405 21.5118 4.82149C21.1993 4.50893 20.7754 4.33333 20.3333 4.33333L8.04167 4.33333C7.98641 4.33333 7.93342 4.35528 7.89435 4.39435C7.85528 4.43342 7.83333 4.48641 7.83333 4.54167Z"
          fill="black"
        />
      </svg>;
};

export default DocumentHorizontal;
