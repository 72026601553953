import { NonConfigurableIconProps } from '../types';

const MapCheck = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0396 9V6.78038C19.0397 6.56662 18.9756 6.35777 18.8558 6.18076C18.736 6.00376 18.5658 5.86672 18.3674 5.78735L14.0901 4.07646C13.8352 3.97451 13.5509 3.97451 13.296 4.07646L8.74361 5.89784C8.48871 5.99978 8.20437 5.99978 7.94947 5.89784L3.73355 4.21119C3.65221 4.17858 3.56409 4.16648 3.47697 4.17594C3.38985 4.18539 3.30638 4.21612 3.23393 4.2654C3.16147 4.31469 3.10224 4.38104 3.06145 4.45861C3.02066 4.53617 2.99956 4.62257 3.00001 4.7102V14.9563C2.99997 15.1701 3.064 15.3789 3.18382 15.5559C3.30365 15.7329 3.47377 15.87 3.67224 15.9493L7.94947 17.6602C8.20437 17.7622 8.48871 17.7622 8.74361 17.6602L10 17"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.34668 5.97412V17.7365"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6929 4.00024V9.50006"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 11C16.0111 11 15.0444 11.2932 14.2222 11.8427C13.3999 12.3921 12.759 13.173 12.3806 14.0866C12.0022 15.0002 11.9031 16.0055 12.0961 16.9755C12.289 17.9454 12.7652 18.8363 13.4645 19.5355C14.1637 20.2348 15.0546 20.711 16.0245 20.9039C16.9945 21.0969 17.9998 20.9978 18.9134 20.6194C19.827 20.241 20.6079 19.6001 21.1573 18.7778C21.7068 17.9556 22 16.9889 22 16C21.998 14.6745 21.4705 13.404 20.5333 12.4667C19.596 11.5295 18.3255 11.002 17 11ZM19.6923 13.9077C19.7731 13.9683 19.8412 14.0442 19.8927 14.1312C19.9441 14.2181 19.978 14.3143 19.9923 14.4143C20.0066 14.5143 20.001 14.6161 19.9759 14.714C19.9509 14.8118 19.9068 14.9038 19.8462 14.9846L17.4231 18.2154C17.3015 18.3749 17.1475 18.5068 16.9711 18.6023C16.7947 18.6978 16.6 18.7547 16.4 18.7692C16.2005 18.7831 16.0004 18.7539 15.8133 18.6836C15.6261 18.6132 15.4563 18.5034 15.3154 18.3615L14.0769 17.1077C13.9964 17.0387 13.931 16.9539 13.8848 16.8584C13.8387 16.763 13.8127 16.6591 13.8086 16.5531C13.8045 16.4472 13.8224 16.3415 13.861 16.2428C13.8997 16.1441 13.9584 16.0545 14.0333 15.9795C14.1083 15.9045 14.198 15.8459 14.2967 15.8072C14.3954 15.7685 14.501 15.7507 14.607 15.7548C14.7129 15.7589 14.8169 15.7848 14.9123 15.831C15.0077 15.8772 15.0926 15.9426 15.1615 16.0231L16.1385 17C16.1586 17.0197 16.1828 17.0347 16.2094 17.044C16.236 17.0533 16.2643 17.0567 16.2923 17.0538C16.3199 17.0533 16.347 17.046 16.3711 17.0326C16.3952 17.0192 16.4157 17.0001 16.4308 16.9769L18.6231 14.0769C18.743 13.9135 18.9227 13.8041 19.1229 13.7724C19.3231 13.7407 19.5277 13.7893 19.6923 13.9077Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default MapCheck;
