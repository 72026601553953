import { NonConfigurableIconProps } from '../types';

const Expand = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9983 2.83333C21.9983 2.61232 21.9105 2.40036 21.7542 2.24408C21.5979 2.0878 21.386 2 21.165 2H15.7483C15.5838 2.00082 15.4232 2.05032 15.2867 2.14227C15.1503 2.23421 15.0441 2.36448 14.9816 2.51667C14.9178 2.66843 14.9004 2.83569 14.9315 2.99734C14.9627 3.159 15.041 3.30781 15.1566 3.425L16.9983 5.25C17.0182 5.2687 17.0341 5.29129 17.045 5.31638C17.0558 5.34146 17.0614 5.3685 17.0614 5.39583C17.0614 5.42316 17.0558 5.45021 17.045 5.47529C17.0341 5.50037 17.0182 5.52296 16.9983 5.54167L13.3483 9.18333C13.2515 9.27938 13.1747 9.39363 13.1223 9.51949C13.0699 9.64535 13.043 9.78033 13.043 9.91667C13.043 10.053 13.0699 10.188 13.1223 10.3138C13.1747 10.4397 13.2515 10.5539 13.3483 10.65C13.4438 10.7478 13.5578 10.8255 13.6838 10.8785C13.8097 10.9315 13.945 10.9589 14.0816 10.9589C14.2183 10.9589 14.3535 10.9315 14.4795 10.8785C14.6054 10.8255 14.7195 10.7478 14.815 10.65L18.4566 7C18.4753 6.98007 18.4979 6.96419 18.523 6.95333C18.5481 6.94247 18.5751 6.93687 18.6025 6.93687C18.6298 6.93687 18.6568 6.94247 18.6819 6.95333C18.707 6.96419 18.7296 6.98007 18.7483 7L20.5733 8.825C20.6498 8.90525 20.7415 8.96943 20.8431 9.01379C20.9447 9.05815 21.0541 9.08179 21.165 9.08333C21.386 9.08333 21.5979 8.99554 21.7542 8.83926C21.9105 8.68297 21.9983 8.47101 21.9983 8.25V2.83333Z"
        fill="#1D1D1D"
      />
      <path
        d="M2.83333 21.9997H8.25C8.41452 21.9988 8.57512 21.9493 8.71155 21.8574C8.84798 21.7654 8.95415 21.6352 9.01667 21.483C9.08048 21.3312 9.09792 21.164 9.06677 21.0023C9.03563 20.8407 8.9573 20.6918 8.84167 20.5747L7 18.7497C6.98007 18.731 6.96419 18.7084 6.95333 18.6833C6.94247 18.6582 6.93687 18.6312 6.93687 18.6038C6.93687 18.5765 6.94247 18.5494 6.95333 18.5244C6.96419 18.4993 6.98007 18.4767 7 18.458L10.6333 14.8163C10.8278 14.6218 10.9371 14.358 10.9371 14.083C10.9371 13.8079 10.8278 13.5441 10.6333 13.3497C10.4388 13.1552 10.1751 13.0459 9.9 13.0459C9.62495 13.0459 9.36116 13.1552 9.16667 13.3497L5.54167 16.9997C5.52296 17.0196 5.50037 17.0355 5.47529 17.0463C5.45021 17.0572 5.42316 17.0628 5.39583 17.0628C5.3685 17.0628 5.34146 17.0572 5.31638 17.0463C5.29129 17.0355 5.2687 17.0196 5.25 16.9997L3.425 15.158C3.30781 15.0424 3.159 14.964 2.99734 14.9329C2.83569 14.9017 2.66843 14.9192 2.51667 14.983C2.36448 15.0455 2.23421 15.1517 2.14227 15.2881C2.05032 15.4245 2.00082 15.5851 2 15.7497V21.1663C2 21.3873 2.0878 21.5993 2.24408 21.7556C2.40036 21.9119 2.61232 21.9997 2.83333 21.9997Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Expand;
