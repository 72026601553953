import { NonConfigurableIconProps } from '../types';

const HexSatellite = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.69167 8.8489C7.92604 9.08298 8.24375 9.21447 8.575 9.21447C8.90625 9.21447 9.22396 9.08298 9.45833 8.8489L10.6333 7.63223C10.8674 7.39786 10.9989 7.08015 10.9989 6.7489C10.9989 6.41765 10.8674 6.09994 10.6333 5.86557L7.075 2.36557C6.84062 2.13148 6.52292 2 6.19167 2C5.86042 2 5.54271 2.13148 5.30833 2.36557L4.13333 3.55723C4.01642 3.67272 3.9236 3.81027 3.86025 3.9619C3.7969 4.11353 3.76428 4.27623 3.76428 4.44057C3.76428 4.6049 3.7969 4.7676 3.86025 4.91923C3.9236 5.07086 4.01642 5.20841 4.13333 5.3239L7.69167 8.8489Z"
        fill="#1D1D1D"
      />
      <path
        d="M21.2166 16.5322L17.625 13.0156C17.3895 12.7835 17.0722 12.6534 16.7416 12.6534C16.4111 12.6534 16.0938 12.7835 15.8583 13.0156L14.6833 14.1989C14.4492 14.4333 14.3177 14.751 14.3177 15.0822C14.3177 15.4135 14.4492 15.7312 14.6833 15.9656L18.2333 19.4906C18.4688 19.7226 18.7861 19.8527 19.1166 19.8527C19.4472 19.8527 19.7645 19.7226 20 19.4906L21.175 18.2989C21.4127 18.069 21.5503 17.7547 21.5581 17.4241C21.5659 17.0935 21.4432 16.7731 21.2166 16.5322Z"
        fill="#1D1D1D"
      />
      <path
        d="M19.9343 5.95135C19.6602 6.13449 19.338 6.23223 19.0083 6.23223H19.0167C18.7971 6.23333 18.5795 6.19103 18.3763 6.10777C18.1731 6.0245 17.9884 5.90191 17.8328 5.74703C17.6771 5.59216 17.5536 5.40806 17.4693 5.2053C17.3851 5.00255 17.3417 4.78514 17.3417 4.56557C17.3417 4.23593 17.4394 3.9137 17.6226 3.63962C17.8057 3.36553 18.066 3.15191 18.3705 3.02577C18.6751 2.89962 19.0102 2.86662 19.3335 2.93092C19.6568 2.99523 19.9538 3.15397 20.1869 3.38706C20.4199 3.62014 20.5787 3.91711 20.643 4.24042C20.7073 4.56372 20.6743 4.89883 20.5481 5.20337C20.422 5.50792 20.2084 5.76821 19.9343 5.95135Z"
        fill="#1D1D1D"
      />
      <path
        d="M7.83333 17.4239C7.39131 17.4239 6.96738 17.2483 6.65482 16.9357C6.34226 16.6232 6.16667 16.1993 6.16667 15.7572C6.16667 15.5362 6.07887 15.3243 5.92259 15.168C5.76631 15.0117 5.55435 14.9239 5.33333 14.9239C5.11232 14.9239 4.90036 15.0117 4.74408 15.168C4.5878 15.3243 4.5 15.5362 4.5 15.7572C4.5 16.6413 4.85119 17.4891 5.47631 18.1143C6.10143 18.7394 6.94928 19.0906 7.83333 19.0906C8.05435 19.0906 8.26631 19.0028 8.42259 18.8465C8.57887 18.6902 8.66667 18.4782 8.66667 18.2572C8.66667 18.0362 8.57887 17.8243 8.42259 17.668C8.26631 17.5117 8.05435 17.4239 7.83333 17.4239Z"
        fill="#1D1D1D"
      />
      <path
        d="M4.76745 18.8231C5.47023 19.5259 6.42278 19.9217 7.41667 19.9239C7.63768 19.9239 7.84964 20.0117 8.00592 20.168C8.1622 20.3243 8.25 20.5362 8.25 20.7572C8.25 20.9782 8.1622 21.1902 8.00592 21.3465C7.84964 21.5028 7.63768 21.5906 7.41667 21.5906C5.98075 21.5884 4.60428 21.017 3.58894 20.0016C2.57359 18.9863 2.0022 17.6098 2 16.1739C2 15.9529 2.0878 15.7409 2.24408 15.5846C2.40036 15.4284 2.61232 15.3406 2.83333 15.3406C3.05435 15.3406 3.26631 15.4284 3.42259 15.5846C3.57887 15.7409 3.66667 15.9529 3.66667 16.1739C3.66887 17.1678 4.06466 18.1203 4.76745 18.8231Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.7917 6.7989C16.3229 6.33073 15.6875 6.06777 15.025 6.06777C14.3625 6.06777 13.7271 6.33073 13.2584 6.7989L9.53336 10.5406C9.45526 10.618 9.39326 10.7102 9.35095 10.8118C9.30865 10.9133 9.28687 11.0222 9.28687 11.1322C9.28687 11.2422 9.30865 11.3512 9.35095 11.4527C9.39326 11.5543 9.45526 11.6464 9.53336 11.7239L11.9 14.0656C12.0562 14.2208 12.2674 14.3079 12.4875 14.3079C12.7077 14.3079 12.9189 14.2208 13.075 14.0656L16.7917 10.2906C17.2473 9.82362 17.5023 9.1971 17.5023 8.54473C17.5023 7.89237 17.2473 7.26584 16.7917 6.7989Z"
        fill="#1D1D1D"
      />
      <path
        d="M6.25399 12.8826C6.33396 12.776 6.43832 12.6902 6.55832 12.6322C7.18025 12.3338 7.87944 12.2357 8.55949 12.3514C9.23953 12.4671 9.86693 12.791 10.3552 13.2783C10.8434 13.7656 11.1684 14.3924 11.2854 15.0722C11.4024 15.752 11.3056 16.4514 11.0083 17.0739C10.9512 17.1945 10.8659 17.2997 10.7597 17.3805C10.6535 17.4613 10.5294 17.5154 10.398 17.5382C10.2665 17.5611 10.1314 17.5521 10.0041 17.5119C9.87689 17.4717 9.76114 17.4015 9.66665 17.3072L6.33332 13.9739C6.23878 13.88 6.16814 13.7648 6.12733 13.6379C6.08652 13.5111 6.07672 13.3763 6.09876 13.2449C6.1208 13.1134 6.17403 12.9892 6.25399 12.8826Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default HexSatellite;
