import { ThemeableOnlyIconProps } from '../types';

const CloseCircle = ({
  width = '28',
  height = '28',
  theme = 'light',
}: ThemeableOnlyIconProps) => {
  return theme === 'light' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_115_1556)">
          <rect width="28" height="28" rx="14" fill="#E8E8E8" />
          <path
            d="M8.65355 18.055C8.31764 18.3909 8.31078 18.9873 8.66041 19.3369C9.01689 19.6866 9.61331 19.6797 9.94237 19.3507L14.0008 15.2923L18.0523 19.3438C18.3951 19.6866 18.9846 19.6866 19.3343 19.3369C19.6839 18.9805 19.6839 18.3978 19.3411 18.055L15.2896 14.0035L19.3411 9.94506C19.6839 9.60229 19.6907 9.01273 19.3343 8.66311C18.9846 8.31348 18.3951 8.31348 18.0523 8.65625L14.0008 12.7078L9.94237 8.65625C9.61331 8.32034 9.01003 8.30663 8.66041 8.66311C8.31078 9.01273 8.31764 9.61601 8.65355 9.94506L12.7051 14.0035L8.65355 18.055Z"
            fill="#686868"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_115_1556"
            x="-54.3656"
            y="-54.3656"
            width="136.731"
            height="136.731"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1828" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_115_1556"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_115_1556"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_115_1708)">
          <rect width="28" height="28" rx="14" fill="#4A4A4A" />
          <path
            d="M8.65355 18.055C8.31764 18.3909 8.31078 18.9873 8.66041 19.3369C9.01689 19.6866 9.61331 19.6797 9.94237 19.3507L14.0008 15.2923L18.0523 19.3438C18.3951 19.6866 18.9846 19.6866 19.3343 19.3369C19.6839 18.9805 19.6839 18.3978 19.3411 18.055L15.2896 14.0035L19.3411 9.94506C19.6839 9.60229 19.6907 9.01273 19.3343 8.66311C18.9846 8.31348 18.3951 8.31348 18.0523 8.65625L14.0008 12.7078L9.94237 8.65625C9.61331 8.32034 9.01003 8.30663 8.66041 8.66311C8.31078 9.01273 8.31764 9.61601 8.65355 9.94506L12.7051 14.0035L8.65355 18.055Z"
            fill="#B9B9B9"
          />
        </g>
        <defs>
          <filter
            id="filter0_b_115_1708"
            x="-54.3656"
            y="-54.3656"
            width="136.731"
            height="136.731"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1828" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_115_1708"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_115_1708"
              result="shape"
            />
          </filter>
        </defs>
      </svg>;
};

export default CloseCircle;
