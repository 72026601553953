const Panorama = () => {
  const filled = () => (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.61 0.0169337C18.96 -0.0430365 19.31 0.0569071 19.58 0.306783C19.85 0.556659 20 0.926485 20 1.31629V12.6806C20 13.0704 19.85 13.4403 19.58 13.6901C19.36 13.89 19.09 14 18.81 14C18.7479 14 18.6937 13.9921 18.6333 13.9834C18.6257 13.9822 18.6179 13.9811 18.61 13.98C13.44 13.0205 6.56 13.0205 1.39 13.98C1.04 14.04 0.69 13.94 0.42 13.6901C0.15 13.4403 0 13.0704 0 12.6806V1.31629C0 0.926485 0.15 0.556659 0.42 0.306783C0.69 0.0569071 1.04 -0.0430365 1.39 0.0169337C6.56 0.976457 13.45 0.976457 18.61 0.0169337ZM15.58 7.24334L18.53 11.0614H18.56V1.67611C13.39 2.59566 6.67 2.59566 1.5 1.67611V11.0614L4.45 7.24334C4.64 6.99346 5.01 6.98346 5.22 7.22334L6.53 8.74259L9.62 4.71459C9.82 4.45472 10.21 4.45472 10.41 4.71459L13.5 8.74259L14.81 7.22334C15.01 6.98346 15.39 6.99346 15.58 7.24334ZM5.22998 4.58509C5.22998 4.06509 5.64998 3.6551 6.15998 3.6551C6.66998 3.6551 7.08998 4.06509 7.08998 4.58509C7.08998 5.10509 6.67998 5.5151 6.15998 5.5151C5.63998 5.5151 5.22998 5.10509 5.22998 4.58509Z"
        fill="#0065FF"
      />
    </svg>
  );

  return filled();
};

export default Panorama;
