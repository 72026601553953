/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const UpperCase = ({ fill }) => (
  <svg
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99374 3.028C5.10574 3.532 5.31574 4.176 5.49774 4.694L6.19774 6.626H3.76174L4.46174 4.694C4.64374 4.176 4.85374 3.532 4.96574 3.028H4.99374ZM7.41574 10H9.76774V9.748L6.21174 0.899999H3.71974L0.163742 9.748V10H2.54374L3.11774 8.418H6.84174L7.41574 10ZM19.1466 4.96H14.7506V6.808H17.0886V7.704C16.5986 8.082 15.9686 8.334 15.0866 8.334C13.3366 8.334 12.3566 7.074 12.3566 5.464C12.3566 3.686 13.1546 2.608 14.8206 2.608C15.8426 2.608 16.5286 2.958 16.6826 3.784H18.9366V3.602C18.6426 1.544 17.0326 0.69 14.7506 0.69C12.0766 0.69 9.97665 2.538 9.97665 5.282V5.618C9.97665 8.236 11.8946 10.21 14.8766 10.21C17.1026 10.21 18.5586 9.132 19.1466 8.362V4.96Z"
      fill={fill}
    />
  </svg>
);

UpperCase.propTypes = {
  fill: PropTypes.string,
};

UpperCase.defaultProps = {
  fill: '#FFFFFF',
};

export default UpperCase;
