import { makeAuthenticatedRequest, makeRequest } from './utils/request';
import { getAPIURL, getParcelsAPIURL } from './utils/domains';
import store from '../store';

export const queryLayer = (layerName, layer, coordinates, sharing) => {
  switch (layerName) {
    case 'parcels':
      return queryParcelLayer(layer, coordinates, sharing);
    case 'fsa':
      return queryFsaLayer(coordinates);
  }
};

const queryParcelLayer = (layer, lngLat, sharing = false) => {
  const lng = lngLat.lng.toString();
  const lat = lngLat.lat.toString();
  const { user } = store.getState();

  if (sharing && !user.authenticated) {
    return makeRequest(
      `${getParcelsAPIURL()}/parcels/v2/by_location.json?lng=${lng}&lat=${lat}`,
    );
  }

  return makeAuthenticatedRequest(
    `${getParcelsAPIURL()}/parcels/v2/by_location.json?lng=${lng}&lat=${lat}`,
  );
};

const queryFsaLayer = (projectedCoords) => {
  const parameters = `point_x=${projectedCoords[0]}&point_y=${projectedCoords[1]}`;

  return makeAuthenticatedRequest(`${getAPIURL()}/fsa.json?${parameters}`);
};

export const loadOverlaysCategories = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/mapright_layers/categories.json`);

export const fetchPopupInfo = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/mapright_layers/popups.json`);

export default {
  queryLayer,
  loadOverlaysCategories,
  fetchPopupInfo,
};
