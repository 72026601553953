import { NonConfigurableIconProps } from '../types';
import * as colorPalette from '../../styles/colors.module.scss';

const ParcelId = ({
  width = '24',
  height = '24',
  color = colorPalette.black,
}: NonConfigurableIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M21.4609 8.31166L12.7654 2.22813C12.3306 1.92396 11.7567 1.92396 11.322 2.22813L2.62648 8.31166C2.25257 8.52893 2.01779 8.92001 2.0004 9.34586V20.696C1.98301 21.3999 2.53083 21.9822 3.23516 21.9996C3.26125 21.9996 3.27864 21.9996 3.30472 21.9996H20.6957C21.4 22.017 21.9826 21.4695 22 20.7655C22 20.7394 22 20.7221 22 20.696V9.34586C22 8.93739 21.8 8.54631 21.4609 8.31166ZM12.0002 8.52893C13.4437 8.52893 14.6088 9.69349 14.6088 11.1362C14.6088 12.5788 13.4437 13.7434 12.0002 13.7434C10.5568 13.7434 9.39156 12.5788 9.39156 11.1362C9.39156 9.69349 10.5568 8.52893 12.0002 8.52893ZM16.2871 18.8709C16.2349 18.9231 16.1653 18.9578 16.0871 18.9578H8.08724C7.9655 18.9578 7.84376 18.9057 7.7655 18.8101C7.67855 18.7145 7.64377 18.5928 7.65246 18.4712C7.70464 16.0725 9.6959 14.1692 12.0959 14.2214C14.4958 14.2735 16.4001 16.2637 16.3479 18.6623C16.3479 18.7319 16.3305 18.8101 16.2784 18.8622H16.2871V18.8709Z"
        fill={color}
      />
    </svg>
  );
};

export default ParcelId;
