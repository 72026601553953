import { useAppSelector } from 'store/hooks';
import { useFeatureFlags } from 'services/FeatureFlags';
import { isProPlan } from 'selectors/userSelectors';
import useEntitlement from './useEntitlement';
import { checkQuota } from '../utils';

const useCanCreateMaps = () => {
  const mapsCount = useAppSelector(
    (state) => state.user.user?.saved_maps_count,
  );
  const subscriptions = useAppSelector((state) => state.subscriptions);

  const { newPlanChangesCore299 } = useFeatureFlags();
  const createMapEntitlement = useEntitlement('CREATE_MAPS');

  if (mapsCount === undefined) {
    return false;
  }

  if (newPlanChangesCore299?.value) {
    return checkQuota(createMapEntitlement, mapsCount);
  }

  return !(isProPlan(subscriptions) && mapsCount > 2);
};

export default useCanCreateMaps;
