import { NonConfigurableIconProps } from '../types';

const MapWarning = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0396 9V6.78038C19.0397 6.56662 18.9756 6.35777 18.8558 6.18076C18.736 6.00376 18.5658 5.86672 18.3674 5.78735L14.0901 4.07646C13.8352 3.97451 13.5509 3.97451 13.296 4.07646L8.74361 5.89784C8.48871 5.99978 8.20437 5.99978 7.94947 5.89784L3.73355 4.21119C3.65221 4.17858 3.56409 4.16648 3.47697 4.17594C3.38985 4.18539 3.30638 4.21612 3.23393 4.2654C3.16147 4.31469 3.10224 4.38104 3.06145 4.45861C3.02066 4.53617 2.99956 4.62257 3.00001 4.7102V14.9563C2.99997 15.1701 3.064 15.3789 3.18382 15.5559C3.30365 15.7329 3.47377 15.87 3.67224 15.9493L7.94947 17.6602C8.20437 17.7622 8.48871 17.7622 8.74361 17.6602L10 17"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.34668 5.97412V17.7365"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6929 4.00024V9.50006"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 11C16.0111 11 15.0444 11.2932 14.2222 11.8427C13.3999 12.3921 12.759 13.173 12.3806 14.0866C12.0022 15.0002 11.9031 16.0055 12.0961 16.9755C12.289 17.9454 12.7652 18.8363 13.4645 19.5355C14.1637 20.2348 15.0546 20.711 16.0245 20.9039C16.9945 21.0969 17.9998 20.9978 18.9134 20.6194C19.827 20.241 20.6079 19.6001 21.1573 18.7779C21.7068 17.9556 22 16.9889 22 16C22 14.6739 21.4732 13.4021 20.5355 12.4645C19.5979 11.5268 18.3261 11 17 11V11ZM16.3182 13.2727C16.3182 13.0919 16.39 12.9185 16.5179 12.7906C16.6457 12.6627 16.8192 12.5909 17 12.5909C17.1808 12.5909 17.3543 12.6627 17.4821 12.7906C17.61 12.9185 17.6818 13.0919 17.6818 13.2727V16C17.6818 16.1808 17.61 16.3543 17.4821 16.4821C17.3543 16.61 17.1808 16.6818 17 16.6818C16.8192 16.6818 16.6457 16.61 16.5179 16.4821C16.39 16.3543 16.3182 16.1808 16.3182 16V13.2727ZM17 19.6364C16.8576 19.6498 16.7139 19.6334 16.5783 19.5881C16.4426 19.5428 16.3178 19.4697 16.212 19.3734C16.1063 19.2771 16.0217 19.1598 15.9639 19.029C15.9061 18.8981 15.8762 18.7567 15.8762 18.6136C15.8762 18.4706 15.9061 18.3291 15.9639 18.1983C16.0217 18.0675 16.1063 17.9502 16.212 17.8539C16.3178 17.7576 16.4426 17.6845 16.5783 17.6392C16.7139 17.5939 16.8576 17.5775 17 17.5909C17.2546 17.6149 17.4911 17.7331 17.6632 17.9222C17.8354 18.1114 17.9307 18.3579 17.9307 18.6136C17.9307 18.8694 17.8354 19.1159 17.6632 19.3051C17.4911 19.4942 17.2546 19.6123 17 19.6364Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default MapWarning;
