/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const AddBold = ({ fill }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.875 1.75C7.875 1.26675 7.48325 0.875 7 0.875C6.51675 0.875 6.125 1.26675 6.125 1.75V6.125H1.75C1.26675 6.125 0.875 6.51675 0.875 7C0.875 7.48325 1.26675 7.875 1.75 7.875H6.125V12.25C6.125 12.7332 6.51675 13.125 7 13.125C7.48325 13.125 7.875 12.7332 7.875 12.25V7.875H12.25C12.7332 7.875 13.125 7.48325 13.125 7C13.125 6.51675 12.7332 6.125 12.25 6.125H7.875V1.75Z"
      fill={fill}
    />
  </svg>
);

AddBold.propTypes = {
  fill: PropTypes.string,
};

AddBold.defaultProps = {
  fill: '#FFFFFF',
};

export default AddBold;
