import actionTypes from 'actions/deedPlotter/actions';
import settingsActionTypes from 'actions/settings/actions';
import update from 'immutability-helper';

const ACTION_HANDLERS = {
  [actionTypes.UPDATE_DEED_PLOTTER_FEATURE]: (state, action) => {
    const { feature } = action;
    return {
      ...state,
      deedPlotterFeature: update(state.deedPlotterFeature, { $set: feature }),
    };
  },
  [actionTypes.UPDATE_DEED_PLOTTER_STATUS]: (state, action) => {
    const { status } = action;
    return {
      ...state,
      deedPlotterStatus: status,
    };
  },
  [actionTypes.UPDATE_DEED_PLOTTER_INITIAL_POINT]: (state, action) => {
    const { point } = action;
    return {
      ...state,
      deedPlotterInitialPoint: point,
    };
  },
  [actionTypes.STOP_DEED_PLOTTING]: (state, action) => initialState,
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => initialState,
};

// Reducer
const initialState = {
  deedPlotterStatus: null,
  deedPlotterFeature: null,
  deedPlotterInitialPoint: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
