import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const ParcelOverview = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.374 4H2.62604C2.28381 4 2 4.30306 2 4.66852V19.3315C2 19.6969 2.28381 20 2.62604 20H21.374C21.7162 20 22 19.6969 22 19.3315V4.66852C22 4.30306 21.7162 4 21.374 4ZM20.7479 8.53705L12.1336 15.5521L8.74457 10.8279L15.4975 5.33705H20.7479V8.53705ZM13.4357 5.33705L3.25209 13.6357V5.33705H13.4357ZM3.25209 15.3114L7.7596 11.639L12.793 18.663H3.25209V15.3114ZM14.3623 18.663L12.8932 16.6217L20.7479 10.2217V18.663H14.3623Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.62499 5H15.0331L2 14.6415V5.62499C2 5.28333 2.28333 5 2.62499 5ZM12.1335 15.8248L8.65026 11.2749L17.1334 5H21.375C21.7167 5 22 5.27499 22 5.62499V8.29994L12.1335 15.8248ZM7.64989 12.0165L2 16.1997V19.3747C2 19.7246 2.28333 19.9996 2.62499 19.9996H13.7581L7.64989 12.0165ZM12.9039 16.8218L12.8999 16.8165V16.8249L12.9039 16.8218ZM12.9039 16.8218L15.3331 19.9998H21.3747C21.7163 19.9998 21.9997 19.7165 21.9997 19.3748V9.87499L12.9039 16.8218Z"
          fill={color}
        />
      </svg>;
};

export default ParcelOverview;
