import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Overlay = ({
  fill = colors.black,
  variant = 'outlined',
}: IconPropsWithVariant) => {
  const outlined = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C11.6751 4 11.3548 4.0778 11.0661 4.2269L2.68846 8.55811C2.48099 8.66556 2.30705 8.82795 2.18563 9.02756C2.06422 9.22717 2 9.45631 2 9.68995C2 9.92359 2.06422 10.1527 2.18563 10.3523C2.30705 10.5519 2.48131 10.7145 2.68877 10.8219L4.66664 11.8451L2.68846 12.8684C2.48099 12.9758 2.30705 13.1382 2.18563 13.3378C2.06422 13.5375 2 13.7666 2 14.0002C2 14.2339 2.06422 14.463 2.18563 14.6626C2.30705 14.8622 2.48131 15.0248 2.68877 15.1322L11.0661 19.4657C11.3548 19.6148 11.6751 19.6926 12 19.6926C12.3249 19.6926 12.6456 19.6146 12.9343 19.4655L21.3115 15.1321C21.519 15.0246 21.693 14.8622 21.8144 14.6626C21.9358 14.463 22 14.2339 22 14.0002C22 13.7666 21.9358 13.5375 21.8144 13.3378C21.693 13.1382 21.5187 12.9757 21.3112 12.8682L19.337 11.847L21.3115 10.8267C21.519 10.7193 21.693 10.5569 21.8144 10.3573C21.9358 10.1577 22 9.92851 22 9.69487C22 9.46123 21.9358 9.23209 21.8144 9.03249C21.7145 8.86836 21.5792 8.7294 21.4189 8.62545C21.3852 8.60023 21.3492 8.57758 21.3111 8.55787L12.9339 4.2269C12.6452 4.0778 12.3249 4 12 4ZM17.3329 11.1509C17.3162 11.1588 17.2998 11.1673 17.2836 11.1764L12.228 13.7886C12.1575 13.8251 12.0793 13.844 12 13.844C11.9207 13.844 11.8425 13.8251 11.772 13.7886L3.84868 9.69002L11.772 5.59371C11.8425 5.55731 11.9207 5.53831 12 5.53831C12.0793 5.53831 12.1575 5.55731 12.228 5.59371L20.1558 9.69234L17.3329 11.1509ZM17.662 12.7124L20.1515 14.0002L12.228 18.0989C12.1575 18.1353 12.0793 18.1543 12 18.1543C11.9207 18.1543 11.8425 18.1353 11.772 18.0989L3.84855 14.0002L6.34069 12.711L11.0661 15.1555C11.3548 15.3046 11.6751 15.3824 12 15.3824C12.3249 15.3824 12.6453 15.3045 12.934 15.1554L17.662 12.7124Z"
        fill={fill}
      />
    </svg>
  );

  const filled = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.77936 10.1265L10.9815 13.6915C11.3035 13.8321 11.6511 13.9046 12.0025 13.9046C12.3539 13.9046 12.7015 13.8321 13.0235 13.6915L21.2342 10.1265C21.4638 10.0239 21.6586 9.85656 21.7946 9.64499C21.9306 9.43342 22.0019 9.18679 21.9999 8.93528C22.0006 8.68619 21.9274 8.44249 21.7896 8.235C21.6518 8.02751 21.4555 7.86556 21.2257 7.76963L13.0235 4.2131C12.7015 4.07255 12.3539 4 12.0025 4C11.6511 4 11.3035 4.07255 10.9815 4.2131L2.77936 7.78664C2.55214 7.88145 2.3577 8.04078 2.22009 8.24493C2.08248 8.44908 2.00773 8.6891 2.00509 8.93528C2.0027 9.18797 2.07488 9.43576 2.2126 9.64763C2.35032 9.85951 2.54746 10.0261 2.77936 10.1265Z"
        fill="#0065FF"
      />
      <path
        d="M21.2257 12.8917L20.1706 12.4322C20.0633 12.3854 19.9474 12.3612 19.8303 12.3612C19.7132 12.3612 19.5973 12.3854 19.49 12.4322L12.0025 15.6825L4.5151 12.4322C4.40775 12.3854 4.29188 12.3612 4.17476 12.3612C4.05763 12.3612 3.94177 12.3854 3.83442 12.4322L2.77937 12.8917C2.54854 12.9881 2.35137 13.1506 2.21268 13.3587C2.074 13.5669 2 13.8115 2 14.0616C2 14.3118 2.074 14.5563 2.21268 14.7645C2.35137 14.9727 2.54854 15.1352 2.77937 15.2315L10.9815 18.7966C11.3036 18.9371 11.6511 19.0097 12.0025 19.0097C12.3539 19.0097 12.7015 18.9371 13.0235 18.7966L21.2257 15.2315C21.4576 15.1311 21.6547 14.9646 21.7924 14.7527C21.9302 14.5408 22.0023 14.293 21.9999 14.0403C21.9973 13.7942 21.9226 13.5541 21.7849 13.35C21.6473 13.1458 21.4529 12.9865 21.2257 12.8917Z"
        fill="#0065FF"
      />
    </svg>
  );

  return variant === 'outlined' ? outlined() : filled();
};

export default Overlay;
