import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { getAPIURL, isEmbeddedInIframe } from '../utils/domains';
import type { RootState } from 'store';

type QueryArgs = {
  url: string;
  body?: BodyInit;
  headers?: HeadersInit;
  withAuth?: boolean;
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE';
};

const customBaseQuery: BaseQueryFn<QueryArgs> = async (
  { url, method = 'GET', headers = {}, body, withAuth = false },
  api,
  extraOptions,
) => {
  const modifiedHeaders = new Headers(headers);

  modifiedHeaders.set('X-Embedded', isEmbeddedInIframe().toString());

  const state = api.getState() as RootState;
  if (withAuth && state.user.user) {
    modifiedHeaders.set('X-Auth-Email', state.user.user.email);
    modifiedHeaders.set('X-Auth-Token', state.user.user.authentication_token);

    const { selectedAccount } = state.user;

    if (selectedAccount?.type === 'organizations') {
      modifiedHeaders.set('Team-Id', selectedAccount.id.toString());
    }
  }

  const baseQuery = fetchBaseQuery({ baseUrl: getAPIURL() });
  const result = await baseQuery(
    { url, method, body, headers: modifiedHeaders, signal: api.signal },
    api,
    extraOptions,
  );

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
});

export default api;
