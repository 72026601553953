import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const Redirector = () => {
  const navigate = useNavigate();
  const { needsRedirect, redirectPath } = useSelector(
    (state) => state.redirect,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (needsRedirect) {
      navigate(redirectPath);
      dispatch({ type: 'CLEAR_REDIRECT' }); // Reset redirect state after navigation
    }
  }, [needsRedirect, redirectPath, navigate, dispatch]);

  return null;
};
