import { ApolloLink } from '@apollo/client';
import store from 'store';
import { isEmbeddedInIframe } from 'api/utils/domains';
import packageInfo from '../../../package.json';

export const authLink = new ApolloLink((operation, forward) => {
  const { user } = store.getState();

  const authHeaders: Record<string, string> = {};

  if (user?.authenticated) {
    const { email, authentication_token: authenticationToken } =
      user.user || {};
    if (email) {
      authHeaders['X-Auth-Email'] = email;
    }
    if (authenticationToken) {
      authHeaders['X-Auth-Token'] = authenticationToken;
    }

    if (user.selectedAccount?.type === 'organizations') {
      authHeaders['X-Team-Id'] = user.selectedAccount.id.toString();
    }
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...authHeaders,
      'X-App-Version': packageInfo.version,
      'X-Embedded': isEmbeddedInIframe(),
    },
  }));

  return forward(operation);
});
