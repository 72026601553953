import React from 'react';
import PropTypes from 'prop-types';

const Email = ({ fill }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.38178 11.8551C9.54639 12.015 9.76686 12.1045 9.99636 12.1045C10.2259 12.1045 10.4463 12.015 10.6109 11.8551L17.3401 5.1259C17.3854 5.08035 17.4172 5.02313 17.4319 4.9606C17.4466 4.89808 17.4437 4.83269 17.4234 4.77173C17.4029 4.71089 17.3662 4.65678 17.3173 4.61519C17.2683 4.5736 17.209 4.54609 17.1457 4.53562L16.9443 4.52173H2.854C2.79037 4.53146 2.73069 4.5587 2.68163 4.6004C2.63258 4.64209 2.59609 4.6966 2.57623 4.75784C2.55431 4.8186 2.55052 4.88442 2.56531 4.9473C2.5801 5.01017 2.61285 5.06739 2.65956 5.11201L9.38178 11.8551Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M18.2568 5.79256C18.2362 5.77883 18.2121 5.7715 18.1873 5.7715C18.1626 5.7715 18.1385 5.77883 18.1179 5.79256L13.8123 10.0981C13.7946 10.1138 13.7804 10.133 13.7707 10.1545C13.7609 10.1761 13.7559 10.1995 13.7559 10.2231C13.7559 10.2468 13.7609 10.2701 13.7707 10.2917C13.7804 10.3132 13.7946 10.3325 13.8123 10.3481L17.2846 13.8203C17.3766 13.9191 17.4266 14.0497 17.4243 14.1846C17.4219 14.3195 17.3672 14.4483 17.2718 14.5437C17.1764 14.6391 17.0476 14.6938 16.9127 14.6962C16.7778 14.6985 16.6472 14.6485 16.5484 14.5565L13.0762 11.0842C13.06 11.0674 13.0406 11.0541 13.0191 11.0449C12.9977 11.0358 12.9746 11.0311 12.9512 11.0311C12.9279 11.0311 12.9048 11.0358 12.8833 11.0449C12.8618 11.0541 12.8424 11.0674 12.8262 11.0842L11.3471 12.5634C10.988 12.92 10.5024 13.1202 9.99637 13.1202C9.49028 13.1202 9.00474 12.92 8.64567 12.5634L7.17345 11.112C7.15781 11.0943 7.13857 11.0801 7.11702 11.0703C7.09547 11.0606 7.07209 11.0556 7.04845 11.0556C7.0248 11.0556 7.00143 11.0606 6.97988 11.0703C6.95832 11.0801 6.93909 11.0943 6.92345 11.112L3.45123 14.5842C3.35249 14.6762 3.22191 14.7263 3.08697 14.7239C2.95204 14.7216 2.8233 14.6669 2.72788 14.5715C2.63245 14.476 2.57779 14.3473 2.57541 14.2124C2.57303 14.0774 2.62312 13.9469 2.71512 13.8481L6.18734 10.3759C6.20507 10.3603 6.21928 10.341 6.229 10.3195C6.23873 10.2979 6.24376 10.2745 6.24376 10.2509C6.24376 10.2273 6.23873 10.2039 6.229 10.1823C6.21928 10.1608 6.20507 10.1415 6.18734 10.1259L1.88178 5.82034C1.86123 5.80661 1.83706 5.79927 1.81234 5.79927C1.78762 5.79927 1.76345 5.80661 1.74289 5.82034C1.7237 5.82791 1.70668 5.84012 1.69335 5.85587C1.68002 5.87163 1.6708 5.89043 1.6665 5.91062V14.244C1.6665 14.6123 1.81283 14.9656 2.0733 15.226C2.33377 15.4865 2.68704 15.6328 3.05539 15.6328H16.9443C17.3126 15.6328 17.6659 15.4865 17.9264 15.226C18.1868 14.9656 18.3332 14.6123 18.3332 14.244V5.91062C18.3337 5.88555 18.3268 5.86088 18.3132 5.83983C18.2995 5.81878 18.2799 5.80231 18.2568 5.79256Z"
      fill={fill}
    />
  </svg>
);

Email.propTypes = {
  fill: PropTypes.string,
};

Email.defaultProps = {
  fill: '#1D1D1D',
};

export default Email;
