/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Boundary = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2902 12.3335L12.5383 5.50518L1.95769 1.75547L3.53212 12.3335H10.2902ZM10.417 13.6668H3.38115C2.80732 13.6668 2.31998 13.2464 2.23545 12.6785L0.596166 1.66478C0.467479 0.800173 1.30512 0.109611 2.12858 0.40144L13.1444 4.30541C13.738 4.51578 14.0549 5.16217 13.8579 5.76068L11.5171 12.8704C11.3607 13.3457 10.917 13.6668 10.417 13.6668Z"
      fill={fill}
    />
  </svg>
);

Boundary.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Boundary.defaultProps = {
  fill: 'white',
  width: '14',
  height: '14',
};

export default Boundary;
