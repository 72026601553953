import { StyleableOnlyIconProps } from '../types';

const CopyCrop = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.4141 17.1723C22.4141 17.7436 21.9509 18.2068 21.3796 18.2068H18.2761V20.6206C18.2761 21.1919 17.813 21.6551 17.2416 21.6551C16.6703 21.6551 16.2072 21.1919 16.2072 20.6206V18.2068L8.27613 18.2068C7.32392 18.2068 6.55199 17.4349 6.55199 16.4827V8.76714H8.62096L8.62096 16.1378L21.3796 16.1378C21.9509 16.1378 22.4141 16.601 22.4141 17.1723Z"
          fill="#1D1D1D"
        />
        <path
          d="M8.62096 3.37921V5.793H16.552C17.5042 5.793 18.2761 6.56492 18.2761 7.51714V15.2327H16.2072V7.86197H3.44855C2.87722 7.86197 2.41406 7.39881 2.41406 6.82749C2.41406 6.25616 2.87722 5.793 3.44855 5.793H6.55199V3.37921C6.55199 2.80788 7.01515 2.34473 7.58648 2.34473C8.15781 2.34473 8.62096 2.80788 8.62096 3.37921Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.4486 5.793V3.37921C9.4486 2.80788 8.98544 2.34473 8.41411 2.34473C7.84279 2.34473 7.37963 2.80788 7.37963 3.37921V5.793H4.27618C3.70485 5.793 3.2417 6.25616 3.2417 6.82749C3.2417 7.39881 3.70485 7.86197 4.27618 7.86197H17.0348V15.2327H19.1038V7.51714C19.1038 6.56492 18.3318 5.793 17.3796 5.793H9.4486Z"
          fill="#1D1D1D"
        />
        <path
          d="M22.2072 18.2068C22.7785 18.2068 23.2417 17.7436 23.2417 17.1723C23.2417 16.601 22.7785 16.1378 22.2072 16.1378L9.4486 16.1378L9.4486 8.76714H7.37963V16.4827C7.37963 17.4349 8.15155 18.2068 9.10377 18.2068L17.0348 18.2068V20.6206C17.0348 21.1919 17.498 21.6551 18.0693 21.6551C18.6406 21.6551 19.1038 21.1919 19.1038 20.6206V18.2068H22.2072Z"
          fill="#1D1D1D"
        />
        <path
          d="M16.1714 8.81244H10.2964V15.1562H16.1714V8.81244Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default CopyCrop;
