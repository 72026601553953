import { colorNameToHexa } from 'utils/colors';
import actionTypes from '../actions/filters/actions';
import mapActionTypes from '../actions/map/actions';

const DEFAULT_LABELING_STYLE = {
  font_size: 11,
  font_type: 'openSans',
  font_style: 'bold',
  font_color: 'white',
  uppercase: true,
};

const initializeStateWithData = (data) => {
  const tools = data.filters
    .reduce((acc, { tools }) => acc.concat(tools), [])
    .concat(data.generic_tools);

  return {
    ...initialState,
    filters: data.filters,
    tools,
  };
};

const updateTool = (tools, toolId, toolType, properties, values) =>
  tools.map((tool) => {
    if (tool.id === toolId && tool.tool_type === toolType) {
      const newTool = { ...tool };

      properties.forEach((prop, index) => {
        newTool[prop] = values[index];
      });

      return newTool;
    }

    return tool;
  });

const getDuplicatedToolName = (
  tools,
  duplicatedTools,
  baseToolId,
  baseToolType,
) => {
  const duplicatedCount = duplicatedTools.reduce((count, tool) => {
    if (tool.toolId === baseToolId && tool.toolType === baseToolType) {
      return count + 1;
    }

    return count;
  }, 1);

  const originalTool = tools.find(
    (tool) => tool.id === baseToolId && tool.tool_type === baseToolType,
  );

  return `${originalTool.name} ${duplicatedCount}`;
};

const addDuplicatedTool = (tools, baseToolId, baseToolType, duplicatedTool) => {
  const baseToolIndex = tools.findIndex(
    (tool) => tool.id === baseToolId && tool.tool_type === baseToolType,
  );

  if (baseToolIndex === -1) {
    return tools;
  }

  return tools
    .slice(0, baseToolIndex + 1)
    .concat(duplicatedTool)
    .concat(tools.slice(baseToolIndex + 1));
};

const updateToolStyling = (
  tools,
  featureInstance,
  detailId,
  oldDetailValue,
  newDetailValue,
  features,
) =>
  tools.map((tool) => {
    const {
      properties: { toolId, toolType },
    } = featureInstance;
    if (tool.id === toolId && tool.tool_type === toolType) {
      return {
        ...tool,
        data: {
          ...tool.data,
          details: tool.data.details.map((detail) => {
            if (detail.id === detailId) {
              let styling = detail.styling ? [...detail.styling] : [];

              const featuresWithOldValue = features
                .map((feature) => feature.properties.details)
                .filter(
                  (details) =>
                    details &&
                    details.some(
                      (featureDetail) =>
                        featureDetail.id === detailId &&
                        featureDetail.value === oldDetailValue,
                    ),
                );

              const styleIndex = styling.findIndex(
                (style) => style.name === oldDetailValue,
              );
              let newStyle =
                styleIndex < 0 ?
                  tool.has_color ?
                    { ...tool.style, color: colorNameToHexa(tool.color) }
                  : { ...tool.style }
                : styling[styleIndex];

              if (styleIndex < 0 && tool.tool_type === 'icons') {
                if (tool.shape_less) {
                  newStyle = { color: tool.color };
                } else {
                  newStyle = { color: tool.color, shape: tool.shape };
                }
              }

              if (featuresWithOldValue.length === 1) {
                styling = styling.filter(
                  (style) => style.name !== oldDetailValue,
                );
              }

              if (
                newDetailValue &&
                !styling.some((style) => style.name === newDetailValue)
              ) {
                styling.push({
                  ...newStyle,
                  name: newDetailValue,
                });
              }

              return {
                ...detail,
                styling,
              };
            }

            return detail;
          }),
        },
      };
    }

    return tool;
  });

const getToolDefaultLabeling = (toolType) => {
  switch (toolType) {
    case 'icons':
      DEFAULT_LABELING_STYLE.font_size = 8;
      DEFAULT_LABELING_STYLE.textAnchor = 'top-right';
      break;
    case 'polylines':
      DEFAULT_LABELING_STYLE.textAnchor = 'top';
      break;
    case 'polygons':
      DEFAULT_LABELING_STYLE.textAnchor = 'center';
      break;
    case 'circle_polygons':
      DEFAULT_LABELING_STYLE.textAnchor = 'center';
      break;
  }

  return DEFAULT_LABELING_STYLE;
};

const ACTION_HANDLERS = {
  [actionTypes.GET_MAP_FILTERS_SUCCESS]: (state, action) =>
    initializeStateWithData(action.data),
  [actionTypes.CHANGE_FEATURE_ID]: (state, action) => {
    const { toolId, toolType, newId } = action;
    const filters = state.filters.map((filter) => ({
      ...filter,
      tools: updateTool(filter.tools, toolId, toolType, ['id'], [newId]),
    }));
    const tools = updateTool(state.tools, toolId, toolType, ['id'], [newId]);

    return {
      ...state,
      filters,
      tools,
      modified: [
        ...state.modified,
        {
          toolId,
          toolType,
          newId,
        },
      ],
    };
  },
  [actionTypes.CHANGE_FEATURE_PROPERTIES]: (state, action) => {
    const { toolId, toolType, properties, values } = action;

    return {
      ...state,
      filters: state.filters.map((filter) => ({
        ...filter,
        tools: updateTool(filter.tools, toolId, toolType, properties, values),
      })),
      tools: updateTool(state.tools, toolId, toolType, properties, values),
    };
  },
  [actionTypes.CHANGE_FEATURE_LIST_FILTER]: (state, action) => {
    const { filter: featureListFilter } = action;
    return {
      ...state,
      featureListFilter,
    };
  },
  [actionTypes.CHANGE_FEATURE_INSTANCE_DETAIL]: (state, action) => {
    const {
      featureInstance,
      detailId,
      oldDetailValue,
      newDetailValue,
      features,
    } = action;

    return {
      ...state,
      filters: state.filters.map((filter) => ({
        ...filter,
        tools: updateToolStyling(
          filter.tools,
          featureInstance,
          detailId,
          oldDetailValue,
          newDetailValue,
          features,
        ),
      })),
      tools: updateToolStyling(
        state.tools,
        featureInstance,
        detailId,
        oldDetailValue,
        newDetailValue,
        features,
      ),
    };
  },
  [actionTypes.DUPLICATE_TOOL]: (state, action) => {
    const { filters, tools, duplicated, modified } = state;
    const {
      toolId: baseToolId,
      toolType: baseToolType,
      tempId,
      customData,
    } = action;

    const newId = tempId || `temporal_${Date.now()}`;
    const alreadyDuplicated = duplicated.find(
      (tool) => tool.newId === baseToolId,
    );
    const alreadyModified = modified.find((tool) => tool.newId === baseToolId);

    let initialBaseToolId = baseToolId;
    let initialBaseToolType = baseToolType;

    if (alreadyModified) {
      initialBaseToolId = alreadyModified.toolId;
      initialBaseToolType = alreadyModified.toolType;
    } else if (alreadyDuplicated) {
      initialBaseToolId = alreadyDuplicated.toolId;
      initialBaseToolType = alreadyDuplicated.toolType;
    }

    const duplicatedTool = {
      ...tools.find(
        (tool) => tool.id === baseToolId && tool.tool_type === baseToolType,
      ),
    };
    duplicatedTool.name = getDuplicatedToolName(
      tools,
      duplicated,
      baseToolId,
      baseToolType,
    );
    duplicatedTool.id = newId;
    duplicatedTool.data = {
      ...duplicatedTool.data,
      labeling: {
        enabled: false,
        byFeatureName: {
          enabled: false,
          order: 0,
        },
        style: getToolDefaultLabeling(duplicatedTool.tool_type),
      },
    };

    const duplicatedData = {
      ...duplicatedTool,
      toolId: initialBaseToolId,
      toolType: initialBaseToolType,
      newId,
    };

    if (customData?.styles) {
      duplicatedTool.style = {
        ...duplicatedTool.style,
        ...customData?.styles,
      };
    }

    if (customData?.data) {
      const {
        color: customColor,
        name: customName,
        details: customDetails,
      } = customData?.data;
      if (customColor) duplicatedTool.color = customColor;
      if (customName) duplicatedTool.name = customName;
      if (customDetails) duplicatedTool.data.details = customDetails;
    }

    const newFilters = filters.map(({ tools, ...otherProps }) => ({
      ...otherProps,
      tools: addDuplicatedTool(tools, baseToolId, baseToolType, duplicatedTool),
    }));

    return {
      ...state,
      filters: newFilters,
      duplicated: [...duplicated, duplicatedData],
      tools: addDuplicatedTool(tools, baseToolId, baseToolType, duplicatedTool),
    };
  },
  [actionTypes.REMOVE_TOOL]: (state, action) => {
    const { toolId, toolType } = action;
    const { filters, tools, modified, deleted, duplicated } = state;

    const duplicatedIndex = duplicated.findIndex(
      (tool) => tool.newId === toolId,
    );

    const deletedTool = tools.find(
      (tool) => tool.id === toolId && tool.tool_type === toolType,
    );

    const newTools = tools.filter(
      (tool) => !(tool.id === toolId && tool.tool_type === toolType),
    );

    const newModified = modified.filter(
      (tool) => !(tool.toolId === toolId && tool.toolType === toolType),
    );

    const newFilters = filters.map(({ tools, ...otherProps }) => ({
      ...otherProps,
      tools: tools.filter(
        (tool) => !(tool.id === toolId && tool.tool_type === toolType),
      ),
    }));

    return {
      ...state,
      filters: newFilters,
      tools: newTools,
      modified: newModified,
      deleted: duplicatedIndex >= 0 ? deleted : deleted.concat(deletedTool),
      duplicated:
        duplicatedIndex >= 0 ?
          duplicated
            .slice(0, duplicatedIndex)
            .concat(duplicated.slice(duplicatedIndex + 1))
        : duplicated,
    };
  },
  [mapActionTypes.SAVE_MAP_SUCCESS]: (state, action) =>
    initializeStateWithData(action.filters),
};

const initialState = {
  filters: [],
  tools: [],
  modified: [],
  deleted: [],
  duplicated: [],
  featureListFilter: {
    name: 'all',
    filter: [],
  },
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
