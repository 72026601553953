import { StyleableOnlyIconProps } from '../types';

const ArrowRight = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1303 3.46967C12.8374 3.17678 12.3626 3.17678 12.0697 3.46967C11.7768 3.76256 11.7768 4.23744 12.0697 4.53033L19.1894 11.65H3C2.58579 11.65 2.25 11.9858 2.25 12.4C2.25 12.8142 2.58579 13.15 3 13.15H19.1893L12.0697 20.2697C11.7768 20.5626 11.7768 21.0374 12.0697 21.3303C12.3626 21.6232 12.8374 21.6232 13.1303 21.3303L21.527 12.9337C21.5381 12.9227 21.5488 12.9115 21.5592 12.8999C21.673 12.7726 21.7441 12.6062 21.7496 12.4233C21.7501 12.407 21.7501 12.3907 21.7496 12.3744C21.7467 12.2882 21.7292 12.2057 21.6996 12.1292C21.6631 12.0346 21.6066 11.946 21.5303 11.8697L13.1303 3.46967Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.17226 13.1998L17.4782 13.1998C17.5205 13.2002 17.5617 13.2134 17.5964 13.2376C17.6311 13.2619 17.6576 13.296 17.6726 13.3356C17.6875 13.3752 17.6901 13.4184 17.68 13.4595C17.6699 13.5006 17.6477 13.5377 17.6162 13.566L10.395 19.9201C10.168 20.1341 10.033 20.4279 10.0183 20.7396C10.0037 21.0513 10.1106 21.3564 10.3166 21.5908C10.5226 21.8252 10.8115 21.9704 11.1224 21.996C11.4334 22.0215 11.7421 21.9253 11.9836 21.7277L21.3316 13.5016C21.5452 13.3133 21.7162 13.0817 21.8333 12.8222C21.9504 12.5628 22.011 12.2814 22.011 11.9967C22.011 11.712 21.9504 11.4306 21.8333 11.1711C21.7162 10.9116 21.5452 10.68 21.3316 10.4917L11.9852 2.26735C11.7436 2.07219 11.4359 1.97796 11.1265 2.00436C10.817 2.03075 10.5297 2.17572 10.3246 2.40898C10.1195 2.64224 10.0125 2.94573 10.0259 3.25604C10.0394 3.56634 10.1722 3.85944 10.3967 4.0741L17.6178 10.4282C17.6494 10.4564 17.6716 10.4935 17.6817 10.5346C17.6918 10.5757 17.6892 10.6189 17.6742 10.6585C17.6593 10.6981 17.6328 10.7323 17.5981 10.7566C17.5634 10.7808 17.5222 10.794 17.4799 10.7944L3.17226 10.7944C2.85856 10.8027 2.56048 10.9331 2.34153 11.1579C2.12257 11.3827 2.00004 11.6841 2.00004 11.9979C2.00004 12.3117 2.12257 12.6131 2.34153 12.8379C2.56048 13.0627 2.85856 13.1932 3.17226 13.2014L3.17226 13.1998Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default ArrowRight;
