import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const ParcelIcon = ({
  fill = colors.black,
  width = '15',
  height = '15',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M11.4206 14L13.9497 6.31814L2.04653 2.09972L3.81777 14H11.4206ZM11.5632 15.5H3.64792C3.00236 15.5 2.45411 15.027 2.35901 14.3881L0.514818 1.99769C0.370045 1.02501 1.31239 0.24813 2.23878 0.576437L14.6316 4.9684C15.2994 5.20507 15.6559 5.93226 15.4343 6.60558L12.8009 14.604C12.6249 15.1387 12.1258 15.5 11.5632 15.5Z"
      fill={fill}
    />
  </svg>
);

export default ParcelIcon;
