export default {
  SELECT_TOOL: 'SELECT_TOOL',
  DESELECT_TOOL: 'DESELECT_TOOL',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_FAILURE: 'UPLOAD_PHOTO_FAILURE',
  DELETE_PHOTO: 'DELETE_PHOTO',
  UPLOAD_VIDEO: 'UPLOAD_VIDEO',
  DELETE_VIDEO: 'DELETE_VIDEO',
};
