import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Contact = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9078 2.75326C15.5741 2.09088 14.1049 1.74745 12.6161 1.75001C10.9023 1.7478 9.21918 2.20453 7.74129 3.07278C6.26298 3.94127 5.04392 5.18984 4.21054 6.68885C3.37716 8.18785 2.9598 9.8827 3.0017 11.5975C3.03965 13.15 3.45277 14.6677 4.20264 16.0219L1.83488 20.9991C1.67573 21.3336 1.74451 21.7321 2.00657 21.994C2.26864 22.2558 2.6672 22.3242 3.0016 22.1648L7.97272 19.7947C9.13031 20.4364 10.4094 20.833 11.7305 20.9575C13.2132 21.0974 14.7083 20.8906 16.0977 20.3536C17.487 19.8166 18.7326 18.964 19.7366 17.863C20.7404 16.7621 21.4751 15.443 21.8827 14.0096C22.2903 12.5761 22.3597 11.0675 22.0856 9.60265C21.8113 8.13781 21.2009 6.75663 20.3025 5.56803C19.404 4.37942 18.2419 3.41583 16.9078 2.75326ZM12.6179 3.50001C13.836 3.49773 15.0381 3.77862 16.1294 4.32059C17.2206 4.86257 18.1714 5.65084 18.9065 6.62333C19.6416 7.5958 20.141 8.72592 20.3654 9.92463C20.5898 11.1234 20.533 12.3579 20.1995 13.5309C19.8659 14.7038 19.2648 15.7832 18.4434 16.6839C17.6221 17.5846 16.6031 18.282 15.4668 18.7213C14.3303 19.1605 13.1075 19.3296 11.8947 19.2153C10.6819 19.1009 9.51222 18.7062 8.47775 18.0622C8.22439 17.9044 7.90812 17.8867 7.63873 18.0152L4.47467 19.5237L5.98208 16.355C6.1101 16.0859 6.09236 15.7702 5.93501 15.5171C5.19423 14.3258 4.78547 12.9576 4.75118 11.5548C4.7169 10.1519 5.05835 8.76537 5.74005 7.53919C6.42175 6.31302 7.41883 5.29187 8.62774 4.58165C9.83664 3.87143 11.2137 3.49799 12.6154 3.50001L12.6179 3.50001Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6161 2.00001C14.0664 1.99745 15.4975 2.33195 16.7966 2.97716C18.096 3.62251 19.2279 4.56105 20.1031 5.71879C20.9782 6.87651 21.5727 8.22183 21.8398 9.64866C22.1069 11.0755 22.0393 12.5449 21.6423 13.9412C21.2452 15.3374 20.5296 16.6223 19.5518 17.6946C18.574 18.7669 17.3607 19.5974 16.0075 20.1204C14.6543 20.6434 13.1981 20.8448 11.7539 20.7086C10.4247 20.5833 9.13894 20.1752 7.9823 19.5131L2.894 21.9391C2.65514 22.053 2.37046 22.0041 2.18327 21.8171C1.99608 21.6301 1.94695 21.3454 2.06063 21.1065L4.48406 16.0123C3.71401 14.6654 3.28962 13.1463 3.25162 11.5914C3.21081 9.92115 3.61732 8.27035 4.42904 6.81033C5.24075 5.3503 6.42809 4.13422 7.86791 3.28833C9.30744 2.44262 10.9468 1.99779 12.6161 2.00001Z"
          fill={color}
        />
      </svg>;
};

export default Contact;
