import { NonConfigurableIconProps } from '../types';

const Panoramic = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1763 4.18086C17.468 4.13088 17.7596 4.21417 17.9846 4.4224C18.2096 4.63063 18.3346 4.93882 18.3346 5.26366V14.7339C18.3346 15.0588 18.2096 15.367 17.9846 15.5752C17.8013 15.7418 17.5763 15.8334 17.343 15.8334C17.2912 15.8334 17.246 15.8268 17.1958 15.8195C17.1894 15.8186 17.1829 15.8177 17.1763 15.8167C12.868 15.0171 7.13464 15.0171 2.8263 15.8167C2.53464 15.8667 2.24297 15.7834 2.01797 15.5752C1.79297 15.367 1.66797 15.0588 1.66797 14.7339V5.26366C1.66797 4.93882 1.79297 4.63063 2.01797 4.4224C2.24297 4.21417 2.53464 4.13088 2.8263 4.18086C7.13464 4.98046 12.8763 4.98046 17.1763 4.18086ZM14.6513 10.2029L17.1096 13.3846H17.1346V5.56351C12.8263 6.32979 7.2263 6.32979 2.91797 5.56351V13.3846L5.3763 10.2029C5.53464 9.99463 5.84297 9.9863 6.01797 10.1862L7.10964 11.4522L9.68464 8.09558C9.8513 7.87902 10.1763 7.87902 10.343 8.09558L12.918 11.4522L14.0096 10.1862C14.1763 9.9863 14.493 9.99463 14.6513 10.2029ZM6.02629 7.98766C6.02629 7.55433 6.37629 7.21267 6.80129 7.21267C7.22629 7.21267 7.57629 7.55433 7.57629 7.98766C7.57629 8.42099 7.23462 8.76267 6.80129 8.76267C6.36795 8.76267 6.02629 8.42099 6.02629 7.98766Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Panoramic;
