/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const Acres = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M15.7709 5.87499H14.7292C14.6333 5.87499 14.5556 5.95272 14.5556 6.0486V11.9514C14.5556 12.0473 14.6333 12.125 14.7292 12.125H15.7709C15.8667 12.125 15.9445 12.0473 15.9445 11.9514V6.0486C15.9445 5.95272 15.8667 5.87499 15.7709 5.87499Z"
      fill={fill}
    />
    <path
      d="M11.9514 2.05555H6.04863C5.95275 2.05555 5.87502 2.13327 5.87502 2.22916V3.27082C5.87502 3.36671 5.95275 3.44443 6.04863 3.44443H11.9514C12.0473 3.44443 12.125 3.36671 12.125 3.27082V2.22916C12.125 2.13327 12.0473 2.05555 11.9514 2.05555Z"
      fill={fill}
    />
    <path
      d="M3.27085 5.87499H2.22919C2.1333 5.87499 2.05558 5.95272 2.05558 6.0486V11.9514C2.05558 12.0473 2.1333 12.125 2.22919 12.125H3.27085C3.36674 12.125 3.44446 12.0473 3.44446 11.9514V6.0486C3.44446 5.95272 3.36674 5.87499 3.27085 5.87499Z"
      fill={fill}
    />
    <path
      d="M11.9514 14.5555H6.04863C5.95275 14.5555 5.87502 14.6333 5.87502 14.7292V15.7708C5.87502 15.8667 5.95275 15.9444 6.04863 15.9444H11.9514C12.0473 15.9444 12.125 15.8667 12.125 15.7708V14.7292C12.125 14.6333 12.0473 14.5555 11.9514 14.5555Z"
      fill={fill}
    />
    <path
      d="M15.25 4.83332C16.4006 4.83332 17.3334 3.90058 17.3334 2.74999C17.3334 1.5994 16.4006 0.666656 15.25 0.666656C14.0994 0.666656 13.1667 1.5994 13.1667 2.74999C13.1667 3.90058 14.0994 4.83332 15.25 4.83332Z"
      fill={fill}
    />
    <path
      d="M2.75002 4.83332C3.90061 4.83332 4.83335 3.90058 4.83335 2.74999C4.83335 1.5994 3.90061 0.666656 2.75002 0.666656C1.59943 0.666656 0.666687 1.5994 0.666687 2.74999C0.666687 3.90058 1.59943 4.83332 2.75002 4.83332Z"
      fill={fill}
    />
    <path
      d="M15.25 17.3333C16.4006 17.3333 17.3334 16.4006 17.3334 15.25C17.3334 14.0994 16.4006 13.1667 15.25 13.1667C14.0994 13.1667 13.1667 14.0994 13.1667 15.25C13.1667 16.4006 14.0994 17.3333 15.25 17.3333Z"
      fill={fill}
    />
    <path
      d="M2.75002 17.3333C3.90061 17.3333 4.83335 16.4006 4.83335 15.25C4.83335 14.0994 3.90061 13.1667 2.75002 13.1667C1.59943 13.1667 0.666687 14.0994 0.666687 15.25C0.666687 16.4006 1.59943 17.3333 2.75002 17.3333Z"
      fill={fill}
    />
  </svg>
);

Acres.propTypes = {
  fill: PropTypes.string,
};

Acres.defaultProps = {
  fill: colorPalette.grayMedium,
};

export default Acres;
