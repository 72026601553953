import { Reducer } from '@reduxjs/toolkit';
import contextualCardReducer from '../reducers/contextualCard';
import counties from '../reducers/counties';
import dashboardReducer from '../reducers/dashboard';
import printHistoryReducer from '../reducers/printHistory';
import drawReducer from '../reducers/draw';
import drawingTooltips from 'reducers/drawingTooltips';
import editSidebar from '../reducers/editSidebar';
import exportReducer from '../reducers/export';
import filtersReducer from '../reducers/filters';
import flashMessagesReducer from '../reducers/flashMessages';
import georeferenceReducer from '../reducers/georeference';
import helpCenterReducer from '../reducers/helpCenter';
import highlightReducer from '../reducers/highlight';
import layerPopupsReducer from '../reducers/layerPopups';
import parcelsReducer from '../reducers/parcels';
import mapReducer from '../reducers/map';
import mapToolsControlReducer from '../reducers/mapToolsControl';
import modalsReducer from './slices/modalsSlice';
import photoGalleryReducer from '../reducers/photoGallery';
import search from '../reducers/search';
import settingsReducer from '../reducers/settings';
import shapeHandlerReducer from '../reducers/shapeHandler';
import shareReducer from '../reducers/share';
import soilsReportReducer from '../reducers/soilsReport';
import subscriptionsReducer from '../reducers/subscriptions';
import transformHandlerReducer from '../reducers/transformHandler';
import userReducer from '../reducers/user';
import errorsReducer from '../reducers/errors';
import gisFileImportReducer from '../reducers/gisFileImport';
import sharedMapsReducer from '../reducers/sharedMaps';
import deedPlotterReducer from '../reducers/deedPlotter';
import toolEditReducer from '../reducers/toolEdit';
import measurementUnitReducer from '../reducers/measurementUnit';
import cutTool from 'reducers/cutTool';
import parcelReportsReducer from 'reducers/parcelReports';
import exportParcelCsv from 'reducers/exportParcelCsv';
import shareMapMobileMapInfo from 'reducers/shareMapMobileMapInfo';
import redirect from 'reducers/redirect';
import features from 'reducers/features';
import api from 'api/endpoints';
import eventLoggerReducer from './slices/eventLoggerSlice';

import {
  BufferToolState,
  MapState,
  MapToolsControlState,
  MeasurementUnitState,
  DrawingTooltipsState,
  EditSidebarState,
  ShareState,
  SoilsReportState,
  SubscriptionsState,
  DrawState,
  GeoreferenceState,
  SearchState,
  ParcelsState,
  LayerPopupsState,
  FiltersState,
  TransformHandlerState,
  DashboardState,
  UserState,
  ShareMapMobileMapInfoState,
} from 'reducers/types';

// we need to disable this rule for now because we don't want to import from
// the whole `package/map-kit` module to avoid import cycles
// we will think of a better way to handle this in the future
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import bufferToolSlice from 'packages/map-kit/store/bufferToolSlice';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import popupsSlice from 'packages/map-kit/store/popupSlice';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import parcelCardReducer from 'packages/map-kit/store/parcelCardSlice';

export const reducers = {
  bufferTool: bufferToolSlice as Reducer<BufferToolState>,
  popup: popupsSlice,
  contextualCard: contextualCardReducer,
  counties,
  cutTool,
  dashboard: dashboardReducer as Reducer<DashboardState>,
  deedPlotter: deedPlotterReducer,
  draw: drawReducer as Reducer<DrawState>,
  drawingTooltips: drawingTooltips as Reducer<DrawingTooltipsState>,
  editSidebar: editSidebar as Reducer<EditSidebarState>,
  errors: errorsReducer,
  export: exportReducer,
  exportParcelCsv,
  features,
  filters: filtersReducer as Reducer<FiltersState>,
  flashMessages: flashMessagesReducer,
  georeference: georeferenceReducer as Reducer<GeoreferenceState>,
  gisFileImport: gisFileImportReducer,
  helpCenter: helpCenterReducer,
  highlight: highlightReducer,
  layerPopups: layerPopupsReducer as Reducer<LayerPopupsState>,
  map: mapReducer as Reducer<MapState>,
  mapToolsControl: mapToolsControlReducer as Reducer<MapToolsControlState>,
  measurementUnit: measurementUnitReducer as Reducer<MeasurementUnitState>,
  modals: modalsReducer,
  parcelCard: parcelCardReducer,
  parcels: parcelsReducer as Reducer<ParcelsState>,
  parcelReports: parcelReportsReducer,
  photoGallery: photoGalleryReducer,
  printHistory: printHistoryReducer,
  redirect,
  search: search as Reducer<SearchState>,
  settings: settingsReducer,
  sharedMaps: sharedMapsReducer,
  shapeHandler: shapeHandlerReducer,
  share: shareReducer as Reducer<ShareState>,
  soilsReport: soilsReportReducer as Reducer<SoilsReportState>,
  subscriptions: subscriptionsReducer as Reducer<SubscriptionsState>,
  toolEdit: toolEditReducer,
  transformHandler: transformHandlerReducer as Reducer<TransformHandlerState>,
  user: userReducer as Reducer<UserState>,
  shareMapMobileMapInfo:
    shareMapMobileMapInfo as Reducer<ShareMapMobileMapInfoState>,
  eventLogger: eventLoggerReducer,
  [api.reducerPath]: api.reducer,
};

export default reducers;
