import { api } from 'api/endpoints';

interface Team {
  team_id: number | null;
  role: string;
  team_name: string;
  organization_id?: number;
  organization_name?: string;
}

interface Teams {
  teams: Team[];
}

export const teamsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserTeams: builder.query<Teams, void>({
      query: () => ({
        url: '/teams.json',
        withAuth: true,
      }),
      serializeQueryArgs: () => 'fetchUserTeams',
    }),
  }),
  overrideExisting: false,
});

export const { useFetchUserTeamsQuery } = teamsEndpoints;
