import { gql } from 'graphql-client-generated';

export const PLAN_ENTITLEMENTS_QUERY = gql(`
  query AuthzPlanEntitlements {
    planEntitlements {
      current {
        code
        quota
      }
    }
  }
`);
