import actionTypes from '../actions/photoGallery/actions';
import settingsActionTypes from '../actions/settings/actions';
import update from 'immutability-helper';

const ACTION_HANDLERS = {
  [actionTypes.SELECT_TOOL]: (state, action) => ({
    tool: { ...action.toolGeoJSON },
    media: action.media,
  }),
  [actionTypes.DESELECT_TOOL]: (state, action) => initialState,
  [actionTypes.UPLOAD_PHOTO_SUCCESS]: (state, action) => {
    const { photo } = action;
    return {
      tool: update(state.tool, {
        $apply: (tool) => {
          tool.photos = tool.photos ? tool.photos.concat(photo.id) : [photo.id];
          return tool;
        },
      }),
      media: state.media.concat({
        type: 'photo',
        element: photo,
        id: photo.id,
      }),
      uploadingPhoto: false,
    };
  },
  [actionTypes.UPLOAD_PHOTO]: (state, action) => ({
    ...state,
    uploadingPhoto: true,
  }),
  [actionTypes.DELETE_PHOTO]: (state, action) => {
    const { photoId } = action;
    const index = state.media.map((media) => media.id).indexOf(photoId);

    return {
      tool: update(state.tool, {
        $apply: (tool) => {
          const index = tool.photos.indexOf(photoId);
          tool.photos = tool.photos
            .slice(0, index)
            .concat(tool.photos.slice(index + 1));
          return tool;
        },
      }),
      media: state.media.slice(0, index).concat(state.media.slice(index + 1)),
    };
  },
  [actionTypes.UPLOAD_VIDEO]: (state, action) => {
    const { videoLink } = action;
    return {
      tool: update(state.tool, {
        $apply: (tool) => {
          tool.videos =
            tool.videos ? tool.videos.concat(videoLink) : [videoLink];
          return tool;
        },
      }),
      media: state.media.concat({
        type: 'video',
        element: videoLink,
        id: videoLink,
      }),
    };
  },
  [actionTypes.DELETE_VIDEO]: (state, action) => {
    const { videoId } = action;
    const index = state.media.map((media) => media.id).indexOf(videoId);

    return {
      tool: update(state.tool, {
        $apply: (tool) => {
          const index = tool.videos.indexOf(videoId);
          tool.videos = tool.videos
            .slice(0, index)
            .concat(tool.videos.slice(index + 1));
          return tool;
        },
      }),
      media: state.media.slice(0, index).concat(state.media.slice(index + 1)),
    };
  },
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

// Reducer
const initialState = {
  tool: null,
  media: [],
  uploadingPhoto: false,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
