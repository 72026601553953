import 'utils/polyfills/find';
import 'utils/polyfills/includes';
import 'utils/polyfills/flat';

import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
// eslint-disable-next-line restricted-imports/react-modal -- we need to use react-modal directly here since this is the root Modal.setAppElement setup
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RootModal from 'components/RootModal';
import { Redirector } from './components/Redirector';
import ErrorScreen from './components/ErrorScreen';
import UserContext from './UserContext';
import ErrorBoundary from './components/ErrorBoundary';
import { FeatureFlagsProvider } from 'services/FeatureFlags';
import apolloClient from 'services/apolloClient';
import EventButton from 'components/EventLogger';

import Routes from './routes';
import store from './store';
import loadColors from './colors';

import './static/images/favicon.ico';
import './static/images/app-logo-dark.png';
import './static/images/embed-placeholder.jpg';
import './static/images/LandId.svg';

const MOUNT_NODE = document.getElementById('app') as HTMLElement;

toast.configure();

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary fallback={() => <ErrorScreen />}>
        <FeatureFlagsProvider>
          <Router>
            <DndProvider backend={HTML5Backend}>
              <Redirector />
              <UserContext />

              <Routes />

              <RootModal />
              <EventButton />
            </DndProvider>
          </Router>
        </FeatureFlagsProvider>
      </ErrorBoundary>
    </ApolloProvider>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object,
};

(async () => {
  await loadColors();
  ReactDOM.render(<App />, MOUNT_NODE);
  Modal.setAppElement(MOUNT_NODE);
})();
