import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Remove = ({ fill = colors.fail500 }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M4.42445 11.5738C4.19032 11.3397 4.19491 10.9403 4.41985 10.7154L7.13294 8.0023L4.41985 5.28462C4.19491 5.06427 4.19032 4.66029 4.42445 4.42617C4.65857 4.18746 5.06255 4.19664 5.2829 4.42158L8.00057 7.13466L10.7137 4.42158C10.9432 4.19205 11.338 4.19205 11.5721 4.42617C11.8108 4.66029 11.8062 5.05509 11.5767 5.28462L8.86362 8.0023L11.5767 10.7154C11.8062 10.9449 11.8062 11.3351 11.5721 11.5738C11.338 11.808 10.9432 11.808 10.7137 11.5784L8.00057 8.86534L5.2829 11.583C5.06255 11.8034 4.66316 11.808 4.42445 11.5738Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8ZM14.375 8C14.375 11.5208 11.5208 14.375 8 14.375C4.47918 14.375 1.625 11.5208 1.625 8C1.625 4.47918 4.47918 1.625 8 1.625C11.5208 1.625 14.375 4.47918 14.375 8Z"
    />
  </svg>
);

export default Remove;
