export default {
  FETCH_USER_SUBSCRIPTIONS_SUCCESS: 'FETCH_USER_SUBSCRIPTIONS_SUCCESS',
  FETCH_USER_SUBSCRIPTIONS_FAILURE: 'FETCH_USER_SUBSCRIPTIONS_FAILURE',
  FETCH_ALL_USER_SUBSCRIPTIONS_SUCCESS: 'FETCH_ALL_USER_SUBSCRIPTIONS_SUCCESS',
  FETCH_ALL_USER_SUBSCRIPTIONS_FAILURE: 'FETCH_ALL_USER_SUBSCRIPTIONS_FAILURE',
  FETCH_SUBSCRIPTIONS_PLANS_SUCCESS: 'FETCH_SUBSCRIPTIONS_PLANS_SUCCESS',
  FETCH_SUBSCRIPTIONS_PLANS_FAILURE: 'FETCH_SUBSCRIPTIONS_PLANS_FAILURE',
  POST_SUBSCRIPTION: 'POST_SUBSCRIPTION',
  POST_SUBSCRIPTION_SUCCESS: 'POST_SUBSCRIPTION_SUCCESS',
  POST_SUBSCRIPTION_FAILURE: 'POST_SUBSCRIPTION_FAILURE',
  PATCH_SUBSCRIPTION: 'PATCH_SUBSCRIPTION',
  PATCH_SUBSCRIPTION_SUCCESS: 'PATCH_SUBSCRIPTION_SUCCESS',
  PATCH_SUBSCRIPTION_FAILURE: 'PATCH_SUBSCRIPTION_FAILURE',
};
