/* eslint-disable */

if (!Array.prototype.flat) {
  Array.prototype.flat = function (nesting_level = 1) {
    let arr = this;

    for (let i = 0; i < nesting_level; i++) {
      arr = arr.reduce((acc, val) => acc.concat(val), []);
    }

    return arr.filter((el) => el != null);
  };
}
