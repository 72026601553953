import { NonConfigurableIconProps } from '../types';

const Spring = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8123 9.93112C13.7728 9.5479 13.6033 9.2502 13.3037 9.038C13.0041 8.82581 12.5975 8.71971 12.084 8.71971C11.735 8.71971 11.4403 8.76722 11.2 8.86223C10.9597 8.95408 10.7753 9.08234 10.6469 9.24703C10.5218 9.41172 10.4593 9.59858 10.4593 9.8076C10.4527 9.98179 10.4905 10.1338 10.5728 10.2637C10.6584 10.3935 10.7753 10.5059 10.9235 10.601C11.0716 10.6928 11.2428 10.7736 11.437 10.8432C11.6313 10.9097 11.8387 10.9667 12.0593 11.0143L12.9679 11.2233C13.4091 11.3183 13.814 11.445 14.1827 11.6033C14.5514 11.7617 14.8708 11.9565 15.1407 12.1876C15.4107 12.4188 15.6198 12.6912 15.7679 13.0048C15.9193 13.3183 15.9967 13.6778 16 14.0831C15.9967 14.6785 15.8387 15.1948 15.5259 15.6318C15.2165 16.0657 14.7687 16.403 14.1827 16.6437C13.6 16.8812 12.8971 17 12.0741 17C11.2576 17 10.5465 16.8797 9.94074 16.639C9.33827 16.3983 8.86749 16.042 8.5284 15.5701C8.19259 15.095 8.01646 14.5075 8 13.8076H10.0691C10.0922 14.1338 10.1893 14.4062 10.3605 14.6247C10.535 14.8401 10.7671 15.0032 11.0568 15.114C11.3498 15.2217 11.6807 15.2755 12.0494 15.2755C12.4115 15.2755 12.7259 15.2249 12.9926 15.1235C13.2626 15.0222 13.4716 14.8812 13.6198 14.7007C13.7679 14.5202 13.842 14.3127 13.842 14.0784C13.842 13.8599 13.7745 13.6762 13.6395 13.5273C13.5078 13.3785 13.3136 13.2518 13.0568 13.1473C12.8033 13.0428 12.4922 12.9477 12.1235 12.8622L11.0222 12.5962C10.1695 12.3967 9.4963 12.0847 9.00247 11.6603C8.50864 11.2359 8.26337 10.6643 8.26667 9.94537C8.26337 9.35629 8.42634 8.84165 8.75556 8.40143C9.08807 7.9612 9.54403 7.61758 10.1235 7.37055C10.7029 7.12352 11.3613 7 12.0988 7C12.8494 7 13.5045 7.12352 14.0642 7.37055C14.6272 7.61758 15.065 7.9612 15.3778 8.40143C15.6905 8.84165 15.8519 9.35154 15.8617 9.93112H13.8123Z"
        fill="#1D1D1D"
      />
      <circle cx="12" cy="12" r="9.5" stroke="#1D1D1D" />
    </svg>
  );
};

export default Spring;
