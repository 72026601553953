import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import EllipseSpinner from 'components/EllipseSpinner';
import Button from 'components/ui/Button';
import Modal from 'components/Modal';

import './styles.scss';

class SaveOutdatedMap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      loading: false,
      messageType: 'success',
    };
  }

  onSave = () => {
    const { onClose, saveRequest } = this.props;

    this.setState({ loading: true }, () => {
      saveRequest().then(() => {
        this.setState({ loading: false }, () => {
          onClose();
        });
      });
    });
  };

  showMessage = (type, message) => {
    this.setState({ messageType: type, message });
    setTimeout(() => this.setState({ message: '', messageType: '' }), 2000);
  };

  render() {
    const { onClose: closeModal, onCancel, lastModified } = this.props;
    const { loading } = this.state;

    const onClose = () => {
      closeModal();
      onCancel();
    };

    const date = new Date(lastModified).toLocaleDateString('en-US');
    // eslint-disable-next-line max-len
    const deleteMessage = `Saving these changes will override the changes made on ${date} from another device. Do you want to proceed?`;

    return (
      <>
        {loading && <EllipseSpinner fullScreen zIndex="3600" />}
        <Modal
          className="save-outdated-map"
          overlayClassName="custom-modal-overlay--save-as"
          onClose={onClose}
          positionModifier="center-full"
          header={{
            title: 'Outdated Map',
            icon: 'save',
          }}
        >
          <main>
            <p className="save-outdated-map__notice">
              This map was saved on {date} from another device
            </p>
            <Scrollbars autoHeight autoHeightMax="70vh">
              <p className="save-outdated-map__message">{deleteMessage}</p>
            </Scrollbars>
          </main>
          <Modal.Footer className="print-modal__actions">
            <Button color="blue" size="small" onClick={this.onSave}>
              Save
            </Button>
            <Button
              color="grayLightest"
              fontColor="fBlack"
              size="small"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const ModalActions = ({ saveDisabled, onSave, onCancel }) => (
  <div className="save-outdated-map__actions">
    <Button secondary onClick={onCancel}>
      Cancel
    </Button>
    <Button onClick={onSave} disabled={saveDisabled}>
      Save
    </Button>
  </div>
);

SaveOutdatedMap.propTypes = {
  lastModified: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  saveRequest: PropTypes.func,
};

ModalActions.propTypes = {
  saveDisabled: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SaveOutdatedMap;
