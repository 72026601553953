/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const TextLabel = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.384 0H1.6933C1.24421 0 0.813511 0.178401 0.495956 0.495956C0.178401 0.813511 0 1.24421 0 1.6933V3.15464C0 3.41097 0.101827 3.6568 0.28308 3.83805C0.464333 4.01931 0.710164 4.12113 0.966495 4.12113C1.22283 4.12113 1.46866 4.01931 1.64991 3.83805C1.83116 3.6568 1.93299 3.41097 1.93299 3.15464V2.12629C1.93299 2.07502 1.95336 2.02586 1.98961 1.98961C2.02586 1.95336 2.07502 1.93299 2.12629 1.93299H6.37887C6.43013 1.93299 6.4793 1.95336 6.51555 1.98961C6.5518 2.02586 6.57217 2.07502 6.57217 2.12629V15.8737C6.57217 15.925 6.5518 15.9741 6.51555 16.0104C6.4793 16.0466 6.43013 16.067 6.37887 16.067H4.97938C4.72305 16.067 4.47722 16.1688 4.29597 16.3501C4.11471 16.5313 4.01289 16.7772 4.01289 17.0335C4.01289 17.2898 4.11471 17.5357 4.29597 17.7169C4.47722 17.8982 4.72305 18 4.97938 18H10.0979C10.3543 18 10.6001 17.8982 10.7814 17.7169C10.9626 17.5357 11.0644 17.2898 11.0644 17.0335C11.0644 16.7772 10.9626 16.5313 10.7814 16.3501C10.6001 16.1688 10.3543 16.067 10.0979 16.067H8.69845C8.64719 16.067 8.59802 16.0466 8.56177 16.0104C8.52552 15.9741 8.50516 15.925 8.50516 15.8737V2.12629C8.50516 2.07502 8.52552 2.02586 8.56177 1.98961C8.59802 1.95336 8.64719 1.93299 8.69845 1.93299H12.951C13.0023 1.93299 13.0515 1.95336 13.0877 1.98961C13.124 2.02586 13.1443 2.07502 13.1443 2.12629V3.15464C13.1443 3.41097 13.2462 3.6568 13.4274 3.83805C13.6087 4.01931 13.8545 4.12113 14.1108 4.12113C14.3672 4.12113 14.613 4.01931 14.7942 3.83805C14.9755 3.6568 15.0773 3.41097 15.0773 3.15464V1.6933C15.0773 1.24421 14.8989 0.813511 14.5814 0.495956C14.2638 0.178401 13.8331 0 13.384 0Z"
      fill={fill}
    />
  </svg>
);

TextLabel.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextLabel.defaultProps = {
  fill: '#1D1D1D',
  width: '16',
  height: '18',
};

export default TextLabel;
