/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const ItalicStyle = ({ fill }) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6523 0H1.34769C0.990262 0 0.647471 0.138756 0.39473 0.385743C0.141989 0.632731 0 0.967717 0 1.31701V2.45361C0 2.65298 0.0810438 2.84418 0.225303 2.98515C0.369561 3.12613 0.565218 3.20533 0.769231 3.20533C0.973244 3.20533 1.1689 3.12613 1.31316 2.98515C1.45742 2.84418 1.53846 2.65298 1.53846 2.45361V1.65378C1.53846 1.61391 1.55467 1.57567 1.58352 1.54747C1.61237 1.51928 1.65151 1.50344 1.69231 1.50344H5.07692C5.11773 1.50344 5.15686 1.51928 5.18571 1.54747C5.21456 1.57567 5.23077 1.61391 5.23077 1.65378L2.84308 12.3462C2.84308 12.3861 2.82687 12.4243 2.79802 12.4525C2.76917 12.4807 2.73003 12.4966 2.68923 12.4966H1.57538C1.37137 12.4966 1.17572 12.5758 1.03146 12.7167C0.887198 12.8577 0.806154 13.0489 0.806154 13.2483C0.806154 13.4476 0.887198 13.6389 1.03146 13.7798C1.17572 13.9208 1.37137 14 1.57538 14H5.64923C5.85324 14 6.0489 13.9208 6.19316 13.7798C6.33742 13.6389 6.41846 13.4476 6.41846 13.2483C6.41846 13.0489 6.33742 12.8577 6.19316 12.7167C6.0489 12.5758 5.85324 12.4966 5.64923 12.4966H4.53538C4.49458 12.4966 4.45545 12.4807 4.4266 12.4525C4.39775 12.4243 4.38154 12.3861 4.38154 12.3462L6.76923 1.65378C6.76923 1.61391 6.78544 1.57567 6.81429 1.54747C6.84314 1.51928 6.88227 1.50344 6.92308 1.50344H10.3077C10.3485 1.50344 10.3876 1.51928 10.4165 1.54747C10.4453 1.57567 10.4615 1.61391 10.4615 1.65378V2.45361C10.4615 2.65298 10.5426 2.84418 10.6868 2.98515C10.8311 3.12613 11.0268 3.20533 11.2308 3.20533C11.4348 3.20533 11.6304 3.12613 11.7747 2.98515C11.919 2.84418 12 2.65298 12 2.45361V1.31701C12 0.967717 11.858 0.632731 11.6053 0.385743C11.3525 0.138756 11.0097 0 10.6523 0Z"
      fill={fill}
    />
  </svg>
);

ItalicStyle.propTypes = {
  fill: PropTypes.string,
};

ItalicStyle.defaultProps = {
  fill: '#FFFFFF',
};

export default ItalicStyle;
