export const actionTypes = {
  OPEN_HELP_CENTER: 'OPEN_HELP_CENTER',
  CLOSE_HELP_CENTER: 'CLOSE_HELP_CENTER',
};

export const openHelpCenter = (topic = 'home') => ({
  type: actionTypes.OPEN_HELP_CENTER,
  topic,
});

export const closeHelpCenter = () => ({ type: actionTypes.CLOSE_HELP_CENTER });

export const actions = {
  openHelpCenter,
  closeHelpCenter,
};
