/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Icon360 = ({ fill, variant, width, height }) => {
  const filled = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5763 8.66792C16.612 8.65043 16.6419 8.62309 16.6626 8.58913C16.6833 8.55517 16.6938 8.516 16.6929 8.47625C16.6932 8.4377 16.6824 8.39988 16.6617 8.36736C16.641 8.33483 16.6113 8.30898 16.5763 8.29292L12.2429 6.16792C12.1689 6.12706 12.0858 6.10564 12.0013 6.10564C11.9167 6.10564 11.8336 6.12706 11.7596 6.16792L7.42627 8.33458C7.39122 8.35065 7.36156 8.3765 7.34086 8.40902C7.32016 8.44155 7.30931 8.47936 7.3096 8.51792C7.31576 8.55038 7.32955 8.58091 7.34983 8.60699C7.37012 8.63308 7.39632 8.65396 7.42627 8.66792L11.9096 10.9096C11.9386 10.922 11.9698 10.9283 12.0013 10.9283C12.0328 10.9283 12.064 10.922 12.0929 10.9096L16.5763 8.66792Z"
        fill={fill}
      />
      <path
        d="M12.4179 17.4096C12.4184 17.4448 12.4279 17.4794 12.4454 17.51C12.4628 17.5406 12.4878 17.5663 12.5179 17.5846C12.5486 17.6014 12.583 17.6102 12.6179 17.6102C12.6529 17.6102 12.6873 17.6014 12.7179 17.5846L17.1179 15.3846C17.2085 15.3405 17.2847 15.2718 17.3378 15.1863C17.3908 15.1007 17.4186 15.0019 17.4179 14.9012V9.54292C17.4174 9.50767 17.408 9.47313 17.3905 9.44252C17.373 9.41191 17.348 9.38624 17.3179 9.36792C17.2873 9.35114 17.2529 9.34235 17.2179 9.34235C17.183 9.34235 17.1486 9.35114 17.1179 9.36792L12.5346 11.6596C12.4996 11.6756 12.4699 11.7015 12.4492 11.734C12.4285 11.7665 12.4176 11.8044 12.4179 11.8429V17.4096Z"
        fill={fill}
      />
      <path
        d="M6.8846 9.35958C6.85394 9.34281 6.81955 9.33401 6.7846 9.33401C6.74965 9.33401 6.71526 9.34281 6.6846 9.35958C6.65449 9.37791 6.62952 9.40358 6.61203 9.43419C6.59454 9.46479 6.5851 9.49934 6.5846 9.53458V14.9012C6.58389 15.0019 6.61169 15.1007 6.66478 15.1863C6.71787 15.2718 6.79408 15.3405 6.8846 15.3846L11.2846 17.5846C11.3153 17.6014 11.3496 17.6102 11.3846 17.6102C11.4195 17.6102 11.4539 17.6014 11.4846 17.5846C11.5147 17.5663 11.5397 17.5406 11.5572 17.51C11.5747 17.4794 11.5841 17.4448 11.5846 17.4096V11.8346C11.5849 11.796 11.574 11.7582 11.5533 11.7257C11.5326 11.6932 11.503 11.6673 11.4679 11.6512L6.8846 9.35958Z"
        fill={fill}
      />
      <path
        d="M14.5013 18.6679H9.50127C9.419 18.6683 9.33871 18.6931 9.27049 18.7391C9.20227 18.785 9.14919 18.8502 9.11793 18.9263C9.0839 19.0014 9.07443 19.0853 9.09089 19.1661C9.10735 19.2469 9.14889 19.3204 9.2096 19.3763L11.7096 21.8763C11.747 21.9161 11.7922 21.9479 11.8424 21.9696C11.8925 21.9913 11.9466 22.0025 12.0013 22.0025C12.0559 22.0025 12.11 21.9913 12.1602 21.9696C12.2103 21.9479 12.2555 21.9161 12.2929 21.8763L14.7929 19.3763C14.8536 19.3204 14.8952 19.2469 14.9116 19.1661C14.9281 19.0853 14.9186 19.0014 14.8846 18.9263C14.8533 18.8502 14.8003 18.785 14.732 18.7391C14.6638 18.6931 14.5835 18.6683 14.5013 18.6679Z"
        fill={fill}
      />
      <path
        d="M9.50127 5.33458H14.5013C14.5835 5.33417 14.6638 5.30942 14.732 5.26345C14.8003 5.21748 14.8533 5.15234 14.8846 5.07625C14.9186 5.00114 14.9281 4.91721 14.9116 4.83641C14.8952 4.7556 14.8536 4.68206 14.7929 4.62625L12.2929 2.12625C12.2555 2.08639 12.2103 2.05462 12.1602 2.03291C12.11 2.01119 12.0559 1.99998 12.0013 1.99998C11.9466 1.99998 11.8925 2.01119 11.8424 2.03291C11.7922 2.05462 11.747 2.08639 11.7096 2.12625L9.2096 4.62625C9.14889 4.68206 9.10735 4.7556 9.09089 4.83641C9.07443 4.91721 9.0839 5.00114 9.11793 5.07625C9.14919 5.15234 9.20227 5.21748 9.27049 5.26345C9.33871 5.30942 9.419 5.33417 9.50127 5.33458Z"
        fill={fill}
      />
      <path
        d="M21.8763 11.7346L19.3763 9.23458C19.3205 9.17388 19.2469 9.13234 19.1661 9.11588C19.0853 9.09942 19.0014 9.10888 18.9263 9.14292C18.8534 9.17158 18.7903 9.2206 18.7445 9.28413C18.6987 9.34767 18.6721 9.42304 18.6679 9.50125V14.5012C18.6683 14.5835 18.6931 14.6638 18.7391 14.732C18.785 14.8002 18.8502 14.8533 18.9263 14.8846C18.9784 14.8964 19.0325 14.8964 19.0846 14.8846C19.1392 14.8852 19.1934 14.8745 19.2437 14.8529C19.2939 14.8314 19.3391 14.7996 19.3763 14.7596L21.8763 12.2596C21.9396 12.1868 21.9744 12.0936 21.9744 11.9971C21.9744 11.9006 21.9396 11.8074 21.8763 11.7346Z"
        fill={fill}
      />
      <path
        d="M5.07627 9.14292C5.00092 9.11013 4.91731 9.10134 4.8368 9.11774C4.75629 9.13414 4.68278 9.17493 4.62627 9.23458L2.12627 11.7346C2.08641 11.772 2.05464 11.8172 2.03292 11.8673C2.0112 11.9175 2 11.9716 2 12.0262C2 12.0809 2.0112 12.135 2.03292 12.1852C2.05464 12.2353 2.08641 12.2805 2.12627 12.3179L4.62627 14.8179C4.66347 14.8579 4.70864 14.8897 4.75887 14.9113C4.8091 14.9328 4.86329 14.9436 4.91793 14.9429C4.97005 14.9547 5.02415 14.9547 5.07627 14.9429C5.16231 14.9085 5.23443 14.8464 5.28121 14.7664C5.328 14.6864 5.34678 14.5931 5.3346 14.5013V9.50125C5.33044 9.42304 5.30388 9.34767 5.25807 9.28413C5.21227 9.2206 5.14916 9.17158 5.07627 9.14292Z"
        fill={fill}
      />
    </svg>
  );

  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75532 5.05404C10.028 5.28408 10.4096 5.24566 10.6403 4.99173L12.0074 3.48956L13.3681 4.98457C13.6052 5.27692 13.9927 5.23499 14.2238 5.06784C14.5211 4.85289 14.5397 4.44589 14.339 4.18784L14.3312 4.17772L12.5394 2.24067C12.4083 2.08694 12.2168 2 12.0074 2C11.8221 2 11.6208 2.08189 11.4922 2.20592L11.4855 2.2124L9.81325 4.03867L9.81064 4.03803L9.6905 4.17042C9.44322 4.44291 9.48869 4.82908 9.75532 5.05404Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2429 6.1679C12.1689 6.12705 12.0858 6.10562 12.0012 6.10562C11.9167 6.10562 11.8336 6.12705 11.7596 6.1679L7.42624 8.33457C7.41105 8.34153 7.39688 8.35033 7.384 8.36071C7.36717 8.37429 7.35257 8.39058 7.34084 8.40901C7.32168 8.43912 7.31095 8.47377 7.3097 8.50933L7.30958 8.5179C7.31231 8.53231 7.31655 8.54634 7.32219 8.55977C7.32926 8.57659 7.33853 8.59248 7.34981 8.60698C7.3701 8.63306 7.3963 8.65394 7.42624 8.6679L11.9096 10.9096C11.9386 10.9219 11.9697 10.9283 12.0012 10.9283C12.0328 10.9283 12.0639 10.9219 12.0929 10.9096L16.5762 8.6679C16.612 8.65042 16.6419 8.62308 16.6626 8.58912C16.668 8.58024 16.6727 8.57101 16.6767 8.56151C16.6832 8.54595 16.6878 8.52966 16.6905 8.513C16.6923 8.50089 16.6932 8.48859 16.6929 8.47624C16.6932 8.43768 16.6823 8.39987 16.6616 8.36734C16.642 8.33652 16.6144 8.3117 16.5817 8.29551L16.5762 8.2929L12.2429 6.1679ZM12.0012 9.27835L13.5747 8.49163L12.0054 7.72206L10.447 8.50124L12.0012 9.27835Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7179 17.5846L17.1179 15.3846C17.2084 15.3405 17.2846 15.2718 17.3377 15.1862C17.3908 15.1007 17.4186 15.0019 17.4179 14.9012V9.5429C17.4174 9.50766 17.408 9.47311 17.3905 9.4425C17.373 9.4119 17.348 9.38623 17.3179 9.3679C17.2872 9.35113 17.2529 9.34233 17.2179 9.34233C17.183 9.34233 17.1486 9.35113 17.1179 9.3679L12.5346 11.6596C12.5162 11.668 12.4993 11.6791 12.4844 11.6925C12.4709 11.7046 12.459 11.7186 12.4492 11.734L12.4468 11.7379C12.4291 11.7671 12.4192 11.8004 12.418 11.8346L12.4179 11.8409L12.4179 11.8429V17.4096L12.4181 17.4155L12.4184 17.4214C12.4206 17.4525 12.4298 17.4828 12.4453 17.51C12.4628 17.5406 12.4878 17.5662 12.5179 17.5846C12.5486 17.6013 12.583 17.6101 12.6179 17.6101C12.6529 17.6101 12.6872 17.6013 12.7179 17.5846ZM13.9179 15.3075L15.9179 14.3075V11.645L13.9179 12.645V15.3075Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5846 11.8346C11.5848 11.7989 11.5756 11.7639 11.5578 11.733L11.5533 11.7257C11.5442 11.7113 11.5333 11.6983 11.521 11.6868C11.5054 11.6722 11.4875 11.6602 11.4679 11.6512L6.88458 9.35957C6.85392 9.34279 6.81953 9.334 6.78458 9.334C6.74963 9.334 6.71524 9.34279 6.68458 9.35957C6.65446 9.3779 6.62949 9.40356 6.61201 9.43417C6.59452 9.46478 6.58508 9.49932 6.58458 9.53457V14.9012C6.58387 15.0019 6.61167 15.1007 6.66476 15.1862C6.71784 15.2718 6.79405 15.3405 6.88458 15.3846L11.2846 17.5846C11.3152 17.6013 11.3496 17.6101 11.3846 17.6101C11.4195 17.6101 11.4539 17.6013 11.4846 17.5846C11.5147 17.5662 11.5397 17.5406 11.5571 17.51C11.5727 17.4828 11.5819 17.4525 11.5841 17.4214C11.5844 17.4175 11.5845 17.4135 11.5846 17.4096V11.8346ZM8.08458 11.6366V14.3075L10.0846 15.3075V12.6366L8.08458 11.6366Z"
        fill={fill}
      />
      <path
        d="M13.3597 19.0083C13.5904 18.7543 13.972 18.7159 14.2447 18.946C14.5113 19.1709 14.5568 19.5571 14.3095 19.8296L14.1894 19.962L14.1867 19.9613L12.5145 21.7876L12.5078 21.7941C12.3792 21.9181 12.1779 22 11.9926 22C11.7832 22 11.5917 21.9131 11.4606 21.7593L9.66885 19.8223L9.66098 19.8122C9.46025 19.5541 9.47892 19.1471 9.77616 18.9322C10.0073 18.765 10.3948 18.7231 10.6319 19.0154L11.9926 20.5104L13.3597 19.0083Z"
        fill={fill}
      />
      <path
        d="M18.946 9.75532C18.7159 10.028 18.7543 10.4096 19.0083 10.6403L20.5104 12.0074L19.0154 13.3681C18.7231 13.6052 18.765 13.9927 18.9322 14.2238C19.1471 14.5211 19.5541 14.5397 19.8122 14.339L19.8223 14.3312L21.7593 12.5394C21.9131 12.4083 22 12.2168 22 12.0074C22 11.8221 21.9181 11.6208 21.7941 11.4922L21.7876 11.4855L19.9613 9.81325L19.962 9.81064L19.8296 9.6905C19.5571 9.44322 19.1709 9.48869 18.946 9.75532Z"
        fill={fill}
      />
      <path
        d="M4.99173 13.3597C5.24566 13.5904 5.28408 13.972 5.05404 14.2447C4.82908 14.5113 4.44291 14.5568 4.17042 14.3095L4.03803 14.1894L4.03867 14.1867L2.2124 12.5145L2.20592 12.5078C2.08189 12.3792 2 12.1779 2 11.9926C2 11.7832 2.08694 11.5917 2.24067 11.4606L4.17772 9.66885L4.18784 9.66098C4.44589 9.46025 4.85289 9.47892 5.06784 9.77616C5.235 10.0073 5.27692 10.3948 4.98457 10.6319L3.48956 11.9926L4.99173 13.3597Z"
        fill={fill}
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

Icon360.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['outlined', 'filled']),
};

Icon360.defaultProps = {
  fill: '#1D1D1D',
  variant: 'filled',
  width: '24',
  height: '24',
};

export default Icon360;
