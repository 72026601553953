import { StyleableOnlyIconProps } from '../types';

const Undo = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.53 6.91001H12.9129L12.9029 6.92001H14.11C18.1797 6.92001 21.49 10.1919 21.49 14.21C21.49 18.2285 18.1893 21.5 14.11 21.5H10.12C9.56953 21.5 9.13 21.0573 9.13 20.53C9.13 20.0027 9.56953 19.56 10.12 19.56H14.11C17.0901 19.56 19.52 17.1721 19.52 14.21C19.52 11.2472 17.0795 8.86001 14.11 8.86001H8.53V12.73C8.53 12.8356 8.49707 12.943 8.44152 13.0217C8.38017 13.1086 8.28762 13.1822 8.17597 13.2292C8.08175 13.2689 7.97227 13.2815 7.85356 13.2588C7.74084 13.2374 7.64177 13.1847 7.56355 13.1065L7.56137 13.1043L2.66144 8.26422L2.65437 8.25751C2.56145 8.16923 2.5 8.02396 2.5 7.88001C2.5 7.74407 2.55623 7.60146 2.66248 7.49463L7.56101 2.66611L7.56355 2.66356C7.64177 2.58534 7.74085 2.53267 7.85356 2.5112L7.85806 2.5103C7.96302 2.48931 8.08105 2.50086 8.17597 2.54083L8.19023 2.54683L8.20482 2.55194C8.28212 2.57899 8.36194 2.63847 8.43559 2.74007C8.49867 2.8405 8.53 2.94444 8.53 3.04001V6.91001ZM14.11 6.42001C14.127 6.42001 14.1439 6.42006 14.1608 6.42017C14.1506 6.4201 14.1403 6.42006 14.13 6.42003L14.12 6.42001L14.11 6.42001Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.12 6.41H9.03V3.04C9.03 2.83 8.96 2.63 8.85 2.46C8.73 2.29 8.57 2.15 8.37 2.08C8.18 2 7.96 1.98 7.76 2.02C7.55 2.06 7.36 2.16 7.21 2.31L2.31 7.14C2.11 7.34 2 7.61 2 7.88C2 8.15 2.11 8.43 2.31 8.62L7.21 13.46C7.36 13.61 7.55 13.71 7.76 13.75C7.97 13.79 8.18 13.77 8.37 13.69C8.56 13.61 8.73 13.48 8.85 13.31C8.97 13.14 9.03 12.93 9.03 12.73V9.36H14.11C16.81 9.36 19.02 11.53 19.02 14.21C19.02 16.89 16.82 19.06 14.11 19.06H10.12C9.3 19.06 8.63 19.72 8.63 20.53C8.63 21.34 9.3 22 10.12 22H14.11C18.46 22 21.99 18.51 21.99 14.21C21.99 9.91 18.45 6.42 14.11 6.42L14.12 6.41Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Undo;
