/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const Drawer = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0938 6.1875H14.1562C14.07 6.1875 14 6.25746 14 6.34375V11.6562C14 11.7425 14.07 11.8125 14.1562 11.8125H15.0938C15.18 11.8125 15.25 11.7425 15.25 11.6562V6.34375C15.25 6.25746 15.18 6.1875 15.0938 6.1875Z"
      fill={fill}
    />
    <path
      d="M11.6562 2.75H6.34375C6.25746 2.75 6.1875 2.81996 6.1875 2.90625V3.84375C6.1875 3.93004 6.25746 4 6.34375 4H11.6562C11.7425 4 11.8125 3.93004 11.8125 3.84375V2.90625C11.8125 2.81996 11.7425 2.75 11.6562 2.75Z"
      fill={fill}
    />
    <path
      d="M3.84375 6.1875H2.90625C2.81996 6.1875 2.75 6.25746 2.75 6.34375V11.6562C2.75 11.7425 2.81996 11.8125 2.90625 11.8125H3.84375C3.93004 11.8125 4 11.7425 4 11.6562V6.34375C4 6.25746 3.93004 6.1875 3.84375 6.1875Z"
      fill={fill}
    />
    <path
      d="M11.6562 14H6.34375C6.25746 14 6.1875 14.07 6.1875 14.1562V15.0938C6.1875 15.18 6.25746 15.25 6.34375 15.25H11.6562C11.7425 15.25 11.8125 15.18 11.8125 15.0938V14.1562C11.8125 14.07 11.7425 14 11.6562 14Z"
      fill={fill}
    />
    <path
      d="M14.625 5.25C15.6605 5.25 16.5 4.41053 16.5 3.375C16.5 2.33947 15.6605 1.5 14.625 1.5C13.5895 1.5 12.75 2.33947 12.75 3.375C12.75 4.41053 13.5895 5.25 14.625 5.25Z"
      fill={fill}
    />
    <path
      d="M3.375 5.25C4.41053 5.25 5.25 4.41053 5.25 3.375C5.25 2.33947 4.41053 1.5 3.375 1.5C2.33947 1.5 1.5 2.33947 1.5 3.375C1.5 4.41053 2.33947 5.25 3.375 5.25Z"
      fill={fill}
    />
    <path
      d="M14.625 16.5C15.6605 16.5 16.5 15.6605 16.5 14.625C16.5 13.5895 15.6605 12.75 14.625 12.75C13.5895 12.75 12.75 13.5895 12.75 14.625C12.75 15.6605 13.5895 16.5 14.625 16.5Z"
      fill={fill}
    />
    <path
      d="M3.375 16.5C4.41053 16.5 5.25 15.6605 5.25 14.625C5.25 13.5895 4.41053 12.75 3.375 12.75C2.33947 12.75 1.5 13.5895 1.5 14.625C1.5 15.6605 2.33947 16.5 3.375 16.5Z"
      fill={fill}
    />
  </svg>
);

Drawer.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Drawer.defaultProps = {
  fill: colorPalette.white,
  width: '18',
  height: '18',
};

export default Drawer;
