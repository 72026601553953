import { getSubdomain, SUBDOMAIN_NAMES } from 'utils/subdomains';

export default () => {
  const subdomain = getSubdomain();
  const { href } = window.location;

  if (!(href.includes('/share') || href.includes('/embed'))) {
    return import('./layout/colors/default_colors.css');
  }

  switch (subdomain) {
    case SUBDOMAIN_NAMES.wtp:
      return import('./layout/colors/wtp_colors.css');
    default:
      return import('./layout/colors/default_colors.css');
  }
};
