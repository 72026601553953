// Waypoint Sidebar Panel Modes
export const WAYPOINT_EDIT_MODE = 'EDIT';
export const WAYPOINT_VIEW_MODE = 'VIEW';

// Sidebar Panel Types
export const DEED_DRAWER_PANEL = 'DEED_DRAWER';
export const GEOREFERENCE_PANEL = 'GEOREFERENCE';
export const HELP_CENTER_PANEL = 'HELP_CENTER';
export const PARCELS_INFO_PANEL = 'PARCELS_INFO';
export const PARCELS_MERGE_PANEL = 'PARCELS_MERGE';
export const PARCELS_PANEL = 'PARCELS';
export const PRINT_PANEL = 'PRINT';
export const REPORT_PANEL = 'REPORT';
export const WAYPOINT_PANEL = 'HOME_MAP';

// Distance
export const MEASUREMENT_UNITS = {
  FT: 'feet',
  MILES: 'miles',
};

// Map Tools
export const TOOLS = {
  GEO_REFERENCE: 'geo-reference-tool',
  PRINT: 'print-tool',
  DEED_PLOTTER: 'deed-plotter-tool',
  BUFFER: 'buffer-tool',
  CUT: 'cut-tool',
  MEASURE_PERIMETER: 'polylines-measure-tool',
  MEASURE_AREA: 'polygons-measure-tool',
  STANDARD_LABEL: 'standard-label-tool',
  BACKGROUND_LABEL: 'background-label-tool',
  PARCELS: 'parcels-control',
  REPORTS: 'report-control',
};

export const MAPPED_FEATURES_PANEL_INDEX = 1;
export const FEATURES_PANEL_INDEX = 0;

export const ALLOWED_THIRD_PARTY_IFRAMES = [
  'https://view.ricohtours.com/',
  'https://my.matterport.com/',
  'https://app.cloudpano.com/',
  'https://momento360.com/',
];

export const EDGE_TOOLS = {
  DIRECTION: 'line-direction',
  TRACK: 'line-tracks',
  DISTANCE: 'line-distance',
};

export const TOOL_EDGE_CODE = 'TOOL_EDGE';
export const DECIMAL_PRECISION = 2;
export const METERS_PER_ACRE = 4046.86;
export const KILOMETERS_PER_MILE = 1.609;
export const FEETS_PER_MILE = 5280;
export const EXPORT_CSV_MAX_ACRES = 5013679;
export const EXPORT_CSV_MAX_RADIUS = 50;
export const ACRES_PER_SQUARE_METER = 0.000247105;
export const FEET_PER_KILOMETER = 3280.84;
export const MILE_PER_KILOMETER = 0.621371;
export const EXPORT_CSV_MAX_POLYLINE_LONGITUDE_FT = 5280000;
export const EXPORT_CSV_MAX_POLYLINE_LONGITUDE_MI = 1000;

export const EMAIL_REGEX =
  /^((\w+([.-]?\w+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63})$/;

// regex below adds allowing + for id.land and qubika.com email addresses
// eslint-disable-next-line
export const EMAIL_REGEX_ALLOW_PLUS =
  /^((\w+([.-]?\w+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63})$|[\w+.-]+@(id\.land|qubika\.com)$/;

// User
export const USAGE_TRACKING_OPTIONS = [
  'For Personal Use',
  'To Market Properties',
  'To Appraise Properties',
  'Learn About Properties',
  'Real Estate Needs',
  'Mapping My Properties',
  'Other Professional Needs',
];

export const INDIVIDUAL_TEAM = 'individual';
export const ORGANIZATIONS = 'organizations';

export const PRO_LIMITED_PLANS = ['p-monthly', 'p-annual'];
export const PRO_UNLIMITED_PLANS = ['pu-monthly', 'pu-annual'];
export const FREE_PLAN = 'free';
export const FREE_STATUS = 'free';
export const TRIAL_STATUS = 'trial';
export const ACTIVE_STATUS = 'active';
export const EXPIRED_STATUS = 'expired';
export const CANCELLED_STATUS = 'cancelled';
export const PAYMENT_ISSUE_STATUS = 'payment_issue';

// Mobile app stores
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.mapright.android';
export const APPLE_STORE_URL =
  'https://apps.apple.com/us/app/mapright/id1173937731';

export const LOCATION_PERMISSION_DENIED_CACHE_KEY = 'locationPermissionDenied';

// Subscription sources
export const APPLE_SUBSCRIPTION_SOURCE = 'AppStore';
export const GOOGLE_SUBSCRIPTION_SOURCE = 'PlayStore';
export const MOBILE_SUBSCRIPTION_SOURCES = [
  APPLE_SUBSCRIPTION_SOURCE,
  GOOGLE_SUBSCRIPTION_SOURCE,
];
export const STRIPE_SOURCE = 'Stripe';

export const DEFAULT_ACTIVE_SIDEBAR_TAB = 'Overview';
export const ACTIVE_PARCELS_INFO_PANEL = 'PARCELS_INFO';

// Width and height to create bounding box around a point
export const BOUNDING_BOX_WIDTH = 8;
export const BOUNDING_BOX_HEIGHT = 8;

// Magic Link's purposes
export const MAGIC_LINK_PURPOSES = {
  EMAIL_VERIFICATION: 'MagicLinkPurpose::EmailVerification',
  RE_TRIAL: 'MagicLinkPurpose::ReTrial',
};

export const AERIAL_TILE_BASE_URL = {
  MAPBOX: 'https://api.mapbox.com/v4/mapbox.satellite/',
  HEX: 'https://mapright.giza.cloud/login/path/',
};

// possible status codes
export const STATUS_FORBIDDEN = '403';
export const STATUS_GONE = '410';
export const STATUS_NOT_FOUND = '404';

export enum StatusCode {
  STATUS_FORBIDDEN,
  STATUS_GONE,
  STATUS_NOT_FOUND,
}
