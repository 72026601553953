/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Lowercase = ({ fill }) => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.21054 3.028C5.32254 3.532 5.53254 4.176 5.71454 4.694L6.41454 6.626H3.97854L4.67854 4.694C4.86054 4.176 5.07054 3.532 5.18254 3.028H5.21054ZM7.63254 10H9.98454V9.748L6.42854 0.899999H3.93654L0.380539 9.748V10H2.76054L3.33454 8.418H7.05854L7.63254 10ZM13.8829 4.218C14.5129 4.218 14.9049 4.554 14.9049 5.212C14.9049 5.87 14.4989 6.206 13.8829 6.206C13.2669 6.206 12.8609 5.87 12.8609 5.212C12.8609 4.554 13.2529 4.218 13.8829 4.218ZM17.5509 10.308C17.5509 8.95 16.1649 8.362 14.8209 8.362H13.1969C12.8049 8.362 12.5389 8.236 12.5389 7.858C12.5389 7.732 12.5529 7.592 12.6229 7.48C13.0009 7.578 13.4349 7.62 13.8829 7.62C15.6189 7.62 17.0049 6.934 17.0049 5.31V5.156C17.0049 4.386 16.7109 3.84 16.2069 3.448L17.5509 1.838V1.586H15.6329L14.5829 2.818C14.3589 2.79 14.1209 2.776 13.8829 2.776C12.1189 2.776 10.7609 3.546 10.7609 5.156V5.296C10.7609 6.024 11.0409 6.57 11.5029 6.948C10.7889 7.396 10.6349 7.802 10.6349 8.292C10.6349 8.908 10.9849 9.174 11.3629 9.44C10.7049 9.664 10.2429 10.392 10.2429 10.784V10.896C10.2429 12.394 12.2449 12.73 13.6869 12.73C15.4369 12.73 17.5509 12.1 17.5509 10.406V10.308ZM13.8969 11.232C12.8469 11.232 12.4269 11.008 12.4269 10.49C12.4269 10.322 12.5389 10.056 12.8189 9.958H14.6669C15.1569 9.958 15.4089 10.14 15.4089 10.504C15.4089 10.994 14.9469 11.232 13.8969 11.232Z"
      fill={fill}
    />
  </svg>
);

Lowercase.propTypes = {
  fill: PropTypes.string,
};

Lowercase.defaultProps = {
  fill: '#FFFFFF',
};

export default Lowercase;
