import { StyleableOnlyIconProps } from '../types';

const NavigationMenuEllipsesHorizontal = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66683 14.3333C5.28567 14.3333 5.87916 14.0874 6.31675 13.6498C6.75433 13.2122 7.00016 12.6188 7.00016 11.9999C7.00016 11.3811 6.75433 10.7876 6.31674 10.35C5.87916 9.91242 5.28567 9.66659 4.66683 9.66659C4.04799 9.66659 3.4545 9.91242 3.01691 10.35C2.57933 10.7876 2.3335 11.3811 2.3335 11.9999C2.3335 12.6188 2.57933 13.2122 3.01691 13.6498C3.4545 14.0874 4.04799 14.3333 4.66683 14.3333ZM20.0003 14.3333C20.6192 14.3333 21.2127 14.0874 21.6502 13.6498C22.0878 13.2122 22.3337 12.6188 22.3337 11.9999C22.3337 11.3811 22.0878 10.7876 21.6502 10.35C21.2127 9.91242 20.6192 9.66659 20.0003 9.66659C19.3815 9.66659 18.788 9.91242 18.3504 10.35C17.9128 10.7876 17.667 11.3811 17.667 11.9999C17.667 12.6188 17.9128 13.2122 18.3504 13.6498C18.788 14.0874 19.3815 14.3333 20.0003 14.3333ZM13.9832 13.6498C13.5457 14.0874 12.9522 14.3333 12.3333 14.3333C11.7145 14.3333 11.121 14.0874 10.6834 13.6498C10.2458 13.2122 10 12.6188 10 11.9999C10 11.3811 10.2458 10.7876 10.6834 10.35C11.121 9.91242 11.7145 9.66659 12.3333 9.66659C12.9522 9.66659 13.5457 9.91242 13.9832 10.35C14.4208 10.7876 14.6667 11.3811 14.6667 11.9999C14.6667 12.6188 14.4208 13.2122 13.9832 13.6498Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.12516 12C7.12516 10.5043 5.9126 9.29171 4.41683 9.29171C2.92106 9.29171 1.7085 10.5043 1.7085 12C1.7085 13.4958 2.92106 14.7084 4.41683 14.7084C5.9126 14.7084 7.12516 13.4958 7.12516 12ZM14.4168 12C14.4168 10.5043 13.2042 9.29171 11.7085 9.29171C10.2127 9.29171 9.00012 10.5043 9.00012 12C9.00012 13.4958 10.2127 14.7084 11.7085 14.7084C13.2042 14.7084 14.4168 13.4958 14.4168 12ZM19.0002 9.29171C20.496 9.29171 21.7085 10.5043 21.7085 12C21.7085 13.4958 20.496 14.7084 19.0002 14.7084C17.5044 14.7084 16.2919 13.4958 16.2919 12C16.2919 10.5043 17.5044 9.29171 19.0002 9.29171Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default NavigationMenuEllipsesHorizontal;
