import { xor, uniq } from 'lodash';
import us from 'us';

export const getStateOverlays = (overlays, state) => {
  let stateOverlays = [];

  if (overlays && state) {
    const mapState = us.lookup(state);
    if (mapState?.abbr) {
      stateOverlays = overlays.layers[mapState.abbr.toLowerCase()];
    }
  }

  return stateOverlays;
};

export const toggleLayer = (layers, selectedLayers, toggleOn = false) =>
  toggleOn ? uniq(selectedLayers.concat(layers)) : xor(selectedLayers, layers);

export const getNewLayersForCategory = (
  customLayers,
  layers,
  toggleOn = false,
) => {
  return {
    selectedLayers: toggleLayer(
      layers,
      structuredClone(customLayers),
      toggleOn,
    ),
  };
};

export default {
  getStateOverlays,
  toggleLayer,
  getNewLayersForCategory,
};
