import { StyleableOnlyIconProps } from '../types';

const Wifi = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.25C8.46987 3.25 5.07101 4.58836 2.48864 6.99527C2.18563 7.27769 2.16894 7.75227 2.45136 8.05528C2.73378 8.35828 3.20836 8.37497 3.51137 8.09256C5.81606 5.94445 8.84945 4.75 12 4.75C15.1506 4.75 18.1839 5.94445 20.4886 8.09256C20.7916 8.37497 21.2662 8.35828 21.5486 8.05528C21.8311 7.75227 21.8144 7.27769 21.5114 6.99527C18.929 4.58836 15.5301 3.25 12 3.25ZM4.6817 9.88081C6.62268 7.93996 9.25514 6.84961 12 6.84961C14.7449 6.84961 17.3773 7.93996 19.3183 9.88081C19.6112 10.1737 19.6112 10.6486 19.3184 10.9415C19.0255 11.2344 18.5506 11.2344 18.2577 10.9415C16.598 9.28194 14.3471 8.34961 12 8.34961C9.65294 8.34961 7.402 9.28194 5.74232 10.9415C5.44942 11.2344 4.97454 11.2344 4.68166 10.9415C4.38878 10.6486 4.38879 10.1737 4.6817 9.88081ZM11.9976 10.4496C10.2075 10.4496 8.49075 11.1606 7.22491 12.4263C6.932 12.7192 6.93197 13.1941 7.22485 13.487C7.51772 13.7799 7.9926 13.7799 8.28551 13.4871C9.27005 12.5026 10.6053 11.9496 11.9976 11.9496C13.3899 11.9496 14.7252 12.5026 15.7097 13.4871C16.0026 13.7799 16.4775 13.7799 16.7704 13.487C17.0632 13.1941 17.0632 12.7192 16.7703 12.4263C15.5045 11.1606 13.7877 10.4496 11.9976 10.4496ZM11.3636 17.8355C11.1948 17.6667 11.1 17.4378 11.1 17.1991C11.1 16.9604 11.1948 16.7315 11.3636 16.5627C11.5324 16.3939 11.7613 16.2991 12 16.2991C12.2387 16.2991 12.4676 16.3939 12.6364 16.5627C12.8052 16.7315 12.9 16.9604 12.9 17.1991C12.9 17.4378 12.8052 17.6667 12.6364 17.8355C12.4676 18.0043 12.2387 18.0991 12 18.0991C11.7613 18.0991 11.5324 18.0043 11.3636 17.8355ZM9.60001 17.1991C9.60001 17.8356 9.85287 18.446 10.303 18.8961C10.753 19.3462 11.3635 19.5991 12 19.5991C12.6365 19.5991 13.247 19.3462 13.6971 18.8961C14.1472 18.446 14.4 17.8356 14.4 17.1991C14.4 16.5626 14.1472 15.9521 13.6971 15.502C13.247 15.0519 12.6365 14.7991 12 14.7991C11.3635 14.7991 10.753 15.0519 10.303 15.502C9.85287 15.9521 9.60001 16.5626 9.60001 17.1991Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.31818 6.81239C4.94683 4.36235 8.4066 3 12 3C15.5934 3 19.0532 4.36235 21.6818 6.81239C22.0858 7.18895 22.1081 7.82172 21.7315 8.22573C21.355 8.62974 20.7222 8.65199 20.3182 8.27544C18.0598 6.17047 15.0873 5 12 5C8.91271 5 5.94023 6.17047 3.68182 8.27544C3.27781 8.65199 2.64504 8.62974 2.26848 8.22573C1.89192 7.82172 1.91417 7.18895 2.31818 6.81239ZM12 6.59961C9.18886 6.59961 6.49282 7.7163 4.50495 9.70403C4.11442 10.0945 4.11439 10.7277 4.50491 11.1182C4.89542 11.5088 5.52858 11.5088 5.91912 11.1183C7.53192 9.5056 9.71927 8.59961 12 8.59961C14.2808 8.59961 16.4682 9.5056 18.081 11.1183C18.4715 11.5088 19.1047 11.5088 19.4952 11.1182C19.8857 10.7277 19.8857 10.0945 19.4951 9.70403C17.5073 7.7163 14.8112 6.59961 12 6.59961ZM11.9976 10.1996C10.1412 10.1996 8.36085 10.937 7.04812 12.2496C6.65757 12.6401 6.65754 13.2732 7.04804 13.6638C7.43854 14.0543 8.07171 14.0544 8.46225 13.6638C9.39992 12.7263 10.6716 12.1996 11.9976 12.1996C13.3236 12.1996 14.5953 12.7263 15.5329 13.6638C15.9235 14.0544 16.5566 14.0543 16.9471 13.6638C17.3376 13.2732 17.3376 12.6401 16.9471 12.2496C15.6343 10.937 13.854 10.1996 11.9976 10.1996ZM10.303 18.8961C9.85287 18.446 9.60001 17.8356 9.60001 17.1991C9.60001 16.5626 9.85287 15.9521 10.303 15.502C10.753 15.0519 11.3635 14.7991 12 14.7991C12.6365 14.7991 13.247 15.0519 13.6971 15.502C14.1472 15.9521 14.4 16.5626 14.4 17.1991C14.4 17.8356 14.1472 18.446 13.6971 18.8961C13.247 19.3462 12.6365 19.5991 12 19.5991C11.3635 19.5991 10.753 19.3462 10.303 18.8961Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Wifi;
