import { searchViewsLayersFeatures } from 'actions/search';
import actionTypes from './actions';
import EventRouter from 'services/EventRouter';

/**
 * Changes the active panel.
 *
 * @param {string} panelName - The name of the panel to activate.
 * @param {any} props - Additional properties for the panel.
 * @param {'discover' | 'edit' | 'share' | 'embed' | null} mapType - The type of the map.
 * @param {'parcel_click' | 'map_click' | 'search' | null} path - How the change was triggered.
 */
export const changeActivePanel =
  (panelName, props, mapType, path) => (dispatch, getState) => {
    if (panelName === 'HELP_CENTER') {
      const {
        map: { id: map_id },
      } = getState();

      if (map_id) {
        EventRouter.sendWithPlanProperties('education_help_shown', {
          map_id,
          map_type: mapType,
        });
      }
    } else if (panelName === 'PARCELS_INFO') {
      const {
        map: { id: map_id },
      } = getState();

      if (map_id) {
        EventRouter.sendWithPlanProperties('mapCard_parcelCard_shown', {
          map_id,
          map_type: mapType,
          path,
        });
      }
    }

    dispatch({
      type: actionTypes.CHANGE_ACTIVE_PANEL,
      panelName,
      props,
    });
  };

export const addActivePanelItem = (panelItem) => ({
  type: actionTypes.ADD_ACTIVE_PANEL_ITEM,
  panelItem,
});

export const removeActivePanelItem = (panelItem) => ({
  type: actionTypes.REMOVE_ACTIVE_PANEL_ITEM,
  panelItem,
});

export const setActivePanelItems = (panelItems) => ({
  type: actionTypes.SET_ACTIVE_PANEL_ITEMS,
  panelItems,
});

export const toggleCollapsed = () => ({
  type: actionTypes.TOGGLE_COLLAPSED,
});

export const changeEditPanel = (panel, props) => (dispatch, getState) => {
  const {
    editSidebar: { editPanel },
  } = getState();

  if (
    editPanel !== 'EDIT_TOOL_INFO' &&
    panel === 'EDIT_TOOL_INFO' &&
    !props.groupTab
  ) {
    props.groupTab = 'Name';
  }

  dispatch({
    type: actionTypes.CHANGE_EDIT_PANEL,
    panel,
    props,
  });
};

export const highlightLayerItem = (feature) => (dispatch, getState) => {
  const layersPanelItemIndex = 1;

  dispatch(searchViewsLayersFeatures(''));
  dispatch(addActivePanelItem(layersPanelItemIndex));
  dispatch({
    type: actionTypes.HIGHLIGHT_LAYER_ITEM,
    feature,
  });
};

export const actions = {
  changeActivePanel,
  addActivePanelItem,
  removeActivePanelItem,
  setActivePanelItems,
  toggleCollapsed,
  changeEditPanel,
  highlightLayerItem,
};
