export const PRODUCTION_ROOT_DOMAIN = 'id.land';
export const PRODUCTION_API_ROOT_DOMAIN = 'https://api.id.land';
export const PRODUCTION_PARCELS_API_ROOT_DOMAIN = 'https://parcels.id.land';

export const getAPIURL = () => {
  if (!['production'].includes(process.env.ENVIRONMENT))
    return process.env.API_URL;

  return window.location.hostname.includes(`.${PRODUCTION_ROOT_DOMAIN}`) ?
      process.env.API_URL
    : PRODUCTION_API_ROOT_DOMAIN;
};

export const getParcelsAPIURL = () => {
  if (!['production'].includes(process.env.ENVIRONMENT))
    return process.env.PARCELS_API_URL;

  return window.location.hostname.includes(`.${PRODUCTION_ROOT_DOMAIN}`) ?
      process.env.PARCELS_API_URL
    : PRODUCTION_PARCELS_API_ROOT_DOMAIN;
};

export const isEmbeddedInIframe = () => {
  return window.self !== window.top;
};
