import type { RootState } from 'store';

import { settingsEndpoints } from './endpoints';

export const selectSettings = (from?: string) => (state: RootState) =>
  settingsEndpoints.endpoints.fetchSettings.select({ from })(state)?.data;

export const selectSettingsEnabledLayers =
  (from?: string) => (state: RootState) =>
    selectSettings(from)(state)?.configuration?.enabled_layers;
