/* eslint-disable max-len */
import React from 'react';

const Logo = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4229_8062)">
      <path
        d="M-0.0214844 9.69741C-0.0214844 6.30725 -0.0214852 4.61174 0.638305 3.31688C1.21882 2.17802 2.14457 1.25141 3.28343 0.671753C4.57829 0.0119629 6.2738 0.0119629 9.66396 0.0119629H10.2692C13.6594 0.0119629 15.3549 0.0119629 16.6497 0.671753C17.7886 1.25227 18.7152 2.17802 19.2949 3.31688C19.9546 4.61174 19.9546 6.30725 19.9546 9.69741V10.3026C19.9546 13.6928 19.9546 15.3883 19.2949 16.6832C18.7143 17.822 17.7886 18.7486 16.6497 19.3283C15.3549 19.9881 13.6594 19.9881 10.2692 19.9881H9.66396C6.2738 19.9881 4.57829 19.9881 3.28343 19.3283C2.14457 18.7478 1.21797 17.822 0.638305 16.6832C-0.0214852 15.3883 -0.0214844 13.6928 -0.0214844 10.3026V9.69741Z"
        fill="#1D1D1D"
      />
      <path
        d="M16.192 11.5983H15.701C15.2038 11.5983 14.8008 12.0013 14.8008 12.4984V12.9895C14.8008 13.4866 15.2038 13.8896 15.701 13.8896H16.192C16.6891 13.8896 17.0921 13.4866 17.0921 12.9895V12.4984C17.0921 12.0013 16.6891 11.5983 16.192 11.5983Z"
        fill="#F9213B"
      />
      <path
        d="M6.85318 6.51347C6.85318 7.20821 6.26414 7.58499 5.55832 7.58499C4.8525 7.58499 4.27539 7.20821 4.27539 6.51347V6.40777C4.27539 5.71303 4.8525 5.31323 5.55832 5.31323C6.26414 5.31323 6.85318 5.71388 6.85318 6.40777V6.51347ZM4.4868 13.8479V7.96177H6.64092V13.8479H4.4868Z"
        fill="white"
      />
      <path
        d="M11.7998 5.60901V8.41099H11.7759C11.458 7.99926 10.9635 7.787 10.3046 7.787C8.62102 7.787 7.37305 9.21143 7.37305 10.8123V11.0476C7.37305 12.8957 8.71479 14.026 10.1043 14.026C10.8408 14.026 11.4341 13.8035 11.8475 13.3211L11.8177 12.9161L11.9728 12.8283L12.336 13.8496H13.9539V5.60901H11.7998ZM10.7402 12.2606C9.99857 12.2606 9.58684 11.7193 9.58684 10.8711C9.58684 10.023 9.99857 9.48165 10.7402 9.48165C11.4818 9.48165 11.8586 9.97607 11.8586 10.8711C11.8586 11.7662 11.4469 12.2606 10.7402 12.2606Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4229_8062">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
