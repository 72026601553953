const TransparentBox = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5914_57998)">
      <rect x="0.554688" width="24" height="24" rx="4" fill="white" />
      <rect
        x="28.5547"
        y="-5"
        width="2"
        height="46.1387"
        transform="rotate(45 28.5547 -5)"
        fill="#F9213B"
      />
    </g>
    <rect
      x="1.05469"
      y="0.5"
      width="23"
      height="23"
      rx="3.5"
      stroke="#B9B9B9"
    />
    <defs>
      <clipPath id="clip0_5914_57998">
        <rect x="0.554688" width="24" height="24" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TransparentBox;
