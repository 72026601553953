import { StyleableOnlyIconProps } from '../types';

const Listings = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.84375 2.5C2.42954 2.5 2.09375 2.83579 2.09375 3.25C2.09375 3.66421 2.42954 4 2.84375 4H4.90825C4.9138 4.02722 4.91666 4.05513 4.91666 4.08333V5.54167C4.91666 5.65217 4.87277 5.75815 4.79463 5.83629C4.76683 5.86409 4.73551 5.88755 4.70172 5.90625H3C2.44772 5.90625 2 6.35396 2 6.90625V15.9062C2 16.4585 2.44772 16.9062 3 16.9062H18.3125C18.8648 16.9062 19.3125 16.4585 19.3125 15.9062V6.90625C19.3125 6.35397 18.8648 5.90625 18.3125 5.90625H16.3816C16.3478 5.88755 16.3165 5.86409 16.2887 5.83629C16.2106 5.75815 16.1667 5.65217 16.1667 5.54167V4.08333C16.1667 4.05513 16.1695 4.02722 16.1751 4H20.2188C20.3568 4 20.4688 4.11193 20.4688 4.25V21.25C20.4688 21.6642 20.8045 22 21.2188 22C21.633 22 21.9688 21.6642 21.9688 21.25V4.25C21.9688 3.2835 21.1852 2.5 20.2188 2.5H2.84375ZM14.4916 4H6.59175C6.58619 4.02722 6.58333 4.05513 6.58333 4.08333V5.54167C6.58333 5.65217 6.62723 5.75815 6.70537 5.83629C6.73317 5.86409 6.76449 5.88755 6.79828 5.90625H14.285C14.3188 5.88755 14.3502 5.86409 14.378 5.83629C14.4561 5.75815 14.5 5.65217 14.5 5.54167V4.08333C14.5 4.05513 14.4971 4.02722 14.4916 4ZM3.3 7.20625V15.6062H18.0125V7.20625H3.3Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5118 2.48816C21.8244 2.80072 22 3.22464 22 3.66667V21.1667C22 21.3877 21.9122 21.5996 21.7559 21.7559C21.5996 21.9122 21.3877 22 21.1667 22C20.9457 22 20.7337 21.9122 20.5774 21.7559C20.4211 21.5996 20.3333 21.3877 20.3333 21.1667V4.08333C20.3333 3.97283 20.2894 3.86685 20.2113 3.78871C20.1332 3.71057 20.0272 3.66667 19.9167 3.66667H16.5833C16.4728 3.66667 16.3668 3.71057 16.2887 3.78871C16.2106 3.86685 16.1667 3.97283 16.1667 4.08333V5.54167C16.1667 5.65217 16.2106 5.75815 16.2887 5.83629C16.3668 5.91443 16.4728 5.95833 16.5833 5.95833H18.0417C18.3732 5.95833 18.6911 6.09003 18.9255 6.32445C19.16 6.55887 19.2917 6.87681 19.2917 7.20833V15.5417C19.2917 15.8732 19.16 16.1911 18.9255 16.4255C18.6911 16.66 18.3732 16.7917 18.0417 16.7917H3.25C2.91848 16.7917 2.60054 16.66 2.36612 16.4255C2.1317 16.1911 2 15.8732 2 15.5417V7.20833C2 6.87681 2.1317 6.55887 2.36612 6.32445C2.60054 6.09003 2.91848 5.95833 3.25 5.95833H4.5C4.61051 5.95833 4.71649 5.91443 4.79463 5.83629C4.87277 5.75815 4.91667 5.65217 4.91667 5.54167V4.08333C4.91667 3.97283 4.87277 3.86685 4.79463 3.78871C4.71649 3.71057 4.61051 3.66667 4.5 3.66667H3.25C3.02899 3.66667 2.81702 3.57887 2.66074 3.42259C2.50446 3.26631 2.41667 3.05435 2.41667 2.83333C2.41667 2.61232 2.50446 2.40036 2.66074 2.24408C2.81702 2.0878 3.02899 2 3.25 2H20.3333C20.7754 2 21.1993 2.17559 21.5118 2.48816ZM6.70537 3.78871C6.62723 3.86685 6.58333 3.97283 6.58333 4.08333V5.54167C6.58333 5.65217 6.62723 5.75815 6.70537 5.83629C6.78351 5.91443 6.88949 5.95833 7 5.95833H14.0833C14.1938 5.95833 14.2998 5.91443 14.378 5.83629C14.4561 5.75815 14.5 5.65217 14.5 5.54167V4.08333C14.5 3.97283 14.4561 3.86685 14.378 3.78871C14.2998 3.71057 14.1938 3.66667 14.0833 3.66667H7C6.88949 3.66667 6.78351 3.71057 6.70537 3.78871Z"
          fill="black"
        />
      </svg>;
};

export default Listings;
