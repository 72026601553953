import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';
interface MapIconProps extends IconPropsWithVariant {
  secondaryFill?: string;
}

const Map = ({
  fill = colors.black,
  width = 20,
  height = 20,
  variant = 'outlined',
  secondaryFill,
}: MapIconProps) => {
  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.80643 5.67188C10.6349 5.67188 11.3064 5.0003 11.3064 4.17188C11.3064 3.34345 10.6349 2.67188 9.80643 2.67188C8.978 2.67188 8.30643 3.34345 8.30643 4.17188C8.30643 5.0003 8.978 5.67188 9.80643 5.67188Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80643 10.8333C9.86107 10.834 9.91526 10.8232 9.96549 10.8017C10.0157 10.7802 10.0609 10.7484 10.0981 10.7083C10.2564 10.55 13.9731 6.80833 13.9731 4.16667C13.9731 3.0616 13.5341 2.00179 12.7527 1.22039C11.9713 0.438987 10.9115 0 9.80643 0C8.70136 0 7.64155 0.438987 6.86015 1.22039C6.07875 2.00179 5.63976 3.0616 5.63976 4.16667C5.63976 6.80833 9.35643 10.55 9.51476 10.7083C9.55197 10.7484 9.59714 10.7802 9.64737 10.8017C9.6976 10.8232 9.75178 10.834 9.80643 10.8333ZM9.80643 9.11859C10.1542 8.7297 10.6046 8.19874 11.0491 7.59664C11.4958 6.99153 11.916 6.34206 12.2198 5.71446C12.5318 5.07009 12.6731 4.54639 12.6731 4.16667C12.6731 3.40638 12.3711 2.67723 11.8335 2.13963C11.2959 1.60202 10.5667 1.3 9.80643 1.3C9.04614 1.3 8.31699 1.60202 7.77939 2.13963C7.24178 2.67723 6.93976 3.40638 6.93976 4.16667C6.93976 4.54639 7.08103 5.07009 7.39302 5.71446C7.6969 6.34206 8.11704 6.99153 8.56376 7.59664C9.00826 8.19874 9.45868 8.7297 9.80643 9.11859Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9303 10.1822L12.2308 13.1875H7.42032L7.69247 10.1939C7.38845 9.83672 7.04996 9.4186 6.71338 8.96268C6.47639 8.64167 6.23672 8.29708 6.00882 7.9375H2.74091C1.70779 7.9375 0.84468 8.72438 0.749428 9.7531L0.00868723 17.7531C-0.0998662 18.9255 0.822778 19.9375 2.00017 19.9375H17.6127C18.7901 19.9375 19.7127 18.9255 19.6042 17.7531L18.8634 9.7531C18.7682 8.72438 17.9051 7.9375 16.8719 7.9375H13.604C13.3761 8.29708 13.1365 8.64167 12.8995 8.96268C12.5666 9.41365 12.2318 9.82765 11.9303 10.1822ZM6.25505 9.4375H2.74091C2.48263 9.4375 2.26686 9.63422 2.24304 9.8914L1.93785 13.1875H5.91414L6.25505 9.4375ZM7.28396 14.6875H12.3808L12.7558 18.4375H6.94305L7.28396 14.6875ZM13.8883 14.6875L14.2633 18.4375H17.6127C17.907 18.4375 18.1377 18.1845 18.1106 17.8914L17.8139 14.6875H13.8883ZM13.3633 9.4375L13.7383 13.1875H17.675L17.3698 9.8914C17.346 9.63422 17.1302 9.4375 16.8719 9.4375H13.3633ZM5.77777 14.6875H1.79896L1.5023 17.8914C1.47516 18.1845 1.70582 18.4375 2.00017 18.4375H5.43687L5.77777 14.6875Z"
        fill={secondaryFill || fill}
      />
    </svg>
  );

  const filled = () => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0905 10.8017C10.0403 10.8232 9.98608 10.834 9.93143 10.8333C9.87678 10.834 9.8226 10.8232 9.77237 10.8017C9.72214 10.7802 9.67697 10.7484 9.63976 10.7083C9.60929 10.6779 9.44705 10.5147 9.20489 10.2522C8.18862 9.15067 5.76476 6.29998 5.76476 4.16667C5.76476 3.0616 6.20375 2.00179 6.98515 1.22039C7.76655 0.438987 8.82636 0 9.93143 0C11.0365 0 12.0963 0.438987 12.8777 1.22039C13.6591 2.00179 14.0981 3.0616 14.0981 4.16667C14.0981 6.2974 11.6801 9.14378 10.6617 10.2482C10.6542 10.2563 10.6468 10.2643 10.6395 10.2722C10.4076 10.523 10.2528 10.6786 10.2231 10.7083C10.2231 10.7083 10.2231 10.7083 10.2231 10.7083C10.1859 10.7484 10.1407 10.7802 10.0905 10.8017ZM8.71887 3.35646C8.55863 3.59628 8.4731 3.87824 8.4731 4.16667C8.47528 4.55277 8.62963 4.92243 8.90265 5.19545C9.17567 5.46846 9.54533 5.62281 9.93143 5.625C10.2199 5.625 10.5018 5.53947 10.7416 5.37923C10.9815 5.21898 11.1684 4.99122 11.2788 4.72475C11.3891 4.45827 11.418 4.16505 11.3617 3.88216C11.3055 3.59927 11.1666 3.33942 10.9626 3.13547C10.7587 2.93152 10.4988 2.79262 10.2159 2.73635C9.93305 2.68008 9.63983 2.70896 9.37335 2.81934C9.10687 2.92972 8.87911 3.11664 8.71887 3.35646Z"
        fill="#0065FF"
      />
      <path
        d="M13.8668 13.1875L13.3787 8.46663C13.4835 8.31464 13.5875 8.15885 13.6892 8H16.8719C17.9051 8 18.7682 8.78688 18.8634 9.8156L19.1756 13.1875H13.8668Z"
        fill="#0065FF"
      />
      <path
        d="M9.37845 11.7208C9.5532 11.7957 9.7414 11.834 9.93143 11.8334C10.1215 11.834 10.3097 11.7957 10.4844 11.7208C10.6558 11.6474 10.8106 11.5403 10.9397 11.4059C11.0263 11.319 11.4424 10.8966 11.9781 10.2726L12.3034 13.1875H7.52825L7.68691 10.2498L7.69247 10.2564L7.87043 10.2559C8.41306 10.8891 8.83584 11.3182 8.92316 11.4059C9.05225 11.5403 9.20706 11.6474 9.37845 11.7208Z"
        fill="#0065FF"
      />
      <path
        d="M13.0638 20H7.16031L7.44724 14.6875H12.4708L13.0638 20Z"
        fill="#0065FF"
      />
      <path
        d="M14.5712 20H17.6127C18.7901 20 19.7127 18.988 19.6042 17.8156L19.3145 14.6875H14.0219L14.5712 20Z"
        fill="#0065FF"
      />
      <path
        d="M5.89378 14.6875L5.56347 20H2.00017C0.822779 20 -0.0998662 18.988 0.00868723 17.8156L0.298327 14.6875H5.89378Z"
        fill="#0065FF"
      />
      <path
        d="M0.437216 13.1875H5.98704L6.28354 8.41862C6.19073 8.28177 6.09886 8.14206 6.00882 8H2.74091C1.70779 8 0.84468 8.78688 0.749428 9.8156L0.437216 13.1875Z"
        fill="#0065FF"
      />
    </svg>
  );

  return variant === 'outlined' ? outlined() : filled();
};

export default Map;
