import { StyleableOnlyIconProps } from '../types';

const DocumentVertical = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7723 2.06102C14.7332 2.02195 14.6803 2 14.625 2H5.66667C5.22464 2 4.80072 2.17559 4.48816 2.48816C4.17559 2.80072 4 3.22464 4 3.66667V20.3333C4 20.7754 4.17559 21.1993 4.48816 21.5118C4.80072 21.8244 5.22464 22 5.66667 22H19C19.442 22 19.8659 21.8244 20.1785 21.5118C20.4911 21.1993 20.6667 20.7754 20.6667 20.3333V8.04167C20.6667 7.98641 20.6447 7.93342 20.6056 7.89435L14.7723 2.06102ZM14 3.5H5.66667C5.62246 3.5 5.58007 3.51756 5.54882 3.54882C5.51756 3.58007 5.5 3.62246 5.5 3.66667V20.3333C5.5 20.3775 5.51756 20.4199 5.54882 20.4512C5.58007 20.4824 5.62246 20.5 5.66667 20.5H19C19.0442 20.5 19.0866 20.4824 19.1179 20.4512C19.1491 20.4199 19.1667 20.3775 19.1667 20.3333V9H15.5939C15.1712 9 14.7658 8.83199 14.4668 8.53293C14.1679 8.23386 14 7.82825 14 7.40531V3.5Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9167 6.99997H20.1667C20.208 6.9994 20.2482 6.98658 20.2822 6.96312C20.3162 6.93967 20.3424 6.90665 20.3576 6.86826C20.3728 6.82986 20.3762 6.78782 20.3675 6.74747C20.3587 6.70713 20.3381 6.67029 20.3084 6.64164L16.0251 2.34997C15.9946 2.32348 15.9573 2.30604 15.9174 2.29962C15.8775 2.29321 15.8366 2.29808 15.7993 2.31368C15.7621 2.32928 15.7299 2.35499 15.7065 2.38791C15.683 2.42083 15.6693 2.45965 15.6667 2.49997V5.74997C15.6667 6.08149 15.7984 6.39944 16.0329 6.63386C16.2673 6.86828 16.5852 6.99997 16.9167 6.99997Z"
          fill="black"
        />
        <path
          d="M20.4583 7.83333H16.9167C16.3641 7.83333 15.8342 7.61384 15.4435 7.22314C15.0528 6.83244 14.8333 6.30253 14.8333 5.75V2.20833C14.8333 2.15308 14.8114 2.10009 14.7723 2.06102C14.7332 2.02195 14.6803 2 14.625 2H5.66667C5.22464 2 4.80072 2.17559 4.48816 2.48816C4.17559 2.80072 4 3.22464 4 3.66667V20.3333C4 20.7754 4.17559 21.1993 4.48816 21.5118C4.80072 21.8244 5.22464 22 5.66667 22H19C19.442 22 19.8659 21.8244 20.1785 21.5118C20.4911 21.1993 20.6667 20.7754 20.6667 20.3333V8.04167C20.6667 7.98641 20.6447 7.93342 20.6056 7.89435C20.5666 7.85528 20.5136 7.83333 20.4583 7.83333Z"
          fill="black"
        />
      </svg>;
};

export default DocumentVertical;
