import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Upload = ({ fill = colors.white }: IconProps) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M18.458 1.58301C18.458 0.892652 17.8984 0.333008 17.208 0.333008C16.5177 0.333008 15.958 0.892652 15.958 1.58301V12.1069L13.9252 10.0741C13.4371 9.58597 12.6456 9.58597 12.1575 10.0741C11.6693 10.5623 11.6693 11.3537 12.1575 11.8419L16.3241 16.0086C16.444 16.1284 16.5821 16.2188 16.7295 16.2798C16.8769 16.3409 17.0385 16.3747 17.208 16.3747C17.549 16.3747 17.8582 16.2381 18.0837 16.0167L18.458 1.58301Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M18.458 1.58301V12.1069L20.4908 10.0741C20.9789 9.58597 21.7704 9.58597 22.2586 10.0741C22.7467 10.5623 22.7467 11.3537 22.2586 11.8419L18.0927 16.0077L18.458 1.58301Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M5.54134 28.6663C5.54134 27.976 6.10099 27.4163 6.79134 27.4163H27.6247C28.315 27.4163 28.8747 27.976 28.8747 28.6663C28.8747 29.3567 28.315 29.9163 27.6247 29.9163H6.79134C6.10099 29.9163 5.54134 29.3567 5.54134 28.6663Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M28.8747 25.0205C28.8747 24.0425 28.0818 23.2497 27.1038 23.2497C26.1258 23.2497 25.333 24.0425 25.333 25.0205C25.333 25.9985 26.1258 26.7913 27.1038 26.7913C28.0818 26.7913 28.8747 25.9985 28.8747 25.0205Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M3.66634 19.083C1.82537 19.083 0.333008 20.5754 0.333008 22.4163V30.7497C0.333008 32.5906 1.82537 34.083 3.66634 34.083H30.7497C32.5906 34.083 34.083 32.5906 34.083 30.7497V22.4163C34.083 20.5754 32.5906 19.083 30.7497 19.083H3.66634ZM2.83301 22.4163C2.83301 21.9561 3.20612 21.583 3.66634 21.583H30.7497C31.2099 21.583 31.583 21.9561 31.583 22.4163V30.7497C31.583 31.2099 31.2099 31.583 30.7497 31.583H3.66634C3.20612 31.583 2.83301 31.2099 2.83301 30.7497V22.4163Z"
      fill={fill}
    />
  </svg>
);

export default Upload;
