import { StyleableOnlyIconProps } from '../types';
import * as colors from '../../styles/colors.module.scss';

const ChevronUp = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colors.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3284 17.1545C20.6579 17.5572 21.2516 17.6165 21.6543 17.2871C22.0571 16.9576 22.1165 16.364 21.7869 15.9613L14.1937 6.68391C13.4473 5.77203 12.0526 5.77203 11.3063 6.68391L3.71304 15.9613C3.38344 16.364 3.44283 16.9576 3.84565 17.2871C4.24841 17.6165 4.84204 17.5572 5.1716 17.1545L12.75 7.89523L20.3284 17.1545Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0308 5.79904C12.5547 5.79904 13.0036 6.02353 13.303 6.39769L20.7114 14.7041C21.1604 15.3027 21.0855 16.2007 20.4869 16.6497C19.8882 17.0987 19.0651 17.0987 18.6161 16.5L12.1805 9.16649C12.1057 9.09165 12.0308 9.09165 11.8812 9.16649L5.4456 16.5C4.92178 17.0987 4.09862 17.1735 3.49996 16.6497C2.90131 16.1259 2.82647 15.3027 3.3503 14.7041C3.3503 14.7041 3.3503 14.7041 3.42513 14.6292L10.7587 6.32286C11.058 6.02353 11.5818 5.79904 12.0308 5.79904Z"
          fill={color}
        />
      </svg>;
};

export default ChevronUp;
