import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const DeedDrawer = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.125 8.25H18.875C18.7599 8.25 18.6667 8.34327 18.6667 8.45833V15.5417C18.6667 15.6567 18.7599 15.75 18.875 15.75H20.125C20.2401 15.75 20.3333 15.6567 20.3333 15.5417V8.45833C20.3333 8.34327 20.2401 8.25 20.125 8.25Z"
        fill={color}
      />
      <path
        d="M15.5417 3.66667H8.45833C8.34327 3.66667 8.25 3.75994 8.25 3.875V5.125C8.25 5.24006 8.34327 5.33333 8.45833 5.33333H15.5417C15.6567 5.33333 15.75 5.24006 15.75 5.125V3.875C15.75 3.75994 15.6567 3.66667 15.5417 3.66667Z"
        fill={color}
      />
      <path
        d="M5.125 8.25H3.875C3.75994 8.25 3.66667 8.34327 3.66667 8.45833V15.5417C3.66667 15.6567 3.75994 15.75 3.875 15.75H5.125C5.24006 15.75 5.33333 15.6567 5.33333 15.5417V8.45833C5.33333 8.34327 5.24006 8.25 5.125 8.25Z"
        fill={color}
      />
      <path
        d="M15.5417 18.6667H8.45833C8.34327 18.6667 8.25 18.7599 8.25 18.875V20.125C8.25 20.2401 8.34327 20.3333 8.45833 20.3333H15.5417C15.6567 20.3333 15.75 20.2401 15.75 20.125V18.875C15.75 18.7599 15.6567 18.6667 15.5417 18.6667Z"
        fill={color}
      />
      <path
        d="M19.5 7C20.8807 7 22 5.88071 22 4.5C22 3.11929 20.8807 2 19.5 2C18.1193 2 17 3.11929 17 4.5C17 5.88071 18.1193 7 19.5 7Z"
        fill={color}
      />
      <path
        d="M4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2C3.11929 2 2 3.11929 2 4.5C2 5.88071 3.11929 7 4.5 7Z"
        fill={color}
      />
      <path
        d="M19.5 22C20.8807 22 22 20.8807 22 19.5C22 18.1193 20.8807 17 19.5 17C18.1193 17 17 18.1193 17 19.5C17 20.8807 18.1193 22 19.5 22Z"
        fill={color}
      />
      <path
        d="M4.5 22C5.88071 22 7 20.8807 7 19.5C7 18.1193 5.88071 17 4.5 17C3.11929 17 2 18.1193 2 19.5C2 20.8807 3.11929 22 4.5 22Z"
        fill={color}
      />
    </svg>
  );
};

export default DeedDrawer;
