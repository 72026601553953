export default {
  CHANGE_ACTIVE_TOOL: 'CHANGE_ACTIVE_TOOL',
  DESELECT_ACTIVE_TOOL: 'DESELECT_ACTIVE_TOOL',
  CHANGE_ACTIVE_LABEL_TOOL: 'CHANGE_ACTIVE_LABEL_TOOL',
  DESELECT_ACTIVE_LABEL_TOOL: 'DESELECT_ACTIVE_LABEL_TOOL',
  ADD_TOOL: 'ADD_TOOL',
  HIDE_MEASURE_TOOLS: 'HIDE_MEASURE_TOOLS',
  SHOW_MEASURE_TOOLS: 'SHOW_MEASURE_TOOLS',
  TOGGLE_MEASURE_TOOL_VISIBILITY: 'TOGGLE_MEASURE_TOOL_VISIBILITY',
  START_MEASURING: 'START_MEASURING',
  STOP_MEASURING: 'STOP_MEASURING',
  TOGGLE_LABELS: 'TOGGLE_LABELS',
  START_PARCEL_CSV_EXPORT: 'START_PARCEL_CSV_EXPORT',
  FINISH_PARCEL_CSV_EXPORT: 'FINISH_PARCEL_CSV_EXPORT',
  SEND_PARCELS_REPORT_REQUEST_SUCCESS: 'SEND_PARCELS_REPORT_REQUEST_SUCCESS',
  SEND_PARCELS_REPORT_REQUEST_FAILURE: 'SEND_PARCELS_REPORT_REQUEST_FAILURE',
  GET_REPORT_META_SUCCESS: 'GET_REPORT_META_SUCCESS',
  GET_REPORT_META_FAILURE: 'GET_REPORT_META_FAILURE',
  TOGGLE_PARCEL_CSV_EXPORT_STATUS: 'TOGGLE_PARCEL_CSV_EXPORT_STATUS',
  ACTIVATE_TERRAIN: 'ACTIVATE_TERRAIN',
  DEACTIVATE_TERRAIN: 'DEACTIVATE_TERRAIN',
};
