import { forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import Button from 'components/ui/Button/';
import Icon from 'components/ui/DeprecatedIcon';
import { useFeatureFlags } from 'services/FeatureFlags';

import classNames from 'classnames';

import {
  isFreePlan,
  isLegacyUser,
  isDiscoverPlan,
  isEmailVerified,
  subscriptionExpired,
  isAssociatedWithOrganization,
} from 'selectors/userSelectors';
import { useAppSelector } from 'store/hooks';
import { getSubdomain, SUBDOMAIN_NAMES } from 'utils/subdomains';
import { showModal } from 'store/slices/modalsSlice';
import { getAPIURL } from 'api/utils/domains';
import ChooseAccountModal from '../../modals/ChooseAccountModal';
import UpgradePlanModal from 'components/modals/UpgradePlanModal';
import BuyNowButton from 'components/BuyNowButton/BuyNowButton';
import SelectStateModal from 'components/modals/SelectStateModal';
import { useCanCreateMaps } from 'packages/entitlements';

import KWLogo from 'static/images/subdomains/kw.png';
import UCLogo from 'static/images/subdomains/uc.png';
import WTPLogo from 'static/images/subdomains/wtp.png';
import LandId from 'static/images/LandId.svg';
import LandIdNeutral from 'static/images/LandIdNeutral.svg';

import { INDIVIDUAL_TEAM } from 'utils/constants';

import { DASHBOARD_ROUTE, NEW_MAP_ROUTE } from 'utils/routes';

import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';
import './styles.scss';

const SubdomainLogo = ({ subdomain }: { subdomain: string }) => {
  let logo;

  switch (subdomain) {
    case SUBDOMAIN_NAMES.kw:
      logo = KWLogo;
      break;
    case SUBDOMAIN_NAMES.uc:
      logo = UCLogo;
      break;
    case SUBDOMAIN_NAMES.wtp:
      logo = WTPLogo;
      break;
  }

  if (!logo) return null;

  return <img className="main-header__subdomain-logo" src={logo} />;
};

interface TopNavHandle {
  show: () => void;
  hide: () => void;
}

interface TopNavProps {
  empty?: boolean;
}

const TopNav = forwardRef<TopNavHandle, TopNavProps>(({ empty }, ref) => {
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();

  const dispatch = useDispatch();
  const subdomain = getSubdomain();
  const [showBurgerNavbar, setShowBurgerNavbar] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [accountOptionsVisible, setAccountOptionsVisible] = useState(false);

  const user = useAppSelector((state) => state.user);
  const admin = user.user?.admin;
  const authenticated = user.authenticated;
  const verifiedEmail = isEmailVerified(user.user);

  const userSubscriptions = useAppSelector((state) => state.subscriptions);
  const hasExpiredSubscription = subscriptionExpired(userSubscriptions);
  const organizationUser = isAssociatedWithOrganization(userSubscriptions);
  const hasDiscoverPlan = isDiscoverPlan(userSubscriptions);

  const canCreateMaps = useCanCreateMaps();

  const maps = useAppSelector((state) => state.dashboard.maps);

  const subscriptionTotalMaps = maps.length;
  const shouldHideNavBarIcons =
    (isFreePlan(userSubscriptions) && !organizationUser) ||
    !verifiedEmail ||
    empty;

  useImperativeHandle(ref, () => ({
    show: () => setIsVisible(true),
    hide: () => setIsVisible(false),
  }));

  if (!isVisible) {
    return null;
  }

  const onCreateMapClick = () => {
    const { selectedAccount } = user;

    if (hasDiscoverPlan) {
      navigate('/dashboard');
      return;
    }

    const teamSub = selectedAccount && selectedAccount.type !== INDIVIDUAL_TEAM;

    if (canCreateMaps) {
      createNewMap();
    } else {
      dispatch(
        showModal(UpgradePlanModal, {
          onMap: false,
          onContinue: createNewMap,
          teamSub,
        }),
      );
    }
  };

  const createNewMap = () => {
    const isAutosaveEnabled = featureFlags.autosaveCreate320?.value;

    if (isAutosaveEnabled) {
      navigate(NEW_MAP_ROUTE);
    } else {
      dispatch(
        showModal(SelectStateModal, {
          onSubmit: () => navigate(NEW_MAP_ROUTE),
        }),
      );
    }
  };

  const toggleNavBar = () => {
    const newShowState = !showBurgerNavbar;
    setShowBurgerNavbar(newShowState);
    toggleParentOverflow(newShowState ? 'hidden' : 'visible');
  };

  const closeMenu = () => {
    setShowBurgerNavbar(false);
    toggleParentOverflow('visible');
  };

  const onAccountDropdown = () => {
    dispatch(
      showModal(ChooseAccountModal, {
        topNavVersion: true,
        onClose: () => {
          setAccountOptionsVisible(false);
          setShowBurgerNavbar(false);
          toggleParentOverflow('hidden');
        },
      }),
    );
    setAccountOptionsVisible(true);
  };

  const toggleParentOverflow = (overflow = 'hidden') => {
    // We need to do this because the overflow is handled in the parent node
    document.getElementsByTagName('main')[0].style.overflow = overflow;
  };

  const renderLogo = () => {
    const subdomainLogo =
      subdomain ? <SubdomainLogo subdomain={subdomain} /> : null;

    const onMapRightLogoClick = () => navigate(`${DASHBOARD_ROUTE}`);

    return (
      <div
        className="app-logo"
        style={{ cursor: 'pointer' }}
        onClick={onMapRightLogoClick}
      >
        <img
          className="main-header__logo"
          src={isLegacyUser ? LandId : LandIdNeutral}
        />
        {subdomainLogo}
      </div>
    );
  };

  const navLinkClassname =
    (classPrefix: string) =>
    ({ isActive }: { isActive: boolean }) =>
      isActive ? `main-header${classPrefix}__nav__left__active-tab` : '';

  const renderNavBarOld = (classPrefix = '') =>
    authenticated && (
      <>
        <div className={`oldmain-header${classPrefix}__nav__left`}>
          {authenticated && admin && (
            <li>
              <a href={`${getAPIURL()}/admin`}>
                Admin
                {showBurgerNavbar && <i />}
              </a>
            </li>
          )}
          <li>
            <NavLink to="/dashboard">
              Saved Maps
              {subscriptionTotalMaps > 0 && ` (${subscriptionTotalMaps})`}
              {showBurgerNavbar && <i />}
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile">
              Account Details <i />
            </NavLink>
          </li>
          <li>
            <NavLink to="/reports">
              Prints & Reports {showBurgerNavbar && <i />}
            </NavLink>
          </li>
        </div>
        <div className={`oldmain-header${classPrefix}__nav__right`}>
          {!authenticated && (
            <li>
              <NavLink to="/users/sign_in">Login</NavLink>
            </li>
          )}
          <li>
            <a
              href={`${process.env.HELP_LINK}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </li>
          <li>
            <a href="/contact" target="_blank" rel="noopener noreferrer">
              Contact
            </a>
          </li>
          <span className="vertical-line" />
          <li onClick={onAccountDropdown} className="profile">
            <Icon id="account" type="filled-individual" />
            <Icon
              id="chevron"
              direction={accountOptionsVisible ? 'top' : 'bottom'}
              className="chevron"
            />
          </li>
        </div>
      </>
    );

  const renderNavBar = (classPrefix = '') =>
    authenticated && (
      <>
        <div className={`main-header${classPrefix}__nav__left`}>
          <li>
            <NavLink
              className={navLinkClassname(classPrefix)}
              to="/discover"
              onClick={closeMenu}
            >
              <Icon id="discover" fill="currentColor" />
              <>Discover Map {showBurgerNavbar && <i />}</>
            </NavLink>
          </li>
          <li>
            <NavLink
              className={navLinkClassname(classPrefix)}
              to="/dashboard"
              onClick={closeMenu}
            >
              <Icon id="saved" fill="currentColor" />
              <span>
                My Maps
                {showBurgerNavbar && <i />}
                {hasDiscoverPlan && <span className="pro-tag">Pro</span>}
              </span>
              <i />
            </NavLink>
          </li>
          <li>
            <NavLink
              className={navLinkClassname(classPrefix)}
              to="/reports"
              onClick={closeMenu}
            >
              <Icon
                id="reports"
                fill="currentColor"
                variant="outlined"
                width="24"
                height="24"
              />
              <span>
                Prints & Reports
                {showBurgerNavbar && <i />}
                {hasDiscoverPlan && <span className="pro-tag">Pro</span>}
              </span>
            </NavLink>
          </li>
        </div>
        <div className={`main-header${classPrefix}__nav__right`}>
          {!authenticated && (
            <li>
              <NavLink to="/users/sign_in">Login</NavLink>
            </li>
          )}
          {!hasExpiredSubscription && (
            <Button
              color="white"
              iconHeight="12"
              iconWidth="12"
              iconStrokeWidth="0.8"
              fontColor="fBlack"
              fill={colorPalette.blackLight}
              onClick={onCreateMapClick}
              size="wAuto"
              otherClassNames={['create-new-map-button']}
            >
              Create New Map{' '}
              <Icon
                id="plus"
                className={classNames('glyphicon', 'reskin-button__icon')}
                fill={colorPalette.blackLight} // Use the fill prop value passed to the Button component
                height="12"
                width="12"
                strokeWidth="0.8"
              />
              {showBurgerNavbar && <i />}
            </Button>
          )}
          <div
            className={`main-header${classPrefix}__nav__right__account-box
            ${
              accountOptionsVisible ?
                `main-header${classPrefix}__nav__right__account-box__choose-account-modal`
              : ''
            }`}
          >
            <li
              onClick={onAccountDropdown}
              className={`main-header${classPrefix}__nav__right__profile`}
            >
              <Icon
                className={`main-header${classPrefix}__nav__right__profile__profile-icon`}
                id="account"
                type="filled-square-individual"
              />
              <p>Account</p>
              <Icon
                id="chevron"
                height="12"
                width="12"
                strokeWidth="0.8"
                direction={accountOptionsVisible ? 'top' : 'bottom'}
                className={`main-header${classPrefix}__nav__right__profile__chevron`}
                fill="#1D1D1D"
              />
            </li>
          </div>
          <BuyNowButton />
        </div>
      </>
    );

  // The nav bar is simplified when the user is in the sign up flow.
  // When the user is in the sign up flow his subscription has a free status.
  // As the organization users can have free individual subscriptions the
  // nav bar simplification is skipped for those cases, otherwise they cannot see
  // the select account modal.
  if (shouldHideNavBarIcons) {
    return (
      <header className="main-header">
        <div className="main-header__container">{renderLogo()};</div>
      </header>
    );
  }

  return (
    <div>
      {isLegacyUser ?
        <header className="oldmain-header">
          <div className="oldmain-header__container">
            {renderLogo()}
            <div
              className="oldmain-header__container__burger"
              onClick={toggleNavBar}
            >
              {showBurgerNavbar ?
                <span className="oldmain-header__container__burger__close_icon" />
              : <span className="oldmain-header__container__burger__icon" />}
            </div>
            <div className="oldmain-header__nav">{renderNavBarOld()}</div>
          </div>

          <div
            className={classNames('oldmain-header__burger', {
              'oldmain-header__burger__full_height': showBurgerNavbar,
            })}
          >
            {showBurgerNavbar && renderNavBarOld('__burger')}
          </div>
        </header>
      : <header className="main-header">
          <div className="main-header__container">
            {renderLogo()}
            <div
              className="main-header__container__burger"
              onClick={toggleNavBar}
            >
              {showBurgerNavbar ?
                <span className="main-header__container__burger__close_icon" />
              : <span className="main-header__container__burger__icon" />}
            </div>
            <div className="main-header__nav">{renderNavBar()}</div>
          </div>

          <div
            className={classNames('main-header__burger', {
              'main-header__burger__full_height': showBurgerNavbar,
            })}
          >
            {showBurgerNavbar && renderNavBar('__burger')}
          </div>
        </header>
      }
    </div>
  );
});

export default TopNav;
