import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const OpenMap = ({ fill = colors.white }: IconProps) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8496 14.5577H4.34961V4.05774H9.59961V2.55774H4.34961C3.51711 2.55774 2.84961 3.23274 2.84961 4.05774V14.5577C2.84961 15.3827 3.51711 16.0577 4.34961 16.0577H14.8496C15.6746 16.0577 16.3496 15.3827 16.3496 14.5577V9.30774H14.8496V14.5577ZM11.0996 2.55774V4.05774H13.7921L6.41961 11.4302L7.47711 12.4877L14.8496 5.11524V7.80774H16.3496V2.55774H11.0996Z" />
  </svg>
);

export default OpenMap;
