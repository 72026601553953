import React from 'react';
import PropTypes from 'prop-types';

const CheckCircle = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M9.00008 0.666992C7.35191 0.666992 5.74074 1.15573 4.37033 2.07141C2.99992 2.98709 1.93182 4.28858 1.30109 5.8113C0.670359 7.33401 0.505331 9.00957 0.826874 10.6261C1.14842 12.2426 1.94209 13.7274 3.10753 14.8929C4.27297 16.0583 5.75782 16.852 7.37433 17.1735C8.99084 17.4951 10.6664 17.33 12.1891 16.6993C13.7118 16.0686 15.0133 15.0005 15.929 13.6301C16.8447 12.2597 17.3334 10.6485 17.3334 9.00032C17.3334 6.79019 16.4554 4.67057 14.8926 3.10777C13.3298 1.54497 11.2102 0.666992 9.00008 0.666992V0.666992ZM13.8126 6.36144L9.05564 12.8128C9.00029 12.8883 8.93026 12.9517 8.84977 12.9994C8.76928 13.0471 8.67997 13.0781 8.58722 13.0904C8.49448 13.1027 8.40019 13.0962 8.31004 13.0711C8.21988 13.0461 8.13571 13.0031 8.06258 12.9448L4.66675 10.2295C4.59541 10.1722 4.5361 10.1013 4.49223 10.021C4.44836 9.94074 4.4208 9.85255 4.41112 9.76156C4.40144 9.67057 4.40983 9.57856 4.43583 9.49083C4.46182 9.40309 4.5049 9.32136 4.56258 9.25032C4.67798 9.1103 4.84359 9.021 5.024 9.00153C5.2044 8.98206 5.38525 9.03397 5.52786 9.14616L8.3612 11.41L12.6945 5.5281C12.8039 5.38168 12.9665 5.28425 13.1472 5.25695C13.3279 5.22965 13.5121 5.27468 13.6598 5.38227C13.7348 5.43599 13.7984 5.50412 13.8468 5.58267C13.8951 5.66123 13.9274 5.74864 13.9416 5.8398C13.9558 5.93096 13.9517 6.02404 13.9296 6.1136C13.9074 6.20317 13.8677 6.28742 13.8126 6.36144Z"
      fill={fill}
    />
  </svg>
);

CheckCircle.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckCircle.defaultProps = {
  fill: '#0065FF',
  width: '20',
  height: '20',
};

export default CheckCircle;
