import { NonConfigurableIconProps } from '../types';

const Track = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98084 17.9066H10.2758C10.487 18.5042 10.788 19.0662 11.1683 19.5733H5.98084C5.16354 19.5733 4.46682 18.9806 4.33575 18.1739L2.0224 3.93584C1.82238 2.70474 2.99586 1.6982 4.18215 2.08333L19.9491 7.20211C20.8439 7.49262 21.3211 8.46568 21.0029 9.35102L20.3459 11.1791C19.9147 10.7911 19.4347 10.4678 18.9212 10.2153L19.4344 8.78733L3.6675 3.66855L5.98084 17.9066Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.159 11.3195C14.0497 10.7243 15.097 10.4066 16.1683 10.4066C17.6042 10.4088 18.9807 10.9802 19.996 11.9955C21.0114 13.0109 21.5828 14.3873 21.585 15.8233C21.585 16.8946 21.2673 17.9418 20.6721 18.8326C20.0769 19.7234 19.2309 20.4176 18.2412 20.8276C17.2514 21.2376 16.1623 21.3448 15.1116 21.1358C14.0608 20.9268 13.0957 20.4109 12.3381 19.6534C11.5806 18.8959 11.0647 17.9307 10.8557 16.88C10.6467 15.8293 10.754 14.7402 11.1639 13.7504C11.5739 12.7606 12.2682 11.9146 13.159 11.3195ZM13.643 16.2652C13.7602 16.3824 13.9192 16.4483 14.085 16.4483H18.2516C18.4174 16.4483 18.5764 16.3824 18.6936 16.2652C18.8108 16.148 18.8766 15.989 18.8766 15.8233C18.8766 15.6575 18.8108 15.4985 18.6936 15.3813C18.5764 15.2641 18.4174 15.1983 18.2516 15.1983H14.085C13.9192 15.1983 13.7602 15.2641 13.643 15.3813C13.5258 15.4985 13.46 15.6575 13.46 15.8233C13.46 15.989 13.5258 16.148 13.643 16.2652Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Track;
