import { StyleableOnlyIconProps } from '../types';
import * as colorPalette from '../../styles/colors.module.scss';

const Tips = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5.745C12.46 5.745 12.83 5.375 12.83 4.915V2.825C12.83 2.365 12.46 1.995 12 1.995C11.54 1.995 11.17 2.365 11.17 2.825V4.915C11.17 5.375 11.54 5.745 12 5.745Z"
          fill={color}
        />
        <path
          d="M13.57 19.915H10.45C10.3 19.915 10.17 19.995 10.09 20.115C10.02 20.235 10.02 20.395 10.09 20.515L10.36 21.075C10.64 21.635 11.21 21.995 11.84 22.005H12.17C12.81 22.005 13.39 21.645 13.67 21.075L13.94 20.515C14.01 20.395 14.01 20.235 13.94 20.115C13.86 19.985 13.72 19.905 13.57 19.915Z"
          fill={color}
        />
        <path
          d="M18.49 5.16499C18.81 4.84499 19.33 4.84499 19.66 5.16499H19.67C19.99 5.49499 19.99 6.02499 19.66 6.34499L18.18 7.81499C18.03 7.96499 17.82 8.05499 17.6 8.05499C17.38 8.05499 17.17 7.97499 17.01 7.81499C16.68 7.48499 16.68 6.95499 17.01 6.63499L18.49 5.16499Z"
          fill={color}
        />
        <path
          d="M21.17 11.995H19.5C19.04 11.995 18.67 12.365 18.67 12.825C18.67 13.285 19.04 13.655 19.5 13.655H21.17C21.63 13.655 22 13.285 22 12.825C22 12.365 21.63 11.995 21.17 11.995Z"
          fill={color}
        />
        <path
          d="M5.52002 5.175L7.00002 6.645C7.33002 6.975 7.33002 7.505 7.00002 7.825C6.84002 7.985 6.62002 8.065 6.40002 8.065C6.18002 8.065 5.98002 7.975 5.82002 7.825L4.35002 6.345C4.08002 6.035 4.08002 5.575 4.35002 5.265C4.64002 4.915 5.17002 4.875 5.52002 5.175Z"
          fill={color}
        />
        <path
          d="M4.5 11.995H2.83C2.37 11.995 2 12.365 2 12.825C2 13.285 2.37 13.655 2.83 13.655H4.5C4.96 13.655 5.33 13.285 5.33 12.825C5.33 12.365 4.96 11.995 4.5 11.995Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.59003 12.425C6.59003 9.425 9.01003 6.995 12 6.995C13.77 6.995 15.43 7.865 16.44 9.315C18.15 11.765 17.55 15.145 15.1 16.855C14.99 16.925 14.92 17.055 14.92 17.195V17.825C14.92 18.285 14.55 18.655 14.09 18.655H9.92003C9.46003 18.655 9.09003 18.285 9.09003 17.825V17.195C9.09003 17.065 9.02003 16.935 8.91003 16.855C7.46003 15.845 6.59003 14.195 6.59003 12.425ZM15.21 10.165C14.48 9.125 13.28 8.495 12 8.495C10.95 8.495 9.97003 8.90499 9.23003 9.645C8.49003 10.385 8.09003 11.365 8.09003 12.415C8.09003 13.695 8.71003 14.895 9.76003 15.625C10.27 15.975 10.58 16.555 10.58 17.165H13.41C13.41 16.545 13.73 15.965 14.25 15.615C15.1 15.015 15.67 14.115 15.85 13.095C16.04 12.065 15.81 11.025 15.21 10.165Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5892 5.50592C12.433 5.6622 12.221 5.75 12 5.75C11.779 5.75 11.567 5.6622 11.4107 5.50592C11.2545 5.34964 11.1667 5.13768 11.1667 4.91667V2.83333C11.1667 2.61232 11.2545 2.40036 11.4107 2.24408C11.567 2.0878 11.779 2 12 2C12.221 2 12.433 2.0878 12.5892 2.24408C12.7455 2.40036 12.8333 2.61232 12.8333 2.83333V4.91667C12.8333 5.13768 12.7455 5.34964 12.5892 5.50592Z"
          fill={color}
        />
        <path
          d="M11.9958 7C10.8252 7.00183 9.68565 7.37085 8.74271 8.05344C7.79977 8.73603 7.10252 9.69665 6.75223 10.7958C6.40194 11.8949 6.41685 13.0753 6.7948 14.1655C7.17275 15.2558 7.89406 16.199 8.85395 16.8583C8.90978 16.8966 8.9552 16.9477 8.98622 17.0073C9.01723 17.0669 9.03288 17.1331 9.03179 17.2V17.8333C9.03179 18.0543 9.12101 18.2663 9.27983 18.4226C9.43864 18.5789 9.65404 18.6667 9.87864 18.6667H14.1129C14.3375 18.6667 14.5529 18.5789 14.7117 18.4226C14.8705 18.2663 14.9598 18.0543 14.9598 17.8333V17.2C14.9588 17.1322 14.9754 17.0652 15.008 17.0055C15.0406 16.9457 15.0881 16.8951 15.1461 16.8583C16.1065 16.1986 16.8281 15.2546 17.2059 14.1636C17.5836 13.0725 17.5979 11.8914 17.2465 10.7918C16.8951 9.69227 16.1965 8.73169 15.2522 8.04974C14.3079 7.3678 13.1671 7.00006 11.9958 7Z"
          fill={color}
        />
        <path
          d="M13.5878 19.9167H10.4121C10.3391 19.9163 10.2671 19.9345 10.2033 19.9695C10.1395 20.0046 10.086 20.0553 10.048 20.1167C10.0123 20.1775 9.99354 20.2465 9.99354 20.3167C9.99354 20.3869 10.0123 20.4559 10.048 20.5167L10.3275 21.075C10.4664 21.3502 10.6799 21.5822 10.9444 21.7454C11.2089 21.9087 11.5142 21.9968 11.8264 22H12.1651C12.4817 22.0014 12.7923 21.9155 13.0617 21.752C13.3311 21.5885 13.5486 21.354 13.6895 21.075L13.9689 20.5167C14.0046 20.4559 14.0234 20.3869 14.0234 20.3167C14.0234 20.2465 14.0046 20.1775 13.9689 20.1167C13.9302 20.0521 13.8741 19.9993 13.8069 19.9641C13.7397 19.9288 13.6639 19.9124 13.5878 19.9167Z"
          fill={color}
        />
        <path
          d="M19.6583 5.17501C19.5022 5.0198 19.291 4.93268 19.0708 4.93268C18.8507 4.93268 18.6395 5.0198 18.4833 5.17501L17 6.65001C16.9219 6.72747 16.8599 6.81964 16.8176 6.92119C16.7753 7.02274 16.7535 7.13166 16.7535 7.24167C16.7535 7.35168 16.7753 7.4606 16.8176 7.56215C16.8599 7.6637 16.9219 7.75587 17 7.83334C17.0779 7.91057 17.1702 7.97168 17.2718 8.01315C17.3733 8.05462 17.482 8.07564 17.5917 8.07501C17.8103 8.07408 18.0198 7.98729 18.175 7.83334L19.65 6.35834C19.7287 6.28142 19.7913 6.18969 19.8343 6.08844C19.8774 5.98719 19.8999 5.87842 19.9007 5.76841C19.9015 5.6584 19.8804 5.54932 19.8388 5.44747C19.7973 5.34563 19.7359 5.25302 19.6583 5.17501Z"
          fill={color}
        />
        <path
          d="M19.5 12H21.1667C21.3877 12 21.5996 12.0878 21.7559 12.2441C21.9122 12.4004 22 12.6123 22 12.8333C22 13.0543 21.9122 13.2663 21.7559 13.4226C21.5996 13.5789 21.3877 13.6667 21.1667 13.6667H19.5C19.279 13.6667 19.067 13.5789 18.9107 13.4226C18.7545 13.2663 18.6667 13.0543 18.6667 12.8333C18.6667 12.6123 18.7545 12.4004 18.9107 12.2441C19.067 12.0878 19.279 12 19.5 12Z"
          fill={color}
        />
        <path
          d="M7.00001 6.64999L5.51668 5.17499C5.35726 5.03847 5.1522 4.96713 4.94247 4.97523C4.73274 4.98333 4.53379 5.07028 4.38537 5.21869C4.23696 5.3671 4.15002 5.56605 4.14192 5.77578C4.13382 5.98551 4.20516 6.19057 4.34168 6.34999L5.81668 7.83332C5.97189 7.98728 6.18139 8.07407 6.40001 8.07499C6.51108 8.07673 6.62138 8.05625 6.72442 8.01475C6.82745 7.97325 6.92116 7.91156 7.00001 7.83332C7.07812 7.75585 7.14011 7.66369 7.18242 7.56214C7.22473 7.46059 7.24651 7.35167 7.24651 7.24166C7.24651 7.13165 7.22473 7.02273 7.18242 6.92118C7.14011 6.81963 7.07812 6.72746 7.00001 6.64999Z"
          fill={color}
        />
        <path
          d="M2.83333 12H4.5C4.72101 12 4.93298 12.0878 5.08926 12.2441C5.24554 12.4004 5.33333 12.6123 5.33333 12.8333C5.33333 13.0543 5.24554 13.2663 5.08926 13.4226C4.93298 13.5789 4.72101 13.6667 4.5 13.6667H2.83333C2.61232 13.6667 2.40036 13.5789 2.24408 13.4226C2.0878 13.2663 2 13.0543 2 12.8333C2 12.6123 2.0878 12.4004 2.24408 12.2441C2.40036 12.0878 2.61232 12 2.83333 12Z"
          fill={color}
        />
      </svg>;
};

export default Tips;
