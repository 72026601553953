import actionTypes from '../actions/shareMapMobileMapInfo/actions';

const ACTION_HANDLERS = {
  [actionTypes.TOGGLE_SHARE_MAP_MOBILE_MAP_INFO_VISIBILITY]: (
    state,
    action,
  ) => ({
    ...state,
    shareMobileMapInfoVisibility: !state.shareMobileMapInfoVisibility,
  }),
  [actionTypes.CLOSE_SHARE_MAP_MOBILE_MAP_INFO_VISIBILITY]: (
    state,
    action,
  ) => ({
    ...state,
    shareMobileMapInfoVisibility: false,
  }),
};

const initialState = {
  shareMobileMapInfoVisibility: true,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
