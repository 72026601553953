export const actionTypes = {
  OPEN_SHARE_TUTORIAL: 'OPEN_SHARE_TUTORIAL',
  CLOSE_SHARE_TUTORIAL: 'CLOSE_SHARE_TUTORIAL',
  CHANGE_ACTIVE_BUTTON: 'CHANGE_ACTIVE_BUTTON',
  CHANGE_NAV_HISTORY: 'CHANGE_NAV_HISTORY',
  SELECT_HIGHLIGHT: 'SELECT_HIGHLIGHT',
  UNSELECT_HIGHLIGHT: 'UNSELECT_HIGHLIGHT',
  SELECT_MEDIA_ELEMENT: 'SELECT_MEDIA_ELEMENT',
  UNSELECT_MEDIA_ELEMENT: 'UNSELECT_MEDIA_ELEMENT',
  TOGGLE_COLLAPSED: 'TOGGLE_COLLAPSED',
  OPEN_PANO_VIEW: 'OPEN_PANO_VIEW',
  CLOSE_PANO_VIEW: 'CLOSE_PANO_VIEW',
};
