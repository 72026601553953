import { api } from 'api/endpoints';
import { useFeatureFlags, useFeatureFlagsLoaded } from 'services/FeatureFlags';

export interface EnabledLayer {
  enabled: boolean;
  ipad_enabled: boolean;
  key: string;
  layerImage: string;
  maxMapZoom: number;
  minMapZoom: number;
  name: string;
  roads_vector_layer: boolean;
  street_layer: string;
  url: string;
  index?: number;
  disable_roads_layer_toggle?: boolean;
}

export interface Settings {
  configuration: {
    version: number;
    base_layers: {
      default_base_layer_for_map_creation: string;
      default_base_layer_for_home_map: string;
    };
    enabled_layers: {
      [key: string]: EnabledLayer;
    };
    export_link_query_frequency: number;
    usa_geographic_center: { lat: number; lng: number };
    zendesk_support: { url: string };
    tools_settings: any;
    map_types: { id: number; name: string; slug: string }[];
  };
  default_layer: string;
  spa_domain: string;
  parcels_api_url: string;
}

export const settingsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchSettings: builder.query<
      Settings,
      {
        from?: string;
        withAuth?: boolean;
        aerialImageryDisco294?: string | null;
      }
    >({
      query: ({
        withAuth = true,
        from = null,
        aerialImageryDisco294 = null,
      }) => {
        let url;
        // TODO flag removal under DISCO-463
        if (aerialImageryDisco294) {
          url = `/settings.json${
            from ?
              `?from=${from}&aerial_layer=${aerialImageryDisco294}`
            : `?aerial_layer=${aerialImageryDisco294}`
          }`;
        } else {
          url = `/settings.json${from ? `?from=${from}` : ''}`;
        }
        return { url, withAuth };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { from } = queryArgs;
        return `${endpointName}({from: ${from}})`;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchSettingsQuery: useFetchSettingsQueryBase,
  util: { updateQueryData },
} = settingsEndpoints;

// TODO Remove wrapper after DISCO-463
export const useFetchSettingsQuery = (
  arg?: { from?: string; withAuth?: boolean },
  options?: {
    skip?: boolean;
    refetchOnMountOrArgChange?: boolean;
  },
) => {
  const { aerialImageryDisco294 } = useFeatureFlags();
  const featureFlagsLoaded = useFeatureFlagsLoaded();

  const mergedArg = {
    ...arg,
    aerialImageryDisco294: aerialImageryDisco294?.value as string | null,
  };

  const mergedOptions = {
    ...options,
    skip: options?.skip || !featureFlagsLoaded,
  };

  const result = useFetchSettingsQueryBase(mergedArg, mergedOptions);

  if (!featureFlagsLoaded) {
    return {
      data: undefined,
      status: 'pending',
      isUninitialized: false,
      isLoading: true,
      isSuccess: false,
      isError: false,
      isFetching: true,
      refetch: () => {},
    };
  }

  return result;
};
