import { NonConfigurableIconProps } from '../types';

const Devices = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7958 6.18182H18.137V5.07553C18.137 4.9672 18.094 4.86332 18.0174 4.78672C17.9408 4.71012 17.8369 4.66709 17.7286 4.66709H6.15896C6.10463 4.66598 6.05062 4.67573 6.0001 4.69576C5.94958 4.71579 5.90357 4.74569 5.86475 4.78373C5.82594 4.82177 5.7951 4.86717 5.77406 4.91728C5.75301 4.96738 5.74218 5.02118 5.74219 5.07553V13.7694C5.74219 13.8799 5.7861 13.986 5.86426 14.0641C5.94242 14.1423 6.04843 14.1862 6.15896 14.1862H12.4545V17.0869H9.8349C9.67053 17.0869 9.51277 17.1516 9.39577 17.2671C9.27876 17.3825 9.21193 17.5394 9.20974 17.7037C9.2119 17.8689 9.27846 18.0266 9.39523 18.1434C9.51201 18.2602 9.66977 18.3267 9.8349 18.3289H12.4545V18.6818C12.4545 19.1332 12.556 19.5769 12.7488 19.9793H3.47495C3.21627 19.9819 2.96162 19.9152 2.73737 19.7863C2.51313 19.6573 2.32747 19.4707 2.19962 19.2458C2.06897 19.0265 2 18.7759 2 18.5206C2 18.2653 2.06897 18.0148 2.19962 17.7954L4.10011 15.1698C4.14157 15.1144 4.16861 15.0496 4.17879 14.9813C4.18898 14.9129 4.18199 14.843 4.15846 14.778C4.08424 14.5896 4.04744 14.3886 4.05009 14.1862V4.66709C4.05009 4.22495 4.22573 3.80092 4.53837 3.48828C4.85101 3.17564 5.27505 3 5.71719 3H18.1287C18.5708 3 18.9949 3.17564 19.3075 3.48828C19.6201 3.80092 19.7958 4.22495 19.7958 4.66709V6.18182Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3523 7.54545H20.4659C20.8728 7.54545 21.263 7.71307 21.5507 8.01142C21.8384 8.30977 22 8.71443 22 9.13636V18.6818C22 19.1038 21.8384 19.5084 21.5507 19.8068C21.263 20.1051 20.8728 20.2727 20.4659 20.2727H15.3523C14.9454 20.2727 14.5552 20.1051 14.2675 19.8068C13.9798 19.5084 13.8182 19.1038 13.8182 18.6818V9.13636C13.8182 8.71443 13.9798 8.30977 14.2675 8.01142C14.5552 7.71307 14.9454 7.54545 15.3523 7.54545ZM17.625 19.372C17.7091 19.4303 17.808 19.4614 17.9091 19.4614C18.0447 19.4614 18.1748 19.4055 18.2707 19.306C18.3666 19.2066 18.4205 19.0717 18.4205 18.9311C18.4205 18.8262 18.3905 18.7236 18.3343 18.6364C18.2781 18.5492 18.1982 18.4813 18.1048 18.4411C18.0113 18.401 17.9085 18.3905 17.8093 18.4109C17.7101 18.4314 17.619 18.4819 17.5475 18.5561C17.476 18.6302 17.4273 18.7247 17.4076 18.8276C17.3878 18.9305 17.3979 19.0371 17.4367 19.134C17.4754 19.2309 17.5409 19.3137 17.625 19.372ZM20.8275 17.4659C20.9234 17.3664 20.9773 17.2316 20.9773 17.0909V9.41742C20.9773 9.27678 20.9234 9.14189 20.8275 9.04244C20.7316 8.94299 20.6015 8.88712 20.4659 8.88712H15.3523C15.2167 8.88712 15.0866 8.94299 14.9907 9.04244C14.8948 9.14189 14.8409 9.27678 14.8409 9.41742V17.0909C14.8409 17.2316 14.8948 17.3664 14.9907 17.4659C15.0866 17.5653 15.2167 17.6212 15.3523 17.6212H20.4659C20.6015 17.6212 20.7316 17.5653 20.8275 17.4659Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Devices;
