import settingsActionTypes from '../actions/settings/actions';
import actionTypes from '../actions/soilsReport/actions';
import { generateNewMaprightId } from 'utils/general';
import { planeCoordinatesToLatLng } from 'utils/geoUtilities';
import editSidebarActionTypes from '../actions/editSidebar/actions';

const soilsReportStates = {
  HIDDEN: 'HIDDEN',
  CREATING: 'CREATING',
  SHOWING: 'SHOWING',
};

const soilsReportPanelStates = {
  HIDDEN: 'HIDDEN',
  MINIMIZED: 'MINIMIZED',
  LOADING: 'LOADING',
  CAPABILITY_ON_CAP: 'CAPABILITY_ON_CAP',
  CAPABILITY_OFF: 'CAPABILITY_OFF',
};

// Action Handlers
const ACTION_HANDLERS = {
  [actionTypes.HIDE_SOILS_REPORT]: (state, action) => ({
    ...state,
    soilsReportState: soilsReportStates.HIDDEN,
  }),
  [actionTypes.CREATE_SOILS_REPORT]: (state, action) => ({
    ...state,
    soilsReportState: soilsReportStates.CREATING,
  }),
  [actionTypes.SHOW_SOILS_REPORT]: (state, action) => ({
    ...state,
    soilsReportState: soilsReportStates.SHOWING,
    panelState: soilsReportPanelStates.CAPABILITY_OFF,
  }),
  [actionTypes.SELECT_POLYGON_FOR_REPORT]: (state, action) => ({
    ...state,
    selectedPolygons: state.selectedPolygons.concat(action.polygon),
  }),
  [actionTypes.DESELECT_POLYGON_FOR_REPORT]: (state, action) => {
    const polygons = state.selectedPolygons.filter(
      (p) => p.id !== action.polygon.id,
    );
    return {
      ...state,
      selectedPolygons: polygons,
    };
  },
  [actionTypes.DESELECT_ALL_POLYGONS_FOR_REPORT]: (state, action) => ({
    ...state,
    selectedPolygons: [],
    soilsReportQueryData: [],
  }),
  [actionTypes.CHANGE_PANEL_STATE]: (state, action) => {
    if (action.panelState === soilsReportPanelStates.HIDDEN) {
      return {
        ...state,
        panelState: action.panelState,
        soilsReportState: soilsReportStates.HIDDEN,
      };
    }
    return { ...state, panelState: action.panelState };
  },
  [editSidebarActionTypes.CHANGE_ACTIVE_PANEL]: (state, action) => ({
    ...state,
  }),
  [actionTypes.SAVE_SOILS_REPORT_SUCCESS]: (state, action) => ({
    selectedPolygons: [],
    soilsReportState: soilsReportStates.SHOWING,
    panelState: soilsReportPanelStates.CAPABILITY_OFF,
    error: false,
  }),
  [actionTypes.DELETE_SOILS_REPORT_SUCCESS]: (state, action) => initialState,
  [actionTypes.SET_MUSYM_COLOR_MAPPER]: (state, action) => ({
    ...state,
    soilClassColors: action.soilClassColors,
  }),
  [actionTypes.SCROLL_TO_SOIL_ROW]: (state, action) => ({
    ...state,
    selectedSoilRow: action.soilId,
  }),
  [actionTypes.SELECT_SOIL_POLYGON]: (state, action) => ({
    ...state,
    soilId: action.soilId,
    geoJSON: action.geoJSON,
    lngLat: action.lngLat,
    coordinates: action.coordinates,
  }),
  [actionTypes.DESELECT_SOIL_POLYGON]: (state, action) => ({
    ...state,
    soilId: null,
    lngLat: null,
  }),
  [actionTypes.CHANGE_LAYER]: (state, action) => ({
    ...state,
    layer: action.layer,
  }),
  [actionTypes.SOILS_REPORT_QUERY_IN_PROGRESS]: (state) => ({
    ...state,
    status: 'IN_PROGRESS',
  }),
  [actionTypes.SOILS_REPORT_QUERY_SUCCESS]: (state, action) => {
    if (action.fsa) {
      const geometry = planeCoordinatesToLatLng(action.fsa);
      const id = generateNewMaprightId();
      const geoJSON = {
        id,
        type: 'Feature',
        properties: {
          id,
          code: 'polygon-boundary',
          name: 'Boundary',
          color: '#D40000',
        },
        geometry,
        maprightId: id,
      };

      return {
        ...state,
        status: 'SUCCESS',
        soilsReportQueryData: state.soilsReportQueryData.concat(geoJSON),
      };
    }
    return state;
  },
  [actionTypes.CLEAN_SELECTED_POLYGON_FOR_REPORT]: (state) => ({
    ...state,
    selectedPolygons: [],
  }),
  [actionTypes.SOILS_REPORT_QUERY_REMOVE]: (state, action) => {
    const { id } = action;
    const selectedPolygons = state.selectedPolygons.filter(
      (sP) => sP.id !== id,
    );
    const soilsReportQueryData = state.soilsReportQueryData.filter(
      (sRQP) => sRQP.id !== id,
    );
    return {
      ...state,
      status: 'SUCCESS',
      selectedPolygons,
      soilsReportQueryData,
    };
  },
  [actionTypes.SOILS_REPORT_QUERY_READY]: (state) => ({
    ...state,
    status: 'READY',
  }),
  [actionTypes.SOILS_REPORT_QUERY_ERROR]: (state) => ({
    ...state,
    status: 'READY',
    selectedPolygons: [],
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
  [actionTypes.SEND_REPORT_QUERY_FAILURE]: (state, action) => ({
    ...state,
    error: true,
  }),
  [actionTypes.SAVE_SOILS_REPORT_FAILURE]: (state, action) => ({
    ...state,
    error: true,
  }),
};

// Reducer
const initialState = {
  soilsReportQueryData: [],
  selectedPolygons: [],
  soilId: null,
  selectedSoilRow: null,
  lngLat: null,
  coordinates: null,
  soilOnPopup: null,
  soilClassColors: null,
  soilsReportState: soilsReportStates.HIDDEN,
  panelState: soilsReportPanelStates.HIDDEN,
  layer: 'BOUNDARY',
  queryStatus: 'READY',
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
