import React from 'react';
import PropTypes from 'prop-types';

const FolderOpen = ({ fill }) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M1.05072 1.09265C1.36328 0.780087 1.7872 0.604492 2.22923 0.604492H5.35423C5.79625 0.604492 6.22018 0.780087 6.53274 1.09265C6.8453 1.40521 7.02089 1.82913 7.02089 2.27116V2.68783H13.6876C14.1296 2.68783 14.5535 2.86342 14.8661 3.17598C15.1786 3.48854 15.3542 3.91246 15.3542 4.35449V5.81283H15.7707C16.0261 5.81277 16.2783 5.87141 16.5074 5.98423C16.7366 6.09708 16.9368 6.26111 17.0926 6.46365C17.2483 6.66618 17.3553 6.90181 17.4055 7.15232C17.4552 7.40048 17.4477 7.65668 17.3836 7.90146L15.8572 14.146L15.854 14.1584C15.7595 14.5134 15.5502 14.8273 15.2589 15.0511C14.9676 15.2749 14.6105 15.3962 14.2431 15.3962H2.36603C2.30495 15.3962 2.24592 15.3874 2.19012 15.3711C2.17106 15.3692 2.152 15.367 2.13296 15.3645C1.70279 15.308 1.30735 15.0985 1.01898 14.7744C0.730616 14.4502 0.568614 14.0331 0.562619 13.5992L0.5625 13.5906L0.56256 2.27116C0.56256 1.82913 0.738154 1.40521 1.05072 1.09265ZM14.1042 5.81283V4.35449C14.1042 4.24399 14.0603 4.13801 13.9822 4.05986C13.904 3.98172 13.7981 3.93783 13.6876 3.93783H6.39589C6.05071 3.93783 5.77089 3.658 5.77089 3.31283V2.27116C5.77089 2.16065 5.72699 2.05467 5.64885 1.97653C5.57071 1.89839 5.46473 1.85449 5.35423 1.85449H2.22923C2.11872 1.85449 2.01274 1.89839 1.9346 1.97653C1.85646 2.05467 1.81256 2.16065 1.81256 2.27116V13.5855C1.81522 13.7177 1.86497 13.8447 1.95292 13.9435C2.04165 14.0433 2.16332 14.1077 2.29568 14.1251C2.42804 14.1425 2.56223 14.1116 2.6737 14.0382C2.78261 13.9664 2.86271 13.8587 2.90013 13.7339L4.5296 7.04068C4.53178 7.03172 4.53417 7.0228 4.53674 7.01394C4.63762 6.66717 4.8484 6.36256 5.13736 6.14594C5.42622 5.92939 5.77757 5.8125 6.13858 5.81283"
      fill={fill}
    />
  </svg>
);

FolderOpen.propTypes = {
  fill: PropTypes.string,
};

FolderOpen.defaultProps = {
  fill: '#1D1D1D',
};

export default FolderOpen;
