import { NonConfigurableIconProps } from '../types';

const StartOver = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6008 7.00967C12.6008 7.00967 12.5223 7.02127 12.4893 7.04326C12.4563 7.06526 12.4305 7.09657 12.4153 7.13322C12.4001 7.16987 12.3962 7.21021 12.4039 7.24912C12.4117 7.28803 12.4309 7.32376 12.459 7.35177L14.0276 8.92039C14.1058 8.99796 14.1679 9.09024 14.2102 9.19192C14.2526 9.29359 14.2744 9.40265 14.2744 9.5128C14.2744 9.62295 14.2526 9.73201 14.2102 9.83368C14.1679 9.93536 14.1058 10.0276 14.0276 10.1052C13.9496 10.1825 13.8572 10.2437 13.7555 10.2852C13.6539 10.3268 13.545 10.3478 13.4352 10.3472C13.3254 10.3478 13.2165 10.3268 13.1149 10.2852C13.0132 10.2437 12.9207 10.1825 12.8428 10.1052L9.50527 6.7677C9.42707 6.69014 9.365 6.59785 9.32264 6.49618C9.28028 6.3945 9.25847 6.28544 9.25847 6.1753C9.25847 6.06515 9.28028 5.95609 9.32264 5.85441C9.365 5.75274 9.42707 5.66046 9.50527 5.58289L12.8428 2.24538C12.9206 2.16759 13.0129 2.10588 13.1146 2.06377C13.2162 2.02167 13.3252 2 13.4352 2C13.5452 2 13.6541 2.02167 13.7558 2.06377C13.8574 2.10588 13.9498 2.16759 14.0276 2.24538C14.1054 2.32318 14.1671 2.41554 14.2092 2.51718C14.2513 2.61883 14.273 2.72777 14.273 2.83779C14.273 2.94781 14.2513 3.05675 14.2092 3.1584C14.1671 3.26004 14.1054 3.3524 14.0276 3.4302L12.459 4.99883C12.4309 5.02683 12.4117 5.06256 12.4039 5.10147C12.3962 5.14038 12.4001 5.18072 12.4153 5.21737C12.428 5.24781 12.4479 5.27456 12.4732 5.29536C12.527 5.28469 12.5826 5.2791 12.6396 5.2791C17.2569 5.2791 21 9.0222 21 13.6396C21 18.2569 17.2569 22 12.6396 22C8.0222 22 4.2791 18.2569 4.2791 13.6396C4.2791 13.1695 4.66013 12.7885 5.13017 12.7885C5.60019 12.7885 5.98123 13.1695 5.98123 13.6396C5.98123 17.3168 8.96226 20.2979 12.6396 20.2979C16.3168 20.2979 19.2979 17.3168 19.2979 13.6396C19.2979 9.96226 16.3168 6.98123 12.6396 6.98123C12.6266 6.98123 12.6137 6.98094 12.6008 6.98036L12.6008 7.00967Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default StartOver;
