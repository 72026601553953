import { LngLat, LngLatBounds } from 'mapbox-gl';
import us from 'us';
import { isFinite, maxBy } from 'lodash';

export const US_GEOGRAPHIC_CENTER = {
  lat: 39.828328,
  lng: -98.579416,
};

export const US_BOUNDARY = [
  [-123.0, 26.396308],
  [-68.93457, 47.384358],
];

const STATE_BOUNDS = {
  Alabama: [
    [-88.87683556149852, 30.54237557792341],
    [-85.0459009055602, 35.29749324508383],
  ],
  Alaska: [
    [-170.45419546402508, 52.353553737319174],
    [-139.1514462704524, 71.72324714877539],
  ],
  Arizona: [
    [-115.07130971189432, 31.47075234012668],
    [-108.63793270934578, 37.32341595653254],
  ],
  Arkansas: [
    [-94.89433796832603, 32.68227290096853],
    [-89.2791323767453, 36.819862682572676],
  ],
  California: [
    [-125.05809085173746, 31.71747658907323],
    [-114.3454351203152, 42.39247336880507],
  ],
  Colorado: [
    [-109.38972291840545, 36.684137751342035],
    [-101.69053765394133, 41.30012198698631],
  ],
  Connecticut: [
    [-74.02537022684902, 40.77327768965657],
    [-71.48019285238601, 42.21519664122553],
  ],
  Delaware: [
    [-75.86894856633653, 38.316307259901066],
    [-74.76726864983246, 39.747200469211634],
  ],
  Florida: [
    [-87.7293440989835, 24.19545449794265],
    [-79.96251767324554, 31.189783654265867],
  ],
  Georgia: [
    [-85.8505721118304, 30.331225882204222],
    [-80.86510920341752, 35.22607668823214],
  ],
  Hawaii: [
    [-160.64983221239078, 18.292090574005016],
    [-154.21438608062334, 22.314771044129003],
  ],
  Idaho: [
    [-117.91508964009017, 41.46741212113244],
    [-109.99221155813396, 49.38817725436789],
  ],
  Illinois: [
    [-91.38982764726497, 36.964556274229906],
    [-86.87974940244116, 42.64396172375962],
  ],
  Indiana: [
    [-88.408707313705, 37.605928709793375],
    [-84.47281554390537, 41.95892819078881],
  ],
  Iowa: [
    [-96.68807490574733, 40.381961047405895],
    [-89.85727678737341, 43.69776614729122],
  ],
  Kansas: [
    [-102.49626919573863, 36.699359870010426],
    [-94.25775568376505, 40.31455638981453],
  ],
  Kentucky: [
    [-89.79052655476019, 36.061767862827296],
    [-81.68990058104757, 39.35179055811267],
  ],
  Louisiana: [
    [-93.904599, 29.726428],
    [-89.597959, 33.036497],
  ],
  Maine: [
    [-71.16208640209258, 42.76392848060081],
    [-66.78960219468193, 47.72187175193514],
  ],
  Maryland: [
    [-78.24100031529053, 37.58412417904002],
    [-74.53534368184742, 39.93766566384528],
  ],
  Massachusetts: [
    [-73.68275019567132, 41.91238697786147],
    [-70.37075656095344, 42.98380173075617],
  ],
  Michigan: [
    [-90.65654045130668, 41.23856551076298],
    [-82.06170002238663, 48.654477461385454],
  ],
  Minnesota: [
    [-97.76760034727752, 43.05894391938034],
    [-88.66791817551574, 49.77509162532837],
  ],
  Mississippi: [
    [-92.15973004987214, 29.794689851703765],
    [-87.60448384681047, 35.29182151447196],
  ],
  Missouri: [
    [-95.91085815287371, 35.64431538248357],
    [-88.89584133752534, 40.96415373894348],
  ],
  Montana: [
    [-116.52066309766587, 43.86712980385445],
    [-103.59052606791329, 49.47062390416943],
  ],
  Nebraska: [
    [-104.53436835723734, 39.54413214188861],
    [-95.29115807551084, 43.267237213334624],
  ],
  Nevada: [
    [-120.53602487652077, 35.06245702085124],
    [-112.5672911237716, 42.30843005871307],
  ],
  'New Hampshire': [
    [-72.72015106045777, 42.57666076756058],
    [-70.37075656095344, 45.56484687013082],
  ],
  'New Jersey': [
    [-76.03149150483712, 38.641487143390094],
    [-73.44886481532771, 41.56920895310998],
  ],
  'New Mexico': [
    [-109.6416322497217, 31.01698352016392],
    [-102.57442500061677, 37.35498042539918],
  ],
  'New York': [
    [-79.93120895116769, 40.50120256153599],
    [-72.93406802707948, 45.253330982486034],
  ],
  'North Carolina': [
    [-84.61318091767502, 33.41069541896306],
    [-75.28949063632753, 36.7312843742224],
  ],
  'North Dakota': [
    [-104.44825149125796, 45.671311326192125],
    [-96.41067733323494, 49.203208969018675],
  ],
  Ohio: [
    [-85.21566044562324, 38.14851983145235],
    [-80.03777616179804, 42.67417297630942],
  ],
  Oklahoma: [
    [-103.55970953737955, 33.32698421757793],
    [-93.64026617877028, 37.28246766184071],
  ],
  Oregon: [
    [-125.14940234383168, 41.602801681516524],
    [-116.29343327109649, 46.30468753518119],
  ],
  Pennsylvania: [
    [-80.79069599509862, 39.432675475792934],
    [-74.40761514695076, 42.90170741361368],
  ],
  'Rhode Island': [
    [-72.16171633000971, 41.056596768495695],
    [-70.89523203474234, 42.15506064138353],
  ],
  'South Carolina': [
    [-83.54611692675157, 31.606114776875998],
    [-78.3157540859956, 35.45438795076588],
  ],
  'South Dakota': [
    [-104.5343683572368, 42.62389474205506],
    [-96.03750424732675, 46.17053339783985],
  ],
  Tennessee: [
    [-90.57869556841875, 34.81317365647514],
    [-81.47096474392015, 36.766510707487],
  ],
  Texas: [
    [-106.67275589848352, 25.345418118607512],
    [-93.14048013450224, 37.174402618107834],
  ],
  Utah: [
    [-114.88116063792737, 36.61445710096589],
    [-108.60624119701798, 42.42687058827909],
  ],
  Vermont: [
    [-73.82958735189034, 42.64870368849094],
    [-71.34967093574691, 45.22113194663169],
  ],
  Virginia: [
    [-84.077694814859, 36.32407996949625],
    [-74.3691617826866, 39.605386273645166],
  ],
  Washington: [
    [-124.85747083800442, 45.489116266062325],
    [-116.61649961754247, 49.21307548558315],
  ],
  'West Virginia': [
    [-83.06310938086624, 36.983658795899075],
    [-77.79776083548987, 40.97960675382117],
  ],
  Wisconsin: [
    [-93.07126567783578, 42.3389063413517],
    [-85.80259588520704, 47.57021624180558],
  ],
  Wyoming: [
    [-111.67101300010864, 40.72706551721288],
    [-103.58967735651326, 45.31145500864446],
  ],
};

export const getFramePadding = (map) => {
  const framePaddingOffset = 50;
  const [exportFrame] = document.getElementsByClassName('export-frame__rect');
  let position = [exportFrame.getAttribute('x'), exportFrame.getAttribute('y')];
  position = position.map((el) => parseFloat(el));

  return position.map((val) => Math.round(val) + framePaddingOffset);
};

// Returns the zoom to be set if a fit bounds were executed.
export const getFitBoundsZoom = (map, bounds, padding) => {
  const lngLatBounds = LngLatBounds.convert(bounds);

  const lateralPadding = Math.min(padding.right, padding.left);
  const verticalPadding = Math.min(padding.top, padding.bottom);

  const tr = map.transform;
  const nw = tr.project(lngLatBounds.getNorthWest());
  const se = tr.project(lngLatBounds.getSouthEast());
  const size = se.sub(nw);
  const scaleX = (tr.width - lateralPadding * 2) / size.x;
  const scaleY = (tr.height - verticalPadding * 2) / size.y;

  return tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY));
};

// Takes a geoJSON and returns the fitting bound
export const getBounds = (geoJSON, state, onlyVisibleElements = true) => {
  const mapState = us.lookup(state || 'tx');
  const stateName = mapState ? mapState.name : 'Texas';
  const stateBounds = STATE_BOUNDS[stateName];
  let resultingBounds;

  if (geoJSON.length === 0) {
    resultingBounds = stateBounds;
  } else {
    try {
      const bound = geoJSON.reduce((accumulator, currentElement) => {
        let coordinates;
        const { geometry } = currentElement;

        if (!onlyVisibleElements || currentElement.properties.visibility) {
          switch (geometry.type) {
            case 'Point':
              coordinates = [[geometry.coordinates]];
              break;
            case 'MultiPoint':
              coordinates = [geometry.coordinates];
              break;
            case 'LineString':
              coordinates = [geometry.coordinates];
              break;
            case 'Polygon':
              ({ coordinates } = geometry);
              break;
            case 'MultiPolygon':
              coordinates = geometry.coordinates.flat();
              break;
            default:
              coordinates = STATE_BOUNDS[stateName];
          }
        } else {
          coordinates = [];
        }

        coordinates.forEach((polygons) => {
          polygons.forEach((coordinate) => {
            accumulator.extend(new LngLat(...coordinate));
          });
        });

        return accumulator;
      }, new LngLatBounds());
      resultingBounds = bound.toArray();
    } catch (e) {
      resultingBounds = stateBounds;
    }
  }

  return resultingBounds;
};

export const getStateBounds = (state) => {
  if (STATE_BOUNDS[state]) {
    return STATE_BOUNDS[state];
  }

  // Handle outside US
  return US_BOUNDARY;
};

// Returns the padding to use on a fitBounds call, depening on user resolution.
export const getFitBoundsPadding = () => {
  const windowWidth = window.screen.width;
  const mobileMaxWidth = 768; // You can adjust this value to match your definition of mobile width
  const padding =
    windowWidth <= mobileMaxWidth ? 10 : Math.floor(windowWidth * 0.1);
  const maxPadding = 100;
  return padding > maxPadding ? maxPadding : padding;
};

export const getPaddingObject = (options = {}) => {
  const { left, right, top, bottom, defaultPadding } = options;

  const fallbackPadding =
    isFinite(defaultPadding) ? defaultPadding : getFitBoundsPadding();

  return {
    left: isFinite(left) ? left : getLeftOffset() + fallbackPadding,
    right: isFinite(right) ? right : fallbackPadding,
    top: isFinite(top) ? top : fallbackPadding,
    bottom: isFinite(bottom) ? bottom : fallbackPadding,
  };
};

export const getLeftOffset = () => {
  const widestSidebar = maxBy(
    [...document.querySelectorAll('.lid-sidebar')],
    'clientWidth',
  );

  if (!widestSidebar) return 0;
  return widestSidebar.clientWidth + widestSidebar.offsetLeft;
};

export const zoomToCoordinates = (map, geoJSON, state) => {
  const geometryBounds = getBounds(
    geoJSON,
    geoJSON[0]?.properties?.state || state,
    false,
  );

  map.fitBounds(geometryBounds, {
    padding: getPaddingObject(),
    maxZoom: 17,
  });
};
