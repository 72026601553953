import {
  groupBy,
  uniq,
  isNil,
  findKey,
  xorBy,
  partition,
  difference,
  pull,
  without,
  cloneDeep,
  isEqual,
} from 'lodash';
import update from 'immutability-helper';
import truncate from '@turf/truncate';
import { produce } from 'immer';

import {
  THIRD_PARTY_PARCELS_LAYER,
  INTERACTIVE_LABELS_LAYER,
} from 'utils/layers';

import photoGalleryActionTypes from '../actions/photoGallery/actions';
import settingsActionTypes from '../actions/settings/actions';
import soilsReportsActionTypes from '../actions/soilsReport/actions';
import { generateNewMaprightId } from 'utils/general';
import actionTypes from '../actions/map/actions';
import filterActionTypes from '../actions/filters/actions';
import { TOOL_EDGE_CODE } from 'utils/constants';
import { getValidCoordinate } from 'utils/geoUtilities';
import { isSpecialPolygon } from 'utils/geoJSONElements';
import { getNewLayersForCategory } from 'utils/maps/layers';

// Helpers
const toolIndex = (geoJSON, maprightId) =>
  geoJSON.findIndex((element) => element.maprightId === maprightId);

const checkVisibility = (geoJSON) =>
  geoJSON.map((geoJSONElement) => {
    const { visibility } = geoJSONElement.properties;
    const isVisible = isNil(visibility) || visibility;
    geoJSONElement.properties.visibility = isVisible;

    return {
      ...geoJSONElement,
      properties: {
        ...geoJSONElement.properties,
        isVisible,
        highlightSize: 1,
      },
    };
  });

const orderGeoJSON = (geoJSON) => {
  const labels = orderByToolType(geoJSON, 'labels');
  const georeferencing = orderByToolType(geoJSON, 'georeferencing');
  const icons = orderByToolType(geoJSON, 'icons');
  const polylines = orderByToolType(geoJSON, 'polylines');
  const polygons = orderByToolType(geoJSON, 'polygons');
  const circlePolygons = orderByToolType(geoJSON, 'circle_polygons');
  const toolEdges = orderByToolType(geoJSON, TOOL_EDGE_CODE);

  return labels
    .concat(georeferencing)
    .concat(icons)
    .concat(toolEdges)
    .concat(polylines)
    .concat(polygons)
    .concat(circlePolygons);
};

const orderByToolType = (geoJSON, toolType) => {
  // Filter elements by tool type.
  let elements = geoJSON.filter((el) => el.properties.toolType === toolType);

  // Save the original elements order inside the geoJSON.
  const elementsOrder = uniq(
    elements.map((element) => element.properties.toolId),
  );

  // Group the elements by toolId.
  elements = groupBy(elements, (el) => el.properties.toolId);

  // Get an array of elements grouped by toolId and toolType keeping the orininal
  // order of the elements in the geoJSON.
  let orderedElements = [];
  elementsOrder.forEach((order) => {
    orderedElements = orderedElements.concat(elements[order]);
  });

  return orderedElements;
};

const sanitizeGeoJSON = (geoJSON) => {
  // Remove inconsistent tools.
  let sanitizedGeoJSON = geoJSON.filter(
    (element) =>
      element.properties.toolId && element.properties.toolId !== 'temp',
  );

  // Add mapright id if necessary.
  sanitizedGeoJSON = sanitizedGeoJSON.map((element) => {
    const newMaprightId = generateNewMaprightId();
    const maprightId = element.maprightId || newMaprightId;

    element.maprightId = maprightId;
    element.properties.maprightId = maprightId;
    return truncate(element);
  });

  // Sanitize coordinates.
  sanitizedGeoJSON.forEach((element) => {
    sanitizeElement(element);
  });

  return sanitizedGeoJSON;
};

const sanitizeElement = (element) => {
  const {
    geometry,
    geometry: { coordinates },
    properties: { toolType, content },
  } = element;

  switch (geometry.type) {
    case 'Point':
      element.geometry.coordinates = getValidCoordinate(
        sanitizeCoordinate(coordinates),
      );
      break;
    case 'LineString':
      element.geometry.coordinates = coordinates.map((coordinate) =>
        getValidCoordinate(sanitizeCoordinate(coordinate)),
      );
      break;
    case 'Polygon':
      element.geometry.coordinates = coordinates.map((lineString) =>
        lineString.map((coordinate) =>
          getValidCoordinate(sanitizeCoordinate(coordinate)),
        ),
      );
      break;
    case 'MultiPolygon':
      element.geometry.coordinates = coordinates.map((polygon) =>
        polygon.map((lineString) =>
          lineString.map((coordinate) =>
            getValidCoordinate(sanitizeCoordinate(coordinate)),
          ),
        ),
      );
      break;
  }

  if (toolType === 'labels' && content) {
    element.properties.content = sanitizeLabelContent(content);
  }
};

const sanitizeLabelContent = (content) =>
  content
    .replace(/&nbsp;/g, ' ')
    .replace(/<div>|<\/div>/gi, '')
    .replace(/<p>|<\/p>/gi, '');

const sanitizeCoordinate = (coordinate) => {
  if (coordinate.length > 2) {
    coordinate.pop();
  }

  return coordinate;
};

const toggleLayersFromArray = (
  customLayers,
  stateOverlays,
  categorization,
  turnOn,
) => {
  const turnOff = !turnOn;
  let newCustomLayers = customLayers.map((item) =>
    item === 'parcels_mapbox' ? 'parcels' : item,
  );
  newCustomLayers = [...new Set(newCustomLayers)];

  for (const layerName in stateOverlays) {
    if (
      Object.prototype.hasOwnProperty.call(stateOverlays, layerName) &&
      stateOverlays[layerName].categorization === categorization
    ) {
      if (turnOff && newCustomLayers.includes(layerName)) {
        const index = newCustomLayers.indexOf(layerName);
        newCustomLayers = newCustomLayers
          .slice(0, index)
          .concat(newCustomLayers.slice(index + 1));
      } else if (turnOn && !newCustomLayers.includes(layerName)) {
        newCustomLayers = newCustomLayers.concat(layerName);
      }
    }
  }

  return newCustomLayers;
};

const mapStreetLayerToNormalLayer = (layer, allLayers) => {
  if (allLayers[layer] && allLayers[layer].enabled) {
    return {
      layer,
      roadsVectorLayerEnabled: false,
    };
  }

  const layerKey = findKey(allLayers, (l) => l.street_layer === layer);
  return {
    layer: layerKey,
    roadsVectorLayerEnabled: true,
  };
};

// Action Handlers

// maprightId: Unique global id for each tool, used as index to
//  update and remove them from the map

const ACTION_HANDLERS = {
  [actionTypes.LOAD_MAP_SUCCESS]: (state, action) => {
    const geoJSON = checkVisibility(action.data.geoJSON);

    const { enabledLayers } = action;
    const { roadsVectorLayerEnabled } = mapStreetLayerToNormalLayer(
      action.data.layer,
      enabledLayers,
    );

    const customMessage = action.data.custom_message || '';

    return {
      ...state,
      ...action.data,
      roadsVectorLayerEnabled,
      roadsVectorLayerIds: [],
      geoJSON: orderGeoJSON(sanitizeGeoJSON(geoJSON)),
      zoom: null,
      mode: 'edit',
      custom_message: customMessage,
      hasUnsavedChanges: false,
    };
  },
  [actionTypes.LOAD_MAP_FAILURE]: (state, action) => ({
    ...initialState,
    error: action.error,
  }),
  [actionTypes.CREATE_MAP_SUCCESS]: (state, action) => {
    const geoJSON = checkVisibility(action.data.geoJSON || []);

    const { enabledLayers } = action;
    const { roadsVectorLayerEnabled } = mapStreetLayerToNormalLayer(
      action.data.layer || 'mb_outdoors',
      enabledLayers,
    );

    const customMessage = action.data.custom_message || '';

    return {
      ...state,
      ...action.data,
      roadsVectorLayerEnabled,
      roadsVectorLayerIds: [],
      geoJSON: orderGeoJSON(sanitizeGeoJSON(geoJSON)),
      zoom: action.data.zoom ?? null,
      mode: 'edit',
      custom_message: customMessage,
      hasUnsavedChanges: false,
      county: action.data.county ?? '',
      acres: action.data.acres ?? '',
      description: action.data.description ?? '',
      shared_layers: action.data.shared_layers ?? [],
      photos: action.data.photos ?? [],
      shared_enabled_layers: action.data.shared_enabled_layers ?? [],
      documents: action.data.documents ?? [],
      streets_on: action.data.streets_on ?? false,
      active_filter_id: action.data.active_filter_id ?? 1,
      active_filter_type: action.data.active_filter_type ?? 'DefaultFilter',
    };
  },
  [actionTypes.LOAD_NEW_MAP]: (state, action) => ({
    name: null,
    geoJSON: [],
    lat: action.lat,
    lng: action.lng,
    zoom: null,
    layer: action.defaultLayer,
    roadsVectorLayerEnabled: true,
    legend: {},
    state: action.state,
    county: null,
    acres: null,
    description: null,
    custom_layers: [THIRD_PARTY_PARCELS_LAYER, INTERACTIVE_LABELS_LAYER],
    shared_layers: [],
    photos: [],
    shared_enabled_layers: [],
    toolbox_slug: action.toolboxSlug,
    video: null,
    auto_play_video: false,
    display_creator_logo: true,
    display_creator_info: true,
    roadsVectorLayerIds: [],
    streets_on: action.streetsOn,
    active_filter_id:
      action.toolboxSlug === 'ranching' ? 1
      : action.toolboxSlug === 'oil-gas' ? 2
      : 3,
    active_filter_type: 'DefaultFilter',
    documents: [],
    custom_message: '',
    hasUnsavedChanges: false,
  }),
  [actionTypes.LOAD_SHARE_MAP_SUCCESS]: (state, action) => {
    const {
      data: { geoJSON, layer },
    } = action;
    const visibleGeoJSON = checkVisibility(geoJSON);

    const { enabledLayers } = action;
    const { roadsVectorLayerEnabled } = mapStreetLayerToNormalLayer(
      layer,
      enabledLayers,
    );

    return {
      ...state,
      ...action.data,
      geoJSON: orderGeoJSON(sanitizeGeoJSON(visibleGeoJSON)),
      mode: 'share',
      roadsVectorLayerEnabled,
      roadsVectorLayerIds: [],
    };
  },
  [actionTypes.LOAD_SHARE_MAP_FAILURE]: (state, action) => ({
    ...initialState,
    error: action.error,
  }),
  [actionTypes.SAVE_MAP_REQUEST]: (state) => ({
    ...state,
    isSaving: true,
  }),
  [actionTypes.SET_SAVING_MAP]: (state) => ({
    ...state,
    isSaving: true,
  }),
  [actionTypes.SET_HAS_UNSAVED_CHANGES]: (state) => ({
    ...state,
    hasUnsavedChanges: true,
  }),
  [actionTypes.SAVE_MAP_SUCCESS]: (state, action) => {
    const {
      roadsVectorLayerEnabled,
      roadsVectorLayerIds,
      originalLayer,
      documents,
    } = action;

    // Restore original layer.
    action.data.layer = originalLayer;
    return {
      ...action.data,
      roadsVectorLayerEnabled,
      roadsVectorLayerIds,
      isSaving: false,
      hasUnsavedChanges: false,
      documents,
    };
  },
  [actionTypes.SAVE_REPORT_SUCCESS]: (state, action) => {
    const { report } = action;

    return { ...state, report };
  },
  [actionTypes.CHANGE_BASE_LAYER]: (state, action) => ({
    ...state,
    layer: action.layer,
  }),
  [actionTypes.TOGGLE_LAYER_VISIBILITY]: (state, action) => {
    const { layer } = action;

    const { selectedLayers } = getNewLayersForCategory(state.custom_layers, [
      layer,
    ]);

    return {
      ...state,
      custom_layers: selectedLayers,
    };
  },
  [actionTypes.TOGGLE_LAYERS_VISIBILITY]: (state, action) => {
    const { layers, toggleOn } = action;

    const { selectedLayers } = getNewLayersForCategory(
      state.custom_layers,
      layers,
      toggleOn,
    );

    return {
      ...state,
      custom_layers: selectedLayers,
    };
  },
  [actionTypes.TOGGLE_LAYERS_VISIBILITY_ON_BY_CATEGORIZATION]: (
    state,
    action,
  ) => {
    const { categorization, stateOverlays, sharing } = action;
    const mapLayersKey = sharing ? 'shared_enabled_layers' : 'custom_layers';
    const newCustomLayers = toggleLayersFromArray(
      state[mapLayersKey],
      stateOverlays,
      categorization,
      true,
    );

    if (newCustomLayers.includes(THIRD_PARTY_PARCELS_LAYER)) {
      newCustomLayers.push(INTERACTIVE_LABELS_LAYER);
    }

    const { selectedLayers } = getNewLayersForCategory(
      state.custom_layers,
      newCustomLayers,
      true,
    );

    return {
      ...state,
      [mapLayersKey]: selectedLayers,
    };
  },
  [actionTypes.TOGGLE_LAYERS_VISIBILITY_OFF_BY_CATEGORIZATION]: (
    state,
    action,
  ) => {
    const { categorization, stateOverlays, sharing } = action;
    const mapLayersKey = sharing ? 'shared_enabled_layers' : 'custom_layers';
    const newCustomLayers = toggleLayersFromArray(
      state[mapLayersKey],
      stateOverlays,
      categorization,
      false,
    );

    if (!newCustomLayers.includes(THIRD_PARTY_PARCELS_LAYER)) {
      pull(newCustomLayers, INTERACTIVE_LABELS_LAYER);
    }

    return {
      ...state,
      [mapLayersKey]: newCustomLayers,
    };
  },
  [actionTypes.TURN_LAYER_VISIBILITY_ON]: (state, action) => {
    let customLayers = state.custom_layers;
    const { layer } = action;

    if (!customLayers.includes(layer)) {
      customLayers = customLayers.concat(layer);
    }

    return {
      ...state,
      custom_layers: customLayers,
    };
  },
  [actionTypes.TURN_LAYER_VISIBILITY_OFF]: (state, action) => {
    const customLayers = state.custom_layers;
    const { layer } = action;

    return {
      ...state,
      custom_layers: without(customLayers, layer),
    };
  },
  [actionTypes.TOGGLE_SHARED_LAYER_VISIBILITY]: (state, action) => {
    let sharedEnabledLayers = state.shared_enabled_layers;
    const { layer } = action;

    if (sharedEnabledLayers.includes(layer)) {
      const index = sharedEnabledLayers.indexOf(layer);
      sharedEnabledLayers = sharedEnabledLayers
        .slice(0, index)
        .concat(sharedEnabledLayers.slice(index + 1));
    } else {
      sharedEnabledLayers = sharedEnabledLayers.concat(layer);
    }

    return {
      ...state,
      shared_enabled_layers: sharedEnabledLayers,
    };
  },
  [actionTypes.TOGGLE_ALL_LAYERS_VISIBILITY]: (state, action) => {
    const { active, layers } = action;

    return {
      ...state,
      custom_layers: active ? [] : layers,
    };
  },
  [actionTypes.REMOVE_TOOL_FROM_MAP]: (state, action) => {
    const { geoJSON } = state;
    const index = toolIndex(state.geoJSON, action.maprightId);

    return {
      ...state,
      geoJSON: geoJSON.slice(0, index).concat(geoJSON.slice(index + 1)),
    };
  },
  [actionTypes.CHANGE_GEO_JSON]: (state, action) => ({
    ...state,
    geoJSON: action.geoJSON,
  }),
  [filterActionTypes.REMOVE_TOOL]: (state, action) => {
    const { geoJSON } = state;
    const { toolId, toolType } = action;

    return {
      ...state,
      geoJSON: geoJSON.filter(
        ({ properties: { toolId: id, toolType: type } }) =>
          !(id === toolId && type === toolType),
      ),
    };
  },
  [actionTypes.CHANGE_MAP_PROPERTY]: (state, action) => {
    const { property, value, keepLayers } = action;
    let customLayers;

    if (property === 'state' && !keepLayers) {
      customLayers = [];
    } else {
      customLayers = state.custom_layers;
    }

    return { ...state, [property]: value, custom_layers: customLayers };
  },
  [actionTypes.CHANGE_TOOL_PROPERTY]: (state, action) => {
    const { maprightId, property, value, transform } = action;
    const index = toolIndex(state.geoJSON, maprightId);

    if (index < 0) return state;

    const currentValue = state.geoJSON[index]?.properties[property];

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          properties: {
            $merge: { [property]: transform ? transform(currentValue) : value },
          },
        },
      }),
    };
  },
  [actionTypes.UPDATE_TOOL_PROPERTIES]: (state, action) => {
    const { maprightId, properties } = action;
    const index = toolIndex(state.geoJSON, maprightId);

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          properties: {
            $set: properties,
          },
        },
      }),
    };
  },
  [actionTypes.ADD_CONTROL_POINT_TO_GEOREFERENCE]: (state, action) => {
    const { controlPoint, maprightId } = action;
    const index = state.geoJSON.findIndex((el) => el.maprightId === maprightId);
    const controlPoints = state.geoJSON[index].properties.control_points;

    if (!controlPoints) {
      return state;
    }

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          properties: {
            $merge: { control_points: controlPoints.concat(controlPoint) },
          },
        },
      }),
    };
  },
  [actionTypes.REMOVE_CONTROL_POINT_FROM_GEOREFERENCE]: (state, action) => {
    const { index: controlPointIndex, maprightId } = action;
    const index = state.geoJSON.findIndex((el) => el.maprightId === maprightId);
    const controlPoints = state.geoJSON[index].properties.control_points;

    if (!controlPoints) {
      return state;
    }

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          properties: {
            $merge: {
              control_points: controlPoints
                .slice(0, controlPointIndex)
                .concat(controlPoints.slice(controlPointIndex + 1)),
            },
          },
        },
      }),
    };
  },
  [actionTypes.CHANGE_TOOL_POSITION]: (state, action) => {
    const { maprightId, coordinates } = action;
    const index = toolIndex(state.geoJSON, maprightId);

    const arrayCoordinates =
      coordinates.toArray ? coordinates.toArray() : coordinates;

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          geometry: {
            $merge: { coordinates: arrayCoordinates },
          },
        },
      }),
    };
  },
  [actionTypes.CHANGE_TOOLS_POSITIONS]: (state, action) => {
    const { coordinatesIdsMap } = action;

    return produce(state, (draftState) => {
      const { geoJSON } = draftState;

      for (const maprightId in coordinatesIdsMap) {
        const index = toolIndex(geoJSON, parseFloat(maprightId));
        const coordinates = coordinatesIdsMap[maprightId];
        const arrayCoordinates =
          coordinates.toArray ? coordinates.toArray() : coordinates;

        if (!isEqual(geoJSON[index].geometry.coordinates, arrayCoordinates)) {
          geoJSON[index].geometry.coordinates = arrayCoordinates;
        }
      }
    });
  },
  [actionTypes.CHANGE_TOOL_INDEX]: (state, action) => {
    const { targetTool, draggedTool, forward } = action;

    const lowerQuota = state.geoJSON.findIndex(
      (element) =>
        element.properties.toolId === draggedTool.properties.toolId &&
        element.properties.toolType === draggedTool.properties.toolType,
    );

    const higherQuota =
      state.geoJSON.filter(
        (element) =>
          element.properties.toolId === draggedTool.properties.toolId &&
          element.properties.toolType === draggedTool.properties.toolType,
      ).length + lowerQuota;

    const subArray = state.geoJSON.slice(lowerQuota, higherQuota);
    const geoJSON = state.geoJSON
      .slice(0, lowerQuota)
      .concat(state.geoJSON.slice(higherQuota));

    let newIndex = 0;

    if (forward) {
      newIndex =
        geoJSON.findIndex(
          (element) =>
            element.properties.toolId === targetTool.properties.toolId &&
            element.properties.toolType === targetTool.properties.toolType,
        ) +
        geoJSON.filter(
          (element) =>
            element.properties.toolId === targetTool.properties.toolId &&
            element.properties.toolType === targetTool.properties.toolType,
        ).length;
    } else {
      newIndex = geoJSON.findIndex(
        (element) =>
          element.properties.toolId === targetTool.properties.toolId &&
          element.properties.toolType === targetTool.properties.toolType,
      );
    }

    return {
      ...state,
      geoJSON: geoJSON
        .slice(0, newIndex)
        .concat(subArray)
        .concat(geoJSON.slice(newIndex)),
    };
  },
  [actionTypes.TOGGLE_ELEMENT_VISIBILITY]: (state, action) => {
    const { toolId, toolType, visibility } = action;

    return produce(state, (draftState) => {
      draftState.geoJSON.forEach((element) => {
        if (
          element.properties.toolId === toolId &&
          element.properties.toolType === toolType
        ) {
          element.properties.visibility = visibility;
        }
      });
    });
  },
  [actionTypes.TOGGLE_ELEMENT_PROPERTY]: (state, action) => {
    const { maprightId, attribute } = action;
    const index = toolIndex(state.geoJSON, maprightId);
    const currentValue = state.geoJSON[index].properties[attribute];

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          properties: {
            $merge: { [attribute]: !currentValue },
          },
        },
      }),
    };
  },
  [actionTypes.CREATE_GEOREFERENCE_FAILURE]: (state, action) => {
    return { ...state, error: action.error };
  },
  [actionTypes.CREATE_GEOREFERENCE_SUCCESS]: (state, action) => {
    const maprightId = generateNewMaprightId();

    const georeference = {
      type: 'Feature',
      maprightId,
      properties: {
        toolType: 'georeferencing',
        toolId: 1,
        photo_id: action.data.id,
        name: '',
        opacity: 1,
        locked: false,
        visibility: true,
        rotation: 0,
        control_points: [],
        maprightId,
      },
      geometry: {
        type: 'MultiPoint',
        coordinates: [],
      },
    };

    return {
      ...state,
      geoJSON: state.geoJSON.concat(georeference),
      photos: state.photos.concat(action.data),
    };
  },
  [actionTypes.UPDATE_GEOREFERENCE_CALCULATED_COORDINATES]: (state, action) => {
    const { maprightId, coords } = action;
    const index = toolIndex(state.geoJSON, maprightId);

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          properties: {
            $merge: {
              calculated_coordinates: coords,
            },
          },
        },
      }),
    };
  },
  [actionTypes.CREATE_TOOL]: (state, action) => {
    const { geoJSON, properties, customData } = action;
    const maprightId = customData?.data?.maprightId || generateNewMaprightId();

    sanitizeElement(geoJSON);
    geoJSON.maprightId = maprightId;
    geoJSON.properties.maprightId = maprightId;

    if (geoJSON.properties.toolType === 'labels') {
      geoJSON.properties.font_size = 12;
      geoJSON.properties.font_type = 'openSans';
      geoJSON.properties.font_style = 'bold';
      geoJSON.properties.font_color = 'white';
      geoJSON.properties.uppercase = true;
      geoJSON.properties.rotation = 0;
      geoJSON.properties.visibility = true;
    }

    if (properties) {
      geoJSON.properties = {
        ...geoJSON.properties,
        ...properties,
      };
    }

    if (customData?.details) {
      if (!geoJSON.properties?.details) geoJSON.properties.details = [];

      geoJSON.properties.details = [
        ...(isSpecialPolygon({ properties: { details: customData?.details } }) ?
          geoJSON.properties?.details
        : []),
        ...customData.details,
      ];
    }

    return { ...state, geoJSON: orderGeoJSON(state.geoJSON.concat(geoJSON)) };
  },
  [actionTypes.CHANGE_ELEMENT_GEOMETRY]: (state, action) => {
    let { element, index } = action;
    const { geoJSON } = state;

    if (index === -1) {
      index = geoJSON.findIndex((el) => el.maprightId === element.maprightId);
    }

    const newGeoJSON = [...state.geoJSON];
    newGeoJSON[index] = { ...newGeoJSON[index], geometry: element.geometry };

    return { ...state, geoJSON: newGeoJSON };
  },
  [actionTypes.CHANGE_TOOL_FROM_DEED_PLOTTER]: (state, action) => {
    let { element, index } = action;
    const { geoJSON } = state;

    if (index === -1) {
      index = geoJSON.findIndex((el) => el.maprightId === element.maprightId);
    }

    const newGeoJSON = [...state.geoJSON];
    newGeoJSON[index] = {
      ...newGeoJSON[index],
      geometry: element.geometry,
      properties: element.properties,
    };

    return { ...state, geoJSON: newGeoJSON };
  },
  [actionTypes.CHANGE_GEOJSON_ELEMENT]: (state, action) => {
    const { element } = action;
    const { geoJSON } = state;

    const index = geoJSON.findIndex(
      (el) => el.maprightId === element.maprightId,
    );

    const newGeoJSON = [...geoJSON];

    newGeoJSON[index] = element;

    return { ...state, geoJSON: newGeoJSON };
  },
  [actionTypes.CHANGE_MAP_ACTIVE_FILTER]: (state, action) => ({
    ...state,
    active_filter_id: action.filterId,
    active_filter_type: action.filterType,
  }),
  [actionTypes.SET_TOOL_CUSTOM_DATA_STATUS]: (state, action) => {
    const {
      data: { maprightId, status, thumbnail, xml, auto_display },
    } = action;
    const { geoJSON } = state;

    const index = geoJSON.findIndex(
      (el) => el.maprightId === Number.parseFloat(maprightId),
    );

    if (index === -1) {
      return state;
    }

    const newGeoJSON = cloneDeep(geoJSON);
    const newElement = newGeoJSON[index];

    newElement.properties.custom_data = {
      ...newElement.properties.custom_data,
      ...(status && { status }),
      ...(thumbnail && { thumbnail }),
      ...(xml && { xml }),
      ...(auto_display !== undefined ? { auto_display } : {}),
    };

    return { ...state, geoJSON: newGeoJSON };
  },
  [actionTypes.SET_CUSTOM_TOOL_DATA_SUCCESS]: (state, action) => {
    const { data: element } = action;
    const { geoJSON } = state;

    const index = geoJSON.findIndex(
      (el) => el.maprightId === Number.parseFloat(element.mapright_id),
    );

    if (index === -1) {
      return state;
    }

    const newGeoJSON = geoJSON.map((geo, i) => {
      if (i === index) {
        return {
          ...geo,
          properties: {
            ...geo.properties,
            custom_data: {
              ...geo.properties.custom_data,
              ...element.custom_data,
            },
          },
        };
      }
      return geo;
    });

    return {
      ...state,
      geoJSON: newGeoJSON,
    };
  },
  [actionTypes.ADD_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    documents: [...state.documents, action.document],
    geoJSON: update(state.geoJSON, {
      $apply: (geoJSON) =>
        geoJSON.map((element) => {
          if (
            action.geoJSON &&
            element.maprightId === action.geoJSON.maprightId
          ) {
            const docObject = [{ id: action.document.id }];
            element.documents =
              element.documents ?
                element.documents.concat(docObject)
              : docObject;
          }
          return element;
        }),
    }),
  }),
  [actionTypes.EDIT_DOCUMENT_SUCCESS]: (state, action) => {
    const documentIndex = state.documents.findIndex(
      (file) => file.id === action.document.id,
    );
    return {
      ...state,
      documents: state.documents
        .slice(0, documentIndex)
        .concat(action.document)
        .concat(state.documents.slice(documentIndex + 1)),
    };
  },
  [actionTypes.REMOVE_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    documents: state.documents.filter((file) => file.id !== action.document),
    geoJSON: update(state.geoJSON, {
      $apply: (geoJSON) =>
        geoJSON.map((element) => {
          if (
            action.geoJSON &&
            element.maprightId === action.geoJSON.maprightId
          ) {
            const index = element.documents.findIndex(
              (file) =>
                Number.isInteger(file.id) && file.id === action.document,
            );
            element.documents = element.documents
              .slice(0, index)
              .concat(element.documents.slice(index + 1));
          }
          return element;
        }),
    }),
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
  [soilsReportsActionTypes.SAVE_SOILS_REPORT_SUCCESS]: (state, action) => ({
    ...state,
    report: action.reportData,
  }),
  [soilsReportsActionTypes.DELETE_SOILS_REPORT_SUCCESS]: (state, action) => ({
    ...state,
    report: null,
  }),
  [photoGalleryActionTypes.UPLOAD_PHOTO_SUCCESS]: (state, action) => {
    const { photo, marker, mediaIndex } = action;
    return {
      ...state,
      photos: state.photos ? state.photos.concat(photo) : [photo],
      geoJSON: update(state.geoJSON, {
        $apply: (geoJSON) =>
          geoJSON.map((element) => {
            if (element.maprightId === marker.maprightId) {
              const photoObject = [{ id: photo.id, mediaIndex }];
              element.photos =
                element.photos ?
                  element.photos.concat(photoObject)
                : photoObject;
            }
            return element;
          }),
      }),
    };
  },
  [photoGalleryActionTypes.DELETE_PHOTO]: (state, action) => {
    const { photoId, marker } = action;
    const index = state.photos.map((photo) => photo.id).indexOf(photoId);

    return {
      ...state,
      photos: state.photos
        .slice(0, index)
        .concat(state.photos.slice(index + 1)),
      geoJSON: update(state.geoJSON, {
        $apply: (geoJSON) =>
          geoJSON.map((element) => {
            if (element.maprightId === marker.maprightId) {
              const index = element.photos.findIndex(
                (photo) =>
                  (Number.isInteger(photo) && photo === photoId) ||
                  photo.id === photoId,
              );
              element.photos = element.photos
                .slice(0, index)
                .concat(element.photos.slice(index + 1));
            }
            return element;
          }),
      }),
    };
  },
  [photoGalleryActionTypes.UPLOAD_VIDEO]: (state, action) => {
    const { videoLink, marker, mediaIndex } = action;
    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        $apply: (geoJSON) =>
          geoJSON.map((element) => {
            if (element.maprightId === marker.maprightId) {
              const videoObject = [{ videoLink, mediaIndex }];
              element.videos =
                element.videos ?
                  element.videos.concat(videoObject)
                : videoObject;
            }
            return element;
          }),
      }),
    };
  },
  [photoGalleryActionTypes.DELETE_VIDEO]: (state, action) => {
    const { videoId, marker } = action;
    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        $apply: (geoJSON) =>
          geoJSON.map((element) => {
            if (element.maprightId === marker.maprightId) {
              const index = element.videos.indexOf(videoId);
              element.videos = element.videos
                .slice(0, index)
                .concat(element.videos.slice(index + 1));
            }
            return element;
          }),
      }),
    };
  },
  [actionTypes.CHANGE_MAP_ZOOM]: (state, action) => {
    const { newZoom } = action;
    return {
      ...state,
      zoom: newZoom,
    };
  },
  [actionTypes.CHANGE_MAP_CENTER]: (state, action) => {
    const { lat, lng } = action;
    return {
      ...state,
      lat,
      lng,
    };
  },
  [actionTypes.CHANGE_TOOL_INSTANCES_PROPERTIES]: (state, action) => {
    const { toolInstances, properties, options } = action;
    if (options) {
      return {
        ...state,
        geoJSON: state.geoJSON.map((element) => {
          const {
            properties: { toolId, toolType },
          } = element;
          if (options.toolId === toolId && options.toolType === toolType) {
            return {
              ...element,
              properties: {
                ...element.properties,
                ...properties,
                ...(!(properties?.highlight || element.properties?.highlight) ?
                  { highlightStyle: null }
                : {}),
              },
            };
          }
          return element;
        }),
      };
    }

    const { oldToolId } = properties;
    if (oldToolId) delete properties.oldToolId;

    return {
      ...state,
      geoJSON: state.geoJSON.map((element) => {
        if (
          toolInstances.includes(element.maprightId) ||
          (toolInstances.length === 0 &&
            oldToolId &&
            element.properties.toolId === oldToolId)
        ) {
          return {
            ...element,
            properties: {
              ...element.properties,
              ...properties,
              ...(!(properties?.highlight || element.properties?.highlight) ?
                { highlightStyle: null }
              : {}),
            },
          };
        }

        return element;
      }),
    };
  },
  [filterActionTypes.CHANGE_FEATURE_ID]: (state, action) => {
    const { toolId: oldId, toolType: type, newId } = action;

    return {
      ...state,
      geoJSON: state.geoJSON.map((element) => {
        const {
          properties: { toolId, toolType },
        } = element;

        if (toolId === oldId && toolType === type) {
          return {
            ...element,
            properties: {
              ...element.properties,
              toolId: newId,
            },
          };
        }

        return element;
      }),
    };
  },
  [filterActionTypes.CHANGE_FEATURE_INSTANCE_DETAIL]: (state, action) => {
    let {
      featureInstance: {
        properties: { details },
      },
    } = action;
    details = (details && [...details]) || [];

    const index = details.findIndex((detail) => detail.id === action.detailId);
    const modifyIndex = index < 0 ? details.length : index;

    details[modifyIndex] = {
      id: action.detailId,
      value: action.newDetailValue,
    };

    return {
      ...state,
      geoJSON: state.geoJSON.map((element) => {
        if (element.maprightId === action.featureInstance.maprightId) {
          return {
            ...element,
            properties: {
              ...element.properties,
              details,
            },
          };
        }

        return element;
      }),
    };
  },
  [actionTypes.ADD_CUSTOM_MESSAGE]: (state, action) => ({
    ...state,
    custom_message: action.message,
  }),
  [actionTypes.TURN_STREETS_ON]: (state, action) => ({
    ...state,
    streets_on: true,
  }),
  [actionTypes.TURN_STREETS_OFF]: (state, action) => ({
    ...state,
    streets_on: false,
  }),
  [actionTypes.UPDATE_WAYPOINT]: (state, action) => {
    const { photos, geoJSON } = state;
    const { newPhotos, notes, feature } = action;

    const index = geoJSON.findIndex(
      (element) => element.maprightId === feature.maprightId,
    );
    const element = geoJSON[index];

    const diff = xorBy(element.photos, newPhotos, (photo) => photo.id);

    const [additions, deletions] = partition(diff, (photo) =>
      newPhotos.includes(photo),
    );

    return {
      ...state,
      geoJSON: update(state.geoJSON, {
        [index]: {
          photos: { $set: newPhotos },
          properties: {
            notes: { $set: notes },
          },
        },
      }),
      photos: difference(photos, deletions).concat(additions),
    };
  },
};

const initialState = {};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
