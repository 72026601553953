import { NonConfigurableIconProps } from '../types';

const Aerial = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51809 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92894 4.92894C3.53041 6.32746 2.578 8.10929 2.19215 10.0491C1.8063 11.9889 2.00433 13.9996 2.76121 15.8268C3.51809 17.6541 4.79981 19.2159 6.4443 20.3147C8.08879 21.4135 10.0222 22 12 22H12.1C14.725 21.9978 17.2418 20.9541 19.0979 19.0979C20.9541 17.2418 21.9978 14.725 22 12.1V12ZM20.3333 12C20.3318 12.7407 20.2309 13.4778 20.0333 14.1917C20.0188 14.2492 19.9918 14.3028 19.9544 14.3489C19.917 14.395 19.87 14.4323 19.8167 14.4583C19.7205 14.5129 19.607 14.5278 19.5 14.5C18.9839 14.2778 18.5438 13.91 18.2333 13.4417L16.3833 10.6667C16.1928 10.3818 15.9349 10.1484 15.6326 9.98701C15.3302 9.82566 14.9927 9.74139 14.65 9.74167H14.2917C13.6839 9.74167 13.101 9.50023 12.6712 9.07046C12.2414 8.64069 12 8.05779 12 7.45C12 6.84222 12.2414 6.25932 12.6712 5.82955C13.101 5.39978 13.6839 5.15834 14.2917 5.15834H16.6C16.6866 5.15653 16.7714 5.18285 16.8417 5.23334C17.9194 6.00269 18.7983 7.0178 19.4056 8.19455C20.0128 9.3713 20.3308 10.6758 20.3333 12ZM3.81667 10.4667C3.83261 10.3703 3.88258 10.2828 3.95749 10.2201C4.03241 10.1574 4.12733 10.1237 4.225 10.125H7.83334C8.21461 10.12 8.59304 10.1912 8.94647 10.3343C9.29991 10.4774 9.62125 10.6895 9.89167 10.9583L10.8333 11.9167C11.3592 12.4428 11.6642 13.15 11.6858 13.8935C11.7075 14.6371 11.4443 15.3608 10.95 15.9167L9.81667 17.1917C9.47763 17.5726 9.29074 18.065 9.29167 18.575V19.2667C9.29234 19.3365 9.27506 19.4054 9.2415 19.4667C9.20795 19.528 9.15923 19.5796 9.10001 19.6167C9.03931 19.6474 8.97222 19.6634 8.90417 19.6634C8.83613 19.6634 8.76904 19.6474 8.70834 19.6167C7.2177 18.9758 5.94659 17.9136 5.05099 16.5606C4.1554 15.2076 3.67427 13.6226 3.66667 12C3.6654 11.4852 3.71565 10.9715 3.81667 10.4667Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Aerial;
