import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const User = ({ fill = colors.black }: IconProps) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33683 3.33302C8.0431 2.62676 9.001 2.22998 9.99981 2.22998C10.9986 2.22998 11.9565 2.62676 12.6628 3.33302C13.3691 4.03929 13.7658 4.99719 13.7658 5.99601C13.7658 6.99482 13.3691 7.95272 12.6628 8.65899C11.9565 9.36526 10.9986 9.76203 9.99981 9.76203C9.001 9.76203 8.0431 9.36526 7.33683 8.65899C6.63057 7.95272 6.23379 6.99482 6.23379 5.99601C6.23379 4.99719 6.63057 4.03929 7.33683 3.33302ZM7.16781 17.903H9.99984H12.9165H14.5666H15.8332C16.1783 17.903 16.4582 17.6232 16.4582 17.278C16.4582 15.5651 15.7777 13.9224 14.5666 12.7113C13.3554 11.5001 11.7127 10.8197 9.99984 10.8197C8.28698 10.8197 6.64428 11.5001 5.43311 12.7113C4.22193 13.9224 3.5415 15.5651 3.5415 17.278C3.5415 17.6232 3.82133 17.903 4.1665 17.903H5.43311C5.61332 17.903 5.76314 17.8978 5.91546 17.8926C6.22784 17.8818 6.55073 17.8707 7.16781 17.903Z"
      fill={fill}
    />
  </svg>
);

export default User;
