import { StyleableOnlyIconProps } from '../types';

const Subtract = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.76923 11.25H21.2308C21.6556 11.25 22 11.5858 22 12C22 12.4142 21.6556 12.75 21.2308 12.75H2.76923C2.3444 12.75 2 12.4142 2 12C2 11.5858 2.3444 11.25 2.76923 11.25Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.42857 10.5H20.5714C21.3604 10.5 22 11.1716 22 12C22 12.8284 21.3604 13.5 20.5714 13.5H3.42857C2.63959 13.5 2 12.8284 2 12C2 11.1716 2.63959 10.5 3.42857 10.5Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Subtract;
