import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Parcel = ({ fill = colors.white }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
  >
    <path
      d="M4.34501 17.3618H9.03283C9.26344 18.014 9.59196 18.6275 10.0071 19.1809H4.34501C3.45294 19.1809 2.69249 18.5341 2.54942 17.6535L0.0244526 2.11293C-0.193869 0.769213 1.08696 -0.329408 2.38177 0.090957L19.5911 5.67801C20.5678 5.99509 21.0886 7.05717 20.7413 8.0235L20.0242 10.0189C19.5535 9.59537 19.0297 9.24241 18.4692 8.96687L19.0294 7.40825L1.82004 1.82119L4.34501 17.3618Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.3766 15.0878C21.3766 18.353 18.7296 21 15.4644 21C12.1992 21 9.55224 18.353 9.55224 15.0878C9.55224 11.8226 12.1992 9.17562 15.4644 9.17562C18.7296 9.17562 21.3766 11.8226 21.3766 15.0878ZM14.687 18.2665V14.3506H16.4758V18.2665H14.687ZM15.5856 13.5192C15.3197 13.5192 15.0915 13.431 14.9012 13.2546C14.7136 13.0755 14.6198 12.8613 14.6198 12.6122C14.6198 12.3659 14.7136 12.1545 14.9012 11.9782C15.0915 11.799 15.3197 11.7094 15.5856 11.7094C15.8515 11.7094 16.0783 11.799 16.2658 11.9782C16.4562 12.1545 16.5514 12.3659 16.5514 12.6122C16.5514 12.8613 16.4562 13.0755 16.2658 13.2546C16.0783 13.431 15.8515 13.5192 15.5856 13.5192Z"
      fill={fill}
    />
  </svg>
);

export default Parcel;
