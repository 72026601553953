import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SentEvent } from 'services/EventRouter';

interface EventLoggerState {
  events: SentEvent[];
}

const initialState: EventLoggerState = {
  events: [],
};

const eventLoggerSlice = createSlice({
  name: 'eventLogger',
  initialState,
  reducers: {
    logEvent: (state, action: PayloadAction<SentEvent>) => {
      state.events.push(action.payload);
    },
  },
});

export const { logEvent } = eventLoggerSlice.actions;
export default eventLoggerSlice.reducer;
