import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Boundaries = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 3.2H16.7178V12.0882C16.7178 12.6958 16.2254 13.1882 15.6178 13.1882H12.0114V20.8H18C19.5464 20.8 20.8 19.5464 20.8 18V6C20.8 4.4536 19.5464 3.2 18 3.2ZM10.8114 20.8V13.1882H3.2V18C3.2 19.5464 4.4536 20.8 6 20.8H10.8114ZM6 3.2H15.5178V11.9882H3.2V6C3.2 4.4536 4.4536 3.2 6 3.2ZM6 2C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H6Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C3.79086 2 2 3.79086 2 6V11.9882H15.5179V2H6ZM16.7179 2V12.0882C16.7179 12.6958 16.2254 13.1882 15.6179 13.1882H12.0114V22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2H16.7179ZM10.8114 22V13.1882H2V18C2 20.2091 3.79086 22 6 22H10.8114Z"
          fill={color}
        />
      </svg>;
};

export default Boundaries;
