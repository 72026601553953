import { NonConfigurableIconProps } from '../types';
import * as colorPalette from '../../styles/colors.module.scss';

const Document = ({
  width = '24',
  height = '24',
  color = colorPalette.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5833 22C17.6546 22 18.7019 21.6823 19.5927 21.0871C20.4834 20.4919 21.1777 19.646 21.5877 18.6562C21.9977 17.6664 22.1049 16.5773 21.8959 15.5266C21.6869 14.4759 21.171 13.5107 20.4135 12.7532C19.656 11.9956 18.6908 11.4798 17.6401 11.2707C16.5893 11.0617 15.5002 11.169 14.5105 11.579C13.5207 11.989 12.6747 12.6832 12.0795 13.574C11.4843 14.4648 11.1667 15.512 11.1667 16.5833C11.1667 18.0199 11.7374 19.3977 12.7532 20.4135C13.769 21.4293 15.1467 22 16.5833 22ZM13.875 16.1319H16.0191C16.049 16.1319 16.0777 16.1201 16.0989 16.0989C16.1201 16.0777 16.1319 16.049 16.1319 16.0191V13.875C16.1319 13.7553 16.1795 13.6405 16.2642 13.5558C16.3488 13.4712 16.4636 13.4236 16.5833 13.4236C16.703 13.4236 16.8179 13.4712 16.9025 13.5558C16.9872 13.6405 17.0347 13.7553 17.0347 13.875V16.0191C17.0347 16.049 17.0466 16.0777 17.0678 16.0989C17.0889 16.1201 17.1176 16.1319 17.1476 16.1319H19.2917C19.4114 16.1319 19.5262 16.1795 19.6108 16.2642C19.6955 16.3488 19.7431 16.4636 19.7431 16.5833C19.7431 16.7031 19.6955 16.8179 19.6108 16.9025C19.5262 16.9872 19.4114 17.0347 19.2917 17.0347H17.1476C17.1176 17.0347 17.0889 17.0466 17.0678 17.0678C17.0466 17.0889 17.0347 17.1176 17.0347 17.1476V19.2917C17.0347 19.4114 16.9872 19.5262 16.9025 19.6108C16.8179 19.6955 16.703 19.7431 16.5833 19.7431C16.4636 19.7431 16.3488 19.6955 16.2642 19.6108C16.1795 19.5262 16.1319 19.4114 16.1319 19.2917V17.1476C16.1319 17.1176 16.1201 17.0889 16.0989 17.0678C16.0777 17.0466 16.049 17.0347 16.0191 17.0347H13.875C13.7553 17.0347 13.6405 16.9872 13.5558 16.9025C13.4712 16.8179 13.4236 16.7031 13.4236 16.5833C13.4236 16.4636 13.4712 16.3488 13.5558 16.2642C13.6405 16.1795 13.7553 16.1319 13.875 16.1319Z"
        fill={color}
      />
      <path
        d="M20.3333 11.5833V3.59724C20.3333 3.17376 20.1722 2.76744 19.8857 2.46796C19.5993 2.16848 19.2114 2.00002 18.8056 2.00002H8.11111C8.06337 1.99963 8.01563 2.00392 7.96937 2.01289C7.91716 2.02264 7.86643 2.03824 7.81719 2.0593C7.72469 2.09907 7.63965 2.15756 7.56803 2.23165L2.22081 7.82192C2.15069 7.8964 2.09399 7.98492 2.05669 8.08241C2.0194 8.1795 2 8.28401 2 8.38891V19.5694C2 19.9929 2.16113 20.3993 2.44759 20.6987C2.73405 20.9982 3.12196 21.1667 3.52778 21.1667H12.3341C12.2765 21.1132 12.2197 21.0586 12.1639 21.0028C11.7361 20.5749 11.3767 20.0915 11.0928 19.5694H3.90972C3.80827 19.5694 3.71129 19.5273 3.63968 19.4525C3.56806 19.3776 3.52778 19.2762 3.52778 19.1701V9.18751C3.52778 9.15086 3.53225 9.1146 3.54121 9.07989C3.55911 9.01438 3.59342 8.95394 3.63968 8.90519C3.71129 8.83032 3.80827 8.78821 3.90972 8.78821H6.96528C7.14431 8.78821 7.31887 8.75545 7.48448 8.69345C7.69336 8.61468 7.88582 8.48795 8.04546 8.32027C8.15736 8.20368 8.24986 8.07071 8.32148 7.92721C8.43338 7.70182 8.49306 7.44952 8.49306 7.19099V3.99655C8.49306 3.96028 8.49753 3.9248 8.50648 3.89087C8.52439 3.82458 8.55721 3.76375 8.60495 3.71423C8.67657 3.63936 8.77355 3.59724 8.875 3.59724H18.4236C18.5251 3.59724 18.622 3.63936 18.6937 3.71423C18.7653 3.7891 18.8056 3.89048 18.8056 3.99655V10.7417C19.3514 10.9494 19.8657 11.2326 20.3333 11.5833Z"
        fill={color}
      />
    </svg>
  );
};

export default Document;
