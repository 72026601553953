import * as colors from 'components/ui/styles/colors.module.scss';

const Merge = ({ fill = colors.black }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M17.3332 1.66677C17.3341 1.53519 17.3089 1.40474 17.2589 1.283C17.209 1.16126 17.1354 1.05065 17.0423 0.957607C16.9493 0.864564 16.8387 0.79094 16.7169 0.741012C16.5952 0.691084 16.4648 0.665849 16.3332 0.666773H13.1248C12.3576 0.667126 11.6002 0.840224 10.9089 1.17323C10.2177 1.50623 9.61029 1.99059 9.13178 2.59038C9.11675 2.61099 9.09681 2.6275 9.07376 2.63841C9.05071 2.64933 9.0253 2.65431 8.99984 2.65288C8.97463 2.65267 8.94976 2.64697 8.92698 2.63618C8.90419 2.62538 8.88403 2.60976 8.86789 2.59038C8.38939 1.99059 7.78198 1.50623 7.09073 1.17323C6.39947 0.840224 5.64212 0.667126 4.87484 0.666773H1.6665C1.40129 0.666773 1.14693 0.772129 0.959397 0.959666C0.771861 1.1472 0.666504 1.40156 0.666504 1.66677C0.666504 1.93199 0.771861 2.18634 0.959397 2.37388C1.14693 2.56142 1.40129 2.66677 1.6665 2.66677H4.87484C5.70308 2.66861 6.49687 2.99844 7.08252 3.58409C7.66817 4.16974 7.998 4.96354 7.99984 5.79177V13.1668C7.99984 13.2128 7.98155 13.257 7.94899 13.2895C7.91643 13.3221 7.87227 13.3404 7.82623 13.3404H6.99984C6.80288 13.3426 6.61097 13.403 6.44819 13.5139C6.28541 13.6248 6.159 13.7813 6.08482 13.9637C6.01065 14.1462 5.99201 14.3465 6.03125 14.5395C6.07049 14.7326 6.16586 14.9097 6.30539 15.0487L8.30539 17.0418C8.49263 17.2201 8.74128 17.3196 8.99984 17.3196C9.2584 17.3196 9.50705 17.2201 9.69428 17.0418L11.7082 15.0418C11.8477 14.9028 11.9431 14.7256 11.9823 14.5326C12.0216 14.3396 12.0029 14.1393 11.9287 13.9568C11.8546 13.7743 11.7282 13.6178 11.5654 13.5069C11.4026 13.396 11.2107 13.3357 11.0137 13.3334H10.1734C10.1274 13.3334 10.0832 13.3151 10.0507 13.2826C10.0181 13.25 9.99984 13.2059 9.99984 13.1598V5.79177C10.0017 4.96354 10.3315 4.16974 10.9172 3.58409C11.5028 2.99844 12.2966 2.66861 13.1248 2.66677H16.3332C16.4648 2.6677 16.5952 2.64246 16.7169 2.59253C16.8387 2.54261 16.9493 2.46898 17.0423 2.37594C17.1354 2.28289 17.209 2.17229 17.2589 2.05055C17.3089 1.9288 17.3341 1.79835 17.3332 1.66677Z"
      fill={fill}
    />
  </svg>
);

export default Merge;
