import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Account = ({
  fill = colors.links300,
  type = 'individual',
}: IconPropsWithVariant) => {
  const IndividualAccount = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle cx="18" cy="18" r="17.5" fill={fill} stroke="#6BA6FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3383 10.978C16.0445 10.2717 17.0024 9.87494 18.0013 9.87494C19.0001 9.87494 19.958 10.2717 20.6642 10.978C21.3705 11.6842 21.7673 12.6422 21.7673 13.641C21.7673 14.6398 21.3705 15.5977 20.6642 16.3039C19.958 17.0102 19.0001 17.407 18.0013 17.407C17.0024 17.407 16.0445 17.0102 15.3383 16.3039C14.632 15.5977 14.2352 14.6398 14.2352 13.641C14.2352 12.6422 14.632 11.6842 15.3383 10.978ZM18.0013 11.1249C17.334 11.1249 16.694 11.39 16.2222 11.8619C15.7503 12.3337 15.4852 12.9737 15.4852 13.641C15.4852 14.3083 15.7503 14.9482 16.2222 15.4201C16.694 15.8919 17.334 16.157 18.0013 16.157C18.6685 16.157 19.3085 15.8919 19.7804 15.4201C20.2522 14.9482 20.5173 14.3083 20.5173 13.641C20.5173 12.9737 20.2522 12.3337 19.7804 11.8619C19.3085 11.39 18.6685 11.1249 18.0013 11.1249Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0013 19.7146C16.62 19.7146 15.2952 20.2634 14.3185 21.2401C13.3417 22.2169 12.793 23.5416 12.793 24.923C12.793 25.2681 12.5131 25.548 12.168 25.548C11.8228 25.548 11.543 25.2681 11.543 24.923C11.543 23.2101 12.2234 21.5674 13.4346 20.3562C14.6457 19.145 16.2884 18.4646 18.0013 18.4646C19.7142 18.4646 21.3569 19.145 22.568 20.3562C23.7792 21.5674 24.4596 23.2101 24.4596 24.923C24.4596 25.2681 24.1798 25.548 23.8346 25.548C23.4895 25.548 23.2096 25.2681 23.2096 24.923C23.2096 23.5416 22.6609 22.2169 21.6842 21.2401C20.7074 20.2634 19.3826 19.7146 18.0013 19.7146Z"
        fill="white"
      />
    </svg>
  );
  const OrganizationAccount = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle cx="18" cy="18" r="17.5" fill={fill} stroke="#6BA6FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9805 10.5C14.055 10.5 13.1674 10.8677 12.513 11.5221C11.8585 12.1765 11.4909 13.0641 11.4909 13.9896C11.4909 14.9151 11.8585 15.8027 12.513 16.4571C13.1674 17.1115 14.055 17.4792 14.9805 17.4792C15.906 17.4792 16.7936 17.1115 17.448 16.4571C18.1024 15.8027 18.4701 14.9151 18.4701 13.9896C18.4701 13.0641 18.1024 12.1765 17.448 11.5221C16.7936 10.8677 15.906 10.5 14.9805 10.5ZM13.3968 12.406C13.8168 11.986 14.3865 11.75 14.9805 11.75C15.5744 11.75 16.1441 11.986 16.5641 12.406C16.9841 12.826 17.2201 13.3956 17.2201 13.9896C17.2201 14.5836 16.9841 15.1532 16.5641 15.5732C16.1441 15.9932 15.5744 16.2292 14.9805 16.2292C14.3865 16.2292 13.8168 15.9932 13.3968 15.5732C12.9768 15.1532 12.7409 14.5836 12.7409 13.9896C12.7409 13.3956 12.9768 12.826 13.3968 12.406Z"
        fill="white"
      />
      <path
        d="M12.1078 20.544C12.8697 19.7822 13.903 19.3542 14.9805 19.3542C16.0579 19.3542 17.0912 19.7822 17.8531 20.544C18.615 21.3059 19.043 22.3392 19.043 23.4167C19.043 23.7618 19.3228 24.0417 19.668 24.0417C20.0131 24.0417 20.293 23.7618 20.293 23.4167C20.293 22.0077 19.7333 20.6564 18.737 19.6602C17.7407 18.6639 16.3894 18.1042 14.9805 18.1042C13.5715 18.1042 12.2203 18.6639 11.224 19.6602C10.2277 20.6564 9.66797 22.0077 9.66797 23.4167C9.66797 23.7618 9.94779 24.0417 10.293 24.0417C10.6381 24.0417 10.918 23.7618 10.918 23.4167C10.918 22.3392 11.346 21.3059 12.1078 20.544Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9833 13.4529C20.5401 12.8961 21.2952 12.5833 22.0825 12.5833C22.8699 12.5833 23.625 12.8961 24.1818 13.4529C24.7385 14.0096 25.0513 14.7647 25.0513 15.5521C25.0513 16.3394 24.7385 17.0946 24.1818 17.6513C23.625 18.2081 22.8699 18.5208 22.0825 18.5208C21.2952 18.5208 20.5401 18.2081 19.9833 17.6513C19.4266 17.0946 19.1138 16.3394 19.1138 15.5521C19.1138 14.7647 19.4266 14.0096 19.9833 13.4529ZM22.0825 13.8333C21.6267 13.8333 21.1895 14.0144 20.8672 14.3367C20.5449 14.6591 20.3638 15.0962 20.3638 15.5521C20.3638 16.0079 20.5449 16.4451 20.8672 16.7674C21.1895 17.0898 21.6267 17.2708 22.0825 17.2708C22.5384 17.2708 22.9756 17.0898 23.2979 16.7674C23.6202 16.4451 23.8013 16.0079 23.8013 15.5521C23.8013 15.0962 23.6202 14.6591 23.2979 14.3367C22.9756 14.0144 22.5384 13.8333 22.0825 13.8333Z"
        fill="white"
      />
      <path
        d="M20.9695 20.4045C21.455 20.2256 21.9765 20.1665 22.4897 20.2324C23.0028 20.2983 23.4925 20.4872 23.917 20.783C24.3415 21.0788 24.6883 21.4727 24.9279 21.9313C25.1674 22.3899 25.2927 22.8996 25.293 23.417C25.2931 23.7622 25.5731 24.0418 25.9183 24.0417C26.2635 24.0415 26.5431 23.7615 26.543 23.4163C26.5426 22.6976 26.3686 21.9896 26.0358 21.3526C25.703 20.7155 25.2213 20.1683 24.6316 19.7574C24.0419 19.3465 23.3617 19.0842 22.6489 18.9926C21.936 18.9011 21.2116 18.9831 20.5372 19.2316C20.2134 19.351 20.0476 19.7103 20.1669 20.0342C20.2863 20.3581 20.6456 20.5238 20.9695 20.4045Z"
        fill="white"
      />
    </svg>
  );

  const FilledIndividualAccount = () => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="5"
      y="5"
    >
      <rect width="30" height="30" rx="8" fill="#4A4A4A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6033 8.68024C13.2389 8.0446 14.101 7.6875 15 7.6875C15.8989 7.6875 16.761 8.0446 17.3966 8.68024C18.0323 9.31588 18.3894 10.178 18.3894 11.0769C18.3894 11.9759 18.0323 12.838 17.3966 13.4736C16.761 14.1092 15.8989 14.4663 15 14.4663C14.101 14.4663 13.2389 14.1092 12.6033 13.4736C11.9676 12.838 11.6105 11.9759 11.6105 11.0769C11.6105 10.178 11.9676 9.31588 12.6033 8.68024ZM15 8.8125C14.3994 8.8125 13.8234 9.05107 13.3988 9.47573C12.9741 9.9004 12.7355 10.4764 12.7355 11.0769C12.7355 11.6775 12.9741 12.2535 13.3988 12.6781C13.8234 13.1028 14.3994 13.3413 15 13.3413C15.6005 13.3413 16.1765 13.1028 16.6011 12.6781C17.0258 12.2535 17.2644 11.6775 17.2644 11.0769C17.2644 10.4764 17.0258 9.9004 16.6011 9.47573C16.1765 9.05107 15.6005 8.8125 15 8.8125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16.5432C13.7568 16.5432 12.5645 17.0371 11.6854 17.9162C10.8064 18.7952 10.3125 19.9875 10.3125 21.2307C10.3125 21.5414 10.0607 21.7932 9.75 21.7932C9.43934 21.7932 9.1875 21.5414 9.1875 21.2307C9.1875 19.6891 9.79989 18.2107 10.8899 17.1207C11.98 16.0306 13.4584 15.4182 15 15.4182C16.5416 15.4182 18.02 16.0306 19.1101 17.1207C20.2001 18.2107 20.8125 19.6891 20.8125 21.2307C20.8125 21.5414 20.5607 21.7932 20.25 21.7932C19.9393 21.7932 19.6875 21.5414 19.6875 21.2307C19.6875 19.9875 19.1936 18.7952 18.3146 17.9162C17.4355 17.0371 16.2432 16.5432 15 16.5432Z"
        fill="white"
      />
    </svg>
  );

  const FilledSquareIndividualAccount = () => (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="5"
      y="5"
    >
      <rect width="30" height="30" rx="8" fill="#4A4A4A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6033 8.68024C13.2389 8.0446 14.101 7.6875 15 7.6875C15.8989 7.6875 16.761 8.0446 17.3966 8.68024C18.0323 9.31588 18.3894 10.178 18.3894 11.0769C18.3894 11.9759 18.0323 12.838 17.3966 13.4736C16.761 14.1092 15.8989 14.4663 15 14.4663C14.101 14.4663 13.2389 14.1092 12.6033 13.4736C11.9676 12.838 11.6105 11.9759 11.6105 11.0769C11.6105 10.178 11.9676 9.31588 12.6033 8.68024ZM15 8.8125C14.3994 8.8125 13.8234 9.05107 13.3988 9.47573C12.9741 9.9004 12.7355 10.4764 12.7355 11.0769C12.7355 11.6775 12.9741 12.2535 13.3988 12.6781C13.8234 13.1028 14.3994 13.3413 15 13.3413C15.6005 13.3413 16.1765 13.1028 16.6011 12.6781C17.0258 12.2535 17.2644 11.6775 17.2644 11.0769C17.2644 10.4764 17.0258 9.9004 16.6011 9.47573C16.1765 9.05107 15.6005 8.8125 15 8.8125Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 16.5432C13.7568 16.5432 12.5645 17.0371 11.6854 17.9162C10.8064 18.7952 10.3125 19.9875 10.3125 21.2307C10.3125 21.5414 10.0607 21.7932 9.75 21.7932C9.43934 21.7932 9.1875 21.5414 9.1875 21.2307C9.1875 19.6891 9.79989 18.2107 10.8899 17.1207C11.98 16.0306 13.4584 15.4182 15 15.4182C16.5416 15.4182 18.02 16.0306 19.1101 17.1207C20.2001 18.2107 20.8125 19.6891 20.8125 21.2307C20.8125 21.5414 20.5607 21.7932 20.25 21.7932C19.9393 21.7932 19.6875 21.5414 19.6875 21.2307C19.6875 19.9875 19.1936 18.7952 18.3146 17.9162C17.4355 17.0371 16.2432 16.5432 15 16.5432Z"
        fill="white"
      />
    </svg>
  );

  switch (type) {
    case 'individual':
      return <IndividualAccount />;
    case 'organization':
      return <OrganizationAccount />;
    case 'filled-individual':
      return <FilledIndividualAccount />;
    case 'filled-square-individual':
      return <FilledSquareIndividualAccount />;
    default:
      return <IndividualAccount />;
  }
};

export default Account;
