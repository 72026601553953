import { actionTypes } from '../actions/highlight';
import settingsActionTypes from '../actions/settings/actions';

const ACTION_HANDLERS = {
  [actionTypes.HIGHLIGHT]: (state, action) => ({
    ...state,
    highlight: action.element,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

// Reducer
const initialState = {
  highlight: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
