/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Unlocked = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1391 8.27505H14.6182V6.36533C14.6209 5.23812 14.2173 4.1477 13.4814 3.29387C12.7455 2.44003 11.7266 1.87997 10.6113 1.71631C9.49606 1.55265 8.35917 1.79635 7.40898 2.40275C6.45879 3.00916 5.75894 3.93765 5.43766 5.0181C5.37136 5.23912 5.39557 5.47742 5.50496 5.68058C5.61436 5.88375 5.79998 6.03513 6.021 6.10144C6.24201 6.16774 6.48031 6.14353 6.68348 6.03414C6.88664 5.92474 7.03803 5.73912 7.10433 5.5181C7.30823 4.83998 7.74914 4.25776 8.34661 3.87769C8.94409 3.49763 9.65829 3.34504 10.3589 3.44777C11.0595 3.55051 11.6999 3.90172 12.1631 4.43732C12.6263 4.97292 12.8815 5.65721 12.8821 6.36533V7.92783C12.8821 8.01991 12.8455 8.10823 12.7804 8.17335C12.7153 8.23847 12.627 8.27505 12.5349 8.27505H4.72238C4.35403 8.27505 4.00076 8.42138 3.74029 8.68184C3.47982 8.94231 3.3335 9.29558 3.3335 9.66394V16.9556C3.3335 17.324 3.47982 17.6772 3.74029 17.9377C4.00076 18.1982 4.35403 18.3445 4.72238 18.3445H15.1391C15.5074 18.3445 15.8607 18.1982 16.1211 17.9377C16.3816 17.6772 16.5279 17.324 16.5279 16.9556V9.66394C16.5279 9.29558 16.3816 8.94231 16.1211 8.68184C15.8607 8.42138 15.5074 8.27505 15.1391 8.27505ZM8.54183 12.4417C8.54183 12.0734 8.68816 11.7201 8.94863 11.4596C9.20909 11.1992 9.56236 11.0528 9.93072 11.0528C10.2991 11.0528 10.6523 11.1992 10.9128 11.4596C11.1733 11.7201 11.3196 12.0734 11.3196 12.4417C11.3181 12.6841 11.2533 12.9219 11.1314 13.1314C11.0096 13.341 10.8351 13.515 10.6252 13.6362V15.2195C10.6252 15.4037 10.552 15.5803 10.4218 15.7105C10.2915 15.8408 10.1149 15.9139 9.93072 15.9139C9.74654 15.9139 9.56991 15.8408 9.43967 15.7105C9.30944 15.5803 9.23627 15.4037 9.23627 15.2195V13.6362C9.02636 13.515 8.85182 13.341 8.72999 13.1314C8.60816 12.9219 8.54329 12.6841 8.54183 12.4417Z"
      fill={fill}
    />
  </svg>
);

Unlocked.propTypes = {
  fill: PropTypes.string,
};

Unlocked.defaultProps = {
  fill: '#0065FF',
};

export default Unlocked;
