import { useFeatureFlags } from 'services/FeatureFlags';
import useEntitlements from './useEntitlements';
import { checkQuota } from '../utils';
import { EntitlementCode } from '../types';

type ResultType<T> = T extends EntitlementCode ? boolean : boolean[];

/**
 * Hook to check if a user is authorized to a certain feature based on entitlements.
 * This is only for features where the entitlement is a boolean. Not for numeric quotas.
 *
 * @param entitlementCode - A single entitlement code or an array of entitlement codes
 * @returns A boolean if a single code was provided, or an array of booleans matching the input array
 *
 * @example
 * // Check a single entitlement
 * const canCreateMap = useCan('CREATE_MAPS');
 *
 * @example
 * // Check multiple entitlements
 * const [canCreateMap, canPrintMap] = useCan(['CREATE_MAPS', 'PRINT_MAPS']);
 */
function useCan<T extends EntitlementCode | readonly EntitlementCode[]>(
  entitlementCode: T,
): ResultType<T> {
  const { newPlanChangesCore299 } = useFeatureFlags();
  const entitlements = useEntitlements();
  const featureFlagEnabled = !!newPlanChangesCore299?.value;

  // While the new plans aren't enabled, all entitlements are allowed
  // This is to ensure backwards compatibility with the old plans
  if (!featureFlagEnabled) {
    return (
      Array.isArray(entitlementCode) ?
        entitlementCode.map(() => true)
      : true) as ResultType<T>;
  }

  if (Array.isArray(entitlementCode)) {
    return entitlementCode.map((code: EntitlementCode) => {
      const entitlement = entitlements[code];
      return entitlement ? checkQuota(entitlement) : false;
    }) as ResultType<T>;
  }

  const entitlement = entitlements[entitlementCode as EntitlementCode];
  return checkQuota(entitlement) as ResultType<T>;
}

export default useCan;
