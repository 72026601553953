export function handleSelectToolInstance(
  selectedToolInstance,
  dispatch,
  getState,
  updateToolInstancesPropsAction,
  setSelectedToolInstanceAction,
) {
  const { map } = getState();

  // Remove prior highlights.
  const highlightedFeatures = map.geoJSON.filter(
    (feature) =>
      feature.properties.highlight === true ||
      feature.properties.highlightStyle === 'bold',
  );
  if (highlightedFeatures.length > 0) {
    dispatch(
      updateToolInstancesPropsAction(
        highlightedFeatures.map((feature) => feature.properties.maprightId),
        { highlight: false, highlightStyle: null },
      ),
    );
  }

  // If no tool is passed or the name includes "waypoint" (case-insensitive),
  // simply update the selected tool instance.
  if (
    !selectedToolInstance ||
    (selectedToolInstance.properties &&
      selectedToolInstance.properties.name &&
      selectedToolInstance.properties.name.toLowerCase().includes('waypoint'))
  ) {
    dispatch(setSelectedToolInstanceAction(selectedToolInstance));
    return;
  }

  // Highlight the selected tool if a valid identifier exists.
  if (
    selectedToolInstance.properties &&
    selectedToolInstance.properties.maprightId
  ) {
    dispatch(
      updateToolInstancesPropsAction(
        [selectedToolInstance.properties.maprightId],
        { highlight: true, highlightStyle: 'bold' },
      ),
    );
  }

  // Finally, dispatch the action to mark the selected tool instance.
  dispatch(setSelectedToolInstanceAction(selectedToolInstance));
}
