import React from 'react';
import PropTypes from 'prop-types';

const Folder = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M17.5 7.34575C17.2168 7.17555 16.8929 7.08487 16.5625 7.08325H3.4375C3.10708 7.08487 2.78321 7.17555 2.5 7.34575V4.27075C2.5 4.02211 2.59877 3.78365 2.77459 3.60784C2.9504 3.43202 3.18886 3.33325 3.4375 3.33325H8.125C8.29898 3.33349 8.46947 3.38214 8.61738 3.47375C8.76529 3.56537 8.88479 3.69634 8.9625 3.852L9.55625 5.03325C9.58141 5.08632 9.62131 5.13103 9.67117 5.16206C9.72104 5.19309 9.77878 5.20912 9.8375 5.20825H16.5625C16.8111 5.20825 17.0496 5.30702 17.2254 5.48284C17.4012 5.65865 17.5 5.89711 17.5 6.14575V7.34575ZM3.4375 8.02075H16.5625C17.0803 8.02075 17.5 8.44049 17.5 8.95825V16.1458C17.5 16.6635 17.0803 17.0833 16.5625 17.0833H3.4375C2.91973 17.0833 2.5 16.6635 2.5 16.1458V8.95825C2.5 8.44049 2.91973 8.02075 3.4375 8.02075Z"
      fill={fill}
    />
  </svg>
);

Folder.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Folder.defaultProps = {
  fill: '#FFFFFF',
  width: '20',
  height: '20',
};

export default Folder;
