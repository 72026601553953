import { NonConfigurableIconProps } from '../types';

const Infrared = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.65888 15.4374C7.67949 15.4683 7.70829 15.4929 7.74207 15.5083V15.525C8.07862 15.6922 8.34877 15.9683 8.50867 16.3084C8.66858 16.6485 8.70885 17.0326 8.62296 17.3985C8.53707 17.7643 8.33006 18.0904 8.03552 18.3238C7.74098 18.5572 7.37621 18.6842 7.00041 18.6842C6.62461 18.6842 6.25983 18.5572 5.9653 18.3238C5.67076 18.0904 5.46374 17.7643 5.37785 17.3985C5.29197 17.0326 5.33224 16.6485 5.49215 16.3084C5.65205 15.9683 5.9222 15.6922 6.25874 15.525C6.29512 15.5084 6.32569 15.4812 6.34649 15.4471C6.36728 15.4129 6.37736 15.3733 6.37541 15.3333V7.41667C6.37541 7.25091 6.44126 7.09193 6.55847 6.97472C6.67568 6.85751 6.83465 6.79167 7.00041 6.79167C7.16617 6.79167 7.32514 6.85751 7.44235 6.97472C7.55956 7.09193 7.62541 7.25091 7.62541 7.41667V15.3333C7.62668 15.3705 7.63827 15.4065 7.65888 15.4374Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00033 22C7.95615 22.0001 8.89197 21.7262 9.69691 21.2107C10.5019 20.6953 11.1422 19.96 11.5421 19.0918C11.9419 18.2236 12.0846 17.2591 11.9531 16.3123C11.8217 15.3656 11.4216 14.4764 10.8003 13.75C10.7672 13.7136 10.7493 13.6659 10.7503 13.6167V5.75C10.7503 4.75544 10.3552 3.80161 9.65198 3.09835C8.94872 2.39509 7.99489 2 7.00033 2C6.00577 2 5.05194 2.39509 4.34868 3.09835C3.64542 3.80161 3.25033 4.75544 3.25033 5.75V13.6167C3.25141 13.6659 3.2335 13.7136 3.20033 13.75C2.57905 14.4764 2.17898 15.3656 2.04752 16.3123C1.91606 17.2591 2.05872 18.2236 2.4586 19.0918C2.85847 19.96 3.49881 20.6953 4.30376 21.2107C5.1087 21.7262 6.04451 22.0001 7.00033 22ZM7.00033 20.3333C6.33763 20.3339 5.68979 20.1369 5.13956 19.7676C4.58933 19.3982 4.16169 18.8732 3.91125 18.2597C3.66082 17.6461 3.59896 16.9718 3.73357 16.3229C3.86818 15.674 4.19316 15.08 4.667 14.6167C4.74574 14.5395 4.80838 14.4475 4.85129 14.3459C4.89421 14.2443 4.91654 14.1353 4.917 14.025V5.75C4.917 5.19747 5.13649 4.66756 5.52719 4.27686C5.91789 3.88616 6.4478 3.66667 7.00033 3.66667C7.55287 3.66667 8.08277 3.88616 8.47347 4.27686C8.86417 4.66756 9.08367 5.19747 9.08367 5.75V14.025C9.08412 14.1353 9.10646 14.2443 9.14937 14.3459C9.19228 14.4475 9.25493 14.5395 9.33367 14.6167C9.8075 15.08 10.1325 15.674 10.2671 16.3229C10.4017 16.9718 10.3398 17.6461 10.0894 18.2597C9.83898 18.8732 9.41133 19.3982 8.86111 19.7676C8.31088 20.1369 7.66304 20.3339 7.00033 20.3333Z"
        fill="#1D1D1D"
      />
      <path
        d="M12.6254 4.91667C12.8464 4.91667 13.0584 4.82887 13.2147 4.67259C13.3709 4.51631 13.4587 4.30435 13.4587 4.08333V2.83333C13.4587 2.61232 13.3709 2.40036 13.2147 2.24408C13.0584 2.0878 12.8464 2 12.6254 2C12.4044 2 12.1924 2.0878 12.0361 2.24408C11.8799 2.40036 11.7921 2.61232 11.7921 2.83333V3.175C11.7846 3.20791 11.7846 3.24209 11.7921 3.275C12.0317 3.73667 12.2029 4.23073 12.3004 4.74167C12.3091 4.78257 12.3295 4.82007 12.3591 4.84965C12.3887 4.87922 12.4262 4.8996 12.4671 4.90833L12.6254 4.91667Z"
        fill="#1D1D1D"
      />
      <path
        d="M18.367 6.80833C18.2118 6.96229 18.0023 7.04908 17.7837 7.05C17.674 7.05063 17.5653 7.02961 17.4638 6.98814C17.3622 6.94667 17.2699 6.88557 17.192 6.80833C17.0368 6.6522 16.9497 6.44099 16.9497 6.22083C16.9497 6.00068 17.0368 5.78947 17.192 5.63333L18.0754 4.74167C18.2323 4.58475 18.4451 4.49659 18.667 4.49659C18.889 4.49659 19.1018 4.58475 19.2587 4.74167C19.4156 4.89859 19.5038 5.11141 19.5038 5.33333C19.5038 5.55525 19.4156 5.76808 19.2587 5.925L18.367 6.80833Z"
        fill="#1D1D1D"
      />
      <path
        d="M18.4587 11.375C18.4587 9.8279 17.8441 8.34417 16.7502 7.25021C15.6562 6.15625 14.1725 5.54167 12.6254 5.54167C12.5977 5.54049 12.5701 5.54507 12.5443 5.55512C12.5185 5.56517 12.495 5.58048 12.4754 5.60006C12.4559 5.61965 12.4406 5.64309 12.4305 5.6689C12.4205 5.69471 12.4159 5.72233 12.4171 5.75V7C12.416 7.04922 12.4339 7.09696 12.4671 7.13333C12.5042 7.16514 12.5514 7.18284 12.6004 7.18333C13.6348 7.15062 14.6444 7.50405 15.4326 8.17482C16.2207 8.84559 16.7311 9.78568 16.8642 10.812C16.9973 11.8384 16.7436 12.8776 16.1526 13.7272C15.5617 14.5768 14.6756 15.1761 13.6671 15.4083C13.6413 15.4142 13.6169 15.4252 13.5954 15.4405C13.5738 15.4559 13.5556 15.4754 13.5416 15.4979C13.5276 15.5203 13.5183 15.5454 13.5141 15.5715C13.5099 15.5976 13.5109 15.6243 13.5171 15.65C13.6029 16.0503 13.6531 16.4575 13.6671 16.8667C13.6673 16.8969 13.6741 16.9268 13.6871 16.9541C13.7001 16.9814 13.7188 17.0056 13.7421 17.025C13.7697 17.0367 13.7995 17.0427 13.8296 17.0427C13.8596 17.0427 13.8894 17.0367 13.9171 17.025C15.2004 16.7336 16.3476 16.0168 17.1721 14.9911C17.9966 13.9654 18.45 12.691 18.4587 11.375Z"
        fill="#1D1D1D"
      />
      <path
        d="M21.1671 10.5417H19.9171C19.6961 10.5417 19.4841 10.6295 19.3278 10.7857C19.1715 10.942 19.0837 11.154 19.0837 11.375C19.0837 11.596 19.1715 11.808 19.3278 11.9643C19.4841 12.1205 19.6961 12.2083 19.9171 12.2083H21.1671C21.3881 12.2083 21.6 12.1205 21.7563 11.9643C21.9126 11.808 22.0004 11.596 22.0004 11.375C22.0004 11.154 21.9126 10.942 21.7563 10.7857C21.6 10.6295 21.3881 10.5417 21.1671 10.5417Z"
        fill="#1D1D1D"
      />
      <path
        d="M17.7929 15.7419C18.0026 15.7338 18.2077 15.8051 18.3671 15.9417L19.2587 16.825C19.3369 16.9025 19.3988 16.9946 19.4412 17.0962C19.4835 17.1977 19.5052 17.3067 19.5052 17.4167C19.5052 17.5267 19.4835 17.6356 19.4412 17.7371C19.3988 17.8387 19.3369 17.9309 19.2587 18.0083C19.1809 18.0856 19.0885 18.1467 18.987 18.1881C18.8855 18.2296 18.7768 18.2506 18.6671 18.25C18.5574 18.2506 18.4487 18.2296 18.3472 18.1881C18.2456 18.1467 18.1533 18.0856 18.0754 18.0083L17.1921 17.1167C17.0556 16.9572 16.9842 16.7522 16.9923 16.5425C17.0004 16.3327 17.0874 16.1338 17.2358 15.9854C17.3842 15.837 17.5831 15.75 17.7929 15.7419Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Infrared;
