import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Shield = ({ fill = colors.black }: IconProps) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M15.1391 8.61895H14.6182V6.70923C14.6182 5.46603 14.1244 4.27374 13.2453 3.39467C12.3662 2.51559 11.1739 2.02173 9.93072 2.02173C8.68752 2.02173 7.49523 2.51559 6.61616 3.39467C5.73708 4.27374 5.24322 5.46603 5.24322 6.70923V8.61895H4.72238C4.35403 8.61895 4.00076 8.76528 3.74029 9.02575C3.47982 9.28621 3.3335 9.63948 3.3335 10.0078V17.2995C3.3335 17.6679 3.47982 18.0211 3.74029 18.2816C4.00076 18.5421 4.35403 18.6884 4.72238 18.6884H15.1391C15.5074 18.6884 15.8607 18.5421 16.1211 18.2816C16.3816 18.0211 16.5279 17.6679 16.5279 17.2995V10.0078C16.5279 9.63948 16.3816 9.28621 16.1211 9.02575C15.8607 8.76528 15.5074 8.61895 15.1391 8.61895ZM8.54183 12.7856C8.54183 12.4173 8.68816 12.064 8.94863 11.8035C9.20909 11.5431 9.56236 11.3967 9.93072 11.3967C10.2991 11.3967 10.6523 11.5431 10.9128 11.8035C11.1733 12.064 11.3196 12.4173 11.3196 12.7856C11.3181 13.028 11.2533 13.2658 11.1314 13.4753C11.0096 13.6849 10.8351 13.8589 10.6252 13.9801V15.5634C10.6252 15.7476 10.552 15.9242 10.4218 16.0544C10.2915 16.1847 10.1149 16.2578 9.93072 16.2578C9.74654 16.2578 9.56991 16.1847 9.43967 16.0544C9.30944 15.9242 9.23627 15.7476 9.23627 15.5634V13.9801C9.02636 13.8589 8.85182 13.6849 8.72999 13.4753C8.60816 13.2658 8.54329 13.028 8.54183 12.7856ZM6.97933 6.70923C6.97933 5.92647 7.29028 5.17577 7.84377 4.62228C8.39726 4.06879 9.14796 3.75784 9.93072 3.75784C10.7135 3.75784 11.4642 4.06879 12.0177 4.62228C12.5712 5.17577 12.8821 5.92647 12.8821 6.70923V8.27173C12.8821 8.36382 12.8455 8.45214 12.7804 8.51725C12.7153 8.58237 12.627 8.61895 12.5349 8.61895H7.32655C7.23446 8.61895 7.14615 8.58237 7.08103 8.51725C7.01591 8.45214 6.97933 8.36382 6.97933 8.27173V6.70923Z"
      fill={fill}
    />
  </svg>
);

export default Shield;
