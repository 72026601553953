import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import us from 'us';

import Button from 'components/ui/Button';
import Checkbox from 'components/ui/Checkbox';
import CustomModal from '../../CustomModal';
import SelectStateInput from './selectStateInput';
import EventRouter from 'services/EventRouter';

import './styles.scss';

class SelectStateModal extends PureComponent {
  constructor(props) {
    super(props);

    const selectedState =
      props.defaultState &&
      us.STATES.find((state) => state.name === props.defaultState).abbr;

    this.state = {
      selectedState,
      defineDefaultState: false,
    };
  }

  onStateClicked = (state) => this.setState({ selectedState: state });

  onInputChange = (value) => this.setState({ defineDefaultState: value });

  onCreateMapInsideUs = () => {
    const {
      setUserDefaultState,
      setNewMapState,
      onClose,
      onSubmit: onStateSubmit,
    } = this.props;
    const { selectedState, defineDefaultState } = this.state;

    const stateFullName = us.lookup(selectedState).name;

    if (defineDefaultState) {
      setUserDefaultState(stateFullName);
    }

    EventRouter.send('map_editMap_created', { map_state: stateFullName });

    setNewMapState(stateFullName);
    onStateSubmit(stateFullName);
    onClose();
  };

  onCreateMapOutsideUs = () => {
    const { setNewMapState, onSubmit, onClose } = this.props;

    setNewMapState(null);
    onSubmit(null);
    onClose();
  };

  onCloseButtonClicked = () => {
    const { onClose, setNewMapState } = this.props;

    setNewMapState(undefined);
    onClose();
  };

  render() {
    const { selectedState, defineDefaultState } = this.state;
    const { defaultState } = this.props;
    const stateFullName = selectedState ? us.lookup(selectedState).name : '';

    return (
      <CustomModal
        positionModifier="full"
        className="select-state-modal"
        {...this.props}
      >
        <main>
          <div className="select-state-modal__header">
            <p className="title">New Map</p>
            <p className="subtitle">Select a State</p>
          </div>

          <p className="select-state-modal__selected_state">{stateFullName}</p>

          {selectedState && (
            <div className="select-state-modal__form">
              <label>
                <Checkbox
                  disabled={!selectedState}
                  value={defineDefaultState}
                  onChange={this.onInputChange}
                />
                <p>Save selected state as my default state</p>
              </label>
            </div>
          )}

          <div className="select-state-modal__map">
            <SelectStateInput
              onClick={this.onStateClicked}
              className="select-state-modal__map_input"
              selectedState={selectedState && selectedState.toLowerCase()}
            />
          </div>

          <div className="select-state-modal__footer">
            <div className="select-state-modal__footer__actions">
              <Button
                color="blue"
                size="w200"
                onClick={this.onCreateMapInsideUs}
                disabled={!selectedState && !defaultState}
              >
                Get Started
              </Button>

              <Button
                size="w200"
                color="grayLightest"
                fontColor="fBlack"
                onClick={this.onCloseButtonClicked}
              >
                Cancel
              </Button>
            </div>

            <div className="select-state-modal__footer__outside-button">
              <p onClick={this.onCreateMapOutsideUs}>Create map outside US</p>
            </div>
          </div>
        </main>
      </CustomModal>
    );
  }
}

SelectStateModal.propTypes = {
  setNewMapState: PropTypes.func,
  setUserDefaultState: PropTypes.func,
  defaultState: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default SelectStateModal;
