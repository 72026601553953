import { Middleware } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import actionTypes from 'actions/user/actions';
import { api } from 'api/endpoints';

export const resetApiOnLogoutMiddleware: Middleware<{}, any, any> =
  (store) => (next) => (action: AnyAction) => {
    const actionResult = next(action);
    if (action.type === actionTypes.LOGOUT) {
      setTimeout(() => {
        store.dispatch(api.util.resetApiState());
      }, 0);
    }

    return actionResult;
  };

export default resetApiOnLogoutMiddleware;
