import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const SoilReport = ({ fill = colors.white }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M3 8.03125C3 7.61704 3.33579 7.28125 3.75 7.28125H16.25C16.6642 7.28125 17 7.61704 17 8.03125C17 8.44546 16.6642 8.78125 16.25 8.78125H3.75C3.33579 8.78125 3 8.44546 3 8.03125Z"
      fill={fill}
    />
    <path
      d="M3.75 10.8125C3.33579 10.8125 3 11.1483 3 11.5625C3 11.9767 3.33579 12.3125 3.75 12.3125H16.25C16.6642 12.3125 17 11.9767 17 11.5625C17 11.1483 16.6642 10.8125 16.25 10.8125H3.75Z"
      fill={fill}
    />
    <path
      d="M3 15.125C3 14.7108 3.33579 14.375 3.75 14.375H16.25C16.6642 14.375 17 14.7108 17 15.125C17 15.5392 16.6642 15.875 16.25 15.875H3.75C3.33579 15.875 3 15.5392 3 15.125Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0C19.1046 0 20 0.89543 20 2V18C20 19.1046 19.1046 20 18 20H2C0.895431 20 0 19.1046 0 18V2C0 0.895431 0.89543 0 2 0H18ZM7.5 4.5C7.5 5.05228 7.94772 5.5 8.5 5.5H18C18.2761 5.5 18.5 5.72386 18.5 6V18C18.5 18.2761 18.2761 18.5 18 18.5H2C1.72386 18.5 1.5 18.2761 1.5 18V2C1.5 1.72386 1.72386 1.5 2 1.5H7C7.27614 1.5 7.5 1.72386 7.5 2V4.5ZM18.5 4V2C18.5 1.72386 18.2761 1.5 18 1.5H14.7812V4H18.5ZM13.2812 4H9.5C9.22386 4 9 3.77614 9 3.5V1.5H13.2812V4Z"
      fill={fill}
    />
  </svg>
);

export default SoilReport;
