import spinner from 'static/images/spinner.svg';
import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/usePortal';

import './styles.scss';

interface EllipseSpinnerProps {
  spinnerStyle?: React.CSSProperties;
  spinnerInnerStyle?: React.CSSProperties;
  fadded?: boolean;
  fullScreen?: boolean;
  zIndex?: string;
  color?: string;
}

const EllipseSpinner = ({
  spinnerStyle,
  spinnerInnerStyle,
  fadded,
  fullScreen = false,
  zIndex = '3499',
}: EllipseSpinnerProps) => {
  const combinedSpinnerInnerStyle = { ...spinnerInnerStyle };

  if (fadded) {
    combinedSpinnerInnerStyle.backgroundImage = `url(${spinner})`;
    combinedSpinnerInnerStyle.backgroundRepeat = 'no-repeat';
    combinedSpinnerInnerStyle.border = 'unset';
  }

  const spinnerElement = (
    <div className="spinner" style={spinnerStyle}>
      <div className="spinner-inner" style={combinedSpinnerInnerStyle} />
    </div>
  );
  const { body } = document;
  const target = usePortal(body);

  if (fullScreen && target) {
    const LoadingWithOverlay = (
      <div
        style={{
          margin: 'auto',
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: 0,
          left: 0,
          zIndex,
        }}
      >
        {spinnerElement}
      </div>
    );

    return createPortal(LoadingWithOverlay, target);
  }

  return spinnerElement;
};

export default EllipseSpinner;
