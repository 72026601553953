import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Print = ({
  fill = colors.black,
  height,
  width,
  variant = 'filled',
}: IconPropsWithVariant) => {
  const defaultSize = variant === 'filled' ? '15' : '20';

  return variant === 'filled' ?
      <svg
        width={width || defaultSize}
        height={height || defaultSize}
        viewBox="0 0 15 15"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // eslint-disable-next-line max-len
          d="M4.09375 4.25C4.25951 4.25 4.41848 4.18415 4.53569 4.06694C4.6529 3.94973 4.71875 3.79076 4.71875 3.625V1.75H10.2437L11.5938 3.1V3.625C11.5938 3.79076 11.6596 3.94973 11.7768 4.06694C11.894 4.18415 12.053 4.25 12.2188 4.25C12.3845 4.25 12.5435 4.18415 12.6607 4.06694C12.7779 3.94973 12.8438 3.79076 12.8438 3.625V2.84375C12.8476 2.75733 12.8335 2.67104 12.8023 2.59035C12.7711 2.50967 12.7235 2.43633 12.6625 2.375L11.125 0.86875C10.8917 0.634008 10.5747 0.501395 10.2437 0.5H4.71875C4.38723 0.5 4.06929 0.631696 3.83487 0.866117C3.60045 1.10054 3.46875 1.41848 3.46875 1.75V3.625C3.46875 3.79076 3.5346 3.94973 3.65181 4.06694C3.76902 4.18415 3.92799 4.25 4.09375 4.25Z"
          fill={fill}
        />
        <path
          // eslint-disable-next-line max-len
          d="M13.9375 4.875H2.0625C1.6481 4.875 1.25067 5.03962 0.957646 5.33265C0.66462 5.62567 0.5 6.0231 0.5 6.4375V9.25C0.5 9.6644 0.66462 10.0618 0.957646 10.3549C1.25067 10.6479 1.6481 10.8125 2.0625 10.8125H3.625V14.7188C3.625 14.8213 3.64521 14.9229 3.68447 15.0177C3.72373 15.1125 3.78128 15.1986 3.85382 15.2712C3.92637 15.3437 4.01249 15.4013 4.10728 15.4405C4.20206 15.4798 4.30365 15.5 4.40625 15.5H11.9062C12.0088 15.5 12.1104 15.4798 12.2052 15.4405C12.3 15.4013 12.3861 15.3437 12.4587 15.2712C12.5312 15.1986 12.5888 15.1125 12.628 15.0177C12.6673 14.9229 12.6875 14.8213 12.6875 14.7188V10.8125H13.9375C14.1427 10.8125 14.3459 10.7721 14.5354 10.6936C14.725 10.615 14.8973 10.4999 15.0424 10.3549C15.1874 10.2098 15.3025 10.0375 15.3811 9.84794C15.4596 9.65837 15.5 9.45519 15.5 9.25V6.4375C15.5 6.23231 15.4596 6.02913 15.3811 5.83956C15.3025 5.64999 15.1874 5.47774 15.0424 5.33265C14.8973 5.18755 14.725 5.07246 14.5354 4.99394C14.3459 4.91542 14.1427 4.875 13.9375 4.875ZM2.6875 7.6875C2.56389 7.6875 2.44305 7.65085 2.34027 7.58217C2.23749 7.51349 2.15738 7.41588 2.11008 7.30168C2.06277 7.18747 2.05039 7.06181 2.07451 6.94057C2.09862 6.81933 2.15815 6.70797 2.24556 6.62056C2.33297 6.53315 2.44433 6.47363 2.56557 6.44951C2.68681 6.42539 2.81247 6.43777 2.92668 6.48508C3.04088 6.53238 3.13849 6.61249 3.20717 6.71527C3.27584 6.81805 3.3125 6.93889 3.3125 7.0625C3.3125 7.22826 3.24665 7.38723 3.12944 7.50444C3.01223 7.62165 2.85326 7.6875 2.6875 7.6875ZM11.4375 13.9375C11.4375 14.0204 11.4046 14.0999 11.346 14.1585C11.2874 14.2171 11.2079 14.25 11.125 14.25H5.1875C5.10462 14.25 5.02513 14.2171 4.96653 14.1585C4.90792 14.0999 4.875 14.0204 4.875 13.9375V8.3125C4.875 8.22962 4.90792 8.15013 4.96653 8.09153C5.02513 8.03292 5.10462 8 5.1875 8H11.125C11.2079 8 11.2874 8.03292 11.346 8.09153C11.4046 8.15013 11.4375 8.22962 11.4375 8.3125V13.9375Z"
          fill={fill}
        />
        <path
          // eslint-disable-next-line max-len
          d="M9.68125 10.4312C9.65869 10.3791 9.62232 10.334 9.57607 10.301C9.52981 10.268 9.47542 10.2482 9.41875 10.2437C9.36275 10.2373 9.30608 10.2468 9.25523 10.2712C9.20438 10.2955 9.16142 10.3336 9.13125 10.3813L8.09375 11.925L7.0625 11.0813C7.02693 11.0528 6.98556 11.0324 6.94129 11.0216C6.89703 11.0108 6.85094 11.0099 6.80625 11.0187C6.7602 11.0284 6.71679 11.0479 6.67902 11.076C6.64125 11.104 6.61003 11.1399 6.5875 11.1812L5.5625 13.1687C5.53622 13.2155 5.52241 13.2682 5.52241 13.3219C5.52241 13.3755 5.53622 13.4282 5.5625 13.475C5.59141 13.5203 5.63109 13.5577 5.67799 13.5838C5.72489 13.61 5.77755 13.6242 5.83125 13.625H10.5C10.5516 13.625 10.6025 13.6126 10.6482 13.5886C10.6939 13.5647 10.7331 13.53 10.7625 13.4875C10.7871 13.4425 10.8 13.392 10.8 13.3406C10.8 13.2893 10.7871 13.2388 10.7625 13.1937L9.68125 10.4312Z"
          fill={fill}
        />
      </svg>
    : <svg
        width={width || defaultSize}
        height={height || defaultSize}
        viewBox="0 0 20 20"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // eslint-disable-next-line max-len
          d="M2.46914 8.14815C2.46914 7.73905 2.80078 7.40741 3.20988 7.40741H4.44444C4.85354 7.40741 5.18519 7.73905 5.18519 8.14815C5.18519 8.55725 4.85354 8.88889 4.44444 8.88889H3.20988C2.80078 8.88889 2.46914 8.55725 2.46914 8.14815Z"
          fill={fill}
        />
        <path
          // eslint-disable-next-line max-len
          d="M11.8971 12.7552C11.7716 12.5042 11.5152 12.3457 11.2346 12.3457C10.954 12.3457 10.6975 12.5042 10.572 12.7552L9.29103 15.3172L8.7645 14.5274C8.62712 14.3213 8.39584 14.1975 8.14817 14.1975C7.9005 14.1975 7.66922 14.3213 7.53183 14.5274L6.29727 16.3792C6.07034 16.7196 6.16232 17.1795 6.50271 17.4065C6.8431 17.6334 7.303 17.5414 7.52993 17.201L8.14817 16.2737L8.7664 17.201C8.91287 17.4207 9.16522 17.5458 9.42876 17.5294C9.6923 17.513 9.92719 17.3576 10.0453 17.1214L11.2346 14.7428L12.4239 17.1214C12.6069 17.4873 13.0518 17.6356 13.4177 17.4527C13.7836 17.2697 13.9319 16.8248 13.749 16.4589L11.8971 12.7552Z"
          fill={fill}
        />
        <path
          // eslint-disable-next-line
          fillRule="evenodd"
          // eslint-disable-next-line
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M3.7037 1.97531V4.93827H1.97531C1.45142 4.93827 0.948997 5.14638 0.578555 5.51683C0.208112 5.88727 0 6.3897 0 6.91358V13.0864C0 13.6103 0.208113 14.1127 0.578555 14.4832C0.948996 14.8536 1.45142 15.0617 1.97531 15.0617H3.7037V19.2593C3.7037 19.6684 4.03534 20 4.44444 20H15.5556C15.9647 20 16.2963 19.6684 16.2963 19.2593V15.0617H18.0247C18.5486 15.0617 19.051 14.8536 19.4214 14.4832C19.7919 14.1127 20 13.6103 20 13.0864V6.91358C20 6.38969 19.7919 5.88727 19.4214 5.51683C19.051 5.14638 18.5486 4.93827 18.0247 4.93827H16.2963V1.97531C16.2963 1.45142 16.0882 0.948996 15.7177 0.578554C15.3473 0.208113 14.8449 0 14.321 0H5.67901C5.15513 0 4.6527 0.208112 4.28226 0.578554C3.91182 0.948997 3.7037 1.45142 3.7037 1.97531ZM5.67901 1.48148C5.54804 1.48148 5.42243 1.53351 5.32982 1.62612C5.23721 1.71873 5.18519 1.84434 5.18519 1.97531V4.93827H14.8148V1.97531C14.8148 1.84434 14.7628 1.71873 14.6702 1.62612C14.5776 1.53351 14.452 1.48148 14.321 1.48148H5.67901ZM1.97531 6.41975H18.0247C18.1557 6.41975 18.2813 6.47178 18.3739 6.56439C18.4665 6.657 18.5185 6.78261 18.5185 6.91358V13.0864C18.5185 13.2174 18.4665 13.343 18.3739 13.4356C18.2813 13.5282 18.1557 13.5802 18.0247 13.5802H16.2963V10.6173C16.2963 10.2082 15.9647 9.87654 15.5556 9.87654H4.44444C4.03534 9.87654 3.7037 10.2082 3.7037 10.6173V13.5802H1.97531C1.84434 13.5802 1.71873 13.5282 1.62612 13.4356C1.53351 13.343 1.48148 13.2174 1.48148 13.0864V6.91358C1.48148 6.78261 1.53351 6.657 1.62612 6.56439C1.71873 6.47178 1.84434 6.41975 1.97531 6.41975ZM14.8148 11.358H5.18519V18.5185H14.8148V11.358Z"
          fill={fill}
        />
      </svg>;
};

export default Print;
