import { StyleableOnlyIconProps } from '../types';

const Postcard = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33333 11.4919C4.91912 11.4919 4.58333 11.8277 4.58333 12.2419C4.58333 12.6561 4.91912 12.9919 5.33333 12.9919H8.66667C9.08088 12.9919 9.41667 12.6561 9.41667 12.2419C9.41667 11.8277 9.08088 11.4919 8.66667 11.4919H5.33333Z"
          fill="black"
        />
        <path
          d="M4.58333 15.629C4.58333 15.2148 4.91912 14.879 5.33333 14.879H12.6667C13.0809 14.879 13.4167 15.2148 13.4167 15.629C13.4167 16.0432 13.0809 16.379 12.6667 16.379H5.33333C4.91912 16.379 4.58333 16.0432 4.58333 15.629Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5556 7.13708C14.7144 7.13708 14.1389 7.85911 14.1389 8.61289V11.5161C14.1389 12.2699 14.7144 12.9919 15.5556 12.9919H18.2222C19.0634 12.9919 19.6389 12.2699 19.6389 11.5161V8.61289C19.6389 7.85911 19.0634 7.13708 18.2222 7.13708H15.5556ZM15.6389 11.4919V8.63708H18.1389V11.4919H15.6389Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33333 3.75C2.124 3.75 1.25 4.79698 1.25 5.95161V18.0484C1.25 19.203 2.124 20.25 3.33333 20.25H20.6667C21.876 20.25 22.75 19.203 22.75 18.0484V5.95161C22.75 4.79698 21.876 3.75 20.6667 3.75H3.33333ZM2.75 5.95161C2.75 5.50284 3.0699 5.25 3.33333 5.25H20.6667C20.9301 5.25 21.25 5.50284 21.25 5.95161V18.0484C21.25 18.4972 20.9301 18.75 20.6667 18.75H3.33333C3.0699 18.75 2.75 18.4972 2.75 18.0484V5.95161Z"
          fill="black"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.3333 4.5H3.66667C3.22464 4.5 2.80072 4.69754 2.48816 5.04917C2.17559 5.40081 2 5.87772 2 6.375L2 17.625C2 18.1223 2.17559 18.5992 2.48816 18.9508C2.80072 19.3025 3.22464 19.5 3.66667 19.5H20.3333C20.7754 19.5 21.1993 19.3025 21.5118 18.9508C21.8244 18.5992 22 18.1223 22 17.625V6.375C22 5.87772 21.8244 5.40081 21.5118 5.04917C21.1993 4.69754 20.7754 4.5 20.3333 4.5ZM19.5 7.78125V11.5312C19.5 11.6556 19.4561 11.7748 19.378 11.8627C19.2998 11.9506 19.1938 12 19.0833 12H15.75C15.6395 12 15.5335 11.9506 15.4554 11.8627C15.3772 11.7748 15.3333 11.6556 15.3333 11.5312V7.78125C15.3333 7.65693 15.3772 7.5377 15.4554 7.44979C15.5335 7.36189 15.6395 7.3125 15.75 7.3125H19.0833C19.1938 7.3125 19.2998 7.36189 19.378 7.44979C19.4561 7.5377 19.5 7.65693 19.5 7.78125ZM5.75 13.6406H12.4167C12.5824 13.6406 12.7414 13.7147 12.8586 13.8466C12.9758 13.9784 13.0417 14.1573 13.0417 14.3438C13.0417 14.5302 12.9758 14.7091 12.8586 14.8409C12.7414 14.9728 12.5824 15.0469 12.4167 15.0469H5.75C5.58424 15.0469 5.42527 14.9728 5.30806 14.8409C5.19085 14.7091 5.125 14.5302 5.125 14.3438C5.125 14.1573 5.19085 13.9784 5.30806 13.8466C5.42527 13.7147 5.58424 13.6406 5.75 13.6406ZM5.125 11.0625C5.12388 10.9698 5.13928 10.8778 5.17029 10.7919C5.2013 10.7061 5.2473 10.628 5.30556 10.5625C5.36382 10.497 5.43317 10.4452 5.5095 10.4103C5.58584 10.3754 5.66761 10.3581 5.75 10.3594H7.41667C7.58243 10.3594 7.7414 10.4335 7.85861 10.5653C7.97582 10.6972 8.04167 10.876 8.04167 11.0625C8.04167 11.249 7.97582 11.4278 7.85861 11.5597C7.7414 11.6915 7.58243 11.7656 7.41667 11.7656H5.75C5.66761 11.7669 5.58584 11.7496 5.5095 11.7147C5.43317 11.6798 5.36382 11.628 5.30556 11.5625C5.2473 11.497 5.2013 11.4189 5.17029 11.3331C5.13928 11.2472 5.12388 11.1552 5.125 11.0625Z"
          fill="black"
        />
      </svg>;
};

export default Postcard;
