import { NonConfigurableIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Area = ({
  width = '24',
  height = '24',
  color = colorPalette.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.26036 4.13018C6.26036 4.18467 6.25831 4.23868 6.25429 4.29214L18.2532 8.54176C18.6439 8.08691 19.2232 7.79881 19.8698 7.79881C21.0463 7.79881 22 8.75252 22 9.92898C22 11.1054 21.0463 12.0592 19.8698 12.0592C19.8249 12.0592 19.7803 12.0578 19.736 12.055L17.6994 18.2371C18.0896 18.6232 18.3313 19.159 18.3313 19.7513C18.3313 20.9278 17.3776 21.8815 16.2012 21.8815C15.3209 21.8815 14.5653 21.3476 14.2407 20.5859H8.57588C8.25124 21.3476 7.49567 21.8815 6.61539 21.8815C5.43892 21.8815 4.48521 20.9278 4.48521 19.7513C4.48521 19.0007 4.87349 18.3407 5.46017 17.9613L3.71135 6.21919C2.73545 6.02462 2 5.16328 2 4.13018C2 2.95371 2.95371 2 4.13018 2C5.30664 2 6.26036 2.95371 6.26036 4.13018ZM8.71905 19.4143C8.55749 18.398 7.67716 17.6212 6.61539 17.6212C6.60827 17.6212 6.60115 17.6212 6.59405 17.6213L4.88169 6.124C5.27053 5.97737 5.60518 5.72025 5.84738 5.39093L17.7632 9.61112C17.7477 9.71482 17.7397 9.82096 17.7397 9.92898C17.7397 10.6437 18.0917 11.2762 18.6318 11.6626L16.6529 17.6692C16.5073 17.6377 16.3562 17.6212 16.2012 17.6212C15.1394 17.6212 14.2591 18.398 14.0975 19.4143H8.71905Z"
        fill={color}
      />
    </svg>
  );
};

export default Area;
