import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import settingsActionTypes from 'actions/settings/actions';

type Modal<C extends React.ComponentType<any>> = {
  component: C;
  props: Omit<React.ComponentProps<C>, 'onClose'> & { onClose?: () => void };
};

type Modals = Modal<React.ComponentType<any>>[];

const initialState: Modals = [] as Modals;

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (
      _state,
      action: PayloadAction<Modal<React.ComponentType<any>>>,
    ) =>
      [
        { component: action.payload.component, props: action.payload.props },
      ] as Modals,
    hideModal: (_state) => initialState,
    pushModal: (
      state,
      action: PayloadAction<Modal<React.ComponentType<any>>>,
    ) => {
      state.push({
        component: action.payload.component,
        props: action.payload.props,
      });
    },
    popModal: (state) => {
      state.pop();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      settingsActionTypes.RESET_MAP_VIEW_STATE,
      () => initialState,
    );
  },
});

const { showModal, hideModal, pushModal, popModal } = modalsSlice.actions;

function stronglyTypedShowModal<C extends React.ComponentType<any>>(
  component: Modal<C>['component'],
  props: Modal<C>['props'] = {} as Modal<C>['props'],
): ReturnType<typeof showModal> {
  return showModal({ component, props });
}

function stronglyTypedPushModal<C extends React.ComponentType<any>>(
  component: Modal<C>['component'],
  props: Modal<C>['props'] = {} as Modal<C>['props'],
): ReturnType<typeof pushModal> {
  return pushModal({ component, props });
}

export {
  stronglyTypedShowModal as showModal,
  hideModal,
  stronglyTypedPushModal as pushModal,
  popModal,
};

export default modalsSlice.reducer;
