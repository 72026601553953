import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './Checkbox.scss';

const Checkbox = ({ onChange, value, looksDisabled = false, ...props }) => (
  <button
    data-testid="checkbox"
    className={classNames(
      'reskin-checkbox',
      {
        'reskin-checkbox--looks-disabled': looksDisabled,
      },
      {
        'reskin-checkbox--checked': value,
      },
    )}
    onClick={() => onChange(!value)}
    {...props}
  >
    <span />
  </button>
);

Checkbox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  looksDisabled: PropTypes.bool,
};

export default Checkbox;
