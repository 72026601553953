import { NonConfigurableIconProps } from '../types';

const PhotoGallery = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91667 2H3.25C2.55964 2 2 2.55964 2 3.25V9.91667C2 10.607 2.55964 11.1667 3.25 11.1667H9.91667C10.607 11.1667 11.1667 10.607 11.1667 9.91667V3.25C11.1667 2.55964 10.607 2 9.91667 2Z"
        fill="black"
      />
      <path
        d="M20.75 2H14.0833C13.393 2 12.8333 2.55964 12.8333 3.25V9.91667C12.8333 10.607 13.393 11.1667 14.0833 11.1667H20.75C21.4404 11.1667 22 10.607 22 9.91667V3.25C22 2.55964 21.4404 2 20.75 2Z"
        fill="black"
      />
      <path
        d="M9.91667 12.8333H3.25C2.55964 12.8333 2 13.393 2 14.0833V20.75C2 21.4404 2.55964 22 3.25 22H9.91667C10.607 22 11.1667 21.4404 11.1667 20.75V14.0833C11.1667 13.393 10.607 12.8333 9.91667 12.8333Z"
        fill="black"
      />
      <path
        d="M20.75 12.8333H14.0833C13.393 12.8333 12.8333 13.393 12.8333 14.0833V20.75C12.8333 21.4404 13.393 22 14.0833 22H20.75C21.4404 22 22 21.4404 22 20.75V14.0833C22 13.393 21.4404 12.8333 20.75 12.8333Z"
        fill="black"
      />
    </svg>
  );
};

export default PhotoGallery;
