import { StyleableOnlyIconProps } from '../types';

const Copy = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.25 4.42405H10.25C9.69772 4.42405 9.25 4.8491 9.25 5.37342V15.8165C9.25 16.3408 9.69772 16.7658 10.25 16.7658H18.25C18.8023 16.7658 19.25 16.3408 19.25 15.8165V5.37342C19.25 4.8491 18.8023 4.42405 18.25 4.42405ZM10.25 3C8.86929 3 7.75 4.06262 7.75 5.37342V15.8165C7.75 17.1273 8.86929 18.1899 10.25 18.1899H18.25C19.6307 18.1899 20.75 17.1273 20.75 15.8165V5.37342C20.75 4.06262 19.6307 3 18.25 3H10.25Z"
          fill="#1D1D1D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 8.93354C5.16421 8.93354 5.5 9.25233 5.5 9.64557V19.1392C5.5 19.7946 6.05964 20.326 6.75 20.326H14.75C15.1642 20.326 15.5 20.6447 15.5 21.038C15.5 21.4312 15.1642 21.75 14.75 21.75H6.75C5.23122 21.75 4 20.5811 4 19.1392V9.64557C4 9.25233 4.33579 8.93354 4.75 8.93354Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.25 3C8.86929 3 7.75 4.06262 7.75 5.37342V15.8165C7.75 17.1273 8.86929 18.1899 10.25 18.1899H18.25C19.6307 18.1899 20.75 17.1273 20.75 15.8165V5.37342C20.75 4.06262 19.6307 3 18.25 3H10.25Z"
          fill="#1D1D1D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.75 8.93354C5.16421 8.93354 5.5 9.25233 5.5 9.64557V19.1392C5.5 19.7946 6.05964 20.326 6.75 20.326H14.75C15.1642 20.326 15.5 20.6447 15.5 21.038C15.5 21.4312 15.1642 21.75 14.75 21.75H6.75C5.23122 21.75 4 20.5811 4 19.1392V9.64557C4 9.25233 4.33579 8.93354 4.75 8.93354Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Copy;
