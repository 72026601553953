const LandIdFull = () => (
  <svg
    width="92"
    height="40"
    viewBox="0 0 92 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7473_140249)">
      <path
        d="M51.8301 19.3948C51.8301 12.6145 51.8301 9.22348 53.1497 6.63376C54.3107 4.35604 56.1622 2.50283 58.4399 1.34351C61.0296 0.0239259 64.4207 0.0239258 71.201 0.0239258H72.4114C79.1918 0.0239258 82.5828 0.0239259 85.1725 1.34351C87.4502 2.50453 89.3034 4.35604 90.4628 6.63376C91.7823 9.22348 91.7823 12.6145 91.7823 19.3948V20.6053C91.7823 27.3856 91.7823 30.7766 90.4628 33.3664C89.3017 35.6441 87.4502 37.4973 85.1725 38.6566C82.5828 39.9762 79.1918 39.9762 72.4114 39.9762H71.201C64.4207 39.9762 61.0296 39.9762 58.4399 38.6566C56.1622 37.4956 54.309 35.6441 53.1497 33.3664C51.8301 30.7766 51.8301 27.3856 51.8301 20.6053V19.3948Z"
        fill="#1D1D1D"
      />
      <path
        d="M0 11.2114H4.30824V23.2905C4.30824 24.4908 4.75492 24.5624 6.0745 24.4447H6.38138V27.5766C5.62782 27.742 5.08567 27.8119 4.35598 27.8119C1.79013 27.8119 0 26.9646 0 24.2333V11.2114H0Z"
        fill="white"
      />
      <path
        d="M42.7397 11.2114V16.8154H42.692C42.056 15.9919 41.0672 15.5674 39.7493 15.5674C36.3822 15.5674 33.8862 18.4163 33.8862 21.618V22.0886C33.8862 25.7848 36.5697 28.0455 39.3487 28.0455C40.8217 28.0455 42.0083 27.6005 42.8352 26.6355L42.7755 25.8257L43.0858 25.6501L43.8121 27.6925H47.0479V11.2114H42.7397ZM40.6205 24.5146C39.1373 24.5146 38.3138 23.432 38.3138 21.7357C38.3138 20.0393 39.1373 18.9567 40.6205 18.9567C42.1038 18.9567 42.8573 19.9455 42.8573 21.7357C42.8573 23.5258 42.0339 24.5146 40.6205 24.5146Z"
        fill="white"
      />
      <path
        d="M18.2233 16.9809C17.0691 15.8267 15.516 15.5215 13.7259 15.5215C10.8531 15.5215 8.35717 16.957 8.35717 19.3353V19.6405H12.3125C12.3824 18.9807 12.6654 18.3686 13.7719 18.3686C14.8784 18.3686 15.1375 18.8869 15.1375 19.9457V20.4639C14.6908 20.3702 14.1725 20.3224 13.5605 20.3224C11.9835 20.3224 9.88816 20.6515 8.73395 21.8057C8.14576 22.3939 7.74512 23.1236 7.74512 24.1363V24.3715C7.74512 25.3842 8.1219 26.2554 8.75782 26.8914C9.62901 27.7626 10.8054 28.0456 11.9596 28.0456C13.4258 28.0456 14.6704 27.5546 15.3438 26.7959L15.2551 25.8122L15.5398 25.6502L16.2661 27.6927H19.2821V20.4639C19.2821 19.0284 19.0707 17.8265 18.2233 16.9792V16.9809ZM15.0676 23.4321C15.0676 24.3511 14.1487 24.9393 13.3951 24.9393C12.4762 24.9631 12.0056 24.5625 12.0056 23.8805C12.0056 22.9616 12.9007 22.7025 13.6304 22.7025C14.2663 22.7025 14.6891 22.7263 15.0659 22.89V23.4321H15.0676Z"
        fill="white"
      />
      <path
        d="M28.5212 15.5674C26.9732 15.5674 25.8599 16.0737 25.1643 17.0711L25.2154 17.7718L24.9733 17.915L24.2641 15.9203H20.9873V27.6925H25.2955V20.8645C25.2955 19.6642 25.8837 19.0743 26.8265 19.0743C27.7693 19.0743 28.3337 19.6625 28.3337 20.8645V27.6925H32.6419V19.6642C32.6419 17.4751 31.4178 15.5674 28.5212 15.5674Z"
        fill="white"
      />
      <path
        d="M89.2509 0.212992H88.811V-0.0495605H90.0096V0.212992H89.5697V1.4712H89.2509V0.212992Z"
        fill="#1D1D1D"
      />
      <path
        d="M90.1851 -0.0495605H90.5533L90.9846 1.04327H90.9915L91.4228 -0.0495605H91.7911V1.4712H91.5063V0.443151H91.3597L91.6785 -0.00693844L91.0887 1.4712H90.8892L90.2993 -0.00693844L90.6181 0.443151H90.4698V1.46949H90.1868V-0.0495605H90.1851Z"
        fill="#1D1D1D"
      />
      <path
        d="M84.2589 23.1965H83.2769C82.2826 23.1965 81.4766 24.0026 81.4766 24.9969V25.9789C81.4766 26.9732 82.2826 27.7793 83.2769 27.7793H84.2589C85.2532 27.7793 86.0593 26.9732 86.0593 25.9789V24.9969C86.0593 24.0026 85.2532 23.1965 84.2589 23.1965Z"
        fill="#F9213B"
      />
      <path
        d="M65.5818 13.0269C65.5818 14.4164 64.4038 15.17 62.9921 15.17C61.5805 15.17 60.4263 14.4164 60.4263 13.0269V12.8155C60.4263 11.4261 61.5805 10.6265 62.9921 10.6265C64.4038 10.6265 65.5818 11.4278 65.5818 12.8155V13.0269ZM60.8491 27.6958V15.9235H65.1573V27.6958H60.8491Z"
        fill="white"
      />
      <path
        d="M75.4751 11.218V16.822H75.4273C74.7914 15.9985 73.8026 15.574 72.4847 15.574C69.1175 15.574 66.6216 18.4229 66.6216 21.6246V22.0952C66.6216 25.7914 69.3051 28.052 72.084 28.052C73.5571 28.052 74.7437 27.6071 75.5705 26.6421L75.5109 25.8323L75.8211 25.6567L76.5474 27.6991H79.7833V11.218H75.4751ZM73.3559 24.5212C71.8726 24.5212 71.0492 23.4386 71.0492 21.7423C71.0492 20.0459 71.8726 18.9633 73.3559 18.9633C74.8391 18.9633 75.5927 19.9521 75.5927 21.7423C75.5927 23.5324 74.7692 24.5212 73.3559 24.5212Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7473_140249">
        <rect width="91.8063" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LandIdFull;
