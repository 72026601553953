import store from 'store';
import Cookies from 'cookies-js';
import redirectActionTypes from 'actions/redirect/actions';
import { ANNUAL_SCHEMA_SUFFIX, MONTHLY_SCHEMA_SUFFIX } from 'utils/payments';

export const MAPS_ROUTE = '/maps';
export const DASHBOARD_ROUTE = '/dashboard';
export const DISCOVER_ROUTE = '/discover';
export const PLANS_ROUTE = '/plans';
export const REPORTS_ROUTE = '/reports';
export const USERS_SIGN_IN_ROUTE = '/users/sign_in';
export const PROFILE_ROUTE = '/profile';
export const EMAIL_VERIFICATION_ROUTE = '/verify';
export const CONTACT_ROUTE = '/contact';
export const NEW_MAP_ROUTE = `${MAPS_ROUTE}/new`;

export const getPlanRoute = (planId, isAnnual = false) => {
  return `${PLANS_ROUTE}/${planId}/${isAnnual ? ANNUAL_SCHEMA_SUFFIX : MONTHLY_SCHEMA_SUFFIX}`;
};

export const isAuthenticated = () => {
  const { user } = store.getState();
  const { pathname } = window.location;
  if (!user.authenticated) {
    const redirect = !Cookies.get('userSignedInBefore');

    if (
      window.location.host === 'mapright.com' &&
      pathname === '/dashboard' &&
      redirect
    ) {
      store.dispatch({
        type: redirectActionTypes.SET_REDIRECT,
        url: '/users/sign_in',
      });
      window.location = 'https://id.land';
    } else {
      store.dispatch({
        type: redirectActionTypes.SET_REDIRECT,
        url: '/users/sign_in',
      });
    }
  }
};
