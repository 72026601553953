// extracted by mini-css-extract-plugin
var _1 = "#1d1d1d";
var _2 = "#fdbaba";
var _3 = "#ff7384";
var _4 = "#fb6a6a";
var _5 = "#f92121";
var _6 = "#b11717";
var _7 = "#a50d1f";
var _8 = "#3c1212";
var _9 = "#fafafa";
var _a = "#b9b9b9";
var _b = "#979797";
var _c = "#686868";
var _d = "#4a4a4a";
var _e = "#e8e8e8";
var _f = "#282828";
var _10 = "#1d1d1d";
var _11 = "#6ba6ff";
var _12 = "#0065ff";
var _13 = "#003e9c";
var _14 = "#f4f3f1";
var _15 = "#ebe5e1";
var _16 = "#dab2a2";
var _17 = "#f9213b";
var _18 = "#c9f2c8";
var _19 = "#8be489";
var _1a = "#52d64f";
var _1b = "#3a9838";
var _1c = "#fde7ba";
var _1d = "#fbc64a";
var _1e = "#f9b021";
var _1f = "#fff";
export { _1 as "black", _2 as "fail100", _3 as "fail200", _4 as "fail300", _5 as "fail500", _6 as "fail700", _7 as "fail800", _8 as "fail900", _9 as "grayScale00", _a as "grayScale100", _b as "grayScale200", _c as "grayScale300", _d as "grayScale400", _e as "grayScale50", _f as "grayScale500", _10 as "grayScale700", _11 as "links100", _12 as "links300", _13 as "links500", _14 as "marketingSecondary100", _15 as "marketingSecondary300", _16 as "marketingSecondary500", _17 as "red", _18 as "success100", _19 as "success300", _1a as "success500", _1b as "success700", _1c as "warning100", _1d as "warning300", _1e as "warning500", _1f as "white" }
