import { NonConfigurableIconProps } from '../types';

const Panorama = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.61 5.01693C20.96 4.95696 21.31 5.05691 21.58 5.30678C21.85 5.55666 22 5.92648 22 6.31629V17.6806C22 18.0704 21.85 18.4403 21.58 18.6901C21.36 18.89 21.09 19 20.81 19C20.7479 19 20.6937 18.9921 20.6333 18.9834C20.6257 18.9822 20.6179 18.9811 20.61 18.98C15.44 18.0205 8.56 18.0205 3.39 18.98C3.04 19.04 2.69 18.94 2.42 18.6901C2.15 18.4403 2 18.0704 2 17.6806V6.31629C2 5.92648 2.15 5.55666 2.42 5.30678C2.69 5.05691 3.04 4.95696 3.39 5.01693C8.56 5.97646 15.45 5.97646 20.61 5.01693ZM17.58 12.2433L20.53 16.0614H20.56V6.67611C15.39 7.59566 8.67 7.59566 3.5 6.67611V16.0614L6.45 12.2433C6.64 11.9935 7.01 11.9835 7.22 12.2233L8.53 13.7426L11.62 9.71459C11.82 9.45472 12.21 9.45472 12.41 9.71459L15.5 13.7426L16.81 12.2233C17.01 11.9835 17.39 11.9935 17.58 12.2433ZM7.22998 9.58509C7.22998 9.06509 7.64998 8.6551 8.15998 8.6551C8.66998 8.6551 9.08998 9.06509 9.08998 9.58509C9.08998 10.1051 8.67998 10.5151 8.15998 10.5151C7.63998 10.5151 7.22998 10.1051 7.22998 9.58509Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Panorama;
