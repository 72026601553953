export const actionTypes = {
  START_DRAWING: 'START_DRAWING',
  STOP_DRAWING: 'STOP_DRAWING',
  UPDATE_DRAWING_FEATURE: 'UPDATE_DRAWING_FEATURE',
  UPDATE_DRAWING_FEATURE_CENTER: 'UPDATE_DRAWING_FEATURE_CENTER',
};

export const startDrawing = (toolId, toolType, properties, persist) => ({
  type: actionTypes.START_DRAWING,
  toolId,
  toolType,
  properties,
  persist,
});

export const stopDrawing = () => ({ type: actionTypes.STOP_DRAWING });

export const updateDrawingFeature = (feature) => ({
  type: actionTypes.UPDATE_DRAWING_FEATURE,
  feature,
});

export const updateDrawingFeatureCenter = (center) => ({
  type: actionTypes.UPDATE_DRAWING_FEATURE_CENTER,
  center,
  meta: { debounce: { time: 10 } },
});

export const actions = {
  updateDrawingFeature,
  updateDrawingFeatureCenter,
  startDrawing,
  stopDrawing,
};
