import * as styles from './styles.module.scss';

interface ProgressBarProps {
  width?: number;
  height?: number;
  progress?: number;
  backgroundColor?: React.CSSProperties['backgroundColor'];
  fillColor?: React.CSSProperties['backgroundColor'];
}

const ProgressBar = ({
  width = 200,
  height = 20,
  progress = 0,
  backgroundColor,
  fillColor,
}: ProgressBarProps) => (
  <div
    className={styles.background}
    style={{ width: `${width}px`, height: `${height}px`, backgroundColor }}
  >
    <div
      className={styles.fill}
      style={{ width: `${progress}%`, backgroundColor: fillColor }}
    />
  </div>
);

export default ProgressBar;
