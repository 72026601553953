import actionTypes from '../actions/sharedMaps/actions';

const ACTION_HANDLERS = {
  [actionTypes.FETCH_SHARE_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    shared_emails: action.emails.map((email) => ({
      email,
      selected: false,
      status: 'Sent',
      sent: true,
    })),
  }),
  [actionTypes.ADD_SHARE_EMAIL]: (state, action) => {
    const emails = action.emails.map((email) => ({
      email,
      selected: true,
      status: 'Ready to send',
      sent: false,
    }));
    return {
      ...state,
      shared_emails: [
        ...state.shared_emails,
        ...emails.filter(
          ({ email }) =>
            !state.shared_emails.some((item) => item.email === email),
        ),
      ],
    };
  },
  [actionTypes.TOGGLE_SELECTED_EMAIL]: (state, action) => {
    const { email, selected } = action;
    const emailIndex = state.shared_emails.findIndex(
      (item) => item.email === email,
    );
    const sent = state.shared_emails[emailIndex].sent || false;
    let updatedEmail = {
      email,
      selected: !selected,
      status: 'Ready to send',
      sent,
    };
    if (selected) {
      updatedEmail = {
        ...updatedEmail,
        status:
          state.shared_emails[emailIndex].sent ? 'Sent' : 'Select to send',
      };
    }
    return {
      ...state,
      shared_emails: state.shared_emails
        .slice(0, emailIndex)
        .concat(updatedEmail)
        .concat(state.shared_emails.slice(emailIndex + 1)),
    };
  },
  [actionTypes.SELECT_ALL_EMAILS]: (state, action) => {
    const updatedArray = state.shared_emails.map((email) =>
      action.emails.some((item) => item.email === email.email) ?
        { ...email, selected: true, status: 'Ready to send' }
      : email,
    );

    return {
      ...state,
      shared_emails: updatedArray,
    };
  },
  [actionTypes.DESELECT_ALL_EMAILS]: (state, action) => {
    const updatedArray = state.shared_emails.map((email) =>
      action.emails.some((item) => item.email === email.email) ?
        {
          ...email,
          selected: false,
          status: email.sent ? 'Sent' : 'Select to send',
        }
      : email,
    );

    return {
      ...state,
      shared_emails: updatedArray,
    };
  },
  [actionTypes.REMOVE_SHARE_EMAIL]: (state, action) => ({
    ...state,
    shared_emails: state.shared_emails.filter((email) => !email.selected),
  }),
  [actionTypes.REMOVE_SINGLE_SHARE_EMAIL]: (state, action) => ({
    ...state,
    shared_emails: state.shared_emails.filter(
      (email) => action.email !== email.email,
    ),
  }),
  [actionTypes.MARK_EMAIL_SENT]: (state, action) => {
    const sentArray = state.shared_emails.map((email) =>
      action.emails.some((item) => item.customer_email === email.email) ?
        { ...email, status: 'Sent', selected: false, sent: true }
      : email,
    );

    return {
      ...state,
      shared_emails: sentArray,
    };
  },
};

const initialState = {
  custom_share_message: '',
  shared_emails: [],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
