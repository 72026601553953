import { connect } from 'react-redux';

import { setNewMapState } from 'actions/map/';
import { setUserDefaultState } from 'actions/user/';
import SelectStateModal from './presentation';

const mapStateToProps = (state) => ({
  defaultState: state.user.user.default_state,
});

const mapActionsToProps = {
  setNewMapState,
  setUserDefaultState,
};

export default connect(mapStateToProps, mapActionsToProps)(SelectStateModal);
