import { NonConfigurableIconProps } from '../types';

const Delete = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4167 8.25H5.91667C5.80616 8.25 5.70018 8.2939 5.62204 8.37204C5.5439 8.45018 5.5 8.55616 5.5 8.66667V20.3333C5.5 20.7754 5.67559 21.1993 5.98816 21.5118C6.30072 21.8244 6.72464 22 7.16667 22H17.1667C17.6087 22 18.0326 21.8244 18.3452 21.5118C18.6577 21.1993 18.8333 20.7754 18.8333 20.3333V8.66667C18.8333 8.55616 18.7894 8.45018 18.7113 8.37204C18.6332 8.2939 18.5272 8.25 18.4167 8.25Z"
        fill="#1D1D1D"
      />
      <path
        d="M20.5 5.33333H16.5417C16.4864 5.33333 16.4334 5.31138 16.3944 5.27231C16.3553 5.23324 16.3333 5.18025 16.3333 5.125V4.08333C16.3333 3.5308 16.1138 3.0009 15.7231 2.61019C15.3324 2.21949 14.8025 2 14.25 2H10.0833C9.5308 2 9.0009 2.21949 8.61019 2.61019C8.21949 3.0009 8 3.5308 8 4.08333V5.125C8 5.18025 7.97805 5.23324 7.93898 5.27231C7.89991 5.31138 7.84692 5.33333 7.79167 5.33333H3.83333C3.61232 5.33333 3.40036 5.42113 3.24408 5.57741C3.0878 5.73369 3 5.94565 3 6.16667C3 6.38768 3.0878 6.59964 3.24408 6.75592C3.40036 6.9122 3.61232 7 3.83333 7H20.5C20.721 7 20.933 6.9122 21.0893 6.75592C21.2455 6.59964 21.3333 6.38768 21.3333 6.16667C21.3333 5.94565 21.2455 5.73369 21.0893 5.57741C20.933 5.42113 20.721 5.33333 20.5 5.33333ZM9.66667 5.125V4.08333C9.66667 3.97283 9.71057 3.86685 9.78871 3.78871C9.86685 3.71057 9.97283 3.66667 10.0833 3.66667H14.25C14.3605 3.66667 14.4665 3.71057 14.5446 3.78871C14.6228 3.86685 14.6667 3.97283 14.6667 4.08333V5.125C14.6667 5.18025 14.6447 5.23324 14.6056 5.27231C14.5666 5.31138 14.5136 5.33333 14.4583 5.33333H9.875C9.81975 5.33333 9.76676 5.31138 9.72769 5.27231C9.68862 5.23324 9.66667 5.18025 9.66667 5.125Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Delete;
