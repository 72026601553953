import { NonConfigurableIconProps } from '../types';

const ArrowThickCircleRight = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM17.2583 12.6L12.6667 17.45C12.5109 17.6027 12.3015 17.6882 12.0833 17.6882C11.8652 17.6882 11.6558 17.6027 11.5 17.45L11.2583 17.2083C11.1084 17.0428 11.0254 16.8275 11.0254 16.6042C11.0254 16.3809 11.1084 16.1655 11.2583 16L13.175 13.85C13.4833 13.5083 13.3583 13.2333 12.9 13.2333H7.44167C7.22066 13.2333 7.00869 13.1455 6.85241 12.9893C6.69613 12.833 6.60834 12.621 6.60834 12.4V11.5667C6.60834 11.3457 6.69613 11.1337 6.85241 10.9774C7.00869 10.8211 7.22066 10.7333 7.44167 10.7333H12.8333C13.2917 10.7333 13.4083 10.4583 13.1 10.125L11.1667 7.98333C11.0132 7.81961 10.9277 7.60359 10.9277 7.37917C10.9277 7.15474 11.0132 6.93872 11.1667 6.775L11.4083 6.53333C11.5645 6.37812 11.7757 6.29101 11.9958 6.29101C12.216 6.29101 12.4272 6.37812 12.5833 6.53333L17.2583 11.3917C17.4137 11.5544 17.5004 11.7708 17.5004 11.9958C17.5004 12.2209 17.4137 12.4372 17.2583 12.6Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default ArrowThickCircleRight;
