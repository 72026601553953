import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { isProPlan } from 'selectors/userSelectors';
import Modal from 'components/Modal';
import Button from 'components/ui/Button';
import { PLANS_ROUTE } from 'utils/routes';

import './styles.scss';

const UpgradePlanModal = (props) => {
  const { onMap, onContinue, onClose, subscriptions, teamSub } = props;
  const navigate = useNavigate();

  const goToPricing = () => {
    onClose();
    navigate(`${PLANS_ROUTE}`);
  };

  const goToMap = () => {
    onClose();

    if (!onMap && !isProPlan(subscriptions)) {
      onContinue();
    }
  };

  if (teamSub) {
    return (
      <Modal
        header={{
          title: 'Map limit reached',
          icon: 'baseMap',
          iconVariant: 'filled',
        }}
        className="upgrade-plan-modal"
        wide
        {...props}
      >
        <main>
          <div className="upgrade-plan-modal__content">
            You have reached your limit of three maps. Delete a saved map to
            create a new map or contact the team owner to upgrade your plan.
          </div>
        </main>
        <Modal.Footer>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal
      header={{
        title: 'Upgrade your account',
        icon: 'baseMap',
        iconVariant: 'filled',
      }}
      className="upgrade-plan-modal"
      wide
      {...props}
    >
      <main>
        <div className="upgrade-plan-modal__content">
          You have reached your limit of three maps. Delete a saved map to
          create a new map or upgrade to the Land id™ Pro Unlimited plan.
        </div>
      </main>
      <Modal.Footer>
        <Button color="blue" onClick={goToPricing}>
          Upgrade Now
        </Button>
        <Button fontColor="fBlack" color="grayLightest" onClick={goToMap}>
          {onMap || isProPlan(subscriptions) ? 'Cancel' : 'Continue To New Map'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UpgradePlanModal.propTypes = {
  onMap: PropTypes.bool,
  onContinue: PropTypes.func,
  onClose: PropTypes.func,
  subscriptions: PropTypes.object,
  teamSub: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
});

export default connect(mapStateToProps)(UpgradePlanModal);
