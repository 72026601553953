import actionTypes from '../actions/flashMessages/actions';

const ACTION_HANDLERS = {
  [actionTypes.FLASH]: (_, action) => ({
    messageKey: action.key,
    options: action.options,
  }),
  [actionTypes.CLEAR_FLASH]: () => initialState,
};

const initialState = {
  messageKey: null,
  options: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
