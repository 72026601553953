import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const LockCircle = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill={color} />
      <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="white" />
      <path
        d="M15.5974 10.7847H15.2328V9.44794C15.2328 8.5777 14.8871 7.7431 14.2717 7.12774C13.6564 6.51239 12.8218 6.16669 11.9516 6.16669C11.0813 6.16669 10.2467 6.51239 9.63136 7.12774C9.016 7.7431 8.6703 8.5777 8.6703 9.44794V10.7847H8.30572C8.04787 10.7847 7.80058 10.8872 7.61825 11.0695C7.43593 11.2518 7.3335 11.4991 7.3335 11.757V16.8611C7.3335 17.119 7.43593 17.3663 7.61825 17.5486C7.80058 17.7309 8.04787 17.8334 8.30572 17.8334H15.5974C15.8552 17.8334 16.1025 17.7309 16.2849 17.5486C16.4672 17.3663 16.5696 17.119 16.5696 16.8611V11.757C16.5696 11.4991 16.4672 11.2518 16.2849 11.0695C16.1025 10.8872 15.8552 10.7847 15.5974 10.7847ZM10.9793 13.7014C10.9793 13.4436 11.0818 13.1963 11.2641 13.0139C11.4464 12.8316 11.6937 12.7292 11.9516 12.7292C12.2094 12.7292 12.4567 12.8316 12.639 13.0139C12.8213 13.1963 12.9238 13.4436 12.9238 13.7014C12.9227 13.8711 12.8773 14.0375 12.7921 14.1842C12.7068 14.3309 12.5846 14.4527 12.4377 14.5375V15.6459C12.4377 15.7748 12.3864 15.8984 12.2953 15.9896C12.2041 16.0807 12.0805 16.132 11.9516 16.132C11.8226 16.132 11.699 16.0807 11.6078 15.9896C11.5167 15.8984 11.4654 15.7748 11.4654 15.6459V14.5375C11.3185 14.4527 11.1963 14.3309 11.111 14.1842C11.0258 14.0375 10.9804 13.8711 10.9793 13.7014ZM9.88558 9.44794C9.88558 8.90001 10.1032 8.37452 10.4907 7.98707C10.8781 7.59963 11.4036 7.38196 11.9516 7.38196C12.4995 7.38196 13.025 7.59963 13.4124 7.98707C13.7999 8.37452 14.0175 8.90001 14.0175 9.44794V10.5417C14.0175 10.6061 13.9919 10.668 13.9463 10.7136C13.9008 10.7591 13.8389 10.7847 13.7745 10.7847H10.1286C10.0642 10.7847 10.0024 10.7591 9.95677 10.7136C9.91119 10.668 9.88558 10.6061 9.88558 10.5417V9.44794Z"
        fill="white"
      />
    </svg>
  );
};

export default LockCircle;
