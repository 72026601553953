import { Experiment } from '@amplitude/experiment-js-client';
import * as amplitude from '@amplitude/analytics-browser';
import _ from 'lodash';

class Amplitude {
  static instance;
  experiment;

  constructor() {
    if (Amplitude.instance) {
      return Amplitude.instance;
    }

    amplitude.init(process.env.AMPLITUDE_KEY, {
      defaultTracking: true,
      minIdLength: 1,
    });
    Amplitude.instance = this;
    this.initializeFeatureFlags();
  }

  async initializeFeatureFlags() {
    try {
      this.experiment = Experiment.initializeWithAmplitudeAnalytics(
        process.env.AMPLITUDE_KEY,
      );

      await this.experiment.start();
      this.getAllFeatureFlags();
    } catch (error) {
      console.error('Error initializing Amplitude Experiment:', error);
    }
  }

  getAllFeatureFlags() {
    try {
      const allFlags = this.experiment.all();
      const featureFlags = {};

      for (const [name, { value, payload }] of Object.entries(allFlags)) {
        const camelCaseKey = _.camelCase(name);
        let flagValue;
        if (value === 'on') {
          flagValue = true;
        } else if (value === 'off' || value === undefined) {
          flagValue = false;
        } else {
          flagValue = value;
        }
        featureFlags[camelCaseKey] = { value: flagValue, payload };
      }

      return featureFlags;
    } catch (error) {
      return {};
    }
  }

  async identifyUser(userId) {
    await Promise.all([
      this.experiment.fetch({ userId }),
      amplitude.setUserId(userId),
    ]);
  }
}

export default Amplitude;
