import store from 'store';
import get from 'lodash/get';
import actionTypes from 'actions/redirect/actions';
import { isEmbeddedInIframe } from './domains';

export const makeRequest = (url, options = {}) => {
  options.headers = options.headers || {};
  options.method = options.method || 'GET';

  if (typeof options.body === 'string') {
    options.headers['Content-Type'] = 'application/json';
  }

  options.headers['X-Embedded'] = isEmbeddedInIframe();

  let timeoutId;

  if (options.timeout) {
    const abortController = new AbortController();
    timeoutId = setTimeout(() => abortController.abort(), options.timeout);

    options.signal = abortController.signal;
  }

  return fetch(url, options)
    .then(checkStatus)
    .then((response) => {
      if (timeoutId) clearTimeout(timeoutId);

      if (response.status === 204) {
        return {};
      }

      return options.blob ? response.blob() : response.json();
    });
};

export const makeAuthenticatedRequest = (url, options = {}) => {
  const state = store.getState();

  options.headers = options.headers || {};
  options.headers['X-Auth-Email'] = get(state, 'user.user.email');
  options.headers['X-Auth-Token'] = get(
    state,
    'user.user.authentication_token',
  );

  const { selectedAccount } = state.user;

  if (selectedAccount?.type === 'organizations') {
    options.headers['Team-Id'] = selectedAccount.id;
  }

  return makeRequest(url, options);
};

export const waitForRequest = (
  request,
  checkCompletion,
  intervalTime = 1000,
) => {
  const requestPromise = new Promise((resolve, reject) => {
    const INTERVAL_TIME = intervalTime || 1000;

    request()
      .then((data) => {
        const interval = setInterval(() => {
          checkCompletion(data.job_id)
            .then((meta) => {
              if (meta.ready) {
                if (meta.status === 'failed') {
                  reject(meta);
                } else {
                  resolve(meta);
                }
                clearInterval(interval);
              }
            })
            .catch((error) => {
              reject(error);
              clearInterval(interval);
            });
        }, INTERVAL_TIME);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return requestPromise;
};

const checkStatus = (response) => {
  if (response.ok) {
    return Promise.resolve(response);
  } else if (response.status === 401) {
    store.dispatch({ type: 'LOGOUT' });
    store.dispatch({ type: actionTypes.SET_REDIRECT, url: '/users/sign_in' });
    return Promise.reject(new Error('Expired token'));
  }

  return response.json().then((json) => {
    if (json.message) {
      return Promise.reject(json);
    }
    const error = new Error(response.statusText || 'API Error', {
      cause: { status: response.status },
    });
    return Promise.reject(Object.assign(error, { json }));
  });
};
