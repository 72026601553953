// extracted by mini-css-extract-plugin
var _1 = "styles-module__background___rYbkB";
var _2 = "#1d1d1d";
var _3 = "#fdbaba";
var _4 = "#ff7384";
var _5 = "#fb6a6a";
var _6 = "#f92121";
var _7 = "#b11717";
var _8 = "#a50d1f";
var _9 = "#3c1212";
var _a = "styles-module__fill___fLTjp";
var _b = "#fafafa";
var _c = "#b9b9b9";
var _d = "#979797";
var _e = "#686868";
var _f = "#4a4a4a";
var _10 = "#e8e8e8";
var _11 = "#282828";
var _12 = "#1d1d1d";
var _13 = "#6ba6ff";
var _14 = "#0065ff";
var _15 = "#003e9c";
var _16 = "#f4f3f1";
var _17 = "#ebe5e1";
var _18 = "#dab2a2";
var _19 = "#f9213b";
var _1a = "#c9f2c8";
var _1b = "#8be489";
var _1c = "#52d64f";
var _1d = "#3a9838";
var _1e = "#fde7ba";
var _1f = "#fbc64a";
var _20 = "#f9b021";
var _21 = "#fff";
export { _1 as "background", _2 as "black", _3 as "fail100", _4 as "fail200", _5 as "fail300", _6 as "fail500", _7 as "fail700", _8 as "fail800", _9 as "fail900", _a as "fill", _b as "grayScale00", _c as "grayScale100", _d as "grayScale200", _e as "grayScale300", _f as "grayScale400", _10 as "grayScale50", _11 as "grayScale500", _12 as "grayScale700", _13 as "links100", _14 as "links300", _15 as "links500", _16 as "marketingSecondary100", _17 as "marketingSecondary300", _18 as "marketingSecondary500", _19 as "red", _1a as "success100", _1b as "success300", _1c as "success500", _1d as "success700", _1e as "warning100", _1f as "warning300", _20 as "warning500", _21 as "white" }
