import * as colors from '../../styles/colors.module.scss';
import { IconProps } from '../types';

const Eye = ({ fill = colors.black, width = 18, height = 19 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.75C14.2448 15.75 16.0646 13.9591 16.0646 11.75C16.0646 9.54086 14.2448 7.75 12 7.75C9.7552 7.75 7.93542 9.54086 7.93542 11.75C7.93542 13.9591 9.7552 15.75 12 15.75ZM12 14.25C13.403 14.25 14.5404 13.1307 14.5404 11.75C14.5404 10.3693 13.403 9.25 12 9.25C10.597 9.25 9.45964 10.3693 9.45964 11.75C9.45964 13.1307 10.597 14.25 12 14.25Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5C16.4895 5 19.9917 8.64506 21.3672 10.3245C21.7826 10.8318 21.8602 11.5176 21.551 12.0958C20.5098 14.0435 17.5827 18.3333 12 18.3333C6.41731 18.3333 3.49022 14.0435 2.44895 12.0958C2.13983 11.5176 2.21743 10.8318 2.63284 10.3245C4.00825 8.64506 7.51052 5 12 5ZM3.8559 11.5432C3.7866 11.4181 3.80187 11.2635 3.89354 11.1538C4.33758 10.622 4.98521 9.9139 5.79626 9.20927C7.45048 7.77212 9.6087 6.5 12 6.5C14.3913 6.5 16.5495 7.77212 18.2037 9.20927C19.0148 9.9139 19.6624 10.622 20.1065 11.1538C20.1981 11.2635 20.2134 11.4181 20.1441 11.5432C20.1432 11.5448 20.1423 11.5465 20.1413 11.5482C19.8075 12.1501 19.2957 12.9509 18.5878 13.7464C17.176 15.3329 15.0535 16.8333 12 16.8333C8.94647 16.8333 6.82397 15.3329 5.41223 13.7464C4.70426 12.9509 4.19248 12.1501 3.85866 11.5482C3.85774 11.5465 3.85682 11.5448 3.8559 11.5432Z"
      fill={fill}
    />
  </svg>
);

export default Eye;
