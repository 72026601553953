import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Portrait = ({ fill = colors.black }: IconProps) => (
  <svg
    width="42"
    height="51"
    viewBox="0 0 42 51"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M26.8153 1.06675C26.7193 0.970707 26.589 0.916748 26.4532 0.916748H4.4306C3.34395 0.916748 2.3018 1.34842 1.53342 2.1168C0.765044 2.88517 0.333374 3.92732 0.333374 5.01397V45.9862C0.333374 47.0728 0.765044 48.115 1.53342 48.8834C2.3018 49.6517 3.34395 50.0834 4.4306 50.0834H37.2084C38.295 50.0834 39.3372 49.6517 40.1055 48.8834C40.8739 48.115 41.3056 47.0728 41.3056 45.9862V15.7692C41.3056 15.6333 41.2516 15.5031 41.1556 15.407L26.8153 1.06675ZM24.9167 4.60425H4.4306C4.32193 4.60425 4.21772 4.64742 4.14088 4.72425C4.06404 4.80109 4.02087 4.9053 4.02087 5.01397V45.9862C4.02087 46.0949 4.06404 46.1991 4.14088 46.2759C4.21772 46.3527 4.32193 46.3959 4.4306 46.3959H37.2084C37.317 46.3959 37.4213 46.3527 37.4981 46.2759C37.5749 46.1991 37.6181 46.0949 37.6181 45.9862V18.1251H28.835C27.7958 18.1251 26.7992 17.7121 26.0644 16.9769C25.3295 16.2417 24.9167 15.2445 24.9167 14.2048V4.60425Z"
      fill={fill}
    />
  </svg>
);

export default Portrait;
