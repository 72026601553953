/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Locked = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1391 8.26373H14.6182V6.354C14.6182 5.1108 14.1244 3.91852 13.2453 3.03944C12.3662 2.16036 11.1739 1.6665 9.93072 1.6665C8.68752 1.6665 7.49523 2.16036 6.61616 3.03944C5.73708 3.91852 5.24322 5.1108 5.24322 6.354V8.26373H4.72238C4.35403 8.26373 4.00076 8.41006 3.74029 8.67052C3.47982 8.93099 3.3335 9.28426 3.3335 9.65262V16.9443C3.3335 17.3126 3.47982 17.6659 3.74029 17.9264C4.00076 18.1868 4.35403 18.3332 4.72238 18.3332H15.1391C15.5074 18.3332 15.8607 18.1868 16.1211 17.9264C16.3816 17.6659 16.5279 17.3126 16.5279 16.9443V9.65262C16.5279 9.28426 16.3816 8.93099 16.1211 8.67052C15.8607 8.41006 15.5074 8.26373 15.1391 8.26373ZM8.54183 12.4304C8.54183 12.062 8.68816 11.7088 8.94863 11.4483C9.20909 11.1878 9.56236 11.0415 9.93072 11.0415C10.2991 11.0415 10.6523 11.1878 10.9128 11.4483C11.1733 11.7088 11.3196 12.062 11.3196 12.4304C11.3181 12.6728 11.2533 12.9106 11.1314 13.1201C11.0096 13.3296 10.8351 13.5036 10.6252 13.6248V15.2082C10.6252 15.3923 10.552 15.569 10.4218 15.6992C10.2915 15.8295 10.1149 15.9026 9.93072 15.9026C9.74654 15.9026 9.56991 15.8295 9.43967 15.6992C9.30944 15.569 9.23627 15.3923 9.23627 15.2082V13.6248C9.02636 13.5036 8.85182 13.3296 8.72999 13.1201C8.60816 12.9106 8.54329 12.6728 8.54183 12.4304ZM6.97933 6.354C6.97933 5.57125 7.29028 4.82055 7.84377 4.26706C8.39726 3.71356 9.14796 3.40262 9.93072 3.40262C10.7135 3.40262 11.4642 3.71356 12.0177 4.26706C12.5712 4.82055 12.8821 5.57125 12.8821 6.354V7.9165C12.8821 8.00859 12.8455 8.09691 12.7804 8.16203C12.7153 8.22714 12.627 8.26373 12.5349 8.26373H7.32655C7.23446 8.26373 7.14615 8.22714 7.08103 8.16203C7.01591 8.09691 6.97933 8.00859 6.97933 7.9165V6.354Z"
      fill={fill}
    />
  </svg>
);

Locked.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Locked.defaultProps = {
  fill: '#6BA6FF',
  width: '20',
  height: '20',
};

export default Locked;
