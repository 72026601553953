import { IconProps } from '../types';
import * as colors from '../../styles/colors.module.scss';

const Layers = ({ fill = colors.black }: IconProps) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8.99996 0.333374C8.72918 0.333374 8.46233 0.398209 8.22174 0.522456L1.24034 4.1318C1.06745 4.22134 0.922498 4.35667 0.821319 4.52301C0.720139 4.68935 0.666626 4.8803 0.666626 5.075C0.666626 5.2697 0.72014 5.46065 0.821319 5.62699C0.922498 5.79333 1.06772 5.9288 1.2406 6.01833L2.88883 6.87094L1.24034 7.72371C1.06745 7.81324 0.922498 7.94857 0.821319 8.11491C0.72014 8.28126 0.666626 8.47221 0.666626 8.6669C0.666626 8.8616 0.72014 9.05255 0.821319 9.21889C0.922498 9.38523 1.06772 9.5207 1.2406 9.61024L8.22174 13.2215C8.46233 13.3457 8.72917 13.4106 8.99996 13.4106C9.27074 13.4106 9.53798 13.3455 9.77857 13.2213L16.7596 9.6101C16.9325 9.52056 17.0774 9.38523 17.1786 9.21889C17.2798 9.05255 17.3333 8.8616 17.3333 8.6669C17.3333 8.4722 17.2798 8.28125 17.1786 8.11491C17.0774 7.94857 16.9322 7.81311 16.7593 7.72357L15.1141 6.87251L16.7596 6.0223C16.9325 5.93276 17.0774 5.79743 17.1786 5.63109C17.2798 5.46475 17.3333 5.2738 17.3333 5.0791C17.3333 4.8844 17.2798 4.69345 17.1786 4.52711C17.0954 4.39035 16.9826 4.27454 16.8491 4.18792C16.821 4.1669 16.791 4.14803 16.7592 4.1316L9.77818 0.522456C9.53759 0.398209 9.27074 0.333374 8.99996 0.333374ZM13.4441 6.29248C13.4301 6.29906 13.4164 6.30615 13.403 6.31373L9.18997 8.49058C9.13123 8.52092 9.06607 8.53675 8.99996 8.53675C8.93384 8.53675 8.86869 8.52092 8.80994 8.49058L2.20719 5.07505L8.80994 1.66147C8.86869 1.63113 8.93384 1.6153 8.99996 1.6153C9.06608 1.6153 9.13123 1.63113 9.18997 1.66147L15.7965 5.077L13.4441 6.29248ZM13.7183 7.59373L15.7928 8.6669L9.18997 12.0825C9.13123 12.1128 9.06607 12.1287 8.99996 12.1287C8.93385 12.1287 8.86869 12.1128 8.80994 12.0825L2.20708 8.6669L4.28387 7.59258L8.22174 9.62959C8.46233 9.75384 8.72918 9.81868 8.99996 9.81868C9.27074 9.81868 9.53771 9.75377 9.77831 9.62953L13.7183 7.59373Z"
      fill={fill}
    />
  </svg>
);

export default Layers;
