import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Search = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3408 2.59958C11.3111 1.78055 9.03916 1.80137 7.02478 2.65746C5.01041 3.51355 3.41861 5.13478 2.59958 7.16451C1.78055 9.19423 1.80137 11.4662 2.65746 13.4806C3.51355 15.4949 5.13478 17.0867 7.16451 17.9058C9.19423 18.7248 11.4662 18.704 13.4806 17.8479C14.1472 17.5646 14.7675 17.1975 15.3289 16.7595L20.273 21.7044C20.668 22.0995 21.3085 22.0995 21.7036 21.7045C22.0987 21.3094 22.0988 20.6689 21.7037 20.2738L16.7595 15.3289C17.2283 14.7281 17.6155 14.0601 17.9058 13.3408C18.7248 11.3111 18.704 9.03916 17.8479 7.02478C16.9918 5.01041 15.3706 3.41861 13.3408 2.59958ZM7.81615 4.51955C9.33667 3.87334 11.0516 3.85763 12.5837 4.47586C14.1158 5.0941 15.3396 6.29563 15.9858 7.81615C16.632 9.33667 16.6477 11.0516 16.0295 12.5837C15.7175 13.3569 15.257 14.0515 14.6819 14.633C14.6735 14.6409 14.6653 14.6488 14.6571 14.6569C14.6489 14.6651 14.6409 14.6734 14.6331 14.6818C14.0786 15.2302 13.4208 15.6749 12.6892 15.9858C11.1687 16.632 9.45373 16.6477 7.92162 16.0295C6.38952 15.4113 5.16575 14.2097 4.51955 12.6892C3.87334 11.1687 3.85763 9.45373 4.47586 7.92162C5.0941 6.38952 6.29563 5.16575 7.81615 4.51955Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.316 14.8994L21.5348 19.1681C21.8384 19.4846 22.0052 19.9079 21.9989 20.3464C21.9927 20.7849 21.8139 21.2033 21.5014 21.511C21.3466 21.666 21.1627 21.789 20.9602 21.8729C20.7578 21.9568 20.5408 22 20.3217 22C20.1025 22 19.8856 21.9568 19.6831 21.8729C19.4807 21.789 19.2968 21.666 19.1419 21.511L14.9732 17.2672C13.2558 18.4161 11.1729 18.8864 9.12839 18.5867C7.08392 18.2871 5.22355 17.2389 3.90803 15.6455C2.5925 14.052 1.91555 12.0269 2.00843 9.96263C2.10132 7.8984 2.95743 5.94221 4.41076 4.47337C5.86409 3.00452 7.81108 2.12769 9.8742 2.01289C11.9373 1.89808 13.9696 2.55349 15.5769 3.85202C17.1842 5.15056 18.2521 6.99969 18.5734 9.04086C18.8947 11.082 18.4467 13.1698 17.316 14.8994ZM14.1682 4.62024C13.0369 3.86406 11.7067 3.46044 10.346 3.46044C8.52382 3.46264 6.77682 4.18689 5.48759 5.47456C4.19837 6.76222 3.472 8.50834 3.46759 10.3305C3.46594 11.6912 3.86795 13.0219 4.62275 14.1542C5.37755 15.2864 6.45123 16.1693 7.70794 16.6912C8.96465 17.2131 10.3479 17.3505 11.6827 17.086C13.0176 16.8216 14.244 16.1671 15.2067 15.2055C16.1695 14.2439 16.8255 13.0183 17.0915 11.6838C17.3576 10.3493 17.2219 8.96585 16.7015 7.7085C16.1812 6.45116 15.2996 5.37641 14.1682 4.62024ZM15.9737 10.3388C15.9737 13.4469 13.4541 15.9666 10.346 15.9666C7.23784 15.9666 4.71821 13.4469 4.71821 10.3388C4.71821 7.23069 7.23784 4.71106 10.346 4.71106C13.4541 4.71106 15.9737 7.23069 15.9737 10.3388Z"
          fill={color}
        />
      </svg>;
};

export default Search;
