import _360 from './360';
import Add from './Add';
import AddBoundary from './AddBoundary';
import Address from './Address';
import Admin from './Admin';
import Aerial from './Aerial';
import Alert from './Alert';
import AppleLogo from './AppleLogo';
import Area from './Area';
import ArrowButtonRight from './ArrowButtonRight';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowThickCircleRight from './ArrowThickCircleRight';
import ArrowThickCircleUp from './ArrowThickCircleUp';
import Boundaries from './Boundaries';
import Boundary from './Boundary';
import Brand from './Brand';
import Buffer from './Buffer';
import Calendar from './Calendar';
import Camera from './Camera';
import Car from './Car';
import CenterMap from './CenterMap';
import CheckCircle from './CheckCircle';
import Checkmark from './Checkmark';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Circle from './Circle';
import Clock from './Clock';
import Close from './Close';
import CloseCircle from './CloseCircle';
import CloseCircleOutlined from './CloseCircleOutlined';
import CloudCheck from './CloudCheck';
import CloudDownload from './CloudDownload';
import Company from './Company';
import CompassDirection from './CompassDirection';
import CompassDirections from './CompassDirections';
import CompassWebControl from './CompassWebControl';
import Contact from './Contact';
import Convert from './Convert';
import Copy from './Copy';
import Crop from './Crop';
import CSV from './CSV';
import Cut from './Cut';
import Database from './Database';
import DeedArch from './DeedArch';
import DeedDrawer from './DeedDrawer';
import Delete from './Delete';
import DeleteMarker from './DeleteMarker';
import Devices from './Devices';
import Discover from './Discover';
import Document from './Document';
import DocumentHorizontal from './DocumentHorizontal';
import DocumentVertical from './DocumentVertical';
import Download from './Download';
import Draw from './Draw';
import Duplicate from './Duplicate';
import Expand from './Expand';
import Explore from './Explore';
import Export from './Export';
import Eye from './Eye';
import EyeSlash from './EyeSlash';
import Favorites from './Favorites';
import FeaturePoint from './FeaturePoint';
import Filter from './Filter';
import Folder from './Folder';
import FolderEmpty from './FolderEmpty';
import Frame from './Frame';
import FreeDraw from './FreeDraw';
import Georeference from './Georeference';
import GooglePlay from './GooglePlay';
import HandGesture from './HandGesture';
import Hazard from './Hazard';
import Helper from './Helper';
import HexSatellite from './HexSatellite';
import Highlight from './Highlight';
import Home from './Home';
import Image from './Image';
import ImageMissing from './ImageMissing';
import Import from './Import';
import Information from './Information';
import Infrared from './Infrared';
import Infrastructure from './Infrastructure';
import Landscape from './Landscape';
import LaptopComputer from './LaptopComputer';
import Latlong from './Latlong';
import Layers from './Layers';
import LayersStacked from './LayersStacked';
import Line from './Line';
import Listings from './Listings';
import LocationTarget from './LocationTarget';
import Lock from './Lock';
import LockCircle from './LockCircle';
import Logout from './Logout';
import MailEnvelope from './MailEnvelope';
import MapCheck from './MapCheck';
import MapError from './MapError';
import MapLegend from './MapLegend';
import MapSaved from './MapSaved';
import MapWarning from './MapWarning';
import Menu from './Menu';
import MenuCollapse from './MenuCollapse';
import MenuExpand from './MenuExpand';
import Merge from './Merge';
import ModernTopo from './ModernTopo';
import Move from './Move';
import MyMaps from './MyMaps';
import NavigationMenu from './NavigationMenu';
import NavigationMenuEllipsesHorizontal from './NavigationMenuEllipsesHorizontal';
import NavigationMenuEllipsesVertical from './NavigationMenuEllipsesVertical';
import OilGas from './OilGas';
import OpenStreet from './OpenStreet';
import Panorama from './Panorama';
import ParcelOverview from './ParcelOverview';
import ParcelId from './ParcelId';
import PencilEdit from './PencilEdit';
import Phone from './Phone';
import PhotoGallery from './PhotoGallery';
import Pin from './Pin';
import PinAdd from './PinAdd';
import PinLocation from './PinLocation';
import PinMarker from './PinMarker';
import Plus from './Plus';
import Postcard from './Postcard';
import Print from './Print';
import Profile from './Profile';
import ProfileImage from './ProfileImage';
import PropertyInfo from './PropertyInfo';
import Radius from './Radius';
import Ranching from './Ranching';
import Regional from './Regional';
import RemoveBadge from './RemoveBadge';
import RemoveCircle from './RemoveCircle';
import Reports from './Reports';
import RoadLine from './RoadLine';
import Rotate from './Rotate';
import Ruler from './Ruler';
import Save from './Save';
import Search from './Search';
import SEO from './SEO';
import Settings from './Settings';
import Shapes from './Shapes';
import Share from './Share';
import SharedMaps from './SharedMaps';
import SoilReport from './SoilReport';
import Soils from './Soils';
import Sort from './Sort';
import Spring from './Spring';
import Square from './Square';
import StartOver from './StartOver';
import Subtract from './Subtract';
import Success from './Success';
import Sync from './Sync';
import TextLabel from './TextLabel';
import TextLabelBackground from './TextLabelBackground';
import Tips from './Tips';
import Tool from './Tool';
import Track from './Track';
import TrimBoundary from './TrimBoundary';
import TripDestination from './TripDestination';
import TripPin from './TripPin';
import Undo from './Undo';
import Unlock from './Unlock';
import Upload from './Upload';
import Urban from './Urban';
import User from './User';
import VintageTopo from './VintageTopo';
import Water from './Water';
import Waypoint from './Waypoint';
import Wifi from './Wifi';

import { UIIconProps } from '../types';

const icons: { [key: string]: (props: UIIconProps) => JSX.Element } = {
  _360,
  add: Add,
  addBoundary: AddBoundary,
  address: Address,
  admin: Admin,
  aerial: Aerial,
  alert: Alert,
  appleLogo: AppleLogo,
  area: Area,
  arrowButtonRight: ArrowButtonRight,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowThickCircleRight: ArrowThickCircleRight,
  arrowThickCircleUp: ArrowThickCircleUp,
  boundaries: Boundaries,
  boundary: Boundary,
  brand: Brand,
  buffer: Buffer,
  calendar: Calendar,
  camera: Camera,
  car: Car,
  centerMap: CenterMap,
  checkCircle: CheckCircle,
  checkmark: Checkmark,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  circle: Circle,
  clock: Clock,
  close: Close,
  closeCircle: CloseCircle,
  closeCircleOutlined: CloseCircleOutlined,
  cloudCheck: CloudCheck,
  cloudDownload: CloudDownload,
  company: Company,
  compassDirection: CompassDirection,
  compassDirections: CompassDirections,
  compassWebControl: CompassWebControl,
  contact: Contact,
  convert: Convert,
  copy: Copy,
  crop: Crop,
  csv: CSV,
  cut: Cut,
  database: Database,
  deedArch: DeedArch,
  deedDrawer: DeedDrawer,
  delete: Delete,
  deleteMarker: DeleteMarker,
  devices: Devices,
  discover: Discover,
  document: Document,
  documentHorizontal: DocumentHorizontal,
  documentVertical: DocumentVertical,
  download: Download,
  draw: Draw,
  duplicate: Duplicate,
  expand: Expand,
  eye: Eye,
  eyeSlash: EyeSlash,
  explore: Explore,
  export: Export,
  favorites: Favorites,
  featurePoint: FeaturePoint,
  filter: Filter,
  folder: Folder,
  folderEmpty: FolderEmpty,
  frame: Frame,
  freeDraw: FreeDraw,
  georeference: Georeference,
  googlePlay: GooglePlay,
  handGesture: HandGesture,
  hazard: Hazard,
  helper: Helper,
  hexSatellite: HexSatellite,
  highlight: Highlight,
  home: Home,
  image: Image,
  imageMissing: ImageMissing,
  import: Import,
  information: Information,
  infrared: Infrared,
  infrastructure: Infrastructure,
  laptopComputer: LaptopComputer,
  landscape: Landscape,
  latlong: Latlong,
  layers: Layers,
  layersStacked: LayersStacked,
  line: Line,
  listings: Listings,
  locationTarget: LocationTarget,
  lock: Lock,
  lockCircle: LockCircle,
  logout: Logout,
  mailEnvelope: MailEnvelope,
  mapCheck: MapCheck,
  mapError: MapError,
  mapLegend: MapLegend,
  mapSaved: MapSaved,
  mapWarning: MapWarning,
  menu: Menu,
  menuCollapse: MenuCollapse,
  menuExpand: MenuExpand,
  merge: Merge,
  modernTopo: ModernTopo,
  move: Move,
  myMaps: MyMaps,
  navigationMenu: NavigationMenu,
  navigationMenuEllipsesHorizontal: NavigationMenuEllipsesHorizontal,
  navigationMenuEllipsesVertical: NavigationMenuEllipsesVertical,
  oilGas: OilGas,
  openStreet: OpenStreet,
  panorama: Panorama,
  parcelOverview: ParcelOverview,
  parcelId: ParcelId,
  pencilEdit: PencilEdit,
  phone: Phone,
  photoGallery: PhotoGallery,
  pin: Pin,
  pinAdd: PinAdd,
  pinLocation: PinLocation,
  pinMarker: PinMarker,
  plus: Plus,
  postcard: Postcard,
  print: Print,
  profile: Profile,
  profileImage: ProfileImage,
  propertyInfo: PropertyInfo,
  radius: Radius,
  ranching: Ranching,
  regional: Regional,
  removeBadge: RemoveBadge,
  removeCircle: RemoveCircle,
  reports: Reports,
  roadLine: RoadLine,
  rotate: Rotate,
  ruler: Ruler,
  seo: SEO,
  save: Save,
  search: Search,
  settings: Settings,
  share: Share,
  sharedMaps: SharedMaps,
  shapes: Shapes,
  soilReport: SoilReport,
  soils: Soils,
  sort: Sort,
  spring: Spring,
  square: Square,
  startOver: StartOver,
  subtract: Subtract,
  success: Success,
  sync: Sync,
  textLabel: TextLabel,
  textLabelBackground: TextLabelBackground,
  tips: Tips,
  tool: Tool,
  track: Track,
  trimBoundary: TrimBoundary,
  tripDestination: TripDestination,
  tripPin: TripPin,
  undo: Undo,
  unlock: Unlock,
  upload: Upload,
  urban: Urban,
  user: User,
  vintageTopo: VintageTopo,
  water: Water,
  waypoint: Waypoint,
  wifi: Wifi,
};

export default icons;
