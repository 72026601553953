import actionTypes from '../actions/toolEdit/actions';

const ACTION_HANDLERS = {
  [actionTypes.EDIT_TOOL_INSTANCE]: (state, { feature }) => ({
    ...state,
    feature,
  }),
};

const initialState = {
  tool: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
