import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Landscape = ({ fill = colors.black }: IconProps) => (
  <svg
    width="50"
    height="42"
    viewBox="0 0 50 42"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M0.566753 15.1431C0.470706 15.2391 0.416747 15.3694 0.416747 15.5052L0.416748 37.5278C0.416748 38.6144 0.848418 39.6566 1.6168 40.425C2.38517 41.1933 3.42732 41.625 4.51397 41.625L45.4862 41.625C46.5728 41.625 47.615 41.1933 48.3834 40.4249C49.1517 39.6566 49.5834 38.6144 49.5834 37.5278L49.5834 4.75C49.5834 3.66335 49.1517 2.62121 48.3834 1.85283C47.615 1.08445 46.5728 0.65278 45.4862 0.65278L15.2692 0.652782C15.1333 0.652782 15.0031 0.706741 14.907 0.802787L0.566753 15.1431ZM4.10425 17.0417L4.10425 37.5278C4.10425 37.6364 4.14742 37.7407 4.22425 37.8175C4.30109 37.8943 4.4053 37.9375 4.51397 37.9375L45.4862 37.9375C45.5949 37.9375 45.6991 37.8943 45.7759 37.8175C45.8528 37.7407 45.8959 37.6364 45.8959 37.5278L45.8959 4.75C45.8959 4.64134 45.8528 4.53712 45.7759 4.46028C45.6991 4.38345 45.5949 4.34028 45.4862 4.34028L17.6251 4.34028L17.6251 13.1233C17.6251 14.1625 17.2121 15.1592 16.4769 15.894C15.7417 16.6288 14.7445 17.0417 13.7048 17.0417L4.10425 17.0417Z"
      fill={fill}
    />
  </svg>
);

export default Landscape;
