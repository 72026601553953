import { NonConfigurableIconProps } from '../types';

const Layers = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.001 4.25C11.6841 4.25 11.3719 4.32586 11.0904 4.47125L2.92131 8.6946C2.71901 8.79937 2.5494 8.95772 2.43101 9.15236C2.31262 9.347 2.25 9.57043 2.25 9.79825C2.25 10.0261 2.31262 10.2495 2.43101 10.4441C2.5494 10.6388 2.71932 10.7973 2.92162 10.9021L4.85023 11.8997L2.92131 12.8975C2.71901 13.0023 2.5494 13.1607 2.43101 13.3553C2.31262 13.5499 2.25 13.7734 2.25 14.0012C2.25 14.229 2.31262 14.4524 2.43101 14.6471C2.5494 14.8417 2.71932 15.0002 2.92162 15.105L11.0904 19.3306C11.3719 19.476 11.6841 19.5518 12.001 19.5518C12.3178 19.5518 12.6305 19.4757 12.912 19.3304L21.0806 15.1048C21.2829 15.0001 21.4525 14.8417 21.5709 14.6471C21.6893 14.4524 21.7519 14.229 21.7519 14.0012C21.7519 13.7734 21.6893 13.5499 21.5709 13.3553C21.4525 13.1607 21.2826 13.0022 21.0803 12.8974L19.1552 11.9015L21.0806 10.9067C21.2829 10.8019 21.4525 10.6436 21.5709 10.4489C21.6893 10.2543 21.7519 10.0309 21.7519 9.80305C21.7519 9.57523 21.6893 9.35179 21.5709 9.15716C21.4736 8.99712 21.3416 8.86162 21.1853 8.76026C21.1525 8.73567 21.1173 8.71358 21.0801 8.69436L12.9116 4.47125C12.63 4.32586 12.3178 4.25 12.001 4.25ZM17.2011 11.2228C17.1848 11.2305 17.1687 11.2388 17.153 11.2477L12.2233 13.7949C12.1546 13.8304 12.0783 13.8489 12.001 13.8489C11.9236 13.8489 11.8474 13.8304 11.7786 13.7949L4.05264 9.79831L11.7786 5.80402C11.8474 5.76852 11.9236 5.75 12.001 5.75C12.0783 5.75 12.1546 5.76852 12.2233 5.80402L19.9537 9.80058L17.2011 11.2228ZM17.522 12.7455L19.9494 14.0012L12.2233 17.9978C12.1546 18.0333 12.0783 18.0518 12.001 18.0518C11.9236 18.0518 11.8474 18.0333 11.7786 17.9978L4.05251 14.0012L6.48259 12.7441L11.0904 15.1276C11.3719 15.273 11.6841 15.3489 12.001 15.3489C12.3178 15.3489 12.6302 15.273 12.9117 15.1276L17.522 12.7455Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Layers;
