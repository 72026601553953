/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Helper = ({ fill, width, height, dataTestId }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}
  >
    <path
      d="M6.41675 9.9165C6.41675 9.59434 6.67792 9.33317 7.00008 9.33317C7.32224 9.33317 7.58341 9.59434 7.58341 9.9165C7.58341 10.2387 7.32224 10.4998 7.00008 10.4998C6.67792 10.4998 6.41675 10.2387 6.41675 9.9165Z"
      fill={fill}
    />
    <path
      d="M7.5651 7.98971C7.61693 7.91024 7.69088 7.84768 7.77783 7.80973C8.09906 7.66953 8.37641 7.44513 8.58057 7.16024C8.78472 6.87536 8.90808 6.54059 8.93759 6.19135C8.96711 5.84211 8.90168 5.49139 8.74823 5.17629C8.59478 4.86118 8.35901 4.59341 8.06587 4.4013C7.77273 4.20919 7.43312 4.09988 7.08295 4.08494C6.73279 4.07 6.3851 4.14998 6.07666 4.31642C5.76822 4.48286 5.5105 4.72957 5.33076 5.03046C5.15103 5.33134 5.05596 5.67522 5.05561 6.0257C5.05561 6.15462 5.10683 6.27827 5.19799 6.36943C5.28916 6.4606 5.4128 6.51181 5.54172 6.51181C5.67065 6.51181 5.79429 6.4606 5.88546 6.36943C5.97662 6.27827 6.02783 6.15462 6.02783 6.0257C6.02845 5.85056 6.07635 5.67884 6.1665 5.52869C6.25664 5.37853 6.38567 5.25551 6.53996 5.17263C6.69424 5.08974 6.86805 5.05008 7.04302 5.05782C7.21798 5.06556 7.38761 5.12042 7.53397 5.2166C7.68034 5.31278 7.798 5.44671 7.87454 5.60424C7.95107 5.76177 7.98363 5.93705 7.96877 6.11156C7.95391 6.28607 7.89219 6.45332 7.79013 6.59565C7.68807 6.73797 7.54946 6.85009 7.38895 6.92014C7.12884 7.03366 6.90751 7.22061 6.75209 7.45806C6.59667 7.69551 6.5139 7.97315 6.51395 8.25695C6.51395 8.38587 6.56516 8.50952 6.65632 8.60068C6.74749 8.69184 6.87113 8.74306 7.00006 8.74306C7.12898 8.74306 7.25263 8.69184 7.34379 8.60068C7.43495 8.50952 7.48617 8.38587 7.48617 8.25695C7.48584 8.16208 7.51328 8.06918 7.5651 7.98971Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984ZM7.00008 11.7961C9.649 11.7961 11.7964 9.64876 11.7964 6.99984C11.7964 4.35092 9.649 2.20354 7.00008 2.20354C4.35116 2.20354 2.20379 4.35092 2.20379 6.99984C2.20379 9.64876 4.35116 11.7961 7.00008 11.7961Z"
      fill={fill}
    />
  </svg>
);

Helper.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.string,
};

Helper.defaultProps = {
  fill: '#1D1D1D',
  width: '15',
  height: '15',
  dataTestId: undefined,
};

export default Helper;
