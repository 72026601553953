import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Lightbulb = ({
  fill = colors.white,
  variant = 'outlined',
}: IconPropsWithVariant) =>
  variant === 'filled' ?
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10.4909 4.73038C10.3606 4.86061 10.184 4.93378 9.99983 4.93378C9.81565 4.93378 9.63902 4.86061 9.50878 4.73038C9.37855 4.60014 9.30538 4.42351 9.30538 4.23933V2.50322C9.30538 2.31904 9.37855 2.14241 9.50878 2.01217C9.63902 1.88194 9.81565 1.80878 9.99983 1.80878C10.184 1.80878 10.3606 1.88194 10.4909 2.01217C10.6211 2.14241 10.6943 2.31904 10.6943 2.50322V4.23933C10.6943 4.42351 10.6211 4.60014 10.4909 4.73038Z"
        fill={fill}
      />
      <path
        d="M9.99631 5.97544C9.02081 5.97697 8.07121 6.28448 7.28543 6.85331C6.49964 7.42213 5.9186 8.22265 5.62669 9.13859C5.33479 10.0545 5.34721 11.0382 5.66217 11.9467C5.97713 12.8552 6.57822 13.6413 7.37813 14.1907C7.42466 14.2226 7.46251 14.2652 7.48835 14.3149C7.51419 14.3645 7.52724 14.4197 7.52633 14.4754V15.0032C7.52633 15.1874 7.60068 15.364 7.73303 15.4943C7.86537 15.6245 8.04487 15.6977 8.23204 15.6977H11.7606C11.9478 15.6977 12.1273 15.6245 12.2596 15.4943C12.3919 15.364 12.4663 15.1874 12.4663 15.0032V14.4754C12.4655 14.4189 12.4794 14.3631 12.5065 14.3133C12.5337 14.2635 12.5733 14.2213 12.6216 14.1907C13.422 13.641 14.0233 12.8543 14.3381 11.9451C14.6529 11.0359 14.6647 10.0516 14.3719 9.13529C14.0791 8.219 13.4969 7.41851 12.71 6.85023C11.9231 6.28195 10.9724 5.9755 9.99631 5.97544Z"
        fill={fill}
      />
      <path
        d="M11.323 16.7393H8.67663C8.61573 16.739 8.55578 16.7542 8.5026 16.7834C8.44943 16.8126 8.40484 16.8548 8.37317 16.906C8.34344 16.9567 8.32779 17.0142 8.32779 17.0727C8.32779 17.1312 8.34344 17.1887 8.37317 17.2393L8.60606 17.7046C8.72183 17.9339 8.89976 18.1273 9.12019 18.2633C9.34063 18.3993 9.59498 18.4728 9.85516 18.4755H10.1374C10.4012 18.4766 10.6601 18.405 10.8846 18.2688C11.1091 18.1325 11.2903 17.9371 11.4077 17.7046L11.6406 17.2393C11.6703 17.1887 11.686 17.1312 11.686 17.0727C11.686 17.0142 11.6703 16.9567 11.6406 16.906C11.6083 16.8522 11.5616 16.8082 11.5056 16.7788C11.4496 16.7494 11.3864 16.7358 11.323 16.7393Z"
        fill={fill}
      />
      <path
        d="M16.3818 4.45461C16.2517 4.32527 16.0757 4.25268 15.8922 4.25268C15.7087 4.25268 15.5327 4.32527 15.4026 4.45461L14.1665 5.68378C14.1014 5.74834 14.0498 5.82515 14.0145 5.90977C13.9792 5.99439 13.9611 6.08516 13.9611 6.17684C13.9611 6.26851 13.9792 6.35928 14.0145 6.4439C14.0498 6.52853 14.1014 6.60533 14.1665 6.66989C14.2314 6.73425 14.3084 6.78517 14.393 6.81973C14.4776 6.85429 14.5682 6.87181 14.6596 6.87128C14.8417 6.87051 15.0163 6.79819 15.1457 6.66989L16.3748 5.44073C16.4404 5.37663 16.4926 5.30019 16.5284 5.21581C16.5643 5.13143 16.5831 5.04079 16.5837 4.94912C16.5844 4.85744 16.5669 4.76655 16.5322 4.68167C16.4975 4.5968 16.4464 4.51963 16.3818 4.45461Z"
        fill={fill}
      />
      <path
        d="M16.2498 10.1421H17.6387C17.8229 10.1421 17.9995 10.2153 18.1298 10.3455C18.26 10.4757 18.3332 10.6524 18.3332 10.8366C18.3332 11.0207 18.26 11.1974 18.1298 11.3276C17.9995 11.4578 17.8229 11.531 17.6387 11.531H16.2498C16.0657 11.531 15.889 11.4578 15.7588 11.3276C15.6285 11.1974 15.5554 11.0207 15.5554 10.8366C15.5554 10.6524 15.6285 10.4757 15.7588 10.3455C15.889 10.2153 16.0657 10.1421 16.2498 10.1421Z"
        fill={fill}
      />
      <path
        d="M5.83318 5.68377L4.59707 4.4546C4.46422 4.34083 4.29333 4.28139 4.11856 4.28814C3.94378 4.29489 3.77799 4.36734 3.65432 4.49102C3.53064 4.61469 3.45819 4.78048 3.45144 4.95526C3.44468 5.13003 3.50413 5.30092 3.6179 5.43377L4.84707 6.66988C4.97642 6.79817 5.151 6.8705 5.33318 6.87127C5.42574 6.87272 5.51765 6.85565 5.60352 6.82107C5.68938 6.78648 5.76747 6.73508 5.83318 6.66988C5.89827 6.60532 5.94993 6.52852 5.98519 6.44389C6.02044 6.35927 6.0386 6.2685 6.0386 6.17682C6.0386 6.08515 6.02044 5.99438 5.98519 5.90976C5.94993 5.82513 5.89827 5.74833 5.83318 5.68377Z"
        fill={fill}
      />
      <path
        d="M2.36095 10.1421H3.74984C3.93402 10.1421 4.11065 10.2153 4.24088 10.3455C4.37112 10.4757 4.44428 10.6524 4.44428 10.8366C4.44428 11.0207 4.37112 11.1974 4.24088 11.3276C4.11065 11.4578 3.93402 11.531 3.74984 11.531H2.36095C2.17677 11.531 2.00014 11.4578 1.8699 11.3276C1.73967 11.1974 1.6665 11.0207 1.6665 10.8366C1.6665 10.6524 1.73967 10.4757 1.8699 10.3455C2.00014 10.2153 2.17677 10.1421 2.36095 10.1421Z"
        fill={fill}
      />
    </svg>
  : <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99982 4.78748C10.3832 4.78748 10.6915 4.47914 10.6915 4.09581V2.35414C10.6915 1.97081 10.3832 1.66248 9.99982 1.66248C9.61649 1.66248 9.30816 1.97081 9.30816 2.35414V4.09581C9.30816 4.47914 9.61649 4.78748 9.99982 4.78748Z"
        fill={fill}
      />
      <path
        d="M11.3082 16.5958H8.70815C8.58315 16.5958 8.47482 16.6625 8.40815 16.7625C8.34982 16.8625 8.34982 16.9958 8.40815 17.0958L8.63315 17.5625C8.86648 18.0292 9.34148 18.3292 9.86648 18.3375H10.1415C10.6748 18.3375 11.1582 18.0375 11.3915 17.5625L11.6165 17.0958C11.6748 16.9958 11.6748 16.8625 11.6165 16.7625C11.5498 16.6542 11.4332 16.5875 11.3082 16.5958Z"
        fill={fill}
      />
      <path
        d="M15.4082 4.30414C15.6748 4.03747 16.1082 4.03747 16.3832 4.30414H16.3915C16.6582 4.57914 16.6582 5.0208 16.3832 5.28747L15.1498 6.51247C15.0248 6.63747 14.8498 6.71247 14.6665 6.71247C14.4832 6.71247 14.3082 6.6458 14.1748 6.51247C13.8998 6.23747 13.8998 5.7958 14.1748 5.52914L15.4082 4.30414Z"
        fill={fill}
      />
      <path
        d="M17.6415 9.99581H16.2498C15.8665 9.99581 15.5582 10.3041 15.5582 10.6875C15.5582 11.0708 15.8665 11.3791 16.2498 11.3791H17.6415C18.0248 11.3791 18.3332 11.0708 18.3332 10.6875C18.3332 10.3041 18.0248 9.99581 17.6415 9.99581Z"
        fill={fill}
      />
      <path
        d="M4.59986 4.31248L5.83319 5.53748C6.10819 5.81248 6.10819 6.25415 5.83319 6.52081C5.69986 6.65415 5.51652 6.72081 5.33319 6.72081C5.14986 6.72081 4.98319 6.64581 4.84986 6.52081L3.62486 5.28748C3.39986 5.02915 3.39986 4.64581 3.62486 4.38748C3.86652 4.09581 4.30819 4.06248 4.59986 4.31248Z"
        fill={fill}
      />
      <path
        d="M3.74984 9.99581H2.35817C1.97484 9.99581 1.6665 10.3041 1.6665 10.6875C1.6665 11.0708 1.97484 11.3791 2.35817 11.3791H3.74984C4.13317 11.3791 4.4415 11.0708 4.4415 10.6875C4.4415 10.3041 4.13317 9.99581 3.74984 9.99581Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.49153 10.3541C5.49153 7.85414 7.50819 5.82914 9.99986 5.82914C11.4749 5.82914 12.8582 6.55414 13.6999 7.76248C15.1249 9.80414 14.6249 12.6208 12.5832 14.0458C12.4915 14.1041 12.4332 14.2125 12.4332 14.3291V14.8541C12.4332 15.2375 12.1249 15.5458 11.7415 15.5458H8.26653C7.88319 15.5458 7.57486 15.2375 7.57486 14.8541V14.3291C7.57486 14.2208 7.51653 14.1125 7.42486 14.0458C6.21653 13.2041 5.49153 11.8291 5.49153 10.3541ZM12.6749 8.47081C12.0665 7.60414 11.0665 7.07914 9.99986 7.07914C9.12486 7.07914 8.30819 7.42081 7.69153 8.03748C7.07486 8.65414 6.74153 9.47081 6.74153 10.3458C6.74153 11.4125 7.25819 12.4125 8.13319 13.0208C8.55819 13.3125 8.81653 13.7958 8.81653 14.3041H11.1749C11.1749 13.7875 11.4415 13.3041 11.8749 13.0125C12.5832 12.5125 13.0582 11.7625 13.2082 10.9125C13.3665 10.0541 13.1749 9.18748 12.6749 8.47081Z"
        fill={fill}
      />
    </svg>;

export default Lightbulb;
