import { StyleableOnlyIconProps } from '../types';

const Alert = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5.7037C12.4091 5.7037 12.7407 6.03534 12.7407 6.44444V13.2346C12.7407 13.6437 12.4091 13.9753 12 13.9753C11.5909 13.9753 11.2593 13.6437 11.2593 13.2346V6.44444C11.2593 6.03534 11.5909 5.7037 12 5.7037Z"
          fill="black"
        />
        <path
          d="M10.9506 16.0123C10.9506 15.4328 11.4204 14.963 12 14.963C12.5796 14.963 13.0494 15.4328 13.0494 16.0123C13.0494 16.5919 12.5796 17.0617 12 17.0617C11.4204 17.0617 10.9506 16.5919 10.9506 16.0123Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47716 2 2 6.47716 2 12C2 17.5228 6.47716 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47716 17.5228 2 12 2ZM3.48148 12C3.48148 7.29535 7.29535 3.48148 12 3.48148C16.7046 3.48148 20.5185 7.29535 20.5185 12C20.5185 16.7046 16.7046 20.5185 12 20.5185C7.29535 20.5185 3.48148 16.7046 3.48148 12Z"
          fill="black"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0011 2.00187C9.32435 2.04364 6.77128 3.1361 4.89281 5.04354C3.95781 5.97841 3.22007 7.09152 2.72335 8.31687C2.22663 9.54221 1.98104 10.8548 2.00114 12.1769C2.00004 13.4681 2.2537 14.7469 2.74759 15.94C3.24147 17.133 3.96588 18.2169 4.87932 19.1296C5.79275 20.0423 6.87727 20.7657 8.07076 21.2586C9.26424 21.7515 10.5432 22.0041 11.8345 22.0019H12.0011C14.6762 21.9777 17.2322 20.8923 19.1073 18.9843C20.9825 17.0764 22.0233 14.5019 22.0011 11.8269C22.0036 10.5211 21.7453 9.22805 21.2415 8.02344C20.7376 6.81883 19.9983 5.72696 19.0669 4.81185C18.1355 3.89673 17.0307 3.17681 15.8174 2.69428C14.6041 2.21176 13.3066 1.97636 12.0011 2.00187ZM10.7511 15.7852C10.7443 15.6177 10.7717 15.4505 10.8317 15.2939C10.8917 15.1373 10.983 14.9946 11.1 14.8745C11.217 14.7544 11.3572 14.6594 11.5122 14.5953C11.6671 14.5313 11.8335 14.4995 12.0011 14.5019C12.3294 14.5038 12.6442 14.6322 12.8801 14.8603C13.1161 15.0885 13.2549 15.3989 13.2678 15.7269C13.2746 15.8919 13.2479 16.0566 13.1893 16.211C13.1308 16.3655 13.0416 16.5065 12.9272 16.6256C12.8128 16.7447 12.6754 16.8394 12.5234 16.9041C12.3715 16.9687 12.208 17.002 12.0428 17.0019C11.7117 17.0065 11.3917 16.8822 11.1506 16.6551C10.9095 16.428 10.7663 16.116 10.7511 15.7852ZM11.1678 12.4519V7.45187C11.1678 7.23086 11.2556 7.0189 11.4119 6.86262C11.5682 6.70634 11.7801 6.61854 12.0011 6.61854C12.2222 6.61854 12.4341 6.70634 12.5904 6.86262C12.7467 7.0189 12.8345 7.23086 12.8345 7.45187V12.4519C12.8345 12.6729 12.7467 12.8848 12.5904 13.0411C12.4341 13.1974 12.2222 13.2852 12.0011 13.2852C11.7801 13.2852 11.5682 13.1974 11.4119 13.0411C11.2556 12.8848 11.1678 12.6729 11.1678 12.4519Z"
          fill="black"
        />
      </svg>;
};

export default Alert;
