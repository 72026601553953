export const RECURLY_CONFIGURATION = {
  card: {
    displayIcon: true,
    style: {
      fontFamily: 'Roboto',
      placeHolder: {
        content: {
          number: 'Card Number',
        },
      },
    },
  },
};

// The plan codes in stripe (and recurly until we remove it).
export const STRIPE_PLANS_CODES = {
  landid: 'discover-',
  'landid-premium': 'basic-premium-',
  'landid-pro': 'p-',
  'landid-pro-unlimited': 'pu-',
} as const;

export const getPlanIdFromCode = (planCode: string | undefined) => {
  if (!planCode) return undefined;

  const value = planCode.split('-').slice(0, -1).join('-') + '-';
  return Object.keys(STRIPE_PLANS_CODES).find(
    (key) =>
      STRIPE_PLANS_CODES[key as keyof typeof STRIPE_PLANS_CODES] === value,
  ) as keyof typeof STRIPE_PLANS_CODES | undefined;
};

export const ANNUAL_SCHEMA_SUFFIX = 'annual';
export const MONTHLY_SCHEMA_SUFFIX = 'monthly';

export const DISCOUNT_TYPE_PERCENTUAL = 'percentual';
export const DISCOUNT_TYPE_FIXED = 'fixed';
export const DISCOUNT_TYPE_FREE_TRIAL = 'free_trial';

export const toPriceString = (price: number) =>
  (price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

export const ANNUAL_PLANS_SAVINGS = {
  'discover-annual': 56,
  'p-annual': 33,
  'pu-annual': 17,
};

export default {
  RECURLY_CONFIGURATION,
  STRIPE_PLANS_CODES,
  ANNUAL_SCHEMA_SUFFIX,
  MONTHLY_SCHEMA_SUFFIX,
  DISCOUNT_TYPE_PERCENTUAL,
  DISCOUNT_TYPE_FIXED,
  getPlanIdFromCode,
  toPriceString,
};
