import { useState } from 'react';
import { useFeatureFlags } from 'services/FeatureFlags';
import Typography from 'components/ui/Typography';
import { useAppSelector } from 'store/hooks';

import * as styles from './styles.module.scss';

interface EventButtonProps {}

const EventButton: React.FC<EventButtonProps> = () => {
  const [showLog, setShowLog] = useState(false);
  const events = useAppSelector((state) => state.eventLogger.events);

  const { eventLogTool } = useFeatureFlags();

  if (!eventLogTool?.value) {
    return null;
  }

  return (
    <div className={styles.buttonContainer}>
      <button className={styles.button} onClick={() => setShowLog(!showLog)}>
        {events.length}
      </button>
      {showLog && (
        <div className={styles.log}>
          {events
            .slice()
            .reverse()
            .map((event, index) => (
              <div key={index}>
                <div className={styles.logItem}>
                  <div>
                    <Typography type="ParagraphBold">Name:</Typography>
                    <Typography> {event.name}</Typography>
                  </div>
                  <div>
                    <Typography type="ParagraphBold">Event ID:</Typography>{' '}
                    <Typography>
                      {event.eventId ? event.eventId : 'EventRouter is down ⚠️'}
                    </Typography>
                  </div>
                  <div>
                    <Typography type="ParagraphBold">Timestamp:</Typography>
                    <Typography>
                      {' '}
                      {new Date(event.timestamp ?? '').toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true,
                      })}{' '}
                      {new Date(event.timestamp ?? '').toLocaleDateString(
                        'en-US',
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography type="ParagraphBold">Install ID:</Typography>
                    <Typography> {event.installId}</Typography>
                  </div>
                  <div>
                    <Typography type="ParagraphBold">Properties:</Typography>
                    <Typography> {JSON.stringify(event.properties)}</Typography>
                  </div>
                  <div>
                    <Typography type="ParagraphBold">
                      Vendor Properties:
                    </Typography>
                    <Typography>
                      {' '}
                      {JSON.stringify(event.vendorProperties)}
                    </Typography>
                  </div>
                </div>
                <hr className={styles.separator} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default EventButton;
