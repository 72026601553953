import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BufferTool {
  status: 'ENABLED' | 'DISABLED';
  element: any;
}

const initialState: BufferTool = {
  status: 'DISABLED',
  element: null,
};

const bufferToolSlice = createSlice({
  name: 'bufferTool',
  initialState,
  reducers: {
    startBuffer: (_state, action: PayloadAction<any>) => ({
      status: 'ENABLED',
      element: action.payload,
    }),
    finishBuffer: (_state) => ({ status: 'DISABLED', element: null }),
  },
});

export const { startBuffer, finishBuffer } = bufferToolSlice.actions;

export const clearBufferTool = () => (dispatch: any, getState: () => any) => {
  const { bufferTool } = getState();

  if (bufferTool.status === 'ENABLED') {
    dispatch(finishBuffer());
  }
};

export default bufferToolSlice.reducer;
