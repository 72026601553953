import { NonConfigurableIconProps } from '../types';

const PinMarker = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91651 0C5.8176 0.0022059 3.80528 0.836972 2.32113 2.32113C0.836973 3.80528 0.0022059 5.8176 0 7.91651C0 13.3747 5.41656 18.333 7.08319 19.7079C7.30708 19.8966 7.59042 20 7.88318 20C8.17593 20 8.45927 19.8966 8.68316 19.7079C10.4165 18.333 15.833 13.3747 15.833 7.91651C15.8308 5.8176 14.996 3.80528 13.5119 2.32113C12.0277 0.836972 10.0154 0.0022059 7.91651 0V0ZM7.91651 12.0831C7.09244 12.0831 6.28687 11.8387 5.60168 11.3809C4.91649 10.9231 4.38245 10.2723 4.06709 9.51099C3.75173 8.74965 3.66922 7.91189 3.82998 7.10365C3.99075 6.29541 4.38758 5.553 4.97029 4.97029C5.553 4.38758 6.29541 3.99075 7.10365 3.82998C7.91189 3.66922 8.74965 3.75173 9.51099 4.06709C10.2723 4.38245 10.9231 4.91649 11.3809 5.60168C11.8387 6.28687 12.0831 7.09244 12.0831 7.91651C12.0831 8.46367 11.9753 9.00548 11.7659 9.51099C11.5565 10.0165 11.2496 10.4758 10.8627 10.8627C10.4758 11.2496 10.0165 11.5565 9.51099 11.7659C9.00548 11.9753 8.46367 12.0831 7.91651 12.0831Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default PinMarker;
