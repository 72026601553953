import { StyleableOnlyIconProps } from '../types';

const MyMaps = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = '#1D1D1D',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.41 5.8125C21.19 5.4825 20.88 5.2325 20.51 5.0925L15.65 3.1425C15.18 2.9525 14.66 2.9525 14.19 3.1425L9.01 5.2125C8.95 5.2325 8.9 5.2425 8.84 5.2425C8.78 5.2425 8.72 5.2325 8.67 5.2125L3.86 3.2925C3.65 3.2125 3.43 3.1825 3.21 3.2025C2.99 3.2225 2.77 3.3025 2.59 3.4325C2.4 3.5525 2.25 3.7325 2.15 3.9225C2.05 4.1225 2 4.3425 2 4.5625V16.2225C2 16.6125 2.12 16.9925 2.34 17.3225C2.56 17.6525 2.87 17.9025 3.24 18.0425L8.11 19.9925C8.35 20.0825 8.59 20.1325 8.84 20.1325C9.09 20.1325 9.33 20.0825 9.57 19.9925L14.75 17.9225C14.81 17.9025 14.86 17.8925 14.92 17.8925C14.98 17.8925 15.04 17.9025 15.09 17.9225L19.89 19.8425C20.1 19.9225 20.32 19.9525 20.54 19.9325C20.76 19.9125 20.97 19.8325 21.16 19.7025C21.34 19.5725 21.49 19.4025 21.6 19.2125C21.7 19.0125 21.76 18.7925 21.76 18.5725V6.9125C21.76 6.5125 21.64 6.1325 21.42 5.8125H21.41ZM9.58 6.6025L14.16 4.7725V16.5325L9.58 18.3625V6.6025ZM3.58 16.4825C3.53 16.4025 3.5 16.3125 3.5 16.2225V4.7625L8.08 6.5925V18.3725L3.79 16.6625C3.7 16.6225 3.63 16.5625 3.58 16.4925V16.4825ZM20.25 18.3725L15.67 16.5425V4.7625L19.96 6.4825C20.05 6.5125 20.12 6.5725 20.17 6.6525C20.22 6.7325 20.25 6.8225 20.25 6.9125V18.3625V18.3725Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.77 5.66234C7.96 5.73234 8.08 5.92234 8.08 6.12234H8.09V20.0423C8.09 20.3923 7.73 20.6323 7.4 20.5023L3.24 18.8423C2.87 18.7023 2.56 18.4523 2.34 18.1223C2.12 17.7923 2 17.4123 2 17.0223V5.36234C2 5.14234 2.05 4.92234 2.15 4.72234C2.25 4.53234 2.4 4.35234 2.59 4.23234C2.77 4.10234 2.99 4.02234 3.21 4.00234C3.44 3.99234 3.66 4.01234 3.86 4.10234L7.77 5.66234ZM13.48 4.23231L9.9 5.66231C9.71 5.74231 9.59 5.92231 9.59 6.12231V20.0423C9.59 20.3923 9.95 20.6423 10.28 20.5023L13.86 19.0723C14.05 18.9923 14.17 18.8123 14.17 18.6123V4.69231C14.17 4.34231 13.81 4.09231 13.48 4.23231ZM21.41 6.61235C21.19 6.28235 20.88 6.03235 20.51 5.89235L16.35 4.23235C16.02 4.10235 15.66 4.34235 15.66 4.69235V18.6123C15.66 18.8123 15.78 19.0023 15.97 19.0723L19.87 20.6323C20.08 20.7123 20.3 20.7423 20.52 20.7223C20.74 20.7023 20.95 20.6223 21.14 20.4923C21.32 20.3624 21.47 20.1923 21.58 20.0023C21.68 19.8023 21.74 19.5823 21.74 19.3623V7.72235C21.74 7.32235 21.62 6.94235 21.4 6.62235L21.41 6.61235Z"
          fill={color}
        />
      </svg>;
};

export default MyMaps;
