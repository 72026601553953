import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';
const Saved = ({
  width = '24',
  height = '24',
  fill = colors.grayScale200,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.41 5.8125C21.19 5.4825 20.88 5.2325 20.51 5.0925L15.65 3.1425C15.18 2.9525 14.66 2.9525 14.19 3.1425L9.01 5.2125C8.95 5.2325 8.9 5.2425 8.84 5.2425C8.78 5.2425 8.72 5.2325 8.67 5.2125L3.86 3.2925C3.65 3.2125 3.43 3.1825 3.21 3.2025C2.99 3.2225 2.77 3.3025 2.59 3.4325C2.4 3.5525 2.25 3.7325 2.15 3.9225C2.05 4.1225 2 4.3425 2 4.5625V16.2225C2 16.6125 2.12 16.9925 2.34 17.3225C2.56 17.6525 2.87 17.9025 3.24 18.0425L8.11 19.9925C8.35 20.0825 8.59 20.1325 8.84 20.1325C9.09 20.1325 9.33 20.0825 9.57 19.9925L14.75 17.9225C14.81 17.9025 14.86 17.8925 14.92 17.8925C14.98 17.8925 15.04 17.9025 15.09 17.9225L19.89 19.8425C20.1 19.9225 20.32 19.9525 20.54 19.9325C20.76 19.9125 20.97 19.8325 21.16 19.7025C21.34 19.5725 21.49 19.4025 21.6 19.2125C21.7 19.0125 21.76 18.7925 21.76 18.5725V6.9125C21.76 6.5125 21.64 6.1325 21.42 5.8125H21.41ZM9.58 6.6025L14.16 4.7725V16.5325L9.58 18.3625V6.6025ZM3.58 16.4825C3.53 16.4025 3.5 16.3125 3.5 16.2225V4.7625L8.08 6.5925V18.3725L3.79 16.6625C3.7 16.6225 3.63 16.5625 3.58 16.4925V16.4825ZM20.25 18.3725L15.67 16.5425V4.7625L19.96 6.4825C20.05 6.5125 20.12 6.5725 20.17 6.6525C20.22 6.7325 20.25 6.8225 20.25 6.9125V18.3625V18.3725Z"
      fill={fill}
    />
  </svg>
);

export default Saved;
