import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  subscriptionActive,
  onTrialUser,
  isOrganizationSelected,
  isFreePlan,
} from 'selectors/userSelectors';
import { getPlanIdFromCode, ANNUAL_SCHEMA_SUFFIX } from 'utils/payments';
import { getPlanRoute } from 'utils/routes';

import Button from 'components/ui/Button';

const BuyNowButton = ({ scale }) => {
  const subscriptions = useSelector((state) => state.subscriptions);
  const user = useSelector((state) => state.user);
  const isOnActiveSubscription = subscriptionActive(subscriptions);
  const organizationSelected = useSelector(isOrganizationSelected);

  const isOnTrial = onTrialUser(subscriptions);

  const planCode = subscriptions?.userSubscription?.plan_info?.code;
  const planId = getPlanIdFromCode(planCode);
  const isAnnual = planCode?.includes(ANNUAL_SCHEMA_SUFFIX);

  const needsPayment =
    subscriptions?.userSubscription &&
    (isOnTrial ? user?.user?.skipped_payment : !isOnActiveSubscription);

  const shouldBuyNow =
    needsPayment && !organizationSelected && !isFreePlan(subscriptions);

  if (!shouldBuyNow) {
    return null;
  }

  return (
    <NavLink
      to={getPlanRoute(planId, isAnnual)}
      state={{ viaBuyNowButton: true }}
    >
      <Button
        color="red"
        size="w100"
        otherClassNames={['buy-now-button']}
        scale={scale}
      >
        Buy Now!
      </Button>
    </NavLink>
  );
};

BuyNowButton.propTypes = {
  scale: PropTypes.string,
};

export default BuyNowButton;
