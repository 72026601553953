import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { overlaysCategoriesEndpoints } from 'api/endpoints/overlaysCategories/endpoints';
import { ApiOverlayCategories } from 'api/endpoints/overlaysCategories/types';

type OverlayState = {
  overlays: number[];
};

export type ParcelCardState = {
  categories: ApiOverlayCategories;
  overlaysState: Record<number, OverlayState>;
  loading: boolean;
  currentSectionIndex: number;
};

const initialState: ParcelCardState = {
  categories: [],
  overlaysState: {},
  loading: false,
  currentSectionIndex: 0,
};

const parcelCardSlice = createSlice({
  name: 'parcelCard',
  initialState,
  reducers: {
    toggleOverlay: (
      state,
      action: PayloadAction<{ overlayId: number; sectionId: number }>,
    ) => {
      const { overlayId, sectionId } = action.payload;

      return {
        ...state,
        overlaysState: {
          ...state.overlaysState,
          [sectionId]: {
            ...state.overlaysState[sectionId],
            overlays:
              state.overlaysState[sectionId].overlays.includes(overlayId) ?
                state.overlaysState[sectionId].overlays.filter(
                  (item) => item !== overlayId,
                )
              : [...state.overlaysState[sectionId].overlays, overlayId],
          },
        },
      };
    },
    toggleOverlayBySection: (state, action: PayloadAction<number>) => {
      const sectionId = action.payload;

      state.currentSectionIndex = sectionId;
    },
    resetCurrentSections: (state) => {
      state.currentSectionIndex = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        overlaysCategoriesEndpoints.endpoints.fetchOverlaysCategories
          .matchPending,
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        overlaysCategoriesEndpoints.endpoints.fetchOverlaysCategories
          .matchFulfilled,
        (state, action) => {
          state.loading = false;
          state.categories = action.payload;

          const newOverlayStates: Record<string, OverlayState> = {};

          for (const category of state.categories) {
            newOverlayStates[category.id] = {
              overlays: [],
            };

            for (const overlay of category.overlays) {
              if (overlay.enabled_by_default) {
                newOverlayStates[category.id].overlays.push(overlay.id);
              }
            }
          }

          state.overlaysState = newOverlayStates;
          state.currentSectionIndex = 0;
        },
      );
  },
});

export const { toggleOverlay, toggleOverlayBySection, resetCurrentSections } =
  parcelCardSlice.actions;

export default parcelCardSlice.reducer;
