import inside from '@turf/inside';
import parse from 'wellknown';

import settingsActionTypes from '../actions/settings/actions';
import actionTypes from '../actions/parcels/actions';
import searchActionTypes from '../actions/search/actions';

const ACTION_HANDLERS = {
  [actionTypes.CHANGE_STATUS]: (state, action) => ({
    ...state,
    status: action.status,
  }),
  [actionTypes.SET_PARCEL_DATA]: (state, action) => ({
    ...state,
    status: 'READY',
    data: action.data,
    fieldData: [],
  }),
  [actionTypes.QUERY_SUCCESS]: (state, action) => {
    const { data } = action;

    const parcels = data.parcels.map((parcel) => ({
      ...parcel,
      geometry: parcel.geom_as_wkt ? parse(parcel.geom_as_wkt) : null,
    }));

    const parsedData = {
      ...data,
      parcels,
    };

    return {
      ...state,
      data: parsedData,
      parcelsResult: [],
      paginationParams: {},
      parcelsSearchNextPage: 1,
      selectedParcelIndex: 0,
    };
  },
  [searchActionTypes.SEARCH_APN_SUCCESS]: (state, action) => {
    const { data } = action;

    if (!data.parcels.length) {
      return {
        ...state,
        data: {},
        parcelsResult: [],
        parcelsSearchNextPage: 0,
        status: 'READY',
      };
    }

    const parcels = data.parcels.map((parcel) => ({
      ...parcel,
      geometry: parse(parcel.geom_as_wkt),
    }));

    const parsedData = {
      ...data,
      parcels,
    };

    return {
      ...state,
      data: parsedData,
      ...(action.clearParcelResults ? { parcelsResult: [] } : {}),
      parcelsSearchNextPage: 0,
      status: 'READY',
    };
  },
  [searchActionTypes.SEARCH_APN_FAILURE]: (state, action) => ({
    ...state,
    data: action.data,
  }),
  [searchActionTypes.SEARCH_PARCELS_SUCCESS]: (state, action) => {
    const { data } = action;

    let parcels =
      data.parcels.length ?
        data.parcels.map((parcel) => ({
          ...parcel,
          geometry: parse(parcel.geom_as_wkt),
          properties: {
            apn: parcel.apn,
            apn2: parcel.apn2,
            formatted_apn: parcel.formatted_apn,
            unformatted_apn: parcel.unformatted_apn,
            owner_name: parcel.owner_name,
            acreage: parcel.acreage,
            state: parcel.state,
            parcel_address: parcel.parcel_address,
          },
        }))
      : [];

    if (action.data.current_page && action.data.current_page > 1) {
      parcels = [...state.parcelsResult, ...parcels];
    }

    return {
      ...state,
      status: 'READY',
      parcelsResult: parcels,
      parcelsSearchNextPage: action.data.next_page || 1,
      paginationParams: action.params,
      data: null,
    };
  },
  [searchActionTypes.SEARCH_PARCELS_FAILURE]: (state, action) => ({
    ...state,
    parcelsResult: [],
    parcelsSearchNextPage: 0,
  }),
  [actionTypes.START_PARCEL_SELECTION]: (state, action) => {
    const { geoJSON, data } = action;
    let featureCollection;

    if (!geoJSON) {
      featureCollection = [];
    } else if (geoJSON.geometry.type === 'MultiPolygon') {
      featureCollection = geoJSON.geometry.coordinates.map((coords) => ({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: coords,
        },
        properties: data,
      }));
    } else {
      featureCollection = [{ ...geoJSON, properties: data }];
    }

    return {
      ...state,
      selectingParcels: true,
      parcelsToMerge: featureCollection,
    };
  },
  [actionTypes.STOP_PARCEL_SELECTION]: (state, action) => {
    if (action.isMultiSearch) {
      return {
        ...state,
        selectingParcels: false,
        parcelsToMerge: [],
      };
    }

    return {
      ...initialState,
      suggestions: state.suggestions,
    };
  },
  [actionTypes.SELECT_TOOL_TYPE]: (state, action) => ({
    ...state,
    toolId: action.toolId,
    toolType: action.toolType,
  }),
  [actionTypes.MERGE_PARCELS]: (state, action) => ({
    ...state,
    merge: true,
    changeSet: action.changeSet,
    selectedTool: action.selectedTool,
  }),
  [actionTypes.ADD_PARCEL]: (state, action) => ({
    ...state,
    parcelsToMerge: state.parcelsToMerge.concat(action.featureCollection),
  }),
  [actionTypes.REMOVE_PARCEL]: (state, action) => {
    const { featureCollection } = action;
    const indexes = [];

    state.parcelsToMerge.forEach((currentGeoJSON, index) => {
      const remove = featureCollection.some((feature) =>
        currentGeoJSON.geometry.coordinates[0].every((coord) =>
          inside(coord, feature),
        ),
      );

      if (remove) {
        indexes.push(index);
      }
    });

    let { parcelsToMerge } = state;
    indexes.forEach((index, i) => {
      parcelsToMerge = parcelsToMerge
        .slice(0, index - i)
        .concat(parcelsToMerge.slice(index + 1 - i));
    });

    return {
      ...state,
      parcelsToMerge,
    };
  },
  [actionTypes.ZOOM_PARCEL]: (state, action) => ({
    ...state,
    zoomToParcel: action.parcel,
  }),
  [actionTypes.HIGHLIGHT_PARCEL]: (state, action) => ({
    ...state,
    highlightParcel: action.parcel,
  }),
  [actionTypes.CHANGE_SELECTED_PARCEL_INDEX]: (state, action) => ({
    ...state,
    selectedParcelIndex: action.selectedParcelIndex,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
  [actionTypes.RESET_PARCELS_STATE]: (state, action) => ({
    ...initialState,
  }),
  [searchActionTypes.LOAD_PARCEL_SUGGESTIONS_SUCCESS]: (state, action) => ({
    ...state,
    suggestions: action.data,
  }),
};

const initialState = {
  status: null,
  data: null,
  popupProps: null,
  selectingParcels: false,
  merge: false,
  zoomToParcel: null,
  highlightParcel: null,
  parcelsToMerge: [],
  selectedParcel: null,
  changeSet: {},
  selectedTool: {},
  parcelsResult: [],
  paginationParams: {},
  parcelsSearchNextPage: 1,
  toolId: null,
  toolType: null,
  suggestions: {},
  selectedParcelIndex: 0,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
