import { NonConfigurableIconProps } from '../types';

const Shapes = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86956 2H9.82608C10.3063 2 10.6956 2.38932 10.6956 2.86956V9.82608C10.6956 10.3063 10.3063 10.6956 9.82608 10.6956H2.86956C2.38932 10.6956 2 10.3063 2 9.82608V2.86956C2 2.38932 2.38932 2 2.86956 2ZM6.80029 12.5709C6.94069 12.6413 7.06269 12.7436 7.15658 12.8695L9.90441 16.6087C10.0314 16.7764 10.1001 16.981 10.1001 17.1913C10.1001 17.4016 10.0314 17.6062 9.90441 17.7739L7.15658 21.5652C7.06269 21.6911 6.94069 21.7934 6.80029 21.8639C6.65989 21.9343 6.50497 21.971 6.34789 21.971C6.1908 21.971 6.03589 21.9343 5.89549 21.8639C5.75509 21.7934 5.63308 21.6911 5.53919 21.5652L2.79137 17.8C2.66441 17.6323 2.5957 17.4277 2.5957 17.2174C2.5957 17.007 2.66441 16.8025 2.79137 16.6348L5.53919 12.8695C5.63308 12.7436 5.75509 12.6413 5.89549 12.5709C6.03589 12.5004 6.1908 12.4637 6.34789 12.4637C6.50497 12.4637 6.65989 12.5004 6.80029 12.5709ZM17.652 10.6956C20.0533 10.6956 21.9998 8.74906 21.9998 6.34782C21.9998 3.94659 20.0533 2 17.652 2C15.2508 2 13.3042 3.94659 13.3042 6.34782C13.3042 8.74906 15.2508 10.6956 17.652 10.6956ZM16.7781 13.4272C16.9092 13.5019 17.0186 13.6094 17.0956 13.7391L21.2434 20.6956C21.3198 20.8278 21.3599 20.9778 21.3599 21.1304C21.3599 21.2831 21.3198 21.433 21.2434 21.5652C21.1668 21.6979 21.0565 21.808 20.9237 21.8844C20.7908 21.9607 20.6401 22.0006 20.4869 22H12.226C12.0728 22.0006 11.9222 21.9607 11.7893 21.8844C11.6565 21.808 11.5461 21.6979 11.4695 21.5652C11.3932 21.433 11.353 21.2831 11.353 21.1304C11.353 20.9778 11.3932 20.8278 11.4695 20.6956L15.6 13.7391C15.677 13.6094 15.7864 13.5019 15.9175 13.4272C16.0486 13.3526 16.1969 13.3133 16.3478 13.3133C16.4987 13.3133 16.647 13.3526 16.7781 13.4272Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Shapes;
