import React from 'react';
import { IconProps } from '../types';

const CloseCircle = ({
  width = 24,
  height = 24,
  theme = 'light',
}: IconProps) => {
  const circleColor = theme === 'light' ? '#000000' : '#E8E8E8';
  const xColor = theme === 'light' ? '#FFFFFF' : '#1D1D1D';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_2019_7226)">
        <rect width="24" height="24" rx="12" fill={circleColor} />
        <path
          d="M7.41761 15.4759C7.12968 15.7638 7.12381 16.275 7.42349 16.5747C7.72904 16.8744 8.24026 16.8685 8.52231 16.5864L12.0009 13.1078L15.4737 16.5806C15.7675 16.8744 16.2728 16.8744 16.5725 16.5747C16.8722 16.2691 16.8722 15.7697 16.5784 15.4759L13.1056 12.0031L16.5784 8.52451C16.8722 8.23071 16.8781 7.72537 16.5725 7.42569C16.2728 7.12602 15.7675 7.12602 15.4737 7.41982L12.0009 10.8926L8.52231 7.41982C8.24026 7.13189 7.72316 7.12014 7.42349 7.42569C7.12381 7.72537 7.12968 8.24246 7.41761 8.52451L10.8904 12.0031L7.41761 15.4759Z"
          fill={xColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_b_2019_7226"
          x="-54.3656"
          y="-54.3656"
          width="132.731"
          height="132.731"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="27.1828" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_2019_7226"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_2019_7226"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default CloseCircle;
