export default {
  GET_MAP_FILTERS_SUCCESS: 'GET_MAP_FILTERS_SUCCESS',
  GET_MAP_FILTERS_FAILURE: 'GET_MAP_FILTERS_FAILURE',
  CHANGE_FEATURE_ID: 'CHANGE_FEATURE_ID',
  CHANGE_FEATURE_PROPERTIES: 'CHANGE_FEATURE_PROPERTIES',
  CHANGE_FEATURE_INSTANCE_DETAIL: 'CHANGE_FEATURE_INSTANCE_DETAIL',
  CHANGE_FEATURE_LIST_FILTER: 'CHANGE_FEATURE_LIST_FILTER',
  REMOVE_TOOL: 'REMOVE_TOOL',
  DUPLICATE_TOOL: 'DUPLICATE_TOOL',
  DUPLICATE_TOOL_WITH_ATTRIBUTES: 'DUPLICATE_TOOL_WITH_ATTRIBUTES',
};
