export const actionTypes = {
  SHOW_CARD: 'SHOW_CARD',
  HIDE_CARD: 'HIDE_CARD',
};

export const showCard = (cardName, force = false) => ({
  type: actionTypes.SHOW_CARD,
  cardName,
  force,
});

export const hideCard = () => ({ type: actionTypes.HIDE_CARD });

export const actions = {
  showCard,
  hideCard,
};
