import React from 'react';
import PropTypes from 'prop-types';

const DownloadAction = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M15.9447 11.1487C15.6371 11.1487 15.3819 11.4038 15.3819 11.7115V12.6344C15.3819 13.3473 14.8042 13.9251 14.0913 13.9251H3.91621C3.20335 13.9251 2.62556 13.3473 2.62556 12.6344V11.7115C2.62556 11.4038 2.37044 11.1487 2.06278 11.1487C1.75513 11.1487 1.5 11.4038 1.5 11.7115V12.6344C1.5 13.9626 2.58054 15.0507 3.91621 15.0507H14.0913C15.4195 15.0507 16.5075 13.9701 16.5075 12.6344V11.7115C16.5075 11.4038 16.2524 11.1487 15.9447 11.1487Z"
      fill={fill}
    />
    <path
      // eslint-disable-next-line max-len
      d="M12.1703 9.33279C12.388 9.11518 12.388 8.755 12.1703 8.53739H12.1779C12.0653 8.42484 11.9227 8.37231 11.7802 8.37231C11.6376 8.37231 11.495 8.42484 11.3825 8.53739L9.56655 10.3533V2.81278C9.56655 2.50513 9.31142 2.25 9.00377 2.25C8.69611 2.25 8.44099 2.50513 8.44099 2.81278L8.44098 10.3533L6.62508 8.53739C6.40747 8.31979 6.04729 8.31979 5.82968 8.53739C5.61207 8.755 5.61207 9.11518 5.82968 9.33279L8.60607 12.1092C8.65859 12.1617 8.71862 12.1992 8.78616 12.2292C8.92122 12.2893 9.0788 12.2893 9.21387 12.2292C9.2814 12.1992 9.34143 12.1617 9.39396 12.1092L12.1703 9.33279Z"
      fill={fill}
    />
  </svg>
);

DownloadAction.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DownloadAction.defaultProps = {
  fill: '#FFFFFF',
  width: '18',
  height: '18',
};

export default DownloadAction;
