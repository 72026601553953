import { StyleableOnlyIconProps } from '../types';

const Export = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7C12.2112 7 12.4027 7.07851 12.5424 7.20579L11.7037 7.05452C11.7949 7.01939 11.895 7 12 7Z"
          fill="black"
        />
        <path
          d="M11.7037 7.05452L12.548 7.21094L15.7732 10.2045C16.0756 10.4851 16.0756 10.9401 15.7732 11.2207C15.4709 11.5013 14.9807 11.5013 14.6784 11.2207L12.7742 9.45333V16.2814C12.7742 16.6783 12.4276 17 12 17C11.5724 17 11.2258 16.6783 11.2258 16.2814V9.45333L9.32163 11.2207C9.01929 11.5013 8.5291 11.5013 8.22676 11.2207C7.92441 10.9401 7.92441 10.4851 8.22676 10.2045L11.4526 7.21046C11.5268 7.14157 11.6123 7.08959 11.7037 7.05452Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM5.9765 5.9765C7.57403 4.37897 9.74075 3.48148 12 3.48148C14.2593 3.48148 16.426 4.37897 18.0235 5.9765C19.621 7.57403 20.5185 9.74075 20.5185 12C20.5185 14.2593 19.621 16.426 18.0235 18.0235C16.426 19.621 14.2593 20.5185 12 20.5185C9.74075 20.5185 7.57403 19.621 5.9765 18.0235C4.37897 16.426 3.48148 14.2593 3.48148 12C3.48148 9.74075 4.37897 7.57403 5.9765 5.9765Z"
          fill="black"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893ZM12 6.64999C11.8518 6.64999 11.7092 6.67737 11.5782 6.72778C11.4471 6.77814 11.3228 6.85337 11.2145 6.95392L7.98865 9.94794C7.53711 10.367 7.53711 11.0581 7.98865 11.4772C8.42526 11.8824 9.12311 11.8824 9.55972 11.4772L10.8758 10.2557V16.2814C10.8758 16.8957 11.4042 17.35 12 17.35C12.5958 17.35 13.1242 16.8957 13.1242 16.2814V10.2557L14.4403 11.4772C14.8769 11.8824 15.5747 11.8824 16.0113 11.4772C16.4629 11.0581 16.4629 10.367 16.0113 9.94794L12.786 6.9544L12.7794 6.94826L12.7781 6.94709C12.574 6.7611 12.2985 6.64999 12 6.64999Z"
          fill="black"
        />
      </svg>;
};

export default Export;
