import * as colors from '../../styles/colors.module.scss';
import { IconProps } from '../types';

const CreditCard = ({
  fill = colors.grayScale200,
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="credit-card-1 1">
      <g id="Union">
        <path
          d="M5.75 12.25C5.75 11.8358 6.08579 11.5 6.5 11.5H13.375C13.7892 11.5 14.125 11.8358 14.125 12.25C14.125 12.6642 13.7892 13 13.375 13H6.5C6.08579 13 5.75 12.6642 5.75 12.25Z"
          fill={fill}
        />
        <path
          d="M6.5 14C6.08579 14 5.75 14.3358 5.75 14.75C5.75 15.1642 6.08579 15.5 6.5 15.5H10.875C11.2892 15.5 11.625 15.1642 11.625 14.75C11.625 14.3358 11.2892 14 10.875 14H6.5Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6C2 4.89543 2.89543 4 4 4H20.25C21.3546 4 22.25 4.89543 22.25 6V17.25C22.25 18.3546 21.3546 19.25 20.25 19.25H4C2.89543 19.25 2 18.3546 2 17.25V6ZM3.5 6C3.5 5.72386 3.72386 5.5 4 5.5H20.25C20.5261 5.5 20.75 5.72386 20.75 6V7.75H3.5V6ZM20.75 9.25V17.25C20.75 17.5261 20.5261 17.75 20.25 17.75H4C3.72386 17.75 3.5 17.5261 3.5 17.25V9.25H20.75Z"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default CreditCard;
