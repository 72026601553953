import { NonConfigurableIconProps } from '../types';

const DeedArch = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 7C9.88071 7 11 5.88071 11 4.5C11 3.11929 9.88071 2 8.5 2C7.11929 2 6 3.11929 6 4.5C6 5.88071 7.11929 7 8.5 7Z"
        fill="black"
      />
      <path
        d="M8.5 22C9.88071 22 11 20.8807 11 19.5C11 18.1193 9.88071 17 8.5 17C7.11929 17 6 18.1193 6 19.5C6 20.8807 7.11929 22 8.5 22Z"
        fill="black"
      />
      <path
        d="M16 14.5C17.3807 14.5 18.5 13.3807 18.5 12C18.5 10.6193 17.3807 9.5 16 9.5C14.6193 9.5 13.5 10.6193 13.5 12C13.5 13.3807 14.6193 14.5 16 14.5Z"
        fill="black"
      />
      <path
        d="M12.0083 17.675C11.9852 17.688 11.9649 17.7054 11.9486 17.7263C11.9322 17.7473 11.9202 17.7712 11.9132 17.7968C11.9063 17.8224 11.9044 17.8491 11.9079 17.8754C11.9113 17.9017 11.92 17.9271 11.9333 17.95L12.5583 19.0333C12.589 19.0819 12.6364 19.1175 12.6917 19.1333C12.7164 19.1452 12.7434 19.1513 12.7708 19.1513C12.7982 19.1513 12.8253 19.1452 12.85 19.1333C13.9733 18.4433 14.9183 17.4983 15.6083 16.375C15.6202 16.3503 15.6263 16.3232 15.6263 16.2958C15.6263 16.2684 15.6202 16.2414 15.6083 16.2167C15.5926 16.1614 15.5569 16.114 15.5083 16.0833L14.425 15.4583C14.4021 15.445 14.3767 15.4363 14.3504 15.4329C14.3241 15.4294 14.2974 15.4313 14.2718 15.4382C14.2462 15.4452 14.2223 15.4572 14.2013 15.4736C14.1804 15.4899 14.163 15.5102 14.15 15.5333C13.6038 16.3972 12.8722 17.1288 12.0083 17.675Z"
        fill="black"
      />
      <path
        d="M14.175 8.49167C14.188 8.5148 14.2054 8.53512 14.2263 8.55145C14.2473 8.56777 14.2712 8.57977 14.2968 8.58675C14.3224 8.59373 14.3491 8.59555 14.3754 8.5921C14.4017 8.58865 14.4271 8.58001 14.45 8.56667L15.5333 7.94167C15.5775 7.91731 15.6126 7.8793 15.6333 7.83333C15.6452 7.80862 15.6513 7.78157 15.6513 7.75417C15.6513 7.72677 15.6452 7.69971 15.6333 7.675C14.9433 6.55165 13.9983 5.60668 12.875 4.91667C12.8503 4.90483 12.8232 4.89869 12.7958 4.89869C12.7684 4.89869 12.7414 4.90483 12.7167 4.91667C12.6614 4.93245 12.614 4.96806 12.5833 5.01667L11.9583 6.1C11.945 6.12293 11.9363 6.14828 11.9329 6.17458C11.9295 6.20089 11.9313 6.22761 11.9382 6.25321C11.9452 6.2788 11.9572 6.30275 11.9736 6.32366C11.9899 6.34457 12.0102 6.36202 12.0333 6.375C12.8945 6.91407 13.6259 7.63687 14.175 8.49167Z"
        fill="black"
      />
    </svg>
  );
};

export default DeedArch;
