import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { impersonate } from 'actions/user';
import { useNavigate, useParams } from 'react-router-dom';
import { USERS_SIGN_IN_ROUTE } from 'utils/routes';

const Impersonate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { user_id: userId, token, splat: redirectTo } = params;

  useEffect(() => {
    const impersonateUser = async () => {
      try {
        const url = await dispatch(impersonate(userId, token, redirectTo));
        navigate(url);
      } catch (error) {
        navigate(`${USERS_SIGN_IN_ROUTE}`);
      }
    };

    impersonateUser();
  }, []);

  return null;
};

export default Impersonate;
