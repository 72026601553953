import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CustomModal from '../../CustomModal';

import './styles.scss';

const SaveAsNewMapProgressModal = ({
  modifierClass,
  onClose,
  isCompleted,
  hasErrors,
}) => {
  const regularClassNames = classNames(
    'save-as-new-modal',
    `save-as-new-modal--${modifierClass}`,
  );

  return (
    <CustomModal
      className={regularClassNames}
      onClose={onClose}
      positionModifier="center-full"
    >
      <main>
        <CustomModal.Title title="Saving your map" onClose={onClose} />

        {!hasErrors &&
          isCompleted &&
          'Your map is ready, we are redirecting to it...'}
        {!hasErrors &&
          !isCompleted &&
          'We are processing your new map. You will be redirected to it when the new map is ready'}
        {hasErrors &&
          'Something went wrong creating the new map, please try again...'}
      </main>
    </CustomModal>
  );
};

SaveAsNewMapProgressModal.propTypes = {
  modifierClass: PropTypes.string,
  onClose: PropTypes.func,
  isCompleted: PropTypes.bool,
  hasErrors: PropTypes.bool,
};

export default SaveAsNewMapProgressModal;
