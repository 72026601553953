import { NonConfigurableIconProps } from '../types';

const Boundary = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5607 20L19.9329 9.75752L4.06202 4.13295L6.42367 20H16.5607ZM16.7509 22H6.19721C5.33646 22 4.60545 21.3694 4.47866 20.5175L2.01974 3.99692C1.82671 2.70002 3.08317 1.66417 4.31835 2.10192L20.8421 7.95787C21.7325 8.27343 22.2079 9.24301 21.9123 10.1408L18.4012 20.8054C18.1665 21.5183 17.5011 22 16.7509 22Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Boundary;
