import {
  HEX_BACKGROUND_ID_TERRAIN,
  HEX_BACKGROUND_ID_WATER,
  MAX_MAPBOX_ZOOM,
  LAYER_RASTER_TYPE,
  LAYER_TILE_SIZE,
  BOTTOM_SLOT,
  MIDDLE_SLOT,
  TOP_SLOT,
} from 'utils/mapConstants';

export const SKY_LAYER = 'sky';
export const TERRAIN_SOURCE = 'terrain-source';

export const GOOGLE_LAYER = 'google';
export const MB_SATELLITE_LAYER = 'mb_satellite_streets';
export const TOPO_LAYERS_URLS = ['/caltopo', '/topolayers'];

export const INTERNAL_HEX_LAYERS = {
  hex30_layer_0: 'hex30_layer_0',
  hex30_layer_1: 'hex30_layer_1',
};

export const NAIP_LAYERS = {
  naip_0: 'naip_0',
  naip_1: 'naip_1',
};

// This is the first layer added to the map, is always placed at the bottom
// of all the layers.
export const backgroundLayer = () => {
  const backgroundLayer = [
    {
      layout: {
        visibility: 'none',
      },
      filter: ['match', ['get', 'name'], ['United States'], false, true],
      type: 'fill',
      source: 'composite',
      id: HEX_BACKGROUND_ID_TERRAIN,
      paint: {
        'fill-color': '#f2e9c2',
      },
      'source-layer': 'country_boundaries',
    },
    {
      id: HEX_BACKGROUND_ID_WATER,
      type: 'fill',
      paint: {
        'fill-color': 'hsl(196, 80%, 70%)',
      },
      layout: {
        visibility: 'none',
      },
      source: 'composite',
      'source-layer': 'water',
    },
    {
      id: BOTTOM_SLOT,
      type: 'slot',
    },
    {
      id: MIDDLE_SLOT,
      type: 'slot',
    },
    {
      id: TOP_SLOT,
      type: 'slot',
    },
  ];

  return backgroundLayer;
};

export const expandBoundingBox = (mapBounds, expansionFactor = 0.02) => {
  const [sw, ne] = mapBounds;

  const expandedSW = {
    lat: sw[1] - expansionFactor,
    lng: sw[0] - expansionFactor * 2,
  };
  const expandedNE = {
    lat: ne[1] + expansionFactor,
    lng: ne[0] + expansionFactor * 2,
  };

  return [expandedSW.lng, expandedSW.lat, expandedNE.lng, expandedNE.lat];
};

export const layerToSource = (layer, options = {}) => {
  const { mapBounds } = options;

  const isTopoLayerUrl = (url = '') =>
    TOPO_LAYERS_URLS.reduce((acc, ms) => acc || url.includes(ms), false);

  const isTmsCoordinatesSystem =
    layer.url ?
      layer.url.includes('maxar') || isTopoLayerUrl(layer.url)
    : false;

  if (layer.id === 'mapbox.satellite') {
    return {
      type: LAYER_RASTER_TYPE,
      maxzoom: layer.maxSourceZoom,
      tiles: [
        `https://a.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=${process.env.MAPBOX_ACCESS_TOKEN_MB}`,
      ],
      tileSize: LAYER_TILE_SIZE,
    };
  }

  if (layer.tileUrl) {
    return {
      type: LAYER_RASTER_TYPE,
      tiles: [layer.tileUrl],
      maxzoom: layer.maxSourceZoom || MAX_MAPBOX_ZOOM,
      tileSize: LAYER_TILE_SIZE,
    };
  } else if (layer.vectorial) {
    return {
      type: 'vector',
      tiles: [`${layer.url}?access_token=${process.env.MAPBOX_ACCESS_TOKEN}`],
    };
  } else if (directReferenceURL(layer)) {
    return {
      type: LAYER_RASTER_TYPE,
      url: layer.url,
      tileSize: LAYER_TILE_SIZE,
    };
  }

  if (isTmsCoordinatesSystem || isTopoLayerUrl(layer.tileUrl)) {
    return {
      type: LAYER_RASTER_TYPE,
      scheme: 'tms',
      maxzoom: layer.maxSourceZoom || MAX_MAPBOX_ZOOM,
      tiles: [layer.url + (layer.access_token || '') || ''],
      tileSize: LAYER_TILE_SIZE,
    };
  }

  return {
    type: LAYER_RASTER_TYPE,
    maxzoom: layer.maxSourceZoom || MAX_MAPBOX_ZOOM,
    tiles: [layer.url + (layer.access_token || '') || ''],
    tileSize: LAYER_TILE_SIZE,
    ...(layer.web_attribution && { attribution: layer.web_attribution }),
    ...(layer.with_bounds && { bounds: expandBoundingBox(mapBounds) }),
  };
};

// Check if the url has the form mapbox://{username}.{mapid}
const directReferenceURL = (layer) => /^mapbox:*/.test(layer.url);

export const maprightLayerToSource = (layer) => {
  const source = {
    scheme: layer.scheme,
    type: layer.source_type,
  };

  if (layer.source_type === LAYER_RASTER_TYPE) {
    return { ...source, tileSize: LAYER_TILE_SIZE, tiles: [layer.tile_url] };
  }

  if (layer.third_party) {
    if (layer.source_type === 'vector') {
      return {
        ...source,
        tiles: [layer.tile_url],
        ...(layer.min_zoom ? { minzoom: layer.min_zoom } : {}),
        ...(layer.max_zoom ? { maxzoom: layer.max_zoom } : {}),
      };
    }

    return { ...source, url: layer.tile_url };
  }

  return { ...source, url: layer.style_url };
};

export const maprightLayersStyle = (layer, mrLayerStyles) => {
  if (layer.source_type === 'vector') {
    const styles = mrLayerStyles[layer.layer_subgroup];
    if (!styles) {
      return [];
    }
    return styles.layers;
  }

  return [
    {
      type: LAYER_RASTER_TYPE,
      minzoom: layer.minMapZoom || 0,
      maxzoom: layer.maxMapZoom || MAX_MAPBOX_ZOOM,
    },
  ];
};

export const baseLayersStyle = (layer) => {
  const opacity = layer.opacity || 1;
  const visibility = 'visible';

  if (NAIP_LAYERS[layer.key] || INTERNAL_HEX_LAYERS[layer.key]) {
    // This is done to avoid breaking existing layers like MB satellite with high zoom levels
    // This should be changed in the future
    return {
      id: layer.key,
      type: LAYER_RASTER_TYPE,
      source: layer.key,
      layout: { visibility },
      paint: { 'raster-opacity': opacity },
      maxzoom: layer.maxLayerZoom,
      minzoom: layer.minLayerZoom,
    };
  }

  return {
    id: layer.key,
    type: LAYER_RASTER_TYPE,
    source: layer.key,
    layout: { visibility },
    paint: { 'raster-opacity': opacity },
  };
};

export const isLayerActive = (layer, activeBaseLayer) =>
  layer?.key === activeBaseLayer?.key;

export const reorderLayersWithPriority = (map) => {
  map
    .getStyle()
    .layers.filter(
      (lyr) =>
        lyr.id.includes(INTERACTIVE_LABELS_LAYER) ||
        lyr.id.includes(ICON_MARKER_LAYER),
    )
    .forEach((lyr) => {
      map.moveLayer(lyr.id);
    });

  CUSTOM_LABELS_LAYERS.forEach((lyr) => map.moveLayer(lyr));
  ELEMENT_LABELS_LAYERS.forEach((lyr) => map.moveLayer(lyr));
};

export const ICON_MARKER_LAYER = 'icons-marker';
export const CUSTOM_LABELS_LAYERS = [
  'labels-layer',
  'labels-with-background-layer',
];
export const ELEMENT_LABELS_LAYERS = [
  'lines-element-labels-layer',
  'element-labels-layer',
];
export const LAYERS_WITH_LABELS = ['parcels'];
export const PARCELS_CATEGORIZATION = 'Parcels';
export const INTERACTIVE_LABELS_LAYER = 'parcels_labels';
export const THIRD_PARTY_PARCELS_LAYER = 'parcels';
export const SELF_HOSTED_PARCELS_LAYER = 'parcels_mapbox';
export const LAYERS_WITH_LEGENDS = ['land_use', 'airports'];
