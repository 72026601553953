import React from 'react';
import { IconProps } from '../types';

const DiscoverCC = ({ width = 100, height = 67 }: IconProps) => (
  <svg
    id="discover-dark"
    width={width}
    height={height}
    viewBox="0 16.6 100.1 66.8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      {/* eslint-disable-next-line max-len */}
      <path
        fill="#282828"
        d="M100,76.7c0,3.7-3,6.7-6.7,6.7H6.7c-3.7,0-6.7-3-6.7-6.7V23.3c0-3.7,3-6.7,6.7-6.7h86.7c3.7,0,6.7,3,6.7,6.7L100,76.7L100,76.7z"
      />
      <path
        fill="#E57E25"
        d="M93.8,83.3c3.7,0,6.2-2.8,6.2-6.5V57.2c0,0-25.4,18.3-72.6,26.2L93.8,83.3L93.8,83.3z"
      />
      <path
        fill="#FFFFFF"
        d="M11.1,36.6h-4v14h4c2.1,0,3.6-0.5,5-1.6c1.6-1.3,2.5-3.3,2.5-5.4C18.6,39.4,15.5,36.6,11.1,36.6zM14.3,47.1c-0.9,0.8-2,1.1-3.7,1.1H9.8V39h0.7c1.8,0,2.8,0.3,3.7,1.1s1.5,2.1,1.5,3.5C15.8,44.9,15.2,46.2,14.3,47.1z"
      />
      <rect x="19.9" y="36.6" fill="#FFFFFF" width="2.7" height="14" />
      <path
        fill="#FFFFFF"
        d="M29.2,41.9c-1.6-0.6-2.1-1-2.1-1.8c0-0.9,0.9-1.5,2-1.5c0.8,0,1.5,0.3,2.2,1.1l1.4-1.9c-1.2-1-2.6-1.5-4.1-1.5c-2.5,0-4.4,1.7-4.4,4c0,1.9,0.9,2.9,3.4,3.8c1.1,0.4,1.6,0.6,1.9,0.8c0.5,0.4,0.8,0.9,0.8,1.4c0,1.1-0.9,2-2.1,2c-1.3,0-2.3-0.6-3-1.9l-1.8,1.7c1.3,1.8,2.8,2.7,4.8,2.7c2.8,0,4.8-1.9,4.8-4.6C33.3,44.1,32.3,43.1,29.2,41.9z"
      />
      <path
        fill="#FFFFFF"
        d="M34.1,43.6c0,4.1,3.2,7.3,7.4,7.3c1.2,0,2.2-0.2,3.4-0.8v-3.2c-1.1,1.1-2.1,1.5-3.3,1.5c-2.7,0-4.7-2-4.7-4.8c0-2.7,2-4.8,4.6-4.8c1.3,0,2.3,0.5,3.4,1.6v-3.2c-1.2-0.6-2.2-0.9-3.3-0.9C37.4,36.2,34.1,39.5,34.1,43.6z"
      />
      <polygon
        fill="#FFFFFF"
        points="66.5,45.9 62.8,36.6 59.8,36.6 65.7,50.9 67.2,50.9 73.2,36.6 70.3,36.6   "
      />
      <polygon
        fill="#FFFFFF"
        points="74.4,50.5 82.2,50.5 82.2,48.2 77.2,48.2 77.2,44.4 82,44.4 82,42 77.2,42 77.2,38.9 82.2,38.9 82.2,36.6 74.4,36.6   "
      />
      <path
        fill="#FFFFFF"
        d="M93.2,40.7c0-2.6-1.8-4.1-4.9-4.1h-4v14H87V45h0.4l3.8,5.6h3.3l-4.5-6C92,44.2,93.2,42.8,93.2,40.7zM87.7,43h-0.8v-4.2h0.8c1.7,0,2.6,0.7,2.6,2.1C90.4,42.2,89.4,43,87.7,43z"
      />
      <g>
        <circle fill="#F29C1F" cx="53.2" cy="43.6" r="7.4" />
        <circle fill="#E57E25" cx="53.2" cy="43.6" r="7.4" />
      </g>
    </g>
  </svg>
);

export default DiscoverCC;
