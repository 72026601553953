import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Phone = ({ fill = colors.black }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 2H7.5C6.83696 2 6.20107 2.26339 5.73223 2.73223C5.26339 3.20107 5 3.83696 5 4.5V19.5C5 20.163 5.26339 20.7989 5.73223 21.2678C6.20107 21.7366 6.83696 22 7.5 22H15.8333C16.4964 22 17.1323 21.7366 17.6011 21.2678C18.0699 20.7989 18.3333 20.163 18.3333 19.5V4.5C18.3333 3.83696 18.0699 3.20107 17.6011 2.73223C17.1323 2.26339 16.4964 2 15.8333 2ZM11.6667 20.725C11.5018 20.725 11.3407 20.6761 11.2037 20.5846C11.0666 20.493 10.9598 20.3628 10.8968 20.2106C10.8337 20.0583 10.8172 19.8907 10.8493 19.7291C10.8815 19.5674 10.9609 19.419 11.0774 19.3024C11.194 19.1859 11.3424 19.1065 11.5041 19.0743C11.6657 19.0422 11.8333 19.0587 11.9856 19.1218C12.1378 19.1848 12.268 19.2917 12.3596 19.4287C12.4511 19.5657 12.5 19.7268 12.5 19.8917C12.5 20.1127 12.4122 20.3246 12.2559 20.4809C12.0996 20.6372 11.8877 20.725 11.6667 20.725ZM16.6667 17C16.6667 17.221 16.5789 17.433 16.4226 17.5893C16.2663 17.7455 16.0543 17.8333 15.8333 17.8333H7.5C7.27899 17.8333 7.06702 17.7455 6.91074 17.5893C6.75446 17.433 6.66667 17.221 6.66667 17V4.94167C6.66667 4.72065 6.75446 4.50869 6.91074 4.35241C7.06702 4.19613 7.27899 4.10833 7.5 4.10833H15.8333C16.0543 4.10833 16.2663 4.19613 16.4226 4.35241C16.5789 4.50869 16.6667 4.72065 16.6667 4.94167V17Z"
      fill={fill}
    />
  </svg>
);

export default Phone;
