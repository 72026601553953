/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const BaseMap = ({ fill, variant, width = 20, height = 20 }) => {
  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.842 4.84375C17.6587 4.56875 17.4003 4.36042 17.092 4.24375L13.042 2.61875C12.6503 2.46042 12.217 2.46042 11.8253 2.61875L7.50866 4.34375C7.45866 4.36042 7.41699 4.36875 7.36699 4.36875C7.31699 4.36875 7.26699 4.36042 7.22533 4.34375L3.21699 2.74375C3.04199 2.67708 2.85866 2.65208 2.67533 2.66875C2.49199 2.68542 2.30866 2.75208 2.15866 2.86042C2.00033 2.96042 1.87533 3.11042 1.79199 3.26875C1.70866 3.43542 1.66699 3.61875 1.66699 3.80208V13.5187C1.66699 13.8438 1.76699 14.1604 1.95033 14.4354C2.13366 14.7104 2.39199 14.9187 2.70033 15.0354L6.75866 16.6604C6.95866 16.7354 7.15866 16.7771 7.36699 16.7771C7.57533 16.7771 7.77533 16.7354 7.97533 16.6604L12.292 14.9354C12.342 14.9187 12.3837 14.9104 12.4337 14.9104C12.4837 14.9104 12.5337 14.9187 12.5753 14.9354L16.5753 16.5354C16.7503 16.6021 16.9337 16.6271 17.117 16.6104C17.3003 16.5938 17.4753 16.5271 17.6337 16.4188C17.7837 16.3104 17.9087 16.1687 18.0003 16.0104C18.0837 15.8437 18.1337 15.6604 18.1337 15.4771V5.76042C18.1337 5.42708 18.0337 5.11042 17.8503 4.84375H17.842ZM7.98366 5.50208L11.8003 3.97708V13.7771L7.98366 15.3021V5.50208ZM2.98366 13.7354C2.94199 13.6687 2.91699 13.5938 2.91699 13.5187V3.96875L6.73366 5.49375V15.3104L3.15866 13.8854C3.08366 13.8521 3.02533 13.8021 2.98366 13.7437V13.7354ZM16.8753 15.3104L13.0587 13.7854V3.96875L16.6337 5.40208C16.7087 5.42708 16.767 5.47708 16.8087 5.54375C16.8503 5.61042 16.8753 5.68542 16.8753 5.76042V15.3021V15.3104Z"
        fill={fill}
      />
    </svg>
  );

  const filled = () => (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77 1.66234C5.96 1.73234 6.08 1.92234 6.08 2.12234H6.09V16.0423C6.09 16.3923 5.73 16.6323 5.4 16.5023L1.24 14.8423C0.87 14.7023 0.56 14.4523 0.34 14.1223C0.12 13.7923 0 13.4123 0 13.0223V1.36234C0 1.14234 0.05 0.92234 0.15 0.72234C0.25 0.53234 0.4 0.35234 0.59 0.23234C0.77 0.10234 0.99 0.0223402 1.21 0.00234021C1.44 -0.00765979 1.66 0.0123402 1.86 0.10234L5.77 1.66234ZM11.48 0.232313L7.9 1.66231C7.71 1.74231 7.59 1.92231 7.59 2.12231V16.0423C7.59 16.3923 7.95 16.6423 8.28 16.5023L11.86 15.0723C12.05 14.9923 12.17 14.8123 12.17 14.6123V0.692313C12.17 0.342313 11.81 0.0923127 11.48 0.232313ZM19.41 2.61235C19.19 2.28235 18.88 2.03235 18.51 1.89235L14.35 0.232349C14.02 0.102349 13.66 0.342349 13.66 0.692349V14.6123C13.66 14.8123 13.78 15.0023 13.97 15.0723L17.87 16.6323C18.08 16.7123 18.3 16.7423 18.52 16.7223C18.74 16.7023 18.95 16.6223 19.14 16.4923C19.32 16.3624 19.47 16.1923 19.58 16.0023C19.68 15.8023 19.74 15.5823 19.74 15.3623V3.72235C19.74 3.32235 19.62 2.94235 19.4 2.62235L19.41 2.61235Z"
        fill={fill}
      />
    </svg>
  );
  const customSizeOutlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.842 4.84375C17.6587 4.56875 17.4003 4.36042 17.092 4.24375L13.042 2.61875C12.6503 2.46042 12.217 2.46042 11.8253 2.61875L7.50866 4.34375C7.45866 4.36042 7.41699 4.36875 7.36699 4.36875C7.31699 4.36875 7.26699 4.36042 7.22533 4.34375L3.21699 2.74375C3.04199 2.67708 2.85866 2.65208 2.67533 2.66875C2.49199 2.68542 2.30866 2.75208 2.15866 2.86042C2.00033 2.96042 1.87533 3.11042 1.79199 3.26875C1.70866 3.43542 1.66699 3.61875 1.66699 3.80208V13.5187C1.66699 13.8438 1.76699 14.1604 1.95033 14.4354C2.13366 14.7104 2.39199 14.9187 2.70033 15.0354L6.75866 16.6604C6.95866 16.7354 7.15866 16.7771 7.36699 16.7771C7.57533 16.7771 7.77533 16.7354 7.97533 16.6604L12.292 14.9354C12.342 14.9187 12.3837 14.9104 12.4337 14.9104C12.4837 14.9104 12.5337 14.9187 12.5753 14.9354L16.5753 16.5354C16.7503 16.6021 16.9337 16.6271 17.117 16.6104C17.3003 16.5938 17.4753 16.5271 17.6337 16.4188C17.7837 16.3104 17.9087 16.1687 18.0003 16.0104C18.0837 15.8437 18.1337 15.6604 18.1337 15.4771V5.76042C18.1337 5.42708 18.0337 5.11042 17.8503 4.84375H17.842ZM7.98366 5.50208L11.8003 3.97708V13.7771L7.98366 15.3021V5.50208ZM2.98366 13.7354C2.94199 13.6687 2.91699 13.5938 2.91699 13.5187V3.96875L6.73366 5.49375V15.3104L3.15866 13.8854C3.08366 13.8521 3.02533 13.8021 2.98366 13.7437V13.7354ZM16.8753 15.3104L13.0587 13.7854V3.96875L16.6337 5.40208C16.7087 5.42708 16.767 5.47708 16.8087 5.54375C16.8503 5.61042 16.8753 5.68542 16.8753 5.76042V15.3021V15.3104Z"
        fill={fill}
      />
    </svg>
  );
  switch (variant) {
    case 'outlined':
      return outlined();
    case 'customSizeOutlined':
      return customSizeOutlined();
    default:
      return filled();
  }
};

BaseMap.propTypes = {
  fill: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'filled']),
};

BaseMap.defaultProps = {
  fill: '#1D1D1D',
  variant: 'outlined',
};

export default BaseMap;
