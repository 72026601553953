import { IconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const Line = ({
  fill = colorPalette.blackLight,
  width = 18,
  height = 18,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 2.25C13.8096 2.25 13.25 2.80964 13.25 3.5C13.25 4.19036 13.8096 4.75 14.5 4.75C15.1903 4.75 15.75 4.19036 15.75 3.5C15.75 2.80964 15.1903 2.25 14.5 2.25ZM11.75 3.5C11.75 1.98122 12.9812 0.75 14.5 0.75C16.0188 0.75 17.25 1.98122 17.25 3.5C17.25 5.01878 16.0188 6.25 14.5 6.25C14.0152 6.25 13.5598 6.12458 13.1643 5.90443L5.92371 13.1996C6.13189 13.5868 6.25 14.0296 6.25 14.5C6.25 16.0188 5.01878 17.25 3.5 17.25C1.98122 17.25 0.75 16.0188 0.75 14.5C0.75 12.9812 1.98122 11.75 3.5 11.75C4.00323 11.75 4.47488 11.8852 4.88064 12.1212L12.1014 4.84606C11.8776 4.44818 11.75 3.98899 11.75 3.5ZM4.39286 13.6252C4.16598 13.3937 3.84977 13.25 3.5 13.25C2.80964 13.25 2.25 13.8097 2.25 14.5C2.25 15.1904 2.80964 15.75 3.5 15.75C4.19036 15.75 4.75 15.1904 4.75 14.5C4.75 14.1711 4.62298 13.8719 4.41531 13.6487C4.40763 13.641 4.40014 13.6332 4.39286 13.6252Z"
      fill={fill}
    />
  </svg>
);

export default Line;
