import { StyleableOnlyIconProps } from '../types';
import * as colorPalette from '../../styles/colors.module.scss';

const Pin = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.03 22C11.75 22 11.49 21.89 11.29 21.7C11.09 21.5 10.98 21.24 10.98 20.96V18.41C10.98 18.3 10.98 18.26 10.98 18.22C10.37 16.86 9.68 15.64 8.88 14.49C8.13 13.32 7.44 12.25 6.91 11.17C6.3 9.94 6 8.92 6 8.04C6 6.43 6.63 4.91 7.77 3.77C8.92 2.63 10.44 2 12.05 2C12.85 2 13.63 2.16 14.37 2.46C15.11 2.77 15.77 3.21 16.33 3.78C16.89 4.35 17.33 5.01 17.64 5.75C17.94 6.49 18.1 7.27 18.1 8.07C18.1 8.95 17.8 9.97 17.2 11.2C16.66 12.28 15.94 13.4 15.25 14.49C14.4 15.69 13.67 16.93 13.08 18.23C13.08 18.27 13.08 18.31 13.08 18.35V20.98C13.08 21.26 12.97 21.52 12.78 21.72C12.59 21.91 12.32 22.02 12.05 22.02L12.03 22ZM12.05 3.5C10.84 3.5 9.7 3.98 8.84 4.83C7.98 5.69 7.51 6.83 7.51 8.04C7.51 8.68 7.75 9.49 8.26 10.5C8.76 11.5 9.42 12.54 10.13 13.64C10.85 14.67 11.49 15.77 12.04 16.91C12.62 15.74 13.28 14.66 14 13.64C14.69 12.56 15.36 11.5 15.85 10.52C16.35 9.51 16.6 8.7 16.6 8.06C16.6 7.45 16.49 6.87 16.26 6.31C16.03 5.75 15.7 5.26 15.28 4.83C14.86 4.41 14.36 4.07 13.8 3.84C13.25 3.61 12.66 3.49 12.06 3.49L12.05 3.5ZM12.01 11C10.36 11 9.01 9.65 9.01 8C9.01 6.35 10.36 5 12.01 5C13.66 5 15.01 6.35 15.01 8C15.01 9.65 13.66 11 12.01 11ZM12.01 6.5C11.18 6.5 10.51 7.17 10.51 8C10.51 8.83 11.18 9.5 12.01 9.5C12.84 9.5 13.51 8.83 13.51 8C13.51 7.17 12.84 6.5 12.01 6.5Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0417 2C10.44 2.0022 8.90455 2.63944 7.772 3.772C6.63944 4.90455 6.0022 6.43999 6 8.04167C6 10.0083 7.45833 12.275 8.86667 14.475C9.6862 15.6527 10.3921 16.9056 10.975 18.2167C10.9829 18.2636 10.9829 18.3114 10.975 18.3583V20.9583C10.975 21.2346 11.0847 21.4996 11.2801 21.6949C11.4754 21.8903 11.7404 22 12.0167 22C12.2929 22 12.5579 21.8903 12.7532 21.6949C12.9486 21.4996 13.0583 21.2346 13.0583 20.9583V18.3583C13.0504 18.3114 13.0504 18.2636 13.0583 18.2167C13.6585 16.9123 14.3812 15.6678 15.2167 14.5C16.625 12.3 18.0833 10.0333 18.0833 8.06667C18.0855 7.27147 17.9309 6.48364 17.6284 5.74824C17.3259 5.01284 16.8813 4.34429 16.3202 3.78084C15.759 3.21739 15.0923 2.77008 14.3582 2.46451C13.6241 2.15894 12.8369 2.00109 12.0417 2V2ZM12.0417 10.9583C11.4648 10.9583 10.9009 10.7873 10.4213 10.4668C9.94161 10.1463 9.56777 9.69078 9.34702 9.15783C9.12626 8.62488 9.0685 8.03843 9.18104 7.47265C9.29358 6.90688 9.57137 6.38718 9.97927 5.97927C10.3872 5.57137 10.9069 5.29358 11.4727 5.18104C12.0384 5.0685 12.6249 5.12626 13.1578 5.34702C13.6908 5.56777 14.1463 5.94161 14.4668 6.42125C14.7873 6.9009 14.9583 7.4648 14.9583 8.04167C14.9583 8.81521 14.651 9.55708 14.1041 10.1041C13.5571 10.651 12.8152 10.9583 12.0417 10.9583Z"
          fill={color}
        />
      </svg>;
};

export default Pin;
