import { lazy, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import Layout from '../layout/Layout';
import PageNotFound from './PageNotFound';
import store from 'store';
import ReleaseNotes from './ReleaseNotes';
import EllipseSpinner from 'components/EllipseSpinner';
import MobileRedirector from 'components/MobileRedirector';
import uaParser from 'utils/userAgentParser';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import EmbedMapPageLoader from 'packages/share-map/pages/EmbedMapPage/Loader';
import { useFeatureFlags } from 'services/FeatureFlags';
const CheckoutPage = lazy(
  () => import('packages/subscriptions/pages/CheckoutPage'),
);
const PlansPage = lazy(() => import('packages/subscriptions/pages/PlansPage'));
const WelcomeToFreeTrialPage = lazy(
  () => import('packages/subscriptions/pages/WelcomeToFreeTrialPage'),
);
const DashboardPage = lazy(
  () => import('packages/dashboard/pages/DashboardPage'),
);
const ShareMapPage = lazy(
  () => import('packages/share-map/pages/ShareMapPage'),
);
const EditMapPage = lazy(() => import('packages/edit-map/pages/EditMapPage'));
const CreateNewMapPage = lazy(
  () => import('packages/edit-map/components/CreateNewMap'),
);
const AccountPage = lazy(() => import('packages/profile/pages/AccountPage'));
const ReportsPage = lazy(() => import('packages/reports/pages/ReportsPage'));
const DiscoverPage = lazy(
  () => import('packages/discover-map/pages/DiscoverPage'),
);
const SignInPage = lazy(() => import('packages/auth/pages/SignInPage'));
const SignUpPage = lazy(() => import('packages/auth/pages/SignUpPage'));
const RecoverPasswordPage = lazy(
  () => import('packages/auth/pages/RecoverPasswordPage'),
);
const EmailVerificationPage = lazy(
  () => import('packages/auth/pages/EmailVerificationPage'),
);
const EmbedSignInPage = lazy(
  () => import('packages/auth/pages/EmbedSignInPage'),
);
const InvalidTokenPage = lazy(
  () => import('packages/auth/pages/InvalidTokenPage'),
);
const AuthenticatePage = lazy(
  () => import('packages/auth/pages/AuthenticatePage'),
);
const Impersonate = lazy(() => import('packages/auth/components/Impersonate'));

const renderReleaseNotes = () => {
  const { user } = store.getState();
  const admin = user?.user?.admin;
  if (!admin) {
    return <Navigate to="/dashboard" />;
  }
  return <ReleaseNotes />;
};

const RedirectBasedOnSubscription = () => {
  const subscriptionPlan = store.getState()?.user?.user?.subscription_plan;
  const redirectTo =
    subscriptionPlan?.includes('discover') ? '/discover' : '/dashboard';

  return <Navigate to={redirectTo} />;
};

const AppRoutes = () => {
  const { isMobile } = uaParser;
  const featureFlags = useFeatureFlags();
  const isAutosaveEnabled = featureFlags.autosaveCreate320?.value;

  return (
    <div style={{ height: '100%' }}>
      <Suspense
        fallback={
          <Layout>
            <EllipseSpinner />
          </Layout>
        }
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/verify" element={<EmailVerificationPage />} />
            <Route index element={<RedirectBasedOnSubscription />} />
            <Route path="/release-notes" element={renderReleaseNotes()} />
            {!isMobile() && (
              <Route path="discover" element={<DiscoverPage />} />
            )}
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="reports" element={<ReportsPage />} />

            <Route path="plans" element={<PlansPage />} />
            <Route
              path="plans/verified"
              element={<PlansPage showVerifiedGreeting />}
            />
            <Route
              path="plans/:planCodeParam/:schema?"
              element={<CheckoutPage />}
            />

            <Route path="profile" element={<AccountPage />} />
            <Route
              path="impersonate_user/:user_id/:token/*"
              element={<Impersonate />}
            />
          </Route>

          <Route index path="/users/sign_in" element={<SignInPage />} />

          <Route
            path="/users/new_password/:reset_password_token?"
            element={<RecoverPasswordPage />}
          />

          <Route
            path="/users/sign_up/:utm_source?/:utm_medium?/:utm_campaign?/:utm_content?"
            element={<SignUpPage isDarkBanner />}
          />

          <Route path="/authenticate/:token" element={<AuthenticatePage />} />
          <Route path="/authenticate/expired" element={<InvalidTokenPage />} />

          <Route
            path="/welcome-to-free-trial"
            element={<WelcomeToFreeTrialPage />}
          />
          <Route
            path="/users/embed_sign_in/:email/:auth_token"
            element={<EmbedSignInPage />}
          />

          {isMobile() && (
            <Route path="discover" element={<MobileRedirector />} />
          )}
          <Route
            path="/:toolbox?/maps/new"
            element={
              isMobile() ? <MobileRedirector />
              : isAutosaveEnabled ?
                <CreateNewMapPage />
              : <EditMapPage />
            }
          />
          <Route
            path="/:toolbox?/maps/:mapId"
            element={isMobile() ? <MobileRedirector /> : <EditMapPage />}
          />
          <Route
            path="/:toolbox?/maps/:mapSlug/share/:unbranded?"
            element={<ShareMapPage />}
          />

          <Route
            path="/:toolbox?/maps/:mapSlug/embed/:unbranded?"
            element={<EmbedMapPageLoader />}
          />
          <Route
            path="/:toolbox?/maps/:mapSlug/embed/:minimal?/:minimal_value?"
            element={<EmbedMapPageLoader />}
          />
          <Route
            path="/:toolbox?/maps/:mapSlug/embed/:unbranded?/:minimal?/:minimal_value?"
            element={<EmbedMapPageLoader />}
          />

          <Route path="/404" element={<PageNotFound />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRoutes;
