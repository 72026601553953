import settingsActionTypes from '../actions/settings/actions';
import actionTypes from '../actions/layerPopups/actions';
import { produce } from 'immer';

const ACTION_HANDLERS = {
  [actionTypes.CHANGE_SELECTED_TOOL_INSTANCE]: (state, action) => ({
    ...state,
    selectedToolInstance: action.selectedToolInstance,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
  [actionTypes.FETCH_POPUP_INFO_SUCCESS]: (state, action) => ({
    ...state,
    popupInfo: action.data,
  }),
  [actionTypes.FETCH_POPUP_INFO_ERROR]: (_state, _action) => ({
    ...initialState,
  }),
  [actionTypes.SET_POPUP_LAYERS_DATA]: (state, action) => ({
    ...state,
    popupData: action.data,
  }),
};

const initialState = {
  selectedToolInstance: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ?
      produce(state, (draftState) => handler(draftState, action))
    : state;
};
