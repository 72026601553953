import { isEmpty } from 'lodash';
import center from '@turf/center';

import settingsActionTypes from '../actions/settings/actions';
import { planeCoordinatesToLatLng } from 'utils/geoUtilities';
import actionTypes from '../actions/search/actions';

const ACTION_HANDLERS = {
  [actionTypes.SHOW_LOCATION_MARKER]: (state, action) => ({
    ...state,
    center: action.lngLat,
    marker: true,
    color: action.color,
    flyToLocation: action.flyToLocation,
  }),
  [actionTypes.HIDE_LOCATION_MARKER]: (state, action) => ({
    ...state,
    marker: false,
  }),
  [actionTypes.SEARCH_ABSTRACT_SUCCESS]: (state, action) => {
    const { data = [] } = action;

    if (data.length > 0) {
      const abstracts = data.map((abstract) => {
        const geometry = planeCoordinatesToLatLng(abstract.shape);
        return { center: center(geometry), result: abstract.result };
      });

      return {
        ...state,
        searchingAbstract: false,
        abstractsResults: abstracts,
      };
    }

    return {
      ...state,
      alerts: true,
      searchingAbstract: false,
      abstractsResults: [],
    };
  },
  [actionTypes.SEARCH_ABSTRACT_FAILURE]: (state, action) => ({
    ...state,
    center: null,
    searchingAbstract: false,
    abstractsResults: [],
  }),
  [actionTypes.SEARCHING_ABSTRACT]: (state, action) => ({
    ...state,
    searchingAbstract: true,
    alerts: false,
  }),
  [actionTypes.SEARCH_TRS_SUCCESS]: (state, action) => {
    if (action.data) {
      const center = [Number(action.data.lng), Number(action.data.lat)];
      return { ...state, center, searchingTRS: false };
    }

    return { ...state, alerts: true, searchingTRS: false };
  },
  [actionTypes.SEARCH_TRS_FAILURE]: (state, action) => ({
    ...state,
    center: null,
    searchingTRS: false,
  }),
  [actionTypes.SEARCHING_TRS]: (state, action) => ({
    ...state,
    searchingTRS: true,
    alerts: false,
  }),
  [actionTypes.SEARCH_APN_SUCCESS]: (state, action) => {
    if (!isEmpty(action.data)) {
      return { ...state, searchingAPN: false };
    }

    return { ...state, alerts: true, searchingAPN: false };
  },
  [actionTypes.SEARCH_PARCELS_SUCCESS]: (state, action) => {
    if (!isEmpty(action.data)) {
      return { ...state, searchingParcels: false, searchingAPN: false };
    }

    return { ...state, alerts: true, searchingParcels: false };
  },
  [actionTypes.SEARCH_APN_FAILURE]: (state, action) => ({
    ...state,
    center: null,
    searchingAPN: false,
  }),
  [actionTypes.SEARCHING_APN]: (state, action) => ({
    ...state,
    searchingAPN: true,
    alerts: false,
  }),
  [actionTypes.SEARCHING_PARCELS]: (state, action) => ({
    ...state,
    searchingParcels: true,
    alerts: false,
  }),
  [actionTypes.CLEAR_SEARCH]: (state, action) => ({
    ...state,
    center: null,
  }),
  [actionTypes.CLEAR_ALERTS]: (state, action) => ({
    ...state,
    alerts: false,
  }),
  [actionTypes.SEARCH_VIEWS_LAYERS_FEATURES_CHANGE]: (state, action) => ({
    ...state,
    searchViewLayersFeatures: action.search,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

const initialState = {
  center: null,
  marker: false,
  color: 'red',
  flyToLocation: true,
  alerts: false,
  searchingAbstract: false,
  searchingTRS: false,
  searchingAPN: false,
  searchingParcels: false,
  searchViewLayersFeatures: '',
  abstractsResults: [],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
