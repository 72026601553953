import actionTypes from 'actions/parcelReports/actions';

const ACTION_HANDLERS = {
  [actionTypes.LOAD_PARCELS_REPORTS_START]: (state, action) => ({
    ...state,
    parcelReportsLoaded: false,
  }),
  [actionTypes.LOAD_PARCELS_REPORTS_SUCCESS]: (state, action) => ({
    ...state,
    parcelReports: action.data.records,
    parcelReportsLoaded: true,
    totalReports: action.data.total,
  }),
  [actionTypes.LOAD_PARCELS_REPORTS_FAILURE]: (state, action) => ({
    ...state,
    parcelReports: [],
    parcelReportsLoaded: true,
  }),
};

const initialState = {
  parcelReportsLoaded: false,
  parcelReports: [],
  totalReports: 0,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
