import { NonConfigurableIconProps } from '../types';

const Sync = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.384 3H5.6933C5.24421 3 4.81351 3.1784 4.49596 3.49596C4.1784 3.81351 4 4.24421 4 4.6933V6.15464C4 6.41097 4.10183 6.6568 4.28308 6.83805C4.46433 7.01931 4.71016 7.12113 4.96649 7.12113C5.22283 7.12113 5.46866 7.01931 5.64991 6.83805C5.83116 6.6568 5.93299 6.41097 5.93299 6.15464V5.12629C5.93299 5.07502 5.95336 5.02586 5.98961 4.98961C6.02586 4.95336 6.07502 4.93299 6.12629 4.93299H10.3789C10.4301 4.93299 10.4793 4.95336 10.5155 4.98961C10.5518 5.02586 10.5722 5.07502 10.5722 5.12629V18.8737C10.5722 18.925 10.5518 18.9741 10.5155 19.0104C10.4793 19.0466 10.4301 19.067 10.3789 19.067H8.97938C8.72305 19.067 8.47722 19.1688 8.29597 19.3501C8.11471 19.5313 8.01289 19.7772 8.01289 20.0335C8.01289 20.2898 8.11471 20.5357 8.29597 20.7169C8.47722 20.8982 8.72305 21 8.97938 21H14.0979C14.3543 21 14.6001 20.8982 14.7814 20.7169C14.9626 20.5357 15.0644 20.2898 15.0644 20.0335C15.0644 19.7772 14.9626 19.5313 14.7814 19.3501C14.6001 19.1688 14.3543 19.067 14.0979 19.067H12.6985C12.6472 19.067 12.598 19.0466 12.5618 19.0104C12.5255 18.9741 12.5052 18.925 12.5052 18.8737V5.12629C12.5052 5.07502 12.5255 5.02586 12.5618 4.98961C12.598 4.95336 12.6472 4.93299 12.6985 4.93299H16.951C17.0023 4.93299 17.0515 4.95336 17.0877 4.98961C17.124 5.02586 17.1443 5.07502 17.1443 5.12629V6.15464C17.1443 6.41097 17.2462 6.6568 17.4274 6.83805C17.6087 7.01931 17.8545 7.12113 18.1108 7.12113C18.3672 7.12113 18.613 7.01931 18.7942 6.83805C18.9755 6.6568 19.0773 6.41097 19.0773 6.15464V4.6933C19.0773 4.24421 18.8989 3.81351 18.5814 3.49596C18.2638 3.1784 17.8331 3 17.384 3Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Sync;
