import React from 'react';
import PropTypes from 'prop-types';

const Indent = ({ fill }) => (
  <svg
    width="14"
    height="45"
    viewBox="0 0 14 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M1 0V21C1 23.2091 2.79086 25 5 25H14"
      stroke={fill}
    />
  </svg>
);

Indent.propTypes = {
  fill: PropTypes.string,
};

Indent.defaultProps = {
  fill: '#B9B9B9',
};

export default Indent;
