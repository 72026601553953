const ACTION_HANDLERS = {};

const initialState = {
  filters: [],
  tools: [],
  modified: [],
  deleted: [],
  duplicated: [],
  featureListFilter: {
    name: 'all',
    filter: [],
  },
};
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
