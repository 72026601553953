/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const GrayWaypointMarker = ({ height, width }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50804 27.6548L9.49856 27.6455L9.48874 27.6365C7.18579 25.5285 5.75 22.5271 5.75 19.1986V14.2847C5.75 7.92656 10.9984 2.75 17.5 2.75H22.5C29.0016 2.75 34.25 7.92656 34.25 14.2847V19.1986C34.25 22.5245 32.8165 25.5232 30.517 27.6313L30.5073 27.6401L30.4981 27.6492L21.2423 36.7456C20.5579 37.4181 19.4425 37.4181 18.7581 36.7456L9.50804 27.6548Z"
      fill="#686868"
      stroke={colorPalette.blackLighter}
      strokeWidth="1.5"
    />
    <circle cx="20" cy="17" r="6" fill="white" />
  </svg>
);

GrayWaypointMarker.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GrayWaypointMarker.defaultProps = {
  width: '40',
  height: '40',
};

export default GrayWaypointMarker;
