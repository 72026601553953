import { StyleableOnlyIconProps } from '../types';

const Logout = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_422_4043)">
          <path
            d="M2.11304 0.75C2.10059 0.75 2.08814 0.75031 2.07571 0.75093C1.49817 0.779719 0.955564 1.03617 0.566687 1.46413C0.182067 1.88741 -0.0211797 2.44436 4.48938e-05 3.01539V20.9848C-0.0208556 21.5557 0.182482 22.1124 0.566991 22.5356C0.955693 22.9634 1.49793 23.2199 2.07519 23.249C2.0878 23.2497 2.10042 23.25 2.11304 23.25H14.386C14.3985 23.25 14.4109 23.2497 14.4234 23.2491C15.0009 23.2203 15.5435 22.9639 15.9326 22.5361C16.3174 22.1129 16.5209 21.556 16.5 20.9849V16.5C16.5 16.0858 16.1643 15.75 15.75 15.75C15.3358 15.75 15 16.0858 15 16.5V21C15 21.0045 15.0001 21.0089 15.0002 21.0134C15.0002 21.0164 15.0003 21.0194 15.0004 21.0225L15.0008 21.0338C15.009 21.2153 14.945 21.3926 14.8228 21.527C14.704 21.6576 14.5397 21.7373 14.364 21.75H2.13545C1.95991 21.7372 1.79576 21.6574 1.67714 21.5268C1.55503 21.3925 1.49109 21.2152 1.49928 21.0338C1.49979 21.0226 1.50004 21.0113 1.50004 21V3C1.50004 2.98854 1.49978 2.97709 1.49926 2.96564C1.49094 2.78437 1.5548 2.60719 1.67683 2.47288C1.79544 2.34235 1.95962 2.26267 2.13514 2.25H14.364C14.5397 2.26268 14.704 2.34241 14.8228 2.47302C14.945 2.60741 15.009 2.78473 15.0008 2.96619C15.0003 2.97745 15 2.98873 15 3V7.5C15 7.91421 15.3358 8.25 15.75 8.25C16.1643 8.25 16.5 7.91421 16.5 7.5V3.01514C16.5209 2.444 16.3174 1.88707 15.9326 1.46387C15.5435 1.03605 15.0009 0.779717 14.4234 0.75093C14.4109 0.75031 14.3985 0.75 14.386 0.75H2.11304Z"
            fill="#1D1D1D"
          />
          <path
            d="M18.9697 7.72358C19.2626 7.43068 19.7375 7.43068 20.0304 7.72358L23.7804 11.4736C23.8523 11.5455 23.9066 11.6284 23.9432 11.7168C23.9798 11.8052 24.0001 11.9022 24.0001 12.0039C24.0001 12.1958 23.9268 12.3878 23.7804 12.5342L20.0304 16.2842C19.7375 16.5771 19.2626 16.5771 18.9697 16.2842C18.6768 15.9913 18.6768 15.5165 18.9697 15.2236L21.4394 12.7539H7.50006C7.08585 12.7539 6.75006 12.4181 6.75006 12.0039C6.75006 11.5897 7.08585 11.2539 7.50006 11.2539H21.4394L18.9697 8.78424C18.6768 8.49134 18.6768 8.01647 18.9697 7.72358Z"
            fill="#1D1D1D"
          />
        </g>
        <defs>
          <clipPath id="clip0_422_4043">
            <rect width={width} height={height} fill="white" />
          </clipPath>
        </defs>
      </svg>
    : <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.70652C0 1.62596 0.984974 0.75 2.2 0.75H14.3C15.515 0.75 16.5 1.62596 16.5 2.70652V9.12636H7.0125C5.49372 9.12636 4.2625 10.2213 4.2625 11.572C4.2625 12.9227 5.49372 14.0177 7.0125 14.0177H16.5V21.2935C16.5 22.374 15.515 23.25 14.3 23.25H2.2C0.984973 23.25 0 22.374 0 21.2935V2.70652Z"
          fill="#1D1D1D"
        />
        <path
          d="M18.9697 7.21967C19.2626 6.92678 19.7374 6.92678 20.0303 7.21967L23.7803 10.9697C23.8522 11.0416 23.9065 11.1245 23.9431 11.2129C23.9798 11.3013 24 11.3983 24 11.5C24 11.6919 23.9268 11.8839 23.7803 12.0303L20.0303 15.7803C19.7374 16.0732 19.2626 16.0732 18.9697 15.7803C18.6768 15.4874 18.6768 15.0126 18.9697 14.7197L21.4393 12.25H7.5C7.08579 12.25 6.75 11.9142 6.75 11.5C6.75 11.0858 7.08579 10.75 7.5 10.75H21.4393L18.9697 8.28033C18.6768 7.98744 18.6768 7.51256 18.9697 7.21967Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Logout;
