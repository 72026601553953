import * as colors from '../../styles/colors.module.scss';
import { IconProps } from '../types';

const EyeSlash = ({
  fill = colors.black,
  width = 18,
  height = 19,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4394 19.4999C18.7323 19.7928 19.2072 19.7928 19.5001 19.4999C19.793 19.2071 19.793 18.7322 19.5001 18.4393L5.06072 3.99994C4.76782 3.70705 4.29295 3.70705 4.00006 3.99994C3.70716 4.29284 3.70716 4.76771 4.00006 5.0606L6.04482 7.10537C4.48747 8.21089 3.30567 9.50283 2.63283 10.3244C2.21742 10.8317 2.13982 11.5175 2.44894 12.0957C3.49021 14.0434 6.4173 18.3332 12 18.3332C13.6641 18.3332 15.0922 17.9521 16.3048 17.3654L18.4394 19.4999ZM15.1687 16.2293L14.1093 15.1698C13.4944 15.5379 12.7723 15.7499 12 15.7499C9.75519 15.7499 7.93541 13.959 7.93541 11.7499C7.93541 10.9669 8.16402 10.2365 8.55912 9.61967L7.12231 8.18286C6.6518 8.5033 6.20838 8.85111 5.79625 9.20916C4.9852 9.91378 4.33757 10.6219 3.89353 11.1536C3.80186 11.2634 3.78659 11.418 3.85589 11.5431L3.85865 11.5481C4.19247 12.15 4.70425 12.9507 5.41222 13.7463C6.82396 15.3328 8.94646 16.8332 12 16.8332C13.1943 16.8332 14.2461 16.6037 15.1687 16.2293ZM9.67668 10.7372C9.53715 11.0468 9.45963 11.3894 9.45963 11.7499C9.45963 13.1306 10.597 14.2499 12 14.2499C12.3518 14.2499 12.687 14.1795 12.9917 14.0522L9.67668 10.7372Z"
      fill={fill}
    />
    <path
      d="M21.551 12.0957C21.0624 13.0096 20.1586 14.4392 18.7601 15.6993L17.6981 14.6373C18.0217 14.3501 18.3177 14.0498 18.5878 13.7463C19.2957 12.9507 19.8075 12.15 20.1413 11.5481L20.1441 11.5431C20.2134 11.418 20.1981 11.2634 20.1065 11.1536C19.6624 10.6219 19.0148 9.91378 18.2037 9.20916C16.5495 7.77201 14.3913 6.49989 12 6.49989C11.2705 6.49989 10.5628 6.61826 9.88436 6.82359L8.70607 5.64531C9.72325 5.2483 10.828 4.99989 12 4.99989C16.4895 4.99989 19.9917 8.64494 21.3671 10.3244C21.7826 10.8317 21.8602 11.5175 21.551 12.0957Z"
      fill={fill}
    />
    <path
      d="M16.0646 11.7499C16.0646 12.1309 16.0104 12.4995 15.9093 12.8486L14.5238 11.4631C14.3917 10.3248 13.4828 9.41877 12.3318 9.27102L10.9464 7.88559C11.2824 7.79708 11.6356 7.74989 12 7.74989C14.2448 7.74989 16.0646 9.54075 16.0646 11.7499Z"
      fill={fill}
    />
  </svg>
);

export default EyeSlash;
