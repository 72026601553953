import actionTypes from '../actions/mapToolsControl/actions';
import settingsActionTypes from '../actions/settings/actions';

// we need to disable this rule for now because we don't want to import from
// the whole `package/map-kit` module to avoid import cycles
// we will think of a better way to handle this in the future
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { finishBuffer } from 'packages/map-kit/store/bufferToolSlice';
import update from 'immutability-helper';
import { merge } from 'lodash';

const ACTION_HANDLERS = {
  [actionTypes.CHANGE_ACTIVE_TOOL]: (state, action) => ({
    ...state,
    activeTool: action.toolName,
  }),
  [actionTypes.DESELECT_ACTIVE_TOOL]: (state, action) => ({
    ...state,
    activeTool: null,
  }),
  [actionTypes.START_MEASURING]: (state, action) => ({
    ...state,
    measuringWith: action.measureType,
    toolMode: 'measuring',
  }),
  [actionTypes.STOP_MEASURING]: (state) => ({
    ...state,
    measuringWith: null,
    toolMode: null,
  }),
  [actionTypes.TOGGLE_LABELS]: (state) => ({
    ...state,
    labelsActivated: !state.labelsActivated,
  }),
  [actionTypes.CHANGE_ACTIVE_LABEL_TOOL]: (state, action) => ({
    ...state,
    activeLabelTool: action.toolName,
  }),
  [actionTypes.DESELECT_ACTIVE_LABEL_TOOL]: (state, action) => ({
    ...state,
    activeLabelTool: null,
  }),
  [finishBuffer.type]: (state) => ({
    ...state,
    activeLabelTool: null,
  }),
  [actionTypes.ADD_TOOL]: (state, action) => {
    if (state.toolMode === 'measuring') {
      const nextIndex = state.drawnTools.length;
      const maprightId = `${action.toolType}_measure_tool_${nextIndex}`;

      return {
        ...state,
        drawnTools: state.drawnTools.concat({
          ...action.geoJSON,
          maprightId,
        }),
      };
    }

    return {
      ...state,
      parcelCsvPolygon: action.geoJSON,
    };
  },
  [actionTypes.HIDE_MEASURE_TOOLS]: (state, action) => ({
    ...state,
    drawnTools: update(state.drawnTools, {
      $apply: (drawnTools) =>
        drawnTools.map((element) => {
          if (element.properties.toolType === action.toolType) {
            return merge({}, element, {
              properties: {
                visibility: false,
              },
            });
          }
          return element;
        }),
    }),
  }),
  [actionTypes.SHOW_MEASURE_TOOLS]: (state, action) => ({
    ...state,
    drawnTools: update(state.drawnTools, {
      $apply: (drawnTools) =>
        drawnTools.map((element) => {
          if (element.properties.toolType === action.toolType) {
            return merge({}, element, {
              properties: {
                visibility: true,
              },
            });
          }
          return element;
        }),
    }),
  }),
  [actionTypes.TOGGLE_MEASURE_TOOL_VISIBILITY]: (state, action) => ({
    ...state,
    drawnTools: update(state.drawnTools, {
      $apply: (drawnTools) =>
        drawnTools.map((element) => {
          if (element.maprightId === action.toolId) {
            return merge({}, element, {
              properties: {
                visibility: !element.properties.visibility,
              },
            });
          }
          return element;
        }),
    }),
  }),
  [actionTypes.START_PARCEL_CSV_EXPORT]: (state, action) => ({
    ...state,
    parcelCsvPolygon: null,
    toolMode: 'export-parcel-csv',
    CSVExportEnabled: true,
  }),
  [actionTypes.FINISH_PARCEL_CSV_EXPORT]: (state, action) => ({
    ...state,
    toolMode: null,
    parcelCsvPolygon: null,
    CSVExportEnabled: false,
  }),
  [actionTypes.TOGGLE_PARCEL_CSV_EXPORT_STATUS]: (state, action) => ({
    ...state,
    CSVExportEnabled: !state.CSVExportEnabled,
  }),
  [actionTypes.ACTIVATE_TERRAIN]: (state, action) => ({
    ...state,
    terrain: true,
  }),
  [actionTypes.DEACTIVATE_TERRAIN]: (state, action) => ({
    ...state,
    terrain: false,
  }),
  [settingsActionTypes.RESET_MAP_VIEW_STATE]: (state, action) => ({
    ...initialState,
  }),
};

const initialState = {
  activeTool: null,
  activeLabelTool: null,
  measuringWith: null,
  labelsActivated: false,
  toolMode: null,
  drawnTools: [],
  parcelCsvPolygon: null,
  CSVExportEnabled: false,
  terrain: false,
  // TODO: Refactor and propose a new structure for default tools
  defaultTools: {
    polylines: {
      tool_type: 'polylines',
      name: 'Measuring Tool',
      tool_group: 'measure_perimeter',
      data: {
        default_name: 'Measuring Tool',
      },
      style: {
        color: '#f06eaa',
        opacity: 0.5,
        weight: 3.6,
      },
    },
    polygons: {
      tool_type: 'polygons',
      name: 'Measuring Tool',
      tool_group: 'measure_area',
      data: {
        default_name: 'Measuring Tool',
      },
      style: {
        color: '#f06eaa',
        opacity: 0.5,
        fillColor: '#f06eaa',
        fillOpacity: 0.2,
        weight: 3.6,
      },
    },
    circle_polygons: {
      tool_type: 'circle_polygons',
      tool_group: 'parcel-csv',
      name: 'Parcel CSV',
      data: {
        default_name: 'Parcel CSV',
      },
      style: {
        color: '#efcf6e',
        opacity: 0.5,
        fillColor: '#efcf6e',
        fillOpacity: 0.2,
        weight: 3.6,
      },
    },
    polygons_csv: {
      tool_type: 'polygons',
      tool_group: 'parcel-csv',
      name: 'Parcel CSV',
      data: {
        default_name: 'Parcel CSV',
      },
      style: {
        color: '#efcf6e',
        opacity: 0.5,
        fillColor: '#efcf6e',
        fillOpacity: 0.2,
        weight: 3.6,
      },
    },
    polylines_csv: {
      tool_type: 'polylines',
      tool_group: 'parcel-csv',
      name: 'Parcel CSV',
      data: {
        default_name: 'Parcel CSV',
      },
      style: {
        color: '#efcf6e',
        opacity: 0.5,
        weight: 3.6,
      },
    },
    lasso: {
      tool_type: 'polygons',
      name: 'Lasso Tool',
      data: {
        default_name: 'Lasso Tool',
      },
    },
    cut: {
      tool_type: 'polylines',
      name: 'Cut Tool',
      data: {
        default_name: 'Cut Tool',
      },
    },
  },
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
