import subscriptionsActionTypes from '../actions/subscriptions/actions';
import transactionsActionTypes from '../actions/transactions/actions';
import actionTypes from '../actions/user/actions';
import update from 'immutability-helper';
import { USAGE_TRACKING_OPTIONS } from 'utils/constants';

const identifySegmentUser = (user) => {
  try {
    window.analytics.identify({
      userId: user.id,
      traits: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    });
  } catch {}
};

const ACTION_HANDLERS = {
  [actionTypes.AUTHENTICATE]: (state, action) => {
    const { user } = action;

    // Init tutorial config if none
    user.tutorial.parcel_button_clicked =
      user.tutorial.parcel_button_clicked || false;
    user.tutorial.step = user.tutorial.step || 1;
    user.tutorial.finished = user.tutorial.finished || false;

    identifySegmentUser(user);

    return { ...state, user, authenticated: true, error: false };
  },
  [actionTypes.SIGN_IN_SUCCESS]: (state, action) => {
    const { user } = action;

    // Init tutorial config if none
    user.tutorial.parcel_button_clicked =
      user.tutorial.parcel_button_clicked || false;
    user.tutorial.step = user.tutorial.step || 1;
    user.tutorial.finished = user.tutorial.finished || false;

    // Identify user with Segment
    identifySegmentUser(user);

    return { ...state, user, authenticated: true, error: false };
  },
  [actionTypes.SIGN_IN_FAILURE]: (state, action) => ({
    ...state,
    user: null,
    authenticated: false,
    error: true,
  }),
  [actionTypes.SIGN_UP_SUCCESS]: (state, action) => ({
    ...state,
    error: false,
  }),
  [actionTypes.SIGN_UP_FAILURE]: (state, action) => ({
    ...state,
    error: action.error.json,
  }),
  [actionTypes.RESET_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    error: action.error.json?.error || 'Unknown server error',
  }),
  [actionTypes.RESET_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    error: false,
  }),
  [actionTypes.UPDATE_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.user,
    updated: true,
    error: false,
  }),
  [actionTypes.UPDATE_USER_FAILURE]: (state, action) => ({
    ...state,
    error: action.error.json,
  }),
  [actionTypes.RELOAD_USER_SUCCESS]: (state, action) => {
    const { user } = action;

    // Init tutorial config if none
    user.tutorial.step = user.tutorial.step || 1;
    user.tutorial.finished = user.tutorial.finished || false;
    user.tutorial.parcel_button_clicked =
      user.tutorial.parcel_button_clicked || false;

    return { ...state, user, error: false };
  },
  [actionTypes.RELOAD_USER_FAILURE]: (state, action) => ({
    ...state,
    error: action.error.json,
  }),
  [actionTypes.SET_TUTORIAL_ATTRIBUTE]: (state, action) =>
    update(state, {
      user: { tutorial: { [action.attribute]: { $set: action.value } } },
    }),
  [actionTypes.TOGGLE_PARCEL_BUTTON]: (state) => ({
    ...state,
    user: {
      ...state.user,
      tutorial: { ...state.user.tutorial, parcel_button_clicked: true },
    },
  }),
  [actionTypes.SET_PARCELS_AVAILABILITY]: (state, action) => ({
    ...state,
    parcelsEnabled: action.enabled,
    parcelsEnabledForState: action.parcelsEnabledForState,
  }),
  [actionTypes.CLEAR_USER_ERROR]: (state, action) => ({
    ...state,
    error: false,
  }),
  [actionTypes.DISABLE_UPDATE_FLAG]: (state, action) => ({
    ...state,
    updated: false,
  }),
  [actionTypes.LOGOUT]: (state, action) => initialState,
  [subscriptionsActionTypes.POST_SUBSCRIPTION]: (state, action) => ({
    ...state,
    paymentSuccess: false,
  }),
  [subscriptionsActionTypes.POST_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    ...state,
    user: action.response.data,
    paymentSuccess: true,
  }),
  [subscriptionsActionTypes.PATCH_SUBSCRIPTION]: (state, action) => ({
    ...state,
    paymentSuccess: false,
  }),
  [subscriptionsActionTypes.PATCH_SUBSCRIPTION_SUCCESS]: (state, _action) => ({
    ...state,
    paymentSuccess: true,
  }),
  [transactionsActionTypes.ADD_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    paymentMethods: [],
    paymentMethodAddedSuccess: false,
  }),
  [transactionsActionTypes.ADD_PAYMENT_METHOD_SUCCESS]: (state, action) => ({
    ...state,
    paymentMethods: [...state.paymentMethods, action.response.data],
    paymentMethodAddedSuccess: true,
  }),
  [transactionsActionTypes.ADD_PAYMENT_METHOD_FAILURE]: (state, action) => ({
    ...state,
    paymentMethodAddedSuccess: false,
  }),
  [actionTypes.GET_USAGE_TRACKING_OPTIONS]: (state, action) => ({
    ...state,
    usageTrackingOptions: action.data,
  }),
  [actionTypes.SELECT_ACCOUNT]: (state, action) => ({
    ...state,
    selectedAccount: action.data,
  }),
};

const initialState = {
  user: null,
  authenticated: false,
  updated: false,
  error: false,
  parcelsEnabled: true,
  paymentSuccess: false,
  paymentMethodAddedSuccess: false,
  paymentMethods: [],
  usageTrackingOptions: USAGE_TRACKING_OPTIONS,
  selectedAccount: null,
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
