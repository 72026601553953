import { NonConfigurableIconProps } from '../types';

const CompassDirections = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34783 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM12.8917 14.8833L8 16.8083C7.88348 16.8699 7.75029 16.8925 7.61996 16.8729C7.48964 16.8532 7.36904 16.7923 7.27585 16.6992C7.18265 16.606 7.12177 16.4854 7.10212 16.355C7.08247 16.2247 7.1051 16.0915 7.16667 15.975L9.09167 11.0917C9.11745 11.0287 9.15827 10.9729 9.21057 10.9293C9.26287 10.8858 9.32505 10.8557 9.39167 10.8417C9.45895 10.8295 9.52817 10.8338 9.59345 10.8541C9.65874 10.8744 9.71816 10.9102 9.76667 10.9583L13 14.1917C13.0482 14.2402 13.0839 14.2996 13.1042 14.3649C13.1246 14.4302 13.1288 14.4994 13.1167 14.5667C13.1077 14.6338 13.0825 14.6976 13.0433 14.7528C13.0041 14.808 12.9521 14.8528 12.8917 14.8833ZM16.8083 8.00833L14.8833 12.8917C14.8576 12.9547 14.8167 13.0104 14.7644 13.054C14.7121 13.0976 14.65 13.1277 14.5833 13.1417C14.5161 13.1553 14.4464 13.1518 14.3809 13.1314C14.3153 13.111 14.256 13.0744 14.2083 13.025L10.975 9.79167C10.9268 9.74315 10.8911 9.68373 10.8708 9.61845C10.8504 9.55317 10.8462 9.48395 10.8583 9.41667C10.8723 9.35004 10.9024 9.28786 10.946 9.23556C10.9896 9.18327 11.0453 9.14244 11.1083 9.11667L16 7.19167C16.1126 7.14197 16.2378 7.12811 16.3585 7.15197C16.4793 7.17582 16.5898 7.23623 16.675 7.325C16.7645 7.41157 16.8252 7.52351 16.8491 7.64571C16.8729 7.7679 16.8587 7.89447 16.8083 8.00833Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default CompassDirections;
