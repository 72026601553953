// eslint-disable-next-line restricted-imports/react-modal -- we allow to use react-modal here since this is our wrapper
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import classNames from 'classnames';
import './styles.scss';
import Icon from 'components/ui/DeprecatedIcon';

/**
 * @deprecated please use `components/Modal` instead
 */
class CustomModal extends Component {
  static get Title() {
    return Title;
  }

  static get Footer() {
    return Footer;
  }

  static get BottomAlert() {
    return BottomAlert;
  }

  render() {
    const { title, onClose, className, overlayClassName, customStyle } =
      this.props;
    const {
      positionModifier,
      children,
      notCloseOnOverlayClick,
      shouldCloseOnEsc,
    } = this.props;
    const titleElement = title ? <h4>{title}</h4> : null;

    return (
      <Modal
        className={classNames(
          'custom-modal',
          className,
          `custom-modal--${positionModifier || 'top'}`,
        )}
        overlayClassName={`custom-modal-overlay ${overlayClassName || ''}`}
        contentLabel="Modal"
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={!overlayClassName && !notCloseOnOverlayClick}
        isOpen
        style={customStyle}
        shouldCloseOnEsc={shouldCloseOnEsc}
      >
        {titleElement}
        {children}
      </Modal>
    );
  }
}

const Title = ({
  title,
  subTitle,
  warningMessage,
  borderBottom = true,
  onClose,
}) => (
  <div
    className={`custom-modal__title ${borderBottom ? '' : 'custom-modal__title__without-border-bottom'}`}
  >
    {onClose && (
      <Icon id="xMarkSlim" className="close-icon" onClick={onClose} />
    )}

    {title && (
      <p>
        <span>{title}</span>
      </p>
    )}
    {subTitle && <p>{subTitle}</p>}
    {warningMessage && <p>{warningMessage}</p>}
  </div>
);

const Footer = ({ children, leftAlign }) => (
  <footer
    className={classNames({
      leftAlign,
    })}
  >
    {children}
  </footer>
);

const BottomAlert = ({ message, messageType }) => (
  <CSSTransition classNames="bottom-alert" timeout={400} in={!!message}>
    <div className={`bottom-alert ${messageType}`}>
      <p>{message}</p>
    </div>
  </CSSTransition>
);

CustomModal.defaultProps = {
  shouldCloseOnOverlayClick: false,
  shouldCloseOnEsc: true,
  notCloseOnOverlayClick: true,
};

CustomModal.propTypes = {
  title: PropTypes.string,
  notCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  positionModifier: PropTypes.string,
  children: PropTypes.node,
  customStyle: PropTypes.object,
  shouldCloseOnEsc: PropTypes.bool,
};

Title.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  warningMessage: PropTypes.string,
  borderBottom: PropTypes.bool,
  onClose: PropTypes.func,
};

Footer.propTypes = {
  children: PropTypes.node,
  leftAlign: PropTypes.bool,
};

BottomAlert.propTypes = {
  message: PropTypes.string,
  messageType: PropTypes.string,
};

export default CustomModal;
