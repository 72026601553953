import { IconPropsWithVariant } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const RealState = ({
  fill = colors.black,
  variant = 'filled',
  width = '20',
  height = '18',
}: IconPropsWithVariant) => {
  const filled = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1691 11.0827C11.1691 10.6801 11.4869 10.3538 11.879 10.3538H15.4283C15.8204 10.3538 16.1382 10.6801 16.1382 11.0827V14.727C16.1382 15.1296 15.8204 15.4559 15.4283 15.4559H11.879C11.4869 15.4559 11.1691 15.1296 11.1691 14.727V11.0827ZM12.5888 11.8116V13.9982H14.7185V11.8116H12.5888Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0621 0.635498C12.67 0.635498 12.3522 0.961826 12.3522 1.36437V2.15286L11.2238 1.30313C10.8991 1.05868 10.5069 0.926858 10.1043 0.926858C9.70173 0.926858 9.30961 1.05865 8.98494 1.3031L2.01311 6.55294C2.00063 6.56173 1.98843 6.57091 1.97654 6.58047L0.811107 7.45806C0.494961 7.69612 0.426629 8.15225 0.658484 8.47686C0.890339 8.80146 1.33458 8.87162 1.65073 8.63356L1.70412 8.59336V16.4278H1.23087C0.838817 16.4278 0.520996 16.7541 0.520996 17.1566C0.520996 17.5592 0.838817 17.8855 1.23087 17.8855H18.9777C19.3697 17.8855 19.6876 17.5592 19.6876 17.1566C19.6876 16.7541 19.3697 16.4278 18.9777 16.4278H18.5044V8.59329L18.5579 8.63356C18.8741 8.87162 19.3183 8.80146 19.5502 8.47686C19.782 8.15225 19.7137 7.69612 19.3976 7.45806L18.2306 6.57931C18.2196 6.57055 18.2084 6.56211 18.197 6.55401L17.3213 5.89464V1.36437C17.3213 0.961826 17.0035 0.635498 16.6115 0.635498H13.0621ZM8.80285 16.4278V12.2972C8.80285 12.1683 8.75299 12.0447 8.66424 11.9536C8.57549 11.8624 8.45511 11.8112 8.3296 11.8112H7.14648C7.02096 11.8112 6.90059 11.8624 6.81184 11.9536C6.72309 12.0447 6.67323 12.1683 6.67323 12.2972V16.4278H8.80285ZM16.1834 6.84552C16.1891 6.84999 16.195 6.85437 16.2009 6.85867L17.0847 7.52421V16.4274H10.2226V12.2972C10.2226 11.7817 10.0232 11.2873 9.66815 10.9228C9.31314 10.5583 8.83165 10.3535 8.3296 10.3535H7.14648C6.64443 10.3535 6.16293 10.5583 5.80793 10.9228C5.45292 11.2873 5.25348 11.7817 5.25348 12.2972V16.4274H3.12386V7.52428L9.82448 2.47866C9.90565 2.41755 10.0037 2.3846 10.1043 2.3846C10.205 2.3846 10.303 2.41754 10.3841 2.47864L12.6288 4.16892C12.638 4.17616 12.6473 4.18319 12.6568 4.18998L16.1834 6.84552ZM15.9016 4.82556V2.09324H13.772V3.22194L15.9016 4.82556Z"
        fill="#1D1D1D"
      />
    </svg>
  );

  const outlined = () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1691 11.0827C11.1691 10.6801 11.4869 10.3538 11.879 10.3538H15.4283C15.8204 10.3538 16.1382 10.6801 16.1382 11.0827V14.727C16.1382 15.1296 15.8204 15.4559 15.4283 15.4559H11.879C11.4869 15.4559 11.1691 15.1296 11.1691 14.727V11.0827ZM12.5888 11.8116V13.9982H14.7185V11.8116H12.5888Z"
        stroke={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0621 0.635498C12.67 0.635498 12.3522 0.961826 12.3522 1.36437V2.15286L11.2238 1.30313C10.8991 1.05868 10.5069 0.926858 10.1043 0.926858C9.70173 0.926858 9.30961 1.05865 8.98494 1.3031L2.01311 6.55294C2.00063 6.56173 1.98843 6.57091 1.97654 6.58047L0.811107 7.45806C0.494961 7.69612 0.426629 8.15225 0.658484 8.47686C0.890339 8.80146 1.33458 8.87162 1.65073 8.63356L1.70412 8.59336V16.4278H1.23087C0.838817 16.4278 0.520996 16.7541 0.520996 17.1566C0.520996 17.5592 0.838817 17.8855 1.23087 17.8855H18.9777C19.3697 17.8855 19.6876 17.5592 19.6876 17.1566C19.6876 16.7541 19.3697 16.4278 18.9777 16.4278H18.5044V8.59329L18.5579 8.63356C18.8741 8.87162 19.3183 8.80146 19.5502 8.47686C19.782 8.15225 19.7137 7.69612 19.3976 7.45806L18.2306 6.57931C18.2196 6.57055 18.2084 6.56211 18.197 6.55401L17.3213 5.89464V1.36437C17.3213 0.961826 17.0035 0.635498 16.6115 0.635498H13.0621ZM8.80285 16.4278V12.2972C8.80285 12.1683 8.75299 12.0447 8.66424 11.9536C8.57549 11.8624 8.45511 11.8112 8.3296 11.8112H7.14648C7.02096 11.8112 6.90059 11.8624 6.81184 11.9536C6.72309 12.0447 6.67323 12.1683 6.67323 12.2972V16.4278H8.80285ZM16.1834 6.84552C16.1891 6.84999 16.195 6.85437 16.2009 6.85867L17.0847 7.52421V16.4274H10.2226V12.2972C10.2226 11.7817 10.0232 11.2873 9.66815 10.9228C9.31314 10.5583 8.83165 10.3535 8.3296 10.3535H7.14648C6.64443 10.3535 6.16293 10.5583 5.80793 10.9228C5.45292 11.2873 5.25348 11.7817 5.25348 12.2972V16.4274H3.12386V7.52428L9.82448 2.47866C9.90565 2.41755 10.0037 2.3846 10.1043 2.3846C10.205 2.3846 10.303 2.41754 10.3841 2.47864L12.6288 4.16892C12.638 4.17616 12.6473 4.18319 12.6568 4.18998L16.1834 6.84552ZM15.9016 4.82556V2.09324H13.772V3.22194L15.9016 4.82556Z"
        stroke={fill}
      />
    </svg>
  );

  return variant === 'filled' ? filled() : outlined();
};

export default RealState;
