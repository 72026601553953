const TransparentBox = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5914_57991)">
      <rect x="8" width="8" height="8" fill="#D9D9D9" />
      <rect x="8" y="16" width="8" height="8" fill="#D9D9D9" />
      <rect y="8" width="8" height="8" fill="#D9D9D9" />
      <rect x="16" y="8" width="8" height="8" fill="#D9D9D9" />
    </g>
    <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#B9B9B9" />
    <defs>
      <clipPath id="clip0_5914_57991">
        <rect width="24" height="24" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TransparentBox;
