import { findKey } from 'lodash';

const INNER_LINE_PATH_CODES = [
  'custom-line',
  'custom-polygon-boundary',
  'polygon-pivot',
  'leasehold',
];
const FILLABLES_FEATURES_WITHOUT_COLOR = ['polygon-pond-tank'];

const fixColors = {
  red: '#E60000',
  'light-blue': '#5C8BD2',
  black: '#000',
  white: '#FFF',
  orange: '#FFA500',
};

export const polyColors = {
  red: '#D40000',
  trinidad: '#E64C00',
  'electric-violet': '#A900E6',
  'deep-cerulean': '#008A99',
  'persian-green': '#00A884',
  limeade: '#1DB000',
  'hollywood-cerise': '#E600A9',
  'pirate-gold': '#A87000',
  'buddha-gold': '#A8A800',
  black: '#000000',
};

export const markerColors = {
  'light-red': '#FF4747',
  red: '#D40000',
  'dark-red': '#9A0000',
  'light-purple': '#D568C0',
  purple: '#A02C89',
  'dark-purple': '#6A1D5B',
  'light-blue': '#00AEEF',
  blue: '#2C5AA0',
  'dark-blue': '#1A355F',
  'light-green': '#73FFDF',
  green: '#447821',
  'dark-green': '#294914',
  'light-orange': '#FF9E5E',
  orange: '#FF6600',
  'dark-orange': '#B14700',
  'light-pink': '#FF94BF',
  pink: '#FF5599',
  'dark-pink': '#DC256E',
  'light-brown': '#B5622B',
  brown: '#803300',
  'dark-brown': '#622700',
  'light-yellow': '#FFE888',
  yellow: '#FFCC00',
  'dark-yellow': '#BF9900',
  white: '#FFFFFF',
  'gray-25': '#BFBFBF',
  'gray-58': '#6A6A6A',
  'gray-70': '#4D4D4D',
  'gray-85': '#252525',
  black: '#000000',
};

export const labelColors = {
  sand: '#E9E96C',
  maize: '#F4B54D',
  'label-orange': '#F4904D',
  'darkish-pink': '#E25D82',
  'faded-orange': '#EF6868',
  grape: '#753764',
  'dark-sage': '#59814B',
  'label-green': '#7BC255',
  skyblue: '#6FBECE',
  'label-blue': '#4D93FF',
  white: 'white',
  black: 'black',
};

const getInnerPolyColor = (color) => {
  switch (color.toLowerCase()) {
    case '#e60000': {
      return '#ff7f7f';
    }
    case '#d40000': {
      return '#ff7f7f';
    }
    case '#1db000': {
      return '#a3ff73';
    }
    case '#00a884': {
      return '#73ffdf';
    }
    case '#008a99': {
      return '#97dfff';
    }
    case '#a900e6': {
      return '#df73ff';
    }
    case '#e600a9': {
      return '#ff73e9';
    }
    case '#e64c00': {
      return '#ffa77f';
    }
    case '#a87000': {
      return '#ffd37f';
    }
    case '#a8a800': {
      return '#ffff00';
    }
    case '#000000': {
      return '#4e4e4e';
    }
    default: {
      return color;
    }
  }
};

export const hexaToColorName = (color) => {
  if (!color) {
    return 'no-fill';
  }

  const allColors = Object.assign({}, markerColors, polyColors);
  const key =
    findKey(allColors, (c) => c === color.toUpperCase().trim()) ||
    findKey(fixColors, (c) => c === color.toUpperCase().trim());

  if (key) {
    return key;
  }

  switch (color.toLowerCase()) {
    case 'transparent': {
      return 'transparent';
    }
    case 'no-fill': {
      return 'no-fill';
    }
    default: {
      return color;
    }
  }
};

export const colorNameToHexa = (color) => {
  const allColors = Object.assign({}, markerColors, polyColors);
  if (allColors[color]) {
    return allColors[color];
  }

  switch (color) {
    case 'transparent': {
      return 'transparent';
    }
    case 'no-fill': {
      return 'no-fill';
    }
    default: {
      return color;
    }
  }
};

export const toolColors = (tool) => {
  const { tool_type: toolType } = tool;
  return (
    toolType === 'icons' ? markerColors
    : hasInnerLinePath(tool) ? polyColors
    : markerColors
  );
};

export const randomColor = (tool, colorsToSkip = []) => {
  const obj = toolColors(tool);
  const colorNames = Object.keys(toolColors(tool));
  const colors =
    colorsToSkip.length > 0 ?
      colorNames.filter((i) => !colorsToSkip.includes(i))
    : [];
  const keys = colors.length > 0 ? colors : colorNames;
  return obj[keys[(keys.length * Math.random()) << 0]];
};

const hasInnerLinePath = (tool) => INNER_LINE_PATH_CODES.includes(tool.code);
export const isFillableWithoutColor = (tool) =>
  FILLABLES_FEATURES_WITHOUT_COLOR.includes(tool.code);

export const sanitizeColor = (color, tool, fillColor) => {
  let sanitizedColor;
  let sanitizedStyle;
  const { tool_type: toolType, style, code } = tool;
  const hexColor = colorNameToHexa(color);
  const colorName = hexaToColorName(color);
  const fillColorName = hexaToColorName(fillColor);

  if (toolType === 'icons') {
    sanitizedColor = colorName;
    sanitizedStyle = {
      ...style,
      color: colorName,
    };
  } else if (toolType === 'polylines') {
    sanitizedColor = colorName;
    sanitizedStyle = {
      ...style,
      color: hexColor,
    };
  } else if (FILLABLES_FEATURES_WITHOUT_COLOR.includes(code)) {
    sanitizedColor = colorName;
    sanitizedStyle = {
      ...style,
      ...(fillColor && {
        fillColor: fillColorName === 'no-fill' ? null : fillColor,
      }),
      ...(fillColor && { fill: fillColorName !== 'no-fill' }),
    };
  } else {
    sanitizedColor = colorName;
    sanitizedStyle = {
      ...style,
      fillWidth: {
        ...style.fillWidth,
        color:
          hasInnerLinePath(tool) ?
            getInnerPolyColor(hexColor)
          : style.fillWidth.color,
      },
      color: hexColor,
      ...(fillColor && {
        fillColor: fillColorName === 'no-fill' ? null : fillColor,
      }),
      ...(fillColor && { fill: fillColorName !== 'no-fill' }),
    };
  }

  return { sanitizedColor, sanitizedStyle };
};
