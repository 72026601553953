import { makeAuthenticatedRequest } from './utils/request';
import { getAPIURL } from './utils/domains';

export const fetchVersion = () => {
  const url = `${getAPIURL()}/version`;

  return makeAuthenticatedRequest(url);
};

export default {
  fetchVersion,
};
