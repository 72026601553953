class AppsFlyer {
  static enabled = true;

  static sendEvent(eventName, properties) {
    if (!this.isAvaliable()) return;

    window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventName,
      eventValue: properties,
    });
  }

  static identifyUser(userId) {
    if (!this.isAvaliable()) return;

    window.AF('pba', 'setCustomerUserId', String(userId));
  }

  static setEnabled(value) {
    this.enabled = value;
  }

  static isAvaliable() {
    return this.enabled && typeof window.AF === 'function';
  }
}

export default AppsFlyer;
