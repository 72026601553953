export default {
  CHANGE_ACTIVE_PANEL: 'CHANGE_ACTIVE_PANEL',
  CHANGE_ACTIVE_PANEL_ITEM: 'CHANGE_ACTIVE_PANEL_ITEM',
  ADD_ACTIVE_PANEL_ITEM: 'ADD_ACTIVE_PANEL_ITEM',
  REMOVE_ACTIVE_PANEL_ITEM: 'REMOVE_ACTIVE_PANEL_ITEM',
  SET_ACTIVE_PANEL_ITEMS: 'SET_ACTIVE_PANEL_ITEMS',
  TOGGLE_COLLAPSED: 'TOGGLE_COLLAPSED',
  CHANGE_EDIT_PANEL: 'CHANGE_EDIT_PANEL',
  HIGHLIGHT_LAYER_ITEM: 'HIGHLIGHT_LAYER_ITEM',
};
