/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const AppleLogo = ({ fill }) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6481 0.75012C12.6472 0.668035 12.6463 0.584813 12.6463 0.5C12.6136 0.5049 12.5836 0.509142 12.5555 0.513125C12.5018 0.520731 12.4547 0.527394 12.4079 0.5359C12.35 0.546519 12.2919 0.556563 12.2338 0.566608C12.082 0.592849 11.9301 0.619099 11.7809 0.655652C11.4708 0.731728 11.1798 0.860811 10.9002 1.01426C10.4034 1.28681 9.97648 1.64646 9.59973 2.06714C9.1285 2.59333 8.75913 3.17757 8.56979 3.86653C8.5046 4.10383 8.46313 4.34425 8.44978 4.5879C8.43552 4.84814 8.44498 5.10941 8.49358 5.37769C8.52234 5.37561 8.55108 5.37378 8.57978 5.37195C8.64252 5.36795 8.70509 5.36396 8.76743 5.35747C8.80572 5.35347 8.84408 5.34977 8.88245 5.34608C9.00682 5.33411 9.1313 5.32212 9.25382 5.29954C9.50084 5.25405 9.74087 5.18031 9.97285 5.08272C10.4453 4.88404 10.8614 4.59956 11.2297 4.24575C11.7054 3.7889 12.0944 3.27166 12.3416 2.65281C12.4383 2.41072 12.5169 2.16447 12.5655 1.91007C12.6042 1.70776 12.6328 1.50169 12.6432 1.29614C12.6523 1.11646 12.6502 0.936212 12.6481 0.75012Z"
      fill="white"
    />
    <path
      d={`
    M17.1814 15.2347C17.1989 15.1869 17.2165 15.1389 17.2344 15.0906
    C17.2033 15.0735 17.1723 15.0567 17.1418 15.0403
    C17.076 15.0047 17.0118 14.9699 16.9484 14.9336
    C16.4331 14.638 15.9589 14.2897 15.553 13.8523
    C15.1493 13.4171 14.8446 12.9221 14.6574 12.3591
    C14.59 12.1565 14.543 11.9456 14.5039 11.7354
    C14.4685 11.5456 14.4479 11.3517 14.4371 11.1587
    C14.428 10.994 14.4337 10.8271 14.4479 10.6625
    C14.4643 10.4703 14.4903 10.2782 14.5241 10.0882
    C14.5846 9.74825 14.7029 9.42658 14.8569 9.11799
    C15.1742 8.48243 15.6284 7.95715 16.1681 7.50251
    C16.2861 7.40309 16.4084 7.30864 16.5316 7.21344
    C16.5886 7.1694 16.6458 7.12519 16.7029 7.08027
    C16.7013 7.07809 16.6996 7.07565 16.6978 7.07305
    C16.6934 7.06667 16.6883 7.05937 16.6823 7.05292
    C16.6196 6.98599 16.558 6.91794 16.4963 6.8499
    C16.3581 6.69739 16.2199 6.54489 16.0701 6.40491
    C15.4481 5.82365 14.7133 5.44806 13.8854 5.25625
    C13.705 5.21439 13.5204 5.18782 13.3364 5.16475
    C13.1384 5.14 12.939 5.11887 12.7397 5.11239
    C12.6051 5.10797 12.4702 5.11315 12.3353 5.11832
    C12.2725 5.12073 12.2097 5.12314 12.1469 5.12458
    C11.8893 5.13041 11.6332 5.15426 11.3824 5.214
    C11.3498 5.22177 11.3173 5.22947 11.2847 5.23718
    C11.0587 5.29061 10.8323 5.34417 10.6127 5.41722
    C10.4171 5.4822 10.2247 5.55659 10.0323 5.63098
    C9.89893 5.68255 9.76555 5.73412 9.6311 5.78256
    C9.43021 5.85488 9.22311 5.90206 9.01005 5.93251
    C8.83586 5.95753 8.66492 5.9609 8.4963 5.92513
    C8.19718 5.86162 7.91478 5.74317 7.63471 5.62432
    C7.26366 5.46686 6.89261 5.31548 6.49785 5.22385
    C6.24136 5.16436 5.98229 5.13106 5.72179 5.11615
    C5.39196 5.09736 5.06095 5.10138 4.7328 5.15076
    C4.68035 5.15866 4.62782 5.16625 4.57529 5.17383
    C4.40648 5.1982 4.2376 5.22259 4.07106 5.25768
    C3.67823 5.34036 3.30252 5.47696 2.94391 5.65776
    C2.44067 5.91152 1.99808 6.2468 1.60642 6.65245
    C1.09151 7.18589 0.711261 7.80487 0.441819 8.49124
    C0.352448 8.71893 0.288769 8.95736 0.2254 9.19462
    C0.219481 9.21679 0.213564 9.23894 0.207629 9.26107
    C0.121833 9.58106 0.0728434 9.90895 0.0377214 10.2379
    C0.0146523 10.4538 0.00480254 10.6717 0.00104409 10.8887
    C-0.00243215 11.091 0.00340605 11.2935 0.0092402 11.4959
    C0.0101488 11.5274 0.0110574 11.5589 0.0119306 11.5904
    C0.0155595 11.7204 0.024502 11.8504 0.0340925 11.9801
    C0.0364719 12.0122 0.0387142 12.0444 0.0409566 12.0765
    C0.0498288 12.2036 0.0587031 12.3308 0.0760835 12.4568
    C0.109002 12.696 0.145291 12.9353 0.192725 13.1719
    C0.255841 13.4871 0.319216 13.8032 0.404105 14.1131
    C0.492623 14.4366 0.599933 14.7555 0.712039 15.0719
    C0.806389 15.338 0.913699 15.5999 1.02542 15.8592
    C1.27218 16.4315 1.55963 16.9835 1.87949 17.5184
    C2.25093 18.1396 2.65542 18.7374 3.15386 19.2659
    C3.63572 19.7768 4.16748 20.2154 4.86214 20.4063
    C5.00989 20.4469 5.16204 20.4782 5.31432 20.4934
    C5.51045 20.5129 5.70573 20.4841 5.90007 20.4555
    C5.92109 20.4524 5.94211 20.4493 5.96311 20.4462
    C6.19808 20.4123 6.42397 20.3436 6.64507 20.2584
    C6.75103 20.2177 6.85662 20.1758 6.96221 20.134
    C7.20908 20.0363 7.45593 19.9385 7.70729 19.8542
    C7.95988 19.7694 8.22129 19.7059 8.48905 19.6794
    C8.52645 19.6757 8.56377 19.6714 8.60108 19.6671
    C8.7516 19.6498 8.90182 19.6326 9.05554 19.6532
    C9.14164 19.6647 9.22861 19.6697 9.31558 19.6747
    C9.40299 19.6798 9.49039 19.6848 9.57692 19.6965
    C9.73439 19.7175 9.89185 19.7488 10.0443 19.7929
    C10.1381 19.8201 10.229 19.8571 10.3199 19.8942
    C10.369 19.9143 10.4182 19.9343 10.4678 19.9528
    C10.5128 19.9696 10.5578 19.9865 10.6028 20.0034
    C10.7712 20.0666 10.9397 20.1299 11.1102 20.1871
    C11.3313 20.2614 11.554 20.3319 11.7794 20.3914
    C11.8608 20.4128 11.9444 20.4256 12.028 20.4385
    C12.0957 20.4489 12.1635 20.4592 12.2301 20.4742
    C12.3637 20.5043 12.4962 20.5097 12.6285 20.4817
    C12.6594 20.4752 12.6904 20.4688 12.7214 20.4624
    C12.8673 20.4323 13.0137 20.4021 13.1547 20.356
    C13.399 20.276 13.6206 20.1458 13.8321 20.0001
    C14.2631 19.7035 14.6361 19.343 14.9848 18.9554
    C15.8931 17.9458 16.5754 16.7988 17.0684 15.5364
    C17.1073 15.4368 17.1441 15.3364 17.1814 15.2347Z`}
      fill="white"
    />
  </svg>
);

AppleLogo.propTypes = {
  fill: PropTypes.string,
};

AppleLogo.defaultProps = {
  fill: 'white',
};

export default AppleLogo;
