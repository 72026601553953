/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const Area = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.26036 2.13018C4.26036 2.18467 4.25831 2.23868 4.25429 2.29214L16.2532 6.54176C16.6439 6.08691 17.2232 5.79881 17.8698 5.79881C19.0463 5.79881 20 6.75252 20 7.92898C20 9.10545 19.0463 10.0592 17.8698 10.0592C17.8249 10.0592 17.7803 10.0578 17.736 10.055L15.6994 16.2371C16.0896 16.6232 16.3313 17.159 16.3313 17.7513C16.3313 18.9278 15.3776 19.8815 14.2012 19.8815C13.3209 19.8815 12.5653 19.3476 12.2407 18.5859H6.57588C6.25124 19.3476 5.49567 19.8815 4.61539 19.8815C3.43892 19.8815 2.48521 18.9278 2.48521 17.7513C2.48521 17.0007 2.87349 16.3407 3.46017 15.9613L1.71135 4.21919C0.735445 4.02462 0 3.16328 0 2.13018C0 0.953713 0.953713 0 2.13018 0C3.30664 0 4.26036 0.953713 4.26036 2.13018ZM6.71905 17.4143C6.55749 16.398 5.67716 15.6212 4.61539 15.6212C4.60827 15.6212 4.60115 15.6212 4.59405 15.6213L2.88169 4.124C3.27053 3.97737 3.60518 3.72025 3.84738 3.39093L15.7632 7.61112C15.7477 7.71482 15.7397 7.82096 15.7397 7.92898C15.7397 8.64372 16.0917 9.27623 16.6318 9.66263L14.6529 15.6692C14.5073 15.6377 14.3562 15.6212 14.2012 15.6212C13.1394 15.6212 12.2591 16.398 12.0975 17.4143H6.71905Z"
      fill={fill}
    />
  </svg>
);

Area.propTypes = {
  fill: PropTypes.string,
};

Area.defaultProps = {
  fill: '#1D1D1D',
};

export default Area;
