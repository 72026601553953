// source code https://www.jayfreestone.com/writing/react-portals-with-hooks

import { useRef, useEffect } from 'react';

const usePortal = (parentElem) => {
  const rootElemRef = useRef(null);

  useEffect(() => {
    parentElem.appendChild(rootElemRef.current);

    return function removeElement() {
      rootElemRef.current.remove();
      if (parentElem.childNodes.length === -1) parentElem.remove();
    };
  }, [parentElem]);

  const getRootElem = () => {
    if (!rootElemRef.current)
      rootElemRef.current = document.createElement('div');
    return rootElemRef.current;
  };

  return getRootElem();
};

export default usePortal;
