import { StyleableOnlyIconProps } from '../types';

import * as colorPalette from 'components/ui/styles/colors.module.scss';

const Print = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colorPalette.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.46914 10.1481C4.46914 9.73905 4.80078 9.40741 5.20988 9.40741H6.44444C6.85354 9.40741 7.18519 9.73905 7.18519 10.1481C7.18519 10.5572 6.85354 10.8889 6.44444 10.8889H5.20988C4.80078 10.8889 4.46914 10.5572 4.46914 10.1481Z"
          fill={color}
        />
        <path
          d="M13.8971 14.7552C13.7716 14.5042 13.5152 14.3457 13.2346 14.3457C12.954 14.3457 12.6975 14.5042 12.572 14.7552L11.291 17.3172L10.7645 16.5274C10.6271 16.3213 10.3958 16.1975 10.1482 16.1975C9.9005 16.1975 9.66922 16.3213 9.53183 16.5274L8.29727 18.3792C8.07034 18.7196 8.16232 19.1795 8.50271 19.4065C8.8431 19.6334 9.303 19.5414 9.52993 19.201L10.1482 18.2737L10.7664 19.201C10.9129 19.4207 11.1652 19.5458 11.4288 19.5294C11.6923 19.513 11.9272 19.3576 12.0453 19.1214L13.2346 16.7428L14.4239 19.1214C14.6069 19.4873 15.0518 19.6356 15.4177 19.4527C15.7836 19.2697 15.9319 18.8248 15.749 18.4589L13.8971 14.7552Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.7037 3.97531V6.93827H3.97531C3.45142 6.93827 2.949 7.14638 2.57855 7.51683C2.20811 7.88727 2 8.3897 2 8.91358V15.0864C2 15.6103 2.20811 16.1127 2.57855 16.4832C2.949 16.8536 3.45142 17.0617 3.97531 17.0617H5.7037V21.2593C5.7037 21.6684 6.03534 22 6.44444 22H17.5556C17.9647 22 18.2963 21.6684 18.2963 21.2593V17.0617H20.0247C20.5486 17.0617 21.051 16.8536 21.4214 16.4832C21.7919 16.1127 22 15.6103 22 15.0864V8.91358C22 8.38969 21.7919 7.88727 21.4214 7.51683C21.051 7.14638 20.5486 6.93827 20.0247 6.93827H18.2963V3.97531C18.2963 3.45142 18.0882 2.949 17.7177 2.57855C17.3473 2.20811 16.8449 2 16.321 2H7.67901C7.15513 2 6.6527 2.20811 6.28226 2.57855C5.91182 2.949 5.7037 3.45142 5.7037 3.97531ZM7.67901 3.48148C7.54804 3.48148 7.42243 3.53351 7.32982 3.62612C7.23721 3.71873 7.18519 3.84434 7.18519 3.97531V6.93827H16.8148V3.97531C16.8148 3.84434 16.7628 3.71873 16.6702 3.62612C16.5776 3.53351 16.452 3.48148 16.321 3.48148H7.67901ZM3.97531 8.41975H20.0247C20.1557 8.41975 20.2813 8.47178 20.3739 8.56439C20.4665 8.657 20.5185 8.78261 20.5185 8.91358V15.0864C20.5185 15.2174 20.4665 15.343 20.3739 15.4356C20.2813 15.5282 20.1557 15.5802 20.0247 15.5802H18.2963V12.6173C18.2963 12.2082 17.9647 11.8765 17.5556 11.8765H6.44444C6.03534 11.8765 5.7037 12.2082 5.7037 12.6173V15.5802H3.97531C3.84434 15.5802 3.71873 15.5282 3.62612 15.4356C3.53351 15.343 3.48148 15.2174 3.48148 15.0864V8.91358C3.48148 8.78261 3.53351 8.657 3.62612 8.56439C3.71873 8.47178 3.84434 8.41975 3.97531 8.41975ZM16.8148 13.358H7.18519V20.5185H16.8148V13.358Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.79167 7C7.01268 7 7.22464 6.9122 7.38092 6.75592C7.5372 6.59964 7.625 6.38768 7.625 6.16667V3.66667H14.9917L16.7917 5.46667V6.16667C16.7917 6.38768 16.8795 6.59964 17.0357 6.75592C17.192 6.9122 17.404 7 17.625 7C17.846 7 18.058 6.9122 18.2143 6.75592C18.3705 6.59964 18.4583 6.38768 18.4583 6.16667V5.125C18.4635 5.00977 18.4447 4.89472 18.4031 4.78714C18.3615 4.67956 18.298 4.58178 18.2167 4.5L16.1667 2.49167C15.8555 2.17868 15.433 2.00186 14.9917 2H7.625C7.18297 2 6.75905 2.17559 6.44649 2.48816C6.13393 2.80072 5.95833 3.22464 5.95833 3.66667V6.16667C5.95833 6.38768 6.04613 6.59964 6.20241 6.75592C6.35869 6.9122 6.57065 7 6.79167 7Z"
          fill={color}
        />
        <path
          d="M19.9167 7.83333H4.08333C3.5308 7.83333 3.0009 8.05283 2.61019 8.44353C2.21949 8.83423 2 9.36413 2 9.91667V13.6667C2 14.2192 2.21949 14.7491 2.61019 15.1398C3.0009 15.5305 3.5308 15.75 4.08333 15.75H6.16667V20.9583C6.16667 21.0951 6.19361 21.2306 6.24596 21.357C6.29831 21.4833 6.37504 21.5982 6.47176 21.6949C6.56849 21.7916 6.68332 21.8684 6.8097 21.9207C6.93609 21.9731 7.07154 22 7.20833 22H17.2083C17.3451 22 17.4806 21.9731 17.607 21.9207C17.7333 21.8684 17.8482 21.7916 17.9449 21.6949C18.0416 21.5982 18.1184 21.4833 18.1707 21.357C18.2231 21.2306 18.25 21.0951 18.25 20.9583V15.75H19.9167C20.1903 15.75 20.4612 15.6961 20.7139 15.5914C20.9667 15.4867 21.1964 15.3333 21.3898 15.1398C21.5833 14.9464 21.7367 14.7167 21.8414 14.4639C21.9461 14.2112 22 13.9403 22 13.6667V9.91667C22 9.64308 21.9461 9.37217 21.8414 9.11941C21.7367 8.86665 21.5833 8.63698 21.3898 8.44353C21.1964 8.25007 20.9667 8.09661 20.7139 7.99192C20.4612 7.88722 20.1903 7.83333 19.9167 7.83333ZM4.91667 11.5833C4.75185 11.5833 4.59073 11.5345 4.45369 11.4429C4.31665 11.3513 4.20984 11.2212 4.14677 11.0689C4.08369 10.9166 4.06719 10.7491 4.09935 10.5874C4.1315 10.4258 4.21087 10.2773 4.32741 10.1607C4.44395 10.0442 4.59244 9.96483 4.75409 9.93268C4.91574 9.90052 5.0833 9.91703 5.23557 9.9801C5.38784 10.0432 5.51799 10.15 5.60956 10.287C5.70113 10.4241 5.75 10.5852 5.75 10.75C5.75 10.971 5.6622 11.183 5.50592 11.3393C5.34964 11.4955 5.13768 11.5833 4.91667 11.5833ZM16.5833 19.9167C16.5833 20.0272 16.5394 20.1332 16.4613 20.2113C16.3832 20.2894 16.2772 20.3333 16.1667 20.3333H8.25C8.13949 20.3333 8.03351 20.2894 7.95537 20.2113C7.87723 20.1332 7.83333 20.0272 7.83333 19.9167V12.4167C7.83333 12.3062 7.87723 12.2002 7.95537 12.122C8.03351 12.0439 8.13949 12 8.25 12H16.1667C16.2772 12 16.3832 12.0439 16.4613 12.122C16.5394 12.2002 16.5833 12.3062 16.5833 12.4167V19.9167Z"
          fill={color}
        />
        <path
          d="M14.2417 15.2417C14.2116 15.1721 14.1631 15.1121 14.1014 15.068C14.0397 15.024 13.9672 14.9976 13.8917 14.9917C13.817 14.9831 13.7414 14.9958 13.6736 15.0282C13.6058 15.0606 13.5486 15.1115 13.5083 15.175L12.125 17.2333L10.75 16.1083C10.7026 16.0704 10.6474 16.0432 10.5884 16.0289C10.5294 16.0145 10.4679 16.0131 10.4083 16.025C10.3469 16.0379 10.2891 16.0639 10.2387 16.1013C10.1883 16.1387 10.1467 16.1866 10.1167 16.2417L8.75 18.8917C8.71496 18.954 8.69655 19.0243 8.69655 19.0958C8.69655 19.1673 8.71496 19.2377 8.75 19.3C8.78855 19.3603 8.84146 19.4102 8.90399 19.4451C8.96652 19.48 9.03673 19.4989 9.10833 19.5H15.3333C15.4022 19.5001 15.47 19.4834 15.5309 19.4515C15.5919 19.4195 15.6442 19.3733 15.6833 19.3167C15.7162 19.2566 15.7334 19.1893 15.7334 19.1208C15.7334 19.0524 15.7162 18.9851 15.6833 18.925L14.2417 15.2417Z"
          fill={color}
        />
      </svg>;
};

export default Print;
