import { StyleableOnlyIconProps } from '../types';

const ArrowLeft = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9885 19.8179C11.2814 20.1108 11.7562 20.1108 12.0491 19.8179C12.342 19.525 12.342 19.0501 12.0491 18.7572L5.56059 12.2687L20.3974 12.2687C20.8116 12.2687 21.1474 11.9329 21.1474 11.5187C21.1474 11.1045 20.8116 10.7687 20.3974 10.7687L5.56076 10.7687L12.0491 4.28033C12.342 3.98744 12.342 3.51256 12.0491 3.21967C11.7562 2.92678 11.2814 2.92678 10.9885 3.21967L3.22718 10.981C3.19094 11.0162 3.15826 11.0551 3.1297 11.097C3.11758 11.1148 3.10631 11.133 3.09589 11.1515C3.03483 11.26 3 11.3853 3 11.5187C3 11.6134 3.01756 11.7041 3.04961 11.7875C3.08613 11.8829 3.14282 11.9723 3.21969 12.0491L10.9885 19.8179Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9456 10.9159H7.07727C7.03921 10.9155 7.00214 10.9036 6.97094 10.8818C6.93974 10.86 6.91586 10.8293 6.90244 10.7937C6.88902 10.758 6.88669 10.7192 6.89574 10.6822C6.9048 10.6452 6.92482 10.6119 6.95318 10.5865L13.4486 4.8709C13.6528 4.67837 13.7743 4.41408 13.7875 4.13374C13.8006 3.85339 13.7045 3.57888 13.5192 3.36807C13.3339 3.15725 13.074 3.0266 12.7943 3.00363C12.5146 2.98067 12.2369 3.0672 12.0197 3.24497L3.61108 10.6444C3.41902 10.8138 3.26521 11.0221 3.15985 11.2555C3.05449 11.4889 3 11.742 3 11.9981C3 12.2541 3.05449 12.5073 3.15985 12.7407C3.26521 12.9741 3.41902 13.1824 3.61108 13.3518L12.0182 20.7497C12.2356 20.9252 12.5124 21.01 12.7907 20.9862C13.0691 20.9625 13.3275 20.8321 13.512 20.6223C13.6965 20.4125 13.7927 20.1395 13.7806 19.8603C13.7685 19.5812 13.6491 19.3176 13.4471 19.1245L6.95168 13.4089C6.92332 13.3835 6.90329 13.3502 6.89424 13.3132C6.88518 13.2762 6.88752 13.2374 6.90094 13.2017C6.91436 13.1661 6.93823 13.1354 6.96944 13.1136C7.00064 13.0918 7.0377 13.0799 7.07577 13.0795H19.9456C20.2278 13.0721 20.4959 12.9548 20.6928 12.7526C20.8898 12.5503 21 12.2792 21 11.9969C21 11.7147 20.8898 11.4436 20.6928 11.2413C20.4959 11.0391 20.2278 10.9218 19.9456 10.9144V10.9159Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default ArrowLeft;
