import { NonConfigurableIconProps } from '../types';

const PencilEdit = ({
  width = '24',
  height = '24',
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3538 3C19.0514 3 19.7218 3.27074 20.2237 3.75519C20.4698 4.00048 20.665 4.29192 20.7982 4.6128C20.9314 4.93369 21 5.27771 21 5.62514C21 5.97258 20.9314 6.3166 20.7982 6.63749C20.665 6.95837 20.4698 7.24981 20.2237 7.4951L19.6777 8.01869C19.6069 8.08549 19.5132 8.1227 19.4159 8.1227C19.3186 8.1227 19.2249 8.08549 19.1541 8.01869L15.9527 4.81732C15.9177 4.78256 15.8899 4.74119 15.8709 4.69562C15.8519 4.65004 15.8421 4.60116 15.8421 4.55179C15.8421 4.50242 15.8519 4.45353 15.8709 4.40796C15.8899 4.36239 15.9177 4.32102 15.9527 4.28626L16.4838 3.75519C16.9858 3.27074 17.6562 3 18.3538 3ZM15.13 5.63263C15.0588 5.56363 14.9636 5.52504 14.8644 5.52504C14.7653 5.52504 14.6701 5.56363 14.5989 5.63263L6.06441 14.1671C6.02936 14.2019 6.00153 14.2432 5.98255 14.2888C5.96356 14.3344 5.95378 14.3833 5.95378 14.4326C5.95378 14.482 5.96356 14.5309 5.98255 14.5765C6.00153 14.622 6.02936 14.6634 6.06441 14.6982L9.26578 17.8995C9.29935 17.9353 9.33991 17.9638 9.38493 17.9833C9.42996 18.0028 9.4785 18.0129 9.52757 18.0129C9.57663 18.0129 9.62518 18.0028 9.67021 17.9833C9.71523 17.9638 9.75579 17.9353 9.78936 17.8995L18.3313 9.36506C18.4003 9.29389 18.4389 9.19866 18.4389 9.09953C18.4389 9.0004 18.4003 8.90517 18.3313 8.834L15.13 5.63263ZM5.26408 15.506C5.21826 15.4617 5.16209 15.4296 5.10068 15.4126C5.03927 15.3957 4.97458 15.3943 4.91253 15.4088C4.84966 15.4221 4.79151 15.452 4.74415 15.4954C4.69679 15.5388 4.66194 15.5941 4.64325 15.6556L3.02013 20.4577C2.99746 20.5232 2.99385 20.5939 3.00974 20.6614C3.02563 20.7289 3.06036 20.7906 3.10989 20.8391C3.1574 20.8973 3.22134 20.9399 3.29339 20.9612C3.36544 20.9826 3.44224 20.9817 3.5138 20.9588L8.31585 19.3581C8.37589 19.3369 8.4298 19.3012 8.47284 19.2543C8.51588 19.2073 8.54673 19.1505 8.56268 19.0888C8.58201 19.023 8.58244 18.953 8.56393 18.8868C8.54542 18.8207 8.5087 18.7611 8.45796 18.7149L5.26408 15.506Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default PencilEdit;
