import { api } from 'api/endpoints';
import { ApiOverlayCategories } from './types';

export const overlaysCategoriesEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchOverlaysCategories: builder.query<ApiOverlayCategories, void>({
      query: () => ({
        url: '/mapright_layers/categories.json',
        method: 'GET',
        withAuth: true,
      }),
    }),
  }),
});

export const { useFetchOverlaysCategoriesQuery } = overlaysCategoriesEndpoints;
