import { actionTypes as transformHandlerActionTypes } from '../actions/transformHandler';

const ACTION_HANDLERS = {
  [transformHandlerActionTypes.START_TRANSFORM]: (state, action) => ({
    status: 'DISABLED',
  }),
  [transformHandlerActionTypes.FINISH_TRANSFORM]: (state, action) => ({
    status: 'ENABLED',
  }),
};

const initialState = {
  status: 'ENABLED',
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
