import * as colors from 'components/ui/styles/colors.module.scss';

const Measurement = ({ fill = colors.black }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0H0V2H0.710025L3.15447 13H3V15H3.59891H4H5H10H10.5H10.801H12V13H11.8599L15.0363 7H16V5H14V5.11193L2 0.826212V0ZM1.73442 2H2V1.88807L14 6.17379V6.82026L10.7284 13H10V14H5V13H4.17886L1.73442 2Z"
      fill={fill}
    />
  </svg>
);

export default Measurement;
