import { NonConfigurableIconProps } from '../types';

const Hazard = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8158 18.725L13.4825 2.89167C13.3412 2.62273 13.1292 2.39752 12.8692 2.24036C12.6092 2.08321 12.3113 2.00009 12.0075 2C11.7037 2.00009 11.4057 2.08321 11.1458 2.24036C10.8858 2.39752 10.6737 2.62273 10.5325 2.89167L2.19916 18.725C2.0604 18.9827 1.99197 19.2725 2.00075 19.565C2.00953 19.8576 2.0952 20.1427 2.24916 20.3917C2.40102 20.6315 2.61163 20.8286 2.86101 20.9642C3.1104 21.0999 3.39029 21.1695 3.67416 21.1667H20.3408C20.6277 21.1666 20.9097 21.0924 21.1595 20.9514C21.4093 20.8104 21.6185 20.6073 21.7669 20.3618C21.9152 20.1162 21.9977 19.8366 22.0062 19.5498C22.0148 19.2631 21.9492 18.979 21.8158 18.725ZM11.1742 8.66667C11.1742 8.44565 11.262 8.23369 11.4182 8.07741C11.5745 7.92113 11.7865 7.83333 12.0075 7.83333C12.2285 7.83333 12.4405 7.92113 12.5967 8.07741C12.753 8.23369 12.8408 8.44565 12.8408 8.66667V13.6667C12.8408 13.8877 12.753 14.0996 12.5967 14.2559C12.4405 14.4122 12.2285 14.5 12.0075 14.5C11.7865 14.5 11.5745 14.4122 11.4182 14.2559C11.262 14.0996 11.1742 13.8877 11.1742 13.6667V8.66667ZM12.0492 18.25C11.7209 18.2481 11.4061 18.1197 11.1701 17.8915C10.9342 17.6634 10.7954 17.353 10.7825 17.025C10.7769 16.8603 10.8044 16.6961 10.8634 16.5422C10.9223 16.3882 11.0116 16.2477 11.1258 16.1288C11.24 16.01 11.3768 15.9152 11.5283 15.8501C11.6797 15.7851 11.8427 15.751 12.0075 15.75C12.3357 15.7519 12.6506 15.8803 12.8865 16.1085C13.1224 16.3366 13.2613 16.647 13.2742 16.975C13.2797 17.1397 13.2522 17.3039 13.1933 17.4578C13.1343 17.6118 13.0451 17.7523 12.9309 17.8712C12.8167 17.99 12.6798 18.0848 12.5284 18.1499C12.3769 18.2149 12.214 18.249 12.0492 18.25Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Hazard;
