import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Share = ({
  fill = colors.links300,
  width = '20',
  height = '20',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.667 3.79984L8.23367 5.23317C7.992 5.47484 7.592 5.47484 7.35033 5.23317C7.10867 4.9915 7.10867 4.5915 7.35033 4.34984L9.85867 1.84984C9.917 1.7915 9.98367 1.74984 10.0587 1.7165C10.2087 1.64984 10.3837 1.64984 10.5337 1.7165C10.6087 1.74984 10.6753 1.7915 10.7337 1.84984L13.2337 4.34984C13.4753 4.5915 13.4753 4.9915 13.2337 5.23317C13.1087 5.35817 12.9503 5.4165 12.792 5.4165C12.6337 5.4165 12.4753 5.35817 12.3503 5.23317L10.917 3.79984V9.2915C10.917 9.63317 10.6337 9.9165 10.292 9.9165C9.95033 9.9165 9.667 9.63317 9.667 9.2915V3.79984ZM13.792 6.6665H14.792C15.6837 6.6665 16.417 7.3915 16.417 8.2915V16.2915C16.417 17.1832 15.692 17.9165 14.792 17.9165H5.79199C4.90033 17.9165 4.16699 17.1915 4.16699 16.2915V8.2915C4.16699 7.3915 4.89199 6.6665 5.79199 6.6665H6.79199C7.13366 6.6665 7.41699 6.94984 7.41699 7.2915C7.41699 7.63317 7.13366 7.9165 6.79199 7.9165H5.79199C5.58366 7.9165 5.41699 8.08317 5.41699 8.2915V16.2915C5.41699 16.4998 5.58366 16.6665 5.79199 16.6665H14.792C15.0003 16.6665 15.167 16.4998 15.167 16.2915V8.2915C15.167 8.08317 15.0003 7.9165 14.792 7.9165H13.792C13.4503 7.9165 13.167 7.63317 13.167 7.2915C13.167 6.94984 13.4503 6.6665 13.792 6.6665Z"
      fill={fill}
    />
  </svg>
);

export default Share;
