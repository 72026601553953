/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import * as colorPalette from 'components/ui/styles/colors-deprecated.module.scss';

const DeedArch = ({ fill }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.08333 5.83342C8.23393 5.83342 9.16667 4.90067 9.16667 3.75008C9.16667 2.59949 8.23393 1.66675 7.08333 1.66675C5.93274 1.66675 5 2.59949 5 3.75008C5 4.90067 5.93274 5.83342 7.08333 5.83342Z"
      fill={fill}
    />
    <path
      d="M7.08333 18.3334C8.23393 18.3334 9.16667 17.4007 9.16667 16.2501C9.16667 15.0995 8.23393 14.1667 7.08333 14.1667C5.93274 14.1667 5 15.0995 5 16.2501C5 17.4007 5.93274 18.3334 7.08333 18.3334Z"
      fill={fill}
    />
    <path
      d="M13.3333 12.0834C14.4839 12.0834 15.4167 11.1507 15.4167 10.0001C15.4167 8.84949 14.4839 7.91675 13.3333 7.91675C12.1827 7.91675 11.25 8.84949 11.25 10.0001C11.25 11.1507 12.1827 12.0834 13.3333 12.0834Z"
      fill={fill}
    />
    <path
      d="M10.0069 14.7292C9.98766 14.7401 9.97073 14.7546 9.95713 14.772C9.94353 14.7895 9.93352 14.8094 9.92771 14.8307C9.92189 14.8521 9.92037 14.8743 9.92325 14.8963C9.92612 14.9182 9.93333 14.9393 9.94444 14.9584L10.4653 15.8612C10.4908 15.9017 10.5304 15.9314 10.5764 15.9445C10.597 15.9544 10.6195 15.9595 10.6424 15.9595C10.6652 15.9595 10.6877 15.9544 10.7083 15.9445C11.6445 15.3695 12.4319 14.582 13.0069 13.6459C13.0168 13.6253 13.0219 13.6028 13.0219 13.5799C13.0219 13.5571 13.0168 13.5346 13.0069 13.514C12.9938 13.4679 12.9641 13.4284 12.9236 13.4029L12.0208 12.882C12.0017 12.8709 11.9806 12.8637 11.9587 12.8608C11.9368 12.858 11.9145 12.8595 11.8932 12.8653C11.8718 12.8711 11.8519 12.8811 11.8345 12.8947C11.817 12.9083 11.8025 12.9252 11.7917 12.9445C11.3365 13.6644 10.7268 14.274 10.0069 14.7292Z"
      fill={fill}
    />
    <path
      d="M11.8125 7.07647C11.8233 7.09575 11.8379 7.11268 11.8553 7.12629C11.8727 7.13989 11.8927 7.14989 11.914 7.15571C11.9353 7.16152 11.9576 7.16304 11.9795 7.16017C12.0014 7.15729 12.0226 7.15009 12.0417 7.13897L12.9444 6.61814C12.9812 6.59784 13.0105 6.56617 13.0278 6.52786C13.0376 6.50726 13.0428 6.48472 13.0428 6.46189C13.0428 6.43905 13.0376 6.41651 13.0278 6.39592C12.4528 5.45979 11.6653 4.67231 10.7292 4.0973C10.7086 4.08744 10.686 4.08233 10.6632 4.08233C10.6404 4.08233 10.6178 4.08744 10.5972 4.0973C10.5512 4.11046 10.5116 4.14013 10.4861 4.18064L9.96528 5.08341C9.95416 5.10252 9.94696 5.12365 9.94408 5.14557C9.94121 5.16749 9.94272 5.18976 9.94854 5.21109C9.95436 5.23241 9.96436 5.25237 9.97796 5.26979C9.99156 5.28722 10.0085 5.30176 10.0278 5.31258C10.7454 5.7618 11.3549 6.36414 11.8125 7.07647Z"
      fill={fill}
    />
  </svg>
);

DeedArch.propTypes = {
  fill: PropTypes.string,
};

DeedArch.defaultProps = {
  fill: colorPalette.blue,
};

export default DeedArch;
