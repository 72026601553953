import api from 'api/index';
import actionTypes from './actions';

export const fetchUserSubscription = () => (dispatch, _getState) =>
  api.subscriptions
    .fetchUserSubscription()
    .then((response) => {
      dispatch({
        type: actionTypes.FETCH_USER_SUBSCRIPTIONS_SUCCESS,
        response,
      });
    })
    .catch((error) =>
      dispatch({ type: actionTypes.FETCH_USER_SUBSCRIPTIONS_FAILURE, error }),
    );

export const fetchAllUserSubscriptions = () => (dispatch, _getState) =>
  api.subscriptions
    .fetchAllUserSubscriptions()
    .then((response) => {
      dispatch({
        type: actionTypes.FETCH_ALL_USER_SUBSCRIPTIONS_SUCCESS,
        allSubscriptions: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.FETCH_ALL_USER_SUBSCRIPTIONS_FAILURE,
        error,
      });
    });

export const postUserSubscription = (subscription) => (dispatch, getState) => {
  const {
    user: { user },
  } = getState();

  dispatch({ type: actionTypes.POST_SUBSCRIPTION });

  return api.subscriptions
    .postUserSubscription(user.id, subscription)
    .then((response) => {
      dispatch({ type: actionTypes.POST_SUBSCRIPTION_SUCCESS, response });
    })
    .catch((error) => {
      const { message } = error;
      dispatch({ type: actionTypes.POST_SUBSCRIPTION_FAILURE, error });
      throw new Error(message);
    });
};

export const postReportsPlanSubscription =
  (subscription) => (dispatch, getState) => {
    const {
      user: { user },
    } = getState();

    dispatch({ type: actionTypes.POST_SUBSCRIPTION });

    return api.subscriptions
      .postReportsPlanSubscription(user.id, subscription)
      .then((response) => {
        dispatch({ type: actionTypes.POST_SUBSCRIPTION_SUCCESS, response });
      })
      .catch((error) => {
        const { message } = error;
        dispatch({ type: actionTypes.POST_SUBSCRIPTION_FAILURE, error });
        throw new Error(message);
      });
  };

export const patchUserSubscription =
  (subscription, skippedPayment = false) =>
  (dispatch, _getState) => {
    dispatch({ type: actionTypes.PATCH_SUBSCRIPTION });

    return api.subscriptions
      .patchUserSubscription({ subscription, skipped_payment: skippedPayment })
      .then((response) =>
        dispatch({ type: actionTypes.PATCH_SUBSCRIPTION_SUCCESS, response }),
      )
      .catch((error) =>
        dispatch({ type: actionTypes.PATCH_SUBSCRIPTION_FAILURE, error }),
      );
  };

export const fetchPlansAttributes =
  ({ active = false }) =>
  (dispatch) =>
    api.plans
      .fetchPlansAttributtes({ active })
      .then((response) =>
        dispatch({
          type: actionTypes.FETCH_SUBSCRIPTIONS_PLANS_SUCCESS,
          response: { data: { plans: response } },
        }),
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.FETCH_SUBSCRIPTIONS_PLANS_FAILURE,
          error,
        }),
      );

export const actions = {
  fetchUserSubscription,
  fetchAllUserSubscriptions,
  postUserSubscription,
  postReportsPlanSubscription,
  fetchPlansAttributes,
};
