import { NonConfigurableIconProps } from '../types';

const Menu = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.85004 7.75006C7.43582 7.75006 7.10004 8.08585 7.10004 8.50006C7.10004 8.91427 7.43582 9.25006 7.85004 9.25006H15.65C16.0643 9.25006 16.4 8.91427 16.4 8.50006C16.4 8.08585 16.0643 7.75006 15.65 7.75006H7.85004Z"
        fill="#1D1D1D"
      />
      <path
        d="M7.10004 11.75C7.10004 11.3358 7.43582 11 7.85004 11H15.65C16.0643 11 16.4 11.3358 16.4 11.75C16.4 12.1642 16.0643 12.5 15.65 12.5H7.85004C7.43582 12.5 7.10004 12.1642 7.10004 11.75Z"
        fill="#1D1D1D"
      />
      <path
        d="M7.85004 14.2499C7.43582 14.2499 7.10004 14.5857 7.10004 14.9999C7.10004 15.4142 7.43582 15.7499 7.85004 15.7499H15.65C16.0643 15.7499 16.4 15.4142 16.4 14.9999C16.4 14.5857 16.0643 14.2499 15.65 14.2499H7.85004Z"
        fill="#1D1D1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 1.25C8.96523 1.25 6.29451 2.35625 4.32538 4.32538C2.35625 6.29451 1.25 8.96523 1.25 11.75C1.25 14.5348 2.35625 17.2055 4.32538 19.1746C6.29451 21.1438 8.96523 22.25 11.75 22.25C14.5348 22.25 17.2055 21.1438 19.1746 19.1746C21.1438 17.2055 22.25 14.5348 22.25 11.75C22.25 8.96523 21.1438 6.29451 19.1746 4.32538C17.2055 2.35625 14.5348 1.25 11.75 1.25ZM5.38604 5.38604C7.07387 3.69821 9.36305 2.75 11.75 2.75C14.1369 2.75 16.4261 3.69821 18.114 5.38604C19.8018 7.07387 20.75 9.36305 20.75 11.75C20.75 14.1369 19.8018 16.4261 18.114 18.114C16.4261 19.8018 14.1369 20.75 11.75 20.75C9.36305 20.75 7.07387 19.8018 5.38604 18.114C3.69821 16.4261 2.75 14.1369 2.75 11.75C2.75 9.36305 3.69821 7.07387 5.38604 5.38604Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Menu;
