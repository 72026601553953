import { NavLink as ReactRouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const Link = ({ children, color, type, ...props }) => {
  if (type === 'inline') {
    return (
      <ReactRouterLink
        className={`reskin-link reskin-link--${color} reskin-link-inline`}
        {...props}
      >
        {children}
      </ReactRouterLink>
    );
  }

  return (
    <div className="reskin-link-container">
      <ReactRouterLink
        className={`reskin-link reskin-link--${color}`}
        {...props}
      >
        {children}
      </ReactRouterLink>
    </div>
  );
};

Link.defaultProps = {
  color: 'blue',
  type: 'inline',
};

Link.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['blue', 'gray']),
  type: PropTypes.oneOf(['block', 'inline']),
};

/**
 * @deprecated Use `Link` from `components/ui/Link` instead.
 */
export default React.memo(Link);
