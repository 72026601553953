import React from 'react';
import { IconWithExtraProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Plus = ({
  fill = colors.white,
  width = '20',
  height = '20',
  strokeWidth,
}: IconWithExtraProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 3C13.5 2.17157 12.8284 1.5 12 1.5C11.1716 1.5 10.5 2.17157 10.5 3V10.5H3C2.17157 10.5 1.5 11.1716 1.5 12C1.5 12.8284 2.17157 13.5 3 13.5H10.5V21C10.5 21.8284 11.1716 22.5 12 22.5C12.8284 22.5 13.5 21.8284 13.5 21V13.5H21C21.8284 13.5 22.5 12.8284 22.5 12C22.5 11.1716 21.8284 10.5 21 10.5H13.5V3Z"
      fill={fill}
      stroke={strokeWidth ? fill : 'none'}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Plus;
