/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n  query AuthzPlanEntitlements {\n    planEntitlements {\n      current {\n        code\n        quota\n      }\n    }\n  }\n": typeof types.AuthzPlanEntitlementsDocument,
    "\n  query Search(\n    $query: String!\n    $top: Float!\n    $left: Float!\n    $right: Float!\n    $bottom: Float!\n  ) {\n    search(\n      filter: {\n        q: $query\n        bboxBias: { top: $top, left: $left, right: $right, bottom: $bottom }\n      }\n      limit: 100\n    ) {\n      hits {\n        id\n        kind\n        title\n        ... on GenericSearchItem {\n          apn\n          address\n          cityName\n          stateCode\n          geojson\n          location {\n            lat\n            lon\n          }\n        }\n      }\n    }\n  }\n": typeof types.SearchDocument,
    "\n  mutation EventRouterCreateEvent($NewEventSeries: NewEventSeries!) {\n    createEvent(input: $NewEventSeries) {\n      id\n    }\n  }\n": typeof types.EventRouterCreateEventDocument,
};
const documents: Documents = {
    "\n  query AuthzPlanEntitlements {\n    planEntitlements {\n      current {\n        code\n        quota\n      }\n    }\n  }\n": types.AuthzPlanEntitlementsDocument,
    "\n  query Search(\n    $query: String!\n    $top: Float!\n    $left: Float!\n    $right: Float!\n    $bottom: Float!\n  ) {\n    search(\n      filter: {\n        q: $query\n        bboxBias: { top: $top, left: $left, right: $right, bottom: $bottom }\n      }\n      limit: 100\n    ) {\n      hits {\n        id\n        kind\n        title\n        ... on GenericSearchItem {\n          apn\n          address\n          cityName\n          stateCode\n          geojson\n          location {\n            lat\n            lon\n          }\n        }\n      }\n    }\n  }\n": types.SearchDocument,
    "\n  mutation EventRouterCreateEvent($NewEventSeries: NewEventSeries!) {\n    createEvent(input: $NewEventSeries) {\n      id\n    }\n  }\n": types.EventRouterCreateEventDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AuthzPlanEntitlements {\n    planEntitlements {\n      current {\n        code\n        quota\n      }\n    }\n  }\n"): (typeof documents)["\n  query AuthzPlanEntitlements {\n    planEntitlements {\n      current {\n        code\n        quota\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Search(\n    $query: String!\n    $top: Float!\n    $left: Float!\n    $right: Float!\n    $bottom: Float!\n  ) {\n    search(\n      filter: {\n        q: $query\n        bboxBias: { top: $top, left: $left, right: $right, bottom: $bottom }\n      }\n      limit: 100\n    ) {\n      hits {\n        id\n        kind\n        title\n        ... on GenericSearchItem {\n          apn\n          address\n          cityName\n          stateCode\n          geojson\n          location {\n            lat\n            lon\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Search(\n    $query: String!\n    $top: Float!\n    $left: Float!\n    $right: Float!\n    $bottom: Float!\n  ) {\n    search(\n      filter: {\n        q: $query\n        bboxBias: { top: $top, left: $left, right: $right, bottom: $bottom }\n      }\n      limit: 100\n    ) {\n      hits {\n        id\n        kind\n        title\n        ... on GenericSearchItem {\n          apn\n          address\n          cityName\n          stateCode\n          geojson\n          location {\n            lat\n            lon\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EventRouterCreateEvent($NewEventSeries: NewEventSeries!) {\n    createEvent(input: $NewEventSeries) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation EventRouterCreateEvent($NewEventSeries: NewEventSeries!) {\n    createEvent(input: $NewEventSeries) {\n      id\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;