import { useDispatch } from 'react-redux';
import { hideModal } from 'store/slices/modalsSlice';
import { useAppSelector } from 'store/hooks';

function RootModal() {
  const dispatch = useDispatch();
  const modals = useAppSelector(({ modals }) => modals);

  const activeModal = modals[modals.length - 1];

  if (!activeModal) {
    return null;
  }

  const { component: SpecificModal, props: modalProps } = activeModal;

  return (
    <SpecificModal
      isOpen
      {...modalProps}
      onClose={() => {
        dispatch(hideModal());

        modalProps.onClose?.();
      }}
    />
  );
}

export default RootModal;
