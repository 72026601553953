import actionTypes from './actions';

export const flash = (key, options) => ({
  type: actionTypes.FLASH,
  key,
  options,
});

export const clearFlash = () => ({
  type: actionTypes.CLEAR_FLASH,
});

export const actions = {
  flash,
  clearFlash,
};
