import { v4 as uuidv4 } from 'uuid';
import * as amplitude from '@amplitude/analytics-browser';
import { gql } from 'graphql-client-generated';
import apolloClient from 'services/apolloClient';
import store from 'store';
import { logEvent } from 'store/slices/eventLoggerSlice';
import AppsFlyer from './AppsFlyer';

const CREATE_EVENT_MUTATION = gql(`
  mutation EventRouterCreateEvent($NewEventSeries: NewEventSeries!) {
    createEvent(input: $NewEventSeries) {
      id
    }
  }
`);

// Mobile Measurement Partner events are handled separately for now.
// Full integration planned upon commitment to Kochava/AppsFlyer in eventRouter.
const ALLOWED_MMP_EVENTS = [
  'account_signUp_submitted',
  'account_user_loggedIn',
  'account_session_started',
  'account_delete_submitted',
  'subscription_trial_started',
];

type Properties = Record<string, string | number | boolean>;
type EventParams = {
  eventName: string;
  properties: Properties;
};

export interface SentEvent extends GQL.NewEvent {
  eventId: string | undefined;
}

class EventRouter {
  enabled: boolean | null;
  loggingEnabled: boolean | null;
  installId: string | null;
  pendingQueue: EventParams[];
  eventLog: { properties: Properties; eventId: string | undefined }[];

  constructor() {
    // Check for install ID in local storage
    this.installId = localStorage.getItem('installId');

    this.enabled = null;
    this.loggingEnabled = null;
    this.pendingQueue = [];

    // If not found, generate a new UUID and store it
    if (!this.installId) {
      this.installId = uuidv4();
      localStorage.setItem('installId', this.installId);
    }

    this.eventLog = [];
  }

  /**
   * Function to send a single event using a GraphQL mutation.
   */
  async send(
    eventName: EventParams['eventName'],
    properties: EventParams['properties'],
  ) {
    try {
      if (!eventName) throw new Error('Event name is required.');

      const event: GQL.NewEvent = {
        name: eventName,
        timestamp: new Date().toISOString(),
        installId: this.installId as string,
        properties,
        vendorProperties: {
          amplitudeSdkID: amplitude.getDeviceId(),
          // @ts-ignore - the api expects a string but we are (and were) sending a number.
          // so we are keeping this as a number until we confirm that changing it to a string
          // won't break anything
          amplitudeSessionID: amplitude.getSessionId(),
        },
      };

      if (this.enabled == null) {
        this.queueEvent(eventName, properties);
        return;
      } else if (!this.enabled) {
        return;
      }

      const { data } = await apolloClient.mutate({
        mutation: CREATE_EVENT_MUTATION,
        variables: {
          NewEventSeries: {
            events: [event],
          },
        },
        context: { retry: true },
      });

      if (this.loggingEnabled) {
        this.logEvent({ ...event, eventId: data?.createEvent?.[0]?.id });
      }

      if (!data) {
        throw new Error(
          'The GraphQL was unable to process the event and returned false.',
        );
      }

      this.sendToMmpProvider(eventName, properties);
    } catch {}
  }

  sendWithPlanProperties(
    eventName: EventParams['eventName'],
    properties: EventParams['properties'],
  ) {
    const {
      subscriptions: { userSubscription },
      user: { user },
    } = store.getState();

    const planProperties: Properties = {};
    if (userSubscription) {
      planProperties.plan_selected = userSubscription.plan_info.code;
    }
    if (user) {
      planProperties.subscription_status = user.subscription_status;
    }

    return this.send(eventName, { ...planProperties, ...properties });
  }

  sendToMmpProvider(
    eventName: EventParams['eventName'],
    properties: EventParams['properties'],
  ) {
    try {
      if (!ALLOWED_MMP_EVENTS.includes(eventName)) return;

      AppsFlyer.sendEvent(eventName, properties);
    } catch (error) {
      console.error(error);
    }
  }

  // Should be taken out after initial roll out of event router
  setLogging(value: boolean) {
    this.loggingEnabled = value;
  }

  setEnabled(value: boolean) {
    this.enabled = value;
    if (this.enabled) {
      this.sendQueuedEvents();
    }
  }

  queueEvent(
    eventName: EventParams['eventName'],
    properties: EventParams['properties'],
  ) {
    this.pendingQueue.push({ eventName, properties });
  }

  sendQueuedEvents() {
    this.pendingQueue.forEach(({ eventName, properties }) => {
      this.send(eventName, properties);
    });
    this.pendingQueue = [];
  }

  logEvent(event: SentEvent) {
    store.dispatch(logEvent(event));
  }
}

// Singleton instance
export default new EventRouter();
