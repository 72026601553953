import { NonConfigurableIconProps } from '../types';

const Unlock = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1667 9.93011H17.5417V7.63844C17.5449 6.2858 17.0606 4.97729 16.1775 3.95269C15.2944 2.92809 14.0717 2.25601 12.7334 2.05962C11.3951 1.86323 10.0308 2.15567 8.89059 2.88335C7.75036 3.61104 6.91053 4.72523 6.525 6.02177C6.44544 6.28699 6.47449 6.57295 6.60576 6.81675C6.73704 7.06054 6.95978 7.24221 7.225 7.32177C7.49022 7.40134 7.77618 7.37229 8.01997 7.24101C8.26377 7.10974 8.44543 6.88699 8.525 6.62177C8.76968 5.80802 9.29877 5.10937 10.0157 4.65328C10.7327 4.1972 11.5898 4.01409 12.4305 4.13738C13.2713 4.26066 14.0397 4.68211 14.5955 5.32483C15.1513 5.96756 15.4576 6.7887 15.4583 7.63844V9.51344C15.4583 9.62395 15.4144 9.72993 15.3363 9.80807C15.2582 9.88621 15.1522 9.93011 15.0417 9.93011H5.66667C5.22464 9.93011 4.80072 10.1057 4.48816 10.4183C4.17559 10.7308 4 11.1547 4 11.5968V20.3468C4 20.7888 4.17559 21.2127 4.48816 21.5253C4.80072 21.8378 5.22464 22.0134 5.66667 22.0134H18.1667C18.6087 22.0134 19.0326 21.8378 19.3452 21.5253C19.6577 21.2127 19.8333 20.7888 19.8333 20.3468V11.5968C19.8333 11.1547 19.6577 10.7308 19.3452 10.4183C19.0326 10.1057 18.6087 9.93011 18.1667 9.93011ZM10.25 14.9301C10.25 14.4881 10.4256 14.0642 10.7382 13.7516C11.0507 13.439 11.4746 13.2634 11.9167 13.2634C12.3587 13.2634 12.7826 13.439 13.0952 13.7516C13.4077 14.0642 13.5833 14.4881 13.5833 14.9301C13.5816 15.221 13.5037 15.5063 13.3575 15.7577C13.2113 16.0092 13.0019 16.218 12.75 16.3634V18.2634C12.75 18.4845 12.6622 18.6964 12.5059 18.8527C12.3496 19.009 12.1377 19.0968 11.9167 19.0968C11.6957 19.0968 11.4837 19.009 11.3274 18.8527C11.1711 18.6964 11.0833 18.4845 11.0833 18.2634V16.3634C10.8314 16.218 10.622 16.0092 10.4758 15.7577C10.3296 15.5063 10.2518 15.221 10.25 14.9301Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Unlock;
