import { StyleableOnlyIconProps } from '../types';

const Home = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5513 2.4011C11.8187 2.19956 12.1873 2.19964 12.4545 2.4013L21.1478 8.96042C21.1678 8.97552 21.187 8.99162 21.2054 9.00864C21.5276 9.30679 21.7216 9.71833 21.7464 10.1566C21.7472 10.1707 21.7476 10.1849 21.7476 10.199V20.2177L21.7476 20.2208C21.7459 20.6261 21.5841 21.0143 21.2973 21.3008C21.0105 21.5873 20.6221 21.7488 20.2168 21.75L20.2145 21.75H14.9984C14.5842 21.75 14.2484 21.4142 14.2484 21V16.8006C14.2484 16.2039 14.0114 15.6317 13.5895 15.2099C13.1676 14.788 12.5954 14.551 11.9988 14.551C11.4022 14.551 10.83 14.788 10.4081 15.2099C9.98621 15.6317 9.7492 16.2039 9.7492 16.8006V20.9976C9.7492 21.4118 9.41341 21.7476 8.9992 21.7476H3.7823L3.78001 21.7476C3.37461 21.7464 2.98616 21.5848 2.6995 21.2981C2.41283 21.0114 2.25124 20.623 2.25 20.2176L2.25 20.2153V10.199C2.25 10.1852 2.25038 10.1714 2.25114 10.1576C2.27542 9.71854 2.46975 9.30618 2.79293 9.00795C2.81119 8.9911 2.83027 8.97517 2.8501 8.96022L11.5513 2.4011ZM3.79411 10.1271C3.76996 10.1555 3.75458 10.1904 3.75 10.2276V20.2134C3.75013 20.2224 3.75377 20.2311 3.76016 20.2374C3.76657 20.2439 3.77524 20.2475 3.78431 20.2476C3.7844 20.2476 3.78449 20.2476 3.78459 20.2476L8.2492 20.2476V16.8006C8.2492 15.8061 8.64425 14.8524 9.34743 14.1492C10.0506 13.446 11.0043 13.051 11.9988 13.051C12.9933 13.051 13.947 13.446 14.6502 14.1492C15.3534 14.8524 15.7484 15.8061 15.7484 16.8006V20.25H20.2126C20.2219 20.2499 20.2307 20.2461 20.2372 20.2396C20.2437 20.2331 20.2474 20.2244 20.2476 20.2153V10.2283C20.2429 10.1907 20.2273 10.1554 20.2029 10.1266L12.0026 3.93938L3.79411 10.1271Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 9.91467C21.9997 9.7874 21.9702 9.6619 21.9139 9.54778C21.8576 9.43366 21.7758 9.33396 21.675 9.25633L12.5083 2.173C12.3626 2.06083 12.1839 2 12 2C11.8161 2 11.6374 2.06083 11.4917 2.173L2.325 9.25633C2.22415 9.33396 2.14243 9.43366 2.08609 9.54778C2.02976 9.6619 2.00031 9.7874 2 9.91467V21.1647C2 21.3857 2.0878 21.5976 2.24408 21.7539C2.40036 21.9102 2.61232 21.998 2.83333 21.998H9.5C9.61051 21.998 9.71649 21.9541 9.79463 21.876C9.87277 21.7978 9.91667 21.6918 9.91667 21.5813V17.8313C9.91667 17.2788 10.1362 16.7489 10.5269 16.3582C10.9176 15.9675 11.4475 15.748 12 15.748C12.5525 15.748 13.0824 15.9675 13.4731 16.3582C13.8638 16.7489 14.0833 17.2788 14.0833 17.8313V21.5813C14.0833 21.6918 14.1272 21.7978 14.2054 21.876C14.2835 21.9541 14.3895 21.998 14.5 21.998H21.1667C21.3877 21.998 21.5996 21.9102 21.7559 21.7539C21.9122 21.5976 22 21.3857 22 21.1647V9.91467Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default Home;
