import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Pencil = ({
  fill = colors.white,
  width = '12',
  height = '14',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.19418 0.333496C4.7949 0.334967 3.45336 0.891478 2.46392 1.88091C1.47449 2.87035 0.917975 4.21189 0.916504 5.61117C0.916504 9.24998 4.52754 12.5555 5.63863 13.4721C5.78789 13.5979 5.97678 13.6668 6.17195 13.6668C6.36712 13.6668 6.55602 13.5979 6.70528 13.4721C7.86081 12.5555 11.4718 9.24998 11.4718 5.61117C11.4704 4.21189 10.9139 2.87035 9.92443 1.88091C8.93499 0.891478 7.59345 0.334967 6.19418 0.333496V0.333496ZM8.83301 6.48893H7.02749C6.95382 6.48893 6.88317 6.51819 6.83108 6.57029C6.77899 6.62238 6.74972 6.69303 6.74972 6.7667V8.57222C6.74972 8.71956 6.69119 8.86086 6.587 8.96505C6.48282 9.06923 6.34152 9.12776 6.19418 9.12776C6.04684 9.12776 5.90553 9.06923 5.80135 8.96505C5.69716 8.86086 5.63863 8.71956 5.63863 8.57222V6.7667C5.63863 6.69303 5.60937 6.62238 5.55727 6.57029C5.50518 6.51819 5.43453 6.48893 5.36086 6.48893H3.55534C3.408 6.48893 3.2667 6.4304 3.16251 6.32621C3.05833 6.22203 2.9998 6.08072 2.9998 5.93338C2.9998 5.78604 3.05833 5.64474 3.16251 5.54056C3.2667 5.43637 3.408 5.37784 3.55534 5.37784H5.36086C5.43453 5.37784 5.50518 5.34857 5.55727 5.29648C5.60937 5.24439 5.63863 5.17374 5.63863 5.10007V3.29455C5.63863 3.14721 5.69716 3.0059 5.80135 2.90172C5.90553 2.79753 6.04684 2.739 6.19418 2.739C6.34152 2.739 6.48282 2.79753 6.587 2.90172C6.69119 3.0059 6.74972 3.14721 6.74972 3.29455V5.10007C6.74972 5.17374 6.77899 5.24439 6.83108 5.29648C6.88317 5.34857 6.95382 5.37784 7.02749 5.37784H8.83301C8.98035 5.37784 9.12166 5.43637 9.22584 5.54056C9.33003 5.64474 9.38856 5.78604 9.38856 5.93338C9.38856 6.08072 9.33003 6.22203 9.22584 6.32621C9.12166 6.4304 8.98035 6.48893 8.83301 6.48893Z"
      fill={fill}
    />
  </svg>
);

export default Pencil;
