import { StyleableOnlyIconProps } from '../types';

const NavigationMenu = ({
  width = '24',
  height = '24',
  style = 'regular',
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 6C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H21.3214C21.7356 7.5 22.0714 7.16421 22.0714 6.75C22.0714 6.33579 21.7356 6 21.3214 6H2.75ZM2 12.4643C2 12.0501 2.33579 11.7143 2.75 11.7143H21.3214C21.7356 11.7143 22.0714 12.0501 22.0714 12.4643C22.0714 12.8785 21.7356 13.2143 21.3214 13.2143H2.75C2.33579 13.2143 2 12.8785 2 12.4643ZM2 18.1786C2 17.7644 2.33579 17.4286 2.75 17.4286H21.3214C21.7356 17.4286 22.0714 17.7644 22.0714 18.1786C22.0714 18.5928 21.7356 18.9286 21.3214 18.9286H2.75C2.33579 18.9286 2 18.5928 2 18.1786Z"
          fill="#1D1D1D"
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2174 5H3.78261C3.35039 5 3 5.35039 3 5.78261V6.56522C3 6.99744 3.35039 7.34783 3.78261 7.34783H20.2174C20.6496 7.34783 21 6.99744 21 6.56522V5.78261C21 5.35039 20.6496 5 20.2174 5ZM20.2174 11.2609H3.78261C3.35039 11.2609 3 11.6113 3 12.0435V12.8261C3 13.2583 3.35039 13.6087 3.78261 13.6087H20.2174C20.6496 13.6087 21 13.2583 21 12.8261V12.0435C21 11.6113 20.6496 11.2609 20.2174 11.2609ZM3.78261 17.5217H20.2174C20.6496 17.5217 21 17.8721 21 18.3044V19.087C21 19.5192 20.6496 19.8696 20.2174 19.8696H3.78261C3.35039 19.8696 3 19.5192 3 19.087V18.3044C3 17.8721 3.35039 17.5217 3.78261 17.5217Z"
          fill="#1D1D1D"
        />
      </svg>;
};

export default NavigationMenu;
