import { NonConfigurableIconProps } from '../types';

const Merge = ({ width = '24', height = '24' }: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 3.20003C22.0011 3.04213 21.9708 2.88559 21.9109 2.7395C21.851 2.59341 21.7626 2.46068 21.651 2.34903C21.5393 2.23738 21.4066 2.14903 21.2605 2.08912C21.1144 2.0292 20.9579 1.99892 20.8 2.00003H16.95C16.0293 2.00045 15.1204 2.20817 14.2909 2.60778C13.4614 3.00738 12.7325 3.58861 12.1583 4.30836C12.1403 4.33309 12.1164 4.3529 12.0887 4.366C12.061 4.3791 12.0306 4.38507 12 4.38336C11.9697 4.38311 11.9399 4.37627 11.9126 4.36332C11.8852 4.35036 11.861 4.33161 11.8417 4.30836C11.2675 3.58861 10.5386 3.00738 9.70907 2.60778C8.87956 2.20817 7.97074 2.00045 7.05 2.00003H3.2C2.88174 2.00003 2.57652 2.12646 2.35147 2.3515C2.12643 2.57654 2 2.88177 2 3.20003C2 3.51829 2.12643 3.82351 2.35147 4.04856C2.57652 4.2736 2.88174 4.40003 3.2 4.40003H7.05C8.04389 4.40223 8.99643 4.79803 9.69922 5.50081C10.402 6.2036 10.7978 7.15614 10.8 8.15003V17C10.8 17.0553 10.7781 17.1083 10.739 17.1473C10.6999 17.1864 10.6469 17.2084 10.5917 17.2084H9.6C9.36365 17.2111 9.13336 17.2835 8.93802 17.4166C8.74268 17.5496 8.59099 17.7374 8.50198 17.9564C8.41297 18.1754 8.39061 18.4157 8.4377 18.6473C8.48479 18.879 8.59923 19.0915 8.76667 19.2584L11.1667 21.65C11.3913 21.864 11.6897 21.9834 12 21.9834C12.3103 21.9834 12.6087 21.864 12.8333 21.65L15.25 19.25C15.4174 19.0832 15.5319 18.8706 15.579 18.639C15.6261 18.4074 15.6037 18.167 15.5147 17.9481C15.4257 17.7291 15.274 17.5413 15.0786 17.4082C14.8833 17.2751 14.653 17.2027 14.4167 17.2H13.4083C13.3531 17.2 13.3001 17.1781 13.261 17.139C13.2219 17.0999 13.2 17.0469 13.2 16.9917V8.15003C13.2022 7.15614 13.598 6.2036 14.3008 5.50081C15.0036 4.79803 15.9561 4.40223 16.95 4.40003H20.8C20.9579 4.40114 21.1144 4.37086 21.2605 4.31094C21.4066 4.25103 21.5393 4.16268 21.651 4.05103C21.7626 3.93938 21.851 3.80665 21.9109 3.66056C21.9708 3.51447 22.0011 3.35792 22 3.20003Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default Merge;
