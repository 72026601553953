import { StyleableOnlyIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const EyeSlash = ({
  width = '24',
  height = '24',
  style = 'regular',
  color = colors.black,
}: StyleableOnlyIconProps) => {
  return style === 'regular' ?
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4393 19.5001C18.7322 19.793 19.207 19.793 19.4999 19.5001C19.7928 19.2072 19.7928 18.7323 19.4999 18.4394L5.06059 4.00007C4.7677 3.70717 4.29283 3.70717 3.99993 4.00007C3.70704 4.29296 3.70704 4.76783 3.99993 5.06073L6.0447 7.10549C4.48735 8.21101 3.30554 9.50296 2.63271 10.3245C2.21729 10.8318 2.1397 11.5176 2.44882 12.0958C3.49009 14.0435 6.41718 18.3333 11.9999 18.3333C13.664 18.3333 15.0921 17.9522 16.3047 17.3655L18.4393 19.5001ZM15.1686 16.2294L14.1091 15.1699C13.4942 15.538 12.7722 15.75 11.9999 15.75C9.75507 15.75 7.93529 13.9591 7.93529 11.75C7.93529 10.967 8.1639 10.2366 8.559 9.61979L7.12219 8.18298C6.65168 8.50342 6.20826 8.85123 5.79613 9.20928C4.98508 9.9139 4.33744 10.622 3.8934 11.1538C3.80174 11.2635 3.78647 11.4181 3.85577 11.5432L3.85853 11.5482C4.19235 12.1501 4.70413 12.9509 5.4121 13.7464C6.82384 15.3329 8.94634 16.8333 11.9999 16.8333C13.1941 16.8333 14.246 16.6038 15.1686 16.2294ZM9.67656 10.7373C9.53703 11.0469 9.45951 11.3895 9.45951 11.75C9.45951 13.1307 10.5969 14.25 11.9999 14.25C12.3517 14.25 12.6869 14.1796 12.9916 14.0524L9.67656 10.7373Z"
          fill={color}
        />
        <path
          d="M21.5509 12.0958C21.0623 13.0098 20.1585 14.4393 18.76 15.6994L17.698 14.6375C18.0216 14.3502 18.3176 14.0499 18.5876 13.7464C19.2956 12.9509 19.8074 12.1501 20.1412 11.5482L20.144 11.5432C20.2133 11.4181 20.198 11.2635 20.1063 11.1538C19.6623 10.622 19.0147 9.9139 18.2036 9.20928C16.5494 7.77213 14.3912 6.50001 11.9999 6.50001C11.2704 6.50001 10.5626 6.61839 9.88423 6.82371L8.70595 5.64543C9.72313 5.24842 10.8279 5.00001 11.9999 5.00001C16.4893 5.00001 19.9916 8.64507 21.367 10.3245C21.7824 10.8318 21.86 11.5176 21.5509 12.0958Z"
          fill={color}
        />
        <path
          d="M16.0645 11.75C16.0645 12.131 16.0103 12.4996 15.9092 12.8487L14.5237 11.4632C14.3916 10.3249 13.4827 9.41889 12.3317 9.27114L10.9462 7.88571C11.2823 7.7972 11.6355 7.75001 11.9999 7.75001C14.2447 7.75001 16.0645 9.54087 16.0645 11.75Z"
          fill={color}
        />
      </svg>
    : <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4393 19.5001C18.7322 19.793 19.207 19.793 19.4999 19.5001C19.7928 19.2072 19.7928 18.7323 19.4999 18.4394L5.06059 4.00007C4.7677 3.70717 4.29283 3.70717 3.99993 4.00007C3.70704 4.29296 3.70704 4.76783 3.99993 5.06073L6.0447 7.10549C4.48735 8.21101 3.30554 9.50296 2.63271 10.3245C2.21729 10.8318 2.1397 11.5176 2.44882 12.0958C3.49009 14.0435 6.41718 18.3333 11.9999 18.3333C13.664 18.3333 15.0921 17.9522 16.3047 17.3655L18.4393 19.5001ZM7.99988 12C7.99988 11.1693 8.25717 10.3978 8.6976 9.75839L9.95216 11.0129C9.82198 11.3157 9.74988 11.6493 9.74988 11.9997C9.74988 13.3806 10.8693 14.5 12.2501 14.5C12.6006 14.5 12.9342 14.4279 13.2369 14.2977L14.2881 15.3489C13.6491 15.7606 12.8852 16 12.0645 16C9.81965 16 7.99988 14.2091 7.99988 12Z"
          fill={color}
        />
        <path
          d="M21.5509 12.0958C21.0623 13.0098 20.1585 14.4393 18.76 15.6994L16.0131 12.9526C16.0889 12.6473 16.129 12.3283 16.129 12C16.129 9.79086 14.3093 8 12.0645 8C11.7536 8 11.4508 8.03435 11.1599 8.09941L8.70595 5.64543C9.72313 5.24842 10.8279 5.00001 11.9999 5.00001C16.4893 5.00001 19.9916 8.64507 21.367 10.3245C21.7824 10.8318 21.86 11.5176 21.5509 12.0958Z"
          fill={color}
        />
        <path
          d="M12.3011 9.5C13.6415 9.52682 14.7231 10.6084 14.7499 11.9487L12.3011 9.5Z"
          fill={color}
        />
      </svg>;
};

export default EyeSlash;
