import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const ArrowRight = ({
  fill = colors.white,
  width = '15',
  height = '16',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25339 8.64222C8.33684 8.43846 8.37911 8.22018 8.37774 8C8.37911 7.77982 8.33684 7.56154 8.25339 7.35778C8.16993 7.15402 8.04694 6.96881 7.89151 6.81285L1.86734 0.788675C1.65467 0.596374 1.37624 0.493152 1.08962 0.500353C0.802997 0.507554 0.530102 0.624628 0.327365 0.827365C0.124628 1.0301 0.007554 1.303 0.000352946 1.58962C-0.00684811 1.87624 0.096374 2.15467 0.288675 2.36734L5.80768 7.88634C5.83563 7.91759 5.85109 7.95806 5.85109 8C5.85109 8.04194 5.83563 8.08241 5.80768 8.11366L0.288675 13.6327C0.096374 13.8453 -0.00684811 14.1238 0.000352946 14.4104C0.007554 14.697 0.124628 14.9699 0.327365 15.1726C0.530102 15.3754 0.802997 15.4924 1.08962 15.4996C1.37624 15.5068 1.65467 15.4036 1.86734 15.2113L7.89151 9.18715C8.04694 9.03119 8.16993 8.84598 8.25339 8.64222ZM14.3261 9.18715C14.6348 8.86969 14.8051 8.44279 14.7997 8C14.8051 7.55721 14.6348 7.13031 14.3261 6.81285L8.283 0.788675C8.07033 0.596374 7.7919 0.493152 7.50528 0.500353C7.21866 0.507554 6.94576 0.624628 6.74302 0.827365C6.54029 1.0301 6.42321 1.303 6.41601 1.58962C6.40881 1.87624 6.51203 2.15467 6.70433 2.36734L12.2423 7.88634C12.2576 7.90106 12.2697 7.91872 12.278 7.93825C12.2863 7.95778 12.2906 7.97878 12.2906 8C12.2906 8.02122 12.2863 8.04222 12.278 8.06175C12.2697 8.08128 12.2576 8.09894 12.2423 8.11366L6.72328 13.6327C6.53098 13.8453 6.42775 14.1238 6.43496 14.4104C6.44216 14.697 6.55923 14.9699 6.76197 15.1726C6.9647 15.3754 7.2376 15.4924 7.52422 15.4996C7.81085 15.5068 8.08928 15.4036 8.30194 15.2113L14.3261 9.18715Z"
      fill={fill}
    />
  </svg>
);

export default ArrowRight;
