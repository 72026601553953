import { makeAuthenticatedRequest } from './utils/request';
import { getAPIURL } from './utils/domains';

export const fetchUserSubscription = () =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/subscriptions/user_subscription.json`,
  );

export const fetchAllUserSubscriptions = () =>
  makeAuthenticatedRequest(`${getAPIURL()}/subscriptions.json`);

export const postUserSubscription = (userId, subscription) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/users/${userId}/subscriptions.json`,
    {
      method: 'POST',
      body: JSON.stringify({ subscription }),
    },
  );

export const postReportsPlanSubscription = (userId, subscription) =>
  makeAuthenticatedRequest(
    `${getAPIURL()}/users/${userId}/subscriptions/subscribe_to_reports_plan.json`,
    {
      method: 'POST',
      body: JSON.stringify({ subscription }),
    },
  );

export const patchUserSubscription = (data) =>
  makeAuthenticatedRequest(`${getAPIURL()}/subscriptions`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });

export default {
  fetchUserSubscription,
  fetchAllUserSubscriptions,
  postUserSubscription,
  patchUserSubscription,
  postReportsPlanSubscription,
};
