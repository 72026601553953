import { NonConfigurableIconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Lock = ({
  width = '24',
  height = '24',
  color = colors.black,
}: NonConfigurableIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1667 9.91667H17.5417V7.625C17.5417 6.13316 16.949 4.70242 15.8941 3.64752C14.8392 2.59263 13.4085 2 11.9167 2C10.4248 2 8.99408 2.59263 7.93919 3.64752C6.8843 4.70242 6.29167 6.13316 6.29167 7.625V9.91667H5.66667C5.22464 9.91667 4.80072 10.0923 4.48816 10.4048C4.17559 10.7174 4 11.1413 4 11.5833V20.3333C4 20.7754 4.17559 21.1993 4.48816 21.5118C4.80072 21.8244 5.22464 22 5.66667 22H18.1667C18.6087 22 19.0326 21.8244 19.3452 21.5118C19.6577 21.1993 19.8333 20.7754 19.8333 20.3333V11.5833C19.8333 11.1413 19.6577 10.7174 19.3452 10.4048C19.0326 10.0923 18.6087 9.91667 18.1667 9.91667ZM10.25 14.9167C10.25 14.4746 10.4256 14.0507 10.7382 13.7382C11.0507 13.4256 11.4746 13.25 11.9167 13.25C12.3587 13.25 12.7826 13.4256 13.0952 13.7382C13.4077 14.0507 13.5833 14.4746 13.5833 14.9167C13.5816 15.2075 13.5037 15.4929 13.3575 15.7443C13.2113 15.9958 13.0019 16.2046 12.75 16.35V18.25C12.75 18.471 12.6622 18.683 12.5059 18.8393C12.3496 18.9955 12.1377 19.0833 11.9167 19.0833C11.6957 19.0833 11.4837 18.9955 11.3274 18.8393C11.1711 18.683 11.0833 18.471 11.0833 18.25V16.35C10.8314 16.2046 10.622 15.9958 10.4758 15.7443C10.3296 15.4929 10.2518 15.2075 10.25 14.9167ZM8.375 7.625C8.375 6.68569 8.74814 5.78485 9.41233 5.12066C10.0765 4.45647 10.9774 4.08333 11.9167 4.08333C12.856 4.08333 13.7568 4.45647 14.421 5.12066C15.0852 5.78485 15.4583 6.68569 15.4583 7.625V9.5C15.4583 9.61051 15.4144 9.71649 15.3363 9.79463C15.2582 9.87277 15.1522 9.91667 15.0417 9.91667H8.79167C8.68116 9.91667 8.57518 9.87277 8.49704 9.79463C8.4189 9.71649 8.375 9.61051 8.375 9.5V7.625Z"
        fill={color}
      />
    </svg>
  );
};

export default Lock;
