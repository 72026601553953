import { IconProps } from '../types';

import * as colors from 'components/ui/styles/colors.module.scss';

const Save = ({ fill = colors.black }: IconProps) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8334 9.83342C12.8334 11.6744 11.341 13.1667 9.50004 13.1667C7.65909 13.1667 6.16671 11.6744 6.16671 9.83342C6.16671 7.99247 7.65909 6.50008 9.50004 6.50008C11.341 6.50008 12.8334 7.99247 12.8334 9.83342ZM11.5834 9.83342C11.5834 10.984 10.6506 11.9167 9.50004 11.9167C8.34945 11.9167 7.41671 10.984 7.41671 9.83342C7.41671 8.68282 8.34945 7.75008 9.50004 7.75008C10.6506 7.75008 11.5834 8.68282 11.5834 9.83342Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.98129 1.1855C4.31251 0.854281 4.77232 0.666748 5.25322 0.666748H15.2457C16.2146 0.666748 17 1.41294 17 2.33341V15.6667C17 16.5872 16.2146 17.3334 15.2457 17.3334H2.08776C1.11884 17.3334 0.333374 16.5872 0.333374 15.6667V5.49887C0.333374 5.07163 0.506082 4.66071 0.815831 4.35096L3.98129 1.1855ZM15.6843 2.33341V15.6667C15.6843 15.8969 15.4879 16.0834 15.2457 16.0834H2.08776C1.84553 16.0834 1.64916 15.8969 1.64916 15.6667V5.49887C1.64916 5.39206 1.69234 5.28933 1.76978 5.21189L4.77759 2.20408V3.16675C4.77759 4.08722 5.56306 4.83342 6.53198 4.83342H11.9121C12.881 4.83342 13.6665 4.08722 13.6665 3.16675V1.91675H15.2457C15.4879 1.91675 15.6843 2.1033 15.6843 2.33341ZM12.5554 1.91675H5.8887V2.48966C5.8887 3.20879 6.50235 3.79175 7.25932 3.79175H11.1848C11.9417 3.79175 12.5554 3.20879 12.5554 2.48966V1.91675Z"
      fill={fill}
    />
  </svg>
);

export default Save;
