export default {
  START_PARCEL_SELECTION: 'START_PARCEL_SELECTION',
  STOP_PARCEL_SELECTION: 'STOP_PARCEL_SELECTION',
  SELECT_TOOL_TYPE: 'SELECT_TOOL_TYPE',
  CHANGE_STATUS: 'CHANGE_STATUS',
  SET_PARCEL_DATA: 'SET_PARCEL_DATA',
  QUERY_SUCCESS: 'QUERY_SUCCESS',
  ADD_PARCEL: 'ADD_PARCEL',
  REMOVE_PARCEL: 'REMOVE_PARCEL',
  MERGE_PARCELS: 'MERGE_PARCELS',
  ZOOM_PARCEL: 'ZOOM_PARCEL',
  HIGHLIGHT_PARCEL: 'HIGHLIGHT_PARCEL',
  CHANGE_SELECTED_PARCEL_INDEX: 'CHANGE_SELECTED_PARCEL_INDEX',
  RESET_PARCELS_STATE: 'RESET_PARCELS_STATE',
};
