import MapRightLogo from 'static/images/logo.png';

import './styles.scss';

const ErrorScreen = () => (
  <div className="error-screen">
    <img className="main-header__logo" src={MapRightLogo} />
    <h1>An Error Occurred</h1>
    <p>
      Something went wrong. The support team has been notified and is working on
      the issue. If the issue persists, please submit a support ticket{' '}
      <a href="/contact" target="_blank" rel="noopener noreferrer">
        here
      </a>
    </p>
  </div>
);

export default ErrorScreen;
